import { sendRequest } from "../helper";

export type UpdateCampaignProps = {
  campaignId: string;
  name?: string;
  requirement?: string;
  notes?: string;
  internalNotes?: string;
};

export const updateCampaign = (props: UpdateCampaignProps) => {
  return sendRequest("put", `/campaign`, false, props).then((res) => res.data);
};
