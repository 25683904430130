import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { HiNewspaper } from "react-icons/hi";
import { HiRocketLaunch } from "react-icons/hi2";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { LuWorkflow } from "react-icons/lu";
import { BsPersonFill } from "react-icons/bs";
import { TbInputSearch } from "react-icons/tb";
import { PiNotification } from "react-icons/pi";
import Cookies from "universal-cookie";
import styles from "./Navbar.module.css";
import { Badge, Button, Dropdown, Menu, Space } from "antd";
import { useUserAuth } from "../../../context/userAuthcontext";
import { getUser } from "../../../handler/getUser";
import { getAppState } from "../../../handler/getAppState";
import { getInvitations } from "../../../handler/organization";
import { ExclamationCircleOutlined, DownOutlined } from "@ant-design/icons";
import Modals from "../../Onboarding-1/Modals";
import ProfileModal from "../../Profile/Modal";
import Notification from "./Notification";
import InvoiceModal from "../../Invoices/Modal";
import ContactModal from "../ContactModal";
import { SiMeilisearch } from "react-icons/si";

import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import { firebaseDb } from "../../../firebase";
import { Notification as NotificationData } from "../../../typings/Notification";
import { notificationTypes } from "./Notification/Types";

interface NavbarProps {
  selectedItem?:
    | "campaigns"
    | "discover"
    | "mine"
    | "other"
    | "billing"
    | "chat"
    | "invite"
    // | "track"
    | "workflows"
    | "connected";
}

const Navbar: React.FC<NavbarProps> = ({ selectedItem = "campaigns" }) => {
  const cookies = new Cookies();

  const { currentUser, logout } = useUserAuth();
  const navigate = useNavigate();
  const [getUserkey, getUserFn] = getUser();
  const [getAppStateKey, getAppStateFn] = getAppState();
  const [invoiceModalOpen, setinvoiceModalOpen] = useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);
  const [_, setIsFirstTime] = useState(false);
  const [contactModalVisible, setContactModalVisible] =
    useState<boolean>(false);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const [profileVisible, setProfileVisible] = useState<boolean>(false);

  const [reminderNotifications, setReminderNotifications] = useState<
    NotificationData[]
  >([]);

  const { data: userData } = useQuery(getUserkey, getUserFn);
  const { data: userNotificationData } = useQuery(
    getAppStateKey,
    getAppStateFn
  );

  const [getInvitationsKey, getInvitationsFn] = getInvitations({
    status: "Accepted",
    send: false,
  });

  const { data: orgData } = useQuery(getInvitationsKey, getInvitationsFn);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastDoc, setLastDoc] = useState<object>();
  const [lastReminderDoc, setLastReminderDoc] = useState<object>();

  const [hasMore, setHasMore] = useState<boolean>(false);
  const [hasMoreReminders, setHansMoreReminders] = useState<boolean>(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);
  const firstRender = useRef(true);

  // Function for hasMore check
  const getDocsCount = async (doc: object) => {
    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      orderBy("createdDateTime", "desc"),
      startAfter(doc),
      limit(10)
    );
    const getAllNotifications = await getDocs(q);

    setHasMore(getAllNotifications.docs.length === 0 ? false : true);
  };

  const getDocsReminderCount = async (doc: object) => {
    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      where("type", "in", [
        notificationTypes.PaymentPendingReminder,
        notificationTypes.SendRequirementsReminder,
        notificationTypes.DraftsApprovedReminder,
        notificationTypes.LiveApprovedReminder,
      ]),
      orderBy("createdDateTime", "desc"),
      startAfter(doc),
      limit(10)
    );
    const getAllNotifications = await getDocs(q);

    setHansMoreReminders(getAllNotifications.docs.length === 0 ? false : true);
  };

  useEffect(() => {
    const getUnreadNotificationCount = async () => {
      const q = query(
        collection(firebaseDb, "Notifications"),
        where("userId", "==", cookies.get("brandId") || currentUser?.uid),
        where("isRead", "==", false)
      );

      const getAllUnreadNotifications = await getDocs(q);
      setNewNotificationsCount(getAllUnreadNotifications.docs.length);
    };
    getUnreadNotificationCount();
  }, []);

  useEffect(() => {
    const reminderQuery = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      where("type", "in", [
        notificationTypes.PaymentPendingReminder,
        notificationTypes.SendRequirementsReminder,
        notificationTypes.DraftsApprovedReminder,
        notificationTypes.LiveApprovedReminder,
      ]),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );
    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );
    let reminderArr = [...reminderNotifications];
    let arr = [...notifications];
    onSnapshot(reminderQuery, (snapshot) => {
      setLastReminderDoc(snapshot.docs[snapshot.docs.length - 1]);
      getDocsReminderCount(snapshot.docs[snapshot.docs.length - 1]);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (reminderArr.findIndex((not) => not.id === change.doc.id) === -1) {
            if (firstRender.current) {
              reminderArr.push({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            } else {
              reminderArr.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            }
          }
        }
      });
    });

    onSnapshot(q, (snapshot) => {
      let count = newNotificationsCount;
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      getDocsCount(snapshot.docs[snapshot.docs.length - 1]);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            if (firstRender.current) {
              arr.push({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            } else {
              arr.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            }
          }

          if (change.doc.data().isRead === false) {
            ++count;
          }
        }
      });
      if (firstRender.current) {
        firstRender.current = false;
      } else {
        setNewNotificationsCount(count);
      }
    });

    setNotifications(arr);
    setReminderNotifications(reminderArr);
  }, []);

  useEffect(() => {
    if (
      userData &&
      userData.data.signUpStatus !== "businessVerified" &&
      userData.data.signUpStatus !== "businessVerificationPending" &&
      window.location.pathname.split("/")[1] === "discover"
    ) {
      if (userData.data.signUpStatus === "notInitiated") {
        // check this before commit
        setVisible(true);
        // setIsFirstTime(true);
      }

      // if (userData.data.signUpStatus === "businessNeedsAttention") {
      //   setIsFirstTime(false);
      //   setVisible(true);
      // }
    }
  }, [userData]);

  const fetchNextPageGeneral = async () => {
    setIsLoading(true);
    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      orderBy("createdDateTime", "desc"),
      startAfter(lastDoc),
      limit(10)
    );
    const getAllNotifications = await getDocs(q);
    setLastDoc(getAllNotifications.docs[getAllNotifications.docs.length - 1]);
    getDocsCount(getAllNotifications.docs[getAllNotifications.docs.length - 1]);
    let arr: NotificationData[] = [];

    getAllNotifications.forEach((doc) => {
      arr.push({ ...(doc.data() as NotificationData), id: doc.data().id });
    });
    //
    setNotifications([...notifications, ...arr]);
    setIsLoading(false);
  };

  const fetchNextPageReminder = async () => {
    setIsLoading(true);

    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      where("type", "in", [
        notificationTypes.PaymentPendingReminder,
        notificationTypes.SendRequirementsReminder,
        notificationTypes.DraftsApprovedReminder,
        notificationTypes.LiveApprovedReminder,
      ]),
      orderBy("createdDateTime", "desc"),
      startAfter(lastReminderDoc),
      limit(10)
    );

    const getAllNotifications = await getDocs(q);
    setLastReminderDoc(
      getAllNotifications.docs[getAllNotifications.docs.length - 1]
    );
    getDocsReminderCount(
      getAllNotifications.docs[getAllNotifications.docs.length - 1]
    );
    let arr: NotificationData[] = [];

    getAllNotifications.forEach((doc) => {
      arr.push({ ...(doc.data() as NotificationData), id: doc.data().id });
    });
    //
    setReminderNotifications([...reminderNotifications, ...arr]);
    setIsLoading(false);
  };

  const handleOrgChange = (val: string) => {
    if (val === "default") {
      cookies.remove("brandId", {
        path: "/",
      });
    } else {
      cookies.set("brandId", val, {
        path: "/",
        maxAge: 360 * 24 * 60 * 60,
      });
    }
    // to go to homepage
    window.open(process.env.REACT_APP_DEPLOYMENT_URL, "_self", "noreferrer");
  };

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "1000",
        height: "8.3vh",
        marginBottom:
          userData?.data?.signUpStatus !== "businessVerified" ? "60px" : 0,
      }}
    >
      <nav className={styles.navContainer}>
        <div className={styles.innerContainer}>
          <img
            onClick={() => navigate("/")}
            src="/assets/img/cloutflow.png"
            alt="cloutflow logo"
            style={{ height: "35px", width: "60px", cursor: "pointer" }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              height: "100%",
            }}
          >
            <Link className={styles.navItemContainer} to="/">
              <span
                className={
                  selectedItem === "campaigns"
                    ? styles.activeNavItem
                    : styles.navItem
                }
              >
                <Space align="center">
                  <HiRocketLaunch
                    size={20}
                    color={
                      selectedItem === "campaigns"
                        ? "#080bf4"
                        : "rgba(0, 0, 0, 0.45)"
                    }
                  />
                  <span>Campaigns</span>
                </Space>
              </span>
            </Link>
            <Link className={styles.navItemContainer} to="/discover">
              <span
                className={
                  selectedItem === "discover"
                    ? styles.activeNavItem
                    : styles.navItem
                }
              >
                <Space align="center">
                  <TbInputSearch
                    size={20}
                    color={
                      selectedItem === "discover"
                        ? "#080bf4"
                        : "rgba(0, 0, 0, 0.45)"
                    }
                  />
                  <span>Explore</span>
                </Space>
              </span>
            </Link>

            {userData?.data?.accountType &&
              userData?.data?.accountType !== "Report" && (
                <Link className={styles.navItemContainer} to="/billing">
                  <span
                    className={
                      selectedItem === "billing"
                        ? styles.activeNavItem
                        : styles.navItem
                    }
                  >
                    <Space align="center">
                      <HiNewspaper
                        size={20}
                        color={
                          selectedItem === "billing"
                            ? "#080bf4"
                            : "rgba(0, 0, 0, 0.45)"
                        }
                      />
                      <span>Billing</span>
                    </Space>
                  </span>
                </Link>
              )}
            {userData?.data?.accountType &&
              userData?.data?.accountType !== "Report" && (
                <Link className={styles.navItemContainer} to="/chat">
                  <span
                    className={
                      selectedItem === "chat"
                        ? styles.activeNavItem
                        : styles.navItem
                    }
                  >
                    <Space align="center">
                      <Badge
                        dot={userNotificationData?.data?.hasMessages}
                        offset={["-3px", "1px"]}
                      >
                        <BiSolidMessageSquareDetail
                          size={20}
                          color={
                            selectedItem === "chat"
                              ? "#080bf4"
                              : "rgba(0, 0, 0, 0.45)"
                          }
                        />
                      </Badge>
                      <span>Messaging</span>
                    </Space>
                  </span>
                </Link>
              )}

            <Link className={styles.navItemContainer} to="/connected/instagram">
              <span
                className={
                  selectedItem === "connected"
                    ? styles.activeNavItem
                    : styles.navItem
                }
              >
                {" "}
                <Space align="center">
                  <SiMeilisearch
                    size={20}
                    color={
                      selectedItem === "connected"
                        ? "#080bf4"
                        : "rgba(0, 0, 0, 0.45)"
                    }
                  />

                  <span>Connected </span>
                </Space>
              </span>
            </Link>

            <Link className={styles.navItemContainer} to="/invite">
              <span
                className={
                  selectedItem === "invite"
                    ? styles.activeNavItem
                    : styles.navItem
                }
              >
                <Space align="center">
                  <BsPersonFill
                    size={20}
                    color={
                      selectedItem === "invite"
                        ? "#080bf4"
                        : "rgba(0, 0, 0, 0.45)"
                    }
                  />

                  <span>Community</span>
                </Space>
              </span>
            </Link>

            {/* {userData?.data?.accountType &&
              userData?.data?.accountType === "Managed" && (
                <Link className={styles.navItemContainer} to="/track">
                  <span
                    className={
                      selectedItem === "track"
                        ? styles.activeNavItem
                        : styles.navItem
                    }>
                    <Space align="center">
                      <HiNewspaper
                        size={20}
                        color={
                          selectedItem === "track"
                            ? "#080bf4"
                            : "rgba(0, 0, 0, 0.45)"
                        }
                      />
                      <span>Global Track</span>
                    </Space>
                  </span>
                </Link>
              )} */}

            <Link className={styles.navItemContainer} to="/workflows">
              <span
                className={
                  selectedItem === "workflows"
                    ? styles.activeNavItem
                    : styles.navItem
                }
              >
                <Space align="center">
                  <LuWorkflow
                    size={20}
                    color={
                      selectedItem === "workflows"
                        ? "#080bf4"
                        : "rgba(0, 0, 0, 0.45)"
                    }
                  />

                  <span>WorkFlows</span>
                </Space>
              </span>
            </Link>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Space size={40}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setNotificationVisible(true)}
              >
                <Badge
                  count={newNotificationsCount}
                  offset={[7, 5]}
                  size="small"
                >
                  <PiNotification size={30} color={"rgba(0, 0, 0, 0.45)"} />
                </Badge>
              </div>
              <div className={styles.profile} style={{ cursor: "pointer" }}>
                {orgData && (
                  <Dropdown
                    overlay={
                      <Menu
                        // selectedKeys={cookies.get("brandId") || "default"}
                        items={[
                          // {
                          //   key: "1",
                          //   type: "group",
                          //   label: "Organizations",
                          //   children: [
                          //     {
                          //       key: "default",
                          //       label: "Default Organization",
                          //       onClick: () => handleOrgChange("default"),
                          //     },
                          //     ...orgData?.data?.map((org) => {
                          //       return {
                          //         key: org.brandId,
                          //         label: org?.brandUser?.name + " Organization",
                          //         onClick: () => handleOrgChange(org.brandId),
                          //       };
                          //     }),
                          //   ],
                          // },
                          {
                            key: "2",
                            type: "group",
                            label: "Profile",
                            children: [
                              {
                                key: "2",
                                label: (
                                  <p onClick={() => setProfileVisible(true)}>
                                    Settings
                                  </p>
                                ),
                              },
                              {
                                key: "3",
                                label: <p onClick={logout}>Logout</p>,
                              },
                            ],
                          },
                        ]}
                      />
                    }
                    placement="bottomLeft"
                    arrow
                    className={styles.profile}
                    trigger={["click"]}
                  >
                    <Button>
                      {userData &&
                      userData.data?.businessDetails?.logoUrl !== "" ? (
                        <img
                          src={userData?.data?.businessDetails?.logoUrl}
                          alt="logo"
                          style={{
                            width: "24px",
                            height: "24px",
                            objectFit: "contain",
                            borderRadius: "999px",
                          }}
                        />
                      ) : currentUser?.displayName ? (
                        currentUser?.displayName.charAt(0).toUpperCase()
                      ) : (
                        currentUser?.email?.charAt(0).toUpperCase()
                      )}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </div>
            </Space>

            <Modals
              isVisible={visible}
              setVisible={setVisible}
              isFirstTime={true}
            />
          </div>
        </div>
      </nav>
      {userData &&
        userData.data.signUpStatus !== "businessVerificationPending" &&
        userData.data.signUpStatus !== "businessVerified" && (
          <p
            style={
              userData.data.signUpStatus === "businessNonVerified"
                ? { backgroundColor: "#ff3141" }
                : {}
            }
            className={styles.alert}
          >
            {userData.data.signUpStatus === "businessNonVerified"
              ? "There is an issue in Account Activation"
              : " Activate your Account"}

            <Button
              ghost
              size="middle"
              style={{
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#FFFFFF",
              }}
              onClick={() => {
                // setIsFirstTime(false);
                // setVisible(true);
                setContactModalVisible(true);
              }}
            >
              {" "}
              {userData.data.signUpStatus === "businessNonVerified"
                ? "Review"
                : "Complete Activation"}
            </Button>
          </p>
        )}
      {userNotificationData?.data?.subscriptionStatus === "past_due" && (
        <p style={{ backgroundColor: "#ff3141" }} className={styles.alert}>
          Pay Due Invoices
          <Button
            ghost
            size="middle"
            style={{
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "22px",
              color: "#FFFFFF",
            }}
            onClick={() => setinvoiceModalOpen(true)}
          >
            {" "}
            Open Invoices
          </Button>
        </p>
      )}
      {userData &&
        userData.data.signUpStatus === "businessVerificationPending" && (
          <p className={styles.kycPending}>
            <ExclamationCircleOutlined style={{ color: "#FA541C" }} />
            Account verification under processing
          </p>
        )}

      {/* <Button onClick={() => setProfileVisible(true)}>click Me</Button> */}
      <ProfileModal
        modalVisible={profileVisible}
        setModalVisible={setProfileVisible}
      />
      <Notification
        setNotificationVisible={setNotificationVisible}
        notificationVisible={notificationVisible}
        setNewNotificationsCount={setNewNotificationsCount}
        newNotificationsCount={newNotificationsCount}
        notifications={notifications}
        reminderNotifications={reminderNotifications}
        setNotifications={setNotifications}
        setReminderNotifications={setReminderNotifications}
        isLoading={isLoading}
        fetchNextPage={fetchNextPageGeneral}
        fetchNextPageReminder={fetchNextPageReminder}
        hasMore={hasMore}
        hasMoreReminders={hasMoreReminders}
        setVisible={setContactModalVisible}
        setIsFirstTime={setIsFirstTime}
      />
      <InvoiceModal
        visible={invoiceModalOpen}
        handleClose={() => setinvoiceModalOpen(false)}
      />
      <ContactModal
        handleClose={() => setContactModalVisible(false)}
        show={contactModalVisible}
      />
    </div>
  );
};

export default Navbar;
