import { sendRequest } from "./helper";

import { User } from "../typings";

interface editUserDetailsProps {
  name?: string;
  lastname?: string;
  designation?: string;
  businessNiche?: string[];
  businessName?: string;
  brandPromotingName?: string;
  businessLogoUrl?: string;
  businessType?: string;
  businessWebsiteUrl?: string;
  businessVerificationDocRef?: { label: string; url?: string }[];
  businessAddressStreet?: string;
  businessAddressCity?: string;
  businessAddressPincode?: string;
  businessAddressCountry?: string;
  businessAddressState?: string;
}

export const editUserDetails = (inputProps: editUserDetailsProps) => {
  return sendRequest<User>("put", `/brand/me`, false, {
    ...inputProps,
  }).then((res) => res.data);
};
