import React, { useState } from "react";
import { Modal, Input, InputNumber, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import { CardType } from "../../CardTypes";
import { postLiveChangesV2 } from "../../../../../../handler/campaign/applicants";
import { AxiosError } from "axios";

interface LiveChangesModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  workflowId: string;
  prevLiveLinkTimeLineId: string;
}

const LiveChangesModal: React.FC<LiveChangesModalProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  workflowId,
  prevLiveLinkTimeLineId,
}) => {
  const { TextArea } = Input;
  const queryClient = useQueryClient();

  const postLiveMutation = useMutation(postLiveChangesV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries(["chat", CardType.deliverableLiveSentType]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const [inputVal, setInputVal] = useState<{
    deadline: number;
    comments: string;
  }>({ deadline: 2, comments: "" });

  return (
    <Modal
      title="Approve Changes"
      visible={isVisible}
      onOk={() =>
        postLiveMutation.mutate({
          workflowId,
          changes: inputVal.comments,

          prevLiveLinkTimeLineId,
        })
      }
      onCancel={handleCancel}
      centered>
      <TextArea
        autoSize={{ minRows: 4 }}
        placeholder="Add any comments here"
        value={inputVal.comments}
        onChange={(e) =>
          setInputVal((inputVal) => {
            return { ...inputVal, comments: e.target.value };
          })
        }
      />
      {/* <p
        style={{
          margin: "10px 0 4px",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
        }}>
        Set a deadline
      </p>
      <InputNumber
        size="middle"
        addonAfter="days"
        type={"number"}
        value={inputVal.deadline}
        onChange={(val) =>
          setInputVal((inputVal) => {
            return { ...inputVal, deadline: val as number };
          })
        }
      /> */}
    </Modal>
  );
};

export default LiveChangesModal;
