import React, { useState } from "react";
import { Button, Divider, Col, message, Tag } from "antd";
import ProgressIndicator from "./ProgressIndicator";
import nf from "@tuplo/numberfmt";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Checkbox } from "antd";

import { getFinalizedWorkflow } from "../../../../../handler/campaign/applicants/getFinalizedWorkflow";
import { getCampaign } from "../../../../../handler/campaign";
// import { releasePayment } from "../../../../../handler/campaign/offer";

import { FinalizedApplicant } from "../../../../../typings";

import styles from "./card.module.css";

import TimelineDrawer from "../Timeline";

import CreatorProfileImg from "../../../CreatorProfileImg";

interface CardProps {
  applicant: FinalizedApplicant;
  isCardChecked: boolean;
  handleCheck?: ({
    id,
    influencerId,
  }: {
    id: string;
    influencerId: string;
  }) => void;
  campaignId: string;
  finalizedApplicantKey: any[];
}
const Card: React.FC<CardProps> = ({
  applicant,
  isCardChecked,
  handleCheck,
  campaignId,
  finalizedApplicantKey,
}) => {
  // const queryClient = useQueryClient();

  const [getWorkflowKey, getWorkflowFn] = getFinalizedWorkflow({
    offerId: applicant._id,
  });
  const [getCampaignKey, getCampaignFunc] = getCampaign({
    campaignId,
  });

  const { data } = useQuery(getWorkflowKey, getWorkflowFn);
  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFunc);
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);

  // const releasePaymentMutation = useMutation(releasePayment, {
  //   onError: () => {
  //     message.error("Something went wrong");
  //   },
  //   onSuccess: () => {
  //     message.success("Payment Released");
  //     queryClient.invalidateQueries(finalizedApplicantKey);
  //   },
  // });

  const isPaid = applicant?.ecrowChequeStatus !== "Acquired";

  return (
    <article className={styles.container}>
      <div className={styles.profileInfo}>
        <>
          <Checkbox
            // style={{ top: "3px", left: "6px" }}
            checked={isCardChecked}
            onChange={() => {
              if (handleCheck) {
                handleCheck({
                  id: applicant._id,
                  influencerId: applicant.influencer._id,
                });
              }
            }}
          />

          <CreatorProfileImg
            influencerID={applicant.influencer._id}
            imageURl={applicant.influencer.profilePicUrl || ""}
          />
        </>
        <div>
          <p className={styles.cName}>
            {applicant.influencer.name + " "}
            <span style={{ textTransform: "capitalize" }}>
              {applicant.influencer.lastname}
            </span>
          </p>
          <p className={styles.followers}>
            {nf(
              campaignData?.data?.campaign?.platform === "instagram"
                ? applicant.socialMediaKit?.instagram?.instagramFollowerCount ||
                    0
                : applicant.socialMediaKit?.youtube?.youtubeSubscriberCount ||
                    0,
              "a",
              { locale: "en-US" }
            )}{" "}
            Followers
          </p>
          {/* <p className={styles.followers}>Brand Price: 2k</p> */}
          {/* <p className={styles.followers}>Influencer Price: 2k</p> */}
          {/* <p>{applicant.socialMediaKit.}</p> */}
        </div>
        <div>
          {isPaid && (
            <Tag color="volcano" style={{ borderRadius: 8 }}>
              Paid
            </Tag>
          )}
        </div>
      </div>
      <Divider type="vertical" style={{ height: "50px" }} />
      <div
        style={{
          display: "flex",
          gap: "20px",
          // cursor: "pointer",
          alignItems: "center",
        }}
      >
        <ProgressIndicator
          metricFor="Requirements"
          numerator={(data?.data || []).reduce(
            (current, item) => current + item.isRequirementClosed,
            0
          )}
          denominator={(data?.data || []).reduce(
            (current, item) => current + item.isRequirementsEnabled,
            0
          )}
          workflow={data?.data}
        />
        <Divider style={{ minWidth: "44px" }} />
        <ProgressIndicator
          metricFor="Drafts"
          numerator={(data?.data || []).reduce(
            (current, item) => current + item.isDraftsClosed,
            0
          )}
          denominator={(data?.data || []).reduce(
            (current, item) => current + item.isDraftsEnabled,
            0
          )}
          workflow={data?.data}
        />
        <Divider style={{ minWidth: "44px" }} />
        <ProgressIndicator
          numerator={(data?.data || []).reduce(
            (current, item) => current + item.isLiveClosed,
            0
          )}
          denominator={(data?.data || []).reduce(
            (current, item) => current + item.isLiveEnabled,
            0
          )}
          metricFor="Links"
          workflow={data?.data}
        />
        <Col>
          <Button
            ghost
            onClick={() => setDrawerVisible((visible) => !visible)}
            style={{ borderRadius: "4px" }}
          >
            Timeline
          </Button>
        </Col>
      </div>
      <TimelineDrawer
        isOpen={isDrawerVisible}
        applicantId={applicant._id}
        handleClose={() => setDrawerVisible(false)}
        campaignId={campaignId}
        influencerId={applicant.influencer._id}
      />
    </article>
  );
};

export default Card;
