import React from "react";
import { Alert, Anchor, Row, Spin, Typography } from "antd";
import { CreatorPageContextProvider, useCreatorPageContext } from "./context";
import PerformanceAnalysis from "./PerformanceAnalysis";
import Growth from "./Growth";
import CommentAnalysis from "./CommentAnalysis";
import Audience from "./Audience";

interface Props {
  username: string;
  platform: "instagram" | "youtube";
}

const { Title } = Typography;

export const ProfileChild = () => {
  const { identifier, workplatformId, isLoading, creatorData, error } =
    useCreatorPageContext();
  console.log(error);

  if (!identifier || !workplatformId) return <div>Invalid Data</div>;

  if (isLoading) {
    return (
      <Row justify={"center"}>
        <Spin size="large" />
      </Row>
    );
  }

  if (error?.response?.data?.recursiveError?.error?.code === "retry_later") {
    return (
      <div>
        <Alert
          showIcon
          message="Profile Update in Progress"
          description={
            "We are currently fetching the Profile for " +
            identifier +
            ". This process takes about 20 minutes due to the large amount of data being processed. Please check back after 20 minutes."
          }
          type="success"
        />
      </div>
    );
  }
  if (error) {
    return (
      <Row justify={"center"}>
        <Title level={2}>
          {error?.response?.data?.recursiveError?.error?.message ||
            "Something Went wrong"}
        </Title>
      </Row>
    );
  }

  if (!creatorData) {
    return (
      <Row justify={"center"}>
        <Title> Invalid Id</Title>
      </Row>
    );
  }
  return (
    <>
      <div style={{ padding: "20px" }}>
        {/* <Anchor
          //   direction="horizontal"
          items={[
            {
              key: "part-1",
              href: "#part-1",
              title: "Performance ",
            },
            {
              key: "part-2",
              href: "#part-2",
              title: "Comment Analysis",
            },
            {
              key: "part-3",
              href: "#part-3",
              title: "Growth & interests",
            },
            {
              key: "part-4",
              href: "#part-4",
              title: "Audience",
            },
          ]}
        /> */}
      </div>
      <div style={{ display: "grid", gap: 20 }}>
        <div id="part-1">
          <PerformanceAnalysis />
        </div>
        <div id="part-2">
          <CommentAnalysis />
        </div>
        <div id="part-3">
          <Growth />
        </div>
        <div id="part-4">
          <Audience />
        </div>
      </div>
    </>
  );
};

const ThirdParty: React.FC<Props> = ({ platform, username }) => {
  return (
    <CreatorPageContextProvider platform={platform} username={username}>
      <ProfileChild />
    </CreatorPageContextProvider>
  );
};

export default ThirdParty;
