import React from "react";
import { IoMdHeartEmpty } from "react-icons/io";
import { FiMessageCircle } from "react-icons/fi";

import { Typography, Space, Tag, Card, Avatar, Row } from "antd";

import { useCreatorPageContext } from "../context";
import { formatData, trimData } from "../../../../utils";

import { format } from "date-fns";

const { Title, Text } = Typography;

const RowItem = ({
  title,
  desc,
  tagContent,
}: {
  title: string;
  desc: string;
  tagContent?: string;
}) => {
  return (
    <Card style={{ height: 150, width: 200, borderRadius: "8px" }}>
      <Title level={4} style={{ marginBlock: 0, marginBottom: 10 }}>
        {title}
      </Title>
      <Text type="secondary">{desc}</Text>
      {tagContent && (
        <div>
          <Tag color="green" style={{ marginTop: 5 }}>
            {tagContent}
          </Tag>
        </div>
      )}
    </Card>
  );
};

const PostItem = ({
  url,
  comments,
  desc,
  likes,
  profileAvatar,
  createdDateTime,
  logo,
  thumbUrl,
}: {
  url: string;
  desc: string;
  likes: number;
  comments: number;
  profileAvatar: string;
  createdDateTime: string;
  logo: string;
  thumbUrl: string;
}) => {
  return (
    <Card
      onClick={() => window.open(url, "_blank")}
      title={
        <Row justify={"space-between"}>
          <Space>
            <Avatar src={profileAvatar} size={36} />
            <Text type="secondary">
              {format(new Date(createdDateTime), "MMM, yyyy, hh:mm aaaa")}
            </Text>
          </Space>
          <img src={logo} style={{ height: "36px", width: "36px" }} />
        </Row>
      }
    >
      <img src={thumbUrl} style={{ height: "250px", width: "250px" }} />
      <Text type="secondary" style={{ marginTop: "24px", display: "block" }}>
        {trimData({ value: desc || "", limit: 80 })}
      </Text>
      <Space size={34} style={{ marginTop: 30 }}>
        <Text type="secondary">
          <Space size={10}>
            <IoMdHeartEmpty size={24} />
            {formatData(likes, "a")}
          </Space>
        </Text>
        <Text type="secondary">
          <Space size={10}>
            <FiMessageCircle size={24} />
            {formatData(comments, "a")}
          </Space>
        </Text>
      </Space>
    </Card>
  );
};

const PerformanceAnalysis = () => {
  const { creatorData } = useCreatorPageContext();

  return (
    <div style={{ display: "grid" }}>
      <Title level={4}>Performance</Title>
      <Space wrap size={20}>
        <RowItem
          title={formatData(
            creatorData?.profile?.follower_count ||
              creatorData?.profile?.subscriber_count ||
              0,
            "a"
          )}
          desc={
            creatorData?.work_platform?.name === "Youtube"
              ? "Subscribers"
              : "Followers"
          }
          tagContent={
            formatData(
              creatorData?.profile?.audience?.credibility_score || 0,
              "%"
            ) + "Real"
          }
        />
        <RowItem
          title={formatData(
            creatorData?.profile?.engagement_rate || 0,
            "0.00%"
          )}
          desc="Engagement Rate"
        />
        <RowItem
          title={formatData(
            creatorData?.profile?.sponsored_posts_performance || 0,
            "0.00%"
          )}
          desc="Sponsored posts performance"
        />
        <RowItem
          title={formatData(creatorData?.profile?.average_likes || 0, "a")}
          desc="Average Likes per post"
        />
        <RowItem
          title={formatData(creatorData?.profile?.average_comments || 0, "a")}
          desc="Average Comments per post"
        />
        <RowItem
          title={formatData(creatorData?.profile?.average_views || 0, "a")}
          desc="Average Views per video"
        />
        {creatorData?.work_platform?.name === "Instagram" && (
          <RowItem
            title={formatData(
              creatorData?.profile?.average_reels_views || 0,
              "a"
            )}
            desc="Average views per reel"
          />
        )}
      </Space>
      <div style={{ height: "20px" }} />
      <Title level={4}>Top content</Title>
      <div
        style={{
          display: "flex",
          gap: 20,
          width: "100%",
          overflowX: "scroll",
        }}
      >
        {creatorData?.profile?.top_contents?.map((cItem, idx) => (
          <PostItem
            key={idx}
            comments={cItem?.engagement?.comment_count || 0}
            createdDateTime={cItem?.published_at}
            desc={cItem?.description}
            likes={cItem?.engagement?.like_count || 0}
            logo={creatorData?.work_platform?.logo_url}
            profileAvatar={creatorData?.profile?.image_url}
            thumbUrl={cItem?.thumbnail_url}
            url={cItem?.url}
          />
        ))}
      </div>
    </div>
  );
};

export default PerformanceAnalysis;
