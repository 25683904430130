import { Card, Col, Space, Input, Table, message } from "antd";
import Lottie from "react-lottie";
import { useRef, useEffect } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { HiMagnifyingGlass } from "react-icons/hi2";
import React, { useState } from "react";
import { Row, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import clsx from "clsx";

// import CardHeader from "./CardHeader";
// import CardRow from "./CardRow";
import ReportButton from "../../Campaign/Report/ReportButton";
import NoDataScreen from "./NoDataScreen";

import animationData from "../../assets/lottie/Rocket.json";

import { getInvitedInfluencers } from "../../../handler/community";
import { inviteInfluencer } from "../../../handler/invite";

import styles from "../Community/community.module.css";
import styles2 from "./styles.module.css";

import { getDefaultLottieOptions } from "../../../utils";

interface DataType {
  key: string;
  name: string;
  username: string;
  status: string;
  platform: "instagram" | "youtube";
  socialMediaKit: string;
  details: {
    email: string;
    phone: string;
  };
  isInviteAccepted: boolean;
  _id: string;
  influencerId: string;
}

const InvitedInfluencers = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [influencersKey, influencersFn] = getInvitedInfluencers({
    search: searchQuery,
    limit: 20,
    accountStatus: [],
    inviteStatus: [],
    platform: [],
    inviteBy: "",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const firstRender = useRef(true);

  const {
    data: InfluencerCampaignsData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(influencersKey, influencersFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.invited?.length ? lastPage.data.pageNo + 1 : null,
  });

  const navigate = useNavigate();

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: (_, { inviteList }) => {
      message.success(`Invitation Resent to ${inviteList[0].name}`);
    },
    onError: (e) => {
      console.log(e);
      message.error("Something went wrong while inviting Influencers");
    },
  });

  const getPrimaryStatus = (data: string) => {
    return Boolean(data);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, { username, socialMediaKit }) => {
        return (
          <div
            className={clsx(styles2.rowItem, styles2.status)}
            style={{ textAlign: "center" }}>
            <span>{username}</span>
            <span
              className={clsx(styles2.status2, {
                [styles2.connected]: getPrimaryStatus(socialMediaKit),
                [styles2.pending]: !getPrimaryStatus(socialMediaKit),
              })}>
              {getPrimaryStatus(socialMediaKit) ? (
                <span
                  style={{
                    display: "inline-block",
                    width: 5,
                    height: 5,
                    borderRadius: "50%",
                    backgroundColor: "#398200",
                  }}></span>
              ) : (
                <span
                  style={{
                    display: "inline-block",
                    width: 5,
                    height: 5,
                    borderRadius: "50%",
                    backgroundColor: "#7F7F7F",
                  }}></span>
              )}
              <span style={{ fontSize: 8 }}>
                {getPrimaryStatus(socialMediaKit) ? "Connected" : "Pending"}
              </span>
            </span>
          </div>
        );
      },
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (_, { platform }) => (
        <div className={clsx(styles2.rowItem, styles2.status)}>
          {platform === "instagram" ? (
            <FaInstagram size={15} color="rgba(0, 0, 0, 0.70)" />
          ) : (
            <FaYoutube size={15} color="rgba(0, 0, 0, 0.70)" />
          )}
          {platform[0].toUpperCase() + platform.slice(1) || ""}
        </div>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (_, { details }) => (
        <div className={styles2.rowItem}>
          {`${details?.email || ""} ${details?.phone || ""}`}
        </div>
      ),
    },
    {
      title: "Invite Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status: inviteStatus }) => (
        <div
          className={clsx(styles2.rowItem, styles2.inviteStatus, {
            [styles2.accepted]: inviteStatus === "accepted",
            [styles2.pending2]: inviteStatus === "pending",
            [styles2.withdrawn]: inviteStatus === "withdrawn",
          })}
          style={{ textAlign: "center" }}>
          {inviteStatus === "accepted"
            ? "Accepted 👍🏻"
            : inviteStatus === "pending"
            ? "Pending ⏳"
            : "Withdrawn 😕"}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (
        _,
        {
          _id,
          influencerId,
          isInviteAccepted,
          name,
          username,
          details,
          platform,
        }
      ) => {
        return (
          <div
            className={styles2.rowItem}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}>
            <div style={{ display: "grid", gap: 10 }}>
              {isInviteAccepted && influencerId ? (
                <ReportButton
                  type="default"
                  onClick={() =>
                    navigate(`/influencerAnalysis/${influencerId}`)
                  }>
                  Open profile
                </ReportButton>
              ) : (
                <>
                  <ReportButton
                    type="default"
                    loading={inviteInfluencerMutation.isLoading}
                    style={{ textAlign: "center" }}
                    onClick={() =>
                      inviteInfluencerMutation.mutate({
                        inviteList: [
                          {
                            name: name,
                            platform: platform,
                            username: username,
                            email: details.email,
                            phoneNumber: details.phone,
                          },
                        ],
                      })
                    }>
                    Invite Again
                  </ReportButton>

                  <ReportButton
                    type="primary"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          `https://connect.cloutflow.com/?connectRequestId=${_id}`
                        )
                        .then(() =>
                          message.success("Link Copied to clipboard")
                        );
                    }}>
                    Copy Invite link
                  </ReportButton>
                </>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isLoading && firstRender.current) {
      firstRender.current = false;
      setLoading(false);
    }
  }, [isLoading]);

  if (loading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <React.Fragment>
      <>
        <Col span={11} className={styles.inputContainer}>
          <Space size={2} align="center" style={{ width: "100%" }}>
            <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
            <Input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value || "")}
              onPressEnter={() => setSearchQuery(searchQuery)}
              placeholder={"Search by name or username"}
              className={styles.input}
              size="large"
              style={{
                borderColor: "transparent",
                boxShadow: "none",
                width: "100%",
                minWidth: "400px",
              }}
            />
          </Space>
          <ReportButton
            type="primary"
            onClick={() => setSearchQuery(searchQuery)}
            style={{ padding: "10px 20px " }}>
            Search
          </ReportButton>
        </Col>
        <section style={{ marginBlock: 20 }}>
          <Card
            // title={<CardHeader />}
            bodyStyle={{ padding: 0 }}
            style={{ borderRadius: 10 }}>
            <div
              style={{
                height: "auto",
                overflowY: "scroll",
              }}>
              <InfiniteScroll
                loadMore={() => fetchNextPage()}
                style={{ display: "grid", gap: 10, alignItems: "flex-start" }}
                hasMore={hasNextPage}
                loader={
                  <Row justify="center">
                    <Spin />
                  </Row>
                }
                useWindow={false}>
                {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
                    pageItem.data?.invited?.map((dataItem) => (
                      <CardRow data={dataItem} />
                    ))
                  )} */}
                <Table
                  columns={columns}
                  loading={isLoading}
                  dataSource={
                    InfluencerCampaignsData?.pages?.flatMap((page) => {
                      return page?.data?.invited?.map(
                        (item, idx) =>
                          ({
                            key: String(idx),
                            name: item?.fullName ?? "",
                            username: item?.socialMediaName ?? "",
                            platform: item?.platform ?? "instagram",
                            status: item?.isRemoved
                              ? "withdrawn"
                              : item?.isInviteAccepted
                              ? "accepted"
                              : "pending",
                            socialMediaKit: item?.socialMediaKit?._id ?? "",
                            details: {
                              email: item?.emailId ?? "",
                              phone: item?.phoneNumber ?? "",
                            },
                            _id: item._id,
                            isInviteAccepted: item.isInviteAccepted ?? false,
                            influencerId:
                              item?.socialMediaKit?.influencerId ?? "",
                          } as DataType)
                      );
                    }) || []
                  }
                  scroll={{ y: 320 }}
                  pagination={false}
                />
              </InfiniteScroll>
            </div>
          </Card>
        </section>
      </>
    </React.Fragment>
  );
};

export default InvitedInfluencers;
