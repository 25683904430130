import React from "react";

import { FiltersType } from "../index";

import Chip from "./Chip";

import type { IndexUiState } from "instantsearch.js";

import { INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET } from "../../../SaasDiscovery/constants";

import { getRangeFormatter } from "./Utils";

interface FilterChipsAggregateProps {
  filters: FiltersType;
  setIndexUIState: React.Dispatch<React.SetStateAction<IndexUiState>>;
}
const FilterChipsAggregate: React.FC<FilterChipsAggregateProps> = ({
  filters,
  setIndexUIState,
}) => {
  const handleCloseFilter = (filterName: string, itemName?: string) => {
    if (filterName === `followers`) {
      return setIndexUIState((indexUiState) => ({
        ...indexUiState,
        range: {
          ...indexUiState.range,
          [`followers`]: "",
        },
      }));
    } else {
      return setIndexUIState((indexUiState) => ({
        ...indexUiState,
        refinementList: {
          ...indexUiState.refinementList,
          [filterName]: (
            indexUiState?.refinementList?.[filterName] || []
          ).filter((item) => item !== itemName),
        },
      }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        margin: "20px 0",
        flexWrap: "wrap",
      }}>
      {filters?.range &&
        Object.keys(filters.range).map((filterName) => {
          if (filterName === `followers`) {
            return (
              <Chip
                handleCloseFilter={handleCloseFilter}
                label={`followers: ${getRangeFormatter(
                  filters?.range?.[filterName] || ""
                )}`}
                filterName={filterName}
              />
            );
          }
          return <div />;
        })}
      {filters?.refinementList &&
        Object.keys(filters.refinementList)
          .filter((item) => item !== INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET)
          .map((filterName) => {
            return filters?.refinementList?.[filterName].map((item) => {
              return (
                <Chip
                  handleCloseFilter={handleCloseFilter}
                  label={item}
                  filterName={filterName}
                  isArrayItem
                />
              );
            });
          })}
    </div>
  );
};

export default FilterChipsAggregate;
