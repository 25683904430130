import { sendRequest } from "../../helper";

interface FinalizedCountProps {
  campaignId: string;
  text: string;
  isRequirementNotSent: boolean;
  isDraftWaitingForReview: boolean;
  isLiveLinkWaitingForReview: boolean;
  isPaidToShow: boolean;
  status?: string;
}

interface FinalizedCountResponse {
  status: string;
  message: string;
  data: { count: number };
}

export const getFinalizedCount = ({
  campaignId,
  text,
  isRequirementNotSent,
  isDraftWaitingForReview,
  isLiveLinkWaitingForReview,
  status,
  isPaidToShow,
}: FinalizedCountProps) => {
  const queryKey = [
    "applicants",
    campaignId,
    "count",
    "finalized",
    {
      isDraftWaitingForReview,
      isLiveLinkWaitingForReview,
      isRequirementNotSent,
      isPaidToShow,
      text,
    },
    status,
  ];

  const queryFunction = () =>
    sendRequest<FinalizedCountResponse>(
      "put",
      `/brand/execution/finalized-search-count`,
      false,
      {
        campaignId,
        text,
        isRequirementNotSent,
        isDraftWaitingForReview,
        isLiveLinkWaitingForReview,
        status,
        isPaidToShow,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
