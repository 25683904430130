import React, { useState } from "react";
import { Progress, Tag } from "antd";
import { FinalizedWorkFlow } from "../../../../../../typings";

interface ProgressIndicatorProps {
  workflow: FinalizedWorkFlow[] | undefined;
  numerator: number;
  denominator: number;
  metricFor: "Drafts" | "Requirements" | "Links";
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  numerator,
  denominator,
  metricFor,
  workflow,
}) => {
  const getStrokeColor = () => {
    switch (metricFor) {
      case "Requirements":
        if (numerator === denominator) {
          if (numerator === 0) return "rgba(0, 0, 0, 0.25)";
          return "#48C016";
        }
        return "#B24025";

      case "Drafts":
        if (numerator === denominator) {
          if (numerator === 0) return "rgba(0, 0, 0, 0.25)";
          return "#48C016";
        } else {
          if (
            workflow?.some((item) => item.drafts.status === "Awaiting-Review")
          )
            return "#B24025";
          return "#2130FF";
        }
      case "Links":
        if (numerator === denominator && numerator !== 0) return "#48C016";
        if (workflow?.some((item) => item.drafts.status === "Awaiting-Review"))
          return "#B24025";
        if (workflow?.some((item) => item.drafts.status === "Going-Live"))
          return "#2130FF";
        // if (workflow?.some((item) => item.drafts.status === "Pending"))
        return "rgba(0, 0, 0, 0.25)";
      default:
        return "#48C016";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
      }}>
      <Progress
        type="circle"
        strokeColor={getStrokeColor()}
        width={27}
        percent={100}
        format={() => (
          <span
            style={{
              color: getStrokeColor(),
            }}>{`${numerator}/${denominator}`}</span>
        )}
        // style={{ height: "27px", width: "27px" }}
      />

      <p
        style={{
          marginTop: "6px",
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "14px",
          color: "rgba(0, 0, 0, 0.45)",
        }}>
        {metricFor}
      </p>
    </div>
  );
};

export default ProgressIndicator;
