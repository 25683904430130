import React from "react";
import { Input, Typography, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { RcFile } from "antd/lib/upload";

import { useUserAuth } from "../../../../context/userAuthcontext";

import styles from "../../Onboarding.module.css";
import cuid from "cuid";
interface DocsInputProps {
  CIN: string;
  docsLink: string[] | null;
  setDocsLink: React.Dispatch<React.SetStateAction<string[]>>;
  setCIN: React.Dispatch<React.SetStateAction<string>>;
}

const DocsInput: React.FC<DocsInputProps> = ({
  CIN,
  docsLink,
  setCIN,
  setDocsLink,
}) => {
  const { Title } = Typography;

  const { currentUser } = useUserAuth();
  const uploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 5,
    customRequest: (options) => {
      const { onSuccess, file, onProgress } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/brand/${currentUser?.uid}/documents/${"cin" + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setDocsLink((value) => {
            if (value.length) {
              return [...value, downloadURL];
            }
            return [downloadURL];
          });

          //   setDocsLinks((docsLink)=>{{...docsLink,downloadURL}});
          onSuccess && onSuccess(downloadURL);
        }
      );
    },
    accept: "image/png, image/jpeg, application/pdf",
    onRemove(file) {
      setDocsLink((docs) => {
        if (docs) {
          return docs.filter((docLink) => docLink !== file.response);
        }
        return [];
      });
    },
  };

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Title level={4} className={styles.radioTitle}>
        Enter Corporate Identification Number (CIN)
      </Title>
      <Input
        value={CIN}
        onChange={(event) => setCIN(event.target.value)}
        size="large"
      />
      <Title
        level={5}
        // className={styles.radioDesc}
        style={{
          justifyContent: "center",
          textAlign: "center",
          margin: "20px auto",
        }}>
        OR
      </Title>
      <div>
        <Title level={4} className={styles.radioTitle}>
          Upload Official document
        </Title>
        <Upload {...uploadButtonProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
    </>
  );
};

export default DocsInput;
