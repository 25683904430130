import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type postDeliverableProps = {
  campaignId: string;
  deliverable: any;
};

export const postDeliverable = ({
  campaignId,
  deliverable,
}: postDeliverableProps) => {
  //   const queryKey = ["campaign", "create"];
  //   const updatedProducts = products.map((product) => {
  //     return { title: product.title, price: product.price, link: product.link };
  //   });
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/create-campaign-form/deliverable/${campaignId}`,
    false,

    { deliverables: deliverable }
  ).then((res) => res.data);
  //   return [queryKey, queryFn];
};
