import React from "react";
import { Button, Popover } from "antd";
import { AiOutlineLink } from "react-icons/ai";

import PostedInfoRow from "./PostedInfoRow";

import { formatData } from "../../../../utils";

import styles from "./styles.module.css";

import { useCampaign } from "../Context/campaignContext";

const Chip = ({ label }: { label: string }) => {
  return (
    <div
      style={{
        padding: "1px 8px",
        background: "rgba(0, 0, 0, 0.04)",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "100px",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "17px",
        color: "#09121F",
      }}>
      {label}
    </div>
  );
};

const Preview = () => {
  const getDeliverableDetails = (item: any) => {
    return (
      <>
        <p className={styles.deliverableHeading}>
          {!item?.repost ? item?.duration : "Repost "}
          {item?.duration &&
            (item?.typeDel === "Reel" || item?.typeDel === "Story"
              ? " seconds"
              : " minutes")}{" "}
          {item?.type} {item.typeDel}
        </p>
        {item.contentRights && (
          <p className={styles.delDesc}>
            Content rights ({item.contentRights})
          </p>
        )}
        {item?.link && (
          <p className={styles.delDesc}>Swipe Up link ({item?.link})</p>
        )}

        {item?.description && (
          <p className={styles.delDesc}>Brief: {item?.description}</p>
        )}

        {item?.repostData?.instructions && (
          <p className={styles.delDesc}>
            Brief: {item?.repostData?.instructions}
          </p>
        )}
        {(item?.products || []).length > 0 && (
          <>
            <p className={styles.delDesc}> Products To Promote </p>
            {item?.products.map((product: any) => {
              return (
                <p className={styles.delDesc}>
                  {"-> "}

                  {
                    campaign.deal?.productDetails.find(
                      (ele: any) => ele._id === product
                    )?.productTitle
                  }
                </p>
              );
            })}
          </>
        )}
      </>
    );
  };
  const { campaign } = useCampaign();
  return (
    <Popover
      content={"This is how your Campaign will look on Creator's device"}
      title="Preview"
      trigger="hover"
      placement="top"
      arrowPointAtCenter>
      <div style={{ display: "flex", margin: "0 auto", position: "relative" }}>
        <img
          src="/assets/img/phone.png"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "350px",
            height: "450px",
          }}
          alt="phone"
        />

        <div
          style={{
            position: "absolute",
            top: "75px",
            left: 66,
            width: "218px",
            height: "360px",
            overflowX: "hidden",
            borderRadius: " 0 0 30px 30px",
            //   padding: "60px 40px",
          }}
          className={styles.containerNoScroll}>
          <main
            style={{
              background: "#F5F5F5",
              // height: "610px",
              // overflowY: "scroll",
            }}>
            <div>
              <img
                style={{ width: "100%", height: "150px", objectFit: "cover" }}
                src={
                  campaign.campaign.campaignBannerUrl ||
                  "/assets/img/sampleCampaignBanner.png"
                }
                alt="banner"
              />
            </div>
            <div style={{ display: "grid", gridGap: "7px" }}>
              <div style={{ backgroundColor: "#fff" }}>
                <div className={styles.campaignHeader}>
                  <div>
                    <p
                      className={styles.cName}
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "break-spaces",
                      }}>
                      {campaign.campaign.campaignName}
                    </p>
                    <p className={styles.bName}>
                      {/* {campaign.campaign.brand.businessDetails.name} */}
                    </p>
                  </div>
                  <img
                    style={{ width: "24px", height: "24px" }}
                    src={`/assets/img/${campaign.campaign.platform}.png`}
                    alt="platform"
                  />
                </div>

                <PostedInfoRow
                  style={{ marginBottom: "20px" }}
                  postedDate={campaign.campaign.createdDateTime}
                />

                <section className={styles.cDetails}>
                  {campaign.campaign.filter.niche.length > 0 && (
                    <>
                      <p className={styles.tileHeading}>Community</p>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                          marginTop: "11px",
                          marginBottom: "28px",
                        }}>
                        {campaign.campaign.filter.niche.map((item, idx) => (
                          <Chip label={item} key={idx} />
                        ))}
                      </div>
                    </>
                  )}
                </section>
              </div>

              <section className={styles.productList}>
                {campaign.deal.isProduct ? (
                  <>
                    <p className={styles.tileHeading}>Products</p>
                    <ul
                      style={{
                        marginTop: "10px",
                        display: "grid",
                        gap: "16px",
                        marginLeft: "12px",
                      }}>
                      {campaign.deal.productDetails.map((item, idx) => {
                        return (
                          <li key={idx}>
                            <p>
                              {item.productTitle}{" "}
                              <AiOutlineLink
                                style={{ marginLeft: "6px" }}
                                color="#316bff"
                              />
                            </p>
                            <p>₹ {formatData(item.price, "0,0.00")}/-</p>
                          </li>
                        );
                      })}
                    </ul>
                    <p
                      className={styles.delDesc}
                      style={{
                        fontStyle: "italic",
                        lineHeight: "16px",
                        fontSize: "12px",
                      }}>
                      * There are the list of all the products that the brand
                      wants to promote for this campaign, You might need to
                      promote one/multiple products in the list
                    </p>
                  </>
                ) : (
                  <>
                    <p className={styles.tileHeading}>Product Description</p>
                    <span
                      style={{
                        display: "block",
                        marginTop: "10px",
                        wordBreak: "break-word",
                        whiteSpace: "break-spaces",
                      }}>
                      {campaign.deal.promotionDescription}
                    </span>
                  </>
                )}
              </section>

              <section className={styles.deliverables}>
                <p className={styles.tileHeading}>Deliverables</p>
                <ul
                  style={{
                    display: "grid",
                    gap: "20px",
                    marginTop: "14px",
                    marginLeft: "12px",
                  }}>
                  {campaign.deal.deliverables.map((item, idx) => {
                    return <li key={idx}>{getDeliverableDetails(item)}</li>;
                  })}
                </ul>
              </section>
              <section className={styles.bGuidelines}>
                <p className={styles.tileHeading}>Brand Guidelines</p>

                <div style={{ marginTop: "14px" }} />

                <div
                  style={{
                    wordBreak: "break-word",
                    whiteSpace: "break-spaces",
                    fontSize: "14px",
                    color: "#09121F",
                  }}>
                  {campaign.campaign.brandGuidelines}
                </div>
              </section>

              <section
                style={{ backgroundColor: "#fff", padding: "16px 21px" }}>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#2130FF",
                    border: "1px solid #2130FF",
                    color: "#fff",
                  }}>
                  Apply
                </Button>
              </section>
            </div>
          </main>
        </div>
      </div>
    </Popover>
  );
};

export default Preview;
