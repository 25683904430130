import React, { useRef, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Row, Col, Space, Button, Spin, Table, Divider } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import animationData from "../../assets/lottie/Rocket.json";
import { useQueryClient } from "react-query";

import { useInfiniteQuery } from "react-query";

import { getProducts } from "../../../handler/replies";

import { getDefaultLottieOptions, trimData } from "../../../utils";

import { MessageReply } from "../../../typings";
import { ColumnsType } from "antd/es/table";

import AddMessageModal from "../Modal/AddMessage";

import { format } from "date-fns";

import styles from "./messages.module.css";

const Messages = () => {
  const [messagesKey, messagesFn] = getProducts({ limit: 100 });
  const queryClient = useQueryClient();

  const [messageModalProps, setMessageModalProps] = useState<{
    isView: boolean;
    isEdit: boolean;
    visible: boolean;
    message?: MessageReply;
  }>({
    isEdit: false,
    isView: false,
    visible: false,
    message: undefined,
  });

  const firstRender = useRef(true);

  const [loading, setLoading] = useState<boolean>(true);

  const {
    data: messagesData,
    isLoading: messagesDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(messagesKey, messagesFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.product.length ? lastPage.data.pageNo + 1 : null,
  });

  useEffect(() => {
    if (!messagesDataLoading && firstRender.current) {
      firstRender.current = false;
      setLoading(false);
    }
  }, [messagesDataLoading]);

  if (loading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  const columns: ColumnsType<MessageReply> = [
    {
      title: "Message",
      // dataIndex: "message",
      key: "message",
      render: (_, { title, image_url }) => (
        <Space size={10} align="center">
          {image_url && (
            <img src={image_url} className={styles.profile} alt="profile" />
          )}

          <p
            className={styles.name}
            // style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {trimData({ value: title || "", limit: 50 })}
          </p>
        </Space>
      ),
    },

    {
      title: "User Button Actions",
      // dataIndex: "followers",
      key: "uActions",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { buttons }) => (
        <Space size={10} wrap>
          {buttons?.map((bItem, idx) => (
            <Button
              key={idx}
              style={{ borderRadius: 8 }}
              onClick={() => {
                if (bItem?.type === "web_url") {
                  window.open(bItem?.url, "_blank");
                }
              }}>
              {bItem.title}
            </Button>
          ))}
        </Space>
      ),
    },
    {
      title: "Created on On",
      // dataIndex: "joiningDate",
      key: "joiningDate",

      render: (_, { createdDateTime }) => (
        <p className={styles.followers}>
          {createdDateTime && (
            <>{format(new Date(createdDateTime), "d MMM, RRRR")}</>
          )}
        </p>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "followers",
      key: "Actions",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, record) => (
        <Space size={10} wrap>
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setMessageModalProps({
                isEdit: false,
                isView: true,
                visible: true,
                message: record,
              })
            }>
            View
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setMessageModalProps({
                isEdit: true,
                isView: false,
                visible: true,
                message: record,
              })
            }>
            Edit
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button key={"Delete"} style={{ borderRadius: 8 }} danger type="link">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div
      className={styles.g10}
      style={{
        height: "70vh",
        overflowY: "scroll",
      }}>
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        className={styles.g10}
        style={{ alignItems: "flex-start" }}
        hasMore={hasNextPage}
        loader={
          <Row justify="center">
            <Spin />
          </Row>
        }
        useWindow={false}>
        {messagesDataLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <Table
            columns={columns}
            dataSource={messagesData?.pages?.flatMap(
              (pageItem) => pageItem.data?.product || []
            )}
            scroll={{ y: 700 }}
            pagination={false}
          />
        )}
        {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
      pageItem.data?.community?.map((dataItem) => (
        <CreatorCard creatorData={dataItem} platform={platform} />
      ))
    )} */}
      </InfiniteScroll>
      <AddMessageModal
        isVisible={messageModalProps.visible}
        isView={messageModalProps.isView}
        isEdit={messageModalProps.isEdit}
        initialData={messageModalProps.message}
        handleClose={() => {
          setMessageModalProps({
            isEdit: false,
            isView: false,
            visible: false,
            message: undefined,
          });
        }}
      />
    </div>
  );
};

export default Messages;
