import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  InputNumber,
  Row,
  Col,
  Button,
  Modal,
  Input,
  message,
  Result,
} from "antd";

import { useUserAuth } from "../../context/userAuthcontext";

import { sendOTP, validateOTP } from "../../handler/admin";
import { handleCookieChange } from "../../utils";

const CustomSignIn = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { customSignIn } = useUserAuth();
  const [state, setState] = useState({
    otp: "",
    uid: "",
    visible: false,
    op: "",
  });
  const { uid } = useParams();

  const otpMutation = useMutation(sendOTP, {
    onSuccess: (res) => {
      setState({ ...state, op: res.data.op });
      message.success("OTP sent");
    },
  });
  const validateOTPMutation = useMutation(validateOTP, {
    onSuccess: (res) => {
      customSignIn(res.data.customToken)
        .then(() => {
          handleCookieChange("");
          queryClient.removeQueries();
          navigate("/");
        })
        .catch((err) => message.error(err.message));
    },
  });

  useEffect(() => {
    if (uid) {
      return setState({ ...state, uid });
    }
    return setState({ ...state, uid: "" });
  }, [uid]);

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={
          <div>
            Sorry, the page you visited does not{" "}
            <span onClick={() => setState({ ...state, visible: true })}>
              exist.
            </span>
          </div>
        }
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
      <Modal
        visible={state.visible}
        onCancel={() => setState({ ...state, visible: false })}
        footer={
          <>
            <Button onClick={() => setState({ ...state, visible: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                console.log("state.uid", state.uid);
                otpMutation.mutate({ email: state.uid });
              }}
              disabled={!state.uid}
              loading={otpMutation.isLoading}>
              Send OTP
            </Button>
            <Button
              type="primary"
              disabled={!state.uid || !state.otp}
              onClick={() => {
                validateOTPMutation.mutate({ op: state.op, otp: state.otp });
              }}
              loading={validateOTPMutation.isLoading}>
              Sign IN
            </Button>
          </>
        }>
        <>
          <h3>Enter Brand Email ID</h3>
          <Input
            value={state.uid}
            onChange={(event) =>
              setState({ ...state, uid: event.target.value })
            }
            style={{
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: "2px",
              padding: "4px",
            }}
          />
          <div style={{ marginTop: "20px" }}>
            <h3>Enter OTP</h3>
            <InputNumber
              value={state.otp}
              onChange={(value) => setState({ ...state, otp: value || "" })}
              style={{
                width: "100%",
              }}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};

export default CustomSignIn;
