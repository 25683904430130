import axios, { AxiosResponse } from "axios";
import { auth } from "../firebase.js";
import Cookies from "universal-cookie";

import { logBackend } from "./logBackend.js";

type Client = <T = any>(
  method: string,
  url: string,
  iasAuthAPI: boolean,
  body?: any,
  options?: any,
  isV2?: boolean
) => Promise<AxiosResponse<T>>;

export const sendRequest: Client = async (
  method: string,
  url: string,
  iasAuthAPI = false,
  body?: any,
  options?: any,
  isV2 = false
) => {
  let token = "";
  token = (await auth.currentUser?.getIdToken()) || "";

  const cookies = new Cookies();
  let orgId = cookies.get("brandId");

  if (orgId) {
    options = {
      ...options,
      orgId,
    };
  }

  if (iasAuthAPI) {
    return axios({
      method,
      url: `${process.env.REACT_APP_BACKEND_URL}/v1${url}`,
      data: { ...body, idToken: token },
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        ...options,
      },
    }).catch(async (err) => {
      await logBackend(url, body, JSON.stringify(err));
      throw err;
    });
  } else {
    return axios({
      method,
      url: `${process.env.REACT_APP_BACKEND_URL}${isV2 ? "/v2" : "/v1"}${url}`,
      data: body,
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...options,
      },
    }).catch(async (err) => {
      await logBackend(url, body, JSON.stringify(err));
      throw err;
    });
  }
};
