import React from "react";
import { Collapse, Divider } from "antd";

import { HiMinus, HiPlus } from "react-icons/hi";

import styles from "../filters.module.css";

const { Panel } = Collapse;

interface FilterTileProps {
  children: React.ReactNode;
  title: string;
}
const FilterTile: React.FC<FilterTileProps> = ({ title, children }) => {
  return (
    <>
      <Collapse
        ghost
        expandIconPosition="end"
        expandIcon={(panelProps) => {
          return panelProps.isActive ? <HiMinus /> : <HiPlus />;
        }}
        defaultActiveKey={["Configure"]}>
        <Panel header={<p className={styles.title}>{title}</p>} key={title}>
          {children}
        </Panel>
      </Collapse>
      <Divider style={{ marginTop: "4px" }} />
    </>
  );
};

export default FilterTile;
