import React from "react";
import { Button, Row, Col } from "antd";

interface FooterProps {
  onNext: () => void;
  onBack: () => void;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  isType?: string;
}

const Footer: React.FC<FooterProps> = ({
  onNext,
  onBack,
  isBackDisabled = false,
  isNextDisabled = false,
  isType,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 999,
        background: "#FFFFFF",
      }}>
      <Row
        style={{
          borderTop: "2px solid rgba(0, 0, 0, 0.06)",
          bottom: 0,
          padding: "16px 0 28px",
          width: "100vw",
        }}>
        <Col offset={8} span={8}>
          <Row justify="space-between">
            <Button
              style={{ padding: "8px 20px", borderRadius: "4px" }}
              size="large"
              onClick={onBack}
              disabled={isBackDisabled}>
              Back
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ padding: "8px 20px", borderRadius: "4px" }}
              onClick={onNext}
              disabled={isNextDisabled}>
              {isType ? isType : "Next"}
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
