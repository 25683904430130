import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import { message, Modal, Row, Col, Input, Spin, Space, Checkbox } from "antd";
import { AxiosError } from "axios";

import AddMessageModal from "./AddMessage";
import CustomInput from "../../shared/Custom/Input";

import { Catalogue, MessageReply } from "../../../typings";

import {
  getProducts,
  createCatalogue,
  editCatalogue,
  getCatalogues,
} from "../../../handler/replies";

import { trimData, getTrimmedLength } from "../../../utils";

import globalStyles from "../workflows.module.css";
import ReportButton from "../../Campaign/Report/ReportButton";

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  initialData?: Catalogue;
  isView?: boolean;
  isEdit?: boolean;
}

const AddCatalogue: React.FC<Props> = ({
  isVisible,
  handleClose,
  initialData,
  isEdit,
  isView,
}) => {
  const queryClient = useQueryClient();
  const [addMessageModal, setAddMessageModal] = useState<{
    visible: boolean;
    messageV: MessageReply | undefined;
  }>({ visible: false, messageV: undefined });

  const [userInput, setUserInput] = useState<Catalogue>({
    name: "",
    products: [],
  });

  const [getCaloguesKey] = getCatalogues({ limit: 20 });

  const [userInputErr, setUserInputErr] = useState<{
    name: string;
    products: string;
  }>({
    name: "",
    products: "",
  });

  const [messageQuery, messageFn] = getProducts({ limit: 100 });

  // const { data: messageData, isLoading: messageDataLoading } = useQuery(
  //   messageQuery,
  //   messageFn,
  //   {
  //     enabled: isVisible,
  //   }
  // );

  const {
    data: messagesData,
    isLoading: messagesDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(messageQuery, messageFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.product.length ? lastPage.data.pageNo + 1 : null,
    enabled: isVisible,
  });

  const resetForm = () => {
    setUserInput({
      name: "",
      products: [],
    });
    setUserInputErr({
      name: "",
      products: "",
    });
  };

  useEffect(() => {
    if (!isVisible) {
      return resetForm();
    }
    if (isVisible && initialData?.name) {
      return setUserInput(initialData);
    }
  }, [isVisible]);

  const isItemChecked = (item: MessageReply) => {
    return (
      userInput?.products?.findIndex((uPItem) => uPItem?._id === item?._id) !==
      -1
    );
  };

  const handleCheck = (item: MessageReply) => {
    let oldProducts = userInput?.products || [];

    if (isItemChecked(item)) {
      oldProducts = oldProducts.filter((pItem) => pItem._id !== item?._id);
    } else {
      oldProducts = [...oldProducts, item];
    }

    setUserInput((uInput) => ({ ...uInput, products: oldProducts }));
  };

  const isDataValid = () => {
    let isError = false;

    let errorObj = userInputErr;

    if (getTrimmedLength(userInput?.name) <= 0) {
      isError = true;
      errorObj = { ...errorObj, name: "Please Enter a Title" };
    } else {
      errorObj = { ...errorObj, name: "" };
    }
    if (userInput?.products?.length <= 0) {
      isError = true;
      errorObj = { ...errorObj, products: "Please Select messages" };
    } else {
      errorObj = { ...errorObj, products: "" };
    }

    setUserInputErr(errorObj);

    return !isError;
  };

  const createCatalogueMutation = useMutation(createCatalogue, {
    onSuccess: () => {
      message.success("Catalogue Created");
      queryClient.invalidateQueries(getCaloguesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  const editCatalogueMutation = useMutation(editCatalogue, {
    onSuccess: () => {
      message.success("Catalogue Edited");
      queryClient.invalidateQueries(getCaloguesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  return (
    <Modal
      width={1000}
      title={null}
      footer={null}
      bodyStyle={{
        borderRadius: "8px",
        padding: 28,
        minHeight: "550px",
      }}
      onCancel={() => {
        handleClose();
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={globalStyles.heading}>
          {isView ? "View" : isEdit ? "Edit" : "Add "} Catalogue
        </p>
      </Row>
      <Col
        span={16}
        style={{
          display: "grid",
          gap: "12px",
          // height: "340px",
          // overflowY: "scroll",
        }}>
        <CustomInput
          label="Title"
          error={userInputErr?.name}
          children={
            <Input
              disabled={isView}
              value={userInput.name}
              onChange={(e) =>
                setUserInput((userInput) => ({
                  ...userInput,
                  name: e.target.value || "",
                }))
              }
              size="large"
            />
          }
        />
        <div
          style={{
            display: "grid",
            gap: "20px",
          }}>
          <p>Select the Messages you want to add to catalogue</p>
          {messagesDataLoading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            <>
              {messagesData?.pages?.flatMap(
                (pageItem) => pageItem.data?.product || []
              ).length === 0 ? (
                <>
                  <h4>No Message Created Yet </h4>
                  <Row justify="center">
                    <ReportButton
                      type="primary"
                      onClick={() =>
                        setAddMessageModal({
                          visible: true,
                          messageV: undefined,
                        })
                      }
                      disabled={isView}>
                      Create a Message
                    </ReportButton>
                  </Row>
                </>
              ) : (
                <ol
                  style={{
                    display: "grid",
                    gap: "5px",
                    height: "300px",
                    overflowY: "scroll",
                  }}>
                  {messagesData?.pages
                    ?.flatMap((pageItem) => pageItem.data?.product || [])
                    .map((pItem) => (
                      <Row justify="space-between" key={pItem?._id}>
                        <Checkbox
                          disabled={isView}
                          onClick={() => handleCheck(pItem)}
                          checked={isItemChecked(pItem)}>
                          {" "}
                          <Space size={4} align="center">
                            {pItem?.image_url && (
                              <img
                                src={pItem?.image_url}
                                className={globalStyles.profile}
                                style={{ width: 20, height: 20 }}
                                alt="profile"
                              />
                            )}
                            <p className={globalStyles.name}>
                              {trimData({
                                value: pItem?.title || "",
                                limit: 50,
                              })}
                            </p>
                          </Space>
                        </Checkbox>
                        <ReportButton
                          type="link"
                          onClick={() =>
                            setAddMessageModal({
                              visible: true,
                              messageV: pItem,
                            })
                          }>
                          View
                        </ReportButton>
                      </Row>
                    ))}
                </ol>
              )}
              {userInputErr?.products && (
                <p className={globalStyles?.errorInput}>
                  {userInputErr?.products}
                </p>
              )}
            </>
          )}
        </div>
        <ReportButton
          type="primary"
          style={{ width: "max-content" }}
          loading={
            editCatalogueMutation.isLoading || createCatalogueMutation.isLoading
          }
          onClick={() => {
            if (isView) {
              return handleClose();
            }
            if (isDataValid()) {
              if (isEdit) {
                return editCatalogueMutation.mutate({
                  catalogueId: userInput?._id || "",
                  name: userInput?.name || "",
                  productIds: userInput?.products?.map(
                    (pItem) => pItem?._id || ""
                  ),
                });
              }
              return createCatalogueMutation.mutate({
                name: userInput?.name || "",
                productIds: userInput?.products?.map(
                  (pItem) => pItem?._id || ""
                ),
              });
            }

            return message.error("Please Recheck the form");
          }}>
          {isView ? "Close" : "Submit"}
        </ReportButton>
      </Col>
      <AddMessageModal
        isVisible={addMessageModal.visible}
        handleClose={() =>
          setAddMessageModal({ visible: false, messageV: undefined })
        }
        initialData={addMessageModal.messageV}
        isView={Boolean(addMessageModal.messageV)}
      />
    </Modal>
  );
};

export default AddCatalogue;
