import { sendRequest } from "../helper";
import { FormData } from "./../../components/Billing/BillingSettings/Modals/Details";

export type Props = {
  data: FormData;
};

export const updateBillingSettings = async ({ data }: Props) => {
  const res = await sendRequest("put", `/brand/billing`, false, data);
  return res.data;
  //   return [queryKey, queryFn];
};
