import React, { ReactNode, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  Divider,
  Button,
  Menu,
  Dropdown,
  message,
  Row,
  Col,
  Checkbox,
  Tag,
  Popconfirm,
  Input,
  InputNumber,
  Badge,
} from "antd";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nf from "@tuplo/numberfmt";

import CreatorProfileImg from "../CreatorProfileImg";

import { RejectionModal, FinalizeModal, OfferModal } from "./Modals";

import {
  shortlistOffer,
  rejectOffer,
  finalizeOffer,
  withdrawOffer,
  sendOffer,
  inviteInfluencerCustomOffer,
} from "../../../handler/campaign/offer";
import { getUser } from "../../../handler/getUser";

import { Applicant } from "../../../typings";

import { getButtonTexts } from "./utils";

import styles from "./CreatorCard.module.css";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

const panelVariants = {
  visible: {
    height: "initial",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const RibbonHoc = ({
  enable,
  type,
  children,
}: {
  enable: boolean;
  type: string;
  children: ReactNode;
}) => {
  if (enable) return <Badge.Ribbon text={type}>{children}</Badge.Ribbon>;

  return <>{children}</>;
};

interface CreatorCardProps {
  type: "appRec" | "shortList" | "invite" | "reject";
  applicantData: Applicant;
  isCardChecked?: boolean;
  handleCheck?: (id: string) => void;
  isDisabled?: boolean;
  isProcessing: boolean;
  isCheckable: boolean;
  isSearch?: boolean;
}

const CreatorCard: React.FC<CreatorCardProps> = ({
  type,
  applicantData,
  isCardChecked = false,
  handleCheck,
  isDisabled = false,
  isProcessing,
  isCheckable,
  isSearch = false,
}) => {
  const appRecMenu = (
    <Menu
      items={[
        {
          key: "2",
          label: (
            <p
              onClick={() =>
                navigate(`/chat?influencerId=${applicantData.influencerId}`)
              }
            >
              Chat
            </p>
          ),
        },
      ]}
      onClick={(event) => {
        event.domEvent.stopPropagation();
      }}
    />
  );

  const shortListMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() =>
                navigate(`/chat?influencerId=${applicantData.influencerId}`)
              }
            >
              Chat
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p onClick={() => setRejectionModalToggle(true)}>Not Interested</p>
          ),
        },
      ]}
      onClick={(event) => {
        event.domEvent.stopPropagation();
      }}
    />
  );
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { primaryButtonText, secondaryButtonText } = getButtonTexts(type);

  const [toggle, setToggle] = useState<boolean>(false);
  const [inputPrice, setInputPrice] = useState<number>(0);

  const [getUserKey, getUserFn] = getUser();
  const { data: userData } = useQuery(getUserKey, getUserFn);

  const [rejectionModalToggle, setRejectionModalToggle] =
    useState<boolean>(false);

  const [offerToggle, setOfferToggle] = useState<boolean>(false);

  //mutations
  const shortlistOfferMutation = useMutation(shortlistOffer, {
    onSuccess: () => {
      queryClient
        .invalidateQueries("applicants")
        .then(() => message.success("Creator moved to shortlisted bucket "));
      queryClient.invalidateQueries(["campaigns", "count"]);
    },
    onError: () => {
      message.error("Some error occurred. Please try again");
    },
  });
  const reInviteInfluencerMutation = useMutation(inviteInfluencerCustomOffer, {
    onSuccess: () => {
      setInputPrice(0);
      queryClient
        .invalidateQueries("applicants")
        .then(() => message.success("Creator moved to Invited Tab "));
      queryClient.invalidateQueries(["campaigns", "count"]);
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });
  const withdrawOfferMutation = useMutation(withdrawOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries("applicants");
      queryClient.invalidateQueries(["campaigns", "count"]);
      message.info("Offer Withdrawn");
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  const rejectOfferMutation = useMutation(rejectOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries("applicants");
      queryClient.invalidateQueries(["campaigns", "count"]);
      setRejectionModalToggle(false);
      message.warning("Applicant rejected");
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });
  const sendOfferMutation = useMutation(sendOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries("applicants");
      queryClient.invalidateQueries(["campaigns", "count"]);
      setOfferToggle(false);
      message.success("Offer Sent");
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  //handlers
  const RejectionSubmit = (desc: string) => {
    rejectOfferMutation.mutate({ offerId: applicantData._id, reason: desc });
  };
  const RejectionCancel = () => {
    //  caal a cool api
    setRejectionModalToggle(false);
  };

  // const FinalizeSubmit = () => {
  //   finalizeOfferMutation.mutate({ offerId: applicantData._id });
  //   //  caal a cool api
  // };
  // const FinalizeCancel = () => {
  //   //  caal a cool api
  //   setFinalizeToggle(false);
  // };

  const offerSubmit = (price: number) => {
    sendOfferMutation.mutate({
      offerId: applicantData._id,
    });
    //  caal a cool api

    setOfferToggle(false);
  };
  const offerCancel = () => {
    //  caal a cool api
    setOfferToggle(false);
  };

  const getPrimaryButtonAction = () => {
    switch (type) {
      case "appRec":
        return shortlistOfferMutation.mutate({ offerId: applicantData._id });
      case "shortList":
        return;
      case "reject":
        return navigate(`/chat?influencerId=${applicantData.influencerId}`);
      case "invite":
        return navigate(`/chat?influencerId=${applicantData.influencerId}`);
    }
  };

  const getSecondaryButtonAction = () => {
    switch (type) {
      case "appRec":
        return setRejectionModalToggle(true);
      case "shortList":
        return;
      case "invite":
        return withdrawOfferMutation.mutate({ offerId: applicantData._id });
      case "reject":
        return sendOfferMutation.mutate({
          offerId: applicantData._id,
        });
    }
  };

  const contentList = applicantData
    ? applicantData.platform === "instagram"
      ? applicantData.socialMediaKit[0]?.instagram?.content.medias
      : applicantData.socialMediaKit[0]?.youtube?.content.medias.map((item) => {
          return { ...item };
        }) || []
    : [];

  return (
    <RibbonHoc
      enable={isSearch}
      type={type}
      children={
        <section className={styles.container}>
          {type === "reject" && (
            <p className={styles.rejectionHeading}>
              Reason for rejection: {""}
              <span style={{ fontWeight: 400 }}>{applicantData.remarks}</span>
            </p>
          )}

          <div className={styles.cardHeading}>
            <div className={styles.creatorHeading}>
              {type === "shortList" && (
                <Checkbox
                  checked={isCardChecked}
                  onChange={() => {
                    if (handleCheck) {
                      handleCheck(applicantData._id);
                    }
                  }}
                  disabled={!isCheckable}
                />
              )}
              {applicantData.socialMediaKit.length > 0 && (
                <CreatorProfileImg
                  influencerID={applicantData.influencer[0]._id}
                  // imageURl={
                  //   applicantData.platform === "youtube"
                  //     ? applicantData.socialMediaKit[0].youtube?.user
                  //         .profilePicUrl || ""
                  //     : applicantData.socialMediaKit[0].instagram?.user
                  //         .profilePicUrl || ""
                  // }
                  imageURl={applicantData.influencer[0].profilePicUrl}
                />
              )}
              <div>
                <p className={styles.title}>
                  {applicantData?.influencer[0]?.name + " "}
                  <span style={{ textTransform: "capitalize" }}>
                    {applicantData?.influencer[0]?.lastname}
                  </span>
                </p>
                {/* <p className={styles.description}>
          @{applicantData?.socialMediaKit[0]?.socialMediaName}
        </p> */}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`/assets/img/${applicantData?.platform}.png`}
                    alt="platform"
                    style={{ width: "16px", height: "16px" }}
                  />

                  <a
                    href={
                      applicantData?.platform === "instagram"
                        ? `https://www.instagram.com/${applicantData?.socialMediaKit[0]?.socialMediaName}`
                        : `https://www.youtube.com/channel/${applicantData?.socialMediaKit[0]?.socialMediaId}`
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.creatorUsername}
                  >
                    @{applicantData?.socialMediaKit[0]?.socialMediaName}
                  </a>
                </div>
              </div>
            </div>

            {applicantData.platform === "youtube" &&
            applicantData.socialMediaKit[0].youtube ? (
              <Row style={{ maxWidth: "340px" }} gutter={[0, 12]}>
                <Col className={styles.metricCard} span={8}>
                  <p className={styles.title}>
                    {" "}
                    {nf(
                      applicantData.socialMediaKit[0].youtube
                        .youtubeSubscriberCount,
                      "a",
                      { locale: "en-US" }
                    )}
                  </p>
                  <p className={styles.description}>SubScribers</p>
                </Col>
                {/* <Col className={styles.metricCard} span={8}>
          <p className={styles.title}>
            {" "}
            {nf(
              applicantData.socialMediaKit[0].youtube.content.medias.length,
              "a",
              { locale: "en-US" }
            )}
          </p>
          <p className={styles.description}>Total Videos</p>
        </Col> */}
                {/* <Col className={styles.metricCard} span={8}>
          <p className={styles.title}>
            {" "}
            {nf(
              applicantData.socialMediaKit[0].youtube.content.aggregate
                .engagement / 100,
              "0.00%"
            )}
          </p>
          <p className={styles.description}>Eng. Rate</p>
        </Col> */}

                {/* <Col span={24}>
          <AnimatePresence>
            {toggle && (
              <motion.section
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={panelVariants}>
                <Row gutter={[0, 12]}>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].youtube.content
                          .aggregate.avgLikes,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg Likes</p>
                  </Col>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].youtube.content
                          .aggregate.avgComments,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg. Comments</p>
                  </Col>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].youtube.content
                          .aggregate.avgReach,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg. Reach</p>
                  </Col>
                </Row>
              </motion.section>
            )}
          </AnimatePresence>
        </Col> */}
              </Row>
            ) : applicantData.platform === "instagram" &&
              applicantData.socialMediaKit[0]?.instagram ? (
              <Row style={{ maxWidth: "340px" }} gutter={[0, 12]}>
                <Col className={styles.metricCard} span={8}>
                  <p className={styles.title}>
                    {" "}
                    {nf(
                      applicantData?.socialMediaKit[0]?.instagram
                        .instagramFollowerCount,
                      "a",
                      { locale: "en-US" }
                    )}
                  </p>
                  <p className={styles.description}>Followers</p>
                </Col>
                {/* <Col className={styles.metricCard} span={8}>
          <p className={styles.title}>
            {" "}
            {nf(
              applicantData.socialMediaKit[0].instagram.content.medias
                .length,
              "a",
              { locale: "en-US" }
            )}
          </p>
          <p className={styles.description}>Total Feed</p>
        </Col> */}
                {/* <Col className={styles.metricCard} span={8}>
          <p className={styles.title}>
            {" "}
            {nf(
              applicantData.socialMediaKit[0].instagram.content.aggregate
                .engagement / 100,
              "0.00%"
            )}
          </p>
          <p className={styles.description}>Eng. Rate</p>
        </Col> */}
                {/* <Col span={24}>
          <AnimatePresence>
            {toggle && (
              <motion.section
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={panelVariants}>
                <Row gutter={[0, 12]}>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].instagram.content
                          .aggregate.avgLikes,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg Likes</p>
                  </Col>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].instagram.content
                          .aggregate.avgComments,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg. Comments</p>
                  </Col>
                  <Col className={styles.metricCard} span={8}>
                    <p className={styles.title}>
                      {" "}
                      {nf(
                        applicantData.socialMediaKit[0].instagram.content
                          .aggregate.avgReach,
                        "a",
                        { locale: "en-US" }
                      )}
                    </p>
                    <p className={styles.description}>Avg. Reach</p>
                  </Col>
                </Row>
              </motion.section>
            )}
          </AnimatePresence>
        </Col> */}
              </Row>
            ) : (
              <div />
            )}
          </div>

          <Divider style={{ margin: 0 }} />
          <AnimatePresence>
            {toggle && (
              <motion.section
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={panelVariants}
                style={{ padding: "4px", margin: "12px 0", maxWidth: "48vw" }}
              >
                <div style={{ maxWidth: "48vw" }}>
                  <Slider
                    className="slider"
                    speed={500}
                    slidesToShow={4}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}
                  >
                    {contentList?.map((item, i) => {
                      return (
                        <section className={styles.imageContainer} key={i}>
                          <>
                            {/* {isVideo(item.type) ? (
                      <div style={{ height: "150px" }}>
                        <p style={{ opacity: "0" }}>a</p>
                        <ReactPlayer
                          url={item.url}
                          volume={1}
                          width="100%"
                          height="100%"
                          onReady={() => console.log("ready nodw")}
                          light={true}
                        />
                        <p style={{ opacity: "0" }}>a</p>
                      </div>
                    ) : ( */}
                            <img
                              src={item.thumbnailUrl}
                              alt="carousel"
                              style={{ objectFit: "cover" }}
                              className={styles.carouselImage}
                            />

                            {/* )} */}
                          </>
                        </section>
                      );
                    })}
                  </Slider>
                </div>
              </motion.section>
            )}
          </AnimatePresence>

          <div className={styles.panelHeader}>
            <div />
            {/* <Button
      type="link"
      onClick={() => setToggle((toggle) => !toggle)}
      style={{
        padding: 0,
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}>
      {`See ${toggle ? "less" : "more"}`}
      <img
        src={`/assets/icons/drop${!toggle ? "down" : "up"}.svg`}
        alt="dropdown icon"
      />
    </Button> */}
            <span className={styles.price}>
              Commercials: ₹{nf(applicantData.brandPrice, "0,0.00")}
            </span>
            <div>
              {userData?.data?.accountType === "Managed" && (
                <span
                  className={styles.price}
                  style={{ color: "green", fontSize: "14px" }}
                >
                  Influencer Cost: ₹
                  {nf(applicantData.influencerPrice, "0,0.00")}
                </span>
              )}

              {/* {applicantData.isWithDrawn && (
          <Button style={{ marginLeft: "12px" }} disabled>
            Withdrawn
          </Button>
        )}
        {applicantData.isOfferResent && (
          <Button style={{ marginLeft: "12px" }} disabled>
            Offer Resent
          </Button>
        )} */}

              {!isDisabled && !isProcessing && type !== "shortList" && (
                <>
                  {type === "reject" ? (
                    <Popconfirm
                      title={
                        <>
                          <p>Enter Influencer Price </p>
                          <InputNumber
                            value={inputPrice}
                            onChange={(val) => setInputPrice(val || 0)}
                          />
                        </>
                      }
                      okButtonProps={{ disabled: inputPrice < 0 }}
                      onConfirm={() =>
                        reInviteInfluencerMutation.mutateAsync({
                          brandPrice: inputPrice,
                          campaignId: applicantData?.campaignId,
                          influencerPrice: inputPrice,
                          socialMediaKitId: applicantData?.socialMediaKitId,
                        })
                      }
                    >
                      <Button
                        size="middle"
                        style={{ marginLeft: "24px" }}
                        // onClick={() => getSecondaryButtonAction()
                        // }
                      >
                        {secondaryButtonText}
                      </Button>
                    </Popconfirm>
                  ) : (
                    <>
                      <Button
                        size="middle"
                        style={{ marginLeft: "24px" }}
                        onClick={() => getSecondaryButtonAction()}
                      >
                        {secondaryButtonText}
                      </Button>
                    </>
                  )}

                  <Button
                    type="primary"
                    size="middle"
                    style={{ marginLeft: "8px", width: "140px" }}
                    onClick={() => getPrimaryButtonAction()}
                  >
                    {primaryButtonText}
                  </Button>
                </>
              )}
              {isProcessing && (
                <Tag color="orange" style={{ marginLeft: "16px" }}>
                  Processing
                </Tag>
              )}
              {type !== "reject" && (
                <Dropdown
                  overlay={type === "appRec" ? appRecMenu : shortListMenu}
                  placement="bottomLeft"
                  disabled={isCardChecked}
                >
                  <img
                    src="/assets/icons/options.svg"
                    alt="options icons"
                    style={{ marginLeft: "12px", cursor: "pointer" }}
                  />
                </Dropdown>
              )}
            </div>
          </div>

          <RejectionModal
            handleCancel={RejectionCancel}
            handleSubmit={RejectionSubmit}
            isVisible={rejectionModalToggle}
          />
          {/* <FinalizeModal
    handleCancel={FinalizeCancel}
    handleSubmit={FinalizeSubmit}
    isVisible={finalizeToggle}
    showNote={true}
    balance={5000}
    phoneNumber={"+91 7889561310"}
  /> */}
          <OfferModal
            handleCancel={offerCancel}
            handleSubmit={offerSubmit}
            isVisible={offerToggle}
            applicant={{ price: applicantData.price, _id: applicantData._id }}
          />
        </section>
      }
    />
  );
};

export default CreatorCard;
