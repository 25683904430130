import { Row, Col, Space, Button, Menu } from "antd";
import { useState } from "react";

import { RightOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import {
  ClockCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import Navbar from "../shared/Navbar";
import PersonalDetails from "./PersonalDetails";
import BusinessDetails from "./BusinessDetails";
import BusinessVerification from "./BusinessVerification";
import Sent from "../Invitations/Sent";
import Modals from "../Onboarding-1/Modals";
import EmailConfig from "./EmailConfig";

import styles from "./profile.module.css";

import { getUser } from "../../handler/getUser";

const Profile = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const [getUserkey, getUserFn] = getUser();
  const { data: userData } = useQuery(getUserkey, getUserFn);
  const [sectionSelected, setSectionSelected] = useState<
    "Personal" | "Business" | "Verification" | "Invitation" | "Email"
  >("Personal");

  return (
    <div>
      <Navbar selectedItem="other" />
      <Row
        justify="center"
        style={{
          paddingTop: "43px",
          minHeight: "100vh",
          background: "#FFFFFF",
        }}>
        <Col span={16}>
          {userData?.data.kyc.status === "Not-Initiated" ? (
            <>
              <p className={styles.heading}>Your Profile</p>
              <Row
                style={{
                  background: "##F5F5F5",
                  border: "2px solid rgba(0, 0, 0, 0.04)",
                }}>
                <Col span={24} style={{ minHeight: "60vh" }}>
                  <Row align="middle" style={{ height: "100%" }}>
                    <Space
                      direction="vertical"
                      align="center"
                      style={{ width: "400px", margin: "0 auto" }}>
                      <p className={styles.noKycHeading}>
                        Account not activated yet
                      </p>
                      <p className={styles.noKycDesc}>
                        You’re logged in with {userData.data.emailId}. Fill in
                        the your personal and business details to activate your
                        account.
                      </p>
                      <Button
                        onClick={() => setVisible(true)}
                        style={{
                          marginTop: "12px",
                          backgroundColor: "#533af5 !important",
                        }}
                        type="primary">
                        Activate account
                      </Button>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Modals
                isVisible={visible}
                setVisible={setVisible}
                isFirstTime={false}
              />
            </>
          ) : (
            <div className={styles.outerContainer}>
              <Row justify="space-between">
                <p className={styles.heading}>Settings</p>
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                    color:
                      userData?.data.kyc.status === "Under-Review"
                        ? "#FF8F1F"
                        : userData?.data.kyc.status === "Needs-Attention"
                        ? "#FF3141"
                        : "#00B578",
                  }}>
                  {userData?.data.kyc.status === "Under-Review" ? (
                    <ClockCircleOutlined />
                  ) : userData?.data.kyc.status === "Needs-Attention" ? (
                    <CloseOutlined />
                  ) : (
                    <CheckOutlined />
                  )}
                  <p>
                    {userData?.data.kyc.status === "Under-Review"
                      ? "Account verification pending"
                      : userData?.data.kyc.status === "Needs-Attention"
                      ? "Account verification failed"
                      : "Account verified"}
                  </p>
                </div>
              </Row>
              <div>
                <Menu
                  defaultSelectedKeys={["Personal"]}
                  selectedKeys={[sectionSelected]}
                  mode="horizontal"
                  style={{ position: "relative" }}
                  onSelect={(e) => {
                    setSectionSelected(
                      e.key as
                        | "Personal"
                        | "Business"
                        | "Verification"
                        | "Invitation"
                        | "Email"
                    );
                  }}>
                  <Menu.Item key={"Personal"}> Personal Details</Menu.Item>
                  <Menu.Item key={"Business"}> Business Details</Menu.Item>
                  {/* <Menu.Item key={"Verification"}>
                    {" "}
                    Verification Details
                  </Menu.Item> */}
                  <Menu.Item key={"Invitation"}> Invite Team</Menu.Item>
                  <Menu.Item key={"Email"}>E-mail</Menu.Item>
                </Menu>
              </div>
              <div style={{ padding: "50px 10px" }}>
                {sectionSelected === "Personal" ? (
                  <PersonalDetails />
                ) : sectionSelected === "Business" ? (
                  <BusinessDetails />
                ) : sectionSelected === "Invitation" ? (
                  <Sent isTeam />
                ) : (
                  <EmailConfig />
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const MenuTile = ({
  isSelected,
  label,
  onClick,
}: {
  isSelected: boolean;
  label: string;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        background: isSelected ? "#2130FF" : "transparent",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: isSelected ? 500 : 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: isSelected ? " #FFFFFF" : "rgba(0, 0, 0, 0.85)",
        alignItems: "center",
        padding: "5px 12px 5px 16px",
      }}
      onClick={onClick}>
      {label}
      {isSelected && <RightOutlined color=" #FFFFFF" />}
    </div>
  );
};

export default Profile;
