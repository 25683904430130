import { sendRequest } from "../helper";

interface PutPinnedMetrics {
  platform: "instagram" | "youtube";
  pinnedFields: string[];
}

export const updatePinnedMetrics = async ({
  platform,
  pinnedFields,
}: PutPinnedMetrics) => {
  const res = await sendRequest("put", `/brand/report-only/pinned`, false, {
    platform,
    pinnedFields,
  });
  return res.data;
};
