import React from "react";
import { Popover } from "antd";

interface Props {
  children: React.ReactNode;
  isDisabled: boolean;
  customText: string;
}

const DisabledPopOver: React.FC<Props> = ({
  isDisabled,
  children,
  customText,
}) => {
  if (!isDisabled) return <React.Fragment>{children}</React.Fragment>;
  return (
    <Popover content={customText} title={null} trigger="hover" placement="top">
      <div>{children}</div>
    </Popover>
  );
};

export default DisabledPopOver;
