import { Drawer, Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import styles from "./CampaignDrawer.module.css";
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { getCampaign } from "../../../handler/campaign";

import { getFollowerRange } from "./utils/getFollowerRange";
import nf from "@tuplo/numberfmt";

interface CampaignDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

const CampaignDrawer: React.FC<CampaignDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  id,
}) => {
  // Get Brand data by id
  const [getCampaignKey, getCampaignFn] = getCampaign({ campaignId: id });
  const { data: campaignData, isLoading } = useQuery(
    getCampaignKey,
    getCampaignFn,
    {
      enabled: openDrawer,
    }
  );

  return (
    <>
      <Drawer
        width={"40vw"}
        title="Campaign Details"
        extra={
          <div className={styles.extra}>
            <p className={styles.status}>
              {campaignData?.data.campaign.status === "Under-Review" && (
                <>
                  <span className="">
                    <ClockCircleOutlined style={{ color: "#FF8F1F" }} />
                  </span>{" "}
                  <span style={{ color: "#FF8F1F" }}>Review Pending</span>
                </>
              )}
              {campaignData?.data.campaign.status === "Active" && (
                <>
                  <span>
                    <CheckOutlined style={{ color: "#00B578" }} />
                  </span>{" "}
                  <span style={{ color: "#00B578" }}>Approved</span>
                </>
              )}
              {campaignData?.data.campaign.status === "Needs-Attention" && (
                <>
                  <span>
                    <CloseOutlined style={{ color: "#FF3141" }} />
                  </span>{" "}
                  <span style={{ color: "#FF3141" }}>Not Verified</span>
                </>
              )}
            </p>
          </div>
        }
        visible={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        {openDrawer && (
          <>
            {isLoading ? (
              <Skeleton avatar paragraph={{ rows: 16 }} />
            ) : (
              <div className={styles.container}>
                <div className={styles.banner}>
                  <div className={styles.bannerImageContainer}>
                    {campaignData?.data?.campaign?.campaignBannerUrl && (
                      <img
                        className={styles.bannerImage}
                        src={campaignData?.data.campaign?.campaignBannerUrl}
                        alt="Banner"
                      />
                    )}
                  </div>
                </div>
                <div className={styles.header}>
                  <div className="">
                    {/* <img
                      src={campaignData?.data.brand.businessDetails?.logoUrl}
                      alt="Brand Logo"
                    /> */}
                    <div className="">
                      <p className={styles.campaignName}>
                        {campaignData?.data?.campaign?.campaignName}
                      </p>
                    </div>
                  </div>
                  <img
                    src={`/assets/img/${campaignData?.data.campaign.platform}.png`}
                    alt=""
                  />
                </div>

                <div className={styles.communityAndFollower}>
                  <div className={styles.community}>
                    <p>Community</p>
                    <div className={styles.communities}>
                      {campaignData?.data.campaign.filter.niche?.map(
                        (community, idx) => (
                          <p>{community}</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.follower}>
                    <p>Follower Range</p>
                    <div className={styles.ranges}>
                      {campaignData?.data.campaign.filter.followerCategory
                        ?.filter((item) => item !== "all")
                        .map((range, idx) => (
                          <p key={idx}>
                            {range} {getFollowerRange(range)}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.productDetails}>
                  {campaignData?.data.deal.isProduct ? (
                    <>
                      <p>Products</p>
                      <div className={styles.products}>
                        {campaignData?.data.deal.productDetails?.map(
                          (product, idx) => (
                            <div key={idx} className={styles.product}>
                              <span>•</span>
                              <div className="">
                                <p>{product.productTitle}</p>
                                <p>₹ {nf(product.price, "0,0.00")}/-</p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <p>What are you promoting?</p>
                      <div className={styles.promotion}>
                        <p>{campaignData?.data.deal?.promotionDescription}</p>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.deliverableDetails}>
                  <p>Deliverables</p>
                  <div className={styles.deliverables}>
                    {campaignData?.data.deal.deliverables?.map(
                      (deliverable, idx) => (
                        <div key={idx} className={styles.deliverable}>
                          <span>•</span>
                          <div className="">
                            <p className={styles.deliverableName}>
                              {deliverable?.platform === "youtube"
                                ? `${deliverable.duration} min ${deliverable.type} Video`
                                : deliverable?.repost
                                ? `Repost ${deliverable.typeDel}`
                                : deliverable?.typeDel === "Video" ||
                                  deliverable?.typeDel === "Reel"
                                ? `${deliverable.duration}s ${deliverable.typeDel}`
                                : deliverable?.typeDel === "Post"
                                ? `${deliverable.type} Image Post`
                                : deliverable?.type === "Video"
                                ? `${deliverable.duration}s Video Story`
                                : "Image Story"}
                            </p>
                            {deliverable?.typeDel !== "Story" &&
                              deliverable?.platform !== "youtube" &&
                              deliverable?.contentRights && (
                                <p className={styles.deliverableDescription}>
                                  Content Rights: {deliverable?.contentRights}
                                </p>
                              )}
                            {deliverable?.platform !== "youtube" &&
                              deliverable?.link && (
                                <p className={styles.deliverableDescription}>
                                  Swipe Up Link: {deliverable.link}
                                </p>
                              )}

                            {deliverable?.products &&
                              deliverable.products.length !== 0 && (
                                <>
                                  <p className={styles.deliverableDescription}>
                                    Products
                                  </p>
                                  <div className={styles.products}>
                                    {deliverable.products?.map(
                                      (product, idx) => (
                                        <div
                                          key={idx}
                                          className={styles.productDescription}>
                                          <span>•</span>
                                          <div className="">
                                            <p>
                                              {
                                                campaignData.data.deal.productDetails.find(
                                                  (item) => item._id === product
                                                )?.productTitle
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}

                            <p className={styles.deliverableDescription}>
                              Brief: {deliverable?.description}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={styles.guidelinesDetails}>
                  <p>Campaign Guidelines</p>
                  <div className="">
                    <p className={styles.guidelines}>
                      {campaignData?.data.campaign?.brandGuidelines}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

export default CampaignDrawer;
