import { sendRequest } from "../helper";

export type InfluencerResult = {
  status: string | null;
  message: string | null;
  data: {
    user: {
      biography: string;
      id: string;
      ig_id: number;
      followers_count: number;
      follows_count: number;
      media_count: number;
      name: string;
      profile_picture_url: string;
      username: string;
      website: string;
      [key: string]: any;
    };
    lastUpdatedDateTime: string;
  };
};

export const getIgAnalytics = (influencerId: string) => {
  const queryKey = ["analyze", "influencer", influencerId, "instagram"];
  const queryFunction = () =>
    sendRequest<InfluencerResult>(
      "get",
      `/brand/brand-influencer/media-kit/instagram/user?socialMediaId=${influencerId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
