import { sendRequest } from "../../helper";

import { WorkFlowDeliverable } from "../../../typings";

interface getWorkFlowDeliverablesProps {
  campaignId?: string;
  finalizedOfferId?: string;
  deliverableId?: string;
}

interface getWorkFlowDeliverablesResponse {
  status: string;
  message: string;
  data: WorkFlowDeliverable[];
}

export const getWorkFlowDeliverables = ({
  campaignId,
  finalizedOfferId,
  deliverableId,
}: getWorkFlowDeliverablesProps) => {
  const queryKey = ["tester", "applicants", campaignId, "reqs"];

  const queryFunction = () =>
    sendRequest<getWorkFlowDeliverablesResponse>(
      "get",
      "/brand/execution/deliverables/?" +
        `${
          (finalizedOfferId !== undefined
            ? "finalizedOfferId=" + finalizedOfferId
            : "campaignId=" + campaignId) +
          (deliverableId ? "&deliverableId=" + deliverableId : "")
        }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
