import { Campaign } from "../../typings";
import { sendRequest } from "../helper";

export type deleteCampaignProps = {
  campaignId: string;
};

export type DeleteCampaignResponse = {
  status: string;
  message: string;
  data: Campaign;
};

export const deleteCampaign = ({ campaignId }: deleteCampaignProps) => {
  return sendRequest<DeleteCampaignResponse>(
    "delete",
    `/brand/campaign/${campaignId}`,
    false
  ).then((res) => res.data);
};
