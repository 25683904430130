import { sendRequest } from "../helper";

import {
  InfluencerReport,
  ReportInfluencer,
  reportOnlySocialMediaKit,
} from "../../typings/Report";

type getReportsReportOnlyCampaignProps = {
  campaignId: string;
  platform: string;
};

type getReportsReportOnlyCampaignResponse = {
  data: (InfluencerReport & {
    reportInfluencer: ReportInfluencer[];
    reportInfluencerSize: number;
    socialMediaKit: reportOnlySocialMediaKit;
  })[];
  status: string;
  message: string;
};

export const getReportsReportOnlyCampaign = ({
  campaignId,
}: getReportsReportOnlyCampaignProps) => {
  const queryKey = ["reports", "reportOnlyReports", campaignId];

  const queryFunction = () =>
    sendRequest<getReportsReportOnlyCampaignResponse>(
      "get",
      `/brand/report-only/report?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
