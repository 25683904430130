import { sendRequest } from "../../../helper";

export type approveLivePropsV2 = {
  workflowId: string;
  prevLiveLinkTimeLineId: string;
};

export const approveLiveV2 = ({
  workflowId,
  prevLiveLinkTimeLineId,
}: approveLivePropsV2) => {
  return sendRequest(
    "put",
    `/brand/execution/live/accept`,
    false,
    {
      workflowId,
      prevLiveLinkTimeLineId,
    },
    null,
    true
  ).then((res) => res.data);
};
