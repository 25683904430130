import React, { useState } from "react";
import { Modal, Button, message, InputNumber, Row } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { inviteInfluencerCustomOffer } from "../../../handler/campaign/offer";

interface SpecialOfferModalProps {
  isVisible: boolean;
  handleClose: () => void;
  socialMediaKitId: string;
  queryKey: (string | number | boolean)[];
  campaignId: string;
  campaignName: string;
}

const SpecialOfferModal: React.FC<SpecialOfferModalProps> = ({
  isVisible,
  handleClose,
  socialMediaKitId,
  queryKey,
  campaignId,
  campaignName,
}) => {
  const queryClient = useQueryClient();
  const [inputValues, setInputValues] = useState<{
    brandPrice: number;
    influencerPrice: number;
  }>({
    brandPrice: 0,
    influencerPrice: 0,
  });

  const inviteInfluencerMutation = useMutation(inviteInfluencerCustomOffer, {
    onSuccess: () => {
      message.success("Invite Sent");
      queryClient.invalidateQueries(queryKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      <Modal
        title="Send Custom Offer"
        visible={isVisible}
        footer={
          <Button
            type="primary"
            disabled={inputValues.brandPrice <= 0}
            onClick={() =>
              inviteInfluencerMutation.mutate({
                socialMediaKitId,
                campaignId,
                brandPrice: inputValues.brandPrice,
                influencerPrice: inputValues.brandPrice,
              })
            }
            loading={inviteInfluencerMutation.isLoading}>
            Send
          </Button>
        }
        onCancel={() => handleClose()}
        centered
        destroyOnClose>
        <div>
          <Row justify="center">
            <h3>{campaignName}</h3>
          </Row>
          <div style={{ margin: "20px 0" }}>
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "8px",
                marginRight: "10px",
              }}>
              Custom Price:
            </span>
            <InputNumber
              size="middle"
              value={inputValues.brandPrice}
              onChange={(value) =>
                setInputValues((inputValues) => ({
                  ...inputValues,
                  brandPrice: value as number,
                }))
              }
            />
          </div>
          {/* <div style={{ margin: "20px 0" }}>
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "8px",
                marginRight: "10px",
              }}>
              Influencer Price:
            </span>
            <InputNumber
              size="middle"
              value={inputValues.influencerPrice}
              onChange={(value) =>
                setInputValues((inputValues) => ({
                  ...inputValues,
                  influencerPrice: value as number,
                }))
              }
            />
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default SpecialOfferModal;
