import React from "react";
import { message } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { getUser } from "../../../../handler/getUser";
import { deleteEmail } from "../../../../handler/marketing";

import ReportButton from "../../../Campaign/Report/ReportButton";

import styles from "../styles.module.css";

const RemoveMail = () => {
  const queryClient = useQueryClient();

  const [getUserKey, getUserFn] = getUser();

  const { data } = useQuery(getUserKey, getUserFn);

  const deleteEmailMutation = useMutation(deleteEmail, {
    onSuccess: () => {
      message.info("Email Removed");
      queryClient.invalidateQueries(getUserKey);
    },
    onError: () => {
      message.error("Something went wrong while removing");
    },
  });

  return (
    <div className={styles.grid40} style={{ padding: "20px" }}>
      <div>
        <p className={styles.removeDescLight} style={{ marginBottom: "20px" }}>
          This Email is used to invite influencers when you invite them to your
          network
        </p>
        <p className={styles.removeDescLight} style={{ marginBottom: "20px" }}>
          The default Email is send by app@cloutflow.com.
        </p>
        <p className={styles.removeDescLight} style={{ marginBottom: "40px" }}>
          To increase conversions 📈, you can add your own email-Id
        </p>

        <p className={styles.removeHeadingLabel}> Current Email is set to:</p>
        <p className={styles.removeHeading}>
          {data?.data?.brevo?.sendersEmail || "-"}
        </p>
      </div>

      <ReportButton
        ghost
        isOutline
        style={{ width: "max-content", padding: "10px 20px" }}
        onClick={() => deleteEmailMutation.mutate()}
        loading={deleteEmailMutation.isLoading}
      >
        Remove Email-Id
      </ReportButton>
    </div>
  );
};

export default RemoveMail;
