interface MyObject {
    [key: string]: any;
  }
  
  export const updateObjectAtIndex = (
    array: MyObject[],
    key: string,
    value: any,
    index: number,
  ): MyObject[] => {
    if (index < 0 || index > array.length) {
      throw new Error("Index out of range");
    }
  
    const updatedArray = [...array];
  
    if (index === array.length) {
      updatedArray.push({ [key]: value });
    } else {
      const updatedObject = { ...updatedArray[index], [key]: value };
      updatedArray[index] = updatedObject;
    }
  
    return updatedArray;
  };
  