import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Drawer,
  Row,
  Collapse,
  Col,
  Input,
  Button,
  Tooltip,
  Rate,
  message,
  Result,
  Spin,
} from "antd";
import {
  QuestionCircleOutlined,
  HeartFilled,
  CommentOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { AxiosError } from "axios";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";

import nf from "@tuplo/numberfmt";

import {
  getInfluencerMedia,
  getInfluencer,
  getInfluencerCampaigns,
  postNotes,
  getInfluencerReviews,
} from "../../handler/Profile";

import { getUser } from "../../handler/getUser";

import CampaignCard from "../Campaign/Manage/CreatorProfile/CampaignCard";
import ReviewCard from "../Campaign/Manage/CreatorProfile/ReviewCard";
import InsightCard from "../Campaign/Manage/CreatorProfile/InsightCard";
import CommercialCard from "../Campaign/Manage/CreatorProfile/CommercialCard";
import Demographic from "../Campaign/Manage/CreatorProfile/Demographic";
import ReviewModal from "../Campaign/Manage/Finalized/Timeline/LinksTile/ReviewModals/Review";

import PopularPostCard from "../Campaign/Manage/CreatorProfile/PopularPostCard";
import CampaignModal from "../Discover/CampaignModal";

import Navbar from "../shared/Navbar";

import styles from "./styles.module.css";

const { Panel } = Collapse;
const { TextArea } = Input;

const InfluencerProfile = () => {
  const { id: influencerId } = useParams();
  const queryClient = useQueryClient();
  const [getInfluencerKey, getInfluencerFn] = getInfluencer(influencerId || "");
  const {
    data: influencerData,
    isLoading: isInfluencerDataLoading,
    isError: infleuncerDataError,
  } = useQuery(getInfluencerKey, getInfluencerFn);

  const [getUserKey, getUserFn] = getUser();
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: userDataError,
  } = useQuery(getUserKey, getUserFn);

  const [notes, setNotes] = useState<string>(influencerData?.data.notes || "");
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [campaignModalProps, setCampaignModalProps] = useState<{
    visible: boolean;
    socialMediaKitId: string;
    platform: "youtube" | "instagram";
  }>({ visible: false, socialMediaKitId: "", platform: "instagram" });

  const navigate = useNavigate();

  useEffect(() => {
    setNotes(influencerData?.data.notes || "");
  }, [influencerData?.data.notes]);

  const postNotesMutation = useMutation(postNotes, {
    onSuccess: () => {
      message.success("Updated User Notes");
      queryClient.invalidateQueries(getInfluencerKey);
      setEditNotes(false);
    },
    onError: (err: AxiosError) => {
      message.error("Some error Occurred");
    },
  });

  const [getInfluencerCampaignsKey, getInfluencerCampaignsFn] =
    getInfluencerCampaigns(influencerId || "");

  const {
    data: InfluencerCampaignsData,
    isLoading: isInfluencerCampaignDataLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(getInfluencerCampaignsKey, getInfluencerCampaignsFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  const [getInfluencerReviewsKey, getInfluencerReviewsFn] =
    getInfluencerReviews(influencerId || "");

  const {
    data: InfluencerReviewsData,
    isLoading: isInfluencerReviewsDataLoading,
    hasNextPage: hasReviewNextPage,
    fetchNextPage: fetchReviewNextPage,
    isFetchingNextPage: isFetchingReviewNextPage,
    isError: infleuncerReviewDataError,
  } = useInfiniteQuery(getInfluencerReviewsKey, getInfluencerReviewsFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  const [isReviewVisible, setReviewVisible] = useState<boolean>(false);

  const [getInfluencerMediaKey, getInfluencerMediaFn] = getInfluencerMedia(
    influencerId || ""
  );

  const { data: influencerMedia, isLoading: isInfluencerMediaLoading } =
    useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {});

  const getReviewStarCount = (val: number) => {
    return Math.floor(val) + (val - Math.floor(val) >= 0.5 ? 0.5 : 0);
  };

  if (
    !influencerId ||
    influencerId === "undefined" ||
    influencerId === "null"
  ) {
    navigate("/discover", { replace: true });
  }

  if (
    isInfluencerCampaignDataLoading ||
    isInfluencerDataLoading ||
    isInfluencerReviewsDataLoading ||
    isInfluencerMediaLoading ||
    isUserDataLoading
  ) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ paddingTop: "100px", height: "90vh" }}>
        <Spin
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
        />
      </Row>
    );
  }

  if (
    !influencerId ||
    infleuncerDataError ||
    userDataError ||
    infleuncerReviewDataError
  ) {
    return (
      <Result
        style={{ margin: "200px 0" }}
        status="500"
        title="500"
        subTitle="No Record Found"
      />
    );
  }

  return (
    <main>
      <Navbar selectedItem="invite" />
      <Row justify="center">
        <section
          style={{
            background: "#FFFFFF",
            padding: "30px",
            width: "100%",
            maxWidth: "1200px",
          }}>
          <h2 style={{ textAlign: "center" }}>Creator Profile</h2>
          <div>
            <Row justify="center" style={{ padding: "24px 0 40px 0" }}>
              <div style={{ textAlign: "center" }}>
                <div style={{ display: "flex", gap: "12px" }}>
                  {influencerMedia?.data?.map((item, i) => {
                    return (
                      <div className={styles.creatorProfile}>
                        <img
                          src={
                            item?.socialMediaPlatform !== "youtube"
                              ? item?.instagram?.user?.profilePicUrl
                              : item?.youtube?.user?.profilePicUrl
                          }
                          width={90}
                          alt="profile"
                          key={i}
                          style={{ borderRadius: "100px" }}
                        />
                        <img
                          src={`/assets/img/${
                            item?.socialMediaPlatform !== "youtube"
                              ? "instaProfile"
                              : "youtubeProfile"
                          }.png`}
                          style={{
                            position: "absolute",
                            bottom: "-4px",
                            width: "30px",
                            height: "30px",
                            right: "5px",
                          }}
                          alt="platform icon"
                        />
                      </div>
                    );
                  })}
                </div>

                <p className={styles.profileName}>
                  {influencerData?.data.name}
                </p>
                <p className={styles.profileData} style={{ marginTop: "4px" }}>
                  <span>{influencerData?.data?.age} yrs</span>
                  {" | "}
                  <span>{influencerData?.data?.gender}</span>
                </p>
                <p className={styles.profileData} style={{ marginTop: "12px" }}>
                  {influencerData?.data?.phoneNumber || ""}
                </p>
                <Col>
                  <Button
                    type="primary"
                    icon={<CommentOutlined />}
                    style={{ borderRadius: "4px", marginTop: "12px" }}
                    onClick={() =>
                      navigate(`/chat?influencerId=${influencerId}`)
                    }
                    disabled={
                      userData?.data?.signUpStatus !== "businessVerified"
                    }>
                    {" "}
                    Chat with creator
                  </Button>
                </Col>

                {influencerMedia?.data?.map((item, i) => {
                  return (
                    <Col>
                      <Button
                        style={{ borderRadius: "4px", marginTop: "12px" }}
                        onClick={() => {
                          setCampaignModalProps({
                            visible: true,
                            socialMediaKitId: item._id,
                            platform: item.socialMediaPlatform,
                          });
                        }}
                        key={i}
                        disabled={
                          userData?.data?.signUpStatus !== "businessVerified"
                        }>
                        Invite to {item.socialMediaPlatform} Campaign
                      </Button>
                    </Col>
                  );
                })}
              </div>
            </Row>
            {/* {userData?.data?.accountType === "Managed" && (
          <div
            style={{
              border: "1px solid blue",
              justifyContent: "center",
              display: "grid",
            }}>
            <h3 style={{ textAlign: "center" }}>For Admin Use Only</h3>
            {influencerMedia?.data?.map((item, i) => {
              return (
                <p style={{ display: "flex", gap: "12px" }} key={i}>
                  <span>{item?.socialMediaPlatform} Social Media ID: </span>
                  {item?._id}
                </p>
              );
            })}
          </div>
        )} */}
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              expandIconPosition={"end"}
              bordered={false}
              style={{ background: "#FFFFFF" }}>
              <Panel
                collapsible={"disabled"}
                showArrow={false}
                header={<span className={styles.panelHeading}>Notes</span>}
                key="1">
                <div style={{ position: "relative" }}>
                  <TextArea
                    disabled={!editNotes}
                    autoSize={{ minRows: 4 }}
                    defaultValue={notes}
                    value={notes}
                    style={{
                      border: "0.5px solid #D9D9D9",
                      boxShadow: "0px 0px 8px rgba(10, 6, 18, 0.04)",
                      borderRadius: "8px",
                    }}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                  {!editNotes && (
                    <div
                      onClick={() => setEditNotes(true)}
                      className={styles.editIcon}>
                      <EditOutlined />
                    </div>
                  )}
                </div>
                {editNotes && (
                  <Row justify="end" style={{ marginTop: "10px" }}>
                    <Button
                      type="default"
                      style={{ borderRadius: "2px" }}
                      onClick={() => {
                        setNotes(influencerData?.data?.notes || "");
                        setEditNotes(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: "8px", borderRadius: "2px" }}
                      onClick={() =>
                        postNotesMutation.mutate({ influencerId, notes })
                      }
                      loading={postNotesMutation.isLoading}>
                      Save
                    </Button>
                  </Row>
                )}
              </Panel>
              <Panel
                collapsible={"disabled"}
                showArrow={false}
                header={
                  <p
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                    className={styles.panelHeading}>
                    Campaigns
                    <Tooltip
                      placement="top"
                      trigger={"hover"}
                      title="All the campaigns that creator has done with you">
                      <QuestionCircleOutlined size={16} />
                    </Tooltip>
                  </p>
                }
                key="2">
                {InfluencerCampaignsData?.pages[
                  InfluencerCampaignsData?.pages.length - 1
                ].data.campaigns.length !== 0 ? (
                  <>
                    <Row gutter={[8, 8]}>
                      {InfluencerCampaignsData?.pages.map((page) =>
                        page.data.campaigns
                          .filter(
                            (campaignIem) =>
                              campaignIem.status === "Finalized" ||
                              campaignIem.status === "Cancel" ||
                              campaignIem.status === "Completed"
                          )
                          .map((campaign) => {
                            return (
                              <Col span={12}>
                                <CampaignCard
                                  campaign={campaign}
                                  influencerId={influencerId}
                                  key={campaign._id}
                                />
                              </Col>
                            );
                          })
                      )}
                    </Row>

                    <Row justify="end" style={{ marginTop: "20px" }}>
                      <Button
                        style={{
                          padding: "4px 0 4px 24px",
                        }}
                        type="text"
                        disabled={!hasNextPage}
                        loading={isFetchingNextPage}
                        onClick={() => fetchNextPage()}>
                        <u
                          className={styles.reviewCount}
                          style={{ color: "rgba(0,0,0,0.85)" }}>
                          See More
                        </u>
                      </Button>
                    </Row>
                  </>
                ) : (
                  <Row justify="center">
                    <p
                      style={{
                        color: "rgba(0,0,0,0.45)",
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}>
                      No campaigns to see here
                    </p>
                  </Row>
                )}
              </Panel>
              <Panel
                collapsible={"disabled"}
                showArrow={false}
                header={
                  <span className={styles.panelHeading}>Creator Profile</span>
                }
                key="3">
                <Collapse
                  defaultActiveKey="1"
                  bordered={false}
                  expandIconPosition={"end"}>
                  {influencerMedia?.data?.map((mediaItem, i) => {
                    return (
                      <>
                        {mediaItem.socialMediaPlatform === "instagram" && (
                          <Panel
                            header={
                              <p
                                style={{ fontSize: "16", fontWeight: 500 }}
                                className={styles.nestedPanelHeading}>
                                <img
                                  src="/assets/img/instagram.png"
                                  style={{
                                    marginRight: "8px",
                                    height: "24px",
                                    width: "24px",
                                  }}
                                  alt="instagram"
                                />
                                {mediaItem.socialMediaName || "Creator Name"}
                                <a
                                  href={`https://www.instagram.com/${mediaItem?.socialMediaName}`}
                                  target="_blank"
                                  rel="noreferrer noopener">
                                  <LinkOutlined
                                    size={16}
                                    style={{ marginLeft: "4px" }}
                                  />
                                </a>
                              </p>
                            }
                            key={`instagram-${i}`}
                            style={{ background: "#fff" }}>
                            <section style={{ display: "grid", gap: "12px" }}>
                              <div>
                                <p style={{ marginBottom: "12px" }}>Insights</p>
                                <InsightCard
                                  item={[
                                    {
                                      label: "Followers",
                                      value: nf(
                                        mediaItem.instagram?.user.followerCount,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Eng. Rate",
                                      value: nf(
                                        mediaItem.instagram?.content.aggregate
                                          .engagement,
                                        "0.00%"
                                      ),
                                    },
                                    {
                                      label: "Avg. Reach",
                                      value: nf(
                                        mediaItem.instagram?.content.aggregate
                                          .avgReach,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Total Posts",
                                      value: nf(
                                        mediaItem.instagram?.user.contentCount,
                                        "a"
                                      ),
                                    },
                                  ]}
                                />
                                <InsightCard
                                  item={[
                                    {
                                      label: "Avg. Likes",
                                      value: nf(
                                        mediaItem.instagram?.content.aggregate
                                          .avgLikes,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Avg. Comments",
                                      value: nf(
                                        mediaItem.instagram?.content.aggregate
                                          .avgComments,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Total Posts",
                                      value: nf(
                                        mediaItem.instagram?.user.contentCount,
                                        "a"
                                      ),
                                    },
                                  ]}
                                />
                              </div>

                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Audience Demographic
                                </p>
                                <Demographic
                                  mediaKitId={mediaItem._id}
                                  hasAccess={mediaItem.access_granted}
                                  platform={"instagram"}
                                  influencerId={influencerId}
                                  accessRequested={mediaItem.access_requested}
                                />
                              </div>
                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Commercials
                                </p>
                                <CommercialCard
                                  item={[
                                    {
                                      label: "Reel",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price["IG-Reel"],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                    {
                                      label: "Video Post",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price["IG-Feed-Video"],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                    {
                                      label: "Image Post",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price["IG-Feed-Image"],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                    {
                                      label: "Story",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price["IG-Feed-Story"],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                  ]}
                                  type="instagram"
                                />
                              </div>
                              {/* {userData?.data?.accountType === "Managed" && (
                            <div>
                              <p style={{ marginBottom: "12px" }}>
                                {" "}
                                Influencer Commercials
                              </p>
                              <CommercialCard
                                item={[
                                  {
                                    label: "Reel",
                                    value: `₹ ${nf(
                                      mediaItem.influencerPrice["IG-Reel"],
                                      "0,0"
                                    )}`,
                                  },
                                  {
                                    label: "Video Post",
                                    value: `₹ ${nf(
                                      mediaItem.influencerPrice[
                                        "IG-Feed-Video"
                                      ],
                                      "0,0"
                                    )}`,
                                  },
                                  {
                                    label: "Image Post",
                                    value: `₹ ${nf(
                                      mediaItem.influencerPrice[
                                        "IG-Feed-Image"
                                      ],
                                      "0,0"
                                    )}`,
                                  },
                                  {
                                    label: "Story",
                                    value: `₹ ${nf(
                                      mediaItem.influencerPrice[
                                        "IG-Feed-Story"
                                      ],
                                      "0,0"
                                    )}`,
                                  },
                                ]}
                                type="instagram"
                              />
                            </div>
                          )} */}
                              {/* {(mediaItem?.instagram?.content?.medias || [])
                              .length > 0 && (
                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Recent Posts
                                </p>
                                <PopularPostCard
                                  items={mediaItem?.instagram?.content.medias}
                                  platform={"Instagram"}
                                />
                              </div>
                            )} */}
                            </section>
                          </Panel>
                        )}

                        {mediaItem.socialMediaPlatform === "youtube" && (
                          <Panel
                            header={
                              <p
                                style={{ fontSize: "16", fontWeight: 500 }}
                                className={styles.nestedPanelHeading}>
                                <img
                                  src="/assets/icons/youtubeIcon.png"
                                  style={{
                                    marginRight: "8px",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                  alt="youtube"
                                />
                                {mediaItem.youtube?.user.name || "Channel Name"}
                                <a
                                  href={`https://www.youtube.com/channel/${mediaItem?.socialMediaId}`}
                                  target="_blank"
                                  rel="noreferrer noopener">
                                  {" "}
                                  <LinkOutlined
                                    size={16}
                                    style={{ marginLeft: "4px" }}
                                  />
                                </a>
                              </p>
                            }
                            key={`youtube-${i}`}
                            style={{ background: "#fff" }}>
                            <section style={{ display: "grid", gap: "12px" }}>
                              <div>
                                <p style={{ marginBottom: "12px" }}>Insights</p>
                                <InsightCard
                                  item={[
                                    {
                                      label: "Subscribers",
                                      value: nf(
                                        mediaItem.youtube?.user.followerCount,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Total Videos",
                                      value: nf(
                                        mediaItem.youtube?.content.medias
                                          .length,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Eng. Rate",
                                      value: nf(
                                        mediaItem.youtube?.content.aggregate
                                          .engagement,
                                        "0.00%"
                                      ),
                                    },
                                  ]}
                                />
                                <InsightCard
                                  item={[
                                    {
                                      label: "Avg. Likes",
                                      value: nf(
                                        mediaItem.youtube?.content.aggregate
                                          .avgLikes,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Avg. Comments",
                                      value: nf(
                                        mediaItem.youtube?.content.aggregate
                                          .avgComments,
                                        "a"
                                      ),
                                    },
                                    {
                                      label: "Avg. Reach",
                                      value: nf(
                                        mediaItem.youtube?.content.aggregate
                                          .avgReach,
                                        "a"
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Commercials
                                </p>
                                <CommercialCard
                                  item={[
                                    {
                                      label: "Dedicated Video",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price[
                                                "YT-Dedicated-Video"
                                              ],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                    {
                                      label: "Integrated Video",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price[
                                                "YT-Integrated-Video"
                                              ],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                    {
                                      label: "Shorts",
                                      value: `₹ ${
                                        userData?.data?.signUpStatus !==
                                        "businessVerified"
                                          ? "XXXX"
                                          : nf(
                                              mediaItem.price["YT-Shorts"],
                                              "0,0"
                                            )
                                      }`,
                                    },
                                  ]}
                                  type="youtube"
                                />
                              </div>
                              {/* {userData?.data?.accountType === "Managed" && (
                            <div>
                              <p style={{ marginBottom: "12px" }}>
                                {" "}
                                Influencer Commercials
                              </p>
                              <CommercialCard
                                item={[
                                  {
                                    label: "Dedicated Video",
                                    value: `₹ ${nf(
                                      mediaItem.influencerPrice[
                                        "YT-Dedicated-Video"
                                      ] || 0,
                                      "0,0"
                                    )}`,
                                  },
                                  {
                                    label: "Integrated Video",
                                    value: `₹ ${nf(
                                      mediaItem?.influencerPrice[
                                        "YT-Integrated-Video"
                                      ] || 0,

                                      "0,0"
                                    )}`,
                                  },
                                  {
                                    label: "Shorts",
                                    value: `₹ ${nf(
                                      mediaItem?.influencerPrice["YT-Shorts"] ||
                                        0,

                                      "0,0"
                                    )}`,
                                  },
                                ]}
                                type="youtube"
                              />
                            </div>
                          )} */}
                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Audience Demographic
                                </p>
                                <Demographic
                                  mediaKitId={mediaItem._id}
                                  hasAccess={mediaItem.access_granted}
                                  platform={"youtube"}
                                  influencerId={influencerId}
                                  accessRequested={mediaItem.access_requested}
                                />
                              </div>
                              {/* {(mediaItem?.youtube?.content?.medias || [])
                              .length > 0 && (
                              <div>
                                <p style={{ marginBottom: "12px" }}>
                                  Recent Videos
                                </p>
                                <PopularPostCard
                                  items={mediaItem?.youtube?.content.medias.map(
                                    (item) => {
                                      return {
                                        type: "video",
                                        url: item.url,
                                      };
                                    }
                                  )}
                                  platform={"Youtube"}
                                />
                              </div>
                            )} */}
                            </section>
                          </Panel>
                        )}
                      </>
                    );
                  })}
                </Collapse>
              </Panel>
              <Panel
                collapsible={"disabled"}
                showArrow={false}
                header={
                  <div
                    style={{
                      width: "100%",
                      flexShrink: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <span className={styles.panelHeading}>Reviews</span>
                    <Button type="link" onClick={() => setReviewVisible(true)}>
                      Add a Review
                    </Button>
                  </div>
                }
                key="4">
                <div>
                  <Row style={{ alignItems: "center", gap: "15px" }}>
                    <p className={styles?.reviewNumber}>
                      {nf(
                        `${
                          (influencerData?.data.aggregatedReviewRating || 0) /
                          (influencerData?.data.totalReviews || 1)
                        }`,
                        "0.0"
                      )}
                    </p>
                    <Rate
                      value={getReviewStarCount(
                        (influencerData?.data.aggregatedReviewRating || 0) /
                          (influencerData?.data.totalReviews || 1)
                      )}
                      disabled
                      style={{ color: "#EC3434" }}
                      character={
                        <HeartFilled
                          style={{
                            fontSize: "110%",
                          }}
                        />
                      }
                      allowHalf={true}
                    />

                    <p className={styles.reviewCount}>
                      {influencerData?.data?.totalReviews} reviews
                    </p>
                  </Row>
                  <div
                    style={{ display: "grid", gap: "20px", marginTop: "32px" }}>
                    {InfluencerReviewsData?.pages.map((page) =>
                      page?.data.reviews.map((review) => {
                        return (
                          <Col span={12}>
                            <ReviewCard review={review} />
                          </Col>
                        );
                      })
                    )}
                  </div>
                  <Row justify="end">
                    <Button
                      style={{ padding: "4px 24px" }}
                      type="text"
                      disabled={!hasReviewNextPage}
                      onClick={() => {
                        fetchReviewNextPage();
                      }}
                      loading={isFetchingReviewNextPage}>
                      <u
                        className={styles.reviewCount}
                        style={{ color: "rgba(0,0,0,0.85)" }}>
                        See More
                      </u>
                    </Button>
                  </Row>
                </div>
              </Panel>
            </Collapse>

            <CampaignModal
              isVisible={campaignModalProps.visible}
              handleCancel={() =>
                setCampaignModalProps({
                  visible: false,
                  socialMediaKitId: "",
                  platform: "instagram",
                })
              }
              handleSubmit={() =>
                setCampaignModalProps({
                  visible: false,
                  socialMediaKitId: "",
                  platform: "instagram",
                })
              }
              influencerId={influencerId}
              platform={campaignModalProps.platform}
              socialMediaKitId={campaignModalProps.socialMediaKitId}
            />
            <ReviewModal
              influencerId={influencerId}
              isVisible={isReviewVisible}
              handleSubmit={() => {
                setReviewVisible(false);
                queryClient.invalidateQueries(getInfluencerReviewsKey);
                queryClient.invalidateQueries(getInfluencerKey);
              }}
              handleCancel={() => setReviewVisible(false)}
            />
          </div>
        </section>
      </Row>
    </main>
  );
};

export default InfluencerProfile;
