import { sendRequest } from "../helper";
import { ChatMember } from "../../typings";

export type getAllConversationsResult = {
  status: string;
  message: string;
  data: {
    userConversation: ChatMember[];
    nextPageToken: string | null;
  };
};

export const getAllConversations = (searchCreator?: string) => {
  // console.log(searchCreator);

  const queryKey = ["chats", searchCreator ? searchCreator : "none"];

  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<getAllConversationsResult>(
      "put",
      `/brand/chat/conversation-search?limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false,
      {
        text: searchCreator,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
