import { Drawer } from "antd";
import ProfileAnalysis3p from ".";

const ProfileModal: React.FC<any> = ({ platform, username, open, setOpen }) => {
  if (username && platform) {
    return (
      <Drawer
        open={open}
        width={1200}
        title="Profile Analysis"
        placement="right"
        onClose={() => setOpen(false)}
        destroyOnClose
      >
        <ProfileAnalysis3p platform={platform} username={username} />
      </Drawer>
    );
  }
  return null;
};

export default ProfileModal;
