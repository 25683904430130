import { Button, Select, message, Row, Popover, Col } from "antd";
import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { LuInfo } from "react-icons/lu";
import CSVReader from "react-csv-reader";
import { useMutation, useQuery } from "react-query";
import { countryCodeArray as COUNTRY_CODE_ARR } from "../../../assets/country_code";
import cuid from "cuid";

import TableEdit from "../../Steps/Upload/TableEdit";
import ReportButton from "../../../Campaign/Report/ReportButton";
import AddEmailModal from "../../Steps/Modal/AddEmail";

import styles from "./styles.module.css";

import { validateEmail, validateIndNum } from "../../../../utils";

import { inviteInfluencer } from "../../../../handler/invite";
import { getUser } from "../../../../handler/getUser";

import { TableItem } from "../../Steps";

type BulkInviteProps = {
  handleCancel: () => void;
  handleSubmit: () => void;
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
};

const BulkInvite: React.FC<BulkInviteProps> = ({
  handleCancel,
  handleSubmit,
}) => {
  const [code, setCode] = useState<string>("");
  const [cccVisible, setCccVisible] = useState<boolean>(false);
  const [data, setData] = useState<TableItem[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [userKey, userFn] = getUser();

  const { data: userData } = useQuery(userKey, userFn);

  const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<boolean>(false);

  // const [queryKey, queryFn] = getUser();

  // const { data: userData } = useQuery(queryKey, queryFn);

  const inviteInfluencersMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Influencers Invited");
      handleSubmit();

      handleCancel();
      setCode("");
      setFileName("");
      setData([]);
    },
    onError: (e) => {
      console.log(e);
      message.error("Something went wrong while inviting Influencers");
    },
  });

  const handleNextClick = () => {
    const payload = data.map((item) =>
      item.phoneNumber
        ? { ...item, phoneNumber: code + item.phoneNumber }
        : item
    );
    if (!code) {
      return message.error("Please Select the Country code");
    }
    if (emailMessage && !userData?.data?.brevo?.isSendersMailVerified)
      return message.error("Please Add your Email to invite by email");
    inviteInfluencersMutation.mutate({ inviteList: payload });
  };

  const onChange = (value: string) => {
    const dial_code_value = JSON.parse(value).dial_code;
    setCode(dial_code_value);
  };

  const onSearch = (value: string) => {};

  const options = COUNTRY_CODE_ARR.map((item) => {
    return {
      label: `(${item.dial_code}) ${item.name}`,
      value: JSON.stringify({
        ...item,
      }),
    };
  });

  const handleForce = (data: any[], fileInfo: any) => {
    let initialData: TableItem[] = [];
    data.forEach((item, idx) => {
      if (Object.values(item).every((x) => x !== null && x !== undefined)) {
        initialData.push({
          key: idx.toString(),
          name: item?.name || null,
          platform: item?.platform || null,
          username: item?.username || null,
          email: item?.email || null,
          phoneNumber: item?.phonenumber || null,
        });
      } else {
        initialData.unshift({
          key: idx.toString(),
          name: item?.name || null,
          platform: item?.platform || null,
          username: item?.username || null,
          email: item?.email || null,
          phoneNumber: item?.phonenumber || null,
        });
      }
    });

    setData(initialData);
    setFileName(fileInfo?.name || "sample.csv");
  };

  const isDataValid = (item: TableItem) => {
    let error = false;

    if (!item.name || String(item.name).length > 20) {
      error = true;
    }
    if (
      !item.platform ||
      (item.platform !== "instagram" && item.platform !== "youtube")
    ) {
      error = true;
    }

    if (!item.phoneNumber) {
      error = true;
    }

    if (
      item.phoneNumber &&
      (String(item.phoneNumber).length !== 10 ||
        !validateIndNum(item.phoneNumber))
    ) {
      error = true;
    }
    if (item.email && !validateEmail(item.email)) {
      error = true;
    }

    return error;
  };

  useEffect(() => {
    let hasPhone = false;
    let hasEmail = false;

    for (let i = 0; i < data.length; i++) {
      if (data[i]?.phoneNumber) {
        hasPhone = true;
        break;
      }
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i]?.email) {
        hasEmail = true;
        break;
      }
    }

    if (data && data.length > 0 && hasPhone) {
      setCccVisible(true);
    } else {
      setCccVisible(false);
    }

    if (data && data.length > 0 && hasEmail) {
      setEmailMessage(true);
    } else {
      setEmailMessage(false);
    }

    if (data.length === 0) {
      setFileName("");
    }
  }, [data]);

  return (
    <React.Fragment>
      {emailMessage && !userData?.data?.brevo?.isSendersMailVerified && (
        <Row
          className={styles.emailContainer}
          justify="space-between"
          style={{ marginBottom: 10 }}
        >
          <Col span={17} className={styles.emailHeading}>
            To Invite Influencer By Email You need to add your Email id. <br />
          </Col>
          <Col>
            <ReportButton
              ghost
              isOutline
              title="Add Your Email-Id"
              className={styles.emailButton}
              onClick={() => setEmailModalVisible(true)}
            >
              Add Your Email ID
            </ReportButton>
          </Col>
        </Row>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <span className={styles.span}>
            Upload a .csv file of influencers you want to invite
          </span>
          <Popover
            content={
              <p>
                When you enter an influencer's email ID, <br />
                they will receive an invitation by email to join your <br />{" "}
                community. If you also provide their phone number, <br />{" "}
                Cloutflow will send them an additional invite via WhatsApp.
              </p>
            }
            title={null}
            trigger="hover"
            placement="top"
            arrowPointAtCenter
          >
            <LuInfo size={15} style={{ cursor: "pointer" }} />
          </Popover>
        </div>

        {!fileName && (
          <Button
            type="link"
            style={{
              margin: 0,
              padding: 0,
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
            onClick={() =>
              exportFromJSON({
                data: [
                  {
                    name: "Shivam",
                    platform: "instagram",
                    email: "test@cloutflow.com",
                    phonenumber: "1234567899",
                  },
                ],
                fileName: "sample-upload",
                exportType: "csv",
              })
            }
          >
            <FiDownload color="#533AF5" />
            <span className={styles.download}>Download Sample Template</span>
          </Button>
        )}
      </div>

      {fileName ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "22px 0",
          }}
        >
          <div>
            <Button
              type="primary"
              style={{
                backgroundColor: "#EB3F791A",
                color: "#000000B2",
                borderRadius: "15px",
                height: "52px",
                border: "none",
              }}
            >
              {fileName}
              <img
                src="/assets/icons/close--purple.svg"
                height={32}
                width={32}
                alt="close"
                style={{ marginLeft: "24px", cursor: "pointer" }}
                onClick={() => {
                  setData([]);
                  setFileName("");
                }}
              />
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ marginBlock: 24 }}>
          <CSVReader
            inputStyle={{ display: "none" }}
            cssClass={styles.csvInputWrapper}
            accept=".csv, text/csv"
            label={
              <div className={styles.uploadButtonContainer}>
                <img
                  src={"/assets/icons/lottie-excel.gif"}
                  alt="excel-gif"
                  width={50}
                  height={50}
                />
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <FiDownload color="#533AF5" />
                  <span className={styles.download}>Upload file</span>
                </div>
                <div style={{ marginTop: 8 }}>Allowed file format is csv</div>
              </div>
            }
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
        </div>
      )}

      {fileName && (
        <h3
          style={{
            color: "#00000073",
            fontSize: "0.9rem",
            fontWeight: 400,
            marginBottom: "1.25rem",
            marginTop: "6px",
          }}
        >
          Confirm the details
        </h3>
      )}

      {data?.length !== 0 && (
        <div>
          <TableEdit data={data} setData={setData} />
          <div style={{ marginBlock: 12 }}>
            <Button
              style={{ borderColor: "transparent" }}
              onClick={() => {
                setData((prev) => [
                  {
                    key: cuid(),
                    name: "",
                    platform: "",
                    username: "",
                    email: "",
                    phoneNumber: "",
                  },
                  ...prev,
                ]);
              }}
            >
              <span className={styles.download}>+ Add Row</span>
            </Button>
          </div>
          {fileName && (
            <div style={{ display: "grid", gap: 10, marginTop: 30 }}>
              <span className={styles.span}>
                Select country code to invite via whatsapp.
              </span>
              {cccVisible && (
                <div>
                  <Select
                    className="cccSelector"
                    style={{
                      minWidth: 200,
                    }}
                    bordered={false}
                    showSearch
                    placeholder="Select a Country Code"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    size="large"
                    // defaultValue={
                    //   options.find((item) => {
                    //     return item.label === "(+91) India";
                    //   })?.label
                    // }
                    filterOption={(input, option) =>
                      (
                        (option &&
                          (option.label ||
                            JSON.parse(option.value)?.dial_code)) ??
                        ""
                      )
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={options}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* {data?.length !== 0 && (
        <div style={{ marginBlock: 12 }}>
          <Button
            style={{ borderColor: "transparent" }}
            onClick={() => {
              setData((prev) => [
                {
                  key: cuid(),
                  name: "",
                  platform: "",
                  username: "",
                  email: "",
                  phoneNumber: "",
                },
                ...prev,
              ]);
            }}>
            <span className={styles.download}>+ Add Row</span>
          </Button>
        </div>
      )} */}

      {/* <div style={{ marginTop: 96 }}></div> */}

      <Row justify="start" style={{ marginTop: 80 }}>
        <ReportButton
          title="Create"
          type="primary"
          size="large"
          onClick={() => handleNextClick()}
          disabled={
            inviteInfluencersMutation.isLoading ||
            data.length <= 0 ||
            !data.every((tableItem) => {
              return !isDataValid(tableItem);
            })
          }
          loading={inviteInfluencersMutation.isLoading}
        >
          Invite {data.length} Creator{data.length > 1 ? "s" : ""}
        </ReportButton>
        <ReportButton
          title="Cancel"
          type="link"
          size="large"
          onClick={() => {
            handleCancel();
            setCode("");
            setFileName("");
            setData([]);
          }}
          loading={false}
        >
          Cancel
        </ReportButton>
      </Row>
      <AddEmailModal
        visible={emailModalVisible}
        handleSubmit={() => setEmailModalVisible(false)}
      />
    </React.Fragment>
  );
};
export default BulkInvite;
