import React from "react";

import { Card, Space } from "antd";

import styles from "./metricCard.module.css";

interface MetricCardProps {
  title: string;
  icon: React.ReactNode;
  desc: string;
  metricNumber?: string | number;
  extra?: React.ReactNode;
  isLoading?: boolean;
  emptyText?: string;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  icon,
  desc,
  extra,
  metricNumber,
  isLoading,
  emptyText,
}) => {
  return (
    <Card
      className={styles.container}
      // hoverable
      //   bodyStyle={{ padding: 0, display: "grid", gap: "20px" }}
    >
      <div style={{ display: "grid", gap: "20px" }}>
        <h3 className={styles.title}>{title}</h3>
        <Space align="center" size={10}>
          {icon}
          <p className={styles.desc}>{desc}</p>
        </Space>
        {isLoading ? (
          <div className={styles.loader}>Loading....</div>
        ) : (
          <>
            {metricNumber ? (
              <div className={styles.metric}>{metricNumber}</div>
            ) : (
              <div className={styles.emptyText}>{emptyText}</div>
            )}
          </>
        )}

        {extra}
      </div>
    </Card>
  );
};

export default MetricCard;
