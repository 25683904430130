import { notificationTypes } from "../Types";

export const getNavigateUrl = (
  notificationType: string,
  ref?: { campaignId?: string }
) => {
  if (notificationType === notificationTypes.ApplicationAccepted)
    return `/campaign/manage/${ref?.campaignId}?type=shortList`;
  else if (notificationType === notificationTypes.ApplicationRejected)
    return `/campaign/manage/${ref?.campaignId}?type=reject`;
  else if (notificationType === notificationTypes.CampaignApproved)
    return `/campaign/manage/${ref?.campaignId}?type=appRec`;
  else if (notificationType === notificationTypes.CampaignApprovalIssue)
    return `/campaign/create/${ref?.campaignId}`;
  else if (notificationType === notificationTypes.KycIssue) {
    return "kyc";
  } else if (notificationType === notificationTypes.PaymentDone)
    return `/campaign/manage/${ref?.campaignId}?type=finalized`;
  else if (notificationType === notificationTypes.PaymentFailed)
    return `/campaign/manage/${ref?.campaignId}?type=shortList`;
  else if (notificationType === notificationTypes.SendRequirementsReminder)
    return `/campaign/manage/${ref?.campaignId}?type=finalized`;
  else if (notificationType === notificationTypes.DraftsApprovedReminder)
    return `/campaign/manage/${ref?.campaignId}?type=finalized`;
  else if (notificationType === notificationTypes.LiveApprovedReminder)
    return `/campaign/manage/${ref?.campaignId}?type=finalized`;
  else if (notificationType === notificationTypes.PaymentPendingReminder)
    return `/campaign/manage/${ref?.campaignId}?type=shortList`;
  else return "";
};
