import Cookies from "universal-cookie";

export const handleCookieChange = (value: string) => {
  const cookies = new Cookies();
  cookies.set("brandId", value, { maxAge: 360 * 24 * 60 * 60 }); // Less than 1 year
  return window.open(
    process.env.REACT_APP_DEPLOYMENT_URL,
    "_self",
    "noreferrer"
  );
};
