import clsx from "clsx";
import styles from "./styles.module.css";

const Option: React.FC<{
  isSelected: boolean;
  title: React.ReactNode;
  handleClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}> = ({
  isSelected,
  handleClick,
  title,
  className = "",
  style,
  disabled = false,
}) => {
  return (
    <div
      onClick={disabled ? () => {} : () => handleClick()}
      className={clsx(
        styles.container,
        { [styles.selected]: isSelected },
        { [styles.disabled]: disabled },
        className
      )}
      style={style}>
      {title}
    </div>
  );
};
export default Option;
