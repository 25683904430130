import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

type getCampaignProps = {
  campaignId: string;
};

export const getCampaign = ({ campaignId }: getCampaignProps) => {
  const queryKey = ["campaign", campaignId];

  const queryFunction = () =>
    sendRequest<CampaignResponse>(
      "get",
      `/brand/campaign/${campaignId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
