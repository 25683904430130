import { sendRequest } from "../../helper";

export type sendOfferProps = {
  offerId: string;
};

export const sendOffer = (offerProps: sendOfferProps) => {
  return sendRequest("post", `/brand/campaign/offer/invite-offer`, false, {
    ...offerProps,
  }).then((res) => res.data);
};
