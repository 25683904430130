import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Switch,
  Typography,
  Table,
  message,
} from "antd";
import {
  addInfluencerToCampaign,
  AddInfluencerToCampaignProps,
} from "../../../handler/cloutflowCampaign/Influencer/suggestInfluencer";
import { useQuery } from "react-query";
import { getAllCampaigns } from "../../../handler/cloutflowCampaign/getAllCampaigns";
import { getCampaignById } from "../../../handler/cloutflowCampaign/getCampaignById";

const { Option } = Select;
const { Text } = Typography;

interface InfluencerModalProps {
  visible: boolean;
  onClose: () => void;
  username: string;
  platform: string;
}

const InfluencerModal: React.FC<InfluencerModalProps> = ({
  visible,
  onClose,
  username,
  platform,
}) => {
  const [form] = Form.useForm();
  console.log(username, platform);
  const [selectedCampaignId, setSelectedCampaignId] = useState<
    string | undefined
  >(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showArchived, setShowArchived] = useState(false);
  const [showBreakdownBrand, setShowBreakdownBrand] = useState(false);
  const [showBreakdownInfluencer, setShowBreakdownInfluencer] = useState(false);
  const [specialFields, setSpecialFields] = useState<any[]>([]);

  const [isMediaRightsOpen, setIsMediaRightsOpen] = useState<Boolean>(false);

  const [getCampaignsKey, getCampaignsFn] = getAllCampaigns({
    page,
    limit: pageSize,
    isArchived: showArchived,
  });
  const { data: campaignsData, isLoading: campaignsLoading } = useQuery(
    getCampaignsKey,
    getCampaignsFn
  );
  const [queryKey, queryFunction] = getCampaignById(selectedCampaignId);
  const {
    data: campaignData,
    error,
    isLoading,
  } = useQuery(queryKey, queryFunction, { enabled: !!selectedCampaignId });

  useEffect(() => {
    if (campaignData) {
      //@ts-ignore
      setSpecialFields(campaignData.data?.specialFields || []);
    }
  }, [campaignData]);

  const handleCampaignSelect = (campaignId: string) => {
    setSelectedCampaignId(campaignId);
  };

  const handleSubmit = async (values: any) => {
    if (!selectedCampaignId) {
      console.error("Campaign ID is required");
      return;
    }

    if (values.influencerCommercials > values.brandCommercials) {
      message.error("Influencer Commercials cannot exceed Brand Commercials");
      return;
    }

    const payload: AddInfluencerToCampaignProps = {
      campaignId: selectedCampaignId,
      username,
      platform,
      brandFinalNegotiatedPrice: {
        isBreakdown: showBreakdownBrand,
        breakdown: showBreakdownBrand
          ? values.brandFinalNegotiatedPrice.breakdown
          : [],
        totalPrice: values.brandCommercials,
        currency: "INR",
      },
      influencerFinalNegotiatedPrice: {
        isBreakdown: showBreakdownInfluencer,
        breakdown: showBreakdownInfluencer
          ? values.influencerFinalNegotiatedPrice.breakdown
          : [],
        totalPrice: values.influencerCommercials,
        currency: "INR",
      },
      internalNotes: values.internalNotes,
      notes: values.notes,
      paymentTerms: values.paymentTerms,
      deliverables: values.deliverables,
      isMediaRights: values.isMediaRights,
      mediaRights: values.mediaRights,
      specialFields: specialFields.map((field) => ({
        key: field.key,
        value: values.specialFields[field.key],
      })),
    };

    try {
      const result = await addInfluencerToCampaign(payload);
      console.log(result);
      handleModalClose(); // Close the modal on success and reset the fields
    } catch (error) {
      console.error("Error adding influencer to campaign:", error);
    }
  };

  const columns = [
    {
      title: "Field",
      dataIndex: "key",
      key: "key",
      render: (text: string) => <Text strong>{text}</Text>,
    },
    {
      title: "Value",
      dataIndex: "key",
      key: "value",
      render: (key: string) => (
        <Form.Item name={["specialFields", key]}>
          <Input placeholder={`Enter value for ${key}`} />
        </Form.Item>
      ),
    },
  ];

  const handleModalClose = () => {
    form.resetFields(); // Reset all form values
    onClose(); // Call the original onClose function
  };

  return (
    <Modal
      visible={visible}
      title="Add Influencer to Campaign"
      onCancel={handleModalClose}
      footer={[
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Submit
        </Button>,
      ]}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ marginTop: 20, height: "60vh", overflow: "scroll" }}
      >
        <Form.Item
          label="Select Campaign"
          name="campaign"
          rules={[{ required: true, message: "Please select a campaign" }]}
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
        >
          <Select
            loading={campaignsLoading}
            placeholder="Select a campaign"
            onChange={handleCampaignSelect}
            style={{ width: "100%" }}
          >
            {campaignsData?.data.map((campaign) => (
              //@ts-ignore
              <Option key={campaign._id} value={campaign._id}>
                {campaign.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Brand Commercials"
            name="brandCommercials"
            rules={[
              { required: true, message: "Selling cost to brand is required" },
            ]}
            labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
            help="Selling cost of Influencer"
          >
            <InputNumber
              addonBefore="₹"
              style={{ width: "100%" }}
              placeholder="10,000"
            />
          </Form.Item>
          <Switch
            checked={showBreakdownBrand}
            onChange={setShowBreakdownBrand}
            checkedChildren="Breakdown"
            unCheckedChildren="Breakdown"
          />
        </div>

        {showBreakdownBrand && (
          <Form.List name={["brandFinalNegotiatedPrice", "breakdown"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      rules={[{ required: true, message: "Missing type" }]}
                    >
                      <Input placeholder="Type" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      rules={[{ required: true, message: "Missing price" }]}
                    >
                      <InputNumber placeholder="Price" addonBefore="₹" />
                    </Form.Item>
                    <Button onClick={() => remove(name)}>Remove</Button>
                  </div>
                ))}
                <Button type="dashed" onClick={() => add()}>
                  Add Breakdown
                </Button>
              </>
            )}
          </Form.List>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            label="Influencer Commercials"
            name="influencerCommercials"
            labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
            help="Purchase cost of Influencer's"
            rules={[
              {
                required: true,
                message: "Cost of purchase from influencer is required",
              },
            ]}
          >
            <InputNumber
              addonBefore="₹"
              style={{ width: "100%" }}
              placeholder="10,000"
            />
          </Form.Item>
          <Switch
            checked={showBreakdownInfluencer}
            onChange={setShowBreakdownInfluencer}
            checkedChildren="Breakdown"
            unCheckedChildren="Breakdown"
          />
        </div>

        {showBreakdownInfluencer && (
          <Form.List name={["influencerFinalNegotiatedPrice", "breakdown"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      rules={[{ required: true, message: "Missing type" }]}
                    >
                      <Input placeholder="Type" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      rules={[{ required: true, message: "Missing price" }]}
                    >
                      <InputNumber placeholder="Price" addonBefore="₹" />
                    </Form.Item>
                    <Button onClick={() => remove(name)}>Remove</Button>
                  </div>
                ))}
                <Button type="dashed" onClick={() => add()}>
                  Add Breakdown
                </Button>
              </>
            )}
          </Form.List>
        )}

        <Form.Item
          label="Deliverables"
          name="deliverables"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          rules={[{ required: true, message: "Deliverables are required" }]}
        >
          <Input.TextArea placeholder="1 Reel + 2 Stories on Instagram" />
        </Form.Item>
        <Form.Item
          label="Payment Terms"
          name="paymentTerms"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Try to keep the payment terms to Post"
          rules={[{ required: true, message: "Payment terms are required" }]}
          initialValue="Post"
        >
          <Input placeholder="Specify payment terms" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "25px",
            }}
          >
            <Text strong>Media Rights</Text>
            <Text>Does the deal include media rights?</Text>
          </div>
          <Switch
            checked={form.getFieldValue("isMediaRights")}
            onChange={(checked) => {
              form.setFieldsValue({ isMediaRights: checked });
              setIsMediaRightsOpen(checked);
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
        {isMediaRightsOpen && (
          <Form.Item
            label="Media Rights Details"
            name="mediaRights"
            labelCol={{ style: { fontWeight: "bold" } }}
            help="Details of Media Rights"
            rules={[
              {
                required: true,
                message: "Media rights details are required",
              },
            ]}
          >
            <Input.TextArea placeholder="Define the media rights types, usage, and timeline, e.g., Advertising Rights for 3 months" />
          </Form.Item>
        )}

        <Form.Item
          label="Notes"
          name="notes"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Optional notes"
        >
          <Input.TextArea placeholder="Optional notes" />
        </Form.Item>

        {specialFields.length > 0 && (
          <>
            <Text strong style={{ marginTop: "25px" }}>
              Special Fields
            </Text>
            <Table
              columns={columns}
              dataSource={specialFields}
              pagination={false}
              rowKey="key"
              style={{ marginTop: "10px" }}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default InfluencerModal;
