import { Button, Typography } from "antd";

const { Title, Paragraph } = Typography;

const EmptySearchTemplate = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Title level={3}>Search from a database of 120M+ influencers</Title>
      <Paragraph>
        Enable search by entering either 3 relevant filters or by searching by
        username.
      </Paragraph>
    </div>
  );
};

export default EmptySearchTemplate;
