import { sendRequest } from "../helper";

import { fieldConfiguration, ReportConfiguration } from "../../typings/Report";

type getConfigurationReportOnlyCampaignProps = {
  campaignId: string;
  platform: string;
};

type getConfigurationReportOnlyCampaignResponse = {
  data: {
    reportConfiguration: ReportConfiguration;
    campaignConfiguraion: fieldConfiguration[];
  };
  status: string;
  message: string;
};

export const getConfigurationReportOnlyCampaign = ({
  campaignId,
  platform,
}: getConfigurationReportOnlyCampaignProps) => {
  const queryKey = [
    "reportOnlyReports",
    "configurationReportOnly",
    campaignId,
    platform,
  ];

  const queryFunction = () =>
    sendRequest<getConfigurationReportOnlyCampaignResponse>(
      "get",
      `/brand/report-only/report/configuration?platform=${platform}${
        campaignId ? `&campaignId=${campaignId}` : ""
      }`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
