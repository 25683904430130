import { sendRequest } from "../helper";

import { RuleById } from "../../typings";

type Result = {
  status: string;
  message: string;
  data: RuleById;
};

export const getRuleById = ({ id }: { id: string }) => {
  const queryKey = ["smart-replies", "rule", id];
  const queryFunction = () =>
    sendRequest<Result>(
      "get",
      `/brand/smart-replies/rule-by-id?ruleId=${id}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
