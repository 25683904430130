import React from "react";
import { Row, Col } from "antd";

import styles from "./commercialCard.module.css";

interface CommercialCardProps {
  item: {
    label: string;
    value: string;
  }[];
  type: "instagram" | "youtube";
}

const CommercialCard: React.FC<CommercialCardProps> = ({ item, type }) => {
  return (
    <div className={styles.container}>
      <Row className={styles.tile}>
        <Col
          span={type === "instagram" ? 12 : 8}
          style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}>
          <Row justify="center">
            <div>
              <p className={styles.value}>{item[0].value}</p>
              <p className={styles.label}>{item[0].label}</p>
            </div>
          </Row>
        </Col>
        <Col span={type === "instagram" ? 12 : 8}>
          <Row justify="center">
            <div>
              <p className={styles.value}>{item[1].value}</p>
              <p className={styles.label}>{item[1].label}</p>
            </div>
          </Row>
        </Col>
        {type !== "instagram" && (
          <Col span={8}>
            <Row
              justify="center"
              style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.06)" }}>
              <div>
                <p className={styles.value}>{item[2].value}</p>
                <p className={styles.label}>{item[2].label}</p>
              </div>
            </Row>
          </Col>
        )}
      </Row>
      {type === "instagram" && (
        <Row
          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.06)" }}
          className={styles.tile}>
          <Col span={12}>
            <Row
              justify="center"
              style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}>
              <div>
                <p className={styles.value}>{item[2].value}</p>
                <p className={styles.label}>{item[2].label}</p>
              </div>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="center">
              <div>
                <p className={styles.value}>{item[3].value}</p>
                <p className={styles.label}>{item[3].label}</p>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CommercialCard;
