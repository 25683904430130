import React from "react";
import { Radio, Select, Row, Space } from "antd";
import { useQuery } from "react-query";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { IoMdClose } from "react-icons/io";

import CheckBox from "../../Create/CreatorFilters/Checkbox";

import { useCampaign } from "../Context/campaignContext";
import { getNiche } from "../../../../handler/discovery";

import creatorDetailStyles from "./styles.module.css";

import styles from "../styles.module.css";

const { Option } = Select;

const CheckProps = (props: CustomTagProps) => {
  return (
    <div className={creatorDetailStyles.selectTag}>
      <span className={creatorDetailStyles.selectTagText}>{props.label}</span>
      <IoMdClose
        onClick={() => props.onClose()}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

const CreatorDetails = () => {
  const { campaign, setCampaign, error } = useCampaign();
  const [getNicheKey, getNicheFunction] = getNiche(campaign.campaign.platform);

  const { data: nicheData } = useQuery(getNicheKey, getNicheFunction);

  const handleButtonClick = (value: string, checked: boolean) => {
    const valToCheck = value.split(" ")[0].toLowerCase();
    if (checked) {
      const newList = campaign.campaign.filter.followerCategory.filter(
        (item) => {
          return item !== "all" && item !== valToCheck;
        }
      );

      return setCampaign((campaign) => ({
        ...campaign,
        campaign: {
          ...campaign.campaign,
          filter: { ...campaign.campaign.filter, followerCategory: newList },
        },
      }));
    } else {
      if (valToCheck === "all") {
        return setCampaign((campaign) => ({
          ...campaign,
          campaign: {
            ...campaign.campaign,
            filter: {
              ...campaign.campaign.filter,
              followerCategory: ["all", "nano", "micro", "macro", "celebrity"],
            },
          },
        }));
      }

      return setCampaign((campaign) => ({
        ...campaign,
        campaign: {
          ...campaign.campaign,
          filter: {
            ...campaign.campaign.filter,
            followerCategory: [
              ...campaign.campaign.filter.followerCategory,
              valToCheck,
            ],
          },
        },
      }));
    }
  };

  return (
    <div>
      {" "}
      <h2 className={styles.title}>Creator Details</h2>
      <p className={styles.subHeading}>
        What kind of creators are you looking for?
      </p>
      <div style={{ marginTop: "18px" }}>
        <p className={creatorDetailStyles.label}>
          {" "}
          Select category of influencer you would like to work with
        </p>
        <Select
          mode="multiple"
          size="large"
          allowClear
          style={{ width: "100%", marginTop: "6px" }}
          placeholder={"Select Community"}
          tagRender={(props) => CheckProps(props)}
          value={campaign.campaign.filter.niche}
          onChange={(val, _) =>
            setCampaign((campaign) => ({
              ...campaign,
              campaign: {
                ...campaign.campaign,
                filter: { ...campaign.campaign.filter, niche: val },
              },
            }))
          }>
          {nicheData?.data?.map((item) => {
            return <Option key={item.name}>{item.name}</Option>;
          })}
        </Select>
      </div>
      <div style={{ marginTop: "18px" }}>
        <p className={creatorDetailStyles.label}>Category (followers)</p>

        <Space size={8} style={{ width: "90%", marginTop: "16px" }} wrap>
          {followerOptions.map((item, idx) => {
            return (
              <CheckBox
                key={idx}
                value={item}
                checked={campaign.campaign.filter.followerCategory.includes(
                  item.split(" ")[0].toLowerCase()
                )}
                handleCheck={handleButtonClick}
                disabled={false}
              />
            );
          })}
        </Space>
        {error?.category && <p className={styles.error}>{error?.category}</p>}
      </div>
    </div>
  );
};

export default CreatorDetails;

const followerOptions = [
  "All",
  "Nano (1K-10K)",
  "Micro (10K-100K)",
  "Macro (100K - 1M)",
  "Celebrity (1M and above)",
];
