import { Steps } from "antd";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { getCampaign } from "../../../../handler/campaign";

const { Step } = Steps;

interface CreateStepsProps {
  currentStep: number;
  statusHistory: {
    onDateTime: string;
    status:
      | "Created-Campaign"
      | "Added-Diliverables"
      | "Added-Filters"
      | "Added-Commericals"
      | "Added-Brand-Guidelines"
      | "Added-Product"
      | "Active"
      | "Under-Review"
      | "Needs-Attention"
      | "Complete"
      | "Added-Commerical-Filters";
  }[];
}

const CreateSteps: React.FC<CreateStepsProps> = ({
  currentStep,
  statusHistory,
}) => {
  const { id: campaignId } = useParams();
  const navigate = useNavigate();

  const [getCampaignKey, getCampaignFn] = getCampaign({
    campaignId: campaignId || "",
  });

  const { data: campaign } = useQuery(getCampaignKey, getCampaignFn);

  const getCurrentStep = () => {
    if (campaign?.data.campaign.status === "Created-Campaign") {
      return 1;
    } else if (campaign?.data.campaign.status === "Added-Product") {
      return 2;
    } else if (campaign?.data.campaign.status === "Added-Diliverables") {
      return 3;
    } else if (campaign?.data.campaign.status === "Added-Commerical-Filters") {
      return 4;
    } else if (campaign?.data.campaign.status === "Added-Brand-Guidelines") {
      return 5;
    }
    return 0;
  };

  const getTagColor = (id: number) => {
    return getCurrentStep() === id
      ? "process"
      : getCurrentStep() > id
      ? "finish"
      : "wait";
  };

  const getStepLink = (id: number) => {
    if (id > getCurrentStep()) return "";
    const preLink = `/campaign/create/${campaignId}/`;
    let linkState = "";
    switch (id) {
      case 0:
        linkState = "";
        break;
      case 1:
        linkState = "add-products";
        break;
      case 2:
        linkState = "add-deliverables";
        break;
      case 3:
        linkState = "creators";
        break;
      case 4:
        linkState = "creators";
        break;

      default:
        linkState = "guidelines";
        break;
    }

    return preLink + linkState;
  };

  return (
    <Steps direction="vertical">
      <Step
        title="Campaign Details"
        status={getTagColor(0)}
        onStepClick={(index) => {
          if (getStepLink(index) === "") return;
          return navigate(getStepLink(index));
        }}
        style={{ cursor: "pointer" }}
      />
      <Step
        title="What’re you promoting?"
        status={getTagColor(1)}
        onStepClick={(index) => {
          if (getStepLink(index) === "") return;
          return navigate(getStepLink(index));
        }}
      />
      <Step
        title="Deliverables"
        status={getTagColor(2)}
        onStepClick={(index) => {
          if (getStepLink(index) === "") return;
          return navigate(getStepLink(index));
        }}
      />
      <Step
        title="Creator Details"
        status={getTagColor(3)}
        onStepClick={(index) => {
          if (getStepLink(index) === "") return;
          return navigate(getStepLink(index));
        }}
      />
      <Step
        title="Campaign Guidelines"
        status={getTagColor(4)}
        onStepClick={(index) => {
          if (getStepLink(index) === "") return;
          return navigate(getStepLink(index));
        }}
      />
    </Steps>
  );
};

export default CreateSteps;
