import React, { useRef, useEffect, useState } from "react";
import Lottie from "react-lottie";
import {
  Row,
  Col,
  Space,
  Button,
  Spin,
  Table,
  Divider,
  Tag,
  Tooltip,
  Popover,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import animationData from "../../assets/lottie/Rocket.json";
import { useQueryClient } from "react-query";

import { useInfiniteQuery } from "react-query";

import { getRules, getWorkflows } from "../../../handler/replies";

import { formatData, getDefaultLottieOptions, trimData } from "../../../utils";

import { ReplyWorkflow } from "../../../typings";
import { ColumnsType } from "antd/es/table";

import AddWorkflow from "../Modal/AddWorkflow";

import { format } from "date-fns";

import styles from "../Messages/messages.module.css";
import IgDeliverable from "../../ProfileAnalysis/IndividualPostsInsights/IgDeliverable";

const WorkflowTable = () => {
  const [workflowsKey, workflowsFn] = getWorkflows({ limit: 100 });
  const queryClient = useQueryClient();

  const [workflowModalProps, setWorkflowModalProps] = useState<{
    isView: boolean;
    isEdit: boolean;
    visible: boolean;
    workflowId?: string;
  }>({
    isEdit: false,
    isView: false,
    visible: false,
    workflowId: undefined,
  });

  const firstRender = useRef(true);

  const [loading, setLoading] = useState<boolean>(true);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    workflowsKey,
    workflowsFn,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.workflows.length ? lastPage.data.pageNo + 1 : null,
    }
  );

  //   useEffect(() => {
  //     if (!messagesDataLoading && firstRender.current) {
  //       firstRender.current = false;
  //       setLoading(false);
  //     }
  //   }, [messagesDataLoading]);

  console.log(data);

  //   if (messagesDataLoading) {
  //     return (
  //       <Row
  //         justify="center"
  //         style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
  //         <Lottie
  //           options={getDefaultLottieOptions(animationData)}
  //           height={300}
  //           width={370}
  //         />
  //       </Row>
  //     );
  //   }

  const columns: ColumnsType<ReplyWorkflow> = [
    {
      title: "Attached Media",
      // dataIndex: "message",
      key: "message",

      render: (_, { media }) => (
        <>
          <Popover
            style={{
              cursor: "pointer",
            }}
            content={
              <React.Fragment>
                <IgDeliverable
                  handleClick={() => {}}
                  isSelected={true}
                  type={media?.savedMedias?.at(0)?.mediaType || "IMAGE"}
                  url={media?.savedMedias?.at(0)?.url || ""}
                />
              </React.Fragment>
            }
            trigger="hover"
            placement="top">
            <div
              style={{
                cursor: "pointer",
                color: "#533af5",
                textDecoration: "underline",
              }}
              onClick={() => {
                window.open(media?.permalink, "_blank");
              }}>
              IG {media?.savedMedias?.at(0)?.mediaType}
            </div>
          </Popover>
        </>
      ),
    },

    {
      title: "Rules Name",
      // dataIndex: "followers",
      key: "uActions",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { rules }) => (
        <p className={styles.followers}>
          {" "}
          {trimData({ value: rules?.at(0)?.name || "", limit: 50 })}
        </p>
      ),
    },
    {
      title: "Created on On",
      // dataIndex: "joiningDate",
      key: "joiningDate",

      render: (_, { createdDateTime }) => (
        <p className={styles.followers}>
          {createdDateTime && (
            <>{format(new Date(createdDateTime), "d MMM, RRRR")}</>
          )}
        </p>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "followers",
      key: "Actions",

      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, record) => (
        <Space size={10} wrap>
          <Button
            key={"View"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setWorkflowModalProps({
                isEdit: false,
                isView: true,
                visible: true,
                workflowId: record?._id,
              })
            }>
            View
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            disabled
            onClick={() =>
              setWorkflowModalProps({
                isEdit: true,
                isView: false,
                visible: true,
                workflowId: record?._id,
              })
            }>
            Edit
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button key={"Delete"} style={{ borderRadius: 8 }} danger type="link">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div
      className={styles.g10}
      style={{
        height: "70vh",
        overflowY: "scroll",
      }}>
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        className={styles.g10}
        style={{ alignItems: "flex-start" }}
        hasMore={hasNextPage}
        loader={
          <Row justify="center">
            <Spin />
          </Row>
        }
        useWindow={false}>
        {isLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <Table
            columns={columns}
            dataSource={data?.pages?.flatMap(
              (pageItem) => pageItem.data?.workflows || []
            )}
            scroll={{ y: 700 }}
            pagination={false}
          />
        )}
        {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
      pageItem.data?.community?.map((dataItem) => (
        <CreatorCard creatorData={dataItem} platform={platform} />
      ))
    )} */}
      </InfiniteScroll>
      <AddWorkflow
        isVisible={workflowModalProps.visible}
        isView={workflowModalProps.isView}
        isEdit={workflowModalProps.isEdit}
        workflowId={workflowModalProps.workflowId}
        handleClose={() => {
          setWorkflowModalProps({
            isEdit: false,
            isView: false,
            visible: false,
            workflowId: undefined,
          });
        }}
      />
    </div>
  );
};

export default WorkflowTable;
