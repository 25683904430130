import { sendRequest } from "../helper";

import { transactionResult } from "../../typings";

type getBillProps = {
  campaignId: string;
  fromDate: string;
  toDate: string;
  text: string;
  paymentType: "Payment" | "Refund" | "";
  page: number;
};

export const getBill = (billProps: getBillProps) => {
  const queryKey = ["bill", billProps];
  const queryFunction = () =>
    sendRequest<transactionResult>(
      "put",
      `/brand/transaction/offer?limit=10&pageNo=${billProps.page}`,
      false,
      {
        campaignId: billProps.campaignId,
        fromDate: billProps.fromDate,
        toDate: billProps.toDate,
        text: billProps.text,
        paymentType: billProps.paymentType,

        sortOrder: "Desc",
      }
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
