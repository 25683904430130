import { sendRequest } from "../helper";

interface inviteInfluencerProps {
  platform: "youtube" | "instagram";
  saasIds: string[];
}

export const inviteInfluencer = ({
  platform,
  saasIds,
}: inviteInfluencerProps) => {
  return sendRequest(
    "post",
    `/brand/discovery/saas/influencer-connect-request`,
    false,
    {
      platform,
      saasIds,
    }
  ).then((res) => res.data);
};
