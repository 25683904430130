import { sendRequest } from "../../helper";

export type AddInfluencerToCampaignResult = {
  status: string;
  message: string;
  data: any;
};

export type RequestInfluencerToCampaignProps = {
  campaignId: string;
  username: string;
  platform: string;
  deliverables: string;
  isMediaRights: boolean;
  mediaRights?: string;
  notes: string;
};

export const addInfluencerToCampaign = (
  props: RequestInfluencerToCampaignProps
) => {
  console.log(props);
  return sendRequest<AddInfluencerToCampaignResult>(
    "post",
    "/campaign/influencer/request-price",
    false,
    {
      campaignId: props.campaignId,
      username: props.username,
      platform: props.platform,
      deliverables: props.deliverables,
      isMediaRights: props.isMediaRights,
      mediaRights: props.mediaRights,
      notes: props.notes,
    }
  ).then((res) => res.data);
};
