import React, { useState, useEffect } from "react";
import { Modal, Input, Upload, message, Switch } from "antd";

import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import cuid from "cuid";

import type { RcFile, UploadProps } from "antd/es/upload";
import { PlusOutlined } from "@ant-design/icons";

import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { addWorkFlowReq } from "../../../../../handler/campaign/applicants/";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Chip from "./Chip";
import TextEditor from "../../../../shared/TextEditor";

import { Requirement } from "../../../../../typings";

import styles from "./reqModal.module.css";

interface AddReqProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  id: string;
  deliverableId: string;
  initialReq?: Requirement;
  isView: boolean;
  workflowDeliverableData: any;
}

const AddReq: React.FC<AddReqProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  id,
  deliverableId,
  initialReq = {
    name: "",
    summary: "",
    mentions: [],
    caption: "",
    references: [],
    _id: "new-item",
    isDraftNeeded: true,
  },
  workflowDeliverableData,
  isView,
}) => {
  const queryClient = useQueryClient();

  const addReqMutation = useMutation(addWorkFlowReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      handleSubmit();
      clearAppState();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/campaign/${id}/requirements/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setReqSelected((reqSelected) => {
            return {
              ...reqSelected,
              references: [
                ...reqSelected.references,
                {
                  url: downloadURL,
                  type: (file as RcFile).type,
                },
              ],
            };
          });
        }
      );
    },
    accept: "image/png, image/jpeg, video/mp4",
    onRemove(file) {
      setReqSelected((formInput) => {
        return {
          ...formInput,
          references: reqSelected.references.filter(
            (reference) => reference.url !== file.url
          ),
        };
      });
    },
  };

  const [mentionInput, setMentionInput] = useState<string>("");
  const [reqSelected, setReqSelected] = useState<Requirement>(initialReq);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const clearAppState = () => {
    setEditorState(EditorState.createEmpty());
    setMentionInput("");
    setReqSelected({
      name: "",
      summary: "",
      mentions: [],
      caption: "",
      references: [],
      _id: "new-item",
      isDraftNeeded: true,
    });
  };

  const onChipClose = (id: string) => {
    setReqSelected((reqSelected) => {
      return {
        ...reqSelected,
        mentions: reqSelected.mentions.filter((item) => item !== id),
      };
    });
  };

  const handleFormSubmit = () => {
    addReqMutation.mutate({
      deliverableId: deliverableId,
      isDraftNeeded: reqSelected.isDraftNeeded,
      caption: reqSelected.caption,
      mentions: reqSelected.mentions,
      name: reqSelected.name,
      references: reqSelected.references,
      summary: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const { TextArea } = Input;

  useEffect(() => {
    setReqSelected(initialReq);
    const blocksFromHtml = convertFromHTML(initialReq.summary);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [initialReq._id]);

  return (
    <>
      <Modal
        title={`${isView ? "View" : "Add"} Requirements`}
        visible={isVisible}
        onOk={() => {
          if (isView) {
            return handleCancel();
          }
          return handleFormSubmit();
        }}
        okText={"Add"}
        onCancel={() => {
          clearAppState();
          handleCancel();
        }}
        okButtonProps={{
          loading: addReqMutation.isLoading,
          disabled:
            reqSelected.name.trim() === "" || reqSelected.name.trim() === "",
        }}
        centered>
        <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
          <>
            <div
              className={styles.item}
              style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <p className={styles.heading}>
                Requirement Name<span style={{ color: "red" }}> *</span>
              </p>
              <Input
                style={{ width: 200, marginLeft: 10 }}
                placeholder={"Add a requirement"}
                disabled={isView}
                value={reqSelected.name}
                onChange={(event) =>
                  setReqSelected((item) => {
                    return { ...item, name: event.target.value };
                  })
                }
              />
            </div>
            <div className={styles.item}>
              <p className={styles.heading}>Deliverable Details</p>
              {workflowDeliverableData !== undefined && (
                <div className={styles.description}>
                  <p>
                    {workflowDeliverableData?.deliverablesDetails.duration && (
                      <>
                        [{workflowDeliverableData?.deliverablesDetails.duration}
                        ]
                      </>
                    )}{" "}
                    {workflowDeliverableData?.deliverablesDetails?.typeDel}
                  </p>
                  {workflowDeliverableData?.deliverablesDetails
                    ?.contentRights !== "Not Needed" &&
                    workflowDeliverableData?.deliverablesDetails
                      ?.contentRights !== undefined && (
                      <p>
                        [
                        {
                          workflowDeliverableData?.deliverablesDetails
                            .contentRights
                        }
                        ] Content Rights
                      </p>
                    )}
                </div>
              )}
            </div>

            <div className={styles.item}>
              <p className={styles.heading}>Brief</p>
              <p className={styles.description}>
                {workflowDeliverableData?.deliverablesDetails.description}
              </p>
            </div>
            <div className={styles.draftReq}>
              <p>Creator needs to send draft</p>
              <span className="">
                <Switch
                  checked={reqSelected.isDraftNeeded}
                  disabled={isView}
                  onChange={() =>
                    setReqSelected((reqSelected) => {
                      return {
                        ...reqSelected,
                        isDraftNeeded: !reqSelected.isDraftNeeded,
                      };
                    })
                  }
                />
              </span>
            </div>

            <div className={styles.item}>
              <p className={styles.heading}>Add Requirements</p>

              {!isView ? (
                <TextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  placeholder="Add your requirements here"
                />
              ) : (
                <div
                  style={{ wordBreak: "break-all" }}
                  dangerouslySetInnerHTML={{
                    __html: reqSelected.summary,
                  }}
                />
              )}
            </div>

            <div className={styles.item}>
              <p className={styles.heading}>Caption</p>
              <TextArea
                size="middle"
                placeholder="Caption"
                autoSize={{ minRows: 3 }}
                value={reqSelected.caption}
                onChange={(event) =>
                  setReqSelected((reqSelected) => {
                    return { ...reqSelected, caption: event.target.value };
                  })
                }
                disabled={isView}
              />
            </div>
            <div className={styles.item}>
              <p className={styles.heading}>Mentions</p>
              <Input
                value={mentionInput}
                onChange={(event) => setMentionInput(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setReqSelected((reqSelected) => {
                      return {
                        ...reqSelected,
                        mentions: [...reqSelected.mentions, mentionInput],
                      };
                    });
                    setMentionInput("");
                  }
                }}
                disabled={isView}
              />
              <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                {reqSelected.mentions.map((item) => {
                  return <Chip text={item} onclose={onChipClose} />;
                })}
              </div>
            </div>
            <div className={styles.item}>
              <p className={styles.heading}>Add References (Optional)</p>
              <Upload
                listType="picture-card"
                fileList={reqSelected.references.map((imgItem) => {
                  return {
                    uid: imgItem.url,
                    name: imgItem.type,
                    status: "done",
                    url: imgItem.url,
                    type: imgItem.type,
                  };
                })}
                {...UploadButtonProps}
                disabled={isView}>
                {reqSelected.references.length >= 8 ? null : uploadButton}
              </Upload>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default AddReq;
