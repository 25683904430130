import React, { useState } from "react";
// import { useQuery } from "react-query";
import { MdOutlineInfo } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

import Navbar from "../shared/Navbar";
import Title from "../shared/Title";
import Selector from "./Selector";
import Messages from "./Messages";
import Catalogues from "./Catalogues";
import Rules from "./Rules";
import WorkflowTable from "./WorkflowTable";

import styles from "./workflows.module.css";

export type headerType = "messages" | "catalogue" | "rule";

const Invite: React.FC = (): React.ReactElement => {
  const [searchParams, _] = useSearchParams();
  const selectedSection = searchParams.get("type") || "messages";

  return (
    <main className={styles.main}>
      <Navbar selectedItem="workflows" />
      <div className={styles.innerContainer}>
        <section style={{ paddingBlock: 20 }}>
          <Title
            name="Workflows"
            tagline="Create Automation for the selected media Posts "
            icon={<MdOutlineInfo color="rgba(0, 0, 0, 0.50)" />}
          />
        </section>
        <Selector selectedState={selectedSection} />
        <div className={styles.childContainer}>
          {selectedSection === "messages" ? (
            <Messages />
          ) : selectedSection === "catalogue" ? (
            <Catalogues />
          ) : selectedSection === "rule" ? (
            <Rules />
          ) : (
            <WorkflowTable />
          )}
        </div>
      </div>
    </main>
  );
};
export default Invite;
