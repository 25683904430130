import { sendRequest } from "../helper";
import { CampaignResponse, Product } from "../../typings";

export type updateProductProps = {
  campaignId: string;
  product: Product;
};

export const updateProduct = ({ campaignId, product }: updateProductProps) => {
  //   const queryKey = ["campaign", "create"];
  //   const updatedProducts = products.map((product) => {
  //     return { title: product.title, price: product.price, link: product.link };
  //   });
  return sendRequest<CampaignResponse>(
    "patch",
    `/brand/campaign/create-campaign-form/product/${campaignId}/${product._id}`,
    false,
    {
      productTitle: product.productTitle,
      price: product.price,
      productLink: product.productLink,
    }
  ).then((res) => res.data);
  //   return [queryKey, queryFn];
};
