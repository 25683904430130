import { InfluencerMediaType } from "../../typings";
import { sendRequest } from "../helper";

interface getInfluencerMediaResponse {
  data: InfluencerMediaType[];
  message: string;
  status: string;
}

export const getInfluencerMedia = ({
  campaignId,
  socialMediaId,
}: {
  campaignId: string;
  socialMediaId: string;
}) => {
  const queryKey = ["campaigns", "report", campaignId, socialMediaId];

  const queryFunction = () =>
    sendRequest<getInfluencerMediaResponse>(
      "get",
      `/brand/report-only/report/influencer-content?campaignId=${campaignId}&socialMediaId=${socialMediaId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
