import React, { useState, useEffect } from "react";
import { Dropdown, Button, Space, Menu, DatePicker } from "antd";
import moment from "moment";

import { DownOutlined } from "@ant-design/icons";
import { SearchQuery } from "../index";

const { RangePicker, MonthPicker, YearPicker } = DatePicker;

interface DateSelectorProps {
  searchQuery: SearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  searchQuery,
  setSearchQuery,
}) => {
  const [openKey, setOpenKey] = useState<string>("");
  const [buttonText, setButtonText] = useState("All time");

  useEffect(() => {
    if (searchQuery.fromDate === "" && searchQuery.toDate === "") {
      return;
    }
    if (
      moment(searchQuery.fromDate).isSame(
        moment(searchQuery.toDate).subtract(7, "days")
      )
    ) {
      return setButtonText("Last 7 days");
    }
    if (
      moment(searchQuery.fromDate).month() ===
      moment(searchQuery.toDate).month()
    ) {
      return setButtonText(moment(searchQuery.fromDate).format("MMM"));
    }
    if (
      moment(searchQuery.fromDate).isSame(
        moment(searchQuery.toDate).subtract(30, "days")
      )
    ) {
      return setButtonText(
        ` ${moment(searchQuery.fromDate).format("MMM")}-${moment(
          searchQuery.toDate
        ).format("MMM")}`
      );
    }

    // if (
    //   moment(searchQuery.fromDate).year() === moment(searchQuery.toDate).year()
    // ) {
    //   return setButtonText(
    //     `${moment(searchQuery.fromDate).format("YYYY")}-${moment(
    //       searchQuery.toDate
    //     ).format("YYYY")}`
    //   );
    // }

    return setButtonText("Custom Range");
    // if(searchQuery.)
  }, [searchQuery.fromDate, searchQuery.toDate]);

  const menu = (
    <Menu
      items={[
        {
          label: (
            <p
              onClick={() => {
                SetOpen(false);
                setOpenKey("");
                setSearchQuery((searchQuery) => {
                  return { ...searchQuery, fromDate: "", toDate: "" };
                });
              }}>
              All time
            </p>
          ),
          key: "0",
        },
        {
          label: (
            <p
              onClick={() => {
                SetOpen(false);
                setOpenKey("");
                setSearchQuery((searchQuery) => {
                  return {
                    ...searchQuery,
                    fromDate: moment
                      .utc(Date.now())
                      .subtract(7, "days")
                      .format(),
                    toDate: moment.utc(Date.now()).format(),
                  };
                });
              }}>
              Last 7 days
            </p>
          ),
          key: "1",
        },
        {
          label: (
            <p
              onClick={() => {
                SetOpen(false);
                setOpenKey("");
                setSearchQuery((searchQuery) => {
                  return {
                    ...searchQuery,
                    fromDate: moment
                      .utc(Date.now())
                      .subtract(30, "days")
                      .format(),
                    toDate: moment.utc(Date.now()).format(),
                  };
                });
              }}>
              Last 30 days
            </p>
          ),
          key: "2",
        },

        {
          label: <span onClick={() => setOpenKey("4")}>Financial Year</span>,
          key: "4",
          children: [
            {
              label: (
                <YearPicker
                  onSelect={(value) => {
                    setOpenKey("");
                    SetOpen(false);

                    setSearchQuery((searchQuery) => {
                      return {
                        ...searchQuery,
                        fromDate: moment.utc(value).startOf("year").format(),
                        toDate: moment.utc(value).endOf("year").format(),
                      };
                    });
                  }}
                  allowClear={false}
                  value={moment(searchQuery.fromDate || Date.now())}
                />
              ),
              key: "setting:2",
            },
          ],
        },
        {
          label: <span onClick={() => setOpenKey("5")}>Custom Range</span>,
          key: "5",
          children: [
            {
              label: (
                <RangePicker
                  onChange={(value) => {
                    setOpenKey("");
                    SetOpen(false);
                    if (value) {
                      setSearchQuery((searchQuery) => {
                        return {
                          ...searchQuery,
                          fromDate: moment.utc(value[0]).format(),
                          toDate: moment.utc(value[1]).format(),
                        };
                      });
                    } else {
                      setSearchQuery((searchQuery) => {
                        return {
                          ...searchQuery,
                          fromDate: "",
                          toDate: "",
                        };
                      });
                    }
                  }}
                  value={[
                    moment.utc(searchQuery.fromDate || Date.now()),
                    moment.utc(searchQuery.toDate || Date.now()),
                  ]}
                  allowClear={false}
                />
              ),
              key: "setting:3",
            },
          ],
        },
        {
          label: <span onClick={() => setOpenKey("7")}>Month</span>,
          key: "7",
          children: [
            {
              label: (
                <MonthPicker
                  onSelect={(value) => {
                    setOpenKey("");
                    SetOpen(false);

                    setSearchQuery((searchQuery) => {
                      return {
                        ...searchQuery,
                        fromDate: value.utc().startOf("month").toString(),
                        toDate: value.utc().endOf("month").toString(),
                      };
                    });
                  }}
                  allowClear={false}
                  value={moment(searchQuery.fromDate || Date.now())}
                />
              ),
              key: "setting:1",
            },
          ],
        },
      ]}
      triggerSubMenuAction={"click"}
      //   selectedKeys={["setting:3"]}
      openKeys={[openKey]}
    />
  );

  const [open, SetOpen] = useState<boolean>(false);
  return (
    <Dropdown overlay={menu} trigger={["click"]} visible={open}>
      <Button
        onClick={() => {
          SetOpen((open) => !open);
          setOpenKey("");
        }}>
        <Space>
          {buttonText}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DateSelector;
