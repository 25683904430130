import { sendRequest } from "../../../helper";

import { WorkFlowTimeline } from "../../../../typings";

interface getTimelineDetailsProps {
  finalizedOffersId: string;
}

interface getTimelineDetailsResponse {
  status: string;
  message: string;
  data: WorkFlowTimeline[];
}

export const getTimelineDetailsV2 = ({
  finalizedOffersId,
}: getTimelineDetailsProps) => {
  const queryKey = ["tester", "timeline", finalizedOffersId];

  const queryFunction = () =>
    sendRequest<getTimelineDetailsResponse>(
      "get",
      `/brand/execution/workflow/timeline?finalizedOffersId=${finalizedOffersId}`,
      false,
      {},
      null,
      true
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
