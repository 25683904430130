export const CardType = {
  invitationSentType: "Invitation-Sent",
  invitationRejectedType: "Invitation-Rejected",
  invitationAcceptedType: "Invitation-Accepted",
  campaignStartedType: "Campaign-Started",
  deliverableRequirementSentType: "Deliverable-Requirement-Sent",
  deliverableRequirementEditType: "Deliverable-Requirement-Edit",
  deliverableDraftSentType: "Deliverable-Drafts-Sent",
  deliverableDraftRequestedChangesType: "Deliverable-Drafts-Request-Changes",
  deliverableDraftApprovedType: "Deliverable-Drafts-Approved",
  deliverableLiveSentType: "Deliverable-Live-Link-Sent",
  deliverableLiveApprovedType: "Deliverable-Live-Link-Approved",
  deliverableLiveRequestedChangesType: "Deliverable-Live-Link-Request-Changes",
  campaignCompletedType: "Campaign-Completed",
  mediaType: "media",
};
