import React, { useState } from "react";
import { Modal, Row } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import styles from "./styles.module.css";

import Header from "./Header";
import SingleInvite from "./SingleInvite";
import BulkInvite from "./BulkInvite";
import ActivateScreen from "../../Billing/BillingSettings/Modals/ActivateScreen";

import { getUser } from "../../../handler/getUser";

interface InviteModalProps {
  isVisible: boolean;
  handleCancel: () => void;
}

export type HeaderType = "single" | "bulk";

const InviteModal: React.FC<InviteModalProps> = ({
  isVisible,
  handleCancel,
}) => {
  const [selectedSection, setSelectedSection] = useState<HeaderType>("single");
  const [_, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [userKey, userFn] = getUser();

  const { data: userData } = useQuery(userKey, userFn);

  if (userData?.data?.signUpStatus !== "businessVerified") {
    return <ActivateScreen show={isVisible} handleClose={handleCancel} />;
  }

  return (
    <Modal
      width={1000}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28, minHeight: "450px" }}
      onCancel={() => {
        handleCancel();
        // return setTouched(false);
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={styles.heading}>Invite Creators</p>
      </Row>
      <Header selectedState={selectedSection} setState={setSelectedSection} />
      <div style={{ marginTop: 20 }}>
        {selectedSection === "single" ? (
          <SingleInvite
            handleCancel={handleCancel}
            isVisible={isVisible}
            handleSubmit={() => {
              setSearchParams({ type: "influencers" });
              queryClient.invalidateQueries(["community", "invited"]);
            }}
          />
        ) : (
          <BulkInvite
            handleCancel={handleCancel}
            handleSubmit={() => {
              setSearchParams({ type: "influencers" });
              queryClient.invalidateQueries(["community", "invited"]);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default InviteModal;
