import React, { useState, useRef } from "react";
import { Space, Divider, Row, Col } from "antd";
// import { Carousel } from "react-responsive-carousel";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdTimeline,
} from "react-icons/md";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import Lottie from "react-lottie";
import animationData from "../../../../assets/lottie/threeDotLoading.json";

import { useReportOnly } from "../../Context/ReportOnlyContext";

import MetricCard from "./MetricCard";
import AddInfluencerModal from "../../Modals/AddInfluencer";
import YtGraphs from "./YtGraphs";

import styles from "./styles.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { formatData, getDefaultLottieOptions } from "../../../../../utils";
import ReportButton from "../../ReportButton";

import LineGraph from "../../../../ProfileAnalysis/BasicInsights/LineGraph";
import { Doughnut } from "react-chartjs-2";
import StatsCard from "../../../../ProfileAnalysis/AudienceInsights/StatsCard";

const OverViewSection = () => {
  const {
    tableColumn,
    tableRow,
    isLoading,
    platform,
    campaignId,
    totalAggregateObject,
    advanceMetricsData,
    advanceMetricsDataLoading,
  } = useReportOnly();

  const [influencerModalVisible, setInfluencerModalVisible] =
    useState<boolean>(false);

  const elementRef = useRef<HTMLDivElement>(null);

  let scrollAmount = 0;
  let scrollMin = 0;
  let scrollMax = elementRef?.current?.clientWidth;

  const handleNext = () => {
    if (elementRef)
      elementRef?.current?.scrollTo({
        top: 0,
        left: Math.max((scrollAmount += 150), scrollMax || 150),
        behavior: "smooth",
      });
  };

  const handlePrev = () => {
    if (elementRef)
      elementRef?.current?.scrollTo({
        top: 0,
        left: Math.max((scrollAmount -= 150), scrollMin),
        behavior: "smooth",
      });
  };

  return (
    <div className={styles.gridContainer}>
      <p className={styles.title}>Overview</p>
      <Space size={10}>
        <img
          src={`/assets/icons/${
            platform === "instagram" ? "meta" : "google"
          }.svg`}
          alt="google"
          width={48}
          height={24}
        />
        <p className={styles.desc}>
          We get the data from{" "}
          {platform === "instagram" ? "Facebook" : "Google"} APIs.
          <br />
          We provide with only first part consent based data.{" "}
          <span className={styles.learnMore}>Learn More</span>
        </p>
      </Space>
      <Divider style={{ margin: 0 }} />

      {isLoading || advanceMetricsDataLoading ? (
        <div style={{ height: "156px" }}>
          <Lottie
            options={getDefaultLottieOptions(animationData)}
            height={50}
            width={100}
          />
        </div>
      ) : (
        <>
          {tableRow.length === 0 || advanceMetricsData?.data?.length === 0 ? (
            <div style={{ display: "grid", gap: "20px" }}>
              <p className={styles.title}>No medias added</p>
              <Space size={12} align="center">
                {platform === "instagram" ? (
                  <AiOutlineInstagram size={20} color="#333333" />
                ) : (
                  <AiOutlineYoutube size={20} color="#333333" />
                )}
                <p className={styles.desc}>
                  Medias added to the report
                  <br /> for this campaign
                </p>
              </Space>
              <ReportButton
                type="primary"
                onClick={() => setInfluencerModalVisible(true)}
                style={{ width: "fit-content" }}>
                Add Media
              </ReportButton>
            </div>
          ) : (
            <>
              {" "}
              <Space size={0} style={{ margin: "20px 0" }} align="start">
                <MdKeyboardArrowLeft
                  color=" rgba(0, 0, 0, 0.5)"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={handlePrev}
                />
                <p className={styles.titleGrey}>Metrics</p>
                <MdKeyboardArrowRight
                  color=" rgba(0, 0, 0, 0.5)"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={handleNext}
                />
              </Space>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                  overflowX: "scroll",
                  paddingBottom: "20px",
                  scrollbarWidth: "none",
                }}
                ref={elementRef}
                className={styles.check}>
                {platform === "instagram" ? (
                  <>
                    {tableColumn
                      .filter(
                        (item) =>
                          item.key !== "influencer" && item.key !== "type"
                      )
                      .map((item) => (
                        <MetricCard
                          title={item.title}
                          desc={item.desc || ""}
                          metric={totalAggregateObject[item.key]}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    <YtGraphs />

                    {/* {temp_date.map((el, key) => {
                      return (
                        <>
                          {el.title === "views_per_day" ? (
                            <LineGraph
                              key={key}
                              title="Views per day"
                              optionsItemArray={[]}
                              selectedOption={""}
                              labelArray={getLabelsArray(getData().length)}
                              metric={formatData(
                                getData().reduce(
                                  (prev, current) => current + prev,
                                  0
                                ) || 0,
                                "a"
                              )}
                              metricLabel={`View per day`}
                              description={
                                <>
                                  <p style={{ color: "#398200" }}>
                                    Good follwing growth <br />
                                    <strong>+12%</strong>
                                  </p>
                                </>
                              }
                              graphData={getData()}
                              secondaryDescription={<p>Views per day</p>}
                              secondaryIcon={
                                <MdTimeline size={15} color="#000000B2" />
                              }
                              barColor="#398200"
                            />
                          ) : el.title === "views_by_subscribers" ? (
                            <div className={styles.container} key={key}>
                              <Row
                                justify="space-between"
                                style={{ width: "100%" }}
                                gutter={[10, 10]}>
                                <Col span={24}>
                                  <p className={styles.label}>
                                    Views by subscribers
                                  </p>
                                </Col>
                                <Col span={24}>
                                  <p className={styles.sublabel}>
                                    <MdTimeline size={15} color="#000000B2" />
                                    Views by subscribers
                                  </p>
                                </Col>
                              </Row>
                              <Doughnut
                                options={{
                                  plugins: {
                                    legend: { position: "top" },
                                    datalabels: {
                                      anchor: "end",
                                      align: "start",
                                      formatter: (value) =>
                                        formatData(value || 0, "a"),
                                      font: {
                                        size: 16,
                                        style: "normal",
                                      },
                                    },
                                  },
                                }}
                                data={{
                                  labels: [
                                    "Non Subscribers",
                                    "Subscribers",
                                    "Unknown",
                                  ],
                                  datasets: [
                                    {
                                      label: "No",
                                      data: [
                                        Math.floor(Math.random() * 1000 + 1),
                                        Math.floor(Math.random() * 1000 + 1),
                                        Math.floor(Math.random() * 200 + 1),
                                      ],
                                      backgroundColor: [
                                        "#FF6384",
                                        "#FFCD56",
                                        "#36A2EB",
                                      ],
                                      borderColor: [
                                        "#FF6384",
                                        "#FFCD56",
                                        "#36A2EB",
                                      ],
                                      borderWidth: 1,
                                      borderJoinStyle: "round",
                                      borderRadius: 10,
                                      hoverOffset: 4,
                                      offset: 0,
                                    },
                                  ],
                                }}
                              />
                            </div>
                          ) : (
                            <div className={styles.graphContainer} key={key}>
                              <p className={styles.graphLabel}>
                                Country wise split
                              </p>
                              <StatsCard
                                items={[
                                  {
                                    label: "India",
                                    percent: 84,
                                  },
                                  {
                                    label: "USA",
                                    percent: 77,
                                  },
                                  {
                                    label: "Austria",
                                    percent: 62,
                                  },
                                  {
                                    label: "Brazil",
                                    percent: 59,
                                  },
                                  {
                                    label: "Canada",
                                    percent: 30,
                                  },
                                  {
                                    label: "UAE",
                                    percent: 17,
                                  },
                                  {
                                    label: "Spain",
                                    percent: 12,
                                  },
                                  {
                                    label: "France",
                                    percent: 3,
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </>
                      );
                    })} */}
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
      <AddInfluencerModal
        campaignId={campaignId}
        setVisible={setInfluencerModalVisible}
        visible={influencerModalVisible}
        platform={platform}
      />
    </div>
  );
};

export default OverViewSection;
