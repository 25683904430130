import React, { useState } from "react";
import { Collapse, Tag, Button, Row } from "antd";
import { useQuery } from "react-query";
import { format } from "date-fns";

import { getTimelineDetailsV2 } from "../../../../../../handler/campaign/applicants";
import { getCampaign } from "../../../../../../handler/campaign";

import ReqModal from "../../ReqModal/ReqModalUpdated";
import ReqModalTimeline from "../../ReqModalTimeline";
import ReqModalNoDel from "../../ReqModalNoDel";

import styles from "../tile.module.css";

interface TimelineProps {
  requirementsTimeLine: {
    name: string;
    type: string;
    createDateTime: string;
    requirementId: string;
    _id: string;
    expectedClosureDateTime?: string;
  }[];
  campaignId: string;
  deliverableId: string;
  workflowId: string;
}

const TimeLine: React.FC<TimelineProps> = ({
  requirementsTimeLine,
  campaignId,
  deliverableId,
  workflowId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [requirementId, setRequirementId] = useState<string>("");
  const [isEdit, setEdit] = useState<boolean>(false);

  return (
    <div
      style={{
        borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
        marginLeft: "10px",
        paddingLeft: "20px",
        display: "grid",
        gap: "16px",
      }}>
      {requirementsTimeLine.map((item, idx) => {
        return (
          <Row justify="space-between" key={idx}>
            <div>
              <p className={styles.subTileHeading}>{item.name}</p>
              <p className={styles.description}>
                Sent on:{" "}
                {format(new Date(item.createDateTime), "d LLLL, uuuu ")}
              </p>
              {item.expectedClosureDateTime && (
                <p className={styles.description}>
                  Deadline:{" "}
                  {format(
                    new Date(item?.expectedClosureDateTime),
                    "d LLLL, uuuu "
                  )}
                </p>
              )}
            </div>
            <div>
              <Button
                type="link"
                style={{ padding: "0 8px" }}
                onClick={() => {
                  setRequirementId(item.requirementId);
                  setEdit(false);
                  setVisible(true);
                }}>
                View
              </Button>
              {/* {idx === requirementsTimeLine.length - 1 && (
                <Button
                  type="link"
                  style={{ padding: "0 8px" }}
                  onClick={() => {
                    setRequirementId(item.requirementId);
                    setEdit(true);
                    setVisible(true);
                  }}>
                  Edit
                </Button>
              )} */}
            </div>
          </Row>
        );
      })}
      {visible && (
        <ReqModalTimeline
          isVisible={visible}
          handleCancel={() => setVisible(false)}
          handleSubmit={() => setVisible(false)}
          id={campaignId}
          deliverableId={deliverableId}
          requirementId={requirementId}
          isView={!isEdit}
          workflowId={workflowId}
        />
      )}
    </div>
  );
};

interface RequirementsTileProps {
  offerId: string;
  campaignId: string;
  isCompleted: boolean;
}

const RequirementsTile: React.FC<RequirementsTileProps> = ({
  offerId,
  campaignId,
  isCompleted,
}) => {
  const { Panel } = Collapse;
  const [getTimelineDetailsKey, getTimelineDetailsFn] = getTimelineDetailsV2({
    finalizedOffersId: offerId,
  });

  const [getCampaignKey, getCampaignFn] = getCampaign({ campaignId });

  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFn, {
    enabled: Boolean(campaignId),
  });

  const { data: timelineData } = useQuery(
    getTimelineDetailsKey,
    getTimelineDetailsFn,
    {
      enabled: offerId !== "",
    }
  );

  const getTagColor = (state: string) => {
    if (state === "Pending") {
      return "";
    }
    return "green";
  };

  const [visible, setVisible] = useState<boolean>(false);
  // const [selectedApplicantId, setSelectedApplicantId] = useState<string>("");
  // const [selectedDeliverableId, setSelectedDeliverableId] =
  //   useState<string>("");

  return (
    <div>
      {!isCompleted && (
        <Row justify="end">
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
            style={{ marginBlock: "10px", borderRadius: 8 }}>
            Send New Requirement
          </Button>
        </Row>
      )}

      <Collapse
        ghost
        bordered={false}
        expandIconPosition={"end"}
        defaultActiveKey={
          timelineData?.data.map((_, idx) => idx) || ["0", "1", "2", "3", "4"]
        }
        style={{ display: "grid", gap: "12px" }}
        expandIcon={() => {
          return null;
        }}>
        {timelineData?.data.map((item, idx) => {
          return (
            <Panel
              header={
                <p className={styles.tileHeader}>
                  <span className={styles.tileNumber}>{idx + 1}</span>
                  {`${item.deliverablesType}`}
                </p>
              }
              key={idx}
              style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid rgba(157, 157, 157,0.4)",
              }}
              extra={
                <Tag
                  color={getTagColor(item.requirements.status)}
                  style={{ borderRadius: "4px" }}>
                  {item.requirements.status}
                </Tag>
              }>
              {item.requirements.status === "Send" ? (
                <TimeLine
                  requirementsTimeLine={item.requirementsTimeLine}
                  campaignId={campaignId}
                  deliverableId={item.deliverableId}
                  workflowId={item._id || ""}
                />
              ) : (
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    // setSelectedDeliverableId(item.deliverableId);
                    setVisible(true);
                  }}>
                  Send Requirements
                </Button>
              )}
            </Panel>
          );
        })}
      </Collapse>

      {visible && (
        <>
          <ReqModalNoDel
            campaignId={campaignId}
            handleCancel={() => setVisible(false)}
            handleSubmit={() => setVisible(false)}
            isView={false}
            isVisible={visible}
            platform={campaignData?.data?.campaign?.platform || "instagram"}
            applicantIds={[offerId]}
          />
        </>
      )}
    </div>
  );
};

export default RequirementsTile;
