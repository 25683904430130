export const INSTANT_SEARCH_INDEX_NAME = "platform-discovery";
export const INSTANT_SEARCH_QUERY_SUGGESTIONS =
  "platform-discovery_query_suggestions";
export const INSTANT_SEARCH_USERNAME_FACET = "socialMediaName";
export const INSTANT_SEARCH_YT_PLATFORM_FACET = "youtube";
export const INSTANT_SEARCH_INSTA_PLATFORM_FACET = "instagram";
export const INSTANT_SEARCH_PLATFORM_FACET = "socialMediaData";
export const preDefinedFilters = {
  isSocialMediaConnected: ["true"],
  isSocialMediaRevoked: ["false"],
  isDeliverablePriceApproved: ["true"],
};

export const INSTANT_SEARCH_SAAS_INDEX_NAME = "instagram-main-v2";
export const INSTANT_SEARCH_SAAS_FULL_NAME = "full_name";
export const INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET = "isPhoneNumber";
