import { sendRequest } from "../helper";
import { CampaignReport } from "../../typings";

interface CampaignsResponse {
  status: string;
  message: string;
  data: CampaignReport;
}

type getCampaignReportProps = {
  campaignId: string;
};

export const getCampaignReport = ({ campaignId }: getCampaignReportProps) => {
  const queryKey = ["campaign", "reports", campaignId];

  const queryFunction = () =>
    sendRequest<CampaignsResponse>(
      "get",
      `/brand/report-only/campaign/by-id?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
