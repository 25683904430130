import React from "react";
import clsx from "clsx";
import { Space } from "antd";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import ReportButton from "../../Report/ReportButton";

import { ApplicantUpdated } from "../../../../typings";

import styles from "../../Report/Modals/SharedComponents/CreatorCard/styles.module.css";

import func from "../../../../utils/socialMediaUrlGen";

import { formatData } from "../../../../utils";

const CreatorCard: React.FC<{
  creator: ApplicantUpdated;
  isSelected: boolean;
  handleClick: (applicant: ApplicantUpdated) => void;
  platform: "instagram" | "youtube";
}> = ({ isSelected, creator, handleClick, platform }) => {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(styles.container, { [styles.selectedItem]: isSelected })}
      id={creator?._id}
      onClick={() => {
        handleClick(creator);
      }}
    >
      <Space size={10} align="center">
        <img
          className={styles.profile}
          alt="profile"
          src={creator?.influencer?.profilePicUrl}
        />
        <div style={{ display: "grid", gap: "6.5px" }}>
          <p className={styles.name}>{creator?.influencerName}</p>

          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {platform === "instagram" ? (
              <AiOutlineInstagram size={20} color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <AiOutlineYoutube size={20} color="rgba(0, 0, 0, 0.5)" />
            )}

            <p className={styles.username}>
              <p style={{ color: "#333" }}>{creator?.socialMediaName}</p>
            </p>
          </div>
          <p className={styles.followers}>
            {formatData(creator?.follower, "a")} Followers
          </p>
        </div>
      </Space>
      <div style={{ display: "grid", gap: "8.5px" }}>
        <ReportButton
          ghost
          target="_blank"
          style={{ textDecoration: "underline" }}
          onClick={() =>
            navigate(`/influencerAnalysis/${creator?.influencerId}`)
          }
        >
          Open Profile
        </ReportButton>
      </div>
    </div>
  );
};

export default CreatorCard;
