import React, { useState } from "react";
import styles from "./card.module.css";

import { Button, Card, Col, message, Row, Tag } from "antd";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { withdrawOffer } from "../../../../../handler/campaign/offer";
import { ChatCard } from "../../../../../typings";
import { format } from "date-fns";
import { AxiosError } from "axios";

interface InvitationSentCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    ref?: { offerId?: string };
    messageId: string;
    type: string;
    cardTimestamp?: string;
  };
}

const InvitationSentCard: React.FC<InvitationSentCardProps> = ({ item }) => {
  const [getChatCardKey, getChatCardDataFn] = getChatCardData({
    ref: item.ref,
    messageId: item.messageId,
    type: item.type,
  });
  const { data: cardData } = useQuery(getChatCardKey, getChatCardDataFn);

  const queryClient = useQueryClient();
  const [isWithdrawn, setIsWithdrawn] = useState<boolean>(false);
  const withdrawOfferMutation = useMutation(withdrawOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries("applicants");
      message.info("Offer Withdrawn");
      setIsWithdrawn(true);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      {cardData && (
        <div className={styles.card}>
          <Card
            bodyStyle={{
              fontWeight: 400,
              padding: "12px 16px",
            }}
            style={{
              borderRadius: "10px",
              borderWidth: "",
            }}
            headStyle={{
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "10px 10px 0 0",
              padding: "0px 16px",
            }}
            title={
              <div style={{ display: "grid", gap: "5px" }}>
                <p style={{ fontWeight: 600, fontSize: "14px" }}>
                  {item.cardName}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.6",
                    }}>
                    {item.cardTitle}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                    }}>
                    {format(new Date(item.cardTimestamp as string), "hh:mm")}
                  </p>
                </div>
              </div>
            }>
            <Row>
              <Col>{(cardData?.data as ChatCard).campaign.campaignName}</Col>
            </Row>
            <div className={styles.cardInner}>
              <div style={{ display: "flex", gap: "5px" }}>
                {(cardData?.data as ChatCard).deliverables.map((delData, i) => (
                  <p key={i}>
                    {delData.deliverablesDetails?.count}{" "}
                    {delData.deliverablesDetails?.typeDel}
                    {(cardData?.data as ChatCard).deliverables.length - 1 !==
                      i && ","}
                  </p>
                ))}
              </div>
              <span>
                <span style={{ fontWeight: "bold" }}>₹ </span>
                {(cardData?.data as ChatCard).price}
              </span>
            </div>
            <div className={styles.cardButton}>
              <Button
                disabled={
                  isWithdrawn ||
                  (cardData?.data as ChatCard).isWithDrawn ||
                  (cardData.data as ChatCard).status === "Rejected"
                }
                onClick={() =>
                  item.ref?.offerId &&
                  withdrawOfferMutation.mutate({ offerId: item.ref.offerId })
                }
                size="small"
                style={{
                  padding: "0px 7px",
                  marginTop: "12px",
                }}>
                {(cardData.data as ChatCard).status === "Rejected"
                  ? "Rejected"
                  : isWithdrawn || (cardData?.data as ChatCard).isWithDrawn
                  ? "Withdrawn"
                  : "Withdraw"}
                {}
              </Button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default InvitationSentCard;
