import { sendRequest } from "../helper";

interface CampaignCountResponse {
  status: string;
  message: string;
  data: { count: number };
}

export const getReportCampaignCount = () => {
  const queryKey = ["report", "campaigns-count"];

  const queryFunction = () =>
    sendRequest<CampaignCountResponse>(
      "get",
      "/brand/report-only/campaign/count",
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
