import { sendRequest } from "./helper";

import { User } from "../typings";

interface editUserDetailsProps {
  name?: string;
  lastname: string;
  designation?: string;
  websiteUrl: string;
  niche: string[];
  businessType: string;
  businessName: string;
  logoUrl: string;
  countryCode?: string;
}

export const onboardUser = (inputProps: editUserDetailsProps) => {
  return sendRequest<User>("post", `/brand/me/on-board`, false, {
    ...inputProps,
  }).then((res) => res.data);
};
