import React, { useState } from "react";
import { Typography, Row, Dropdown, MenuProps, Space, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { formatData, sleep } from "../../../utils";
import { replaceUnderscoreAndCapitalize } from "../../../utils/replaceUnderscoreAndCapitalise";

const { Title } = Typography;

const MetaData = () => {
  const [addToListModalVisible, setAddToListModalVisible] =
    useState<boolean>(false);

  const {
    creatorData,
    searchParams,
    setSearchParams,
    getResults,
    selectedCreators,
    isLoading,
  } = useCreatorSearchContext();

  const items: MenuProps["items"] = [
    {
      key: "51",
      label: (
        <p
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              sort_by: { ...searchParams?.sort_by, field: "FOLLOWER_COUNT" },
            }));
            sleep(100)?.then(() => getResults());
          }}
        >
          Followers
        </p>
      ),
    },
    {
      key: "45",
      label: (
        <p
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              sort_by: { ...searchParams?.sort_by, field: "AVERAGE_LIKES" },
            }));
            sleep(100)?.then(() => getResults());
          }}
        >
          Average likes
        </p>
      ),
    },
    {
      key: "61",
      label: (
        <p
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              sort_by: { ...searchParams?.sort_by, field: "ENGAGEMENT_RATE" },
            }));
            sleep(100)?.then(() => getResults());
          }}
        >
          {" "}
          Engagement rate
        </p>
      ),
    },
  ];

  return (
    <Row justify={"space-between"} style={{ margin: "25px 0px" }}>
      <Title level={4} style={{ display: "inline-block", marginBlock: 0 }}>
        {isLoading && "Finding Creators"}
        {creatorData?.data?.metadata?.total_results &&
          formatData(creatorData?.data?.metadata?.total_results) +
            " Creators Found"}
      </Title>
      <Space align="center">
        {selectedCreators?.length > 0 && (
          <>
            <Title level={5} style={{ marginBlock: 0 }}>
              {selectedCreators.length} creator
              {selectedCreators.length > 1 ? "s" : ""} selected
            </Title>
            <Button
              type="primary"
              size="large"
              onClick={() => setAddToListModalVisible(true)}
            >
              Add to List
            </Button>
          </>
        )}
      </Space>
      {/* <AddToListModal
        handleClose={() => setAddToListModalVisible(false)}
        visible={addToListModalVisible}
      /> */}
    </Row>
  );
};

export default MetaData;

{
  /* <Dropdown.Button
style={{ maxWidth: "fit-content" }}
items={{ items }}
trigger={["click"]}
icon={<DownOutlined />}
size="large"
>
Sort By{" "}
{replaceUnderscoreAndCapitalize(searchParams?.sort_by?.field || "")}
</Dropdown.Button>  */
}
