import React, { useEffect, useState } from "react";
import { Card, Input, message } from "antd";

import styles from "./styles.module.css";

import { Influencer } from "../../../../typings";

import ReportButton from "../../../Campaign/Report/ReportButton";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { postNotes } from "../../../../handler/Profile";

type NotesProps = {
  id: string;
  influencerData?: Influencer;
  getInfluencerKey: string[];
};

const Notes: React.FC<NotesProps> = (props) => {
  const { influencerData: data, getInfluencerKey, id } = props;

  const queryClient = useQueryClient();

  const { TextArea } = Input;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [note, setNote] = useState<string>(data?.notes || "");

  const handleCancel = () => {
    setDisabled(true);
  };

  const postNotesMutation = useMutation(postNotes, {
    onSuccess: () => {
      message.success("Updated User Notes");
      queryClient.invalidateQueries(getInfluencerKey);
      setDisabled(true);
    },
    onError: (err: AxiosError) => {
      message.error("Some error Occurred");
    },
  });

  const handleSave = () => {
    postNotesMutation.mutate({ influencerId: id, notes: note });
  };

  useEffect(() => {
    setNote(data?.notes || "");
  }, [disabled, data?.notes]);

  return (
    <Card style={{ maxWidth: 750, borderRadius: 10, width: "100%" }}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Your Notes</h1>
        {disabled && (
          <ReportButton
            onClick={() => setDisabled(false)}
            ghost
            style={{
              textDecoration: "underline",
              width: "fit-content",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Add note
          </ReportButton>
        )}
        {!disabled && (
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <ReportButton onClick={handleCancel} type="default">
              Cancel
            </ReportButton>
            <ReportButton
              onClick={handleSave}
              type="primary"
              loading={postNotesMutation.isLoading}
            >
              Save
            </ReportButton>
          </div>
        )}
      </div>
      <div className={styles.textArea}>
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={5}
          disabled={disabled}
          style={{
            backgroundColor: disabled ? "#F2F2F2" : "transparent",
            borderRadius: 10,
          }}
        />
        {disabled && note.trim().length === 0 && (
          <>
            <div className={styles.message}>
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.50)",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                Add your notes on influencers,
                <br />
                this is shared across your team
              </p>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
export default Notes;
