import { sendRequest } from "../helper";
import { CampaignReport } from "../../typings";

export interface CampaignsResponse {
  status: string;
  message: string;
  data: CampaignReport[];
}

export const getAllReportCampaigns = ({
  pageNumber,
}: {
  pageNumber: number;
}) => {
  const queryKey = ["campaigns", "report", "page", pageNumber];

  const queryFunction = () =>
    sendRequest<CampaignsResponse>(
      "get",
      `/brand/report-only/campaign?limit=5&pageNo=${pageNumber}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
