import { sendRequest } from "../helper";

import { NicheResult } from "../../typings/Discovery";

type NicheResults = {
  status: string | null;
  message: string | null;
  data: NicheResult[];
};

export const getNiche = (platform: string) => {
  const queryKey = ["niche", platform];
  const queryFunction = () =>
    sendRequest<NicheResults>(
      "get",
      `/brand/niche?platform=${platform}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
