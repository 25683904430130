import { sendRequest } from "../helper";

import {
  InfluencerReport,
  ReportInfluencer,
  reportOnlySocialMediaKit,
  IndividualInfluencerReport,
} from "../../typings/Report";

type getInfluencerReportProps = {
  campaignId: string;
  socialMediaId: string;
};

type getInfluencerReportResponse = {
  data: IndividualInfluencerReport;
  status: string;
  message: string;
};

export const getInfluencerReport = ({
  campaignId,
  socialMediaId,
}: getInfluencerReportProps) => {
  const queryKey = ["reports", "reportOnlyReports", campaignId, socialMediaId];

  const queryFunction = () =>
    sendRequest<getInfluencerReportResponse>(
      "get",
      `/brand/report-only/report/influencer?campaignId=${campaignId}&socialMediaId=${socialMediaId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
