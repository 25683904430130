import React from "react";
import { Row, Col, Space } from "antd";

import styles from "./insightcard.module.css";

interface InsightCardProps {
  item: {
    label: string;
    value: string;
  }[];
}

const InsightCard: React.FC<InsightCardProps> = ({ item }) => {
  return (
    <Row className={styles.container}>
      <Col span={8} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}>
        <Row justify="center">
          <div>
            <p className={styles.value}>{item[0].value}</p>
            <p className={styles.label}>{item[0].label}</p>
          </div>
        </Row>
      </Col>
      <Col span={8} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}>
        <Row justify="center">
          <div>
            <p className={styles.value}>{item[1].value}</p>
            <p className={styles.label}>{item[1].label}</p>
          </div>
        </Row>
      </Col>
      <Col span={8}>
        <Row justify="center">
          <div>
            <p className={styles.value}>{item[2].value}</p>
            <p className={styles.label}>{item[2].label}</p>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default InsightCard;
