import React, { useState, useEffect } from "react";
import { Row, Col, Popconfirm, message } from "antd";
import Lottie from "react-lottie";
import { useMutation, useQuery, useQueryClient } from "react-query";

import animationData from "../../../../assets/lottie/threeDotLoading.json";

import ReportModal from "../../ReportModal";
import CreatorCard from "../SharedComponents/CreatorCard";
import ReportButton from "../../ReportButton";
import DeliverableSelect from "../SharedComponents/DeliverableSelect";
import AddReport from "../AddReport";

import globalStyles from "../styles.module.css";

import {
  getInfluencerMedia,
  getCampaignAggregate,
  deleteReport,
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
  getCampaignReport,
} from "../../../../../handler/report";

import {
  CreatorReport,
  InfluencerMediaType,
  YtMediaResponseType,
} from "../../../../../typings";

interface DelteReportProps {
  creator: CreatorReport;
  visible: boolean;
  campaignId: string;
  platform: "instagram" | "youtube";
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const DeleteReport: React.FC<DelteReportProps> = ({
  creator,
  visible,
  setVisible,
  campaignId,
  platform,
}) => {
  const [postsSelected, setPostsSelected] = useState<InfluencerMediaType[]>([]);

  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });

  const queryClient = useQueryClient();

  const [campaignReportKey] = getCampaignAggregate({ campaignId });
  const [mediaKey] = getInfluencerMedia({
    campaignId,
    socialMediaId: creator.socialMediaId,
  });

  const [campaignKey] = getCampaignReport({ campaignId });

  const [queryKey, queryFn] = getInfluencerMedia({
    campaignId,
    socialMediaId: creator.socialMediaId,
  });

  const { data: mediaData, isLoading } = useQuery(queryKey, queryFn, {
    enabled: visible,
  });

  const deleteReportMutation = useMutation(deleteReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(campaignReportKey);
      queryClient.invalidateQueries(mediaKey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries(campaignKey);
      queryClient.invalidateQueries("reports");
      message.success("Media Deleted");
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong while deletion");
    },
  });

  const [addReportVisible, setAddReportVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) {
      setPostsSelected([]);
    }
  }, [visible]);

  const handleSelect = (item: InfluencerMediaType) => {
    if (postsSelected.findIndex((post) => post._id === item._id) === -1) {
      return setPostsSelected((postsSelected) => [...postsSelected, item]);
    }
    setPostsSelected((postsSelected) => {
      return postsSelected.filter((post) => post._id !== item._id);
    });
  };

  return (
    <>
      <ReportModal
        visible={visible}
        centered
        maskClosable={false}
        onCancel={() => setVisible(false)}
        footer={
          <Row justify="end">
            <Popconfirm
              title={`Are you sure you want to delete ${postsSelected.length} media from Report?`}
              okText="Confirm"
              cancelText="Back"
              okType="primary"
              cancelButtonProps={{
                style: {
                  padding: "5px 12px ",
                  height: "30px",
                  borderRadius: "5px ",
                },
              }}
              okButtonProps={{
                style: {
                  padding: "5px 12px ",
                  height: "30px",
                  borderRadius: "5px ",
                  borderColor: "#533af5",
                  background: "#533af5",
                  color: "white",
                },
              }}
              onConfirm={() =>
                deleteReportMutation.mutate({
                  campaignId,
                  deliverableMediaIds: postsSelected.map(
                    (item) => item.deliverableMediaId
                  ),
                })
              }>
              <ReportButton
                type="primary"
                disabled={postsSelected.length === 0}
                loading={deleteReportMutation.isLoading}>
                Delete {postsSelected.length} Media
              </ReportButton>
            </Popconfirm>
          </Row>
        }
        title="Delete from Report">
        <main style={{ display: "grid", gap: "20px" }}>
          <CreatorCard
            creator={creator}
            handleClick={() => {}}
            isSelected={false}
            campaignId={campaignId}
          />
          <div>
            <p className={globalStyles.label}>
              Select media to delete from Report.
            </p>
            <div
              style={{
                height: "20vh",
                overflowY: "scroll",
                marginTop: "30px",
              }}>
              {isLoading ? (
                <Lottie options={defaultOptions} height={50} width={100} />
              ) : (
                <div>
                  {(mediaData?.data || []).length > 0 ? (
                    <Row gutter={[0, 10]}>
                      {mediaData?.data?.map((item) => (
                        <Col
                          span={platform === "instagram" ? 7 : 11}
                          offset={1}>
                          <DeliverableSelect
                            isLabelClickEnabled={false}
                            handleClick={() => handleSelect(item)}
                            isSelected={
                              postsSelected.findIndex(
                                (post) => post._id === item._id
                              ) !== -1
                            }
                            id={item._id}
                            label={item.deliverableType.split("-").at(-1)}
                            url={item.media.savedMedias[0].url}
                            type={
                              platform === "instagram"
                                ? item.media?.savedMedias[0]?.mediaType
                                : "yt-video"
                            }
                            permaLink={item?.media?.permalink}
                            caption={
                              platform === "youtube"
                                ? (item?.media as YtMediaResponseType)?.title
                                : undefined
                            }
                            profilePic={creator?.socialMediaKit?.profilePicUrl}
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <div style={{ display: "grid", gap: "2px" }}>
                      <p className={globalStyles.label}>
                        No media added to report for{" "}
                        {creator.socialMediaKit.socialMediaName}
                      </p>
                      <p
                        className={globalStyles.highlightedLink}
                        onClick={() => setAddReportVisible(true)}>
                        Add media to report
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </main>
      </ReportModal>
      <AddReport
        campaignId={campaignId}
        creator={creator}
        visible={addReportVisible}
        setVisible={setAddReportVisible}
        platform={platform}
      />
    </>
  );
};

export default DeleteReport;
