import { sendRequest } from "../../helper";

export type cancelOfferProps = {
  payInId: string;
};

type cancelOfferResults = {
  data: string;
  message: string;
  status: string;
};

export const cancelOffer = ({ payInId }: cancelOfferProps) => {
  return sendRequest<cancelOfferResults>(
    "put",
    `/brand/campaign/offer/finalize-offer-cancel`,
    false,
    { offerPayInId: payInId }
  ).then((res) => res.data);
};
