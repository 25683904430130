import { sendRequest } from "../helper";

import { YtInsightsResponse } from "../../typings/Report";

export type getYtAdvanceMetricsResponse = {
  data: YtInsightsResponse[];
  status: string;
  message: string;
};

export const getYtAdvanceMetrics = ({ campaignId }: { campaignId: string }) => {
  const queryKey = ["reports", "reportOnlyReports", "youtube", campaignId];

  const queryFunction = () =>
    sendRequest<getYtAdvanceMetricsResponse>(
      "get",
      `/brand/report-only/report/insights?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
