import { Campaign } from "../../../typings";

export const getCampaignStatus = (campaign: Campaign) => {
  const incompleteSteps = [
    "Created-Campaign",
    "Added-Diliverables",
    "Added-Filters",
    "Added-Commericals",
    "Added-Brand-Guidelines",
    "Added-Product",
    "Added-Commerical-Filters",
  ];
  if (incompleteSteps.includes(campaign.statusHistory.at(-1)!.status)) {
    return "Draft";
  }
  return campaign.statusHistory.at(-1)!.status;
};
