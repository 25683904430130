import { Button, Pagination, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import BillTile from "../BillTile";
import { format } from "date-fns";
import moment from "moment";

import { SearchQuery } from "../index";
import { useQuery } from "react-query";

import { getBill, getBillCount } from "../../../handler/Billing";

import DateSelector from "../DateSelector";

import styles from "../billing.module.css";
import { useNavigate } from "react-router-dom";
import { getAllCampaigns, getCampaignCount } from "../../../handler/campaign";

const { Option, OptGroup } = Select;

interface TransactionsProps {
  searchQuery: SearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
}

const Transactions: React.FC<TransactionsProps> = ({
  searchQuery,
  setSearchQuery,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [getBillKey, getBillFn] = getBill(searchQuery);
  const [getBillCountKey, getBillCountFn] = getBillCount(searchQuery);
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const [campaigns, setCampaigns] = useState<
    { campaignName: string; campaignId: string }[]
  >([]);
  const { data: billData } = useQuery(getBillKey, getBillFn);
  const { data: billCount } = useQuery(getBillCountKey, getBillCountFn);
  const [getAllCampaignsKey, getAllCampaignsFn] = getAllCampaigns({
    pageNumber: page,
  });

  const { data: campaignsData, isLoading: isLoadingCampaigns } = useQuery(
    getAllCampaignsKey,
    getAllCampaignsFn
  );

  const [getCampaignCountKey, getCampaignCountFn] = getCampaignCount();

  const { data: countData } = useQuery(getCampaignCountKey, getCampaignCountFn);

  useEffect(() => {
    if (campaignsData) {
      campaignsData.data.forEach((campaign) =>
        setCampaigns((prevCampaigns) => [
          ...prevCampaigns,
          {
            campaignName: campaign.campaignName,
            campaignId: campaign._id,
          },
        ])
      );
      if (campaigns.length + campaignsData.data.length === countData?.data)
        setHideLoadMore(true);
    }
  }, [campaignsData]);

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div>
          <DateSelector
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <Select
          style={{ width: 100 }}
          onSelect={(e: string) => {
            setSearchQuery((searchQuery) => {
              return {
                ...searchQuery,
                paymentType: e as SearchQuery["paymentType"],
              };
            });
          }}
          value={searchQuery.paymentType}
        >
          <OptGroup key="Type" title="Type">
            <Option value="">ALL</Option>
            <Option value="Refund">Refund</Option>
            <Option value="Payment">Payment</Option>
          </OptGroup>
        </Select>
        <Select
          onSelect={(e: string) => {
            setSearchQuery((searchQuery) => {
              return {
                ...searchQuery,
                campaignId: e as SearchQuery["campaignId"],
              };
            });
          }}
          dropdownStyle={{ paddingBottom: "8px" }}
          placeholder="Select Campaign"
          dropdownRender={(menu) => (
            <div>
              <div className="">{menu}</div>
              {!hideLoadMore && (
                <Row style={{ marginTop: "8px" }} justify="center">
                  <Button
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                    style={{ width: "70%" }}
                  >
                    Load more
                  </Button>
                </Row>
              )}
            </div>
          )}
          // defaultValue={"june campaign-26-creators"}
          style={{ width: 150 }}
        >
          <Option value="">None</Option>
          {campaigns.map((campaign, idx) => (
            <Option key={idx} value={campaign.campaignId}>
              {campaign.campaignName}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.count}>
        <div className="">
          <p>Showing {billCount?.data.count || 0} results</p>
          <p>{`${(searchQuery.page - 1) * 10 + 1}- ${Math.min(
            (searchQuery.page - 1) * 10 + 11,
            billCount?.data.count || 0
          )} of ${billCount?.data.count || 0}`}</p>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <p>Date</p>

          <p>Reference ID</p>
          <p>Type</p>
          <p>Campaign Name</p>
          <p style={{ justifySelf: "flex-end" }}>Amount</p>
          <p style={{ justifySelf: "flex-end" }}>PDF</p>
        </div>
        {billCount?.data.count !== 0 ? (
          <div className={styles.tableBody}>
            {billData?.data && billData.data.length > 0 && (
              <p className={styles.date}>
                {format(new Date(billData.data[0].createDateTime), "MMM, RRRR")}
              </p>
            )}

            {billData?.data.map((item, idx) => {
              return (
                <>
                  <BillTile key={idx} tileData={item} type="transactions" />
                  {idx !== billData.data.length - 1 &&
                    moment(billData.data[idx + 1].createDateTime).month() >
                      moment(item.createDateTime).month() && (
                      <p className={styles.date}>
                        {" "}
                        {format(
                          new Date(billData.data[idx + 1].createDateTime),
                          "MMM, RRRR"
                        )}
                      </p>
                    )}
                </>
              );
            })}
            {/* <BillTile />
          <BillTile />

          <p className={styles.date}>June, 2022</p>
          <BillTile />
          <BillTile /> */}
          </div>
        ) : (
          <Row justify="center">
            <Space direction="vertical" align="center">
              <img src="/assets/img/No-invoice.png" alt="" />
              <p className={styles.noInvoiceHeading}>No transactions yet</p>
              <p className={styles.noInvoiceSubHeading}>
                Ready to conduct a campaign?
              </p>
              <Button
                onClick={() => navigate("/", { replace: true })}
                type="primary"
              >
                Explore Cloutflow
              </Button>
            </Space>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Transactions;
