import { sendRequest } from "../../helper";

export type inviteInfluencerCustomOfferProps = {
  socialMediaKitId: string;
  brandPrice: number;
  influencerPrice: number;
  campaignId: string;
};

export const inviteInfluencerCustomOffer = (
  details: inviteInfluencerCustomOfferProps
) => {
  return sendRequest(
    "post",
    `/brand/campaign/offer/invite-special-offer`,
    false,
    {
      ...details,
    },
    null,
    true
  ).then((res) => res.data);
};
