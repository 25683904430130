import { sendRequest } from "../helper";

import { Influencer } from "../../typings";

type InfluencerResult = {
  status: string | null;
  message: string | null;
  data: Influencer;
};

export const getInfluencer = (influencerId: string) => {
  const queryKey = ["influencer", influencerId];
  const queryFunction = () =>
    sendRequest<InfluencerResult>(
      "get",
      `/brand/brand-influencer/influencer?influencerId=${influencerId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
