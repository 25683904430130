import React from "react";
import { useState, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

import { Typography, Space, Slider } from "antd";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { ConfigTypeSingle } from "../../../typings/CreatorSearch";
import HelpPopOver from "./HelpPopover";

const { Paragraph, Title } = Typography;

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}
const RangeSlider: React.FC<Props> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);

  const { searchParams, setSearchParams } = useCreatorSearchContext();

  const selectedValue = Number(searchParams[facetName] || 0);

  return (
    <div>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <div>
          <div>
            <Paragraph>
              {"> "}
              <b>
                {selectedValue} {"%"}
              </b>
            </Paragraph>
          </div>
          <Slider
            min={0}
            max={100}
            onChange={(val) => {
              setSearchParams((searchParams) => ({
                ...searchParams,
                [facetName]: val,
                filterTags: {
                  ...searchParams?.filterTags,
                  [facetName]: val,
                },
              }));
            }}
            value={selectedValue}
          />
        </div>
      )}
    </div>
  );
};

export default RangeSlider;
