import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import WelcomeModal from "./Welcome";
import ReviewModal from "./Review";

import { getInfluencer } from "../../../../../../../handler/Profile";

interface ReviewModalsProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  influencerId: string;
  finalizeOfferId: string;
}

const ReviewModals: React.FC<ReviewModalsProps> = ({
  isVisible,
  setVisible,
  influencerId,
  finalizeOfferId,
}) => {
  const [welcomeVisible, setWelcomeVisible] = useState<boolean>(false);
  const [reviewVisible, setReviewVisible] = useState<boolean>(false);

  const [influencerKey, influencerFn] = getInfluencer(influencerId);

  const { data: influencerData } = useQuery(influencerKey, influencerFn, {
    enabled: isVisible,
  });

  useEffect(() => {
    if (isVisible) {
      setWelcomeVisible(true);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <WelcomeModal
        handleSubmit={() => {
          setReviewVisible(true);
          setWelcomeVisible(false);
        }}
        handleCancel={() => {
          setWelcomeVisible(false);
          setVisible(false);
        }}
        isVisible={welcomeVisible}
        influencerName={influencerData?.data?.name || ""}
      />
      <ReviewModal
        handleSubmit={() => {
          setReviewVisible(true);
          setVisible(false);
          //   setWelcomeVisible(false);
        }}
        handleCancel={() => {
          setReviewVisible(false);
          setVisible(false);
        }}
        isVisible={reviewVisible}
        influencerId={influencerId}
        finalizedOfferId={finalizeOfferId}
      />
    </>
  );
};

export default ReviewModals;
