import React, { useState } from "react";
import { Modal, Row, Col, Input, Button, message, Typography } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { createCloutflowCampaign } from "../../handler/cloutflowCampaign/createCampaign";

const { Title, Text } = Typography;

interface CreateCampaignModalProps {
  isVisible: boolean;
  handleCancel: () => void;
}

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({
  isVisible,
  handleCancel,
}) => {
  const queryClient = useQueryClient();
  const [campaignName, setCampaignName] = useState<string>("");
  const [requirements, setRequirements] = useState<string>("");
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const mutation = useMutation(createCloutflowCampaign, {
    onSuccess: () => {
      message.success("Campaign created successfully");
      queryClient.invalidateQueries("campaigns"); // Adjust this based on your query key
      handleCancel(); // Close the modal on success
    },
    onError: () => {
      message.error("Something went wrong while creating the campaign");
    },
  });

  const handleCreate = () => {
    setShowErrors(true);
    if (campaignName.trim() === "" || requirements.trim() === "") {
      return;
    }
    mutation.mutate({ name: campaignName, requirements });
  };

  return (
    <Modal
      visible={isVisible}
      title="Create a new campaign"
      onCancel={handleCancel}
      footer={null}
      centered
      destroyOnClose
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={4}>Campaign Name</Title>
          <Input
            placeholder="Campaign Name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            status={showErrors && campaignName.trim() === "" ? "error" : ""}
          />
          {showErrors && campaignName.trim() === "" && (
            <Text type="danger">Campaign Name can't be empty</Text>
          )}
        </Col>
        <Col span={24}>
          <Title level={4}>Requirements</Title>
          <Input.TextArea
            placeholder="Enter the requirements for the campaign, e.g., followers of influencers, platform, etc."
            value={requirements}
            onChange={(e) => setRequirements(e.target.value)}
            rows={4}
            status={showErrors && requirements.trim() === "" ? "error" : ""}
          />
          {showErrors && requirements.trim() === "" && (
            <Text type="danger">Requirements can't be empty</Text>
          )}
        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={handleCreate}
            loading={mutation.isLoading}
            disabled={mutation.isLoading}
          >
            Create Campaign
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateCampaignModal;
