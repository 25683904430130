import { sendRequest } from "../../../helper";

export type postWorkFlowReqV2Props = {
  finalizedOffersIds: string[];
  isDraftNeeded: boolean;
  summary: string;
  type: string;
  campaignId: string;
};

export const postWorkFlowReqV2 = ({
  finalizedOffersIds,
  isDraftNeeded,
  summary,
  type,
  campaignId,
}: postWorkFlowReqV2Props) => {
  return sendRequest(
    "post",
    `/brand/execution/requirements/send-deliverables`,
    false,
    {
      finalizedOffersIds,
      isDraftNeeded,
      summary,
      type,
      campaignId,
    },
    null,
    true
  ).then((res) => res.data);
};
