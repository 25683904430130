import { sendRequest } from "../helper";

import { InvitedInfluencer } from "../../typings";

export interface getInvitedInfluencerResponse {
  status: string;
  message: string;
  data: InvitedInfluencer[];
}

interface getInvitedInfluencerProps {
  pageNo: number;
  limit: number;
}

export const getInvitedInfluencer = ({
  pageNo,
  limit,
}: getInvitedInfluencerProps) => {
  const queryKey = ["getInvitedInfluencers", pageNo];

  const queryFunction = () =>
    sendRequest<getInvitedInfluencerResponse>(
      "get",
      `/brand/connect-request/influencer-connect-request?pageNo=${pageNo}&limit=${limit}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
