import axios from "axios";

export interface getInfluencersAlgoliaResponse {
  facetHits: [
    {
      value: string;
      highlighted: string;
      count: string;
    }
  ];
}

interface getFacetvaluesProps {
  query: string;
  facetName: string;
  platform: string;
}

const getAlgoliaIndexName = (platform: string) => {
  switch (platform) {
    case "instagram":
      return "platform-discovery-ig-v2-stage";
    case "youtube":
      return "platform-discovery-yt-v2-stage";
    default:
      return "platform-discovery-ig-v2-stage";
  }
};

export const getFacetsAlgolia = ({
  query,
  platform,
  facetName,
}: getFacetvaluesProps) => {
  const queryKey = ["getFacetsAlgolia", query, platform, facetName];

  const queryFunction = () =>
    axios.post<getInfluencersAlgoliaResponse>(
      `https://${
        process.env.REACT_APP_ALGOLIA_APP_ID
      }-dsn.algolia.net/1/indexes/${getAlgoliaIndexName(
        platform
      )}/facets/${facetName}/query`,

      { facetQuery: query, maxFacetHits: 50 },
      {
        headers: {
          "X-Algolia-API-Key": `${process.env.REACT_APP_ALGOLIA_SEARCH_KEY}`,
          "X-Algolia-Application-Id": `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
        },
      }
    );

  return [queryKey, queryFunction] as const;
};
