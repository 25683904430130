import React from "react";
import { Divider, Typography, Row, Col, Card } from "antd";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

import StatsItem from "./StatsItem";

import { useCreatorPageContext } from "../context";

import { formatData } from "../../../../utils";

const { Title, Text } = Typography;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const BrandInterests = () => {
  const { creatorData } = useCreatorPageContext();

  const realPercentage =
    creatorData?.profile?.audience?.follower_types?.find(
      (item) => item.name === "real"
    )?.value || 0;
  const massPercentage =
    creatorData?.profile?.audience?.follower_types?.find(
      (item) => item.name === "mass_followers"
    )?.value || 0;
  const susPercentage =
    creatorData?.profile?.audience?.follower_types?.find(
      (item) => item.name === "suspicious"
    )?.value || 0;
  const influencersPercentage =
    creatorData?.profile?.audience?.follower_types?.find(
      (item) => item.name === "influencers"
    )?.value || 0;

  return (
    <div>
      <Divider />

      <Row gutter={10}>
        <Col>
          <Card style={{ height: "420px", width: "360px" }}>
            <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
              Audience Brand affinity
            </Title>

            <Text type="secondary" style={{ display: "block" }}>
              Brands followed by te audience
            </Text>

            <StatsItem
              items={
                creatorData?.profile?.audience?.brand_affinity?.map((item) => ({
                  name: item.name,
                  value: item.value,
                })) || []
              }
            />
          </Card>
        </Col>
        <Col>
          <Card style={{ height: "420px", width: "360px" }}>
            <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
              Audience Interests
            </Title>

            <Text type="secondary" style={{ display: "block" }}>
              Interests and topics that audience post about
            </Text>

            <StatsItem
              items={
                creatorData?.profile?.audience?.interests?.map((item) => ({
                  name: item.name,
                  value: item.value,
                })) || []
              }
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
        Follower types
      </Title>
      <Card style={{ height: "400px", width: "360px" }}>
        <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
          Gender Distribution
        </Title>
        <div
          style={{
            display: "flex",
          }}>
          <Doughnut
            options={{
              cutout: "95%",
              plugins: {
                legend: { position: "top" },
                datalabels: {
                  anchor: "start",
                  align: "start",
                  font: {
                    size: 16,
                    style: "normal",
                  },
                },
              },
            }}
            data={{
              labels: [
                `Real  ${formatData(realPercentage, "0.00")} %`,
                `Mass Followers  ${formatData(massPercentage, "0.00")} %`,
                `Suspicious  ${formatData(susPercentage, "0.00")} %`,
                `Influencers ${formatData(influencersPercentage, "0.00")} %`,
              ],
              datasets: [
                {
                  label: "",
                  data: [
                    formatData(realPercentage, "0.00"),
                    formatData(massPercentage, "0.00"),
                    formatData(susPercentage, "0.00"),
                    formatData(influencersPercentage, "0.00"),
                  ],
                  backgroundColor: [
                    "rgb(4, 219, 142)",
                    "rgb(179, 240, 1)",
                    "rgb(217, 29, 74)",
                    "rgb(104, 13, 228)",
                  ],
                  // borderColor: ["rgb(4, 219, 142)", "rgb(229, 229, 232)"],
                  borderWidth: 1,
                  borderJoinStyle: "round",
                  borderRadius: 10,
                  hoverOffset: 4,
                  offset: 0,
                },
              ],
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default BrandInterests;
