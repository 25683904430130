import React, { useState } from "react";
import { Collapse, Tag, Button, Row, Image, Typography, Divider } from "antd";
import ReactPlayer from "react-player";
import { useQuery } from "react-query";
import { format } from "date-fns";
import clsx from "clsx";

import ApproveModal from "./Modal/Approve";
import ChangesModal from "./Modal/Changes";
import AddDraft from "./Modal/Add";
import ReportButton from "../../../../Report/ReportButton";

import styles from "../tile.module.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTimelineDetailsV2 } from "../../../../../../handler/campaign/applicants";
import ShowDraft from "./showDraft";

interface TimeLineProps {
  draftsTimeLine: {
    name: string;
    type: string;
    createDateTime: string;
    message?: string;
    references: { type: string; url: string; platform: string }[];
    _id: string;
    expectedClosureDateTime?: string;
  }[];
  lastDate: string;
  workflowId: string;
}

const getDaysLeft = (lastDate: string) => {
  const difference = new Date(lastDate).getTime() - new Date().getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

const TimeLine: React.FC<TimeLineProps> = ({
  draftsTimeLine,
  lastDate,
  workflowId,
}) => {
  const daysLeft = getDaysLeft(lastDate);
  const [isApproveVisible, setApproveVisible] = useState<boolean>(false);
  const [isChangesModalVisible, setChangesModalVisible] =
    useState<boolean>(false);

  return (
    <div
      style={{
        borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
        marginLeft: "10px",
        paddingLeft: "20px",
        display: "grid",
        gap: "16px",
      }}>
      {draftsTimeLine.map((item, idx) => {
        return (
          <Row style={{ width: "100%" }} justify="space-between">
            <div style={{ width: "100%" }}>
              <p
                className={clsx(styles.subTileHeading, {
                  [styles.subTileHeadingClickable]:
                    item.type === "Drafts-Uploaded" ||
                    item.type === "Drafts-Edited",
                })}>
                {item.name}
                {(item.type === "Drafts-Uploaded" ||
                  item.type === "Drafts-Edited") && (
                  <Button
                    style={{ display: "blocK", padding: "0" }}
                    type="link"
                    onClick={() =>
                      window.open(item?.references[0]?.url, "_blank")
                    }>
                    View link
                  </Button>
                )}
              </p>

              <p className={styles.description}>
                {item.name} on {""}
                {format(new Date(item.createDateTime), "d LLLL, uuuu ")}
              </p>

              {item?.expectedClosureDateTime && (
                <p className={styles.description}>
                  Deadline:{" "}
                  {format(
                    new Date(item?.expectedClosureDateTime),
                    "d LLLL, uuuu "
                  )}
                </p>
              )}
              {item.type === "Drafts-Require-Changes" && <p>{item.message}</p>}
              {(item.type === "Drafts-Uploaded" ||
                item.type === "Drafts-Edited") && (
                <>
                  {idx === draftsTimeLine.length - 1 && (
                    <div style={{ width: "200px" }}>
                      <div style={{ width: "100%" }}>
                        <Button
                          type="primary"
                          block
                          style={{
                            margin: "10px 0 5px 0",
                            borderRadius: "2px",
                          }}
                          onClick={() => setApproveVisible(true)}>
                          Approve
                        </Button>
                        <Button
                          type="default"
                          block
                          style={{ borderRadius: "2px" }}
                          onClick={() => setChangesModalVisible(true)}>
                          Request Changes
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              {item.type === "Drafts-Require-Changes" &&
                idx === draftsTimeLine.length - 1 &&
                !isNaN(daysLeft) && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color:
                        daysLeft >= 5
                          ? "#BFBFBF"
                          : daysLeft >= 2
                          ? "#FA8C16"
                          : "#F5222D",
                    }}>
                    {Math.max(daysLeft, 0)} d left
                  </span>
                )}
            </div>
            <ApproveModal
              isVisible={isApproveVisible}
              handleSubmit={() => setApproveVisible(false)}
              handleCancel={() => setApproveVisible(false)}
              workflowId={workflowId}
              prevDraftTimeLineId={item._id}
            />
            <ChangesModal
              isVisible={isChangesModalVisible}
              handleSubmit={() => setChangesModalVisible(false)}
              handleCancel={() => setChangesModalVisible(false)}
              workflowId={workflowId}
              prevDraftTimeLineId={item._id}
            />
          </Row>
        );
      })}
    </div>
  );
};

interface DraftsTileProps {
  offerId: string;
}

const DraftsTile: React.FC<DraftsTileProps> = ({ offerId }) => {
  const [getTimelineDetailsKey, getTimelineDetailsFn] = getTimelineDetailsV2({
    finalizedOffersId: offerId,
  });

  const [addDraftProps, setAddDraftProps] = useState<{
    visible: boolean;
    workflowId: string;
    platform: string;
  }>({ visible: false, workflowId: "", platform: "" });

  const { data: timelineData } = useQuery(
    getTimelineDetailsKey,
    getTimelineDetailsFn,
    { enabled: offerId !== "" }
  );
  const { Panel } = Collapse;

  const getTagColor = (state: string) => {
    if (state === "In-Making") {
      return "gold";
    } else if (state === "Awaiting-Review") {
      return "red";
    } else if (state === "Approved") return "green";
    return "";
  };

  return (
    <div>
      <Collapse
        ghost
        bordered={false}
        expandIconPosition={"end"}
        style={{ display: "grid", gap: "6px" }}
        expandIcon={() => {
          return null;
        }}
        defaultActiveKey={
          timelineData?.data.map((_, idx) => idx) || ["0", "1", "2", "3", "4"]
        }>
        {timelineData?.data.map((item, idx) => {
          return (
            <Panel
              header={
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "280px",
                    }}>
                    <p className={styles.tileHeader}>
                      <span className={styles.tileNumber}>{idx + 1}</span>
                      {`${item.deliverablesType}`}
                    </p>
                    <Tag
                      color={getTagColor(item.drafts.status)}
                      style={{ borderRadius: "4px" }}>
                      {item.drafts.status}
                    </Tag>
                  </div>
                  {item.drafts.status !== "Approved" && (
                    <Row justify="end" style={{ marginTop: 4 }}>
                      <ReportButton
                        size="small"
                        type="primary"
                        onClick={() =>
                          setAddDraftProps({
                            visible: true,
                            workflowId: item._id || "",
                            platform: item.platform,
                          })
                        }>
                        Add Draft
                      </ReportButton>
                    </Row>
                  )}
                </>
              }
              key={idx}
              style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid rgba(157, 157, 157,0.4)",
              }}
              collapsible={
                item.draftsTimeLine.length > 0 ? "header" : "disabled"
              }>
              <TimeLine
                draftsTimeLine={item.draftsTimeLine}
                lastDate={item?.drafts?.actionExceptedClosureDateTime || ""}
                workflowId={item._id || ""}
              />
            </Panel>
          );
        })}
      </Collapse>
      <AddDraft
        isVisible={addDraftProps.visible}
        handleSubmit={() =>
          setAddDraftProps({ visible: false, workflowId: "", platform: "" })
        }
        handleCancel={() =>
          setAddDraftProps({ visible: false, workflowId: "", platform: "" })
        }
        workflowId={addDraftProps.workflowId}
        platform={addDraftProps.platform}
      />
    </div>
  );
};

export default DraftsTile;
