import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, message, Checkbox, notification } from "antd";
import nf from "@tuplo/numberfmt";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery, useMutation } from "react-query";

import {
  getOfferSummary,
  finalizeOffer,
  cancelOffer,
} from "../../../../handler/campaign/offer";
import { getUser } from "../../../../handler/getUser";

import Details from "../../../Billing/BillingSettings/Modals/Details";

import styles from "./Modals.module.css";

const panelVariants = {
  visible: {
    height: "initial",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

interface PayModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  creatorIDList: string[];
  campaignId: string;
  initialPaymentLink: string;
  handleInvalidate: () => void;
  payInId: string;
}
const FinalizeModal: React.FC<PayModalProps> = ({
  isVisible,
  handleCancel,
  creatorIDList,
  campaignId,
  initialPaymentLink,
  handleInvalidate,
  payInId,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [getUserKey, getUserFn] = getUser();

  const [billingDetailsModalOpen, setBillingDetailsModalOpen] =
    useState<boolean>(false);

  const [payToggle, setPayToggle] = useState<boolean>(
    initialPaymentLink === "" ? false : true
  );
  const [paymentLink, setPaymentLink] = useState<string>(initialPaymentLink);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

  const [getOfferSummaryKey, getOfferSummaryFunction] = getOfferSummary({
    offerIds: creatorIDList,
    campaignId,
  });
  const { data } = useQuery(getOfferSummaryKey, getOfferSummaryFunction);

  const { data: userData } = useQuery(getUserKey, getUserFn);

  const handleClick = () => {
    if (!userData?.data?.isZohoCustomerCreated) {
      return setBillingDetailsModalOpen(true);
    }
    if (!payToggle) {
      if (Number(data?.data.totalReciept || 0) >= 500000) {
        return notification.warning({
          message: "The maximum payment limit at a time is ₹5,00,000",
          description:
            "The total payable amount is more than the maximum limit, which is ₹5,00,000 per transaction. You can select fewer creators at a time and pay them separately.",
          duration: 3,
        });
      }
      return finalizeOfferMutation.mutate({
        campaignId,
        offerIds: data!.data.reciept.map((item) => item._id),
      });
    } else {
      return window.open(paymentLink);
    }
  };

  const finalizeOfferMutation = useMutation(finalizeOffer, {
    onSuccess: (response) => {
      // queryClient.invalidateQueries("applicants");
      setPayToggle(true);
      setPaymentLink(response.data);
      message.success("Payment Link Generated");
      handleInvalidate();
    },
    onError: () => {
      message.error("Some error occurred. Please try again");
    },
  });
  const cancelOfferMutation = useMutation(cancelOffer, {
    onSuccess: () => {
      message.success("Offer Cancelled");
      handleInvalidate();
    },
    onError: () => {
      message.error("Some error occurred. Please try again");
    },
  });
  const handleShare = () => {
    navigator.clipboard.writeText(paymentLink);
    message.success("Payment Link copied");
  };
  useEffect(() => {
    setPaymentLink(initialPaymentLink);
    setPayToggle(initialPaymentLink === "" ? false : true);
    setIsTermsChecked(initialPaymentLink === "" ? false : true);
  }, [initialPaymentLink]);

  return (
    <Modal
      title={`Payment Summary (${data?.data.reciept.length} ${
        (data?.data?.reciept || []).length > 1 ? "Creators" : "creator"
      })`}
      visible={isVisible}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered
      footer={null}
      width={"750px"}>
      <section>
        {data ? (
          <Row gutter={[12, 0]}>
            <Col span={15}>
              <Row className={styles.paymentHeader}>
                <Col span={10}>Creator Name</Col>
                <Col span={14}>
                  <Row>
                    <Col span={12}>Price</Col>
                    <Col span={12}>GST</Col>
                  </Row>
                </Col>
              </Row>
              {data.data.reciept.map((item) => {
                return (
                  <Row className={styles.paymentTile}>
                    <Col span={10}>
                      {item?.socialMediaInfo?.influencerName}
                      {item.isInfluencerGstApplicible ? "*" : ""}
                    </Col>
                    <Col span={14}>
                      <Row>
                        {/* sdd */}
                        <Col span={12}>₹{nf(item?.brandPrice, "0,0.00")}</Col>
                        <Col span={12}>
                          {`₹${nf(item.brandGstPrice, "0,0.00")}`}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
              <hr style={{ border: "2px dashed rgba(0, 0, 0, 0.09)" }} />
              <Row>
                <Col span={10} className={styles.typoLight}>
                  Subtotal
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={12} className={styles.typoDark}>
                      ₹
                      {nf(
                        data.data.reciept.reduce(
                          (prev, current) => Number(current?.brandPrice) + prev,
                          0
                        ),
                        "0,0.00"
                      )}
                    </Col>
                    <Col span={12} className={styles.typoDark}>
                      ₹
                      {nf(
                        data.data.reciept.reduce(
                          (prev, current) =>
                            Number(current.brandGstPrice || 0) + prev,
                          0
                        ),
                        "0,0.00"
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: "6px" }}>
                <Col span={10} className={styles.typoLight}>
                  Service Charge
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={12} className={styles.typoDark}>
                      ₹
                      {nf(
                        data.data.reciept.reduce(
                          (prev, current) =>
                            Number(current.brandServiceChargePrice || 0) + prev,
                          0
                        ),
                        "0,0.00"
                      )}
                    </Col>
                    <Col span={12} className={styles.typoDark}>
                      ₹
                      {nf(
                        data.data.reciept.reduce(
                          (prev, current) =>
                            Number(current.brandGstServiceChargePrice || 0) +
                            prev,
                          0
                        ),
                        "0,0.00"
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr style={{ border: "1px dashed rgba(0, 0, 0, 0.09)" }} />
              <Row style={{ marginTop: "6px" }} className={styles.total}>
                <Col span={10}>Total</Col>
                <Col span={14}> ₹{nf(data.data.totalReciept, "0,0.00")}</Col>
              </Row>
            </Col>
            <Col span={9}>
              <section className={styles.caution}>
                <img
                  src="/assets/img/lock.png"
                  alt="lock"
                  style={{ width: "87px", margin: "0 auto" }}
                  className={styles.lockImage}
                />
                <p className={styles.warningTitle}>
                  Your payment is secure with us
                </p>
                <p className={styles.warningDesc}>
                  The payment will be released to the creator only when you’re
                  satisfied with the results.
                </p>
              </section>
              <article className={styles.totalContainer}>
                <Row justify="space-between" style={{ marginBottom: "6px" }}>
                  <span className={styles.typoLight}>
                    Subtotal ({(data?.data?.reciept || []).length})
                  </span>
                  <span className={styles.typoDark}>
                    ₹
                    {nf(
                      data.data.reciept.reduce(
                        (prev, current) => Number(current?.brandPrice) + prev,
                        0
                      ),
                      "0,0.00"
                    )}
                  </span>
                </Row>
                <Row justify="space-between">
                  <p
                    style={{ cursor: "pointer", display: "flex", gap: "2px" }}
                    onClick={() => setToggle((toggle) => !toggle)}>
                    <span className={styles.typoLight}>
                      Taxes & Charges
                      <img src="/assets/icons/arrowDown.svg" alt="down arrow" />
                    </span>
                  </p>
                  <span className={styles.typoDark}>
                    ₹
                    {nf(
                      Number(data.data.totalReciept) -
                        data.data.reciept.reduce(
                          (prev, current) => Number(current?.brandPrice) + prev,
                          0
                        ),
                      "0,0.00"
                    )}
                  </span>
                </Row>
                <AnimatePresence>
                  {toggle && (
                    <motion.section
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      style={{ padding: "4px" }}
                      variants={panelVariants}>
                      <Row justify="space-between" className={styles.taxesTypo}>
                        <span>Service Charge</span>
                        <span>
                          ₹
                          {nf(
                            data.data.reciept.reduce(
                              (prev, current) =>
                                Number(current.brandServiceChargePrice) + prev,
                              0
                            ),
                            "0,0.00"
                          )}
                        </span>
                      </Row>
                      <Row justify="space-between" className={styles.taxesTypo}>
                        <span>GST (Service Charge)</span>
                        <span>
                          ₹
                          {nf(
                            data.data.reciept.reduce(
                              (prev, current) =>
                                Number(current.brandGstServiceChargePrice) +
                                prev,
                              0
                            ),
                            "0,0.00"
                          )}
                        </span>
                      </Row>
                      <Row justify="space-between" className={styles.taxesTypo}>
                        <span>
                          GST ({data?.data?.reciept.length}{" "}
                          {data?.data?.reciept.length > 1
                            ? "creators"
                            : "creator"}
                          )
                        </span>
                        <span>
                          ₹
                          {nf(
                            data.data.reciept.reduce(
                              (prev, current) =>
                                Number(current.brandGstPrice) + prev,
                              0
                            ),
                            "0,0.00"
                          )}
                        </span>
                      </Row>
                    </motion.section>
                  )}
                </AnimatePresence>
                <Row
                  justify="space-between"
                  className={styles.total}
                  style={{ marginTop: "17px" }}>
                  <span>Total</span>
                  <span>₹{nf(data.data.totalReciept, "0,0.00")}</span>
                </Row>
                {!payToggle && (
                  <Checkbox
                    style={{ marginTop: "28px" }}
                    value={isTermsChecked}
                    defaultChecked={isTermsChecked}
                    onChange={() =>
                      setIsTermsChecked((isTermsChecked) => !isTermsChecked)
                    }>
                    <p className={styles.proceed}>
                      I agree to the{" "}
                      <span style={{ color: "#2130FF" }}>
                        Terms & Conditions
                      </span>
                    </p>
                  </Checkbox>
                )}

                <Button
                  type="primary"
                  block
                  style={{ marginTop: "14px" }}
                  onClick={handleClick}
                  loading={finalizeOfferMutation.isLoading}
                  disabled={!isTermsChecked || paymentLink === "admin"}>
                  {!userData?.data?.isZohoCustomerCreated
                    ? "Update Billing Details"
                    : !payToggle
                    ? "Generate Payment Link"
                    : paymentLink !== "admin"
                    ? "PAY NOW"
                    : "Contact Admin"}
                </Button>
                {paymentLink && paymentLink !== "admin" && (
                  <Row justify="center">
                    <Button type="link" block onClick={() => handleShare()}>
                      <img
                        src="/assets/icons/share.svg"
                        style={{ marginRight: "10px" }}
                        alt="share"
                      />
                      Share Payment Link
                    </Button>
                  </Row>
                )}
              </article>
              {payInId !== "" && (
                <Row justify="center" style={{ marginTop: "12px" }}>
                  <Button
                    type="link"
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                    loading={cancelOfferMutation.isLoading}
                    onClick={() => cancelOfferMutation.mutate({ payInId })}>
                    {" "}
                    Cancel Payment
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        ) : (
          <div>Loading ....</div>
        )}
      </section>
      <Details
        open={billingDetailsModalOpen}
        setOpen={setBillingDetailsModalOpen}
        userData={userData}
      />
    </Modal>
  );
};

export default FinalizeModal;
