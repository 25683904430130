import { sendRequest } from "./helper";

import { User } from "../typings";

export type UserResult = {
  status: string;
  message: string;
  data: User;
};

export const getUserUniversal = () => {
  const queryKey = ["universal", "user"];
  const queryFunction = () =>
    sendRequest<UserResult>("get", `/brand/me/me?fields=all`, false).then(
      (res) => res.data
    );
  return [queryKey, queryFunction] as const;
};
