import React from "react";
import { PinnedMetrics } from "../../YtAnalytics";
import { Row, Col } from "antd";
import { Bar, Doughnut } from "react-chartjs-2";
import { LuPin } from "react-icons/lu";

import styles from "./styles.module.css";

import { formatData } from "../../../../utils";

import StatsCard from "../../AudienceInsights/StatsCard";

type AudienceMetricInsightsProps = {
  data: {
    [key: string]: {
      [key: string]: number;
    };
  };
  metrics: PinnedMetrics[];
  content: PinnedMetrics["content"];
  remove: (title: string, type: "basic" | "audience") => void;
};

const AudienceMetricInsights: React.FC<AudienceMetricInsightsProps> = (
  props
) => {
  const { content, data, metrics, remove } = props;

  const isPinned = (title: string) =>
    metrics.findIndex(
      (item) =>
        item.title === title &&
        item.type === "audience" &&
        item.content === content
    ) !== -1;

  const getCountryViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("countryStats_lifetime" in response)) return result;

    Object.entries(response["countryStats_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result;
  };

  const getDemographicViews = () => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};

    const response = data;
    if (!("viewerDemographics_lifetime" in response)) return totalObject;

    Object.entries(response["viewerDemographics_lifetime"]).forEach(
      ([key, value]) => {
        // const age_genderKey = key.split("$").at(-1) || "$age18-24$male";
        const ageKey = key.split("$").at(1) || "age18-24";
        const genderKey = key.split("$").at(-1) || "female";
        // console.log(key, ageKey, genderKey);
        if (!(ageKey in totalObject)) {
          totalObject = { ...totalObject, [ageKey]: {} };
        }

        if (!(genderKey in totalObject[ageKey])) {
          totalObject = {
            ...totalObject,
            [ageKey]: { ...totalObject[ageKey], [genderKey]: value },
          };
        }
      }
    );

    return totalObject;
  };

  const getAgeRangeData = () => {
    let result: { value: number; label: string }[] = [];

    const response = getDemographicViews();

    let total = 0;

    Object.values(response)?.forEach(
      (value) =>
        (total =
          total +
          Object.values(value).reduce(
            (prev, current) => prev + (current || 0),
            0
          ))
    );
    Object.entries(response).forEach(([key, value]) => {
      result.push({
        label: key,
        value:
          (Object.values(value).reduce(
            (prev, current) => prev + (current || 0),
            0
          ) /
            (total || 1)) *
          100,
      });
    });
    return result;
  };

  const getMaleFemaleSplit = () => {
    const response = getDemographicViews();
    let male =
      Object.values(response).reduce(
        (prev, current) => prev + current?.male,
        0
      ) || 0;
    let female =
      Object.values(response).reduce(
        (prev, current) => prev + current?.female,
        0
      ) || 0;

    let unknown =
      Object.values(response).reduce(
        (prev, current) => prev + current?.user_specified,
        0
      ) || 0;

    return { male, female, unknown };
  };

  const barData = {
    labels: Object.keys(getDemographicViews()),
    datasets: [
      {
        label: "Female",
        data: Object.values(getDemographicViews()).map((item) => item?.female),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.male || 0
        ),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.user_specified || 0
        ),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  };

  const totalCountryViews =
    getCountryViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  return (
    <React.Fragment>
      <Row gutter={[20, 20]}>
        <Col span={18}>
          {isPinned("Age-Gender Ratio") && (
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}>
              <p className={styles.graphLabel}>Age-Gender Ratio</p>
              <div
                style={{
                  width: "100%",
                  height: "308px",
                }}>
                {Object.values(getDemographicViews()).reduce(
                  (prev, current) =>
                    prev +
                      Object.values(current)?.reduce(
                        (x, y) => x + (y || 0),
                        0
                      ) || 0,
                  0
                ) === 0 ? (
                  <p className="global-no-data">
                    {" "}
                    Not enough Data To show this Metric{" "}
                  </p>
                ) : (
                  <Bar
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: "top" as const,
                        },
                        title: {
                          display: false,
                        },
                        datalabels: {
                          anchor: "end",
                          align: "top",
                          formatter: (value) =>
                            `${formatData(value || 0, "0.0a")}%`,
                        },
                      },
                    }}
                    data={barData}
                    style={{ height: "320px" }}
                  />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Age-Gender Ratio", "audience");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          )}
        </Col>
        {isPinned("Country wise split") && (
          <Col span={7}>
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}
              key={1}>
              <p className={styles.graphLabel}>Country wise split</p>
              <StatsCard
                items={getCountryViews().map((item) => ({
                  label: item.label,
                  percent: (item.value / totalCountryViews) * 100,
                }))}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Country wise split", "audience");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}
        {isPinned("Age wise Split") && (
          <Col span={7}>
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}
              key={1}>
              <p className={styles.graphLabel}>Age wise Split</p>
              <StatsCard
                items={getAgeRangeData().map((item) => ({
                  label: item.label.split("age").at(-1) || "",
                  percent: item.value,
                }))}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Age wise Split", "audience");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}

        {isPinned("Gender Split") && (
          <div
            className={styles.container}
            style={{ position: "relative" }}
            key={2}>
            <Row
              justify="space-between"
              style={{ width: "100%" }}
              gutter={[10, 10]}>
              <Col span={24}>
                <p className={styles.label}>Gender Split</p>
              </Col>
            </Row>
            {!(
              getMaleFemaleSplit().male === 0 &&
              getMaleFemaleSplit().female === 0 &&
              getMaleFemaleSplit().unknown === 0
            ) ? (
              <Doughnut
                options={{
                  plugins: {
                    legend: { position: "top" },
                    datalabels: {
                      anchor: "end",
                      align: "start",
                      formatter: (value) => formatData(value || 0, "a"),
                      font: {
                        size: 16,
                        style: "normal",
                      },
                    },
                  },
                }}
                data={{
                  labels: ["Male", "Female", "UnKnown"],
                  datasets: [
                    {
                      label: "No",
                      data: [
                        getMaleFemaleSplit().male,
                        getMaleFemaleSplit().female,
                        getMaleFemaleSplit().unknown,
                      ],
                      backgroundColor: ["#FF6384", "#FFCD56", "#36A2EB"],
                      borderColor: ["#FF6384", "#FFCD56", "#36A2EB"],
                      borderWidth: 1,
                      borderJoinStyle: "round",
                      borderRadius: 10,
                      hoverOffset: 4,
                      offset: 0,
                    },
                  ],
                }}
              />
            ) : (
              <p className="global-no-data">
                {" "}
                Not enough Data To show this Metric{" "}
              </p>
            )}
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                remove("Gender Split", "audience");
              }}>
              <LuPin size={10} color={"#00000066"} />
            </div>
          </div>
        )}
      </Row>
    </React.Fragment>
  );
};
export default AudienceMetricInsights;
