import { sendRequest } from "../helper";

import { RuleById } from "../../typings";

interface createRuleProps {
  data: RuleById;
}

export const createRule = ({ data }: createRuleProps) => {
  const updatedRules = data.rules?.map((rItem) => {
    delete rItem["attachedProductCatalogueObj"];
    return rItem;
  });

  data.rules = updatedRules;
  return sendRequest("post", `/brand/smart-replies/rule`, false, {
    ...data,
  }).then((res) => res.data);
};
