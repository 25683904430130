import { Alert, Col, Row } from "antd";
import Navbar from "../../shared/Navbar";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CampaignSettingsPage from "./Settings";
import { getCampaignById } from "../../../handler/cloutflowCampaign/getCampaignById";
import { useQuery } from "react-query";
import Lottie from "react-lottie";
import { getDefaultLottieOptions } from "../../../utils";
import animationData from "../../../components/assets/lottie/Rocket.json";
import Template from "./Template";

const CampaignDashboard = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const [queryKey, queryFunction] = getCampaignById(id);
  const { data, error, isLoading } = useQuery(queryKey, queryFunction);

  console.log(data);
  const urlAppType = searchParams.get("type");
  const [applicantType, setApplicantType] = useState<
    | "suggested"
    | "shortList"
    | "reject"
    | "finalized"
    | "completed"
    | "settings"
  >("suggested");

  const handleMenuChange = (type: typeof applicantType) => {
    setApplicantType(type);
    return navigate(`/campaign/${id}?type=${type}`, { replace: true });
  };

  useEffect(() => {
    if (
      urlAppType &&
      [
        "suggested",
        "shortList",
        "reject",
        "finalized",
        "completed",
        "settings",
      ].includes(urlAppType)
    ) {
      return setApplicantType(urlAppType as typeof applicantType);
    }
    return handleMenuChange("suggested");
  }, [urlAppType]);

  if (isLoading)
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}
      >
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );

  if (error)
    return (
      <Alert
        message="Error"
        description="There was an error fetching the campaign details."
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Navbar selectedItem="campaigns" />
      <Row style={{ height: "100vh" }}>
        <Col span={5}>
          <Sidebar
            subMenuType={applicantType}
            handleMenuChange={handleMenuChange}
          />
        </Col>
        <Col span={19}>
          {applicantType === "settings" && (
            <CampaignSettingsPage campaign={data?.data} />
          )}
          {/* {applicantType === "suggested" && (
            <CampaignSuggestions campaign={data?.data} />
          )} */}
          {applicantType !== "settings" && (
            <Template handleMenuChange={handleMenuChange} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CampaignDashboard;
