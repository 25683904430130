import { sendRequest } from "../../../helper";

export type pushToCompleteProps = {
  finalizedOfferId: string;
};

type pushToCompleteResults = {
  data: string;
  message: string;
  status: string;
};

export const pushToComplete = ({ finalizedOfferId }: pushToCompleteProps) => {
  return sendRequest<pushToCompleteResults>(
    "put",
    `/brand/execution/completed?finalizedOfferId=${finalizedOfferId}`,
    false,
    {},
    null,
    true
  ).then((res) => res.data);
};
