import React, { useState, useEffect } from "react";
import { Input, MenuProps, Dropdown, Menu } from "antd";

import styles from "./chatInput.module.css";

import { useChatContext } from "../../../../context/ChatContext";

import SendMedia from "./SendMedia";
import SendDocs from "./SendDocs";

const { TextArea } = Input;

const ChatInput = () => {
  const [message, setMessage] = useState<string>("");
  const [showDocs, setShowDocs] = useState<boolean>(false);
  const [showMedia, setShowMedia] = useState<boolean>(false);
  const [sendEnabled, setSendEnabled] = useState<boolean>(false);

  const { handleMessageSend, conversationId } = useChatContext();

  const items: MenuProps["items"] = [
    {
      label: <p onClick={() => setShowMedia(true)}>Send Media</p>,
      key: "0",
    },
    {
      label: <p onClick={() => setShowDocs(true)}>Send Doc</p>,
      key: "1",
    },
  ];
  useEffect(() => {
    return () => {
      setMessage("");
    };
  }, [conversationId]);

  useEffect(() => {
    if (message.length > 0 && message.trim().length > 0) {
      setSendEnabled(true);
    } else {
      setSendEnabled(false);
    }
  }, [message]);

  // const UploadButtonProps: UploadProps = {
  //   name: "file",
  //   multiple: false,
  //   onDrop(e) {},
  //   onChange(info) {
  //     if (info.file.status === "uploading") {
  //       setIsUploadingFile(true);
  //       setMessages([
  //         {
  //           conversationId: conversationId,
  //           author: currentUser?.uid as string,
  //           createDateTime: new Date().toISOString(),
  //           status: "loading",
  //           data: {
  //             type: "string",
  //             msg: "",

  //             cardMessage: { normal: "", blue: "" },
  //           },
  //           __v: 0,
  //           _id: "loader",
  //         },
  //         ...messages,
  //       ]);
  //     }
  //   },
  //   customRequest: (options) => {
  //     const { onSuccess, file, onProgress, onError } = options;

  //     const storage = getStorage();
  //     const fileName = (file as RcFile).name;
  //     const storageRef = ref(
  //       storage,
  //       `/medias/${fileName.slice(0, 10) + cuid()}`
  //     );
  //     const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
  //     // setLoading(true);
  //     const mediaType = (file as RcFile).type.split("/")[0];

  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {},
  //       (error) => {
  //         console.log("firebaseError", error.code, error.message);
  //         onError && onError(error);
  //       },
  //       async () => {
  //         const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

  //         onSuccess && onSuccess(downloadURL);
  //         const removeLoader = () => {
  //           let index = messages.findIndex((msg) => msg._id === "loader");
  //           setMessages([
  //             ...messages.slice(0, index),
  //             ...messages.slice(index + 1, messages?.length),
  //           ]);
  //         };
  //         onSuccess && removeLoader();
  //         setIsUploadingFile(false);
  //         sendFile({ mediaUrl: downloadURL, mediaType, mediaName: fileName });
  //       }
  //     );
  //   },
  //   accept: "image/*,video/*,application/*",
  //   onRemove(file) {},
  // };

  return (
    <div className={styles.container}>
      <Dropdown trigger={["click"]} overlay={<Menu items={items}></Menu>} arrow>
        <img
          style={{ cursor: "pointer" }}
          src="/assets/icons/attachment.svg"
          alt="attachment"
          width={24}
        />
      </Dropdown>

      <TextArea
        autoFocus
        autoSize={{ maxRows: 4 }}
        className={styles.input}
        placeholder="Type your message"
        style={{ borderRadius: "8px" }}
        value={message}
        defaultValue={""}
        onChange={(event) => setMessage(event.target.value)}
        onKeyDown={(e) => {
          if (e.nativeEvent.key === "Enter" && !e.shiftKey && sendEnabled) {
            e.preventDefault();
            e.stopPropagation();
            handleMessageSend(message || "", "string");
            return setMessage("");
          }
        }}
      />

      <img
        src="/assets/icons/arrow-send.svg"
        alt="arrow"
        style={{ cursor: "pointer" }}
        width={24}
        onClick={() => {
          if (sendEnabled) {
            handleMessageSend(message || "", "string");
            setMessage("");
          }
        }}
      />

      <SendDocs openModal={showDocs} setOpenModal={setShowDocs} />
      <SendMedia openModal={showMedia} setOpenModal={setShowMedia} />
    </div>
  );
};

export default ChatInput;
