import { sendRequest } from "../helper";

interface mailOfferProps {
  offerPayInId: string;
}

export const mailOffer = ({ offerPayInId }: mailOfferProps) => {
  return sendRequest(
    "put",
    `/brand/transaction/credit-note/email?offerPayInId=${offerPayInId}`,
    false
  ).then((res) => res.data);
};
