import { sendRequest } from "../../helper";

export type addWorkFlowReqProps = {
  deliverableId: string;
  isDraftNeeded: boolean;
  caption: string;
  mentions: string[];
  name: string;
  summary: string;
  references?: { type: string; url: string }[];
};

export const addWorkFlowReq = ({
  deliverableId,
  isDraftNeeded,
  caption,
  mentions,
  name,
  references,
  summary,
}: addWorkFlowReqProps) => {
  return sendRequest(
    "post",
    `/brand/execution/requirements/add-requirements`,
    false,
    {
      deliverableId,
      isDraftNeeded,
      caption,
      mentions,
      name,
      references,
      summary,
    }
  ).then((res) => res.data);
};
