import { Modal, Row, message } from "antd";
import React, { useState } from "react";
import { AxiosError } from "axios";
import { useQueryClient, useMutation } from "react-query";

import styles from "./styles.module.css";

import CustomButton from "../../../../shared/Custom/CustomButton";
import CustomInput from "../../../../shared/Custom/Input";

import { validateUsername } from "../../../../../utils/validateUsername";

import { getUser } from "../../../../../handler/getUser";
import { updateUsername, checkUserName } from "../../../../../handler/invite";

type EditModalProps = {
  slug: string | undefined;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditModal: React.FC<EditModalProps> = (props) => {
  const { showModal, setShowModal, slug } = props;

  const [state, setState] = useState<{
    value: string;
    touched: boolean;
    error: string;
  }>({
    value: slug ?? "",
    touched: false,
    error: "",
  });

  const [userKey] = getUser();

  const queryClient = useQueryClient();

  const updateUserNameMutation = useMutation(updateUsername, {
    onSuccess: (response) => {
      if (response.status === "SUCCESS") {
        message.success("Username updated successfully");
        queryClient.invalidateQueries(userKey);
      }
      setShowModal(false);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const checkMutation = useMutation(checkUserName, {
    onSuccess: (response) => {
      if (response.data?.isSlugAvailable) {
        updateUserNameMutation.mutate({ username: state.value.trim() });
      } else {
        setState((state) => ({
          ...state,
          error: "This Slug is already taken",
        }));
      }
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <Modal
      width={896}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        setShowModal(false);
      }}
      visible={showModal}
      centered
      className={"campaign-create-modal"}>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
          borderBottom: "1px solid #0000001A",
        }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 22,
            lineHeight: "normal",
            fontFamily: "Inter",
          }}>
          Customize Uni - Link
        </p>
      </Row>

      <div style={{ marginBlock: 25 }}>
        <h2 className={styles.heading}>
          Personalize the URL for your Network Page’s Uni-Link
        </h2>
        <div className={styles.noteContainer}>
          <p className={styles.noteText}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{
                width: 20,
                height: 20,
              }}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span>
              Your last link shall become invalid after creating the new link.
            </span>
          </p>
        </div>
        <p className={styles.text}>
          It’s simple, just change the name as suffix to your
          <br /> desired one.
          <br />
          <br />
          You can change this later.
        </p>
        <span className={styles.link}>
          connect.cloutflow.com/brand/{state.value}
        </span>
      </div>

      <div className={styles.inputWrapper}>
        <CustomInput
          placeholder="Enter Name"
          containerStyles={{ width: "342px", gap: 12 }}
          value={state.value}
          onClick={() => setState((prev) => ({ ...prev, touched: true }))}
          onChange={(e) => {
            setState((prev) => ({ ...prev, value: e.target.value }));
          }}
          error={
            state?.error
              ? state.error
              : state.touched
              ? state.value.trim() === ""
                ? "Slug can't be empty"
                : validateUsername(state.value.trim())[0]
              : undefined
          }
        />
      </div>

      <Row justify="start">
        <CustomButton
          title="Create"
          type="primary"
          style={{
            padding: "14px 30px",
            borderRadius: "10px",
            height: "50px",
            border: "1px solid #FFF",
            color:
              state.value.trim() === "" || validateUsername(state.value)[1]
                ? "#999"
                : "#fff",
            background:
              state.value.trim() === "" || validateUsername(state.value)[0]
                ? "#f5f5f5"
                : "#533AF5",
          }}
          onClick={() =>
            checkMutation.mutate({
              username: state.value.trim(),
            })
          }
          disabled={
            state.value.trim().length === 0 ||
            validateUsername(state.value.trim())[1]
          }
          loading={checkMutation.isLoading || updateUserNameMutation.isLoading}>
          Update Link
        </CustomButton>
        <CustomButton
          title="Cancel"
          type="link"
          style={{
            padding: "14px 30px",
            borderRadius: "10px",
            height: "50px",
            border: "1px solid #FFF",
            color: "#333",
          }}
          disabled={checkMutation.isLoading || updateUserNameMutation.isLoading}
          onClick={() => setShowModal(false)}
          loading={false}>
          Cancel
        </CustomButton>
      </Row>
    </Modal>
  );
};
export default EditModal;
