import React from "react";

import { Drawer } from "antd";
import type { DrawerProps } from "antd";

interface CDrawerProps extends DrawerProps {
  visible: boolean;
  handleClose: () => void;
}

const CustomDrawer: React.FC<CDrawerProps> = ({
  children,
  handleClose,
  visible,
  title,
  ...props
}) => {
  return (
    <Drawer
      title={title}
      closable={false}
      onClose={handleClose}
      destroyOnClose
      {...props}
      open={visible}
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
