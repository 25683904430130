import React from "react";
import { Row, Col } from "antd";
import { LuPin } from "react-icons/lu";
import { Doughnut } from "react-chartjs-2";
import { MdTimeline } from "react-icons/md";

import styles from "./styles.module.css";

import { PinnedMetrics } from "../../YtAnalytics";
import { formatData, capitalizeFirstLetter } from "../../../../utils";

import StatsCard from "../../AudienceInsights/StatsCard";
import LineGraph from "../../BasicInsights/LineGraph";

type BasicMetricInsightsPinnedProps = {
  data: {
    [key: string]: {
      [key: string]: number;
    };
  };
  metrics: PinnedMetrics[];
  content: PinnedMetrics["content"];
  remove: (title: string, type: "basic" | "audience") => void;
};

const BasicMetricInsightsPinned: React.FC<BasicMetricInsightsPinnedProps> = ({
  data,
  metrics,
  content,
  remove,
}) => {
  const isPinned = (title: string) =>
    metrics.findIndex(
      (item) =>
        item.title === title &&
        item.type === "basic" &&
        item.content === content
    ) !== -1;

  const getDayViews = () => {
    let result: { labels: string[]; values: number[] } = {
      labels: [],
      values: [],
    };
    const response = data;

    if (!("basicStats_last_30_days" in response)) return result;

    Object.entries(response["basicStats_last_30_days"]).forEach(
      ([key, value]) => {
        result = {
          labels: [...result.labels, key.split("$").at(-1) || ""],
          values: [...result.values, value],
        };
      }
    );

    return result;
  };

  const getSubscribedViews = () => {
    const response = data;
    if (!("subscribedStatus_lifetime" in response))
      return { subs: 0, unSubs: 0 };
    let subs = response["subscribedStatus_lifetime"]["$SUBSCRIBED"] || 0;
    let unSubs = response["subscribedStatus_lifetime"]["$UNSUBSCRIBED"] || 0;

    return { subs, unSubs };
  };

  const getPlaybackLocationsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("playBackLocation_lifetime" in response)) return result;

    Object.entries(response["playBackLocation_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result;
  };

  const getOsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("operatingSystemDeviceType_lifetime" in response)) return result;

    Object.entries(response["operatingSystemDeviceType_lifetime"]).forEach(
      ([key, value]) => {
        result.push({
          value,
          label: key.split("$").at(-1)?.split("$").join(" ") || "",
        });
      }
    );
    return result;
  };
  const getTrafficViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("trafficSource_lifetime" in response)) return result;

    Object.entries(response["trafficSource_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result;
  };

  const totalLocationViews =
    getPlaybackLocationsViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalTrafficViews =
    getTrafficViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalOsViews =
    getOsViews().reduce((prev, current) => prev + (current.value || 0), 0) || 1;

  return (
    <React.Fragment>
      <Row gutter={[20, 20]}>
        {isPinned("Views per day") && (
          <Col span={16}>
            <div>
              <LineGraph
                key={0}
                title="Views per day"
                optionsItemArray={[]}
                selectedOption={""}
                labelArray={getDayViews().labels}
                metric={formatData(
                  getDayViews().values.reduce(
                    (prev, current) => prev + current,
                    0
                  ),
                  "a"
                )}
                metricLabel={`Total Views`}
                description={<></>}
                graphData={getDayViews().values}
                secondaryDescription={<p>Views per day</p>}
                secondaryIcon={<MdTimeline size={15} color="#000000B2" />}
                barColor="#398200"
                isPinned={true}
                handlePin={() => {
                  remove("Views per day", "basic");
                }}
              />
            </div>
          </Col>
        )}
        {isPinned("Views by subscribers") && (
          <Col span={8}>
            <div
              className={styles.container}
              style={{ position: "relative" }}
              key={2}>
              <Row
                justify="space-between"
                style={{ width: "100%" }}
                gutter={[10, 10]}>
                <Col span={24}>
                  <p className={styles.label}>Views by subscribers</p>
                </Col>
                <Col span={24}>
                  <p className={styles.sublabel}>
                    <MdTimeline size={15} color="#000000B2" />
                    Views by subscribers
                  </p>
                </Col>
              </Row>
              {!(
                getSubscribedViews().subs === 0 &&
                getSubscribedViews().unSubs === 0
              ) ? (
                <Doughnut
                  options={{
                    plugins: {
                      legend: { position: "top" },
                      datalabels: {
                        anchor: "end",
                        align: "start",
                        formatter: (value) => formatData(value || 0, "a"),
                        font: {
                          size: 16,
                          style: "normal",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Subscribers", "UnSubscribers"],
                    datasets: [
                      {
                        label: "No",
                        data: [
                          getSubscribedViews().subs,
                          getSubscribedViews().unSubs,
                        ],
                        backgroundColor: ["#FF6384", "#FFCD56"],
                        borderColor: ["#FF6384", "#FFCD56"],
                        borderWidth: 1,
                        borderJoinStyle: "round",
                        borderRadius: 10,
                        hoverOffset: 4,
                        offset: 0,
                      },
                    ],
                  }}
                />
              ) : (
                <p className="global-no-data">
                  {" "}
                  Not enough Data To show this Metric{" "}
                </p>
              )}

              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Views by subscribers", "basic");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}
        {isPinned("PlaybackLocation wise split") && (
          <Col span={8}>
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}
              key={3}>
              <p className={styles.graphLabel}>PlaybackLocation wise split</p>
              <StatsCard
                items={getPlaybackLocationsViews().map((item) => ({
                  label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                  percent: (item.value / totalLocationViews) * 100,
                }))}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("PlaybackLocation wise split", "basic");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}
        {isPinned("Traffic wise split") && (
          <Col span={8}>
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}
              key={4}>
              <p className={styles.graphLabel}>Traffic wise split</p>
              <StatsCard
                items={getTrafficViews().map((item) => ({
                  label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                  percent: (item.value / totalTrafficViews) * 100,
                }))}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Traffic wise split", "basic");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}
        {isPinned("Device wise split") && (
          <Col span={8}>
            <div
              className={styles.graphContainer}
              style={{ position: "relative" }}
              key={5}>
              <p className={styles.graphLabel}>Device wise split</p>
              <StatsCard
                items={getOsViews().map((item) => ({
                  label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                  percent: (item.value / totalOsViews) * 100,
                }))}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  remove("Device wise split", "basic");
                }}>
                <LuPin size={10} color={"#00000066"} />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};
export default BasicMetricInsightsPinned;
