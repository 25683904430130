import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Lottie from "react-lottie";
import { Row } from "antd";
import { usePDF } from "react-to-pdf";

import animationData from "../../components/assets/lottie/Rocket.json";

import Header from "./Header";
import IgAnalytics from "./IgAnalytics";
import Overview from "./Overview";
import YtAnalytics from "./YtAnalytics";
import Navbar from "../shared/Navbar";

import { getDefaultLottieOptions } from "../../utils";

import styles from "./styles.module.css";

import { getInfluencer, getInfluencerMedia } from "../../handler/Profile";

export type TSection =
  | {
      type: "overview";
    }
  | {
      type: "instagram";
      id: string;
      socialMediaKitId: string;
    }
  | {
      type: "youtube";
      id: string;
      socialMediaKitId: string;
    };

const ProfileAnalysis: React.FC<{
  initialId?: string;
  inDrawer?: boolean;
  isQueryCalled: boolean;
}> = ({ initialId = "", inDrawer = false, isQueryCalled = true }) => {
  const { id } = useParams();

  const influencerId = initialId || id || "";

  const { toPDF, targetRef } = usePDF();

  // id is influencer Id

  const [selectedSection, setSelectedSection] = useState<TSection>({
    type: "overview",
  });

  const handleChangeSection = (section: TSection) => {
    return setSelectedSection(section);
  };

  const [getInfluencerKey, getInfluencerFn] = getInfluencer(influencerId || "");
  const {
    data: influencerData,
    isLoading: isInfluencerDataLoading,
    isError,
  } = useQuery(getInfluencerKey, getInfluencerFn, {
    enabled: isQueryCalled && Boolean(influencerId),
  });

  const [getInfluencerMediaKey, getInfluencerMediaFn] = getInfluencerMedia(
    influencerId || ""
  );

  const { data: influencerMedia, isLoading: isInfluencerMediaLoading } =
    useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {
      enabled: isQueryCalled && Boolean(influencerId),
    });

  useEffect(() => {
    if (selectedSection.type !== "overview" && influencerId && inDrawer) {
      setSelectedSection({ type: "overview" });
    }
  }, [influencerId]);

  if (isInfluencerDataLoading || isInfluencerMediaLoading) {
    return (
      <Row justify="center" style={{ marginBlock: 200 }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={500}
          width={270}
        />
      </Row>
    );
  }

  if (!isQueryCalled) {
    return null;
  }

  if (isError || !influencerData?.data) {
    return <div>No Influencer Found</div>;
  }

  const influencerMediaIdInsta =
    influencerMedia?.data?.find(
      (item) => item.socialMediaPlatform === "instagram"
    )?.socialMediaId || "";

  return (
    <>
      {!inDrawer && <Navbar selectedItem="discover" />}
      <div
        className={styles.container}
        style={{ padding: inDrawer ? "0" : undefined }}
        ref={targetRef}>
        <Header
          influencerId={influencerId}
          name={influencerData?.data?.name}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          inDrawer={inDrawer}
          keys={influencerMedia?.data || []}
          downloadPdf={() => {
            toPDF({
              filename:
                (influencerData?.data?.name + "_" + selectedSection.type ||
                  "influencerReport") + ".pdf",
            });
          }}
        />

        {selectedSection.type === "instagram" ? (
          <IgAnalytics
            socialMediaId={selectedSection.id}
            influencerId={influencerId}
            socialMediaName={influencerData?.data?.name || ""}
            socialMediaKitId={selectedSection.socialMediaKitId}
            inDrawer={inDrawer || false}
          />
        ) : selectedSection.type === "overview" ? (
          <Overview
            influencerId={influencerId}
            socialMediaIdInsta={influencerMediaIdInsta}
            handleChangeSection={handleChangeSection}
          />
        ) : selectedSection.type === "youtube" ? (
          <div style={{ marginTop: "40px" }}>
            <YtAnalytics
              influencerId={influencerId}
              socialMediaId={selectedSection.id}
              socialMediaName={influencerData?.data?.name}
              socialMediaKitId={selectedSection.socialMediaKitId}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ProfileAnalysis;
