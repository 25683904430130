import React, { useState } from "react";
import { Row, Col, Space, Popover } from "antd";
import { PiLinkSimpleHorizontalBold } from "react-icons/pi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdTimeline } from "react-icons/md";
import { useQuery } from "react-query";
import { Bar } from "react-chartjs-2";
import { LuPin, LuPinOff } from "react-icons/lu";
import { BiCaretDown } from "react-icons/bi";
import { BsPersonFillCheck } from "react-icons/bs";

import LineGraph from "./LineGraph";
import CustomDropDown from "../../shared/Custom/DropDown";

import { timesArray } from "../../../constants";

import styles from "./styles.module.css";

import { getIgAnalytics } from "../../../handler/Profile";

import { PinnedMetric } from "../IgAnalytics";

import {
  getDayName,
  DayType,
  daysOfWeek,
  findSequentialObjectsFromEnd,
  calculateArrayAverage,
  formatData,
  addArrays,
} from "../../../utils";

const BasicInsights: React.FC<{
  influencerId: string;
  pinnedMetrices: PinnedMetric[];
  setPinnedMetrices: React.Dispatch<React.SetStateAction<PinnedMetric[]>>;
}> = ({ influencerId, pinnedMetrices, setPinnedMetrices }) => {
  const [influencerKey, influencerFn] = getIgAnalytics(influencerId || "");

  const { data, isError } = useQuery(influencerKey, influencerFn, {
    enabled: Boolean(influencerId),
  });

  const [linkOption, setLinkOption] = useState<"week" | "month">("month");
  const [followerOption, setFollowerOption] = useState<"week" | "month">(
    "month"
  );

  const handlePin = (args: { title: string; period: typeof linkOption }) => {
    const arr = pinnedMetrices.filter((item) => item.title === args.title);
    const idx = arr.findIndex((item) => item.period === args.period);
    if (idx > -1) {
      setPinnedMetrices((prev) =>
        prev.filter(
          (item) => !(item.title === args.title && item.period === args.period)
        )
      );
    } else {
      setPinnedMetrices((prev) => [...prev, args]);
    }
  };

  const isPinned = (title: string, type?: "link" | "follower") => {
    const arr = pinnedMetrices
      .filter((item) => item.title === title)
      .filter(
        (item) =>
          item.period === (type === "link" ? linkOption : followerOption)
      );

    return arr.length > 0;
  };

  const isPinnedOnlineFollowers =
    pinnedMetrices.findIndex(
      (item) => item.title === "Average Online Followers"
    ) != -1;

  const getLinksData = () => {
    if (
      data?.data?.user?.website_clicks_last_30_days?.isValue &&
      !data?.data?.user?.website_clicks_last_30_days?.isError &&
      data?.data?.user?.website_clicks_last_30_days?.isMetricDataAviable
    ) {
      if (linkOption === "month")
        return (
          (
            data?.data?.user?.website_clicks_last_30_days?.values as Array<any>
          )?.map((item) => item?.value || 0) || new Array(30).fill(0)
        );
      else {
        return (
          (data?.data?.user?.website_clicks_last_30_days?.values as Array<any>)
            ?.slice(-7)
            ?.map((item) => item?.value || 0) || new Array(30).fill(0)
        );
      }
    }

    return new Array(linkOption === "week" ? 7 : 30).fill(0);
  };

  const getFollowersData = () => {
    if (
      data?.data?.user?.follower_count_last_30_days?.isValue &&
      !data?.data?.user?.follower_count_last_30_days?.isError &&
      data?.data?.user?.follower_count_last_30_days?.isMetricDataAviable
    ) {
      if (followerOption === "month")
        return (
          (data?.data?.user?.follower_count_last_30_days?.values as Array<any>)
            ?.slice(0, 28)
            .map((item) => item?.value || 0) || new Array(28).fill(0)
        );
      else {
        return (
          (data?.data?.user?.follower_count_last_30_days?.values as Array<any>)
            ?.slice(-7)
            ?.slice(0, 5)
            ?.map((item) => item?.value || 0) || new Array(30).fill(0)
        );
      }
    }

    return new Array(followerOption === "week" ? 5 : 28).fill(0);
  };

  const getOnlineFollowersDataDays = () => {
    let totalFollowerObjectArray: { [key in DayType]: number[] } = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    };

    if (
      data?.data?.user?.online_followers_lifetime?.isValue &&
      !data?.data?.user?.online_followers_lifetime?.isError &&
      data?.data?.user?.online_followers_lifetime?.isMetricDataAviable
    ) {
      const getSequenceDays = findSequentialObjectsFromEnd(
        data?.data?.user?.online_followers_lifetime?.values,
        7
      );

      getSequenceDays.forEach((item) => {
        const dayName = getDayName(item.end_time);
        const hoursArray = Object.values(item.value) as number[];

        totalFollowerObjectArray[dayName] = hoursArray;
      });
    }
    return totalFollowerObjectArray;
  };

  const getBarData = (isDay = false) => {
    const data = getOnlineFollowersDataDays();
    if (isDay) {
      return {
        labels: Object.keys(data),
        datasets: [
          {
            label: "Online Followers",
            data: Object.values(data).map((valItem) =>
              Math.round(calculateArrayAverage(valItem))
            ),
            backgroundColor: "#533AF5",
            borderRadius: 4,
          },
        ],
      };
    }

    return {
      labels: timesArray,
      datasets: [
        {
          label: "Online Followers",
          data: addArrays(Object.values(data)).map((item) =>
            Math.round(item / 7)
          ),
          backgroundColor: "#533AF5",
          borderRadius: 4,
        },
      ],
    };
  };

  const getLabelsArray = (totalLength: number) => {
    return new Array(totalLength)
      .fill("Day")
      .map((item, idx) => `${item} ${idx}`);
  };

  const getColor = (value: number) => {
    const percentageToVal = value * 100;
    if (percentageToVal < 10) {
      return "red";
    }
    if (percentageToVal >= 10 && percentageToVal <= 50) {
      return "#F0BF4C";
    }
    return "#398200";
  };

  const getGoodTime = (isDay = false) => {
    const labelsArray = getBarData(isDay)?.labels;
    const data = getBarData(isDay)?.datasets[0]?.data || [];
    const maxIndex = data?.indexOf(Math.max(...data));
    return labelsArray.at(maxIndex);
  };

  const getOnlineFollowersAggregate = () => {
    let maxOnlineFollowers = 0;
    let averageOnlineFollowers = 0;
    let totalOnlineFollowersSum = 0;
    let totalOnlineFollowers = 0;
    let estimatedGenuineFollower = 0;

    const dataToIterate =
      (data?.data?.user?.online_followers_lifetime?.values as Array<any>) || [];

    if (
      data?.data?.user?.online_followers_lifetime?.isValue &&
      !data?.data?.user?.online_followers_lifetime?.isError &&
      data?.data?.user?.online_followers_lifetime?.isMetricDataAviable
    ) {
      dataToIterate?.forEach((item) => {
        const dataArray = Object.values(item?.value || {}) as number[];
        const maxNumber = Math.max(...dataArray) || 0;

        maxOnlineFollowers = Math.max(maxNumber, maxOnlineFollowers);
        const arraySum = dataArray?.reduce((prev, current) => {
          return (prev || 0) + (current || 0);
        }, 0);
        totalOnlineFollowersSum += arraySum;
        totalOnlineFollowers += dataArray?.length;
      });
    }
    averageOnlineFollowers =
      (totalOnlineFollowersSum || 0) / (totalOnlineFollowers || 1);

    estimatedGenuineFollower = Math.min(
      ((maxOnlineFollowers || 0) * 2.5) /
        (data?.data?.user?.followers_count || 1),
      1
    );

    return { estimatedGenuineFollower, averageOnlineFollowers };
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Basic Insights</p>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <LineGraph
            isPinned={isPinned("Link in bio", "link")}
            handlePin={() =>
              handlePin({
                title: "Link in bio",
                period: linkOption,
              })
            }
            title="Link in bio"
            optionsItemArray={[
              { label: "week", onClick: () => setLinkOption("week") },
              { label: "month", onClick: () => setLinkOption("month") },
            ]}
            selectedOption={linkOption}
            labelArray={getLabelsArray(getLinksData().length)}
            metric={formatData(
              getLinksData().reduce((prev, current) => current + prev, 0) || 0,
              "a"
            )}
            metricLabel={`Clicks in one ${linkOption}`}
            description={
              <>
                {data?.data?.user?.website ? (
                  <p>
                    Current Link in bio
                    <br />
                    <a
                      className={styles.anchor}
                      href={data?.data?.user?.website}
                      target="_blank"
                      rel="noopener noreferrer">
                      {data?.data?.user?.website}
                    </a>
                  </p>
                ) : (
                  <div />
                )}
              </>
            }
            graphData={getLinksData()}
            secondaryDescription={
              <p>
                Clicks on link in bio
                <br /> for past 1 {linkOption}
              </p>
            }
            secondaryIcon={<MdTimeline size={15} color="#000000B2" />}
          />
        </Col>
        <Col span={12}>
          <LineGraph
            isPinned={isPinned("Follower growth", "follower")}
            handlePin={() =>
              handlePin({
                title: "Follower growth",
                period: followerOption,
              })
            }
            title="Follower growth"
            optionsItemArray={[
              { label: "week", onClick: () => setFollowerOption("week") },
              { label: "month", onClick: () => setFollowerOption("month") },
            ]}
            selectedOption={followerOption}
            labelArray={getLabelsArray(getFollowersData().length)}
            metric={formatData(
              getFollowersData().reduce((prev, current) => current + prev, 0) ||
                0,
              "a"
            )}
            metricLabel={`Follows in one ${followerOption}`}
            description={
              <p
                className={styles.followerGrowth}
                style={{
                  color: getColor(
                    Math.min(
                      (getFollowersData().reduce(
                        (prev, current) => current + prev,
                        0
                      ) || 0) / (data?.data?.user?.followers_count || 1),
                      1
                    )
                  ),
                }}>
                Follows in one {followerOption}
                <br />
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  {formatData(
                    Math.min(
                      (getFollowersData().reduce(
                        (prev, current) => current + prev,
                        0
                      ) || 0) / (data?.data?.user?.followers_count || 1),
                      1
                    ),
                    "%"
                  )}
                </span>
              </p>
            }
            graphData={getFollowersData()}
            secondaryDescription={
              <p>
                Followers gained or lost in
                <br /> past one {followerOption}
              </p>
            }
            secondaryIcon={
              <PiLinkSimpleHorizontalBold size={15} color="#000000B2" />
            }
            barColor="#398200"
          />
        </Col>
        <Col span={24}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                if (isPinnedOnlineFollowers) {
                  return setPinnedMetrices?.((prev) => {
                    return prev.filter(
                      (x) => !(x.title === "Average Online Followers")
                    );
                  });
                }
                return setPinnedMetrices((pinned) => [
                  ...pinned,
                  { title: "Average Online Followers" },
                ]);
              }}>
              {isPinnedOnlineFollowers ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <Row justify="space-between" style={{ width: "100%" }}>
              <p className={styles.graphLabel}>Average Online Followers</p>

              {/* <CustomDropDown
                items={[
                  {
                    label: "Daily",
                    onClick: () => setOnlineFollowerOption("day"),
                    key: "1",
                  },
                  {
                    label: "Hourly",
                    onClick: () => setOnlineFollowerOption("hour"),
                    key: "2",
                  },
                ]}>
                <a
                  onClick={(e) => e.preventDefault()}
                  className={styles.optionsContainer}>
                  {onlineFollowerOption == "day" ? "Daily" : "Hourly"}
                  <BiCaretDown color="#00000080" size={8} />
                </a>
              </CustomDropDown> */}
            </Row>
            {getBarData().datasets[0]?.data.reduce(
              (prev, current) => prev + current || 0,
              0
            ) !== 0 && (
              <>
                <p
                  className={styles.secondaryDescription}
                  style={{ fontSize: 14, maxWidth: "fit-content" }}>
                  Estimated Genuine Follower{" "}
                  <Popover
                    title={
                      <p>
                        25% of followers of any account come online at any given
                        hour on an average,
                        <br /> multiplying the average online followers by 4, we
                        get estimated genuine <br /> followers of an account.
                      </p>
                    }
                    placement="right"
                    arrowPointAtCenter>
                    <IoMdInformationCircleOutline style={{ marginLeft: 5 }} />
                  </Popover>
                  <br />
                  <span
                    style={{
                      color: getColor(
                        getOnlineFollowersAggregate().estimatedGenuineFollower
                      ),
                    }}>
                    {formatData(
                      getOnlineFollowersAggregate().estimatedGenuineFollower,
                      "%"
                    )}
                  </span>
                </p>
                <Row justify="space-between" style={{ width: "100%" }}>
                  <Space align="center" size={10}>
                    <BsPersonFillCheck size={15} color="#000000B2" />
                    <p className={styles.secondaryDescription}>
                      Best Time to Post is <b>{getGoodTime()}</b> on{" "}
                      <b>{getGoodTime(true)}</b>
                    </p>
                  </Space>
                  <div>
                    <p className={styles.metric}>
                      {formatData(
                        getOnlineFollowersAggregate()?.averageOnlineFollowers,
                        "a"
                      )}
                    </p>
                    <p className={styles.metricLabel}>Avg Online Followers</p>
                  </div>
                </Row>

                <p style={{ color: "#533AF5" }}></p>
              </>
            )}
            <div
              style={{
                width: "100%",
                height: "308px",
              }}>
              {getBarData().datasets[0]?.data.reduce(
                (prev, current) => prev + current || 0,
                0
              ) === 0 ? (
                <p className="global-no-data">
                  Not enough Data To show this Metric
                </p>
              ) : (
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: false,
                      },
                      datalabels: {
                        anchor: "end",
                        align: "top",
                        formatter: (value) => formatData(value || 0, "a"),
                      },
                    },
                  }}
                  data={getBarData()}
                  style={{ height: "320px" }}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BasicInsights;
