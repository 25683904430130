import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type changeVisibilityProps = {
  campaignId: string;
  isPublic: boolean;
};

export const changeVisibility = ({
  campaignId,
  isPublic,
}: changeVisibilityProps) => {
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/edit/${campaignId}`,
    false,
    { isPublic }
  ).then((res) => res.data);
};
