import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Row } from "antd";
import Lottie from "react-lottie";

import InformationCard from "../InformationCard";
import BasicInfo from "../BasicInfo";
import Selector from "./Selector";
import VideoInsights from "./VideoInsights";
import BasicInsights from "./BasicInsights";
import AudienceInsights from "./AudienceInsights";
import ThirdParty from "../ThirdParty";

import { getInfluencerMedia, getYtAnalytics } from "../../../handler/Profile";
import { getConfigurationReportOnlyCampaign } from "../../../handler/report";
import { getPinnedMetrics } from "../../../handler/Profile/getPinnedMetrics";
import { updatePinnedMetrics } from "../../../handler/Profile/updatePinnedMetrics";
// import { getInfluencerCommunityRelation } from "../../../handler/report";

import { formatData } from "../../../utils";
import { getDefaultLottieOptions } from "../../../utils";

import animationData from "../../assets/lottie/Rocket.json";

export type PinnedMetrics = {
  title: string;
  content: "video" | "shorts";
  type?: "basic" | "audience";
};

type YtProps = {
  influencerId: string;
  socialMediaId: string;
  socialMediaName: string;
  socialMediaKitId: string;
};

const YtAnalytics: React.FC<YtProps> = (props) => {
  const { influencerId, socialMediaName, socialMediaId } = props;

  const queryClient = useQueryClient();

  const isInitialMount = useRef(true);

  const [getInfluencerMediaKey, getInfluencerMediaFn] =
    getInfluencerMedia(influencerId);

  const { data: influencerMediaData, isLoading } = useQuery(
    getInfluencerMediaKey,
    getInfluencerMediaFn,
    {
      enabled: Boolean(influencerId),
    }
  );

  const youtubeData = influencerMediaData?.data?.find(
    (item) => item.socialMediaId === socialMediaId
  );

  const [pinnedMetrics, setPinnedMetrics] = useState<PinnedMetrics[]>([]);

  const updatePinnedMetricsMutation = useMutation(updatePinnedMetrics);
  // const [getInfluencerCommunityRelationKey, getInfluencerCommunityRelationFn] =
  //   getInfluencerCommunityRelation({ socialMediaId });

  // const { data: relationData } = useQuery(
  //   getInfluencerCommunityRelationKey,
  //   getInfluencerCommunityRelationFn,
  //   { enabled: Boolean(socialMediaId) }
  // );

  const onPinnedMetricesChange = () => {
    const payload = pinnedMetrics.map((item) => Object.values(item).join("%"));
    updatePinnedMetricsMutation.mutate(
      {
        platform: "youtube",
        pinnedFields: payload,
      },
      {
        onSuccess: () => console.log("Success"),
        onError: () => console.log("Something went wrong!"),
      }
    );
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onPinnedMetricesChange();
    }
  }, [pinnedMetrics]);

  const [key, fn] = getPinnedMetrics("youtube");
  const {
    data: metricsData,
    isLoading: loadingMetrics,
    isError: errorLoadingMetrics,
  } = useQuery(key, fn);

  useEffect(() => {
    if (metricsData) {
      setPinnedMetrics(
        metricsData.data.map((item) => {
          const arr = item.split("%");
          return {
            title: arr[0],
            content: arr[1] as PinnedMetrics["content"],
            ...(arr[2] && { type: arr[2] as PinnedMetrics["type"] }),
          };
        })
      );
    }
  }, [metricsData]);

  const [getInfluencerMediaInsightsKey, getInfluencerMediaInsightsFn] =
    getYtAnalytics(socialMediaId || "");

  const { data: influencerMediaInsightsData, isLoading: insightsLoading } =
    useQuery(getInfluencerMediaInsightsKey, getInfluencerMediaInsightsFn, {
      enabled:
        Boolean(socialMediaId) &&
        !Boolean(youtubeData?.isProfileLockedForBrand) &&
        Boolean(youtubeData?.isInBrandCommunity) &&
        youtubeData?.dataProvider === "default",
    });

  const [configKey, configFn] = getConfigurationReportOnlyCampaign({
    platform: "youtube",
    campaignId: "",
  });

  const { data: configData, isLoading: configLoading } = useQuery(
    configKey,
    configFn,
    {
      enabled:
        Boolean(influencerId) &&
        !Boolean(youtubeData?.isProfileLockedForBrand) &&
        Boolean(youtubeData?.isInBrandCommunity) &&
        youtubeData?.dataProvider === "default",
    }
  );

  const [content, setContent] = useState<"video" | "shorts">("video");

  const handlePin = (title: PinnedMetrics["title"]) => {
    const item = pinnedMetrics.find(
      (metric) => metric.title === title && metric.content === content
    );

    if (item) {
      setPinnedMetrics((prev) =>
        prev.filter(
          (metric) => !(metric.title === title && metric.content === content)
        )
      );
    } else {
      setPinnedMetrics((prev) => [...prev, { title, content }]);
    }
  };

  const extractData = () => {
    let totalObjectData: { [key: string]: { [key: string]: number } } = {};

    if (!influencerMediaInsightsData) return totalObjectData;

    Object.entries(influencerMediaInsightsData?.data?.user).forEach(
      ([key, value]) => {
        if (
          typeof value !== "object" ||
          key === "basicStats_lifetime" ||
          key === "basicStats_lifetime"
        ) {
          return;
        }

        if (content === key) {
          Object.entries(value as { [key: string]: any }).forEach(
            ([objKey, objValue]) => {
              if (
                objKey !== "count" &&
                objKey !== "basicStats_lifetime" &&
                !objValue?.isError &&
                !objValue?.isErrorTransformingPayload &&
                !Object.is(objValue?.data, null)
              ) {
                if (!(objKey in totalObjectData)) {
                  totalObjectData = { ...totalObjectData, [objKey]: {} };
                }
                Object.entries(
                  objValue?.data as { [key: string]: any }
                ).forEach(([internalKey, internalObj]) => {
                  totalObjectData = {
                    ...totalObjectData,
                    [objKey]: {
                      ...totalObjectData[objKey],
                      [internalKey]:
                        internalObj?.views ||
                        internalObj?.viewerPercentage ||
                        0,
                    },
                  };
                });
              }
            }
          );
        }
      }
    );
    return totalObjectData;
  };

  const getVideoInsightsData = (type: typeof content) => {
    const config = configData?.data?.reportConfiguration?.fields;
    const videoInsights =
      influencerMediaInsightsData?.data?.user?.[type]?.basicStats_lifetime;

    if (!videoInsights?.isError && !videoInsights?.isErrorTransformingPayload) {
      return Object.entries(videoInsights?.data || [])
        .map(([key, value]) => ({
          key: key,
          value: value as number,
        }))
        .map((item) => {
          const configItem = config?.find((x) => x.variableName === item.key);
          if (configItem) {
            return {
              ...item,
              name: configItem.name,
              description: configItem.description,
            };
          } else {
            return [];
          }
        })
        .flat();
    } else return [];
  };

  if (isLoading) {
    return (
      <Row justify="center" style={{ marginBlock: 200 }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={500}
          width={270}
        />
      </Row>
    );
  }

  const handleInvalidate = () => {
    queryClient.invalidateQueries(configKey);
    queryClient.invalidateQueries(getInfluencerMediaInsightsKey);
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(getInfluencerMediaKey);
  };

  return (
    <div style={{ display: "grid", gap: "20px" }}>
      <InformationCard
        platform="youtube"
        hasAccess={Boolean(youtubeData?.isInBrandCommunity)}
        username={socialMediaName}
        isRevoked={youtubeData?.connectionStatus === "Session-Expired"}
        color={youtubeData?.connectionStatus !== "Connected" ? "red" : "blue"}
        lastUpdated={influencerMediaInsightsData?.data?.lastUpdatedDateTime}
        isProfileLocked={Boolean(youtubeData?.isProfileLockedForBrand)}
        soicalMediaKitId={youtubeData?._id || ""}
        handleInvalidate={handleInvalidate}
        accountStatus={youtubeData?.accountStatus || "Connected"}
        isThirdParty={youtubeData?.dataProvider !== "default"}
        isAutoInvite={youtubeData?.isAutoInvite || false}
      />
      {youtubeData && (
        <BasicInfo
          showInsightsButton={false}
          socialMediaId={socialMediaId}
          influencerId={influencerId}
          id={youtubeData?.socialMediaName}
          socialMediaKitId={youtubeData?._id || ""}
          followers={formatData(
            youtubeData?.youtube?.user?.followerCount || 0,
            "a"
          )}
          noOfMedia={formatData(
            youtubeData?.youtube?.user?.contentCount || 0,
            "a"
          )}
          name={youtubeData?.youtube?.user?.name || ""}
          platform="youtube"
          profilePic={youtubeData?.youtube?.user?.profilePicUrl || ""}
          username={youtubeData?.socialMediaName}
          pinnedMetricsYT={
            loadingMetrics || errorLoadingMetrics ? [] : pinnedMetrics
          }
          setPinnedMetricsYT={setPinnedMetrics}
          ytdata={extractData()}
          ytVideoData={getVideoInsightsData(content)}
          content={content}
          dataShow={
            Boolean(influencerId) &&
            !Boolean(youtubeData?.isProfileLockedForBrand) &&
            Boolean(youtubeData?.isInBrandCommunity) &&
            youtubeData?.dataProvider === "default"
          }
          isInBrandCommunity={Boolean(youtubeData?.isInBrandCommunity)}
        />
      )}

      {loadingMetrics || configLoading || insightsLoading ? (
        <Row justify="center" style={{ marginBlock: 20 }}>
          <Lottie
            options={getDefaultLottieOptions(animationData)}
            height={500}
            width={270}
          />
        </Row>
      ) : (
        <>
          {youtubeData?.isInBrandCommunity &&
            !youtubeData?.isProfileLockedForBrand &&
            youtubeData?.dataProvider === "default" && (
              <>
                <Selector selected={content} setSelected={setContent} />
                <VideoInsights
                  content={content}
                  pinnedMetrics={pinnedMetrics}
                  handlePin={handlePin}
                  data={getVideoInsightsData(content)}
                  count={
                    influencerMediaInsightsData?.data?.user?.[content]?.count ||
                    0
                  }
                />
                <BasicInsights
                  content={content}
                  pinnedMetrics={pinnedMetrics}
                  setPinnedMetrics={setPinnedMetrics}
                  data={extractData()}
                />
                <AudienceInsights
                  typeSelected={content}
                  pinnedMetrics={pinnedMetrics}
                  setPinnedMetrics={setPinnedMetrics}
                  socialMediaId={socialMediaId}
                />
              </>
            )}

          {Boolean(
            youtubeData?.isInBrandCommunity &&
              !youtubeData?.isProfileLockedForBrand &&
              youtubeData?.dataProvider !== "default"
          ) && (
            <ThirdParty
              platform="instagram"
              username={youtubeData?.socialMediaName || ""}
            />
          )}
        </>
      )}
    </div>
  );
};

export default YtAnalytics;
