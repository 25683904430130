import { Button } from "antd";
import { createRoot } from "react-dom/client";
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  Fragment,
  createElement,
} from "react";

import type { SearchClient } from "algoliasearch/lite";

import { autocomplete, getAlgoliaFacets } from "@algolia/autocomplete-js";

import { debounce } from "@algolia/autocomplete-shared";

import { useInstantSearch, useSearchBox, usePagination } from 'react-instantsearch';

import {
  INSTANT_SEARCH_SAAS_INDEX_NAME,
  INSTANT_SEARCH_SAAS_FULL_NAME,
} from "../../SaasDiscovery/constants";

import "@algolia/autocomplete-theme-classic";

import FilterChipsAggregate from "./FilterChipsAggregate";

interface SearchProps {
  isDisabled: boolean;
  searchClient: SearchClient;
}

export type FiltersType = Partial<{
  range: {
    [key: string]: string;
  };
  refinementList: {
    [key: string]: string[];
  };
}>;

const Search: React.FC<SearchProps> = ({ isDisabled, searchClient }) => {
  const [query, setQuery] = useState<string>("");
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const panelRootRef = useRef<any>(null);
  const rootRef = useRef<any>(null);
  const [filters, setFilters] = useState<FiltersType>({});

  const { indexUiState, setIndexUiState } = useInstantSearch();

  const { refine: setPage } = usePagination();

  const debouncedSetIndexUiState = debounce(setIndexUiState, 500);

  const plugins = useMemo(() => {
    const profileSuggestionPlugin = {
      getSources() {
        return [
          {
            sourceId: INSTANT_SEARCH_SAAS_INDEX_NAME,
            onActive({ item, setQuery }: { item: any; setQuery: any }) {
              setQuery(item?.label);
            },
            async onSelect({ item, setIsOpen }: { item: any; setIsOpen: any }) {
              setIsOpen(false);

              debouncedSetIndexUiState((prevIndexUiState) => ({
                query: item.label,
                ...prevIndexUiState,
              }));
            },
            getItems({ query }: { query: any }) {
              return getAlgoliaFacets({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_SAAS_INDEX_NAME,
                    facet: INSTANT_SEARCH_SAAS_FULL_NAME,
                    params: {
                      facetQuery: query,
                      maxFacetHits: 5,
                    },
                  },
                ],
              });
            },
            templates: {
              header({ items }: { items: any[] }) {
                if (items.length === 0) {
                  return <Fragment />;
                }
                return (
                  <Fragment>
                    <span className="aa-SourceHeaderTitle">Profile</span>
                    <span className="aa-SourceHeaderLine" />
                  </Fragment>
                );
              },
              item({ item }: { item: any }) {
                return <div>{item?.label}</div>;
              },
            },
          },
        ];
      },
    };

    return [profileSuggestionPlugin];
  }, []);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }
    const autocompleteInstance = autocomplete({
      container: autocompleteContainer.current,
      initialState: { query: indexUiState.query },
      openOnFocus: true,
      placeholder: "Search for influencers",
      detachedMediaQuery: "none",
      plugins: plugins,
      onReset() {
        debouncedSetIndexUiState((prevIndexUiState) => ({
          query: "",
          ...prevIndexUiState,
        }));
      },
      onSubmit({ state }) {
        debouncedSetIndexUiState((prevIndexUiState) => ({
          query: state.query,
          ...prevIndexUiState,
        }));
      },

      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setQuery(state.query);
        }
      },
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    return () => autocompleteInstance.destroy();
  }, [plugins]);

  useEffect(() => {
    if (indexUiState.query && indexUiState.query !== query) {
      setQuery(indexUiState.query);
    }
  }, [indexUiState.query]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    const updatedFilters: typeof filters = {};
    if (indexUiState.refinementList) {
      updatedFilters["refinementList"] = indexUiState.refinementList;
    }
    if (indexUiState.range) {
      updatedFilters["range"] = indexUiState.range;
    }
    setFilters(updatedFilters);
  }, [indexUiState]);

  // to render search box empty  widget from algolia
  useSearchBox();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}>
        <div
          style={{ width: "90%", margin: 6, marginRight: 0, borderRadius: 0 }}
          ref={autocompleteContainer}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              debouncedSetIndexUiState((indexUiState) => ({
                ...indexUiState,
                query,
              }));
            }
          }}
        />
        <Button
          onClick={() => {
            debouncedSetIndexUiState((indexUiState) => ({
              ...indexUiState,
              query,
            }));
          }}
          type="primary"
          style={{
            height: "56px",
            borderRadius: "0 8px 8px 0",
            width: "130px",
          }}>
          Search
        </Button>
      </div>
      <FilterChipsAggregate
        filters={filters}
        setIndexUIState={debouncedSetIndexUiState}
      />
    </>
  );
};

export default Search;
