import React, { useState } from "react";
import { Space, Card, Row, Col } from "antd";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { useQuery } from "react-query";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { BiCaretDown } from "react-icons/bi";

import styles from "./style.module.css";
import postStyles from "../PostInsights/style.module.css";

import ReportButton from "../../Campaign/Report/ReportButton";
import CustomDropDown from "../../shared/Custom/DropDown";

import { TSection } from "..";
import { PinnedMetrics } from "../YtAnalytics";

import { getIgAnalytics } from "../../../handler/Profile";

import BasicMetricInsightsPinned from "./BasicMetricInsights";
import AudienceMetricInsightsPinned from "./AudienceMetricInsights";
import { PinnedMetric } from "../IgAnalytics";
import PinnedMetrices from "./PinnedMetrices";
import VideoMetricInsightsPinned from "./VideoMetricInsights";
import SocialUrlGen from "../../../utils/socialMediaUrlGen";
import { formatData } from "../../../utils";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface BasicInfoProps {
  socialMediaKitId: string;
  dataShow: boolean;
  showInsightsButton: boolean;
  id: string;
  name: string;
  username: string;
  platform: "instagram" | "youtube";
  profilePic: string;
  followers: string;
  noOfMedia: string;
  following?: string;
  bio?: React.ReactNode;
  isTitleVisible?: boolean;
  handleChangeSection?: (section: TSection) => void;
  pinnedMetricsYT?: PinnedMetrics[];
  setPinnedMetricsYT?: React.Dispatch<React.SetStateAction<PinnedMetrics[]>>;
  content?: PinnedMetrics["content"];

  ytdata?: {
    [key: string]: {
      [key: string]: number;
    };
  };
  ytVideoData?: {
    name: string;
    description: string | undefined;
    key: string;
    value: number;
  }[];
  influencerId: string;
  pinnedMetricesIG?: PinnedMetric[];
  setPinnedMetricesIG?: React.Dispatch<React.SetStateAction<PinnedMetric[]>>;
  socialMediaId: string;
  isInBrandCommunity: boolean;
}

const Title: React.FC<{
  id: string;
  platform: BasicInfoProps["platform"];
  handleChangeSection: BasicInfoProps["handleChangeSection"];
  socialMediaKitId: string;
  showInsightsButton: boolean;
}> = (props) => {
  const { platform } = props;
  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          alignItems: "center",
          gap: 20,
        }}
      >
        <span
          style={{
            height: 20,
          }}
        >
          {platform === "instagram" ? (
            <FaInstagram size={20} color="#C13584" />
          ) : (
            <FaYoutube size={20} color="#8A3329" />
          )}
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.80)",
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {platform === "instagram" ? "Instagram" : "Youtube"} Profile
        </span>
      </div>
      {props?.showInsightsButton && (
        <div>
          <ReportButton
            ghost
            style={{
              textDecoration: "underline",
              width: "fit-content",
              fontSize: "16px",
              fontWeight: 500,
            }}
            onClick={() => {
              if (props?.handleChangeSection) {
                props?.handleChangeSection({
                  type: props.platform,
                  id: props.id,
                  socialMediaKitId: props.socialMediaKitId,
                });
              }
            }}
          >
            View Insights
          </ReportButton>
        </div>
      )}
    </section>
  );
};

const BasicInfo: React.FC<BasicInfoProps> = (props) => {
  // YOUTUBE
  const videoMetrics = props.pinnedMetricsYT?.filter((item) => !item.type);
  const basicMetrics = props.pinnedMetricsYT?.filter(
    (item) => item.type === "basic"
  );
  const audienceMetrics = props.pinnedMetricsYT?.filter(
    (item) => item.type === "audience"
  );

  const removePin = (title: string) =>
    props.setPinnedMetricsYT?.((prev) => {
      return prev.filter((item) => !(item.title === title));
    });

  const remove = (title: string, type: "basic" | "audience") => {
    props.setPinnedMetricsYT?.((prev) => {
      return prev.filter(
        (item) =>
          !(
            item.title === title &&
            item.content === props.content &&
            item.type === type
          )
      );
    });
  };

  // INSTAGRAM
  const { pinnedMetricesIG, setPinnedMetricesIG, influencerId, socialMediaId } =
    props;

  // const [getInfluencerCommunityRelationKey, getInfluencerCommunityRelationFn] =
  //   getInfluencerCommunityRelation({ socialMediaId });

  // const { data: relationData } = useQuery(
  //   getInfluencerCommunityRelationKey,
  //   getInfluencerCommunityRelationFn,
  //   { enabled: Boolean(socialMediaId) && props.dataShow }
  // );

  const [breakDownType, setBreakDownType] = useState<"week" | "month">("month");

  const [influencerKey, influencerFn] = getIgAnalytics(socialMediaId || "");

  const { data } = useQuery(influencerKey, influencerFn, {
    enabled:
      Boolean(socialMediaId) &&
      props.dataShow &&
      props.platform === "instagram",
  });

  const getReachData = () => {
    if (breakDownType === "week") {
      if (
        data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isBreakDown &&
        !data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isError &&
        data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          data?.data?.user?.reach_last_7_days_media_product_type_follow_type
            ?.breakDowns?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          // if (optionSelected === "all") {
          if (key.includes("NON_FOLLOWER")) {
            nonFollowers += (value as number) || 0;
          } else {
            followers += (value as number) || 0;
          }
          // }
        });

        return [followers, nonFollowers];
      }
    } else {
      if (
        data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isBreakDown &&
        !data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isError &&
        data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          data?.data?.user?.reach_last_30_days_media_product_type_follow_type
            ?.breakDowns?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          // if (optionSelected === "all") {
          if (key.includes("NON_FOLLOWER")) {
            nonFollowers += (value as number) || 0;
          } else {
            followers += (value as number) || 0;
          }
          // }
        });

        return [followers, nonFollowers];
      }
    }

    return [0, 0];
  };

  return (
    <Card style={{ width: "100%", borderRadius: 10 }}>
      {props.isTitleVisible && (
        <Title
          id={props.id}
          platform={props.platform}
          handleChangeSection={props.handleChangeSection}
          socialMediaKitId={props?.socialMediaKitId}
          showInsightsButton={props?.showInsightsButton}
        />
      )}
      <div className={styles.container}>
        <Space size={20} align="center">
          <img
            src={props.profilePic}
            alt="profile"
            className={styles.profile}
          />
          <div style={{ display: "grid", gap: "10px" }}>
            <p className={styles.name}>{props.name}</p>
            <Space size={5} align="center">
              {props.platform === "instagram" ? (
                <FaInstagram size={20} color="#000000B2" />
              ) : (
                <FaYoutube size={20} color="#000000B2" />
              )}
              <span className={styles.username}>
                <a
                  href={SocialUrlGen({
                    userNameOrChannelId: props.username,
                    platform: props.platform,
                  })}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#000000B2" }}
                >
                  {props.username}
                </a>
              </span>
            </Space>
          </div>
        </Space>
        <Row justify="space-between">
          <div>
            <Space size={40}>
              <p className={styles.metric}>
                {props.followers}{" "}
                <span className={styles.metricLabel}>
                  {props.platform === "instagram" ? "Followers" : "Subscribers"}
                </span>
              </p>
              {!(props.platform === "instagram" && props.dataShow) ? (
                <>
                  <p className={styles.metric}>
                    {props.noOfMedia}{" "}
                    <span className={styles.metricLabel}>
                      {props.platform === "instagram" ? "Posts" : "Videos"}
                    </span>
                  </p>
                  {props?.following && (
                    <p className={styles.metric}>
                      {props.following}{" "}
                      <span className={styles.metricLabel}>Following</span>
                    </p>
                  )}
                </>
              ) : (
                <>
                  {Boolean(
                    data?.data?.user?.all?.calculatedMetrics?.engagement_rate
                  ) && (
                    <p className={styles.metric}>
                      {formatData(
                        data?.data?.user?.all?.calculatedMetrics
                          ?.engagement_rate,
                        "0.00"
                      )}
                      {"% "}
                      <span className={styles.metricLabel}>
                        Engagement Rate
                      </span>
                    </p>
                  )}
                  {Boolean(
                    data?.data?.user?.all?.calculatedMetrics
                      ?.promotional_frequency
                  ) && (
                    <p className={styles.metric}>
                      {formatData(
                        data?.data?.user?.all?.calculatedMetrics
                          ?.promotional_frequency,
                        "0.00"
                      )}
                      {"% "}
                      <span className={styles.metricLabel}>
                        Promotional Frequency
                      </span>
                    </p>
                  )}
                </>
              )}
            </Space>
            {props?.bio && (
              <div style={{ marginTop: 5 }}>
                <p className={styles.bioLabel} style={{ marginBottom: "2px" }}>
                  Bio:
                </p>
                <p className={styles.bio}>{props.bio}</p>
              </div>
            )}
          </div>
          {props.platform === "instagram" && props.dataShow && (
            <Col span={8}>
              <div className={postStyles.reachContainer}>
                <Row justify="space-between" style={{ width: "100%" }}>
                  <p className={postStyles.reach}>Reach-breakdown (All)</p>
                  <CustomDropDown
                    items={[
                      {
                        label: "Week",
                        key: "2",
                        onClick: () => setBreakDownType("week"),
                      },
                      {
                        label: "Month",
                        key: "3",
                        onClick: () => setBreakDownType("month"),
                      },
                    ]}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className={postStyles.optionsContainer}
                    >
                      {breakDownType}
                      <BiCaretDown color="#00000080" size={8} />
                    </a>
                  </CustomDropDown>
                </Row>
                <div style={{ height: "250px" }}>
                  {getReachData().reduce(
                    (prev, current) => prev + current || 0,
                    0
                  ) === 0 ? (
                    <p className="global-no-data">
                      Not enough Data To show this Metric
                    </p>
                  ) : (
                    <Doughnut
                      options={{
                        cutout: "90%",
                        plugins: {
                          legend: {
                            position: "top",
                            labels: {
                              boxHeight: 5,
                            },
                          },
                          datalabels: {
                            anchor: "start",
                            align: "start",
                            formatter: (value) => formatData(value || 0, "a"),
                            font: {
                              size: 16,
                              style: "normal",
                            },
                          },
                        },
                      }}
                      data={{
                        labels: ["Follower", "Non Follower"],

                        datasets: [
                          {
                            label: "No",
                            data: getReachData(),
                            backgroundColor: ["#FF6384", "#FFCD56"],
                            borderColor: ["#FF6384", "#FFCD56"],
                            borderWidth: 1,
                            borderJoinStyle: "round",
                            borderRadius: 10,
                            hoverOffset: 4,
                            offset: 0,
                          },
                        ],
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          )}
        </Row>

        {props?.isInBrandCommunity && props.dataShow && (
          <>
            {props.platform === "youtube" && (
              <div style={{ display: "grid", gap: "10px" }}>
                {props.pinnedMetricsYT && props.pinnedMetricsYT.length > 0 && (
                  <p className={styles.pinned}>Pinned Metrics</p>
                )}
                {videoMetrics && videoMetrics.length > 0 && (
                  <VideoMetricInsightsPinned
                    videoMetrics={videoMetrics}
                    removePin={removePin}
                    ytVideoData={props?.ytVideoData || []}
                  />
                )}
                {basicMetrics && basicMetrics.length > 0 && (
                  <BasicMetricInsightsPinned
                    metrics={basicMetrics}
                    data={props.ytdata || {}}
                    content={props.content || "video"}
                    remove={remove}
                  />
                )}
                {audienceMetrics && audienceMetrics.length > 0 && (
                  <AudienceMetricInsightsPinned
                    metrics={audienceMetrics}
                    data={props.ytdata || {}}
                    content={props.content || "video"}
                    remove={remove}
                  />
                )}
              </div>
            )}
            {props.platform === "instagram" &&
              pinnedMetricesIG &&
              pinnedMetricesIG.length > 0 && (
                <PinnedMetrices
                  influencerId={influencerId}
                  pinnedMetrices={pinnedMetricesIG}
                  setPinnedMetrices={setPinnedMetricesIG}
                />
              )}
          </>
        )}
      </div>
    </Card>
  );
};

export default BasicInfo;
