import React, { useState } from "react";
import { Divider, Space } from "antd";
import clsx from "clsx";

import styles from "./styles.module.css";

import ReportButton from "../../ReportButton";
import GaModal from "./GaModal";

const GaCard: React.FC<{ inReport?: boolean }> = ({ inReport = false }) => {
  const [gaModalVisible, setGaModalVisible] = useState<boolean>(false);

  return (
    <div
      className={clsx(styles.gridContainer, { [styles.reportGrid]: inReport })}
    >
      <p className={styles.title}>Conversion Data</p>
      <Space size={10}>
        <img
          src="/assets/icons/googleAnalytics.svg"
          alt="google"
          width={60}
          height={30}
        />
        <p className={styles.desc}>
          Calculated from Google Analytics
          <br /> by the utm links shared with influencers
        </p>
      </Space>
      {!inReport && <Divider style={{ margin: 0 }} />}
      <div style={{ padding: "10px" }}>
        <p className={styles.titleGrey}>Google Analytics is not connected.</p>
      </div>
      <ReportButton
        ghost
        isOutline
        style={{ width: "15%", justifyContent: "center" }}
        onClick={() => setGaModalVisible(true)}
      >
        Connect GA{" "}
      </ReportButton>
      <GaModal visible={gaModalVisible} setVisible={setGaModalVisible} />
    </div>
  );
};

export default GaCard;
