import React, { useState, useEffect } from "react";
import prependHttp from "prepend-http";
import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  Button,
  Typography,
  Form,
  Radio,
  Row,
  Col,
  List,
  message,
  Space,
  Alert,
  Spin,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { AxiosError } from "axios";

import CreateSteps from "../Steps";
import Footer from "../Footer";
import Navbar from "../../../shared/Navbar";
import ProductModal from "./Modal";

import { capitalizeFirstLetter } from "../../../../utils";
import { isProductDeletable, isProductToggleDisabled } from "../../../../utils";

import {
  deleteProduct,
  addPromDetails,
  transProduct,
  getCampaign,
} from "../../../../handler/campaign";

import { CampaignResponse, Product } from "../../../../typings";

import styles from "../Create.module.css";

const AddProduct = () => {
  let { id } = useParams();
  const [hasProduct, setHasProduct] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);

  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [isEditEnabled, setEditEnabled] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId: id!,
  });
  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFunction);

  useEffect(() => {
    if (campaignData) {
      setHasProduct(campaignData.data.deal.isProduct);
      setProductList(campaignData.data.deal.productDetails);
      setIsLoading(false);

      if (
        campaignData.data.campaign.statusHistory.findIndex(
          (item) => item.status === "Added-Product"
        ) !== -1
      ) {
        setEditEnabled(true);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (isEditEnabled) {
      return setSaveEnabled(false);
    }
    return setSaveEnabled(true);
  }, [isEditEnabled]);

  const deleteProductMutation = useMutation(deleteProduct, {
    onSuccess: (response) => {
      setProductList(response.data.deal.productDetails);
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        () => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const transProductMutation = useMutation(transProduct, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        () => {
          // get single backend data
          return response;
        }
      );
      setEditEnabled(true);
      message.success("Details Updated");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const addPromDetailsMutation = useMutation(addPromDetails, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        () => {
          // get single backend data
          return response;
        }
      );
      transProductMutation.mutate({ campaignId: id!, isProduct: false });
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const [productList, setProductList] = useState<Product[]>([]);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);

  const { Link } = Typography;

  const navigate = useNavigate();

  const handleProductDelete = (productId: string) => {
    deleteProductMutation.mutate({ campaignId: id!, productId });

    // const updatedList = productList!.filter((item) => item.id !== id);
    // setProductList(updatedList);
  };

  // if (!id) {
  //   return null;
  // }
  const handleSubmit = (values?: any) => {
    if (hasProduct === true) {
      transProductMutation.mutate({ campaignId: id!, isProduct: true });
    } else {
      addPromDetailsMutation.mutate({
        campaignId: id!,
        promotionDescription: values.description,
      });
    }
    // handle backend
  };

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }
  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "50px 0" }}>
        <Col span={24}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "40px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.85)",
            }}>
            Create an{" "}
            {capitalizeFirstLetter(campaignData?.data.campaign.platform || "")}{" "}
            Campaign
            <img
              src={`/assets/icons/${campaignData?.data.campaign.platform}Icon.png`}
              alt="instagram"
              style={{ width: "30px", marginLeft: "8px" }}
            />
          </div>

          <p
            style={{
              marginTop: "8px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.45)",
            }}>
            Need assistance? Please watch this{" "}
            <span
              style={{
                color: "#2130FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}>
              video
            </span>
          </p>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "60px",
        }}>
        <Col span={4} offset={2}>
          {campaignData && (
            <CreateSteps
              currentStep={1}
              statusHistory={campaignData?.data.campaign.statusHistory}
            />
          )}
        </Col>
        <Col
          span={9}
          offset={2}
          style={{
            marginBottom: "20px",
            maxHeight: "60vh",
            overflowY: "scroll",
            paddingRight: "10px",
          }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "28px",
              color: "rgba(0, 0, 0, 0.85)",
              marginBottom: "10px",
            }}>
            What you are promoting?
          </p>
          <div
            style={{
              width: "100%",
              padding: "16px 20px",
              background: "#FAFAFA",
              minHeight: "30vh",
            }}>
            <div>
              <div>
                <h1 className={styles.label}>
                  Will the creator need a product to shoot the content?
                </h1>

                <Radio.Group
                  style={{ marginTop: "5px", width: "100%" }}
                  size="middle"
                  defaultValue={Boolean(hasProduct)}
                  onChange={(event) => setHasProduct(event.target.value)}
                  disabled={!isSaveEnabled}>
                  <Radio value={true} className={styles.secondaryLabel}>
                    Yes, there will be a product
                  </Radio>
                  <Radio
                    value={false}
                    disabled={isProductToggleDisabled(
                      campaignData?.data.deal.deliverables!
                    )}
                    className={styles.secondaryLabel}>
                    No, they will need no products
                  </Radio>
                </Radio.Group>
              </div>

              {hasProduct ? (
                <>
                  <Row>
                    <Col span={18}>
                      <p className={styles.desc} style={{ marginTop: "32px" }}>
                        Product Details
                      </p>
                    </Col>
                  </Row>
                  <List
                    style={{ marginTop: "8px", backgroundColor: "#ffffff" }}
                    bordered>
                    {productList &&
                      productList.map((item) => {
                        return (
                          <List.Item
                            key={item._id}
                            actions={[
                              <Button
                                type="link"
                                onClick={() => {
                                  setProduct(item);
                                  setIsEditModalVisible(true);
                                }}
                                disabled={!isSaveEnabled}>
                                Edit
                              </Button>,
                              <Button
                                type="link"
                                onClick={() => handleProductDelete(item._id)}
                                disabled={
                                  isSaveEnabled
                                    ? !isProductDeletable(
                                        item,
                                        campaignData?.data.deal.deliverables ||
                                          []
                                      )
                                    : true
                                }>
                                Delete
                              </Button>,
                            ]}>
                            <List.Item.Meta
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "6px",

                                    alignContent: "center",
                                  }}
                                  className={styles.secondaryLabel}>
                                  {item.productTitle.length > 20
                                    ? item.productTitle.substring(0, 19) +
                                      "...."
                                    : item.productTitle}
                                  <Link
                                    href={prependHttp(item.productLink)}
                                    target="_blank"
                                    style={{
                                      display: "flex",
                                      marginTop: "4px",
                                    }}>
                                    <img
                                      src="/assets/icons/link.svg"
                                      alt="link icon"
                                      style={{
                                        marginLeft: "4px",
                                        width: "14px",
                                      }}
                                    />
                                  </Link>
                                </div>
                              }
                            />
                            {/* content */}

                            <p className={styles.secondaryLabel}>
                              ₹{item.price}
                            </p>
                          </List.Item>
                        );
                      })}
                  </List>
                  <Row justify="center">
                    <Button
                      type="text"
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        display: "flex",
                        marginTop: "12px",
                      }}
                      className={styles.secondaryLabel}
                      onClick={() => setIsAddModalVisible(true)}
                      disabled={!isSaveEnabled}>
                      + Add a product
                    </Button>
                  </Row>
                  <Space>
                    <Button
                      type="primary"
                      size="large"
                      title="Next"
                      style={{ width: "144px", marginTop: "32px" }}
                      htmlType="submit"
                      onClick={handleSubmit}
                      disabled={
                        isSaveEnabled ? !Boolean(productList?.length) : true
                      }
                      loading={transProductMutation.isLoading}>
                      Save
                    </Button>
                    {campaignData?.data.campaign.statusHistory.findIndex(
                      (item) => item.status === "Active"
                    ) === -1 && (
                      <Button
                        type="text"
                        size="large"
                        title="Next"
                        style={{ width: "144px", marginTop: "32px" }}
                        onClick={() => setEditEnabled(false)}
                        disabled={!isEditEnabled}>
                        Edit
                      </Button>
                    )}
                  </Space>

                  <ProductModal
                    type="Add"
                    visible={isAddModalVisible}
                    setIsVisible={setIsAddModalVisible}
                    setProductList={setProductList}
                    setProduct={setProduct}
                    campaignId={id}
                  />
                  {product && (
                    <ProductModal
                      type="Edit"
                      visible={isEditModalVisible}
                      setIsVisible={setIsEditModalVisible}
                      setProductList={setProductList}
                      initialValues={product}
                      setProduct={setProduct}
                      campaignId={id}
                    />
                  )}
                </>
              ) : hasProduct === false ? (
                <div>
                  <p className={styles.desc} style={{ marginTop: "32px" }}>
                    Describe what you are promoting
                  </p>

                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    style={{ marginTop: "8px" }}
                    onFinish={handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    requiredMark={false}
                    // form={form}
                    // {...formItemLayout}
                  >
                    <Form.Item
                      className={styles.label}
                      name="description"
                      initialValue={
                        campaignData?.data.deal.promotionDescription
                      }
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter product brief!",
                        },
                      ]}>
                      <TextArea
                        rows={5}
                        showCount={{
                          formatter: (args) =>
                            `${args.count}/${args.maxLength}`,
                        }}
                        maxLength={100}
                        size="small"
                        disabled={!isSaveEnabled}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Space>
                        <Button
                          type="primary"
                          size="large"
                          title="Next"
                          style={{ width: "144px", marginTop: "32px" }}
                          htmlType="submit"
                          loading={
                            transProductMutation.isLoading ||
                            addPromDetailsMutation.isLoading
                          }
                          disabled={!isSaveEnabled}>
                          Save
                        </Button>

                        <Button
                          type="text"
                          size="large"
                          title="Next"
                          style={{ width: "144px", marginTop: "32px" }}
                          onClick={() => setEditEnabled(false)}
                          disabled={!isEditEnabled}>
                          Edit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Col>
        <Col
          style={{ overflowY: "auto", maxHeight: "47vh" }}
          offset={1}
          span={5}>
          {campaignData?.data.campaign.status === "Needs-Attention" && (
            <Alert type="error" message={campaignData?.data.campaign.remarks} />
          )}
        </Col>
      </Row>
      <Footer
        onNext={() => {
          navigate(`/campaign/create/${id}/add-deliverables`);
        }}
        onBack={() => {
          navigate(`/campaign/create/${id}`);
        }}
        isNextDisabled={
          !campaignData
            ? true
            : isSaveEnabled
            ? true
            : campaignData.data.campaign.statusHistory.findIndex(
                (item) => item.status === "Added-Product"
              ) === -1
        }
      />
    </div>
  );
};

export default AddProduct;

// campaignData.data.campaign.statusHistory.findIndex(
//   (item) => item.status === "Added-Product"
// ) === -1;
