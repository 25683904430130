import { sendRequest } from "../helper";

import { PriceResult } from "../../typings/Discovery";

type InvitePriceResult = {
  status: string | null;
  message: string | null;
  data: PriceResult;
};

export const getInvitePrice = ({
  dealId,
  socialMediaKitId,
  campaignId,
}: {
  dealId: string;
  socialMediaKitId: string;
  campaignId: string;
}) => {
  const queryKey = ["Invite-price", dealId, socialMediaKitId, campaignId];
  const queryFunction = () =>
    sendRequest<InvitePriceResult>(
      "get",
      `/brand/campaign/offer/invite-price?dealId=${dealId}&socialMediaKitId=${socialMediaKitId}&campaignId=${campaignId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
