import React, { useState } from "react";

import {
  message,
  Modal,
  Row,
  Col,
  Input,
  Spin,
  Space,
  Checkbox,
  Select,
  Divider,
} from "antd";

import { SingularRule, MessageReply, Catalogue } from "../../../typings";

import { useQuery } from "react-query";

import CustomInput from "../../shared/Custom/Input";
import { getProductCatalogue } from "../../../handler/replies";
import CommentTags from "./CommentTags";
import ReportButton from "../../Campaign/Report/ReportButton";
import AddMessageModal from "./AddMessage";
import AddCatalogue from "./AddCatalogue";

import { trimData } from "../../../utils";

interface ruleProps {
  data: SingularRule;
  errData?: SingularRule;
  isDisabled?: boolean;
  handleChange: (rule: SingularRule) => void;
}

const Rule: React.FC<ruleProps> = ({
  data,
  handleChange,
  errData,
  isDisabled = false,
}) => {
  const [getProductCatalogueKey, getProductCatalogueFn] = getProductCatalogue();

  const { data: pcData, isLoading: pcDataLoading } = useQuery(
    getProductCatalogueKey,
    getProductCatalogueFn
  );

  const selectedInput = pcData?.data?.find(
    (item) =>
      item.type === data?.attachedType &&
      item?.productCatalogue?._id === data?.attachedProductCatalogue
  );

  const [addMessageModal, setAddMessageModal] = useState<{
    visible: boolean;
    messageV: MessageReply | undefined;
  }>({ visible: false, messageV: undefined });
  const [addCatalogueModal, setAddCatalogueModal] = useState<{
    visible: boolean;
    catalogue?: Catalogue;
  }>({ visible: false, catalogue: undefined });

  return (
    <div style={{ display: "grid", gap: "8px" }}>
      <CustomInput
        label="keywords"
        subLabel="Add keywords you want to add automation On"
        error={errData?.triggeredOnTextContains?.at(0)}
        children={
          <CommentTags
            tags={data?.triggeredOnTextContains || []}
            setTags={(items) => {
              handleChange({ ...data, triggeredOnTextContains: items });
            }}
            isDisabled={isDisabled}
          />
        }
      />

      <CustomInput
        label="Enable Comment Reply Back"
        children={
          <Checkbox
            checked={data?.toCommentBack}
            disabled={isDisabled}
            onChange={(e) => {
              handleChange({ ...data, toCommentBack: e.target.checked });
            }}
          />
        }
      />
      {data?.toCommentBack && (
        <CustomInput
          label="Comment to Reply Back"
          error={errData?.commentToReplyBack}
          children={
            <Input
              disabled={isDisabled}
              value={data.commentToReplyBack}
              onChange={(e) => {
                handleChange({
                  ...data,
                  commentToReplyBack: e.target.value || "",
                });
              }}
              size="large"
            />
          }
        />
      )}

      <CustomInput
        label="Select the message/catalogue that you want to send  "
        error={errData?.attachedProductCatalogue}
        children={
          <>
            <Select
              showSearch
              placeholder="Select a message/catalogue"
              optionFilterProp="children"
              size="large"
              disabled={isDisabled}
              notFoundContent={
                pcDataLoading ? (
                  <Row justify="center">
                    <Spin />
                  </Row>
                ) : (
                  <>
                    <h4>You need to create a message before making a rule </h4>
                    <Row justify="center">
                      <ReportButton
                        type="primary"
                        onClick={() =>
                          setAddMessageModal({
                            visible: true,
                            messageV: undefined,
                          })
                        }
                        disabled={isDisabled}>
                        Create a Message
                      </ReportButton>
                    </Row>
                  </>
                )
              }
              //   searchValue={searchQuery}
              onChange={(val: string, _) => {
                const [f1, f2] = val.split("*");
                handleChange({
                  ...data,
                  attachedProductCatalogue: f1,
                  attachedType: f2,
                });
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={pcData?.data?.map((item) => ({
                label:
                  item?.type === "catalogue"
                    ? item?.productCatalogue?.name
                    : item?.productCatalogue?.title,
                value: item?.productCatalogue?._id + "*" + item?.type,
              }))}
            />

            {selectedInput && (
              <div style={{ padding: 5 }}>
                <Divider style={{ marginBlock: "5px" }} />
                <Row justify="space-between">
                  <p>
                    {trimData({
                      value:
                        selectedInput?.type === "product"
                          ? selectedInput?.productCatalogue?.title
                          : selectedInput?.productCatalogue?.name,
                      limit: 30,
                    })}
                  </p>
                  <ReportButton
                    type="link"
                    onClick={() => {
                      if (selectedInput?.type === "product") {
                        return setAddMessageModal({
                          visible: true,
                          messageV: selectedInput?.productCatalogue,
                        });
                      }
                      return setAddCatalogueModal({
                        visible: true,
                        catalogue: selectedInput?.productCatalogue,
                      });
                    }}>
                    View
                  </ReportButton>
                </Row>
              </div>
            )}
          </>
        }
      />

      <AddMessageModal
        isVisible={addMessageModal.visible}
        handleClose={() =>
          setAddMessageModal({ visible: false, messageV: undefined })
        }
        initialData={addMessageModal.messageV}
        isView={Boolean(addMessageModal.messageV)}
      />
      <AddCatalogue
        isVisible={addCatalogueModal.visible}
        handleClose={() =>
          setAddCatalogueModal({ visible: false, catalogue: undefined })
        }
        initialData={addCatalogueModal?.catalogue}
        isView={Boolean(addCatalogueModal?.catalogue)}
      />
    </div>
  );
};

export default Rule;
