import { Button } from "antd";
import { createRoot } from "react-dom/client";
import { Space, Input } from "antd";
import { HiMagnifyingGlass } from "react-icons/hi2";
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  Fragment,
  createElement,
} from "react";

import ReportButton from "../../../ReportButton";

import styles from "./styles.module.css";

interface SearchProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

export type FiltersType = Partial<{
  range: {
    [key: string]: string;
  };
  refinementList: {
    [key: string]: string[];
  };
}>;

const Search: React.FC<SearchProps> = ({ searchQuery, setSearchQuery }) => {
  return (
    <>
      <section className={styles.inputContainer} style={{ width: "80%" }}>
        <Space size={2} align="center" style={{ width: "100%" }}>
          <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value || "")}
            onPressEnter={() => setSearchQuery(searchQuery)}
            placeholder={"Search by name or username"}
            className={styles.input}
            size="large"
            style={{
              borderColor: "transparent",
              boxShadow: "none",
              width: "100%",
              minWidth: "400px",
            }}
          />
        </Space>
        <ReportButton
          type="primary"
          onClick={() => setSearchQuery(searchQuery)}
          style={{ padding: "10px 20px " }}>
          Search
        </ReportButton>
      </section>
    </>
  );
};

export default Search;
