import { sendRequest } from "../helper";

import {
  ChatCard,
  DraftCard,
  LiveCard,
  RequirementsCard,
} from "../../typings/Chat";

type ChatCardResult = {
  status: string;
  message: string;
  data: ChatCard | RequirementsCard | DraftCard | LiveCard;
};

export const getChatCardData = ({
  ref,
  messageId,
  type,
}: {
  ref?: {
    offerId?: string;
    requirementId?: string;
    workflowId?: string;
    campaignId?: string;
    deliverableId?: string;
  };
  messageId: string;
  type: string;
}) => {
  const queryKey = ["chat", type, ref, messageId];
  const queryFunction = () =>
    sendRequest<ChatCardResult>("post", `/brand/chat/cards`, false, {
      type,
      ref,
    }).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
