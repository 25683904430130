import { sendRequest } from "../helper";
import { CampaignReport } from "../../typings";

export type createCampaignProps = {
  campaignName: string;
  platform: string;
};

type createCampaignResult = {
  message: string;
  data: CampaignReport;
};

export const createCampaign = (props: createCampaignProps) => {
  return sendRequest<createCampaignResult>(
    "post",
    `/brand/report-only/campaign`,
    false,
    { ...props }
  ).then((res) => res.data);
};
