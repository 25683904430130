import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { FiPlus, FiMinus } from "react-icons/fi";
import { ConfigTypeSingle } from "../../../typings/CreatorSearch";
import { getFacetValues } from "../../../handler/insightIq";
import { Spin, Typography, Select, Space } from "antd";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import HelpPopOver from "./HelpPopover";
import { useDebounce } from "use-debounce";

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}

const { Title } = Typography;

const InputList: React.FC<Props> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  maxCount,
  isOnSearch = false,
}) => {
  const { searchParams, setSearchParams, platformId } =
    useCreatorSearchContext();
  const [visible, setVisible] = useState<boolean>(isOnSearch);
  const [query, setQuery] = useState<{ query: string }>({ query: "" });
  const [debouncedQuery] = useDebounce(query.query, 300);

  const [getFacetKey, getFaceValue] = getFacetValues({
    facetName: `${facetName}`,
    body: {},
    query: debouncedQuery,
    work_platform_id: platformId,
  });

  const { data, isLoading } = useQuery(getFacetKey, getFaceValue, {
    enabled: facetName === "topics" ? debouncedQuery.length > 2 : visible,
  });

  useEffect(() => {
    if (!visible) {
      setQuery({ query: "" });
    }
  }, [visible]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  let options = (data?.data || []).map((d) => ({
    value: d.value,
    label: d.name,
  }));
  let optionKeys = options?.map((item) => item.value);

  if (type !== "tags") {
    if (Array.isArray(searchParams[facetName])) {
      searchParams[facetName]?.forEach((sItem: any, idx: number) => {
        if (!optionKeys?.includes(sItem)) {
          options = [
            ...options,
            {
              value: sItem,
              label: searchParams["filterTags"][facetName][idx],
            },
          ];
        }
      });
    } else {
      if (
        searchParams[facetName] &&
        !optionKeys?.includes(searchParams[facetName])
      ) {
        options = [
          ...options,
          {
            value: searchParams[facetName],
            label: searchParams[facetName],
          },
        ];
      }
    }
  }

  return (
    <div style={{ display: "grid", width: !isOnSearch ? "228px" : undefined }}>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <Select
          showSearch={isSearchable}
          size={!isOnSearch ? undefined : "large"}
          placeholder={placeHolder}
          optionFilterProp="children"
          maxTagCount={"responsive"}
          mode={type ? (type as "multiple" | "tags") : undefined}
          onSearch={(val) => setQuery((queryD) => ({ ...queryD, query: val }))}
          searchValue={query.query}
          onChange={(value, val) => {
            let newFacetObject = {
              ...searchParams,
            };
            setQuery((queryD) => ({ ...queryD, query: "" }));

            if (facetName == "creator_lookalikes") {
              newFacetObject = {
                ...newFacetObject,
                [facetName]: value[0],
                filterTags: {
                  ...newFacetObject["filterTags"],
                  [facetName]: value[0],
                },
              };

              return setSearchParams((searchParams) => ({
                ...searchParams,
                ...newFacetObject,
              }));
            }
            if (type === "tags") {
              newFacetObject = {
                ...newFacetObject,
                [facetName]: value,
                filterTags: {
                  ...newFacetObject["filterTags"],
                  [facetName]: value,
                },
              };

              return setSearchParams((searchParams) => ({
                ...searchParams,
                ...newFacetObject,
              }));
            }

            if (Array.isArray(val)) {
              newFacetObject = {
                ...newFacetObject,
                [facetName]: val?.map((item) => item.value),
                filterTags: {
                  ...newFacetObject["filterTags"],
                  [facetName]: val?.map((item) => item.label),
                },
              };
            } else {
              newFacetObject = {
                ...newFacetObject,
                [facetName]: val?.value,
                filterTags: {
                  ...newFacetObject["filterTags"],
                  [facetName]: val?.label,
                },
              };
            }
            setSearchParams((searchParams) => ({
              ...searchParams,
              ...newFacetObject,
            }));
          }}
          notFoundContent={isLoading ? <Spin size="small" /> : undefined}
          filterOption={filterOption}
          options={options}
          value={searchParams[facetName]}
        />
      )}
    </div>
  );
};

export default InputList;
