import React from "react";
import { ConfigTypeSingle } from "../../../typings/CreatorSearch";
import { useState, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

import { Typography, Space, Checkbox } from "antd";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import HelpPopOver from "./HelpPopover";

const { Paragraph, Title, Text } = Typography;

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}
const CustomCheckBox: React.FC<Props> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);

  const { searchParams, setSearchParams } = useCreatorSearchContext();

  const isChecked = Boolean(searchParams[facetName] || false);

  return (
    <div>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <div>
          <Checkbox
            checked={isChecked}
            onChange={(e) => {
              setSearchParams((searchParams) => ({
                ...searchParams,
                [facetName]: e.target.checked || undefined,
                filterTags: {
                  ...searchParams?.filterTags,
                  [facetName]: e.target.checked || undefined,
                },
              }));
            }}
          >
            {facetName === "is_verified"
              ? "Only show verified accounts"
              : "Only show accounts with sponsored posts"}
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default CustomCheckBox;
