import React, { useState } from "react";
import { Row, Col, Input, Form, Button } from "antd";
// import clsx from "clsx";
// import { useNavigate } from "react-router-dom";
import CountryPhoneCode, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import { useUserAuth } from "../../../context/userAuthcontext";

import { sendOTP } from "../../../handler/sendOTP";
import { verifyOTP } from "../../../handler/verifyOTP";
import { useNavigate } from "react-router-dom";
import styles from "../Onboarding.module.css";

import ProgressBar from "../../shared/ProgressBar";

const PhoneUpdate: React.FC = () => {
  // let navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState<number>(91);
  const [number, setNumber] = useState("");
  const { currentUser, setStatus, updateUsername, status } = useUserAuth();
  const [name, setName] = useState(
    currentUser?.displayName ? currentUser.displayName : ""
  );
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  if (status === "registered") {
    navigate("/", { replace: true });
    return null;
  }

  // useEffect(() => {
  //   if (!currentUser?.emailVerified) {
  //     navigate("/verifyMail");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser?.emailVerified]);

  const handlePhoneUpdate = (value: any) => {
    if (!isNaN(value.phone)) {
      setCode(value.code);
      setNumber(value.phone);
    }
  };
  const handlePhoneSubmit = () => {
    setLoading(true);

    const mobile = `+${code}${number}`;
    if (mobile.trim() !== "+" && !disabled && name.trim().length) {
      setDisabled(true);
      sendOTP({ phoneNumber: mobile });
      Promise.all([sendOTP({ phoneNumber: mobile }), updateUsername(name)])
        .then((res) => {
          setStep(2);
        })
        .finally(() => {
          setTimeout(() => setDisabled(false), 30000);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleOTPSubmit = () => {
    if (OTP.trim() !== "") {
      setDisabled(true);
      setLoading(true);
      verifyOTP({ OTP })
        .then((res) => {
          setStatus("organizationDetails");
        })
        .finally(() => {
          setDisabled(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setErr("Invalid OTP");
        });
    }
  };

  if (!currentUser) {
    return null;
  }
  return (
    <>
      <ProgressBar size={50} />
      <div className={styles.container}>
        <Row justify="center">
          <Col sm={10} xs={22} md={8}>
            <h1 className={styles.modalTitle}>Tell us more about you.</h1>
            {step === 1 ? (
              <PhoneInput
                handlePhoneUpdate={handlePhoneUpdate}
                handleSubmit={handlePhoneSubmit}
                code={code}
                number={number}
                name={name}
                setName={setName}
                loading={loading}
              />
            ) : (
              <OTPInput
                phone={`+${code}${number}`}
                handleOTPUpdate={(value) => setOTP(value)}
                goBack={() => {
                  setDisabled(false);
                  setStep(1);
                }}
                handleSubmit={handleOTPSubmit}
                OTP={OTP}
                resendOTP={handlePhoneSubmit}
                disabled={disabled}
                err={err}
                setErr={setErr}
                loading={loading}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

interface PhoneInputProps {
  handlePhoneUpdate: (value: any) => void;
  handleSubmit: () => void;
  number: string;
  code: number;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  handlePhoneUpdate,
  handleSubmit,
  code,
  number,
  setName,
  name,
  loading,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    handleSubmit();
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
      requiredMark={false}
      form={form}>
      <Form.Item
        label={<p className={styles.inputLabel}>Enter your name</p>}
        // className={styles.inputLabel}
        name="name"
        rules={[{ required: true, message: "Please input your Name" }]}
        initialValue={name}>
        <Input
          placeholder="e.g. Stefan Salvatore"
          className={styles.input}
          onChange={(event) => setName(event.target.value)}
          size="large"
        />
      </Form.Item>

      <Form.Item
        label={<p className={styles.inputLabel}>Enter your mobile number</p>}
        // className={styles.inputLabel}
        name="number"
        initialValue={{ code, phone: number }}>
        <ConfigProvider locale={en}>
          <CountryPhoneCode
            placeholder="eg.123456798"
            style={{ marginBottom: "32px" }}
            value={{ code, phone: number }}
            onChange={(value) => handlePhoneUpdate(value)}
            maxLength={10}
          />
        </ConfigProvider>
      </Form.Item>
      {/* <p className={styles.inputLabel}>Enter your mobile number</p> */}
      {/* <ConfigProvider locale={en}>
        <CountryPhoneCode
          placeholder="eg.123456798"
          style={{ marginBottom: "32px" }}
          value={{ code, phone: number }}
          onChange={(value) => handlePhoneUpdate(value)}
          maxLength={10}
        />
      </ConfigProvider> */}

      <Form.Item>
        <Button
          className={styles.submitButton}
          block
          size="large"
          htmlType="submit"
          loading={loading}
          type="primary">
          Continue to OTP Verification
        </Button>
      </Form.Item>
    </Form>
  );
};

interface OTPInputProps {
  phone: string;
  handleOTPUpdate: (value: string) => void;
  handleSubmit: () => void;
  goBack: () => void;
  OTP: string;
  resendOTP: () => void;
  disabled: boolean;
  err: string;
  setErr: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({
  phone,
  handleOTPUpdate,
  handleSubmit,
  goBack,
  OTP,
  resendOTP,
  disabled,
  err,
  setErr,
  loading,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleOTPUpdate(event.target.value);
    if (event.target.value.trim() === "") {
      setErr("OTP can't be empty");
    } else {
      setErr("");
    }
  };

  return (
    <section>
      <p style={{ margin: 0 }}>
        A text with a One Time Password (OTP) has been sent to your mobile
        number: {phone}
      </p>
      <span onClick={goBack} className={styles.resend}>
        Change
      </span>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
        className={styles.inputLabel}>
        <p style={{ margin: 0 }}>Enter OTP</p>

        {disabled ? (
          <span className={styles.disabled}>Send again</span>
        ) : (
          <span onClick={resendOTP} className={styles.resend}>
            Send again
          </span>
        )}
      </div>

      <Input
        style={{ width: "100%", margin: "8px 0 4px 0" }}
        value={OTP}
        onChange={handleInputChange}
        size="large"
      />
      {err && <p className="ant-form-item-explain-error">{err}</p>}

      {/* <Input
        style={{ width: "100%", margin: "8px 0 32px 0" }}
        value={OTP}
        onChange={handleInputChange}
      />
       */}

      <Button
        className={styles.submitButton}
        block
        size="large"
        htmlType="submit"
        loading={loading}
        style={{ marginTop: "32px" }}
        onClick={handleSubmit}
        type="primary">
        Continue to Organisation Details
      </Button>
    </section>
  );
};

export default PhoneUpdate;
