import { sendRequest } from "../../helper";

export type AddInfluencerToCampaignResult = {
  status: string;
  message: string;
  data: any;
};

type Breakdown = {
  type: string;
  price: number;
};

type Price = {
  isBreakdown: boolean;
  breakdown: Breakdown[];
  totalPrice: number;
  currency: string;
};

export type AddInfluencerToCampaignProps = {
  campaignId: string;
  username: string;
  platform: string;
  commercials?: any;
  brandFinalNegotiatedPrice: Price;
  influencerFinalNegotiatedPrice: Price;
  internalNotes?: boolean;
  notes?: string;
  paymentTerms?: string;
  deliverables: string;
  isMediaRights: boolean;
  mediaRights?: string;
  specialFields?: {
    key: string;
    value: string;
  }[];
};

export const addInfluencerToCampaign = (
  props: AddInfluencerToCampaignProps
) => {
  console.log(props);
  return sendRequest<AddInfluencerToCampaignResult>(
    "post",
    "/campaign/influencer",
    false,
    {
      campaignId: props.campaignId,
      username: props.username,
      platform: props.platform,
      commercials: props.commercials,
      brandFinalNegotiatedPrice: props.brandFinalNegotiatedPrice,
      influencerFinalNegotiatedPrice: props.influencerFinalNegotiatedPrice,
      internalNotes: props.internalNotes,
      notes: props.notes,
      paymentTerms: props.paymentTerms,
      deliverables: props.deliverables,
      isMediaRights: props.isMediaRights,
      mediaRights: props.mediaRights,
      specialFields: props.specialFields,
    }
  ).then((res) => res.data);
};
