import React from "react";
import { Badge } from "antd";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { format } from "date-fns";

import { Notification as NotificationData } from "../../../../../typings/Notification";

import styles from "../../../../shared/Navbar/Notification/notification.module.css";

import { getIcon, getNavigateUrl } from "../utils";

interface NotificationItemProps {
  notification: NotificationData;
  changeRead: (isRead: boolean, id: string) => {};
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  changeRead,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.notification} key={notification.id}>
      <Badge
        dot={!notification.isRead ? true : false}
        style={{ left: "-20px", top: "10px" }}
        color="blue">
        <div className={styles.details} style={{ width: "220px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeRead(false, notification.id);
              navigate(getNavigateUrl(notification.type, notification.ref));
            }}
            className="">
            {parse(notification.msg)}
          </div>

          <div className={styles.extraOptions}>
            <span>
              {format(
                new Date(notification.createdDateTime.toDate() as string),
                "LLL dd, yyyy "
              )}
              at
              {format(
                new Date(notification.createdDateTime.toDate() as string),
                " hh:mm a"
              )}
            </span>
          </div>
        </div>
      </Badge>
      <img
        className={styles.profile}
        src={
          getIcon(notification.type) === ""
            ? `${notification.logo}`
            : `${getIcon(notification.type)}`
        }
        alt=""
      />
    </div>
  );
};

export default NotificationItem;
