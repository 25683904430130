import React, { useState } from "react";
import { Button, Pagination, Row, Spin } from "antd";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { format } from "date-fns";

import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { useQuery } from "react-query";

import {
  getInvitedInfluencer,
  getInvitedInfluencerCount,
} from "../../handler/invite/";

import styles from "./invitedInfluencers.module.css";

import Navbar from "../shared/Navbar";

const InvitedInfluencers = (): React.ReactElement => {
  const [page, setPage] = useState<number>(1);

  const limit = 5;

  const [getinfluencerKey, getInfluencerFn] = getInvitedInfluencer({
    pageNo: page,
    limit,
  });
  const [getinfluencerCountKey, getInfluencerCountFn] =
    getInvitedInfluencerCount({ inviteBy: "" });

  const { data: influencerData } = useQuery(getinfluencerKey, getInfluencerFn, {
    keepPreviousData: true,
  });
  const { data: influencerCountData, isLoading: isInfluencerCountDataLoading } =
    useQuery(getinfluencerCountKey, getInfluencerCountFn);

  const navigate = useNavigate();

  const getChipColor = (status: string) => {
    let color = "";
    switch (status) {
      case "Success":
        color = "#40c057";
        break;
      case "Pending":
        color = "#db9b04";
        break;
      default:
        color = "#333";
    }
    return color;
  };

  return (
    <main className={styles.main}>
      <Navbar selectedItem="invite" />
      <header className={styles.header}>
        <Button
          onClick={() => navigate("/invite")}
          size={"large"}
          style={{ color: "#000000" }}
          type="link"
          icon={<BiArrowBack style={{ width: "24px", height: "24px" }} />}
        />
        <h1 className={styles.header__title}>Invited Influencers</h1>
      </header>
      <div className={styles.container}>
        <section className={styles.tableHeader}>
          <div className={styles.tableItem}>Creator</div>
          <div className={styles.tableItem} style={{ textAlign: "center" }}>
            Email/Number
          </div>
          <div className={styles.tableItem} style={{ textAlign: "center" }}>
            Social Media
          </div>
          <div className={styles.tableItem} style={{ textAlign: "center" }}>
            Request Sent On
          </div>
          <div className={styles.tableItem} style={{ textAlign: "center" }}>
            Status
          </div>
          <div className={styles.tableItem}></div>
        </section>
        <div
          style={{
            minHeight: "300px",
          }}>
          {influencerData && (
            <div style={{ display: "grid", gap: "4px" }}>
              {influencerData.data.map((item, idx) => {
                return (
                  <div key={idx} className={styles.tableRowData}>
                    <p>
                      {/* <FaUserCircle style={{ width: "24px", height: "24px" }} /> */}
                      {item.full_name}
                    </p>
                    <p>
                      {item.emailId} {item?.emailId ? <br /> : null}
                      {item?.phoneNumber}
                    </p>
                    <p
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      {item.platform === "youtube" && <AiFillYoutube />}
                      {item.platform === "instagram" && <AiOutlineInstagram />}
                      {item.username}
                    </p>

                    <p>
                      {format(new Date(item.createdDateTime), "dd MMMM, yyyy")}
                    </p>
                    <p
                      className={styles.tableStatus}
                      style={{
                        borderColor: getChipColor(item.status),
                        color: getChipColor(item.status),
                        backgroundColor: `${getChipColor(item.status) + "25"}`,
                      }}>
                      {item.status === "Pending"
                        ? "Getting-Onboarded"
                        : "Onboarded"}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {isInfluencerCountDataLoading && (
            <Row
              justify="center"
              align="middle"
              style={{ paddingTop: "100px" }}>
              <Spin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
              />
            </Row>
          )}
        </div>
      </div>
      <Row justify="center">
        <Pagination
          // defaultCurrent={page}
          current={page}
          total={influencerCountData?.data?.count}
          style={{ margin: "46px auto" }}
          onChange={(page, _) => setPage(page)}
          pageSize={limit}
          showSizeChanger={false}
        />
      </Row>
    </main>
  );
};
export default InvitedInfluencers;
