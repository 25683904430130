import React, { useRef, useEffect, useState } from "react";
import Lottie from "react-lottie";
import {
  Row,
  Col,
  Space,
  Button,
  Spin,
  Table,
  Divider,
  Tag,
  Tooltip,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import animationData from "../../assets/lottie/Rocket.json";
import { useQueryClient } from "react-query";

import { useInfiniteQuery } from "react-query";

import { getRules } from "../../../handler/replies";

import { formatData, getDefaultLottieOptions, trimData } from "../../../utils";

import { Rules as RuleType } from "../../../typings";
import { ColumnsType } from "antd/es/table";

import AddRule from "../Modal/AddRule";

import { format } from "date-fns";

import styles from "../Messages/messages.module.css";

const Rules = () => {
  const [rulesKey, rulesFn] = getRules({ limit: 100 });
  const queryClient = useQueryClient();

  const [ruleModalProps, setRuleModalProps] = useState<{
    isView: boolean;
    isEdit: boolean;
    visible: boolean;
    ruleId?: string;
  }>({
    isEdit: false,
    isView: false,
    visible: false,
    ruleId: undefined,
  });

  const firstRender = useRef(true);

  const [loading, setLoading] = useState<boolean>(true);

  const {
    data: messagesData,
    isLoading: messagesDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(rulesKey, rulesFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.rules.length ? lastPage.data.pageNo + 1 : null,
  });

  useEffect(() => {
    if (!messagesDataLoading && firstRender.current) {
      firstRender.current = false;
      setLoading(false);
    }
  }, [messagesDataLoading]);

  if (loading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  const columns: ColumnsType<RuleType> = [
    {
      title: "Rule Name",
      // dataIndex: "message",
      key: "message",

      render: (_, { name }) => (
        <Space size={10} align="center">
          <p
            className={styles.name}
            // style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {trimData({ value: name || "", limit: 50 })}
          </p>
        </Space>
      ),
    },

    {
      title: "Rules Length",
      // dataIndex: "followers",
      key: "uActions",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { rules }) => (
        <p className={styles.followers}>{formatData((rules || [])?.length)}</p>
      ),
    },
    {
      title: "Created on On",
      // dataIndex: "joiningDate",
      key: "joiningDate",

      render: (_, { createdDateTime }) => (
        <p className={styles.followers}>
          {createdDateTime && (
            <>{format(new Date(createdDateTime), "d MMM, RRRR")}</>
          )}
        </p>
      ),
    },

    {
      title: "Keywords",
      key: "keywords",
      render: (_, record) => (
        <Space size={5} wrap>
          {record?.rules
            ?.flatMap((rItem) => rItem?.triggeredOnTextContains)
            ?.map((item) => {
              const tagItem = item || "";

              const isLongTag = (tagItem || "").length > 20;

              const tagElem = (
                <Tag className={styles.editTag} key={tagItem} closable={false}>
                  {isLongTag ? `${tagItem.slice(0, 20)}...` : tagItem}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tagItem} key={tagItem}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
        </Space>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "followers",
      key: "Actions",
      width: "300px",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, record) => (
        <Space size={10} wrap>
          <Button
            key={"View"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setRuleModalProps({
                isEdit: false,
                isView: true,
                visible: true,
                ruleId: record?._id,
              })
            }>
            View
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setRuleModalProps({
                isEdit: true,
                isView: false,
                visible: true,
                ruleId: record?._id,
              })
            }>
            Edit
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button key={"Delete"} style={{ borderRadius: 8 }} danger type="link">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div
      className={styles.g10}
      style={{
        height: "70vh",
        overflowY: "scroll",
      }}>
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        className={styles.g10}
        style={{ alignItems: "flex-start" }}
        hasMore={hasNextPage}
        loader={
          <Row justify="center">
            <Spin />
          </Row>
        }
        useWindow={false}>
        {messagesDataLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <Table
            columns={columns}
            dataSource={messagesData?.pages?.flatMap(
              (pageItem) => pageItem.data?.rules || []
            )}
            scroll={{ y: 700 }}
            pagination={false}
          />
        )}
        {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
      pageItem.data?.community?.map((dataItem) => (
        <CreatorCard creatorData={dataItem} platform={platform} />
      ))
    )} */}
      </InfiniteScroll>
      <AddRule
        isVisible={ruleModalProps.visible}
        isView={ruleModalProps.isView}
        isEdit={ruleModalProps.isEdit}
        ruleId={ruleModalProps.ruleId}
        handleClose={() => {
          setRuleModalProps({
            isEdit: false,
            isView: false,
            visible: false,
            ruleId: undefined,
          });
        }}
      />
    </div>
  );
};

export default Rules;
