import React from "react";
import { Popover } from "antd";

import styles from "./metric.module.css";

import { LuPin, LuPinOff } from "react-icons/lu";

interface MetricCardProps {
  title: string;
  desc: string;
  metric: string;
  handlePin: (title: string) => void;
  isPinned: boolean;
  disablePin?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  desc,
  metric,
  handlePin,
  isPinned,
  disablePin = false,
}) => {
  return (
    <div className={styles.container}>
      {title.length > 16 ? (
        <Popover
          content={title}
          title="Information"
          trigger="hover"
          placement="top"
          arrowPointAtCenter>
          <p className={styles.label}>{title.slice(0, 16)}...</p>
          {/* <p className={styles.desc}>{desc.slice(0, 25)}...</p> */}
        </Popover>
      ) : (
        <p className={styles.label}>{title}</p>
      )}
      {/* <p className={styles.label}>{title}</p> */}

      {desc.length > 25 ? (
        <Popover
          content={desc}
          title="Information"
          trigger="hover"
          placement="top"
          arrowPointAtCenter>
          <p className={styles.desc}>{desc.slice(0, 25)}...</p>
        </Popover>
      ) : (
        <p className={styles.desc}>{desc}</p>
      )}

      <p className={styles.metric}>{metric}</p>
      {!disablePin && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => handlePin(title)}>
          {isPinned ? (
            <LuPin size={10} color={"#00000066"} />
          ) : (
            <LuPinOff size={10} color={"#00000066"} />
          )}
        </div>
      )}
    </div>
  );
};

export default MetricCard;
