import React from "react";
import clsx from "clsx";

import styles from "./styles.module.css";

interface DeliverableSelectProps {
  type: "video" | "image" | "yt-video";
  url: string;
  id: string;
  isSelected: boolean;
  handleClick: () => void;
}

const DeliverableSelect: React.FC<DeliverableSelectProps> = ({
  type,
  url,
  id,
  isSelected,
  handleClick,
}) => {
  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: isSelected,
        [styles.imageContainer]: type === "image",
      })}
      onClick={handleClick}>
      {type === "image" && (
        <img src={url} alt="post" className={styles.image} />
      )}
      {type === "video" && <video src={url} className={styles.image} />}
    </div>
  );
};

export default DeliverableSelect;
