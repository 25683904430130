import { Row, Col, Button, message } from "antd";
import React from "react";
import styles from "./styles.module.css";
import { FiCheck } from "react-icons/fi";
import { useQuery, useMutation } from "react-query";

import { getAppState } from "../../../../handler/getAppState";
import {
  createCheckoutSession,
  getCustomerPortalLink,
} from "../../../../handler/plan";

import { tierDetail } from "../../../../typings";

import { capitalizeFirstLetter, formatCurrency } from "../../../../utils";

type PlanEntityProps = {
  pricingModel: tierDetail;
  isOwner: boolean;
};

const PlanEntity: React.FC<PlanEntityProps> = ({ pricingModel, isOwner }) => {
  const [appStateKey, appStateFn] = getAppState();

  const { data: appStateData } = useQuery(appStateKey, appStateFn);

  const createCheckoutSessionMutation = useMutation(createCheckoutSession, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Creating Checkout Session");
      console.log(err);
    },
  });

  const currentPlanIndex = appStateData?.data?.tier?.index || 0;

  const getCustomerPortalLinkMutation = useMutation(getCustomerPortalLink, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Getting Link");
      console.log(err);
    },
  });

  const getAction = () => {
    if (pricingModel?.isBasePlan || appStateData?.data?.tier?.isCustomPlan) {
      return {
        onClick: () => {},
        isLoading: false,
        disabled: true,
      };
    }

    if (pricingModel?.isCustomPlan) {
      return {
        // Open Book a Call Modal
        onClick: () =>
          window.open("https://cloutflow.com/book-a-demo", "_blank"),
        isLoading: false,
        disabled: !isOwner,
      };
    }

    if (!pricingModel?.isCustomPlan && !pricingModel?.isCustomPlan) {
      if (appStateData?.data?.tier?.isBasePlan) {
        return {
          onClick: () =>
            createCheckoutSessionMutation.mutate({
              priceId: pricingModel?.stripePriceId,
            }),
          isLoading: createCheckoutSessionMutation.isLoading,
          disabled: pricingModel.index < currentPlanIndex || !isOwner,
        };
      }
      return {
        onClick: () => getCustomerPortalLinkMutation.mutate(),
        isLoading: getCustomerPortalLinkMutation.isLoading,
        disabled: pricingModel.index < currentPlanIndex || !isOwner,
      };
    }

    return {
      onClick: () => {},
      isLoading: false,
      disabled: true,
    };
  };

  return (
    <Row gutter={[60, 0]} style={{ width: 672 }}>
      <Col span={14}>
        <h6 className={styles.planTitle}>{pricingModel.planName}</h6>
        <p className={styles.planPricing}>
          {/* {pricingModel.planName === "Enterprise"
            ? "Custom Pricing"
            : `${appStateData?.data?.currencySymbol}${
                pricingModel.price[appStateData?.data?.isIndia ? "inr" : "usd"]
              } per ${pricingModel.cycle}`} */}

          {/* {data?.data?.currencySymbol} */}
          {/* {formatCurrency(
            pricingModel.price[appStateData?.data?.isIndia ? "inr" : "usd"] ||
              "0",
            appStateData?.data?.isIndia ? "INR" : "USD"
          )} */}

          {pricingModel.planName === "Enterprise"
            ? "Custom Pricing"
            : `${formatCurrency(
                pricingModel.price[
                  appStateData?.data?.isIndia ? "inr" : "usd"
                ] || "0",
                appStateData?.data?.isIndia ? "INR" : "USD"
              )} per ${pricingModel.cycle}`}
        </p>
        <p style={{ marginBlock: 14 }} className={styles.planPeriod}>
          Paid {capitalizeFirstLetter(pricingModel.cycle)}ly
        </p>
        <Row>
          <Col span={12}>
            <Button
              type="primary"
              style={
                getAction().disabled
                  ? {}
                  : {
                      borderRadius: 4,
                      background: "rgba(83, 58, 245, 0.15)",
                      color: "#533AF5",
                      borderColor: "transparent",
                    }
              }
              onClick={() => getAction().onClick()}
              loading={getAction().isLoading}
              disabled={getAction().disabled}>
              {pricingModel.planName !== "Enterprise"
                ? "Upgrade Plan"
                : "Contact us"}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={10}>
        <h6 className={styles.text}>Everything in free, and</h6>
        <Row gutter={[0, 6]}>
          {pricingModel.description.map((descItem) => (
            <Col
              span={24}
              style={{ display: "flex", alignItems: "baseline", gap: 6 }}>
              <FiCheck /> <span>{descItem}</span>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
export default PlanEntity;
