import { sendRequest } from "../../helper";

export type postTimelineReqProps = {
  workflowId: string;
  deliverableId: string;
  requirementId?: string;
  name?: string;
  summary?: string;
  mentions?: string[];
  caption?: string;
  references?: { type: string; url: string }[];
};

export const postTimelineReq = ({
  workflowId,
  deliverableId,
  requirementId,
  name,
  summary,
  mentions,
  caption,
  references,
}: postTimelineReqProps) => {
  return sendRequest(
    "put",
    `/brand/execution/requirements/edit-requirements`,
    false,
    {
      workflowId,
      deliverableId,
      caption,
      mentions,
      name,
      references,
      requirementId,
      summary,
    }
  ).then((res) => res.data);
};
