import React, { useState } from "react";
import {
  Col,
  Row,
  Spin,
  Card,
  Space,
  Checkbox,
  Button,
  Divider,
  Table,
  Input,
} from "antd";
import { HiMagnifyingGlass } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery, useQuery } from "react-query";
import Lottie from "react-lottie";
import { ColumnsType } from "antd/es/table";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import animationData from "../../../assets/lottie/Rocket.json";

import {
  getDefaultLottieOptions,
  capitalizeFirstLetter,
} from "../../../../utils";

import socialMediaUrlGen from "../../../../utils/socialMediaUrlGen";

import styles from "./styles.module.css";

import UniCard from "../UniCard";
import CardHeader from "./CardHeader";
import CardRow from "./CardRow";
import ReportButton from "../../../Campaign/Report/ReportButton";
import InviteModal from "../../InviteModal";

import { getInvitedInfluencers } from "../../../../handler/community";

import communityStyles from "../../Community/community.module.css";

type PlatformConstant = "instagram" | "youtube";
type InviteStatusConstant = "withdrawn" | "accepted" | "pending";

interface DataType {
  dp: string;
  username: string;
  phoneNumber: string;
  // campaigns: number;
  influencerId: string;
  platform: PlatformConstant;
  name: string;
  connectedStatus: "Connected" | "Pending";
  inviteStatus: InviteStatusConstant;
}

const Influencers = ({ slug }: { slug: string | undefined }) => {
  const [filters, setFilters] = useState<{
    platform: PlatformConstant[];
    inviteStatus: InviteStatusConstant[];
    connectedStatus: string[];
    query: string;
  }>({
    platform: [],
    inviteStatus: [],
    connectedStatus: [],
    query: "",
  });

  const [influencersKey, influencersFn] = getInvitedInfluencers({
    search: filters.query,
    limit: 20,
    inviteBy: "brandPublicLink",
    accountStatus: filters.connectedStatus,
    inviteStatus: filters.inviteStatus,
    platform: filters.platform,
  });

  const navigate = useNavigate();

  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    data: InfluencerCampaignsData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(influencersKey, influencersFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.invited?.length ? lastPage.data.pageNo + 1 : null,
  });

  // if (isLoading) {
  //   return (
  //     <Row
  //       justify="center"
  //       style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
  //       <Lottie
  //         options={getDefaultLottieOptions(animationData)}
  //         height={300}
  //         width={370}
  //       />
  //     </Row>
  //   );
  // }

  const getPrimaryStatus = (connectedStatus: string) => {
    return connectedStatus === "Connected";
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { dp, name }) => (
        <Space size={10} align="center">
          {dp && (
            <div className={styles.profilePictureContainer}>
              <img src={dp ?? ""} className={styles.profile} alt="profile" />
            </div>
          )}
          <p className={styles.name}>{name ?? ""}</p>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, { username, platform, connectedStatus }) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <a
            href={socialMediaUrlGen({
              userNameOrChannelId: username,
              platform,
            })}>
            <p className={styles.username}>{username ?? ""}</p>
          </a>
          <span
            className={clsx(styles.status2, {
              [styles.connected]: getPrimaryStatus(connectedStatus),

              [styles.pending]: !getPrimaryStatus(connectedStatus),
            })}>
            {connectedStatus === "Connected" ? (
              <span
                style={{
                  display: "inline-block",
                  width: 5,
                  height: 5,
                  borderRadius: "50%",
                  backgroundColor: "#398200",
                }}></span>
            ) : (
              <span
                style={{
                  display: "inline-block",
                  width: 5,
                  height: 5,
                  borderRadius: "50%",
                  backgroundColor: "#7F7F7F",
                }}></span>
            )}
            <span style={{ fontSize: 8 }}>{connectedStatus}</span>
          </span>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { phoneNumber }) => (
        <p className={styles.followers}> {phoneNumber}</p>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      render: (_, record) => (
        <span>{capitalizeFirstLetter(record.platform)}</span>
      ),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, display: "grid", gap: 10 }}>
          <p>Platform</p>
          {["instagram", "youtube"].map((platformItem) => (
            <Checkbox
              checked={filters.platform.includes(
                platformItem as PlatformConstant
              )}
              onChange={(event) => {
                let newFiltersPlatform = [...filters.platform];
                if (event.target.checked) {
                  newFiltersPlatform = [
                    ...newFiltersPlatform,
                    platformItem as PlatformConstant,
                  ];
                } else if (
                  newFiltersPlatform.includes(platformItem as PlatformConstant)
                ) {
                  newFiltersPlatform = newFiltersPlatform.filter(
                    (item) => item !== platformItem
                  );
                }
                setFilters((filters) => ({
                  ...filters,
                  platform: newFiltersPlatform,
                }));
              }}
              style={{ margin: 0 }}>
              {capitalizeFirstLetter(platformItem)}
            </Checkbox>
          ))}
          <div style={{ marginTop: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedKeys([JSON.stringify(filters)]);
                confirm();
              }}>
              OK
            </Button>
          </div>
        </div>
      ),
      onFilter: () => {
        return true; // If no filters are selected, show all data
      },
    },

    {
      title: "Invite Status",
      dataIndex: "inviteStatus",
      key: "inviteStatus",

      render: (_, { inviteStatus }) => (
        <div
          className={clsx(styles.rowItem, styles.inviteStatus, {
            [styles.accepted]: inviteStatus === "accepted",
            [styles.pending2]: inviteStatus === "pending",
            [styles.withdrawn]: inviteStatus === "withdrawn",
          })}
          style={{ textAlign: "center" }}>
          {inviteStatus === "accepted"
            ? "Accepted 👍🏻"
            : inviteStatus === "pending"
            ? "Pending ⏳"
            : "Withdrawn 😕"}
        </div>
      ),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, display: "grid", gap: 10 }}>
          <p>Platform Connected Status</p>
          {["connected", "pending"].map((connectedStatus) => (
            <Checkbox
              checked={filters.connectedStatus.includes(connectedStatus)}
              onChange={(event) => {
                let newFiltersConnectedStatus = [...filters.connectedStatus];
                if (event.target.checked) {
                  newFiltersConnectedStatus = [
                    ...newFiltersConnectedStatus,
                    connectedStatus,
                  ];
                } else if (
                  newFiltersConnectedStatus.includes(connectedStatus)
                ) {
                  newFiltersConnectedStatus = newFiltersConnectedStatus.filter(
                    (item) => item !== connectedStatus
                  );
                }
                setFilters((filters) => ({
                  ...filters,
                  connectedStatus: newFiltersConnectedStatus,
                }));
              }}
              style={{ margin: 0 }}>
              {connectedStatus}
            </Checkbox>
          ))}

          <Divider type="horizontal" style={{ marginBlock: 5 }} />

          <p>Account Status</p>
          {["withdrawn", "accepted", "pending"].map((inviteStatus) => (
            <Checkbox
              checked={filters.inviteStatus.includes(
                inviteStatus as InviteStatusConstant
              )}
              style={{ margin: 0 }}
              onChange={(event) => {
                let newFiltersInviteStatus = [...filters.inviteStatus];
                if (event.target.checked) {
                  newFiltersInviteStatus = [
                    ...newFiltersInviteStatus,
                    inviteStatus as InviteStatusConstant,
                  ];
                } else if (
                  newFiltersInviteStatus.includes(
                    inviteStatus as InviteStatusConstant
                  )
                ) {
                  newFiltersInviteStatus = newFiltersInviteStatus.filter(
                    (item) => item !== inviteStatus
                  );
                }
                setFilters((filters) => ({
                  ...filters,
                  inviteStatus: newFiltersInviteStatus,
                }));
              }}>
              {capitalizeFirstLetter(inviteStatus)}
            </Checkbox>
          ))}

          <div style={{ marginTop: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedKeys([JSON.stringify(filters)]);
                confirm();
              }}>
              OK
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => {
        return true; // If no filters are selected, show all
      },
    },

    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (_, { influencerId }) => (
        <>
          {influencerId && (
            <ReportButton
              type="default"
              onClick={() => navigate(`/influencerAnalysis/${influencerId}`)}>
              Open profile
            </ReportButton>
          )}
        </>
      ),
    },
  ];

  return (
    <main>
      <Col span={8}>
        <UniCard slug={slug} isEdit />
      </Col>

      <Row justify="space-between" style={{ marginBlock: 40 }}>
        <Col span={11} className={communityStyles.inputContainer}>
          <Space size={2} align="center" style={{ width: "100%" }}>
            <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
            <Input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value || "")}
              onPressEnter={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  query: searchQuery,
                }))
              }
              placeholder={"Search by name or username"}
              className={communityStyles.input}
              size="large"
              style={{
                borderColor: "transparent",
                boxShadow: "none",
                width: "100%",
                minWidth: "400px",
              }}
            />
          </Space>
          <ReportButton
            type="primary"
            onClick={() =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                query: searchQuery,
              }))
            }
            style={{ padding: "10px 20px " }}>
            Search
          </ReportButton>
        </Col>
      </Row>

      <section style={{ marginBlock: 20 }}>
        <div style={{ height: "50vh", overflowY: "scroll" }}>
          <InfiniteScroll
            loadMore={() => fetchNextPage()}
            className={styles.g10}
            style={{ alignItems: "flex-start" }}
            hasMore={hasNextPage}
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            useWindow={false}>
            {/* {isLoading ? (
              <Row justify="center">
                <Spin />
              </Row>
            ) : ( */}
            <Table
              columns={columns}
              dataSource={
                InfluencerCampaignsData?.pages?.flatMap((pageItem) =>
                  pageItem.data?.invited?.map((dataItem) => {
                    return {
                      dp: dataItem?.profilePicUrl,
                      username: dataItem?.socialMediaName,
                      phoneNumber: dataItem?.phoneNumber,
                      // campaigns: numberString,
                      influencerId:
                        dataItem?.socialMediaKit?.influencerId || "",
                      platform: dataItem?.platform,
                      name: dataItem?.fullName || "",
                      connectedStatus: dataItem?.socialMediaKit?._id
                        ? "Connected"
                        : "Pending",
                      inviteStatus: dataItem?.isRemoved
                        ? "withdrawn"
                        : dataItem?.isInviteAccepted
                        ? "accepted"
                        : "pending",
                    } as DataType;
                  })
                ) || []
              }
              scroll={{ y: 400 }}
              pagination={false}
              loading={isLoading}
            />
            {/* )} */}
          </InfiniteScroll>
        </div>
      </section>
      <InviteModal
        handleCancel={() => setInviteModalVisible(false)}
        isVisible={inviteModalVisible}
      />
    </main>
  );
};

export default Influencers;
