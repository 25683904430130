import { sendRequest } from "../helper";

type Insights = {
  status: string | null;
  message: string | null;
  data: {
    user: {
      name: string;
      profile_picture_url: string;
      username: string;
      [key: string]: any;
    };

    lastUpdatedDateTime: string;
  };
};

export const getYtAnalytics = (influencerId: string) => {
  const queryKey = ["analyze", "influencer", influencerId, "youtube"];
  const queryFunction = () =>
    sendRequest<Insights>(
      "get",
      `/brand/brand-influencer/media-kit/youtube/user?socialMediaId=${influencerId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
