import React, { useState } from "react";
import { Row, Space, message } from "antd";
import { clsx } from "clsx";

import styles from "./styles.module.css";

import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";

import EditModal from "./EditModal";

interface UinCardProps {
  isEdit: boolean;
  slug: string | undefined;
}

const UniCard: React.FC<UinCardProps> = ({ isEdit, slug }) => {
  const [showModal, setShowModal] = useState(false);
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      message.success("Link copied successfully!");
    } catch (err) {
      message.error("Failed to copyt the link");
    }
  };

  return (
    <React.Fragment>
      <EditModal
        slug={slug}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <section className={styles.container}>
        <Row align="middle" justify="space-between">
          <Space align="center" size={13}>
            <img
              src="/assets/img/link.png"
              style={{ width: "26px", height: "26px" }}
              alt="graphics"
            />
            <span className={styles.heading}>Uni-Link</span>
          </Space>
        </Row>
        {isEdit ? (
          <div style={{ marginTop: "18px", display: "grid", gap: "5px" }}>
            <p className={styles.content}>Your current Uni-Link is</p>
            <div className={clsx(styles.flex, styles.gapfull)}>
              <a
                className={styles.link}
                href={`https://connect.cloutflow.com/brand/${slug || ""}`}
                target="_blank"
                rel="noreferrer">
                connect.cloutflow.com/brand/{slug || ""}
              </a>
              <div className={clsx(styles.flex, styles.gap6)}>
                <button
                  onClick={() =>
                    copyToClipBoard(`connect.cloutflow.com/brand/${slug || ""}`)
                  }
                  className={styles.btn}>
                  <AiOutlineLink />
                </button>
                <button
                  onClick={() => setShowModal(true)}
                  className={styles.btn}>
                  <MdOutlineEdit />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p className={styles.content} style={{ marginTop: "18px" }}>
            Lorem ipsum dolor sit amet,consec tetur adipiscing elit
          </p>
        )}
      </section>
    </React.Fragment>
  );
};

export default UniCard;
