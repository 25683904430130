import React from "react";
import { Image, Space, Skeleton } from "antd";
import clsx from "clsx";

import { capitalizeFirstLetter, trimData } from "../../../../utils";

import styles from "./styles.module.css";

interface Props {
  url: string;
  type: "IMAGE" | "VIDEO";
  isSelected: boolean;
  handleClick: () => void;
  label?: string;
  caption?: string;
}

const IgDeliverable: React.FC<Props> = ({
  url,
  type,
  isSelected,
  handleClick,
  label = "",
  caption = "",
}) => {
  return (
    <section>
      {label && (
        <Space
          size={10}
          align="center"
          className={clsx(styles.label, {
            [styles.selectedLabel]: isSelected,
          })}>
          <p>
            {capitalizeFirstLetter(
              label?.toString()?.split("-").at(-1)?.toLowerCase() || ""
            )}
          </p>
        </Space>
      )}
      <div
        className={clsx(styles.container, {
          [styles.selected]: isSelected,
          [styles.imageContainer]: true,
        })}
        onClick={(e) => {
          handleClick();
          e.stopPropagation();
        }}>
        {type === "IMAGE" && (
          <Image
            className={styles.image}
            src={url}
            height={200}
            width={200}
            preview={{
              mask: (caption || "").trim() ? (
                <p style={{ padding: 5 }}>
                  {trimData({ value: caption, limit: 50 })}
                </p>
              ) : (
                <div />
              ),
              // onVisibleChange(value) {
              //   if (platform === "instagram") return setPreviewVisible(value);
              //   if (value) {
              //     window.open(url, "_blank");
              //   }
              // },
            }}
          />
          // <img src={url} alt="post" className={styles.image} />
        )}
        {type === "VIDEO" && (
          <div style={{ position: "relative" }}>
            <video
              muted={true}
              src={url}
              controls={false}
              style={{ objectFit: "cover" }}
              width={"200px"}
              height={"200px"}
              className={styles.image}
            />
            <div
              style={{
                position: "absolute",
                // backgroundColor: "red",
                height: "200px",
                width: "200px",
                top: 0,
                left: 0,
                zIndex: -1,
              }}>
              <Skeleton.Image style={{ height: "200px", width: "200px" }} />
            </div>
          </div>
        )}
        {/* {type === "yt-video" && (
        <div className={styles.ytContainer}>
          <img alt="yt thumbnail" src={url} className={styles.thumbnail} />
          <Space align="center" size={5} style={{ marginTop: 5 }}>
            <img
              alt="profile pic"
              src={profilePic}
              className={styles.profilePic}
            />{" "}
            <p className={styles.caption}>
              {(caption || "")?.length > 22
                ? caption?.slice(0, 22) + "..."
                : caption}
            </p>
          </Space>
        </div>

        // <video className={styles.image}>
        //   <source src={url} type="video/mp4"></source>
        // </video>
      )} */}
      </div>
    </section>
  );
};

export default IgDeliverable;
