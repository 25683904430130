import { sendRequest } from "../../helper";

interface ApplicantCountProps {
  campaignId: string;
  text?: string;
  status:
    | "Applied"
    | "Shortlisted"
    | "Rejected"
    | "Invited"
    | "Finalized"
    | "Completed"
    | "";
}

interface ApplicantCountResponse {
  status: string;
  message: string;
  data: number;
}

export const getApplicantCount = ({
  campaignId,
  status,
  text = "",
}: ApplicantCountProps) => {
  const queryKey = ["applicants", campaignId, "count", status, text];

  const queryFunction = () =>
    sendRequest<ApplicantCountResponse>(
      "get",
      `/brand/campaign/offer/count/${campaignId}?status=${status}${
        text ? `&text=${text}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
