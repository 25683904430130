import clsx from "clsx";
import React, { useState } from "react";
import { Space, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import styles from "./styles.module.css";

import { inviteInfluencer } from "../../handler/community";

import ReportButton from "../Campaign/Report/ReportButton";
import PurchaseCommunityCredits from "../Credits/PurchaseCommunityCredits";

import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../utils";

const InformationCard: React.FC<{
  isAutoInvite: boolean;
  hasAccess: boolean;
  isProfileLocked: boolean;
  platform: "instagram" | "youtube";
  color?: "blue" | "pink" | "red";
  accountStatus: "Session-Expired" | "Connected" | "Disconnected";
  lastUpdated?: string;
  username?: string;
  isRevoked?: boolean;
  soicalMediaKitId: string;
  handleInvalidate: () => void;
  isThirdParty: boolean;
}> = ({
  hasAccess,
  accountStatus,
  platform,
  color = "blue",
  lastUpdated = "",
  username = "",
  isRevoked = false,
  isProfileLocked,
  soicalMediaKitId,
  handleInvalidate,
  isThirdParty,
  isAutoInvite,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [isPurchaseMediaVisible, setIsPurchaseMediaVisible] =
    useState<boolean>(false);

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: (res) => {
      if (res?.data?.isInvitationSendToInfluencer) {
        message.success("Invitation Sent");
        return;
      }
      setLoading(true);
      handleInvalidate();
      setTimeout(() => setLoading(false), 30000);

      return;
    },
    onError: (err) => {
      console.log(err);
      message?.error("Something Went wrong");
    },
  });

  const getTagColor = () => {
    switch (accountStatus) {
      case "Connected":
        return "#87d068";
      case "Disconnected":
        return "#f50";
      case "Session-Expired":
        return "#f50";
    }
  };

  return (
    <div
      className={clsx(styles.InfoContainer, {
        [styles.instaBg]: color === "red",
        [styles.ytBg]: color === "pink",
        [styles.noAccessContainer]: color === "blue",
      })}>
      <p
        className={styles.instaTitle}
        style={{ display: "flex", alignItems: "center" }}>
        {hasAccess
          ? isProfileLocked
            ? "Profile Locked"
            : isRevoked
            ? "Account Revoked"
            : `${capitalizeFirstLetter(platform)} Analytis`
          : `${isAutoInvite ? "Add" : "Invite"} to your Community`}
        <Space style={{ marginLeft: 10 }}>
          <Tag color={getTagColor()} style={{ borderRadius: 8 }}>
            {accountStatus}
          </Tag>
          {isThirdParty && (
            <Tag color={"#f50"} style={{ borderRadius: 8 }}>
              Third Party
            </Tag>
          )}
        </Space>
      </p>
      {!isThirdParty && (
        <>
          {hasAccess ? (
            <>
              {isProfileLocked ? (
                <>
                  <p className={styles.instaDesc}>
                    Purchase More Credits to unlock this profile and see deep
                    dived detailed analysis of this profile
                  </p>
                  <ReportButton
                    type="primary"
                    onClick={() => setIsPurchaseMediaVisible(true)}>
                    {" "}
                    Purchase Community Credits
                  </ReportButton>
                </>
              ) : (
                <>
                  {isRevoked ? (
                    <p
                      style={{
                        color: "rgba(0, 0, 0, 0.80)",
                        fontSize: "14px",
                        fontWeight: 500,
                        // width: "20%",
                      }}>
                      We were not able to update the data of {username} since{" "}
                      {lastUpdated && (
                        <span>
                          {format(new Date(lastUpdated), "hh:mm a dd/LL/yyyy")},
                          since{" "}
                        </span>
                      )}
                      {username} might have disconnected their social media
                      account from Cloutflow.
                      <br />
                      <p
                        style={{
                          color: "#533AF5",
                          textDecoration: "underline",
                          marginTop: "10px",
                        }}>
                        Ask {username} to connect their social media again
                      </p>
                    </p>
                  ) : (
                    <>
                      <Space size={10}>
                        {platform === "instagram" ? (
                          <img
                            src="/assets/icons/meta.svg"
                            alt="logo meta"
                            height={24}
                            width={48}
                          />
                        ) : (
                          <img
                            src="/assets/icons/youtubeIcon.png"
                            alt="logo youtube"
                            height={24}
                            width={28}
                          />
                        )}
                        <p className={styles.instaDesc}>
                          The data is fetched from{" "}
                          {platform === "instagram" ? "Facebook" : "Google"}{" "}
                          APIs.
                          <br />
                          We provide with only first part consent based data.{" "}
                          <span className={styles.learnMore}>Learn More</span>
                        </p>
                      </Space>
                      {lastUpdated && (
                        <p className={styles.instaDesc}>
                          Last updated:{" "}
                          {format(new Date(lastUpdated), "hh:mm a dd/LL/yyyy")}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <p className={styles.instaDesc}>
                Invite {username} to your community to view their insights and
                add them to your reports.
                <br /> We provide with only first part consent based data.
                <span className={styles.learnMore}>Learn More</span>
                <br />
                For this the influencer needs to be a part of your influencer’s
                community.
              </p>
              {accountStatus !== "Disconnected" && (
                <ReportButton
                  type="primary"
                  onClick={() =>
                    inviteInfluencerMutation.mutate({
                      socialMediaKitId: soicalMediaKitId,
                    })
                  }
                  loading={inviteInfluencerMutation.isLoading || loading}>
                  {" "}
                  {isAutoInvite ? "Add " : "Invite "} to community
                </ReportButton>
              )}
            </>
          )}
        </>
      )}

      <PurchaseCommunityCredits
        visible={isPurchaseMediaVisible}
        handleClose={() => setIsPurchaseMediaVisible(false)}
      />
    </div>
  );
};

export default InformationCard;
