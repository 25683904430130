import { sendRequest } from "../../../helper";

export type postDraftChangesV2Props = {
  workflowId: string;
  changes: string;
  prevDraftTimeLineId: string;
};

export const postDraftChangesV2 = ({
  workflowId,
  changes,
  prevDraftTimeLineId,
}: postDraftChangesV2Props) => {
  return sendRequest(
    "put",
    `/brand/execution/drafts/require-changes`,
    false,
    {
      workflowId,
      changes,
      prevDraftTimeLineId,
    },
    null,
    true
  ).then((res) => res.data);
};
