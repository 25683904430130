import { sendRequest } from "../helper";
import { CommunityInfluencer } from "../../typings";

export type getCommunityInfluencersResult = {
  status: string;
  message: string;
  data: {
    community: CommunityInfluencer[];
    pageNo: number;
  };
};

export type CommunityProps = {
  platform: ("instagram" | "youtube")[];
  limit: number;
  sortBy: string;
  search: string;
  accountStatus: ("connected" | "disconnected" | "session-expired")[]; // connected, disconnected, session-expired
};

export const getCommunityInfluencers = (props: CommunityProps) => {
  const queryKey = [
    "community",
    props?.platform,
    props.limit,
    props.sortBy,
    props.search,
    props?.accountStatus,
  ];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getCommunityInfluencersResult>(
      "post",
      `/brand/community`,
      false,
      {
        ...props,
        pageNo: pageParam || 1,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
