import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  startAfter,
  limit,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Row, Spin, Typography, Tabs, Empty } from "antd";
import Cookies from "universal-cookie";

import { notificationTypes } from "../../../shared/Navbar/Notification/Types";
// import InfiniteScroll from "react-infinite-scroll-component";

import InfiniteScroll from "react-infinite-scroller";

import { useUserAuth } from "../../../../context/userAuthcontext";
import { firebaseDb } from "../../../../firebase";

import { Notification as NotificationData } from "../../../../typings/Notification";

import NotificationItem from "./NotificationItem";

const CampaignNotification: React.FC<{ campaignId: string }> = ({
  campaignId,
}) => {
  const cookies = new Cookies();
  const { currentUser } = useUserAuth();
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [docSnapshot, setDocSnapshot] = useState<any>([]);
  const [reminderNotifications, setReminderNotifications] = useState<
    NotificationData[]
  >([]);
  const [reminderDocSnapshot, setReminderDocSnapshot] = useState<any>([]);
  const firstRender = useRef(true);

  // Firebase Notifications Handler

  const fetchNotificationData = async (campaignId: string) => {
    let arr = [...notifications];
    const firebaseQuery = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      where("ref.campaignId", "==", campaignId),
      where("type", "in", [
        notificationTypes.ApplicationWithdrawn,
        notificationTypes.ApplicationAccepted,
        notificationTypes.ApplicationRejected,
        notificationTypes.CampaignApproved,
        notificationTypes.CampaignApprovalIssue,
        notificationTypes.PaymentDone,
        notificationTypes.PaymentFailed,
      ]),
      orderBy("createdDateTime", "desc"),
      limit(3)
    );

    const notifListener = onSnapshot(firebaseQuery, (snapshot) => {
      setDocSnapshot(snapshot.docs);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            if (firstRender.current) {
              arr.push({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            } else {
              arr.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            }
          }
        }
        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData;
            }
            return item;
          });
          arr = newArray;
        }
      });

      setNotifications(arr);
    });
    return notifListener;
  };

  const fetchReminderNotificationData = async (campaignId: string) => {
    let arr = [...reminderNotifications];
    const firebaseQuery = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid),
      where("ref.campaignId", "==", campaignId),
      where("type", "in", [
        notificationTypes.PaymentPendingReminder,
        notificationTypes.SendRequirementsReminder,
        notificationTypes.DraftsApprovedReminder,
        notificationTypes.LiveApprovedReminder,
      ]),
      orderBy("createdDateTime", "desc"),
      limit(3)
    );

    const notifListener = onSnapshot(firebaseQuery, (snapshot) => {
      setReminderDocSnapshot(snapshot.docs);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            if (firstRender.current) {
              arr.push({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            } else {
              arr.unshift({
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData);
            }
          }
        }

        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              } as NotificationData;
            }
            return item;
          });
          arr = newArray;
        }
      });

      setReminderNotifications(arr);
    });
    return notifListener;
  };

  const changeRead = async (isRead: boolean, id: string) => {
    const docRef = doc(firebaseDb, "Notifications", id);
    updateDoc(docRef, { isRead: !isRead }).then((doc) => {
      const newData = notifications.map((obj) => {
        if (obj.id === id) {
          return { ...obj, isRead: !isRead };
        }

        return obj;
      });

      setNotifications(newData);
    });
  };
  const changeReadReminders = async (isRead: boolean, id: string) => {
    const docRef = doc(firebaseDb, "Notifications", id);
    updateDoc(docRef, { isRead: !isRead }).then((doc) => {
      const newData = reminderNotifications.map((obj) => {
        if (obj.id === id) {
          return { ...obj, isRead: !isRead };
        }

        return obj;
      });

      setReminderNotifications(newData);
    });
  };

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(firebaseDb, "Notifications"),
        where("userId", "==", cookies.get("brandId") || currentUser?.uid),
        where("ref.campaignId", "==", campaignId),
        where("type", "in", [
          notificationTypes.ApplicationWithdrawn,
          notificationTypes.ApplicationAccepted,
          notificationTypes.ApplicationRejected,
          notificationTypes.CampaignApproved,
          notificationTypes.CampaignApprovalIssue,
          notificationTypes.PaymentDone,
          notificationTypes.PaymentFailed,
        ]),
        orderBy("createdDateTime", "desc"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        limit(3)
      );

      const documentSnapshots = await getDocs(next);

      let docData: NotificationData[] = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          ...doc.data(),
        } as NotificationData);
      });
      setNotifications((notifications) => [...notifications, ...docData]);
      setDocSnapshot(documentSnapshots.docs);
    }
  };
  const loadMoreReminders = async () => {
    if (reminderDocSnapshot[reminderDocSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(firebaseDb, "Notifications"),
        where("userId", "==", cookies.get("brandId") || currentUser?.uid),
        where("ref.campaignId", "==", campaignId),
        where("type", "in", [
          notificationTypes.PaymentPendingReminder,
          notificationTypes.SendRequirementsReminder,
          notificationTypes.DraftsApprovedReminder,
          notificationTypes.LiveApprovedReminder,
        ]),
        orderBy("createdDateTime", "desc"),
        startAfter(reminderDocSnapshot[reminderDocSnapshot.length - 1]),
        limit(3)
      );

      const documentSnapshots = await getDocs(next);

      let docData: NotificationData[] = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          ...doc.data(),
        } as NotificationData);
      });

      setReminderNotifications((notifications) => [
        ...notifications,
        ...docData,
      ]);
      setReminderDocSnapshot(documentSnapshots.docs);
    }
  };

  useEffect(() => {
    let unsubScribe: any = () => {};
    let unsubScribeReminders: any = () => {};

    if (currentUser && campaignId) {
      firstRender.current = true;

      fetchNotificationData(campaignId).then((val) => {
        unsubScribe = val;
      });
      // fetchReminderNotificationData(campaignId).then((val) => {
      //   unsubScribeReminders = val;
      // });

      // To run this code after a specific time, as it was running before Promise was resolved ,HEHE :P
      setTimeout(() => {
        firstRender.current = false;
      }, 4000);
    }

    return () => {
      unsubScribe();
      // unsubScribeReminders();
      console.log("unmounted");
      firstRender.current = false;
    };
  }, [currentUser, campaignId]);

  return (
    <div
      style={{
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px 2px #edeaea",
        padding: "4px",
      }}>
      {/* <Row justify="center" style={{ padding: "16px 0" }}>
        <Title level={5}>Updates</Title>
      </Row> */}
      <Tabs defaultActiveKey="1" style={{ padding: "0 10px " }}>
        <Tabs.TabPane tab="Notification" key="1">
          <InfiniteScroll
            loadMore={() => {
              loadMore();
            }}
            id="general"
            hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            style={{ height: "50vh", overflowY: "scroll" }}
            // height={"60vh"}
            // dataLength={notifications.length}
          >
            {notifications.length === 0 && (
              <Empty description="No Notifications" />
            )}
            {notifications.map((item) => (
              <NotificationItem
                notification={item}
                key={item.id}
                changeRead={changeRead}
              />
            ))}
          </InfiniteScroll>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Reminders" key="2">
          <InfiniteScroll
            loadMore={() => {
              loadMoreReminders();
            }}
            id="reminders"
            hasMore={
              reminderDocSnapshot[reminderDocSnapshot.length - 1] !== undefined
            }
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            style={{ height: "50vh", overflowY: "scroll" }}
            // height={"60vh"}
            // dataLength={notifications.length}
          >
            {reminderNotifications.length === 0 && (
              <Empty description="No Notifications" />
            )}
            {reminderNotifications.map((item, idx) => (
              <NotificationItem
                notification={item}
                key={item.id + idx}
                changeRead={changeReadReminders}
              />
            ))}
          </InfiniteScroll>
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
};

export default CampaignNotification;
