import React from "react";
import { useState, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

import { useQuery } from "react-query";

import { Typography, Space, Select, Slider, Row, Button } from "antd";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { ConfigType } from "../../../typings/CreatorSearch";
import HelpPopOver from "./HelpPopover";
import { getFacetValues } from "../../../handler/insightIq";

const { Text, Paragraph, Title } = Typography;

interface Props extends ConfigType {
  isOnSearch?: boolean;
}

const MultiInputRange: React.FC<any> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);
  const [query, setQuery] = useState<{
    search: string;
    min?: number;
    max?: number;
  }>({ search: "", min: undefined, max: undefined });
  const { searchParams, setSearchParams, platformId } =
    useCreatorSearchContext();

  const [getFacetMinKey, getFacetMinValue] = getFacetValues({
    facetName: `${facetName}_min`,
    body: { min: query.min, max: query.max },
    work_platform_id: platformId,
  });
  const [getFacetMaxKey, getFacetMaxValue] = getFacetValues({
    facetName: `${facetName}_max`,
    body: { min: query.min, max: query.max },
  });

  const { data: facetMinData } = useQuery(getFacetMinKey, getFacetMinValue, {
    enabled: visible,
  });

  const { data: facetMaxData } = useQuery(getFacetMaxKey, getFacetMaxValue, {
    enabled: visible,
  });

  useEffect(() => {
    if (!visible) {
      setQuery({ search: "", min: undefined, max: undefined });
    }
  }, [visible]);

  const selectedValueFrom = (searchParams[facetName] || ({} as any))?.min;
  const selectedValueTo = (searchParams[facetName] || ({} as any))?.max;
  const selectedValuePercentage = Number(
    (searchParams[facetName] || ({} as any))?.percentage || 0
  );

  const handleChangeObject = ({
    type,
    value,
  }: {
    type: "min" | "max" | "percentage";
    value: string;
  }) => {
    const newFacetObject = {
      ...searchParams,
      [facetName]: { ...searchParams[facetName], [type]: value },
      filterTags: {
        ...searchParams["filterTags"],
        [facetName]: { ...searchParams[facetName], [type]: value },
      },
    };
    setSearchParams((searchParams) => ({ ...searchParams, ...newFacetObject }));
  };

  return (
    <div>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <>
          <Space wrap>
            <div style={{ width: "100px", display: "grid", gap: "2px" }}>
              <Text type="secondary">From</Text>
              <Select
                searchValue={query.search}
                value={selectedValueFrom}
                defaultActiveFirstOption={false}
                onSearch={(value) =>
                  setQuery((query) => ({ ...query, search: value }))
                }
                onChange={(value) => handleChangeObject({ type: "min", value })}
                notFoundContent={null}
                options={(facetMinData?.data || []).map((d) => ({
                  value: d.value,
                  label: d.name,
                }))}
              />
            </div>
            <div style={{ width: "100px", display: "grid", gap: "2px" }}>
              <Text type="secondary">To</Text>
              <Select
                searchValue={query.search}
                showSearch={isSearchable}
                value={selectedValueTo}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={(value) =>
                  setQuery((query) => ({ ...query, search: value }))
                }
                onChange={(value) => handleChangeObject({ type: "max", value })}
                notFoundContent={null}
                options={(facetMaxData?.data || []).map((d) => ({
                  value: d.value,
                  label: d.name,
                }))}
              />
            </div>
          </Space>

          {(selectedValueFrom || selectedValueTo) && (
            <div style={{ marginTop: 5 }}>
              <Row justify={"space-between"}>
                <Paragraph>
                  More than <b>{selectedValuePercentage}</b>% {title} is{" "}
                  <b>{`${selectedValueFrom}-${selectedValueTo}`}</b>
                </Paragraph>
                <Button
                  icon={<TiDelete size={18} />}
                  danger
                  onClick={() => {
                    setSearchParams((searchParams) => ({
                      ...searchParams,
                      [facetName]: undefined,
                      filterTags: {
                        ...searchParams["filterTags"],
                        [facetName]: undefined,
                      },
                    }));
                  }}
                  type="link"
                />
              </Row>
              <Slider
                min={0}
                max={100}
                onChange={(val) => {
                  handleChangeObject({
                    type: "percentage",
                    value: val.toString(),
                  });
                }}
                value={selectedValuePercentage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MultiInputRange;
