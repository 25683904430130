import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Space, Tag } from "antd";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { useQuery } from "react-query";

import styles from "./styles.module.css";

import ReportButton from "../../../ReportButton";

import { formatData } from "../../../../../../utils";

import { CreatorReport } from "../../../../../../typings";
import { getInfluencerReportStatus } from "../../../../../../handler/report";
import func from "../../../../../../utils/socialMediaUrlGen";

interface CreatorCardProps {
  creator?: CreatorReport;
  isSelected: boolean;
  inCampaign?: boolean;
  handleClick?: (creator: CreatorReport, price?: number) => void;
  campaignId: string;
  dummyCreator?: {
    profilePic: string;
    name: string;
    followers: number;
    username: string;
    influencerId: string;
    platform: string;
    connectionStatus: string;
  };
}

const CreatorCard: React.FC<CreatorCardProps> = ({
  creator,
  handleClick,
  isSelected,
  inCampaign = false,
  campaignId,
  dummyCreator,
}) => {
  const [influencerStatusKey, influencerStatusFn] = getInfluencerReportStatus({
    campaignId,
    socialMediaId: creator?.socialMediaKit?.socialMediaId || "",
  });

  const navigate = useNavigate();

  const { data } = useQuery(influencerStatusKey, influencerStatusFn, {
    enabled: inCampaign && Boolean(creator?.socialMediaKit?.socialMediaId),
  });

  return (
    <div
      className={clsx(styles.container, { [styles.selectedItem]: isSelected })}
      id={creator?._id}
      onClick={() => {
        if (handleClick && creator) {
          handleClick(creator, data?.data?.brandPrice);
        }
      }}
    >
      <Space size={10} align="center">
        <img
          className={styles.profile}
          alt="profile"
          src={
            creator?.socialMediaKit?.profilePicUrl || dummyCreator?.profilePic
          }
        />
        <div style={{ display: "grid", gap: "6.5px" }}>
          <p className={styles.name}>
            {creator?.socialMediaKit?.influencerName || dummyCreator?.name}
            <br />
            {creator?.socialMediaKit?.connectionStatus !== "Connected" && (
              <Tag color="#f50" style={{ borderRadius: 8, marginBlock: "2px" }}>
                {creator?.socialMediaKit?.connectionStatus}
              </Tag>
            )}
            {dummyCreator?.connectionStatus &&
              dummyCreator?.connectionStatus !== "Connected" && (
                <Tag
                  color="#f50"
                  style={{ borderRadius: 8, marginBlock: "2px" }}
                >
                  {creator?.socialMediaKit?.connectionStatus}
                </Tag>
              )}
          </p>

          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {creator?.platform === "instagram" ||
            dummyCreator?.platform === "instagram" ? (
              <AiOutlineInstagram size={20} color="rgba(0, 0, 0, 0.5)" />
            ) : (
              <AiOutlineYoutube size={20} color="rgba(0, 0, 0, 0.5)" />
            )}

            <p className={styles.username}>
              <a
                style={{ color: "#333" }}
                href={func({
                  userNameOrChannelId:
                    creator?.socialMediaKit?.socialMediaName || "",
                  platform: creator?.platform,
                })}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {creator?.socialMediaKit?.socialMediaName ||
                  dummyCreator?.username}
              </a>
            </p>
          </div>
          <p className={styles.followers}>
            {formatData(
              creator?.socialMediaKit?.followerCount ||
                dummyCreator?.followers ||
                "",
              "a"
            )}{" "}
            Followers
          </p>
        </div>
      </Space>
      <div style={{ display: "grid", gap: "8.5px" }}>
        {data?.data?.isInfluencerInCampaign && inCampaign && (
          <p className={styles.tag}>Already Added</p>
        )}
        <ReportButton
          ghost
          style={{ textDecoration: "underline" }}
          onClick={() =>
            navigate(
              `/influencerAnalysis/${
                creator?.influencerId || dummyCreator?.influencerId
              }`
            )
          }
        >
          Open Profile
        </ReportButton>
      </div>
    </div>
  );
};

export default CreatorCard;
