import { sendRequest } from "../helper";

interface sendEmailOtpProps {
  senderEmail: string;
  senderName: string;
}

export const sendEmailOtp = (conversation: sendEmailOtpProps) => {
  return sendRequest("put", `/brand/marketing/new-email`, false, {
    ...conversation,
  }).then((res) => res.data);
};
