import React, { useState } from "react";
import { useQuery } from "react-query";
import { useReportOnly } from "../Context/ReportOnlyContext";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { DownOutlined } from "@ant-design/icons";
import { GiSettingsKnobs } from "react-icons/gi";
import Lottie from "react-lottie";
import {
  Input,
  Table,
  TableColumnsType,
  Space,
  Col,
  Row,
  Popover,
  Radio,
} from "antd";
import exportFromJSON from "export-from-json";

import rocketAnimationData from "../../../assets/lottie/Rocket.json";
import animationDataEmptyReport from "../../../assets/lottie/astronot.json";

import FilterDrawer from "./FilterDrawer";
import ReportButton from "../ReportButton";
import GaCard from "./GaCard";
import OverViewSection from "./OverviewSection";
import InfluencerDrawer from "./InfluencerDrawer";
import AddInfluencerModal from "../Modals/AddInfluencer";
import DisabledPopOver from "../../../shared/DisabledPopOver";

import { getAppState } from "../../../../handler/getAppState";

import styles from "./styles.module.css";
import globalStyles from "../Modals/styles.module.css";

import { formatData } from "../../../../utils";
import { getDefaultLottieOptions } from "../../../../utils";

import {
  igReelMetrics,
  igStoryMetrics,
  igPostMetrics,
  ytMetrics,
} from "../../../../constants";

const ReportScreen = () => {
  const {
    tableColumn,
    tableRow,
    setFilterBySearchOrMediaType,
    mediaTypes,
    selectedMediaType,
    searchKey,
    isLoading,
    campaignId,
    totalReportsLength,
  } = useReportOnly();
  const dataFields = tableColumn;

  const isSelectedMediaTypeTotal = selectedMediaType === "";

  let originalData: { [key: string]: any }[] = tableRow;

  const [userStateKey, userStateFn] = getAppState();

  const { data: userStateData } = useQuery(userStateKey, userStateFn);

  const [drawer, setdrawer] = useState<boolean>(false);

  const [influencerDrawerVisible, setInfluencerDrawerVisible] =
    useState<boolean>(false);
  const [addInfluencerModalVisible, setAddInfluencerModalVisible] =
    useState<boolean>(false);

  const [influencerDrawerProps, setInfluencerDrawProps] = useState<{
    campaignId: string;
    socialMediaId: string;
    platform: string;
    deliverableMediaId: string;
  }>({
    campaignId: "",
    socialMediaId: "",
    platform: "",
    deliverableMediaId: "",
  });

  const tableColumns: TableColumnsType<{ [key: string]: any }> = dataFields.map(
    (item) => {
      return {
        title: (
          <div className={styles.colLabel}>
            {item?.key === "influencer" ? (
              ""
            ) : item.key === "type" ? (
              <Popover
                content={
                  <>
                    <Radio.Group
                      onChange={(e) =>
                        setFilterBySearchOrMediaType(searchKey, e.target.value)
                      }
                      value={selectedMediaType}
                    >
                      <Col style={{ marginBottom: "8px" }}>
                        <Radio value={""}>Total</Radio>
                      </Col>

                      {mediaTypes.map((item) => (
                        <Col style={{ marginBottom: "8px" }}>
                          <Radio value={item}>{item}</Radio>
                        </Col>
                      ))}
                    </Radio.Group>
                  </>
                }
                trigger="click"
                placement="bottomLeft"
              >
                <Space style={{ cursor: "pointer" }}>
                  Type
                  <DownOutlined />
                </Space>
              </Popover>
            ) : (
              item.title
            )}
          </div>
        ),
        dataIndex: item.key,
        key: item.key,
        fixed:
          item.key === "influencer" || item.key == "type" ? "left" : undefined,
        width: item.key === "influencer" ? "8%" : "6%",
        align: "center",
        render: (text, row, index) => {
          if (item.key === "influencer") {
            return {
              children: (
                <React.Fragment>
                  <Space
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleInfluencerDrawer(
                        row.socialMediaId,
                        row?.influencerData.platform,
                        row?.deliverableMediaId
                      );
                    }}
                  >
                    <img
                      src={row?.influencerData?.profilePicUrl}
                      alt="profile"
                      className={styles.profilePic}
                    />

                    <div>
                      <p className={styles.name}> {row.influencerData?.name}</p>
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        {row?.influencerData?.platform === "instagram" ? (
                          <AiOutlineInstagram size={15} color="#333333" />
                        ) : (
                          <AiOutlineYoutube size={15} color="#333333" />
                        )}
                        <p className={styles.username}>
                          {row?.influencerData?.username}
                        </p>
                      </div>
                    </div>
                  </Space>
                </React.Fragment>
              ),
              props: {
                rowSpan: row?.influencerSpan,
              },
            };
          } else if (item.key === "type") {
            return {
              children: (
                <React.Fragment>
                  {row?.type === "Total" ? (
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#533af5",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        handleInfluencerDrawer(
                          row.socialMediaId,
                          row?.influencerData.platform,
                          row?.deliverableMediaId
                        );
                      }}
                    >
                      {text}
                    </div>
                  ) : (
                    <Popover
                      style={{
                        cursor: "pointer",
                      }}
                      content={
                        <React.Fragment>
                          {row?.savedMedias?.length > 0 ? (
                            row?.savedMedias[0]?.mediaType === "VIDEO" ? (
                              <video
                                muted={true}
                                src={row?.savedMedias[0]?.url}
                                controls={true}
                                style={{
                                  objectFit: "cover",
                                  width: "200px",
                                  height: "283px",
                                  borderRadius: "5px",
                                }}
                                width={"100%"}
                                height={"100%"}
                              />
                            ) : (
                              <img
                                src={row?.savedMedias[0]?.url}
                                style={{
                                  width: "200px",
                                  height: "283px",
                                  borderRadius: "5px",
                                  objectFit: "cover",
                                }}
                                alt="profile"
                              />
                            )
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      }
                      trigger="hover"
                      placement="top"
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          color: "#533af5",
                          textDecoration:
                            row?.type === "Total" ? "underline" : "",
                        }}
                        onClick={() => {
                          handleInfluencerDrawer(
                            row.socialMediaId,
                            row?.influencerData.platform,
                            row?.deliverableMediaId
                          );
                        }}
                      >
                        {text}
                      </div>
                    </Popover>
                  )}
                </React.Fragment>
              ),
            };
          } else {
            return {
              children:
                Number(text) === 0
                  ? "-"
                  : Number(text) < 1000
                  ? formatData(Number(text), "0.00")
                  : formatData(Number(text), "a"),
            };
          }
        },
      };
    }
  );

  const filteredTableColumns = () => {
    if (!selectedMediaType) {
      return tableColumns;
    }

    const metricArray =
      selectedMediaType === "IG-Feed-Story"
        ? igStoryMetrics
        : selectedMediaType === "IG-Reel"
        ? igReelMetrics
        : selectedMediaType === "YT-Video"
        ? ytMetrics
        : igPostMetrics;

    let filteredColumns: { [key: string]: any }[] = [];

    metricArray.forEach((metricItem) => {
      if (
        tableColumns.findIndex(
          (tableColumnItem) => tableColumnItem.key === metricItem
        ) !== -1
      ) {
        filteredColumns = [
          ...filteredColumns,
          tableColumns.find(
            (tableColumnItem) => tableColumnItem.key === metricItem
          )!,
        ];
      }
    });
    filteredColumns = [
      tableColumns.find(
        (tableColumnItem) => tableColumnItem.key === "influencer"
      ) || [],
      tableColumns.find((tableColumnItem) => tableColumnItem.key === "type") ||
        [],
      [...filteredColumns],
      isSelectedMediaTypeTotal
        ? [
            tableColumns.find(
              (tableColumnItem) => tableColumnItem.key === "cpm"
            ) || [],
            tableColumns.find(
              (tableColumnItem) => tableColumnItem.key === "cpv"
            ) || [],
            tableColumns.find(
              (tableColumnItem) => tableColumnItem.key === "cpr"
            ) || [],
          ]
        : [],
    ].flat();

    return filteredColumns;
  };

  const handleInfluencerDrawer = (
    socialMediaId: string,
    platform: string,
    deliverableMediaId: string
  ) => {
    setInfluencerDrawProps((prev) => {
      return {
        campaignId: campaignId,
        socialMediaId: socialMediaId,
        platform: platform,
        deliverableMediaId: deliverableMediaId || "",
      };
    });

    setInfluencerDrawerVisible(true);
  };

  const removeTotal = (data: any) => {
    if (selectedMediaType === "") {
      return data;
    }

    const spanningColumnName = "socialMediaId";
    const filteredItems = data.filter(
      (item: any) => item.type !== "Total" && item.type === selectedMediaType
    );

    let totalTill = 1;
    let beginFrom = 0;

    try {
      for (let i = 0; i < filteredItems?.length - 1; i++) {
        if (
          filteredItems[i][spanningColumnName] ===
          filteredItems[i + 1][spanningColumnName]
        ) {
          totalTill += 1;
          filteredItems[i]["influencerSpan"] = 0;
        } else {
          filteredItems[beginFrom]["influencerSpan"] = totalTill;
          beginFrom = i + 1;
          totalTill = 1;
        }
      }
      filteredItems[beginFrom]["influencerSpan"] = totalTill;
    } catch (error) {
      return data;
    }

    return filteredItems;
  };

  const isCustomizeAvailable =
    userStateData?.data?.tier?.featureLimit?.reportCustomize || false;
  const isDownloadAvailable =
    userStateData?.data?.tier?.featureLimit?.canExportDataExcel || false;

  return (
    <div className={styles.grid}>
      <Row justify="end">
        <DisabledPopOver isDisabled={!isCustomizeAvailable}>
          <ReportButton
            disabled={!isCustomizeAvailable}
            onClick={() => setdrawer((drawer) => !drawer)}
            ghost
          >
            <Space style={{ textDecoration: "underline" }}>
              Customize Metrics
              <GiSettingsKnobs />
            </Space>
          </ReportButton>
        </DisabledPopOver>
      </Row>
      <OverViewSection />
      {isLoading ? (
        <Row justify="center" style={{ width: "100%" }}>
          <Lottie
            options={getDefaultLottieOptions(rocketAnimationData)}
            height={300}
            width={370}
          />
        </Row>
      ) : (
        <div className={styles.reportContainer}>
          <Row align="top" justify="space-between" style={{ padding: "20px" }}>
            <div>
              <p className={styles.title}>Report</p>
              {totalReportsLength > 0 && (
                <Input
                  placeholder="Search by name or username"
                  allowClear
                  style={{ borderRadius: "8px", minWidth: "300px" }}
                  size="large"
                  onPressEnter={(e) => {}}
                  onChange={(e) => {
                    setFilterBySearchOrMediaType(
                      e?.target?.value || "",
                      selectedMediaType
                    );
                  }}
                />
              )}
            </div>
            <DisabledPopOver isDisabled={!isDownloadAvailable}>
              <ReportButton
                ghost
                isOutline
                disabled={tableRow?.length === 0 || !isDownloadAvailable}
                onClick={() =>
                  exportFromJSON({
                    data: tableRow.map((item) => {
                      let temp: { [key: string]: any } = {};
                      if ("influencerData" in item) {
                        Object.keys(item["influencerData"]).forEach((key) => {
                          if (key !== "profilePicUrl") {
                            temp = {
                              ...temp,
                              [`influencer_${key}`]:
                                item["influencerData"][key],
                            };
                          }
                        });
                      }
                      temp = {
                        ...temp,
                        ...item,
                      };
                      delete temp["influencerData"];
                      delete temp["influencerSpan"];
                      delete temp["influencer"];
                      delete temp["socialMediaId"];
                      delete temp["fbMediaProductType"];
                      delete temp["savedMedias"];
                      delete temp["isInsightsAvailable"];
                      delete temp["permalink"];
                      delete temp["deliverableMediaId"];
                      delete temp["_id"];
                      delete temp["isError"];
                      delete temp["audienceRetention_lifetime"];
                      delete temp["isErrorTransformingPayload"];
                      delete temp["ytMediaType"];

                      return temp;
                    }),
                    fileName: "Report",
                    exportType: "csv",
                    beforeTableEncode: (rows) => {
                      rows.sort((a, b) => {
                        if (
                          a.fieldName.startsWith("influencer") ||
                          b.fieldName.startsWith("influencer")
                        ) {
                          return -90;
                        } else if (
                          a.fieldName === "type" ||
                          b.fieldName === "type"
                        ) {
                          return -89;
                        }
                        return 1;
                      });
                      return rows.sort((a, b) => {
                        if (a.fieldName.startsWith("influencer")) {
                          return -90;
                        } else if (a.fieldName === "type") {
                          if (b.fieldName.startsWith("influencer")) {
                            return -89;
                          } else {
                            return 1;
                          }
                        }
                        return 1;
                      });
                    },
                  })
                }
              >
                Download
              </ReportButton>
            </DisabledPopOver>
          </Row>

          {tableRow?.length === 0 ? (
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={18} style={{ marginTop: "80px" }}>
                <Lottie
                  options={getDefaultLottieOptions(animationDataEmptyReport)}
                  height={120}
                  width={300}
                />
                <div
                  style={{
                    display: "grid",
                    gap: "14px",
                    textAlign: "center",
                    margin: "40px 0",
                  }}
                >
                  <p className={globalStyles.label}>
                    Oh, this is an empty space!{" "}
                  </p>
                  <p className={globalStyles.label}>
                    {totalReportsLength > 0
                      ? ` No Influencer found by name or username of ${searchKey}`
                      : "No Media Added To Campaign"}
                  </p>
                  <p className={globalStyles.label}>
                    You can{" "}
                    <span
                      className={globalStyles.highlightedLink}
                      style={{ textDecoration: "underline" }}
                      onClick={() => setAddInfluencerModalVisible(true)}
                    >
                      Find {searchKey}
                    </span>{" "}
                    and add to campaign <br />
                    Or directly{" "}
                    <span
                      className={globalStyles.highlightedLink}
                      onClick={() => setAddInfluencerModalVisible(true)}
                      style={{ textDecoration: "underline" }}
                    >
                      Add The Report
                    </span>{" "}
                    to the campaign
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Table
              columns={filteredTableColumns()}
              dataSource={
                selectedMediaType === ""
                  ? originalData
                  : removeTotal(originalData)
              }
              scroll={{ x: filteredTableColumns().length * 200, y: 500 }}
              size="middle"
              bordered={true}
              pagination={false}
              className={styles.check}
              rowClassName={(record, index) =>
                record?.type === "Total" ? styles.darkRow : ""
              }
              id="cehc"
            />
          )}
        </div>
      )}
      <GaCard />
      <FilterDrawer visible={drawer} setVisible={setdrawer} />
      {/* // To change when add youtube */}
      <InfluencerDrawer
        campaignId={influencerDrawerProps.campaignId}
        setVisible={setInfluencerDrawerVisible}
        socialMediaId={influencerDrawerProps.socialMediaId}
        deliverableMediaId={influencerDrawerProps.deliverableMediaId}
        visible={influencerDrawerVisible}
        platform={influencerDrawerProps.platform}
      ></InfluencerDrawer>
      <AddInfluencerModal
        campaignId={campaignId}
        visible={addInfluencerModalVisible}
        setVisible={setAddInfluencerModalVisible}
        platform={"instagram"}
      />
    </div>
  );
};

export default ReportScreen;
