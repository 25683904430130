import { sendRequest } from "../helper";

interface postInsightRequestProps {
  socialMediaKitId: string;
  influencerId: string;
}

export const postInsightRequest = ({
  socialMediaKitId,
  influencerId,
}: postInsightRequestProps) => {
  return sendRequest(
    "post",
    `/brand/brand-influencer/media-kit-insights-request`,
    false,
    {
      socialMediaKitId,
      influencerId,
    }
  ).then((res) => res.data);
};
