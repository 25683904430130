import {
  checkConversation,
  createConversation,
  getOldMessages,
  getAllConversations,
} from "../handler/chat";

import { useUserAuth } from "./userAuthcontext";
import { useState, createContext, useContext } from "react";
import { useEffect } from "react";
import {
  useInfiniteQuery,
  InfiniteQueryObserverResult,
  useQueryClient,
} from "react-query";
import { io, Socket } from "socket.io-client";
import Cookies from "universal-cookie";

import useSound from "use-sound";

import messageSound from "../assets/sounds/messageSound.mp3";
import { Message } from "../typings";

let socket: Socket | null = null;

type ChatContextState = {
  influencerId: string;
  conversationId: string;
  setInfluencerId: React.Dispatch<React.SetStateAction<string>>;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isLoading: boolean;
  isFirst: boolean;
  fetchNextPage: () => Promise<
    InfiniteQueryObserverResult<
      {
        status: string;
        message: string;
        data: {
          messages: Message[];
          nextPageToken: string | null;
        };
      },
      unknown
    >
  >;
  hasNextPage: boolean | undefined;
  handleMessageSend: (
    val: string,
    type: string,
    mediaUrl?: string,
    mediaType?: string,
    mediaName?: string
  ) => {};
  newMessageLoading: boolean | null;
  setNewMessageLoading: React.Dispatch<React.SetStateAction<boolean | null>>;
  searchCreator: string;
  setSearchCreator: React.Dispatch<React.SetStateAction<string>>;
  noConversation: boolean;
  setNoConversation: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatContext = createContext<ChatContextState | null>(null);

interface Props {
  children: React.ReactNode;
}

export const ChatContextProvider: React.FC<Props> = ({ children }) => {
  const cookies = new Cookies();
  const [influencerId, setInfluencerId] = useState<string>("");
  const [conversationId, setConversationId] = useState<string>("");
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const { currentUser } = useUserAuth();
  const [newMessageLoading, setNewMessageLoading] = useState<boolean | null>(
    false
  );
  const [searchCreator, setSearchCreator] = useState<string>("");
  const [noConversation, setNoConversation] = useState<boolean>(false);
  //query to get old messages
  const [getOldMessagesKey, getOldMessagesFn] = getOldMessages(conversationId);
  const [getAllConversationsKey] = getAllConversations(searchCreator);
  const queryClient = useQueryClient();

  const {
    data: conversationData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(getOldMessagesKey, getOldMessagesFn, {
    enabled: conversationId ? true : false,
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });
  const [play] = useSound(messageSound);

  function playAudio() {
    console.log("audio played");
    // play({ volume: 1, forceSoundEnabled: true, soundEnabled: true });
    play({ forceSoundEnabled: true });
  }

  // ------------------ Conversation ------------------
  //First useEffect getting conersationId and handling isFirst
  useEffect(() => {
    console.log(conversationId, influencerId);
    try {
      const checkConvo = async () => {
        //if we get conversation id chat exists
        if (conversationId) {
          setIsFirst(false);
          return null;
        }
        //if we get brand id only we check if there is any conversation with that brand
        else {
          const response = await checkConversation({
            userId: influencerId,
          });

          //if we dont get a conversation its first time chat
          if (!response.data.conversationId) {
            setIsFirst(true);

            return null;
          }
          //if we get a conversation we set the conversation id
          setIsFirst(false);
          setConversationId(response.data.conversationId);
          return null;
        }
      };
      if (influencerId) checkConvo();
    } catch (err) {
      console.log(err);
    }
  }, [influencerId, conversationId]);

  //------------------ Socket ------------------

  //Second useEffect handling socket connection
  useEffect(() => {
    console.log("socket connection");

    const connectSocket = async () => {
      if (!conversationId) return null;
      if (socket?.connected) return null;
      const token = await currentUser?.getIdToken();

      let headers: any = {
        Authorization: `Bearer ${token}`,
      };
      if (cookies.get("brandId")) {
        headers = {
          ...headers,
          orgId: cookies.get("brandId"),
        };
      }

      socket = io(
        `${process.env.REACT_APP_CHAT_URL}/conversation?conversationId=${conversationId}`,
        {
          extraHeaders: headers,
          forceNew: true,
        }
      );

      //on new message
      socket.on("new-message", (...args) => {
        setMessages((messages) => [args[0], ...messages]);
      });

      //Error while connecting
      socket.on("connect_error", (...args) => {
        socket?.close();
        connectSocket();
      });

      socket.on("send-message-sent", (...args) => {
        //Event for message that is sent by current socket (current user)
        playAudio();
        handleSentMessage(args[0]);
      });

      //When socket disconnects after connection
      socket.on("disconnect", (reason) => {
        socket?.close();
        if (reason === "io server disconnect") {
          connectSocket();
        }
      });
    };

    connectSocket();

    return () => {
      console.log("disconnecting");
      if (socket) {
        socket.close();
      }
    };
  }, [conversationId]);

  // ------------------ Messages ------------------
  //If its not first time chat and loading of conversation is done old messages are fetched in parallel

  useEffect(() => {
    if (!isLoading && !isFirst && conversationData) {
      const oldMessages = conversationData.pages.map(
        (page) => page.data.messages
      );
      setMessages(oldMessages.flat());
    }
  }, [isLoading, conversationData, isFirst]);

  // ------------------ Conversation ------------------
  // Check for new messages while changing tile
  useEffect(() => {
    if (conversationId !== "") {
      queryClient.invalidateQueries(getAllConversationsKey);
      setIsFirst(false);
    }
  }, [conversationId]);

  // ------------------ Sending Messages ------------------

  const handleMessageSend = async (
    val: string,
    type: string,
    mediaUrl?: string,
    mediaType?: string,
    mediaName?: string
  ) => {
    queryClient.invalidateQueries(getAllConversationsKey);
    if (isFirst) return handleFirstMessage(val);
    console.log("mediaName", mediaName);
    //using a local id to identify the message when sent and when recieved
    const localId = Math.random().toString();
    //Locally set message with type as sending
    setMessages((messages) => [
      {
        sending: true,
        isDeleted: false,
        conversationId: conversationId,
        author: cookies.get("brandId") || currentUser?.uid || "",
        createDateTime: new Date().toISOString(),
        data: {
          localId: localId,
          type: type,
          msg: val,
          mediaUrl,
          mediaType,
          mediaName,
          cardMessage: { normal: "", blue: "" },
        },
        __v: 0,
        _id: localId,
      },
      ...messages,
    ]);

    try {
      socket?.emit("send-message", {
        localId: localId,
        type: type,
        msg: val,
        mediaUrl,
        mediaType,
        mediaName,
      });
      //Causing issue as it rerenders parent component
      //Used to render all conversations
      // queryClient.invalidateQueries(getAllConversationsKey);
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  // ------------------ Handling Sent Message ------------------
  const handleSentMessage = async (args: any) => {
    await sleep(200);
    setMessages((messages) => {
      let tempMesssages = messages;
      tempMesssages.forEach((message, index) => {
        if (message.data.localId === args.data.localId) {
          tempMesssages[index].sending = false;
        }
      });
      return [...tempMesssages];
    });
    return null;
  };

  // ------------------ Handling First Message Sent  ------------------
  //Case when there is no conversationId is created

  const handleFirstMessage = async (val: string) => {
    try {
      await createConversation({ message: val, userId: influencerId }).then(
        (response) => {
          if (response.data.conversationId) {
            setConversationId(response.data.conversationId);
          }
        }
      );
    } catch (error) {
      console.log("err", error);
    }
  };

  const value = {
    influencerId,
    conversationId,
    setInfluencerId,
    setConversationId,
    messages,
    isLoading,
    isFirst,
    fetchNextPage,
    hasNextPage,
    handleMessageSend,
    newMessageLoading,
    setNewMessageLoading,
    searchCreator,
    setSearchCreator,
    noConversation,
    setNoConversation,
    setMessages,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
  const chatContext = useContext(ChatContext);
  if (!chatContext)
    throw new Error(
      "useChatContext must be used within an ChatContextProvider"
    );
  return chatContext;
};

function sleep(ms?: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
