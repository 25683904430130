import React, { useState } from "react";
import { Menu, Input } from "antd";

import { SearchQuery } from "../index";

const { Search } = Input;

interface HeaderProps {
  searchQuery: SearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
}

const Header: React.FC<HeaderProps> = ({ searchQuery, setSearchQuery }) => {
  const [searchText, setSearchText] = useState<string>(searchQuery.text);
  return (
    <Menu
      defaultSelectedKeys={["transactions"]}
      selectedKeys={[searchQuery.type]}
      mode="horizontal"
      style={{ position: "relative" }}
      onSelect={(e) => {
        setSearchQuery((searchQuery) => {
          return { ...searchQuery, type: e.key as SearchQuery["type"] };
        });
      }}
    >
      <Menu.Item key={"transactions"}> Transactions</Menu.Item>
      <Menu.Item key={"invoices"}> Invoices</Menu.Item>
      <Menu.Item key={"creditNote"}> Credit Notes</Menu.Item>
      <Menu.Item key={"billingSettings"}> Billing Settings</Menu.Item>

      <div style={{ position: "absolute", right: 0 }}>
        <Search
          size="middle"
          placeholder="Search by name, ID sth sth"
          style={{ borderRadius: "8px", width: "260px" }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={(value) => {
            setSearchQuery((searchQuery) => {
              return { ...searchQuery, text: value };
            });
          }}
        />
      </div>
    </Menu>
  );
};

export default Header;
