import React from "react";
import { useQuery } from "react-query";

import { getUser } from "../../../handler/getUser";

import Influencers from "./Influencers";
import GlobalCreate from "./GlobalCreate";

const Network = () => {
  const [userKey, userFn] = getUser();

  const { data } = useQuery(userKey, userFn);

  return Boolean(data?.data?.creatorConnectLinkSlug) ? (
    <Influencers slug={data?.data?.creatorConnectLinkSlug} />
  ) : (
    <GlobalCreate />
  );
};
export default Network;
