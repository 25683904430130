import React, { useState } from "react";
import prependHttp from "prepend-http";
import { Radio, Row, Col, Button, List, Typography, message } from "antd";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import ProductModal from "../../Create/AddProduct/Modal";
import { CustomTextArea } from "../../../shared/Custom/Input";

import { useCampaign } from "../Context/campaignContext";
import { deleteProduct } from "../../../../handler/campaign";

import styles from "../styles.module.css";
import productStyles from "./styles.module.css";

import { Product } from "../../../../typings";

const Products = () => {
  const { campaign, setCampaign, error } = useCampaign();

  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);

  const deleteProductMutation = useMutation(deleteProduct, {
    onSuccess: (response) => {
      setCampaign((campaign) => ({
        ...campaign,
        deal: {
          ...campaign.deal,
          productDetails: response.data.deal.productDetails,
        },
      }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const { Link } = Typography;

  return (
    <div>
      <h2 className={styles.title} style={{ marginBottom: "32px" }}>
        What you are promoting?
      </h2>

      <div>
        <section>
          <h1 className={productStyles.label}>
            Will the creator need a product to shoot the content?
          </h1>

          <Radio.Group
            size="middle"
            value={campaign.deal.isProduct}
            onChange={(event) =>
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, isProduct: event.target.value },
              }))
            }
            style={{ margin: "16px 0" }}>
            <Radio value={true} className={styles.secondaryLabel}>
              Yes, there will be a product
            </Radio>
            <Radio value={false} className={styles.secondaryLabel}>
              No, they will need no products
            </Radio>
          </Radio.Group>
        </section>

        {campaign?.deal?.isProduct ? (
          <>
            <List
              style={{ marginTop: "8px", backgroundColor: "#ffffff" }}
              bordered>
              {campaign?.deal?.productDetails.map((item) => {
                return (
                  <List.Item
                    key={item._id}
                    actions={[
                      <Button
                        type="link"
                        onClick={() => {
                          setProduct(item);
                          setIsEditModalVisible(true);
                        }}>
                        Edit
                      </Button>,
                      <Button
                        type="link"
                        onClick={() =>
                          deleteProductMutation.mutate({
                            campaignId: campaign.campaign._id,
                            productId: item._id,
                          })
                        }
                        loading={deleteProductMutation.isLoading}>
                        Delete
                      </Button>,
                    ]}>
                    <List.Item.Meta
                      title={
                        <div
                          style={{
                            display: "flex",
                            gap: "6px",
                            alignContent: "center",
                          }}
                          className={styles.secondaryLabel}>
                          {item.productTitle.length > 20
                            ? item.productTitle.substring(0, 19) + "...."
                            : item.productTitle}
                          <Link
                            href={prependHttp(item.productLink)}
                            target="_blank"
                            style={{
                              display: "flex",
                              marginTop: "4px",
                            }}>
                            <img
                              src="/assets/icons/link.svg"
                              alt="link icon"
                              style={{
                                marginLeft: "4px",
                                width: "14px",
                              }}
                            />
                          </Link>
                        </div>
                      }
                    />
                    <p className={styles.secondaryLabel}>₹{item.price}</p>
                  </List.Item>
                );
              })}
            </List>
            {error?.products && (
              <p className={styles.error}>{error?.products}</p>
            )}
            <Row>
              <Button
                type="link"
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  marginTop: "12px",
                }}
                className={styles.secondaryLabel}
                onClick={() => setIsAddModalVisible(true)}>
                + Add a product
              </Button>
            </Row>

            <ProductModal
              type="Add"
              visible={isAddModalVisible}
              setIsVisible={setIsAddModalVisible}
              setProductList={(items: Product[]) => {
                setCampaign((campaign) => ({
                  ...campaign,
                  deal: { ...campaign.deal, productDetails: items },
                }));
              }}
              setProduct={setProduct}
              campaignId={campaign?.campaign?._id}
            />
            {product && (
              <ProductModal
                type="Edit"
                visible={isEditModalVisible}
                setIsVisible={setIsEditModalVisible}
                setProductList={(items: Product[]) => {
                  setCampaign((campaign) => ({
                    ...campaign,
                    deal: { ...campaign.deal, productDetails: items },
                  }));
                }}
                initialValues={product}
                setProduct={setProduct}
                campaignId={campaign?.campaign?._id}
              />
            )}
          </>
        ) : (
          <div>
            <CustomTextArea
              rows={5}
              size="small"
              label=" Describe what you are promoting"
              placeholder="Please enter the description of product you want to promote"
              value={campaign.deal.promotionDescription}
              onChange={(e) =>
                setCampaign((campaign) => ({
                  ...campaign,
                  deal: {
                    ...campaign.deal,
                    promotionDescription: e.target.value || "",
                  },
                }))
              }
            />
            {error?.promotionDescription && (
              <p className={styles.error}>{error?.promotionDescription}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
