import { sendRequest } from "../helper";
import { InstagramStories } from "../../typings";

export type getInstaStoriesResult = {
  status: string;
  message: string;
  data: {
    data: InstagramStories[];
    nextPageToken: string | null;
  };
};

export const getInstaStories = (
  socialMediaKitId: string,
  fetchFromSave = false
) => {
  const queryKey = [
    "reports",
    "influencer",
    socialMediaKitId,
    "story",
    fetchFromSave,
  ];

  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<getInstaStoriesResult>(
      "get",
      `/brand/brand-influencer/media-kit/instagram/stories?socialMediaKitId=${socialMediaKitId}&limit=3${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }&fetchFromSave=${fetchFromSave}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
