import { Modal } from "antd";
import React, { MouseEventHandler, useEffect, useState } from "react";
import clsx from "clsx";

import styles from "./../styles.module.css";

import InfluencerView from "./InfluencerView";
import ProductView from "./ProductView";
import ProductCommercial from "./ProductCommercial";

import { SassDiscoveryContextProvider } from "../../../../../SaasDiscovery/context";

type ModalComponentProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CreatorType = {
  id: string;
  name: string;
  profilePic: string;
  username: string;
  platform: "instagram" | "youtube";
} | null;

export type SelectPost = {
  url: string;
  id_: string;
  price?: string;
};

const ModalComponent: React.FC<ModalComponentProps> = (props) => {
  const { open, setOpen } = props;
  const [view, setView] = useState<"influencer" | "product" | "productPrice">(
    "influencer"
  );

  const [creator, setCreator] = useState<CreatorType>(null);
  const [selectedPosts, setSelectedPosts] = useState<SelectPost[]>([]);

  const handleCancel = () => {
    if (view === "influencer") {
      return setOpen(false);
    } else if (view === "product") {
      setView("influencer");
    } else {
      return setView("product");
    }
  };
  const handleOk = () => {
    if (view === "influencer") {
      setView("product");
    } else if (view === "product") {
      setView("productPrice");
    } else {
      if (window.confirm("Links Added!")) {
        setOpen(false);
        // They clicked Yes
      } else {
        setOpen(false);
        // They clicked no
      }
    }
  };

  const checkDisabled = () => {
    if (view === "influencer" && creator === null) {
      return true;
    }
    if (view === "product" && !selectedPosts.length) {
      return true;
    }
    if (
      view === "productPrice" &&
      selectedPosts.findIndex((item) => {
        if (item.price && Number(item.price) <= 0) return true;
        return false;
      }) !== -1
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!open) {
      setView("influencer");
      setCreator(null);
      setSelectedPosts([]);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Modal
        destroyOnClose
        className={clsx(styles.modal, "custom-modal")}
        title="Add to Report"
        centered
        maskClosable={false}
        closable={false}
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={720}
        cancelButtonProps={{
          type: "primary",
          ghost: true,
          style: {
            padding: "12px 24px",
            height: "48px",
            borderRadius: "5px",
            width: "150px",
          },
        }}
        okButtonProps={{
          type: "primary",
          style: {
            padding: "12px 24px",
            height: "48px",
            borderRadius: "5px",
            width: "150px",
          },
          disabled: checkDisabled(),
        }}
        cancelText={view === "influencer" ? "Cancel" : "Back"}
        okText={view !== "productPrice" ? "Next" : "Add links "}>
        <SassDiscoveryContextProvider>
          {view === "influencer" ? (
            <InfluencerView creator={creator} setCreator={setCreator} />
          ) : view === "product" ? (
            <ProductView
              selectedPosts={selectedPosts}
              setSelectedPosts={setSelectedPosts}
              creator={creator}
              platform="instagram"
            />
          ) : (
            <ProductCommercial
              creator={creator}
              selectedPosts={selectedPosts}
              setSelectedPosts={setSelectedPosts}
            />
          )}
        </SassDiscoveryContextProvider>
      </Modal>
    </React.Fragment>
  );
};
export default ModalComponent;
