import React, { useState, useEffect } from "react";
import { Row, Col, Button, InputNumber, message } from "antd";
import { useIsMutating, useMutation, useQueryClient } from "react-query";
// import clsx from "clsx";
import { Navigate, useNavigate } from "react-router-dom";

import { useUserAuth } from "../../../context/userAuthcontext";
// import { logBackend } from "../../../handler/logBackend";
import { differenceInMinutes, isBefore } from "date-fns";

import CustomInput from "../../shared/Custom/Input";
// import ReportButton from "../../Campaign/Report/ReportButton";

import styles from "../Onboarding1.module.css";
import { sendOTP } from "../../../handler/auth/sendOTP";
import { signInBackend } from "../../../handler/login";

import { auth } from "../../../firebase";

const VerifyMail1: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const [otp, setotp] = useState<string>("");
  const [showOTPBox, setShowOTPBox] = useState(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showTouched, setShowTouched] = useState<boolean>(false);
  // const [loading, setLoading] = useState(false);

  const { currentUser, logout } = useUserAuth();

  const sendOTPMutation = useMutation(sendOTP, {
    onSuccess: () => {
      setDisabled(true);
      setTimeout(() => setDisabled(false), 60000);
      message.success("OTP sent");
    },
    onError: (err) => {
      console.log(err);
      message.error("Some Error Ocurred");
    },
  });

  const verifyOTPMutation = useMutation(signInBackend, {
    onSuccess: async () => {
      await auth.currentUser?.reload();
      queryClient.invalidateQueries();
      // navigate("/activate");
    },
    onError: (err) => {
      console.log(err);
      message.error("Invalid OTP");
    },
  });

  useEffect(() => {
    if (currentUser?.metadata?.lastSignInTime && showOTPBox) {
      const firebaseLastSignTime = new Date(
        currentUser?.metadata?.lastSignInTime || ""
      );

      const currentTime = new Date();

      const timeDifference = differenceInMinutes(
        currentTime,
        firebaseLastSignTime
      );

      const isBeforeBy15Minutes =
        isBefore(firebaseLastSignTime, currentTime) && timeDifference <= 15;
      // console.log(isBeforeBy15Minutes);
      if (!isBeforeBy15Minutes) {
        return setShowOTPBox(false);
      }

      // verifyEmail().catch((err) => console.log("firebase mail err", err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.metadata?.lastSignInTime]);

  if (currentUser?.emailVerified) {
    return <Navigate to="/discover" />;
  }

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div className={styles.container__verify}>
        <Row justify="center">
          <Col sm={10} xs={22} md={6} style={{ textAlign: "center" }}>
            <Row justify="center">
              <img
                src="/assets/img/logo.png"
                alt="logo"
                style={{ width: "160px", height: "24px", marginBottom: "70px" }}
              />
            </Row>
            <h1 className={styles.modalTitle} style={{ marginBottom: "4px" }}>
              Verify your email
            </h1>
            <p className={styles.modalDesc}>
              Confirm your identity by{" "}
              {showOTPBox ? "confirming otp sent to " : "sending OTP to "}
              <b>{currentUser.email} </b>
            </p>
            <div
              onClick={logout}
              className={styles.resend}
              style={{ marginTop: "4px" }}>
              Change Email
            </div>
            <section style={{ marginTop: "48px" }}>
              <Row justify="center" gutter={[20, 20]}>
                {showOTPBox && (
                  <Col span={24}>
                    <CustomInput
                      label="Enter OTP"
                      labelClassName={styles.otpLabel}
                      error={
                        showTouched && otp.length !== 5
                          ? "INVALID OTP"
                          : undefined
                      }
                      errorClassName={styles.otpLabel}
                      value={otp}
                      onChange={(e) => setotp(e.target.value || "")}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px",
                      }}>
                      <span className={styles.tooltip}>
                        Didn’t receive the OTP?
                      </span>
                      {disabled || sendOTPMutation.isLoading ? (
                        <span className={styles.disabled}>Send Again</span>
                      ) : (
                        <span
                          onClick={() => sendOTPMutation.mutate()}
                          className={styles.resend}>
                          Send again
                        </span>
                      )}
                    </div>
                  </Col>
                )}
                <Col span={24} style={{ width: "100%" }}>
                  <Button
                    onClick={() => {
                      if (showOTPBox) {
                        if (otp.trim().length === 5)
                          return verifyOTPMutation.mutate(otp);
                        return setShowTouched(true);
                      }

                      sendOTPMutation.mutate();
                      return setShowOTPBox(true);
                    }}
                    type="primary"
                    block
                    size="large"
                    style={{ borderRadius: 8 }}
                    // disabled={showOTPBox && otp.trim().length !== 5}
                    loading={verifyOTPMutation.isLoading}>
                    {" "}
                    Continue
                  </Button>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyMail1;
