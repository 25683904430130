import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { TiDelete } from "react-icons/ti";
import { FiPlus, FiMinus } from "react-icons/fi";

import { ConfigTypeSingle } from "../../../typings/CreatorSearch";

import { getFacetValues } from "../../../handler/insightIq";

import { Spin, Typography, Select, Slider, Space, Button } from "antd";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import HelpPopOver from "./HelpPopover";
import { updateObjectAtIndex } from "../../../utils/updateObjectAtIndex";

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}

const { Paragraph, Title } = Typography;

const CustomMultiSelect: React.FC<Props> = ({
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
  maxCount,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);
  const [query, setQuery] = useState<{
    query: string;
  }>({ query: "" });
  const { searchParams, setSearchParams, platformId } =
    useCreatorSearchContext();

  const [getFacetKey, getFaceValue] = getFacetValues({
    facetName: `${facetName}`,
    body: {},
    query: query.query,
    work_platform_id: platformId,
  });

  const { data, isLoading } = useQuery(getFacetKey, getFaceValue, {
    enabled: visible && isSearchable,
  });

  useEffect(() => {
    if (!visible) {
      setQuery({ query: "" });
    }
  }, [visible]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getFacetName = (id: string) => {
    return data?.data?.find((item) => item.value === id)?.name;
  };

  let options = (data?.data || []).map((d) => ({
    value: d.value,
    label: d.name,
  }));
  let optionKeys = options?.map((item) => item.value);

  if (type !== "tags" && searchParams[facetName]) {
    if (Array.isArray(searchParams[facetName])) {
      searchParams[facetName]?.forEach((sItem: any, idx: number) => {
        if (!optionKeys?.includes(sItem?.value)) {
          options = [
            ...options,
            {
              value: sItem?.value,
              label: searchParams["filterTags"][facetName][idx]?.value,
            },
          ];
        }
      });
    } else {
      if (
        searchParams[facetName] &&
        !optionKeys?.includes(searchParams[facetName])
      ) {
        options = [
          ...options,
          {
            value: searchParams[facetName]?.value,
            label: searchParams[facetName]?.value,
          },
        ];
      }
    }
  }

  return (
    <div style={{ display: "grid", width: "228px", gap: "5px" }}>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <>
          <Select
            size={!isOnSearch ? undefined : "large"}
            showSearch={isSearchable}
            maxTagCount={"responsive"}
            placeholder={placeHolder}
            optionFilterProp="children"
            // maxCount={maxCount ? maxCount : type === "tags" ? 1 : undefined}
            mode={type === "multiple" ? "multiple" : undefined}
            onSearch={(val) =>
              setQuery((queryD) => ({ ...queryD, query: val }))
            }
            searchValue={query.query}
            onChange={(_, val) => {
              setQuery((queryD) => ({ ...queryD, query: "" }));
              let newFacetObject = {
                ...searchParams,
              };

              if (Array.isArray(val)) {
                let oldArrayKeys: string[] = (
                  (newFacetObject[facetName] || []) as Array<{
                    [key: string]: any;
                  }>
                ).map((item) => item?.value);

                let updatedArrayVal: { [key: string]: any }[] = [];
                let updatedArrayLabel: { [key: string]: any }[] = [];

                val?.forEach((valItem) => {
                  const oldIdx =
                    oldArrayKeys.findIndex((item) => item === valItem?.value) >=
                    0
                      ? oldArrayKeys.findIndex(
                          (item) => item === valItem?.value
                        )
                      : -1;

                  if (oldIdx === -1) {
                    updatedArrayVal = [
                      ...updatedArrayVal,
                      { value: valItem?.value },
                    ];
                    updatedArrayLabel = [
                      ...updatedArrayLabel,
                      { value: valItem?.label },
                    ];
                  } else {
                    updatedArrayVal = [
                      ...updatedArrayVal,
                      newFacetObject[facetName][oldIdx],
                    ];
                    updatedArrayLabel = [
                      ...updatedArrayLabel,
                      newFacetObject["filterTags"][facetName][oldIdx],
                    ];
                  }
                });

                newFacetObject[facetName] = updatedArrayVal;

                return setSearchParams((searchParams) => ({
                  ...searchParams,
                  ...newFacetObject,
                  filterTags: {
                    ...newFacetObject["filterTags"],
                    [facetName]: updatedArrayLabel,
                  },
                }));
              } else {
                newFacetObject = {
                  ...newFacetObject,
                  [facetName]: {
                    ...newFacetObject[facetName],
                    value: val?.value,
                  },
                  filterTags: {
                    ...newFacetObject["filterTags"],
                    [facetName]: {
                      ...newFacetObject[facetName],
                      value: getFacetName(val?.value),
                    },
                  },
                };
                return setSearchParams((searchParams) => ({
                  ...searchParams,
                  ...newFacetObject,
                }));
              }
            }}
            notFoundContent={isLoading ? <Spin size="small" /> : undefined}
            filterOption={filterOption}
            options={(data?.data || []).map((d) => ({
              value: d.value,
              label: d.name,
            }))}
            value={
              searchParams[facetName]
                ? type === "multiple"
                  ? searchParams[facetName]?.map((item: any, idx: number) => ({
                      value: item?.value,
                      label: searchParams["filterTags"][facetName][idx]?.value,
                    }))
                  : {
                      value: searchParams[facetName]?.value,
                      label: searchParams["filterTags"][facetName]?.value,
                    }
                : undefined
            }
          />

          {searchParams[facetName] && (
            <>
              {Array.isArray(searchParams[facetName]) ? (
                <>
                  {searchParams[facetName]?.map((arrItem: any, idx: number) => (
                    <div>
                      <div>
                        <Paragraph>
                          More than <b>{arrItem?.percentage}</b>% {title} is{" "}
                          <b>{getFacetName(arrItem?.value)}</b>
                        </Paragraph>
                        <Button
                          danger
                          type="link"
                          onClick={() => {
                            let oldFacet = searchParams[facetName] || [];
                            let oldFacetName =
                              searchParams["filterTags"][facetName] || [];
                            oldFacet = (oldFacet as Array<any>).filter(
                              (_, dIdex) => dIdex !== idx
                            );
                            oldFacetName = (oldFacetName as Array<any>).filter(
                              (_, dIdex) => dIdex !== idx
                            );
                            setSearchParams((searchParams) => ({
                              ...searchParams,
                              [facetName]: oldFacet,
                              filterTags: {
                                ...searchParams["filterTags"],
                                [facetName]: oldFacetName,
                              },
                            }));
                          }}
                          icon={<TiDelete size={18} />}
                        >
                          {/* Delete */}
                        </Button>
                      </div>
                      <Slider
                        min={0}
                        max={100}
                        onChange={(val) => {
                          let updatedArray = updateObjectAtIndex(
                            searchParams[facetName],
                            "percentage",
                            val,
                            idx
                          );
                          let updatedArrayName = updateObjectAtIndex(
                            searchParams["filterTags"][facetName],
                            "percentage",
                            val,
                            idx
                          );
                          return setSearchParams((searchParams) => ({
                            ...searchParams,
                            [facetName]: updatedArray,
                            filterTags: {
                              ...searchParams.filterTags,
                              [facetName]: updatedArrayName,
                            },
                          }));
                        }}
                        value={Number(arrItem?.percentage || 0)}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <div>
                    <Paragraph>
                      More than <b>{searchParams[facetName]?.percentage}</b>%{" "}
                      {title} is{" "}
                      <b>{getFacetName(searchParams[facetName]?.value)}</b>
                    </Paragraph>
                    <Button
                      icon={<TiDelete size={18} />}
                      danger
                      type="link"
                      onClick={() => {
                        setSearchParams((searchParams) => ({
                          ...searchParams,
                          [facetName]: undefined,
                          filterTags: {
                            ...searchParams.filterTags,
                            [facetName]: undefined,
                          },
                        }));
                      }}
                    />
                  </div>
                  <Slider
                    min={0}
                    max={100}
                    onChange={(val) => {
                      let oldFacet = searchParams[facetName];
                      let oldFacetName = searchParams["filterTags"][facetName];

                      return setSearchParams((searchParams) => ({
                        ...searchParams,
                        [facetName]: { ...oldFacet, percentage: val },
                        filterTags: {
                          ...searchParams["filterTags"],
                          [facetName]: { ...oldFacetName, percentage: val },
                        },
                      }));
                    }}
                    value={Number(searchParams[facetName]?.percentage || 0)}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CustomMultiSelect;
