import React, { useState, useEffect } from "react";
import { Modal, Input, Upload, message, Divider } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";
import { AxiosError } from "axios";

import { addLiveV2 } from "../../../../../../../../handler/campaign/applicants";
import { CardType } from "../../../../../../../Chat/ChatWindow/Message/CardTypes";

import { isLinkValid } from "../../../../../../../../utils";

interface AddLiveProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  workflowId: string;
  platform: string;
}

const AddLive: React.FC<AddLiveProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  workflowId,
  platform,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const addLiveMutation = useMutation(addLiveV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries(["chat", CardType.deliverableLiveSentType]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      //   console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any)?.message);
    },
  });

  const [inputVal, setInputVal] = useState<{
    downloadLink: string;
    urlLink: string;
  }>({ downloadLink: "", urlLink: "" });

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/workflow/${workflowId}/live/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setLoading(false);
          onSuccess && onSuccess(downloadURL);
          setInputVal((inputVal) => ({
            ...inputVal,
            downloadLink: downloadURL,
          }));
        }
      );
    },
    // accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setInputVal((inputVal) => ({
        ...inputVal,
        downloadLink: "",
      }));
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (!isVisible) {
      setInputVal({ downloadLink: "", urlLink: "" });
    }
  }, [isVisible]);

  return (
    <Modal
      title="Add Live"
      visible={isVisible}
      onOk={() => {
        if (!inputVal?.downloadLink && !inputVal?.urlLink) {
          return message.error("Please Upload a Live or enter valid link");
        }
        if (
          inputVal?.urlLink &&
          !isLinkValid(inputVal?.urlLink) &&
          !inputVal?.downloadLink
        ) {
          return message.error("Please Enter a valid Link");
        }
        addLiveMutation.mutate({
          workflowId,
          references: [
            {
              url: inputVal.downloadLink || inputVal.urlLink,
              permalink: inputVal.downloadLink || inputVal.urlLink,
              type: "link",
              actualType: "link",
              platform,
            },
          ],
        });
      }}
      onCancel={handleCancel}
      okButtonProps={{ loading: addLiveMutation.isLoading }}>
      <h4 style={{ marginBlock: 5 }}>Upload Live</h4>
      <Upload
        listType="picture-card"
        // style={{ width: "344px !important", height: "149px !important" }}
        fileList={
          inputVal.downloadLink
            ? [
                {
                  uid: inputVal.downloadLink,
                  name: "file",
                  status: "done",
                  url: inputVal.downloadLink,
                  //   type: "image/jpeg",
                },
              ]
            : []
        }
        {...UploadButtonProps}>
        {inputVal.downloadLink ? null : uploadButton}
      </Upload>
      <Divider>OR</Divider>
      <h4 style={{ marginBlock: 5 }}>Enter Link</h4>
      <Input
        value={inputVal?.urlLink}
        // style={{ width: "50%" }}
        onChange={(e) =>
          setInputVal((inptValues) => ({
            ...inptValues,
            urlLink: e.target.value || "",
          }))
        }
      />
    </Modal>
  );
};

export default AddLive;
