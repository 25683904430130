export const igPostMetrics = [
  "impressions",
  "reach",
  "video_views",
  "engagement",
  "likes",
  "comments",
  "shares",
  "saved",
  "follows",
  "profile_activity_action_type_bio_link_clicked",
  "profile_activity_action_type_call",
  "profile_activity_action_type_direction",
  "profile_activity_action_type_email",
  "profile_activity_action_type_other",
  "profile_activity_action_type_text",
  "profile_visits",
  "total_interactions",
  "timestamp",
];

export const igReelMetrics = [
  "reach",
  "likes",
  "comments",
  "shares",
  "saved",
  "total_interactions",
  "timestamp",
  "follows",
  "ig_reels_avg_watch_time",
  "ig_reels_video_view_total_time",
];
export const igStoryMetrics = [
  "impressions",
  "reach",
  "replies",
  "shares",
  "total_interactions",
  "profile_visits",
  "profile_activity_action_type_bio_link_clicked",
  "profile_activity_action_type_call",
  "profile_activity_action_type_direction",
  "profile_activity_action_type_email",
  "profile_activity_action_type_other",
  "profile_activity_action_type_text",
  "navigation_story_navigation_action_type_automatic_forward",
  "navigation_story_navigation_action_type_tap_back",
  "navigation_story_navigation_action_type_tap_exit",
  "navigation_story_navigation_action_type_tap_forward",
  "navigation_story_navigation_action_type_swipe_back",
  "navigation_story_navigation_action_type_swipe_down",
  "navigation_story_navigation_action_type_swipe_forward",
  "navigation_story_navigation_action_type_swipe_up",
  "exits",
];

export const ytMetrics = [
  "views",
  "comments",
  "likes",
  "dislikes",
  "shares",
  "estimatedMinutesWatched",
  "averageViewDuration",
  "averageViewPercentage",
  "annotationImpressions",
  "annotationClicks",
  "cardImpressions",
  "cardClicks",
  "subscribersGained",
];

export const timesArray: string[] = [
  "01:30 PM",
  "02:30 PM",
  "03:30 PM",
  "04:30 PM",
  "05:30 PM",
  "06:30 PM",
  "07:30 PM",
  "08:30 PM",
  "09:30 PM",
  "10:30 PM",
  "11:30 PM",
  "12:30 AM",
  "01:30 AM",
  "02:30 AM",
  "03:30 AM",
  "04:30 AM",
  "05:30 AM",
  "06:30 AM",
  "07:30 AM",
  "08:30 AM",
  "09:30 AM",
  "10:30 AM",
  "11:30 AM",
  "12:30 PM",
];

export * from "./pricing";
export * from "./reportConfigConstants";
