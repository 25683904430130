import { Avatar, Col, Row, Space, Typography } from "antd";
import { useCreatorPageContext } from "../ProfileAnalysis/ThirdParty/context";
import { MdVerified } from "react-icons/md";
import "./ProfileHeader.css";

const { Text, Title } = Typography;

const ProfileHeader = () => {
  const { creatorData } = useCreatorPageContext();

  if (creatorData) {
    return (
      <div className="profile-header">
        <Row
          justify="space-between"
          align="middle"
          className="profile-header-row"
        >
          <Col>
            <Space align="start" size="large">
              <Avatar
                src={creatorData?.profile?.image_url}
                alt="profile"
                shape="circle"
                size={120}
              />
              <div>
                <Title level={3} className="profile-name">
                  {creatorData?.profile?.full_name}
                  {creatorData?.profile?.is_verified && (
                    <MdVerified color="rgb(29, 155, 240)" size={18} />
                  )}
                </Title>
                <a
                  href={creatorData?.profile?.url}
                  className="profile-link"
                  target="_blank"
                >
                  <Title level={5} className="profile-username">
                    @{creatorData?.profile?.platform_username}
                    <img
                      src={creatorData?.work_platform?.logo_url}
                      alt="logo"
                      height={20}
                      width={20}
                    />
                  </Title>
                </a>
                <Text
                  type="secondary"
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    color: "black",
                  }}
                >
                  Bio:
                </Text>
                <Text type="secondary" className="profile-bio">
                  {creatorData?.profile?.introduction}
                </Text>
              </div>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
  return null;
};

export default ProfileHeader;
