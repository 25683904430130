import React from "react";
import { Rate } from "antd";

import { HeartFilled } from "@ant-design/icons";
import styles from "./reviewCard.module.css";
import { InfluencerReview } from "../../../../../typings";

const ReviewCard: React.FC<{ review: InfluencerReview }> = ({ review }) => {
  const getReviewStarCount = (val: number) => {
    return Math.floor(val) + (val - Math.floor(val) >= 0.5 ? 0.5 : 0);
  };

  return (
    <div className={styles.container}>
      <img
        src="/assets/img/test-profile.png"
        alt="profile"
        className={styles.profileImg}
      />
      <div>
        <p className={styles.pName}>{review.brand.name}</p>
        <p className={styles.bName}>{review.brand.businessDetails.name}</p>
        <Rate
          value={getReviewStarCount(review.rating)}
          disabled
          style={{ color: "#F00276", marginTop: "4px" }}
          character={
            <HeartFilled
              size={13}
              style={{
                fontSize: "70%",
              }}
            />
          }
        />
        <p className={styles.review}>{review.review}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
