import { Button, Card, Carousel, Col, Row } from "antd";
import styles from "./card.module.css";

import React, { useState } from "react";
import ReactPlayer from "react-player";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import { useQuery } from "react-query";
import { DraftCard } from "../../../../../typings";
import ApproveModal from "../../../../Campaign/Manage/Finalized/Timeline/DraftsTile/Modal/Approve";
import ChangesModal from "../../../../Campaign/Manage/Finalized/Timeline/DraftsTile/Modal/Changes";
import { format } from "date-fns";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface DraftReceivedCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    ref?: {
      deliverableId?: string;
      workflowId?: string;
      finalizedOfferId?: string;
      draftsTimeLineId?: string;
    };
    messageId: string;
    cardTimestamp?: string;
    type: string;
  };
}

const DraftReceivedCard: React.FC<DraftReceivedCardProps> = ({ item }) => {
  const [isApproveVisible, setApproveVisible] = useState<boolean>(false);
  const [isChangesModalVisible, setChangesModalVisible] =
    useState<boolean>(false);
  const [getChatCardKey, getChatCardDataFn] = getChatCardData({
    ref: item.ref,
    messageId: item.messageId,
    type: item.type,
  });

  const { data: cardData } = useQuery(getChatCardKey, getChatCardDataFn);

  return (
    <>
      {cardData && (
        <>
          {(cardData?.data as DraftCard).isRequestedChanges ||
          (cardData?.data as DraftCard).isApproved ? (
            <div className={styles.draft}>
              <div className={styles.draftHeading}>
                <p style={{ fontWeight: 600, fontSize: "14px" }}>
                  {item.cardName}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.6",
                    }}>
                    {item.cardTitle}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        opacity: "0.6",
                      }}>
                      {item.cardTitle}
                    </p>
                    <p
                      style={{
                        fontSize: "10px",
                      }}>
                      {format(new Date(item.cardTimestamp as string), "hh:mm")}
                    </p>
                  </div>
                </div>
              </div>
              {/* <h3 className={styles.draftHeading}>{item.cardName}</h3> */}
              <Slider
                className="slider"
                speed={500}
                slidesToScroll={1}
                infinite={false}
                dots={false}>
                {(cardData?.data as DraftCard).draft.references.map(
                  (refe, i) => (
                    <>
                      {refe.type === "video" ? (
                        <div className={styles.video}>
                          {refe.platform === "youtube" ? (
                            <ReactPlayer
                              controls={true}
                              light={true}
                              url={refe.url}
                              volume={1}
                              width="100%"
                              height="100%"
                              onReady={() => console.log("ready now")}
                            />
                          ) : (
                            <video
                              muted={true}
                              src={refe.url}
                              controls={true}
                              style={{ objectFit: "cover" }}
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </div>
                      ) : (
                        <div className={styles.image}>
                          <img
                            className={styles.draftImage}
                            src={refe.url}
                            alt=""
                          />
                        </div>
                      )}
                    </>
                  )
                )}
              </Slider>
              <div className={styles.draftApproved}>
                {(cardData?.data as DraftCard).isRequestedChanges && (
                  <Button
                    disabled
                    size="small"
                    style={{
                      padding: "0px 7px",
                    }}>
                    Changes Requested
                  </Button>
                )}

                {(cardData?.data as DraftCard).isApproved && (
                  <Button
                    disabled
                    size="small"
                    style={{
                      padding: "0px 7px",
                    }}>
                    Approved
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              {(cardData?.data as DraftCard).isEdited ? (
                <div className={styles.card}>
                  <Card
                    bodyStyle={{
                      fontWeight: 400,
                      padding: "12px 16px",
                    }}
                    style={{
                      borderRadius: "10px",
                      borderWidth: "",
                    }}
                    headStyle={{
                      backgroundColor: "rgba(0,0,0,0.05)",
                      borderRadius: "10px 10px 0 0",
                      padding: "0px 16px",
                    }}
                    title={
                      <div style={{ display: "grid", gap: "5px" }}>
                        <p style={{ fontWeight: 600, fontSize: "14px" }}>
                          {item.cardName}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: "12px",
                              opacity: "0.6",
                            }}>
                            {item.cardTitle}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                            }}>
                            {format(
                              new Date(item.cardTimestamp as string),
                              "hh:mm"
                            )}
                          </p>
                        </div>
                      </div>
                    }>
                    <Row>
                      <Col>Draft has been edited</Col>
                    </Row>
                  </Card>
                </div>
              ) : (
                <div className={styles.draft}>
                  <div className={styles.draftHeading}>
                    <p style={{ fontWeight: 600, fontSize: "14px" }}>
                      {item.cardName}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          opacity: "0.6",
                        }}>
                        {item.cardTitle}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            opacity: "0.6",
                          }}>
                          {item.cardTitle}
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                          }}>
                          {format(new Date(item.cardTimestamp || ""), "hh:mm")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Slider
                    className="slider"
                    speed={500}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}>
                    {(cardData?.data as DraftCard).draft.references.map(
                      (refe, i) => (
                        <>
                          {refe.type === "video" ? (
                            <div className={styles.video}>
                              {refe.platform === "youtube" ? (
                                <ReactPlayer
                                  controls={true}
                                  light={true}
                                  url={refe.url}
                                  volume={1}
                                  width="100%"
                                  height="100%"
                                  onReady={() => console.log("ready now")}
                                />
                              ) : (
                                <video
                                  muted={true}
                                  src={refe.url}
                                  controls={true}
                                  style={{ objectFit: "cover" }}
                                  width={"100%"}
                                  height={"100%"}
                                />
                              )}
                            </div>
                          ) : (
                            <div className={styles.image}>
                              <img
                                className={styles.draftImage}
                                src={refe.url}
                                alt=""
                              />
                            </div>
                          )}
                        </>
                      )
                    )}
                  </Slider>

                  <div className={styles.draftButton}>
                    <Button
                      onClick={() => setChangesModalVisible(true)}
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Request Changes
                    </Button>
                    <Button
                      onClick={() => setApproveVisible(true)}
                      type="primary"
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Approve
                    </Button>
                  </div>
                  <ApproveModal
                    isVisible={isApproveVisible}
                    handleSubmit={() => setApproveVisible(false)}
                    handleCancel={() => setApproveVisible(false)}
                    workflowId={item.ref?.workflowId as string}
                    prevDraftTimeLineId={item.ref?.draftsTimeLineId as string}
                  />
                  <ChangesModal
                    isVisible={isChangesModalVisible}
                    handleSubmit={() => setChangesModalVisible(false)}
                    handleCancel={() => setChangesModalVisible(false)}
                    workflowId={item.ref?.workflowId as string}
                    prevDraftTimeLineId={item.ref?.draftsTimeLineId as string}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}{" "}
    </>
  );
};

export default DraftReceivedCard;
