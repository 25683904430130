import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  Typography,
  Row,
  Col,
  message,
  Space,
  Alert,
  Spin,
} from "antd";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import CreateSteps from "../Steps";
import Footer from "../Footer";
import Navbar from "../../../shared/Navbar";

import { capitalizeFirstLetter } from "../../../../utils";

import YoutubeModal from "./Modal/Youtube";

import PostModal from "./Modal/Instagram/Post";
import ReelModal from "./Modal/Instagram/Reel";
import StoryModal from "./Modal/Instagram/Story";
import VideoModal from "./Modal/Instagram/Video";

import {
  deleteDeliverable,
  transDeliverable,
  getCampaign,
} from "../../../../handler/campaign";

import styles from "../Create.module.css";

import { YoutubeDel, InstaContent, InstaModal } from "./Deliverables";
import { Product, CampaignResponse } from "../../../../typings";

const Deliverables: React.FC = () => {
  let { id } = useParams();
  const [selectedPlatform, setSelectedPlatform] = useState("instagram");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [isEditEnabled, setEditEnabled] = useState<boolean>(false);

  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId: id!,
  });
  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFunction);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (campaignData) {
      setProductList(campaignData.data.deal.productDetails);
      const ytList = campaignData.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "youtube"
      );

      setYtDeliverableList(ytList as unknown as YoutubeDel[]);

      const instaList = campaignData.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "instagram"
      );
      setInstaDeliverableList((instaList as unknown as InstaContent[]) || []);
      setSelectedPlatform(campaignData.data.campaign.platform);
      setIsLoading(false);

      if (
        campaignData.data.campaign.statusHistory.findIndex(
          (item) => item.status === "Added-Diliverables"
        ) !== -1
      ) {
        setEditEnabled(true);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (isEditEnabled) {
      return setSaveEnabled(false);
    }
    return setSaveEnabled(true);
  }, [isEditEnabled]);

  const [youtubeModalVisible, setYoutubeModalVisible] = useState(false);
  const [isEditYoutubeModalVisible, setEditYoutubeModalVisible] =
    useState(false);
  const [ytDeliverableList, setYtDeliverableList] = useState<YoutubeDel[]>([]);
  const [ytDeliverable, setYtDeliverable] = useState<YoutubeDel | null>(null);

  const [instaModalVisible, setInstaModalVisible] = useState<InstaModal>({
    Reel: false,
    Story: false,
    Post: false,
    Video: false,
  });
  const [isEditInstaModalVisible, setEditInstaModalVisible] =
    useState<InstaModal>({
      Reel: false,
      Story: false,
      Post: false,
      Video: false,
    });

  const [instaDeliverableList, setInstaDeliverableList] = useState<
    InstaContent[]
  >([]);

  const [instaDeliverable, setInstaDeliverable] = useState<InstaContent | null>(
    null
  );

  const [productList, setProductList] = useState<Product[] | null>([]);

  const handleProductDelete = (deliverableID: string) => {
    deleteYtDeliverableMutation.mutate({ campaignId: id!, deliverableID });
    // const updatedList = ytDeliverableList!.filter((item) => item.id !== id);
    // setYtDeliverableList(updatedList);
  };
  const handleInstaProductDelete = (deliverableID: string) => {
    deleteInstaDeliverableMutation.mutate({ campaignId: id!, deliverableID });
    // const updatedList = instaDeliverableList!.filter((item) => item?.id !== id);
    // setInstaDeliverableList(updatedList);
  };
  const handleSubmit = () => {
    transDeliverableMutation.mutate({ campaignId: id! });
  };

  //mutations

  const deleteYtDeliverableMutation = useMutation(deleteDeliverable, {
    onSuccess: (response) => {
      const ytList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "youtube"
      );
      setYtDeliverableList((ytList as unknown as YoutubeDel[]) || []);
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  const deleteInstaDeliverableMutation = useMutation(deleteDeliverable, {
    onSuccess: (response) => {
      const instaList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "instagram"
      );
      setInstaDeliverableList((instaList as unknown as InstaContent[]) || []);
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  const transDeliverableMutation = useMutation(transDeliverable, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
      setEditEnabled(true);
      message.success("Details Updated");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  if (!id || !campaignData) {
    return null;
  }

  if (isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "50px 0" }}>
        <Col span={24}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "40px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.85)",
            }}>
            Create an{" "}
            {capitalizeFirstLetter(campaignData.data.campaign.platform)}{" "}
            Campaign
            <img
              src={`/assets/icons/${campaignData.data.campaign.platform}Icon.png`}
              alt="instagram"
              style={{ width: "30px", marginLeft: "8px" }}
            />
          </div>

          <p
            style={{
              marginTop: "8px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.45)",
            }}>
            Need assistance? Please watch this{" "}
            <span
              style={{
                color: "#2130FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}>
              video
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={2}>
          {campaignData && (
            <CreateSteps
              currentStep={2}
              statusHistory={campaignData?.data.campaign.statusHistory}
            />
          )}
        </Col>
        <Col
          span={8}
          offset={2}
          style={{
            maxHeight: "60vh",
            overflowY: "scroll",
            paddingRight: "10px",
          }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "28px",
              color: "rgba(0, 0, 0, 0.85)",
              marginBottom: "10px",
            }}>
            Add Deliverables
          </p>
          <div
            style={{
              width: "100%",
              padding: "16px 20px",
              background: "#FAFAFA",
              minHeight: "30vh",
            }}>
            <>
              <div>
                {selectedPlatform === "instagram" ? (
                  <>
                    {instaDeliverableList &&
                      Boolean(instaDeliverableList.length) && (
                        <List
                          dataSource={instaDeliverableList}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  type="link"
                                  key="list-loadmore-edit"
                                  onClick={() => {
                                    setInstaDeliverable(item);
                                    setEditInstaModalVisible((prev) => {
                                      return {
                                        ...prev,
                                        [item!.typeDel]: true,
                                      };
                                    });
                                  }}
                                  disabled={!isSaveEnabled}>
                                  Edit
                                </Button>,
                                <Button
                                  type="link"
                                  key="list-loadmore-more"
                                  onClick={() =>
                                    handleInstaProductDelete(item!._id)
                                  }
                                  disabled={!isSaveEnabled}>
                                  Delete
                                </Button>,
                              ]}>
                              <Typography.Text>
                                {item?.typeDel === "Reel" &&
                                  (item.repost
                                    ? "Repost"
                                    : item?.duration + "s")}
                                {item?.typeDel === "Video" &&
                                  (item.repost
                                    ? "Repost"
                                    : item?.duration + " minute")}
                                {item?.typeDel === "Story" &&
                                  (item.repost
                                    ? "Repost"
                                    : item?.type === "Video" &&
                                      item?.duration + "s")}
                                {item?.typeDel === "Post" &&
                                  (item.repost ? "Repost" : item?.type)}{" "}
                                {item?.typeDel}{" "}
                              </Typography.Text>
                            </List.Item>
                          )}
                        />
                      )}

                    <div
                      style={{
                        display: "flex",
                        gap: "48px",
                        marginTop: "16px",
                        justifyContent: "center",
                      }}>
                      <Button
                        type="link"
                        onClick={() =>
                          setInstaModalVisible((prev) => {
                            return { ...prev, Reel: true };
                          })
                        }
                        style={{ height: "min-content" }}
                        disabled={!isSaveEnabled}
                        onMouseOver={(event) =>
                          (event.currentTarget.style.opacity = "0.7")
                        }
                        onMouseOut={(event) =>
                          (event.currentTarget.style.opacity = "1")
                        }>
                        <img
                          src="/assets/icons/insta-reel.svg"
                          alt="reel icon"
                          width="34px"
                        />
                        <div>Reel</div>
                      </Button>
                      <Button
                        type="link"
                        onClick={() =>
                          setInstaModalVisible((prev) => {
                            return { ...prev, Story: true };
                          })
                        }
                        style={{ height: "min-content" }}
                        disabled={!isSaveEnabled}
                        onMouseOver={(event) =>
                          (event.currentTarget.style.opacity = "0.7")
                        }
                        onMouseOut={(event) =>
                          (event.currentTarget.style.opacity = "1")
                        }>
                        <img
                          src="/assets/icons/insta-story.svg"
                          alt="story icon"
                          width="34px"
                        />
                        <div>Story</div>
                      </Button>
                      <Button
                        type="link"
                        onClick={() =>
                          setInstaModalVisible((prev) => {
                            return { ...prev, Video: true };
                          })
                        }
                        style={{ height: "min-content" }}
                        disabled={!isSaveEnabled}
                        onMouseOver={(event) =>
                          (event.currentTarget.style.opacity = "0.7")
                        }
                        onMouseOut={(event) =>
                          (event.currentTarget.style.opacity = "1")
                        }>
                        <img
                          src="/assets/icons/insta-video.svg"
                          alt="video icon"
                          width="34px"
                        />
                        <div>Video</div>
                      </Button>
                      <Button
                        type="link"
                        onMouseOver={(event) =>
                          (event.currentTarget.style.opacity = "0.7")
                        }
                        onMouseOut={(event) =>
                          (event.currentTarget.style.opacity = "1")
                        }
                        onClick={() =>
                          setInstaModalVisible((prev) => {
                            return { ...prev, Post: true };
                          })
                        }
                        style={{ height: "min-content" }}
                        disabled={!isSaveEnabled}>
                        <img
                          src="/assets/icons/insta-post.svg"
                          alt="post icon"
                          width="34px"
                        />
                        <div>Post</div>
                      </Button>
                    </div>

                    <PostModal
                      type="Add"
                      visible={instaModalVisible.Post}
                      setIsVisible={setInstaModalVisible}
                      setDeliverableList={setInstaDeliverableList}
                      setDeliverable={setInstaDeliverable}
                      productList={productList}
                      campaignId={id!}
                      hasProduct={campaignData.data.deal.isProduct}
                    />

                    {instaDeliverable &&
                      instaDeliverable.typeDel === "Post" && (
                        <PostModal
                          type="Edit"
                          visible={isEditInstaModalVisible.Post}
                          setIsVisible={setEditInstaModalVisible}
                          setDeliverableList={setInstaDeliverableList}
                          setDeliverable={setInstaDeliverable}
                          productList={productList}
                          initialValues={instaDeliverable}
                          campaignId={id!}
                          hasProduct={campaignData.data.deal.isProduct}
                        />
                      )}

                    <ReelModal
                      type="Add"
                      visible={instaModalVisible.Reel}
                      setIsVisible={setInstaModalVisible}
                      setDeliverableList={setInstaDeliverableList}
                      setDeliverable={setInstaDeliverable}
                      productList={productList}
                      campaignId={id!}
                      hasProduct={campaignData.data.deal.isProduct}
                    />

                    {instaDeliverable &&
                      instaDeliverable.typeDel === "Reel" && (
                        <ReelModal
                          type="Edit"
                          visible={isEditInstaModalVisible.Reel}
                          setIsVisible={setEditInstaModalVisible}
                          setDeliverableList={setInstaDeliverableList}
                          setDeliverable={setInstaDeliverable}
                          productList={productList}
                          initialValues={instaDeliverable}
                          campaignId={id!}
                          hasProduct={campaignData.data.deal.isProduct}
                        />
                      )}

                    <StoryModal
                      type="Add"
                      visible={instaModalVisible.Story}
                      setIsVisible={setInstaModalVisible}
                      setDeliverableList={setInstaDeliverableList}
                      setDeliverable={setInstaDeliverable}
                      productList={productList}
                      campaignId={id!}
                      hasProduct={campaignData.data.deal.isProduct}
                    />

                    {instaDeliverable &&
                      instaDeliverable.typeDel === "Story" && (
                        <StoryModal
                          type="Edit"
                          visible={isEditInstaModalVisible.Story}
                          setIsVisible={setEditInstaModalVisible}
                          setDeliverableList={setInstaDeliverableList}
                          setDeliverable={setInstaDeliverable}
                          productList={productList}
                          initialValues={instaDeliverable}
                          campaignId={id!}
                          hasProduct={campaignData.data.deal.isProduct}
                        />
                      )}

                    <VideoModal
                      type="Add"
                      visible={instaModalVisible.Video}
                      setIsVisible={setInstaModalVisible}
                      setDeliverableList={setInstaDeliverableList}
                      setDeliverable={setInstaDeliverable}
                      productList={productList}
                      campaignId={id!}
                      hasProduct={campaignData.data.deal.isProduct}
                    />

                    {instaDeliverable &&
                      instaDeliverable.typeDel === "Video" && (
                        <VideoModal
                          type="Edit"
                          visible={isEditInstaModalVisible.Video}
                          setIsVisible={setEditInstaModalVisible}
                          setDeliverableList={setInstaDeliverableList}
                          setDeliverable={setInstaDeliverable}
                          productList={productList}
                          initialValues={instaDeliverable}
                          campaignId={id!}
                          hasProduct={campaignData.data.deal.isProduct}
                        />
                      )}
                  </>
                ) : (
                  <>
                    {ytDeliverableList && Boolean(ytDeliverableList.length) && (
                      <List
                        dataSource={ytDeliverableList}
                        style={{
                          marginTop: "0px",
                          backgroundColor: "#ffffff",
                          paddingLeft: "20px",
                        }}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Button
                                type="link"
                                key="list-loadmore-edit"
                                onClick={() => {
                                  setYtDeliverable(item);
                                  setEditYoutubeModalVisible(true);
                                }}
                                disabled={!isSaveEnabled}>
                                Edit
                              </Button>,
                              <Button
                                type="link"
                                key="list-loadmore-more"
                                onClick={() => handleProductDelete(item._id)}
                                disabled={!isSaveEnabled}>
                                Delete
                              </Button>,
                            ]}>
                            <Typography.Text
                              style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                              {item.duration} minute {item.type} Video
                            </Typography.Text>
                          </List.Item>
                        )}
                      />
                    )}
                    <Row justify="center">
                      <Button
                        type="text"
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          marginTop: "10px",
                          color: "rgba(0, 0, 0, 0.45)",
                        }}
                        onClick={() => setYoutubeModalVisible(true)}
                        // icon={
                        //   <img
                        //     src="/assets/icons/plus.svg"
                        //     alt="plus icon"
                        //     style={{
                        //       margin: "auto 0",
                        //       paddingRight: "10px",
                        //     }}
                        //   />
                        // }
                        disabled={!isSaveEnabled}>
                        + Add Deliverable
                      </Button>
                    </Row>

                    <YoutubeModal
                      type="Add"
                      visible={youtubeModalVisible}
                      setIsVisible={setYoutubeModalVisible}
                      setDeliverableList={setYtDeliverableList}
                      setDeliverable={setYtDeliverable}
                      productList={productList}
                      campaignId={id!}
                      hasProduct={campaignData.data.deal.isProduct}
                    />

                    {ytDeliverable && (
                      <YoutubeModal
                        type="Edit"
                        visible={isEditYoutubeModalVisible}
                        setIsVisible={setEditYoutubeModalVisible}
                        setDeliverableList={setYtDeliverableList}
                        setDeliverable={setYtDeliverable}
                        productList={productList}
                        initialValues={ytDeliverable}
                        campaignId={id!}
                        hasProduct={campaignData.data.deal.isProduct}
                      />
                    )}
                  </>
                )}
                <Space>
                  <Button
                    type="primary"
                    size="large"
                    title="Save"
                    style={{ width: "144px", marginTop: "32px" }}
                    htmlType="submit"
                    disabled={
                      isSaveEnabled
                        ? !(
                            instaDeliverableList?.length ||
                            ytDeliverableList?.length
                          )
                        : true
                    }
                    loading={transDeliverableMutation.isLoading}
                    onClick={handleSubmit}>
                    Save
                  </Button>
                  {campaignData?.data.campaign.statusHistory.findIndex(
                    (item) => item.status === "Active"
                  ) === -1 && (
                    <Button
                      type="text"
                      size="large"
                      title="Next"
                      style={{ width: "144px", marginTop: "32px" }}
                      onClick={() => setEditEnabled(false)}
                      disabled={!isEditEnabled}>
                      Edit
                    </Button>
                  )}
                </Space>
              </div>
            </>
          </div>
        </Col>
        <Col
          style={{ overflowY: "auto", maxHeight: "47vh" }}
          offset={1}
          span={6}>
          {campaignData?.data.campaign.status === "Needs-Attention" && (
            <Alert type="error" message={campaignData?.data.campaign.remarks} />
          )}
        </Col>
      </Row>
      <Footer
        onNext={() => {
          navigate(`/campaign/create/${id}/creators`);
        }}
        onBack={() => {
          navigate(`/campaign/create/${id}/add-products`);
        }}
        isNextDisabled={
          !campaignData
            ? true
            : isSaveEnabled
            ? true
            : campaignData.data.campaign.statusHistory.findIndex(
                (item) => item.status === "Added-Diliverables"
              ) === -1
        }
      />
    </div>
  );
};

export default Deliverables;
