import { sendRequest } from "../../helper";

type OfferSummary = {
  message: string;
  status: string;
  data: { count: number };
};

export const getCompletedOfferCountV2 = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  const queryKey = ["completed-campaign-count", campaignId, "v2"];

  const queryFunction = () =>
    sendRequest<OfferSummary>(
      "put",
      `/brand/execution/completed/completed-search-count`,
      false,
      {
        campaignId,
      },
      undefined,
      true
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
