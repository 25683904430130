import React from "react";
import { Radio } from "antd";
import { useCampaign } from "../Context/campaignContext";

import { CustomTextArea } from "../../../shared/Custom/Input";

import styles from "../styles.module.css";
import creatorDetailStyles from "../CreatorDetails/styles.module.css";

const Guidelines = () => {
  const { campaign, setCampaign, error } = useCampaign();

  return (
    <div>
      <h2 className={styles.title} style={{ marginBottom: 0 }}>
        General Brand Guidelines
      </h2>
      <p className={styles.subHeading} style={{ marginBottom: "15px" }}>
        Anything that the creators must keep in mind, no matter what content
        they’re creating
      </p>
      <CustomTextArea
        value={campaign.campaign.brandGuidelines}
        autoSize={{ minRows: 6 }}
        showCount={{
          formatter: (args) => `${args.count}/${args.maxLength}`,
        }}
        maxLength={1000}
        size="large"
        placeholder={"Brand Guidelines"}
        onChange={(e) =>
          setCampaign((campaign) => ({
            ...campaign,
            campaign: {
              ...campaign.campaign,
              brandGuidelines: e.target.value || "",
            },
          }))
        }
        rows={6}
      />
      <div style={{ margin: "32px 0" }}>
        <p className={creatorDetailStyles.label}>Campaign Type:</p>
        {error?.cType && <p className={styles.error}>{error?.cType}</p>}
        <Radio.Group
          style={{ width: "100%", marginTop: "16px" }}
          size="middle"
          value={campaign.campaign.isPublic}
          onChange={(event) => {
            setCampaign((campaign) => ({
              ...campaign,
              campaign: { ...campaign.campaign, isPublic: event.target.value },
            }));
          }}>
          <Radio value={true} className={styles.secondaryLabel}>
            Public (Anyone can apply)
          </Radio>
          <Radio value={false} className={styles.secondaryLabel}>
            Invite-Only (Only people you invite can participate)
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default Guidelines;
