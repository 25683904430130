import { sendRequest } from "../../helper";

export type rejectOfferProps = {
  offerId: string;
  reason: string;
};

export const rejectOffer = ({ offerId, reason }: rejectOfferProps) => {
  return sendRequest(
    "patch",
    `/brand/campaign/offer/reject/${offerId}`,
    false,
    {
      rejectReason: reason,
    }
  ).then((res) => res.data);
};
