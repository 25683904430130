import { Modal, ModalProps, InputNumber, Form, Input, message } from "antd";
import React from "react";
import { useQueryClient, useMutation } from "react-query";

import styles from "../../Create.module.css";

import { postProduct, updateProduct } from "../../../../../handler/campaign";

import { CampaignResponse, Product } from "../../../../../typings";
import { AxiosError } from "axios";

interface UpdatedModalProps extends ModalProps {
  initialValues?: Product;
  type: "Add" | "Edit";
  setProductList: (productList: Product[]) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  campaignId: string;
  modalFor?: "Campaign" | "Deal";
}

const ProductModal: React.FC<UpdatedModalProps> = ({
  visible,
  initialValues = {
    productTitle: "",
    price: "",
    productLink: "",
    _id: "",
  },
  setProductList,
  setIsVisible,
  type,
  setProduct,
  campaignId,
  modalFor = "Campaign",
}) => {
  const queryClient = useQueryClient();
  const postProductMutation = useMutation(postProduct, {
    onSuccess: (response) => {
      setProductList(response.data.deal.productDetails);
      setIsVisible(false);
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const updateProductMutation = useMutation(updateProduct, {
    onSuccess: (response) => {
      setProductList(response.data.deal.productDetails);
      setIsVisible(false);
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const [form] = Form.useForm();

  const handleAddOk = () => {
    form
      .validateFields()
      .then((values) => {
        const product = {
          productTitle: values.title,
          price: values.price,
          productLink: values.link,
        };
        if (modalFor === "Campaign") {
          postProductMutation.mutate({ campaignId, product });
        } else {
          // setProductList((productList) => [
          //   ...productList,
          //   { ...product, _id: Date.now().toString() },
          // ]);
          setIsVisible(false);
          form.resetFields();
        }
      })
      .catch((err) => console.log("err", err));

    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleEditOk = () => {
    form
      .validateFields()
      .then((values) => {
        const product = {
          productTitle: values.title,
          price: values.price,
          productLink: values.link,
          _id: initialValues._id!,
        };

        if (modalFor === "Campaign") {
          updateProductMutation.mutate({ campaignId, product });
        } else {
          // setProductList((productList) => {
          //   return productList.map((item) => {
          //     if (item._id !== initialValues._id) {
          //       return item;
          //     }
          //     return product;
          //   });
          // }
          // );
          setIsVisible(false);
        }

        form.resetFields();
        setProduct(null);
      })
      .catch((err) => console.log("err", err));
    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleCancel = () => {
    form.resetFields();
    setIsVisible(false);
    setProduct(null);
  };

  return (
    <Modal
      title="Add product details"
      visible={visible}
      okText="DONE"
      onCancel={handleCancel}
      centered
      okButtonProps={{
        loading:
          updateProductMutation.isLoading || postProductMutation.isLoading,
      }}
      onOk={type === "Add" ? handleAddOk : handleEditOk}
      bodyStyle={{ maxHeight: "60vh", overflowY: "scroll" }}>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
        // {...formItemLayout}
      >
        <Form.Item
          label="Product Title"
          name="title"
          initialValue={initialValues.productTitle}
          className={styles.label}
          rules={[
            { required: true, message: "Please input your Product Title!" },
          ]}>
          <Input
            placeholder="e.g. What would you call it?"
            className={styles.input}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label="Link"
          name="link"
          initialValue={initialValues.productLink}
          className={styles.label}
          rules={[
            {
              required: true,
              message: "Please input your Product Link!",
              type: "url",
            },
          ]}>
          <Input
            placeholder="share the link of product"
            className={styles.input}
            size="large"
            type="url"
          />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          initialValue={initialValues.price}
          className={styles.label}
          rules={[
            { required: true, message: "Please input your Product Price!" },
          ]}>
          <InputNumber size="large" addonBefore="₹" type="number" min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductModal;
