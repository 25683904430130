import { sendRequest } from "../helper";

interface CreateConversationResult {
  status: string;
  message: string;
  data: { conversationId: string };
}

interface CreateConversationProps {
  userId: string;
  message: string;
}

export const createConversation = (conversation: CreateConversationProps) => {
  return sendRequest<CreateConversationResult>(
    "post",
    `/brand/chat/conversation`,
    false,
    {
      ...conversation,
      userType: "influencer",
    }
  ).then((res) => res.data);
};
