import React from "react";
import { useQuery } from "react-query";

import { getUser } from "../../../handler/getUser";
import { getAppState } from "../../../handler/getAppState";

import ReportButton from "../../Campaign/Report/ReportButton";
import DisabledPopOver from "../../shared/DisabledPopOver";

import { auth } from "../../../firebase";

import styles from "./home.module.css";

type HomeProps = {
  setScreen: React.Dispatch<React.SetStateAction<"home" | "pay">>;
};

const Home: React.FC<HomeProps> = (props) => {
  const { setScreen } = props;

  const [userKey, userFn] = getUser();
  const [userStateKey, userStateFn] = getAppState();
  const { data } = useQuery(userStateKey, userStateFn);

  const { data: userData } = useQuery(userKey, userFn);

  const hasAccess = userData?.data?._id === auth?.currentUser?.uid;

  return (
    <div className={styles.home}>
      <div className={styles.container}>
        <div className={styles.child1}>
          <div className={styles.oval} />
          <div>
            <div className={styles.block}>
              <img
                src={userData?.data?.businessDetails?.logoUrl}
                alt="logo"
                width={30}
                height={30}
                style={{ objectFit: "cover", borderRadius: "50%" }}
              />
              <div>
                <p className={styles.blocktitle}>
                  Account Blocked - Payment Due
                </p>
                <p className={styles.blockdesc}>
                  You are missing out on premium features
                </p>
              </div>
            </div>
            <div className={styles.content}>
              <h5 className={styles.pro}> {data?.data?.tier?.planName} Plan</h5>
              <div className={styles.gutter}>
                <h6 className={styles.credits}>
                  {data?.data?.tier?.featureLimit?.community?.maxLimit}{" "}
                  Community Credits /{" "}
                  {data?.data?.tier?.featureLimit?.addMedia?.maxLimit} Media
                  Credits
                </h6>
                {/* <p className={styles.creditDesc}>
                  Users with Pro plan receive over
                  <br /> 750 monthly credits
                </p> */}
                <DisabledPopOver
                  isDisabled={!hasAccess}
                  customText="You Need to Contact this Org Admin for Payment">
                  <ReportButton
                    onClick={() => setScreen("pay")}
                    disabled={!hasAccess}
                    style={{
                      borderRadius: 4,
                      border: "1px solid #533AF5",
                      background: "#533AF5",
                      color: "#fff",
                      width: "300px",
                      padding: "8px 24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      marginTop: 20,
                    }}>
                    Reactivate Now
                    <img src="/assets/icons/arrow.svg" alt="Arrow" />
                  </ReportButton>
                </DisabledPopOver>
                <p className={styles.misc}>
                  Pay as little as {data?.data?.currencySymbol}
                  {
                    data?.data?.tier?.price[data?.data?.isIndia ? "inr" : "usd"]
                  }{" "}
                  / {data?.data?.tier?.cycle}
                </p>
                <p className={styles.misc2}>Cancel Anytime</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child2}>
          <div className={styles.child2Container}>
            <img
              src={"/assets/img/lock-acc.png"}
              alt="lock"
              style={{
                display: "block",
                marginInline: "auto",
                marginBottom: 50,
              }}
            />
            <div>
              <h6 className={styles.title}>
                Your account is currently blocked due to an outstanding payment
              </h6>
              <p className={styles.desc}>
                Payment attempts have been made, but they were unsuccessful.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
