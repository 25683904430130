import { createContext, useContext, useState } from "react";

type ContextState = {
  showMetrics: {
    showMorePosts: boolean;
    showMoreMetrics: boolean;
    showCommercials: boolean;
    showPhoneNumber: boolean;
  };
  setShowMetrics: React.Dispatch<
    React.SetStateAction<{
      showMorePosts: boolean;
      showMoreMetrics: boolean;
      showCommercials: boolean;
      showPhoneNumber: boolean;
    }>
  >;
  selectedCreatorList: string[];
  setSelectedCreatorList: React.Dispatch<React.SetStateAction<string[]>>;
  platform: "youtube" | "instagram";
  setPlatform: React.Dispatch<React.SetStateAction<"youtube" | "instagram">>;
};

const SassDiscoveryContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const SassDiscoveryContextProvider: React.FC<Props> = ({ children }) => {
  const [showMetrics, setShowMetrics] = useState<{
    showMorePosts: boolean;
    showMoreMetrics: boolean;
    showCommercials: boolean;
    showPhoneNumber: boolean;
  }>({
    showCommercials: false,
    showMoreMetrics: false,
    showMorePosts: false,
    showPhoneNumber: false,
  });
  const [selectedCreatorList, setSelectedCreatorList] = useState<string[]>([]);
  const [platform, setPlatform] = useState<"youtube" | "instagram">(
    "instagram"
  );

  return (
    <SassDiscoveryContext.Provider
      value={{
        showMetrics,
        setShowMetrics,
        selectedCreatorList,
        setSelectedCreatorList,
        platform,
        setPlatform,
      }}>
      {children}
    </SassDiscoveryContext.Provider>
  );
};

export const useSaasDiscoveryContext = () => {
  const context = useContext(SassDiscoveryContext);

  if (!context) {
    throw new Error(
      "useSaasDiscoveryContext must be used in <SassDiscoveryContextProvider />"
    );
  }

  return context;
};
