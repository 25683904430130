import { Button, Card, Col, Row, Space } from "antd";
import { format } from "date-fns";
import React from "react";
import { useQuery } from "react-query";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import styles from "./card.module.css";

interface DraftApprovedCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;

    cardMessage: {
      normal: string;
      blue: string;
    };
    cardTimestamp?: string;
  };
}

const DraftApprovedCard: React.FC<DraftApprovedCardProps> = ({ item }) => {
  return (
    <div className={styles.card}>
      <Card
        bodyStyle={{
          fontWeight: 400,
          padding: "12px 16px",
        }}
        style={{
          borderRadius: "10px",
          borderWidth: "",
        }}
        headStyle={{
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: "10px 10px 0 0",
          padding: "0px 16px",
        }}
        title={
          <div style={{ display: "grid", gap: "5px" }}>
            <p style={{ fontWeight: 600, fontSize: "14px" }}>{item.cardName}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  opacity: "0.6",
                }}>
                {item.cardTitle}
              </p>
              <p
                style={{
                  fontSize: "10px",
                }}>
                {format(new Date(item.cardTimestamp as string), "hh:mm")}
              </p>
            </div>
          </div>
        }>
        <Space direction="vertical">
          <Row>
            <Col>{item.cardMessage.normal}</Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.lastDate}>{item.cardMessage.blue}</p>
            </Col>
          </Row>
        </Space>
      </Card>
    </div>
  );
};

export default DraftApprovedCard;
