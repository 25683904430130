import React from "react";
import { Row, Space, Col, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { BsInstagram, BsYoutube } from "react-icons/bs";

import ReportButton from "../Campaign/Report/ReportButton";

import { getUser } from "../../handler/getUser";

import styles from "./styles.module.css";
import { TSection } from ".";
import { InfluencerMedia } from "../../typings";

interface HeaderProps {
  // platform: "instagram" | "youtube";
  influencerId: string;
  name: string;
  selectedSection: TSection;
  setSelectedSection: React.Dispatch<React.SetStateAction<TSection>>;
  inDrawer: boolean;
  keys: InfluencerMedia;
  downloadPdf: () => void;
}

const Header: React.FC<HeaderProps> = ({
  // platform,
  name,
  // profilePic,
  selectedSection,
  setSelectedSection,
  influencerId,
  inDrawer,
  keys,
  downloadPdf,
}) => {
  const navigate = useNavigate();

  const igKeys = keys.filter(
    (item) => item.socialMediaPlatform === "instagram"
  );
  const ytKeys = keys.filter((item) => item.socialMediaPlatform === "youtube");

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ marginBottom: "20px" }}>
      <Space size={10}>
        <section
          style={{
            position: "relative",
            height: "50px",
            width: `calc(50px*(${keys?.length}))`,
          }}>
          {keys
            ?.filter((keyItem) => keyItem?.dataProvider === "default")
            ?.map((item, idx) => {
              return (
                <div
                  style={{
                    position: "absolute",
                    left: `calc(${idx}*40px)`,
                    zIndex: 998 - idx,
                  }}>
                  <div className={styles.profilePicContainer}>
                    <img
                      src={
                        item?.[item?.socialMediaPlatform]?.user
                          ?.profilePicUrl || ""
                      }
                      alt="profile"
                      className={styles.profilePic}
                    />
                    <div className={styles.platformContainer}>
                      {item?.socialMediaPlatform === "instagram" ? (
                        <BsInstagram size={10} />
                      ) : (
                        <BsYoutube size={10} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </section>
        <p className={styles.name}>{name}</p>
      </Space>
      <Space size={10}>
        {inDrawer && (
          <ReportButton
            isOutline
            type="default"
            onClick={() => navigate(`/influencerAnalysis/${influencerId}`)}>
            Open in Full Page
          </ReportButton>
        )}
        {/* {meData?.data?.accountType &&
          meData?.data?.accountType !== "Report" && (
            <ReportButton
              type="primary"
              onClick={() => navigate(`/chat?influencerId=${influencerId}`)}>
              Message
            </ReportButton>
          )} */}
      </Space>
      <Col span={24}>
        <Menu
          defaultSelectedKeys={["overview"]}
          selectedKeys={[Object.values(selectedSection).join("**$")]}
          mode="horizontal"
          style={{ position: "relative" }}
          onSelect={(e) => {
            const first = e.key.split("**$")[0] as TSection["type"];
            const second = e.key.split("**$")[1];
            const socialMediaKitId = e.key.split("**$").at(-1) || "";

            setSelectedSection(() => {
              if (first === "overview") {
                return { type: first };
              } else {
                return {
                  type: first,
                  id: second,
                  socialMediaKitId: socialMediaKitId,
                };
              }
            });
          }}>
          <Menu.Item key={Object.values({ type: "overview" }).join("**$")}>
            Overview
          </Menu.Item>
          {igKeys.map((item, idx) => (
            <React.Fragment key={idx}>
              <Menu.Item
                key={Object.values({
                  type: "instagram",
                  id: item.socialMediaId,
                  socialMediaKitId: item._id,
                }).join("**$")}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <BsInstagram size={10} />
                  {item.instagram?.user.name || ""}
                </div>
              </Menu.Item>
            </React.Fragment>
          ))}
          {ytKeys.map((item, idx) => (
            <React.Fragment key={idx}>
              <Menu.Item
                key={Object.values({
                  type: "youtube",
                  id: item.socialMediaId,
                  socialMediaKitId: item._id,
                }).join("**$")}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <BsYoutube size={10} />
                  {item.youtube?.user.name}
                </div>
              </Menu.Item>
            </React.Fragment>
          ))}
        </Menu>
      </Col>
      <Col span={24} style={{ margin: "20px" }}>
        <Space size={10} align="end">
          <ReportButton isOutline type="default" onClick={downloadPdf}>
            Download Report
          </ReportButton>
        </Space>
      </Col>
    </Row>
  );
};

export default Header;
