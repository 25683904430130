import React from "react";
import { Modal, Row, Button, Col } from "antd";
import clsx from "clsx";

import styles from "./welcome.module.css";

interface WelcomeScreenProps {
  handleExplore: () => void;
  handleActivate: () => void;
  isVisible: boolean;
  handleDemoClick: () => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
  handleActivate,
  handleExplore,
  isVisible,
  handleDemoClick,
}) => {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      title={null}
      closable={false}
      onCancel={handleExplore}
      bodyStyle={{ borderRadius: "8px", padding: "40px" }}
      centered>
      <p className={styles.heading}> You are Almost Done !</p>
      <p className={styles.description}>
        You're almost there to launch your influencer marketing campaigns! We
        just need a few details to get started. Simply activate your account to
        begin.
      </p>
      <Row justify="center" gutter={10}>
        <Col>
          <Button
            type="primary"
            onClick={handleActivate}
            style={{ backgroundColor: "rgb(83, 58, 245)" }}
            className={clsx(styles.activate, styles.activateText)}>
            {" "}
            Activate Account
          </Button>
        </Col>
        <Button
          type="text"
          onClick={handleExplore}
          className={clsx(styles.activate, styles.exploreText)}>
          {" "}
          Explore first
        </Button>
      </Row>
      <h4 className={styles.help} style={{ marginTop: "24px" }}>
        Need any Assistance ?{" "}
        <span className={styles.demo} onClick={() => handleDemoClick()}>
          {" "}
          Book a Demo Call
        </span>
      </h4>
    </Modal>
  );
};

export default WelcomeScreen;
