import React from "react";
import { useQuery } from "react-query";
import { Row, Col } from "antd";

import BasicProfile from "./BasicProfile";
import Notes from "./Notes";
import BasicInfo from "../BasicInfo";
import Campaigns from "../Campaigns";

import ReviewsCard from "./ReviewsCard";

import { getInfluencer, getInfluencerMedia } from "../../../handler/Profile";
import { getUser } from "../../../handler/getUser";

import { formatData } from "../../../utils";

import styles from "./styles.module.css";

import { TSection } from "..";

interface OverviewProps {
  influencerId: string;
  socialMediaIdInsta: string;
  handleChangeSection: (section: TSection) => void;
}

const Overview: React.FC<OverviewProps> = ({
  influencerId,
  socialMediaIdInsta,
  handleChangeSection,
}) => {
  const [getInfluencerKey, getInfluencerFn] = getInfluencer(influencerId);
  const { data: influencerData, isLoading: isInfluencerDataLoading } = useQuery(
    getInfluencerKey,
    getInfluencerFn,
    { enabled: Boolean(influencerId) }
  );

  const [userKey, userFn] = getUser();
  const { data: userData } = useQuery(userKey, userFn);

  const [getInfluencerMediaKey, getInfluencerMediaFn] =
    getInfluencerMedia(influencerId);

  const { data: influencerMediaData, isLoading: influencerMediaLoading } =
    useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {
      enabled: Boolean(influencerId),
    });

  const youtubeData = influencerMediaData?.data?.filter(
    (item) => item.socialMediaPlatform === "youtube"
  );

  const instagramData = influencerMediaData?.data?.filter(
    (item) => item.socialMediaPlatform === "instagram"
  );

  const isReportingAccount = userData?.data?.accountType === "Report";

  return (
    <div className={styles.container}>
      <div>
        {isInfluencerDataLoading || influencerMediaLoading ? (
          <>Loading...</>
        ) : (
          <Row align="top" justify="space-between">
            <Col span={12}>
              <BasicProfile influencerData={influencerData?.data} />
            </Col>
            <Col span={11}>
              <Notes
                influencerData={influencerData?.data}
                id={influencerId}
                getInfluencerKey={getInfluencerKey}
              />
            </Col>
          </Row>
        )}
      </div>

      {instagramData &&
        instagramData
          .filter((accountItem) => {
            // if (isReportingAccount) {
            //   return accountItem?.dataProvider === "default";
            // }
            return true;
          })
          .map((item, idx) => {
            return (
              <BasicInfo
                showInsightsButton={item?.dataProvider === "default"}
                influencerId={influencerId}
                socialMediaKitId={item._id}
                key={idx}
                socialMediaId={item.socialMediaId}
                id={item.socialMediaId}
                bio={item.instagram?.user?.biography || ""}
                followers={formatData(
                  item?.instagram?.user?.followerCount || 0,
                  "a"
                )}
                following={formatData(
                  item?.instagram?.user?.followsCount || 0,
                  "a"
                )}
                noOfMedia={formatData(
                  item.instagram?.user?.contentCount || 0,
                  "a"
                )}
                name={item?.instagram?.user?.fullname || ""}
                platform="instagram"
                profilePic={item?.instagram?.user?.profilePicUrl || ""}
                username={item.socialMediaName || ""}
                isTitleVisible
                handleChangeSection={handleChangeSection}
                dataShow={
                  Boolean(item?.socialMediaId) &&
                  !Boolean(item?.isProfileLockedForBrand) &&
                  Boolean(item?.isInBrandCommunity) &&
                  item?.dataProvider === "default"
                }
                isInBrandCommunity={Boolean(item?.isInBrandCommunity)}
              />
            );
          })}

      {youtubeData &&
        youtubeData
          .filter((accountItem) => {
            // if (isReportingAccount) {
            //   return accountItem?.dataProvider === "default";
            // }
            return true;
          })
          .map((item, idx) => (
            <BasicInfo
              socialMediaKitId={item._id}
              showInsightsButton={item?.dataProvider === "default"}
              influencerId={influencerId}
              key={idx}
              followers={formatData(
                item?.youtube?.user?.followerCount || 0,
                "a"
              )}
              noOfMedia={formatData(
                item?.youtube?.user?.contentCount || 0,
                "a"
              )}
              name={item?.youtube?.user?.name || ""}
              platform="youtube"
              profilePic={item?.youtube?.user?.profilePicUrl || ""}
              username={item?.socialMediaName}
              id={item.socialMediaId}
              socialMediaId={item.socialMediaId}
              isTitleVisible
              handleChangeSection={handleChangeSection}
              dataShow={item.dataProvider !== "default"}
              isInBrandCommunity={Boolean(item?.isInBrandCommunity)}
            />
          ))}
      <div>
        <Campaigns
          type="report"
          influencerId={influencerId}
          socialMediaId={socialMediaIdInsta}
        />
        <div />
      </div>

      {userData?.data?.accountType &&
        userData?.data?.accountType !== "Report" && (
          <div>
            <Campaigns type="exec" influencerId={influencerId} />
            <div />
          </div>
        )}

      <ReviewsCard influencerId={influencerId} />
    </div>
  );
};

export default Overview;
