export const gstinValidate = (str: string) => {
  // Regex to check valid
  // GST CODE
  let regex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );

  if (!str) return false;
  else return regex.test(str);
};
