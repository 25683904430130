import React from "react";
import { useQuery } from "react-query";
import { Col, Input, Row, Space } from "antd";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { MdAccountBox } from "react-icons/md";
import Lottie from "react-lottie";
import { HiMagnifyingGlass } from "react-icons/hi2";

import styles from "./styles.module.css";

import { getDefaultLottieOptions } from "../../../../utils";
import animationData from "./../../../assets/lottie/Rocket.json";
import animationDataEmptyReport from "./../../../assets/lottie/astronot.json";

import { getCampaignAggregate } from "../../../../handler/report";

import MetricCard from "../InfluencerScreen/MetricCard";
import ReportButton from "../ReportButton";
import AddInfluencerModal from "../Modals/AddInfluencer";
import Option from "../../../shared/UI/Option";
import DeliverableSelect from "../Modals/SharedComponents/DeliverableSelect";
import InfluencerDrawer from "../ReportScreen/InfluencerDrawer";

import { useReportOnly } from "../Context/ReportOnlyContext";

const ReportOptions = {
  loop: true,
  autoplay: true,
  animationData: animationDataEmptyReport,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

type ContentScreenProps = {
  campaignId: string;
  platform: "instagram" | "youtube";
};

const ContentScreen: React.FC<ContentScreenProps> = ({
  campaignId,
  platform,
}) => {
  const { tableRow, totalReportsLength } = useReportOnly();

  const mediaItems = tableRow.filter(
    (item) => item.fbMediaProductType || item.ytMediaType
  );

  const mediaTypes = {
    all: {
      _id: 0,
      label: "ALL",
      count: 0,
    },
    story: {
      _id: 1,
      label: "STORY",
      count: 0,
    },
    feed: {
      _id: 2,
      label: "POST",
      count: 0,
    },
    reels: {
      _id: 3,
      label: "REEL",
      count: 0,
    },
  };

  mediaItems.forEach((item, key) => {
    mediaTypes.all.count = mediaTypes.all.count + 1;

    if (item.type === "IG-Feed-Story") {
      mediaTypes.story.count = mediaTypes.story.count + 1;
    } else if (item.type === "IG-Reel") {
      mediaTypes.reels.count = mediaTypes.reels.count + 1;
    } else {
      mediaTypes.feed.count = mediaTypes.feed.count + 1;
    }
  });

  const [queryKey, queryFn] = getCampaignAggregate({
    campaignId: campaignId,
  });

  const { data, isLoading } = useQuery(queryKey, queryFn);

  //Add Influencer Modal
  const [visible, setVisible] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);

  // selected media Type : All, Story, Post Reel
  const [selectedId, setSelectedId] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [mediaId, setMediaId] = React.useState("");
  const [social, setSocial] = React.useState("");

  return (
    <div>
      <h1>Overview</h1>
      <Col span={22}>
        <Row gutter={[20, 20]}>
          <Col span={7}>
            <MetricCard
              title="Influencers added"
              desc="The total number of influencers in campaign"
              icon={<MdAccountBox size={24} color="#7F7F7F" />}
              metricNumber={data?.data?.length || 0}
              isLoading={isLoading}
              extra={
                <ReportButton
                  type="default"
                  style={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => setVisible(true)}>
                  {" "}
                  Add Influencer
                </ReportButton>
              }
              emptyText="No Influencer Added"
            />
          </Col>
          <Col span={7}>
            <MetricCard
              title="Medias added"
              desc="The total number of media received by all influencers "
              icon={
                platform === "instagram" ? (
                  <FaInstagram size={20} color="#7F7F7F" />
                ) : (
                  <FaYoutube size={20} color="#7f7f7f" />
                )
              }
              metricNumber={data?.data?.reduce(
                (prev, current) => Number(current?.noOfMedias) + prev,
                0
              )}
              emptyText="No Media Added"
            />
          </Col>
        </Row>
      </Col>
      <h1 style={{ marginTop: 40 }}>Media Added</h1>
      {platform === "instagram" && totalReportsLength > 0 && (
        <Space size={10}>
          {Object.entries(mediaTypes).map((item, key) => {
            return (
              <Option
                key={key}
                title={item[1].label + " (" + item[1].count + ")"}
                isSelected={selectedId === item[1]._id}
                handleClick={() => setSelectedId(item[1]._id)}
                disabled={item[1].count === 0}
              />
            );
          })}
        </Space>
      )}
      {totalReportsLength > 0 && (
        <div style={{ marginTop: 40 }}>
          <section className={styles.inputContainer} style={{ width: "60%" }}>
            <Space size={2} align="center" style={{ width: "100%" }}>
              <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value || "")}
                onPressEnter={() => setSearchQuery(searchQuery)}
                placeholder={"Search by name or username"}
                className={styles.input}
                size="large"
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  width: "100%",
                  minWidth: "400px",
                }}
              />
            </Space>
            <ReportButton
              type="primary"
              onClick={() => setSearchQuery(searchQuery)}
              style={{ padding: "10px 20px " }}>
              Search
            </ReportButton>
          </section>
        </div>
      )}
      {isLoading ? (
        <Row
          justify="center"
          style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
          <Lottie
            options={getDefaultLottieOptions(animationData)}
            height={300}
            width={370}
          />
        </Row>
      ) : (
        <section style={{ marginBlock: 40 }}>
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {mediaItems.length === 0 ? (
              <Col span={18} style={{ marginBlock: 80 }}>
                <Lottie options={ReportOptions} height={120} width={300} />
                <div
                  style={{
                    display: "grid",
                    gap: "14px",
                    textAlign: "center",
                    marginTop: "40px",
                  }}>
                  <p className={styles.label}>Oh, this is an empty space! </p>
                  <p className={styles.label}>
                    {totalReportsLength > 0
                      ? `No Media found by name or username of ${searchQuery}`
                      : "No Medias Added"}
                  </p>
                  <p className={styles.label}>
                    You can{" "}
                    <span
                      className={styles.highlightedLink}
                      style={{ textDecoration: "underline" }}
                      onClick={() => setVisible(true)}>
                      Find {searchQuery}
                    </span>{" "}
                    and add to campaign <br />
                    Or directly{" "}
                    <span
                      className={styles.highlightedLink}
                      onClick={() => setVisible(true)}
                      style={{ textDecoration: "underline" }}>
                      Add The Report
                    </span>{" "}
                    to the campaign
                  </p>
                </div>
              </Col>
            ) : (
              mediaItems
                .filter((item) => {
                  if (selectedId === 0)
                    return (
                      item.influencerData.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      item.influencerData.username
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    );
                  else if (selectedId === 1)
                    return (
                      item.type === "IG-Feed-Story" &&
                      (item.influencerData.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                        item.influencerData.username
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()))
                    );
                  else if (selectedId === 2)
                    return (
                      (item.type === "IG-Feed-Image" ||
                        item.type === "IG-Feed-Carousel") &&
                      (item.influencerData.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                        item.influencerData.username
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()))
                    );
                  else
                    return (
                      item.type === "IG-Reel" &&
                      (item.influencerData.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                        item.influencerData.username
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()))
                    );
                })
                .map((item, key) => {
                  return (
                    <Col key={key} span={platform === "instagram" ? 3 : 5}>
                      <DeliverableSelect
                        username={item.influencerData.username}
                        isLabelClickEnabled={true}
                        handleClick={() => {
                          setOpen(true);
                          setMediaId(item.deliverableMediaId);
                          setSocial(item.socialMediaId);
                        }}
                        id={item.deliverableMediaId}
                        type={
                          platform === "instagram"
                            ? item.fbMediaType === "CAROUSEL_ALBUM"
                              ? "IMAGE"
                              : item.fbMediaType
                            : "yt-video"
                        }
                        isSelected={false}
                        caption={
                          platform === "youtube" ? item?.title : undefined
                        }
                        profilePic={item?.influencerData?.profilePicUrl}
                        permaLink={item?.media?.permalink}
                        url={item.savedMedias[0].url}
                        label={item?.type}
                      />
                    </Col>
                  );
                })
            )}
          </Row>
        </section>
      )}
      <AddInfluencerModal
        visible={visible}
        setVisible={setVisible}
        campaignId={campaignId}
        platform={platform}
      />
      <InfluencerDrawer
        campaignId={campaignId}
        platform={platform}
        visible={open}
        setVisible={setOpen}
        socialMediaId={social}
        deliverableMediaId={mediaId}
      />
    </div>
  );
};
export default ContentScreen;
