import React from "react";
import styles from "./billTile.module.css";
import { useMutation } from "react-query";
import { Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";

import { transaction } from "../../../typings";

import { mailOffer } from "../../../handler/Billing";

import nf from "@tuplo/numberfmt";
import { format } from "date-fns";

interface BillTileProps {
  tileData: transaction;
  type: "transactions" | "invoices" | "creditNote";
}

const BillTile: React.FC<BillTileProps> = ({ tileData, type }) => {
  const mailOfferMutation = useMutation(mailOffer, {
    onSuccess: () => {
      message.success("Details mailed successfully");
    },
    onError: () => {
      message.error("Something went wrong ");
    },
  });
  return (
    <div className={styles.tile}>
      <p>{format(new Date(tileData.createDateTime), "d MMM, RRRR")}</p>
      <p>{type === "invoices" ? tileData.invoice.number : tileData._id}</p>
      {/* <p>{tileData._id}</p> */}
      <p>{tileData.type}</p>
      <p>{`${tileData.campaign.campaignName}${
        type === "invoices" || type === "transactions"
          ? `${" "}- ${tileData.offerIds.length} creators`
          : ""
      }`}</p>
      {/* <p>{tileData.campaign.campaignName}</p> */}
      <p style={{ justifySelf: "flex-end" }}>
        {}₹{""}
        {nf(
          tileData.type !== "Refund"
            ? tileData.paymentAmount
            : tileData?.creditNote?.amount,
          "0,0.00"
        )}
        {/* ₹40000 */}
      </p>
      {tileData.type === "Refund" ? (
        <Button
          type="text"
          shape="circle"
          icon={<MailOutlined />}
          style={{
            justifySelf: "flex-end",
            cursor: "pointer",
            padding: 0,
            height: "18px",
            width: "14px",
            marginRight: "-6px",
          }}
          title="Mail me"
          onClick={() => {
            mailOfferMutation.mutate({ offerPayInId: tileData._id });
          }}
        />
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          style={{ justifySelf: "flex-end", cursor: "pointer" }}
          download
          href={tileData.invoice.url}>
          <img src="/assets/icons/download.svg" alt="" />
        </a>
      )}
    </div>
  );
};

export default BillTile;
