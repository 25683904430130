import React from "react";
import { Modal } from "antd";

import Invoices from ".";

import styles from "./styles.module.css";

interface InvoiceModalProps {
  visible: boolean;
  handleClose: () => void;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({
  visible,
  handleClose,
}) => {
  return (
    <Modal
      width={700}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        handleClose();
      }}
      visible={visible}
      centered
      className={"campaign-create-modal"}>
      <p className={styles.headingModal}>Review Invoices</p>
      <div style={{ marginBlock: 20 }}>
        <Invoices />
      </div>
    </Modal>
  );
};

export default InvoiceModal;
