import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Row, Col, Space, message } from "antd";
import { AxiosError } from "axios";
import Lottie from "react-lottie";

import PersonalDetails from "./PersonalDetails";
import BusinessDetails from "./BusinessDetails";
import ReportButton from "../Campaign/Report/ReportButton";

import animationData from "../assets/lottie/Rocket.json";

import { getUser } from "../../handler/getUser";
import { getLocation } from "../../handler/getLocation";
import { onboardUser } from "../../handler/onboardUser";
import { getOrgAppState } from "../../handler/organization";

import { getDefaultLottieOptions } from "../../utils";

import Nav from "../Locked/Nav";

export interface UserType {
  name: string;
  lastname: string;
  designation: string;
  phoneNumber: string;
  businessType: "Brand" | "Agency";
  businessLogoUrl: string;
  businessName: string;
  businessWebsiteUrl: string;
  businessNiche: string[];
  isPhoneVerified: boolean;
  countryCode: string;
}

const Activate = () => {
  const [userKey, userFn] = getUser();
  const [sectionSelected, setSectionSelected] = useState<
    "personal" | "business"
  >("personal");

  const [orgKey] = getOrgAppState();

  const firstRef = useRef(true);

  const [userLocationKey, userLocationFn] = getLocation();

  const navigate = useNavigate();

  const { data: userData, isLoading: userDataLoading } = useQuery(
    userKey,
    userFn
  );
  const { data: locationData, isLoading: locationDataLoading } = useQuery(
    userLocationKey,
    userLocationFn,
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData?.data?.signUpStatus !== "notInitiated") {
      return navigate("/", { replace: true });
    }
  }, [userData]);

  useEffect(() => {
    if (firstRef.current && locationData?.country_code) {
      setUserInputData((userInputData) => ({
        ...userInputData,
        countryCode: locationData?.country_code ?? "IN",
      }));
      firstRef.current = false;
    }
  }, [firstRef.current, locationData]);

  if (userDataLoading || locationDataLoading) {
    <Row
      justify="center"
      style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
      <Lottie
        options={getDefaultLottieOptions(animationData)}
        height={300}
        width={370}
      />
    </Row>;
  }

  const [userInputData, setUserInputData] = useState<UserType>({
    name: "",
    lastname: "",
    phoneNumber: "",
    businessLogoUrl: "",
    businessName: "",
    businessNiche: [],
    businessType: "Brand",
    businessWebsiteUrl: "",
    designation: "",
    isPhoneVerified: false,
    countryCode: "",
  });
  const [userDataErr, setUerDataErr] = useState<UserType>({
    name: "",
    lastname: "",
    phoneNumber: "",
    businessLogoUrl: "",
    businessName: "",
    businessNiche: [],
    businessType: "Brand",
    businessWebsiteUrl: "",
    designation: "",
    isPhoneVerified: false,
    countryCode: "IN",
  });

  const isValidUrl = (urlString: string) => {
    if (urlString === "") return true;
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const checkIsDataValid = () => {
    let isError = false;
    let errorObject: UserType = {
      name: "",
      lastname: "",
      phoneNumber: "",
      businessLogoUrl: "",
      businessName: "",
      businessNiche: [],
      businessType: "Brand",
      businessWebsiteUrl: "",
      designation: "",
      isPhoneVerified: userInputData?.isPhoneVerified || false,
      countryCode: "",
    };
    if (sectionSelected === "personal") {
      if (userInputData.name.trim() === "") {
        errorObject = { ...errorObject, name: "Please Enter Name" };
        isError = true;
      }
      if (userInputData.lastname.trim() === "") {
        errorObject = { ...errorObject, lastname: "Please Enter Last Name" };
        isError = true;
      }
      if (userInputData.designation.trim() === "") {
        errorObject = {
          ...errorObject,
          designation: "Please Enter Designation",
        };
        isError = true;
      }

      if (!userInputData?.phoneNumber.trim()) {
        errorObject = {
          ...errorObject,
          phoneNumber: "Please Enter Phone Number",
        };
        isError = true;
      }
      if (userInputData?.phoneNumber && !userInputData?.isPhoneVerified) {
        errorObject = {
          ...errorObject,
          phoneNumber: "Please Verify your phone Number First",
        };
        isError = true;
      }
    }
    if (sectionSelected === "business") {
      if (
        !userInputData?.businessWebsiteUrl ||
        !isValidUrl(userInputData?.businessWebsiteUrl)
      ) {
        errorObject = {
          ...errorObject,
          businessWebsiteUrl: "Please Enter a Valid Url",
        };
        isError = true;
      }
      if (!userInputData?.businessName.trim()) {
        errorObject = {
          ...errorObject,
          businessName: `Please Enter ${userInputData.businessType} Name`,
        };
        isError = true;
      }

      if (!userInputData?.businessLogoUrl) {
        errorObject = {
          ...errorObject,
          businessLogoUrl: "Please Upload a Logo",
        };
        isError = true;
      }
      if (!userInputData?.countryCode) {
        errorObject = {
          ...errorObject,
          countryCode: "Please select a Country",
        };
        isError = true;
      }
    }

    setUerDataErr({ ...errorObject });
    return !isError;
  };

  const queryClient = useQueryClient();
  const editUserDetailsMutation = useMutation(onboardUser, {
    onSuccess: () => {
      message.success("Details Updated");
      queryClient.invalidateQueries(orgKey);

      queryClient
        .invalidateQueries(["user"])
        .then(() => navigate("/", { replace: true }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any)?.message);
    },
  });
  const handleFormSubmit = () => {
    if (!checkIsDataValid()) {
      return message.error("Please recheck the form");
    } else {
      if (sectionSelected === "personal") {
        return setSectionSelected("business");
      }
      return editUserDetailsMutation.mutate({
        name: userInputData.name,
        lastname: userInputData.lastname,
        designation: userInputData.designation,
        businessType: userInputData.businessType,
        logoUrl: userInputData.businessLogoUrl,
        businessName: userInputData.businessName,
        websiteUrl: userInputData.businessWebsiteUrl,
        niche: userInputData.businessNiche,
        countryCode: userInputData?.countryCode,
      });
    }
  };

  return (
    <div style={{ overflowY: "auto", height: "100vh" }}>
      <Nav />
      <Row justify="center" style={{ marginTop: 150 }}>
        <Col span={14}>
          {sectionSelected === "personal" ? (
            <PersonalDetails
              inputValues={userInputData}
              errors={userDataErr}
              setErrors={setUerDataErr}
              setInputValues={setUserInputData}
            />
          ) : (
            <BusinessDetails
              inputValues={userInputData}
              errors={userDataErr}
              setErrors={setUerDataErr}
              setInputValues={setUserInputData}
            />
          )}
          <Space size={10} style={{ marginBlock: "60px" }}>
            {sectionSelected === "business" && (
              <ReportButton
                isOutline
                size="large"
                onClick={() => setSectionSelected("personal")}>
                Back
              </ReportButton>
            )}

            <ReportButton
              type="primary"
              size="large"
              onClick={() => handleFormSubmit()}
              loading={editUserDetailsMutation.isLoading}>
              Continue
            </ReportButton>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default Activate;
