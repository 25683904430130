import { sendRequest } from "../helper";

import { ProfileUser } from "../../typings/Chat";

type UserProfileResult = {
  status: string;
  message: string;
  data: ProfileUser;
};

export const getUserProfile = (userId: string) => {
  const queryKey = ["chat", userId];
  const queryFunction = () =>
    sendRequest<UserProfileResult>(
      "get",
      `/brand/chat/user-profile?userId=${userId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
