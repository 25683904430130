interface MyObject {
  [key: string]: any;
}

export const findSequentialObjectsFromEnd = (
  inputArray: MyObject[],
  sequenceLength: number
): MyObject[] => {
  inputArray = inputArray.slice().reverse(); // Reverse the input array without mutating original

  let result: MyObject[] = [];
  let count = 0;

  for (let i = 0; i < inputArray.length; i++) {
    if (
      Object.keys(inputArray[i]).includes("value") &&
      Object.keys(inputArray[i]?.value).length === 24
    ) {
      // console.log("1", i);
      count++;
      result.push(inputArray[i]);
    } else {
      // console.log("2", i);
      count = 0;
      result = [];
    }

    if (count === sequenceLength) {
      return result.slice().reverse(); // Reverse the result array without mutating it
    }
  }

  return []; // Return null if no sequence of 7 objects found
};
