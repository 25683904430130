import React from "react";
import { Row, Col, Spin } from "antd";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut, Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { MdTimeline } from "react-icons/md";

import StatsCard from "../../AudienceInsights/StatsCard";

import { getYtAnalytics } from "../../../../handler/Profile";

import { formatData } from "../../../../utils";

import styles from "./audience.module.css";
import { PinnedMetrics } from "..";
import { LuPin, LuPinOff } from "react-icons/lu";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const AudienceInsights: React.FC<{
  socialMediaId: string;
  typeSelected: "video" | "shorts";
  pinnedMetrics: PinnedMetrics[];
  setPinnedMetrics: React.Dispatch<React.SetStateAction<PinnedMetrics[]>>;
}> = ({ socialMediaId, typeSelected, pinnedMetrics, setPinnedMetrics }) => {
  const handlePin = (
    title: PinnedMetrics["title"],
    type: PinnedMetrics["type"] = "audience"
  ) => {
    const itemPresent = pinnedMetrics.find(
      (item) =>
        item.title === title &&
        item.content === typeSelected &&
        item.type === type
    );

    if (itemPresent) {
      setPinnedMetrics((prev) =>
        prev.filter(
          (item) =>
            !(
              item.title === itemPresent.title &&
              item.content === itemPresent.content &&
              item.type === itemPresent.type
            )
        )
      );
    } else {
      setPinnedMetrics((prev) => [
        ...prev,
        { title, content: typeSelected, type },
      ]);
    }
  };

  const isPinned = (title: string) =>
    pinnedMetrics.findIndex(
      (item) =>
        item.title === title &&
        item.type === "audience" &&
        item.content === typeSelected
    ) !== -1;

  const [getInfluencerMediaInsightsKey, getInfluencerMediaInsightsFn] =
    getYtAnalytics(socialMediaId || "");

  const {
    data: influencerMediaInsightsData,
    isLoading: isInfluencerMediaInsightsLoading,
  } = useQuery(getInfluencerMediaInsightsKey, getInfluencerMediaInsightsFn, {
    enabled: Boolean(socialMediaId),
  });

  const extractData = () => {
    let totalObjectData: { [key: string]: { [key: string]: number } } = {};

    if (!influencerMediaInsightsData) return totalObjectData;

    Object.entries(influencerMediaInsightsData?.data?.user).forEach(
      ([key, value]) => {
        if (
          typeof value !== "object" ||
          key === "basicStats_lifetime" ||
          key === "basicStats_lifetime"
        ) {
          return;
        }

        if (typeSelected === key) {
          Object.entries(value as { [key: string]: any }).forEach(
            ([objKey, objValue]) => {
              if (
                objKey !== "count" &&
                objKey !== "basicStats_lifetime" &&
                !objValue?.isError &&
                !objValue?.isErrorTransformingPayload &&
                !Object.is(objValue?.data, null)
              ) {
                if (!(objKey in totalObjectData)) {
                  totalObjectData = { ...totalObjectData, [objKey]: {} };
                }
                Object.entries(
                  objValue?.data as { [key: string]: any }
                ).forEach(([internalKey, internalObj]) => {
                  totalObjectData = {
                    ...totalObjectData,
                    [objKey]: {
                      ...totalObjectData[objKey],
                      [internalKey]:
                        internalObj?.views ||
                        internalObj?.viewerPercentage ||
                        0,
                    },
                  };
                });
              }
            }
          );
        }
      }
    );
    return totalObjectData;
  };
  const getCountryViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = extractData();
    if (!("countryStats_lifetime" in response)) return result;

    Object.entries(response["countryStats_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getDemographicViews = () => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};

    const response = extractData();
    if (!("viewerDemographics_lifetime" in response)) return totalObject;

    Object.entries(response["viewerDemographics_lifetime"]).forEach(
      ([key, value]) => {
        // const age_genderKey = key.split("$").at(-1) || "$age18-24$male";
        const ageKey = key.split("$").at(1) || "age18-24";
        const genderKey = key.split("$").at(-1) || "female";

        if (!(ageKey in totalObject)) {
          totalObject = { ...totalObject, [ageKey]: {} };
        }

        if (!(genderKey in totalObject[ageKey])) {
          totalObject = {
            ...totalObject,
            [ageKey]: { ...totalObject[ageKey], [genderKey]: value },
          };
        }
      }
    );

    return totalObject;
  };

  const getAgeRangeData = () => {
    let result: { value: number; label: string }[] = [];

    const response = getDemographicViews();

    let total = 0;

    Object.values(response)?.forEach(
      (value) =>
        (total =
          total +
          Object.values(value).reduce(
            (prev, current) => prev + (current || 0),
            0
          ))
    );
    Object.entries(response).forEach(([key, value]) => {
      result.push({
        label: key,
        value:
          (Object.values(value).reduce(
            (prev, current) => prev + (current || 0),
            0
          ) /
            (total || 1)) *
          100,
      });
    });
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getMaleFemaleSplit = () => {
    const response = getDemographicViews();
    let male =
      Object.values(response).reduce(
        (prev, current) => prev + current?.male,
        0
      ) || 0;
    let female =
      Object.values(response).reduce(
        (prev, current) => prev + current?.female,
        0
      ) || 0;

    let unknown =
      Object.values(response).reduce(
        (prev, current) => prev + current?.genderUserSpecified,
        0
      ) || 0;

    return { male, female, unknown };
  };

  if (isInfluencerMediaInsightsLoading) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  if (!influencerMediaInsightsData) {
    return <div>No Influencer Found</div>;
  }

  const barData = {
    labels: Object.keys(getDemographicViews()),
    datasets: [
      {
        label: "Female",
        data: Object.values(getDemographicViews()).map((item) => item?.female),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.male || 0
        ),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.genderUserSpecified || 0
        ),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  };

  const totalCountryViews =
    getCountryViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Audience Insights</p>
      <p className={styles.desc}>
        Demographic split of the followers of the influencers.
        <br />
        Lifetime value gives the split of all the followers of the influencers
        that still follows them.
      </p>
      <Row gutter={[20, 20]}>
        <Col span={18}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}>
            <p className={styles.graphLabel}>Age-Gender Ratio</p>
            <div
              style={{
                width: "100%",
                height: "308px",
              }}>
              {Object.values(getDemographicViews()).reduce(
                (prev, current) =>
                  prev +
                    Object.values(current)?.reduce((x, y) => x + (y || 0), 0) ||
                  0,
                0
              ) === 0 ? (
                <p className="global-no-data">
                  {" "}
                  Not enough Data To show this Metric{" "}
                </p>
              ) : (
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: false,
                      },
                      datalabels: {
                        anchor: "end",
                        align: "top",
                        formatter: (value) =>
                          `${formatData(value || 0, "0.0a")}%`,
                      },
                    },
                  }}
                  data={barData}
                />
              )}
            </div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Age-Gender Ratio");
              }}>
              {isPinned("Age-Gender Ratio") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <Col span={7}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}
            key={1}>
            <p className={styles.graphLabel}>Country wise split</p>
            <StatsCard
              items={getCountryViews().map((item) => ({
                label: item.label,
                percent: (item.value / totalCountryViews) * 100,
              }))}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Country wise split");
              }}>
              {isPinned("Country wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <Col span={7}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}
            key={1}>
            <p className={styles.graphLabel}>Age wise Split</p>
            <StatsCard
              items={getAgeRangeData().map((item) => ({
                label: item.label.split("age").at(-1) || "",
                percent: item.value,
              }))}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Age wise Split");
              }}>
              {isPinned("Age wise Split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <div
          className={styles.container}
          style={{ position: "relative" }}
          key={2}>
          <Row
            justify="space-between"
            style={{ width: "100%" }}
            gutter={[10, 10]}>
            <Col span={24}>
              <p className={styles.label}>Gender Split</p>
            </Col>
          </Row>
          {!(
            getMaleFemaleSplit().male === 0 &&
            getMaleFemaleSplit().female === 0 &&
            getMaleFemaleSplit().unknown === 0
          ) ? (
            <Doughnut
              options={{
                plugins: {
                  legend: { position: "top" },
                  datalabels: {
                    anchor: "end",
                    align: "start",
                    formatter: (value) => formatData(value || 0, "a"),
                    font: {
                      size: 16,
                      style: "normal",
                    },
                  },
                },
              }}
              data={{
                labels: ["Male", "Female", "UnKnown"],
                datasets: [
                  {
                    label: "No",
                    data: [
                      getMaleFemaleSplit().male,
                      getMaleFemaleSplit().female,
                      getMaleFemaleSplit().unknown,
                    ],
                    backgroundColor: ["#FF6384", "#FFCD56", "#36A2EB"],
                    borderColor: ["#FF6384", "#FFCD56", "#36A2EB"],
                    borderWidth: 1,
                    borderJoinStyle: "round",
                    borderRadius: 10,
                    hoverOffset: 4,
                    offset: 0,
                  },
                ],
              }}
            />
          ) : (
            <p className="global-no-data">
              {" "}
              Not enough Data To show this Metric{" "}
            </p>
          )}
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              handlePin("Gender Split");
            }}>
            {isPinned("Gender Split") ? (
              <LuPin size={10} color={"#00000066"} />
            ) : (
              <LuPinOff size={10} color={"#00000066"} />
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default AudienceInsights;
