import { sendRequest } from "../helper";

interface editCatalogueProps {
  name: string;
  productIds: string[];
  catalogueId: string;
}

export const editCatalogue = ({
  name,
  productIds,
  catalogueId,
}: editCatalogueProps) => {
  return sendRequest("put", `/brand/products/catalogue`, false, {
    name,
    productIds,
    catalogueId,
  }).then((res) => res.data);
};
