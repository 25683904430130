import React from "react";

import { Modal, ModalProps } from "antd";

interface CModalProps extends ModalProps {
  visible: boolean;
  handleClose: () => void;
}

const CustomModal: React.FC<CModalProps> = ({
  children,
  handleClose,
  visible,
  title,
  ...props
}) => {
  return (
    <Modal
      title={title}
      closable={false}
      onCancel={handleClose}
      //   open={visible}
      destroyOnClose
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
