import React from "react";
import { ProfileChild } from "../ProfileAnalysis/ThirdParty";
import { CreatorPageContextProvider } from "../ProfileAnalysis/ThirdParty/context";
import ProfileHeader from "./ProfileHeader";

const ProfileAnalysis3p: React.FC<any> = ({ platform, username }) => {
  return (
    <CreatorPageContextProvider platform={platform} username={username}>
      <ProfileHeader />
      <div style={{ padding: "25px" }}>
        {" "}
        <ProfileChild />
      </div>
    </CreatorPageContextProvider>
  );
};

export default ProfileAnalysis3p;
