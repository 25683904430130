import { Button, Dropdown, Menu, Modal, Tag, message } from "antd";
import React, { useState } from "react";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import styles from "./../table.module.css";

import { CampaignReport } from "../../../../typings";

import { format } from "date-fns";

import { deleteCampaign, archiveCampaign } from "../../../../handler/campaign/";
import { changeVisibility } from "../../../../handler/campaign";

import { AxiosError } from "axios";

type RowProps = {
  campaignkey: (string | number | boolean)[];
  campaignCountKey: (string | number | boolean)[];
  campaign: CampaignReport;
};

const Row: React.FC<RowProps> = (props) => {
  const { campaign, campaignkey, campaignCountKey } = props;
  const navigate = useNavigate();

  // const status = "Active";
  const campLink = `/campaign/manage/report/${campaign._id}`;

  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const deleteCampaignMutation = useMutation(deleteCampaign, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(campaignkey);
      queryClient.invalidateQueries(campaignCountKey);
      setConfirmationModalOpen(false);
      message.success("Campaign Deleted");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const changeVisibilityMutation = useMutation(changeVisibility, {
    onSuccess: () => {
      queryClient.invalidateQueries(campaignkey);
      setIsModalOpen(false);
      message.success("Campaign Updated");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  const handleOk = () => {
    changeVisibilityMutation.mutate({
      campaignId: campaign._id,
      //   isPublic: !campaign.isPublic,
      isPublic: false,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const menu = () => {
    return (
      <>
        <Menu
          items={[
            {
              key: "3",
              label: (
                <>
                  <p
                    onClick={() => {
                      //   if (getCampaignStatus(campaign) === "Under-Review") {
                      return setConfirmationModalOpen(true);
                      //   }
                      //   return setIsModalOpen(true);
                    }}>
                    {/* {status === "Draft"
                      ? "Continue Editing"
                      : getCampaignStatus(campaign) === "Under-Review"
                      ? "Delete Campaign"
                      : `Make campaign ${false ? "Private" : "Public"}`} */}
                    Delete Campaign
                  </p>
                </>
              ),
            },
          ].flat()}
          onClick={(event) => {
            event.domEvent.stopPropagation();
          }}
        />
        <Modal
          title={
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                padding: "10px 0px",
              }}>
              <img
                style={{ width: "22px", height: "22px" }}
                src="/assets/icons/info.svg"
                alt=""
              />
              <p>{false ? "PRIVATE" : "PUBLIC"}</p>
            </div>
          }
          visible={isModalOpen}
          onOk={handleOk}
          okText={`Yes, make it ${false} ? "private" : "public"}`}
          cancelText="Go back"
          okButtonProps={{ loading: changeVisibilityMutation.isLoading }}
          onCancel={handleCancel}>
          <p>
            {false
              ? "You won’t be able to receive more applications, you can still process previous applications and invite creators to participate.You can switch back anytime."
              : "Everyone will be able to apply to the campaign. You can switch back anytime."}
          </p>
        </Modal>
        <Modal
          title={
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                padding: "10px 0px",
              }}>
              <img
                style={{ width: "22px", height: "22px" }}
                src="/assets/icons/info.svg"
                alt=""
              />
              <p>Delete Campaign</p>
            </div>
          }
          visible={confirmationModalOpen}
          onOk={() =>
            deleteCampaignMutation.mutate({
              campaignId: campaign._id,
            })
          }
          okText={`Delete`}
          cancelText="Cancel"
          okButtonProps={{ loading: changeVisibilityMutation.isLoading }}
          onCancel={() => setConfirmationModalOpen(false)}>
          <p>
            Are you sure you want to delete <b>{campaign.campaignName}</b>{" "}
            campaign
          </p>
        </Modal>
      </>
    );
  };

  // const getTagColor = (status: string) => {
  //   switch (status) {
  //     case "Under Review":
  //       return "processing";
  //     case "Draft":
  //       return "warning";
  //     case "Active":
  //       return "success";
  //     case "Needs-Attention":
  //       return "error";
  //     default:
  //       return "default";
  //   }
  // };

  return (
    <React.Fragment>
      <section className={styles.rowWrapper}>
        <div className={styles.rowName}>{campaign.campaignName}</div>
        <div className={styles.rowPlatform} style={{ textAlign: "center" }}>
          <span>
            {campaign.platform === "instagram" ? (
              <AiOutlineInstagram
                color="#C13584"
                style={{ width: 18, height: 18 }}
              />
            ) : (
              <AiOutlineYoutube
                color="#ff0000"
                style={{ width: 18, height: 18 }}
              />
            )}
          </span>
          <span>
            {campaign.platform === "instagram" ? "Instagram" : "Youtube"}
          </span>
        </div>
        <div className={styles.rowVisibility} style={{ textAlign: "center" }}>
          Reporting
        </div>
        <div className={styles.rowDate} style={{ textAlign: "center" }}>
          {format(new Date(campaign.createdDateTime), "d LLL, yyyy")}
        </div>
        {/* <div className={styles.rowStatus} style={{ textAlign: "center" }}>
          <Tag color={getTagColor(status)} className={styles.statusTag}>
            {status}
          </Tag>
        </div> */}
        <div className={styles.rowOps}>
          <Button
            onClick={() => navigate(campLink)}
            type="link"
            style={{
              color: "#533AF5",
              fontFamily: "Inter",
              fontWeight: 600,
            }}>
            Open Campaign
          </Button>
          {/* <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
            
            <Button
              type="link"
              style={{
                color: "#00000080",
              }}
            >
              <FiMoreVertical />
            </Button>
          </Dropdown> */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default Row;
