import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Space, Modal, Alert, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  updateCampaignSpecialFields,
  UpdateSpecialFieldsProps,
} from "../../../../handler/cloutflowCampaign/updateSpecialFields"; // Adjust the import path as needed
import { useQueryClient } from "react-query";

interface SpecialField {
  key: string;
  isVisible: boolean;
  _id: string;
}

interface CampaignSettingsPageProps {
  campaign: {
    _id: string; // Assuming the campaign object has an _id field
    specialFields: SpecialField[];
  };
}

const SpecialFieldsSettings: React.FC<CampaignSettingsPageProps> = ({
  campaign,
}) => {
  const [specialFields, setSpecialFields] = useState<SpecialField[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentField, setCurrentField] = useState<SpecialField | null>(null);
  const [newKey, setNewKey] = useState<string>("");
  const [newVisible, setNewVisible] = useState<boolean>(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (campaign && campaign.specialFields) {
      setSpecialFields(campaign.specialFields);
    }
  }, [campaign]);

  const handleAddSpecialField = async () => {
    const newField = {
      key: newKey,
      isVisible: newVisible,
    };

    const props: UpdateSpecialFieldsProps = {
      campaignId: campaign._id,
      specialField: newField,
      type: "add",
    };

    await updateSpecialFieldsOnServer(props);
  };

  const handleEditSpecialField = (field: SpecialField) => {
    setCurrentField(field);
    setNewKey(field.key);
    setNewVisible(field.isVisible);
    setIsModalVisible(true);
  };

  const handleSaveEditSpecialField = async () => {
    if (currentField) {
      const props: UpdateSpecialFieldsProps = {
        campaignId: campaign._id,
        specialField: {
          _id: currentField._id,
          isVisible: newVisible,
          key: currentField.key,
        },
        type: "edit",
      };

      await updateSpecialFieldsOnServer(props);
    }
  };

  const updateSpecialFieldsOnServer = async (
    props: UpdateSpecialFieldsProps
  ) => {
    try {
      const result = await updateCampaignSpecialFields(props);
      if (result.status === "SUCCESS") {
        queryClient.invalidateQueries(["campaign", props.campaignId]);
      }
      setIsModalVisible(false);
      setCurrentField(null);
      setNewKey("");
      setNewVisible(true);
    } catch (error) {
      console.error("Failed to update special fields:", error);
    }
  };

  const columns = [
    {
      title: "Fields",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Visible",
      dataIndex: "isVisible",
      key: "isVisible",
      render: (visible: boolean) => (visible ? "Yes" : "No"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: SpecialField) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditSpecialField(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <section>
      <Alert
        message="Special Fields"
        description="Special Fields help you to add specific values to each Influencer, for example you can add a Field Product Selections. You can view these in table if you enable the Visible. These values are also available to download."
        type="info"
        showIcon
        style={{ marginBottom: 16, borderRadius: "8px" }}
        closable
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        <Button
          type="default"
          onClick={() => setIsModalVisible(true)}
          style={{ marginBottom: 16, borderRadius: "8px" }}
        >
          Add Special Field
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={specialFields}
        rowKey={(record) => record._id}
        pagination={false}
      />

      <Modal
        title={currentField ? "Edit Special Field" : "Add Special Field"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setCurrentField(null);
          setNewKey("");
          setNewVisible(true);
        }}
        onOk={currentField ? handleSaveEditSpecialField : handleAddSpecialField}
      >
        <Form layout="vertical">
          <Form.Item label="Field Name">
            <Input
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
              placeholder="Name of field, example: Product Selections"
              disabled={currentField !== null}
            />
          </Form.Item>
          <Form.Item label="Visible (Do you want to see these in main table)">
            <Select value={newVisible} onChange={setNewVisible}>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </section>
  );
};

export default SpecialFieldsSettings;
