import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import styles from "./styles.module.css";

interface FinalizedLinkProps {
  campaignId: string;
  creatorCount: number;
}

const FinalizedLink: React.FC<FinalizedLinkProps> = ({
  campaignId,
  creatorCount,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        {creatorCount} Creator{creatorCount > 1 && "s "} Finalized
      </div>
      <div className={styles.desc} style={{ margin: "15px 0 " }}>
        All these Creators have been moved to Finalized Tab, and You can start
        execution with them.
      </div>
      <Button
        block
        onClick={() =>
          navigate(`/campaign/manage/${campaignId}?type=finalized`, {
            replace: true,
          })
        }
        type="primary">
        Go to Finalized Tab
      </Button>
    </div>
  );
};

export default FinalizedLink;
