import React, { useState } from "react";
import { Dropdown, Menu } from "antd";

import CustomInput from "../../../../shared/Custom/Input";

type Props = {
  label?: React.ReactNode;
  menuItems: {
    label: string;
    variable: string;
    key: string;
  }[];
  exp: string;
  setExp: React.Dispatch<React.SetStateAction<string>>;
  setExpressionValid?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomMetricInput: React.FC<Props> = (props) => {
  const {
    label,
    menuItems,
    exp: expression,
    setExp: setExpression,
    setExpressionValid,
  } = props;
  // const [expression, setExpression] = useState<string>("");
  const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);

  const replaceAndExtractVariables = (inputString: string) => {
    const regex = /{(.*?)}/g; // Regular expression to match substrings in the format {var_name}
    const variableNames: string[] = []; // Array to store extracted var_names

    const replacedString = inputString.replace(regex, (_, varName) => {
      console.log();
      variableNames.push(varName);
      return "!"; // Replace the matched substring with number 1
    });

    return {
      replacedString,
      variableNames,
    };
  };

  const isValidMathExpression = (expression: string) => {
    const stack: string[] = [];
    const openingBrackets = ["("];
    const closingBrackets = [")"];
    const validOperators = ["+", "-", "*", "/"];
    const validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "!"];

    let prevChar = null; // To keep track of the previous character

    for (let i = 0; i < expression.length; i++) {
      const char = expression[i];
      const nextChar = i < expression.length - 1 ? expression[i + 1] : null;

      if (openingBrackets.includes(char)) {
        stack.push(char);

        if (prevChar && validChars.includes(prevChar)) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false;
        }
      } else if (closingBrackets.includes(char)) {
        const lastOpeningBracket = stack.pop();
        if (!lastOpeningBracket) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false; // No matching opening bracket found
        }

        if (nextChar && (validChars.includes(nextChar) || nextChar === ")")) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false; // ')' followed by a number, '!',
        }
      } else if (validOperators.includes(char)) {
        if (!prevChar || !nextChar) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false; // Operator at beginning or end of expression
        }

        if (
          validOperators.includes(prevChar) ||
          validOperators.includes(nextChar)
        ) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false; // Two operators in a row
        }
      } else if (!validChars.includes(char) && char !== " ") {
        if (setExpressionValid) {
          setExpressionValid(false);
        }
        return false; // Invalid character
      }

      if (char === "!") {
        if (
          prevChar === "!" ||
          (prevChar && validChars.includes(prevChar)) ||
          // (prevChar && openingBrackets.includes(prevChar)) ||
          (prevChar && closingBrackets.includes(prevChar))
        ) {
          if (setExpressionValid) {
            setExpressionValid(false);
          }
          return false; // Two consecutive '!' characters without an operator in between or number preceeded by '!' without an operator
        }
      }

      prevChar = char;
    }

    if (
      stack.length > 0 ||
      prevChar === "(" ||
      validOperators.includes(prevChar || "") ||
      validOperators.includes(expression[expression.length - 1])
    ) {
      if (setExpressionValid) {
        setExpressionValid(false);
      }
      return false; // Not all opening parentheses have a matching closing parenthesis, last character is an operator or open parenthesis, or operator at end of string
    }

    if (expression.includes("()")) {
      if (setExpressionValid) {
        setExpressionValid(false);
      }
      return false; // Check if "()" is present in the string
    }
    if (setExpressionValid) {
      setExpressionValid(true);
    }
    return true;
  };

  // Taking input from the user

  // const expression = "1*(2+3)*(92/7)";

  // if (isValidMathExpression(expression)) {
  //   console.log("Valid mathematical expression.", expression);
  // } else {
  //   console.log("Invalid mathematical expression.", expression);
  // }

  // console.log(
  //   replaceAndExtractVariables(expression).replacedString,
  //   isValidMathExpression(replaceAndExtractVariables(expression).replacedString)
  // );

  const menu = (
    <Menu
      style={{ height: 240, overflow: "auto" }}
      items={[
        ...menuItems.map((item) => {
          return {
            label: (
              <p
                onClick={() => {
                  setExpression(
                    (expression) => `${expression}{${item.variable}}`
                  );
                  setDropDownVisible(false);
                }}>
                {item.label}
              </p>
            ),
            key: item.key,
          };
        }),
      ]}
    />
  );

  return (
    <Dropdown
      overlay={menu}
      visible={dropDownVisible}
      // onVisibleChange={(visible) => setDropDownVisible(visible)}
    >
      <CustomInput
        label={label ?? null}
        value={expression}
        onChange={(e) => {
          if (e.target.value.includes("@")) {
            return setDropDownVisible(true);
          }

          setExpression(e.target.value);
        }}
        onBlur={(e) => {
          setTimeout(() => {
            setDropDownVisible(false);
          }, 500);
        }}
        // onPaste={(e) => {
        //   e.preventDefault();
        //   return false;
        // }}
        error={
          !isValidMathExpression(
            replaceAndExtractVariables(expression).replacedString
          )
            ? "Invalid Expression"
            : undefined
        }
      />
    </Dropdown>
  );
};

export default CustomMetricInput;
