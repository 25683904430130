import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

interface archiveCampaignProps {
  campaignId: string;
  isArchived: boolean;
}

export const archiveCampaign = ({
  campaignId,
  isArchived,
}: archiveCampaignProps) => {
  //   const queryKey = ["campaign", "create"];
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/archive?campaignId=${campaignId}&isArchived=${isArchived}`,
    false,
    {}
  ).then((res) => res.data);
  //   return [queryKey, queryFn];
};
