import { sendRequest } from "../helper";

interface verifyOtpProps {
  otp: string;
}

export const verifyOtp = ({ otp }: verifyOtpProps) => {
  return sendRequest("put", `/brand/marketing/validate-email`, false, {
    otp,
  }).then((res) => res.data);
};
