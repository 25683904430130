export const formatCurrency = (
  userNumber: number | string,
  currencyCode: "INR" | "USD"
) => {
  // Convert number to string and remove commas and currency symbol if present
  // const numStr = userNumber
  //   .toString()
  //   .replace(/,/g, "")
  //   .replace(/[^\d.-]/g, "");

  // // Define currency symbols
  // const currencySymbols = {
  //   INR: "₹",
  //   USD: "$",
  // };

  // // Check if the provided currency code is valid
  // if (!currencySymbols[currencyCode]) {
  //   return "Invalid currency code";
  // }

  // // Customize formatting based on currency code
  // let formattedNumber;
  // if (currencyCode === "INR") {
  //   formattedNumber = numStr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  // } else {
  //   formattedNumber = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  // return currencySymbols[currencyCode] + formattedNumber;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(Number(userNumber));
};

// Example usage:
// Output: $9,876,543.21
