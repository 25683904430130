import { sendRequest } from "../helper";

interface getInvitedInfluencerCountResponse {
  status: string;
  message: string;
  data: { count: number };
}

export const getInvitedInfluencerCount = ({
  inviteBy = "",
}: {
  inviteBy?: string;
}) => {
  const queryKey = ["getInvitedInfluencers-count", inviteBy];

  const queryFunction = () =>
    sendRequest<getInvitedInfluencerCountResponse>(
      "get",
      `/brand/connect-request/influencer-connect-request-count${
        inviteBy ? `?inviteBy=${inviteBy}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
