import React, { useState } from "react";
import { Button, Form, Input, notification, Row, Col, Divider } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { useQueryClient } from "react-query";

import { HiArrowRight } from "react-icons/hi";

import { useUserAuth } from "../../../context/userAuthcontext";

import { validateEmail } from "../../../utils/validEmail";

import { logBackend } from "../../../handler/logBackend";

import LOGO from "./../../assets/logo.svg";

import styles from "../Onboarding1.module.css";
import Marquee from "react-fast-marquee";
import FCLogo from "./../../assets/brand-logo/FacesCanada.svg";
import VEGA from "./../../assets/brand-logo/vega.svg";
import HIMALAYA from "./../../assets/brand-logo/himalaya.svg";
import MAMAEARTH from "./../../assets/brand-logo/mamaearth.svg";
import REEQUIL from "./../../assets/brand-logo/reequil.svg";
import RENEE from "./../../assets/brand-logo/renee.svg";
import CULT from "./../../assets/brand-logo/cult.svg";

import { sleep } from "../../../utils";

export const LOGO_DATA: Pick<Source, "source" | "text">[] = [
  {
    source: HIMALAYA as string,
    text: "himalaya logo",
  },
  {
    source: VEGA as string,
    text: "vega logo",
  },
  {
    source: REEQUIL as string,
    text: "reequil logo",
  },
  {
    source: FCLogo as string,
    text: "faces canada logo",
  },
  {
    source: CULT as string,
    text: "Cult fit logo",
  },
  {
    source: MAMAEARTH as string,
    text: "mamaearth logo",
  },
  {
    source: RENEE as string,
    text: "renee logo",
  },
];

export const FEATURE_DATA: string[] = [
  "🎯 Precision Matchmaking: Find the perfect influencers who genuinely resonate with your target audience, boosting your ROAS.",
  "📈 Comprehensive Analytics: Access in-depth insights to make data-driven decisions and optimize your campaigns.",
  "🔍 Advanced Reporting: Monitor campaign performance with detailed reports for continuous improvement.",
  "⌛ Time Tracking: Keep campaigns on schedule with a user-friendly timeline.",
  "💸 Secure Payments: Enjoy hassle-free transactions and foster trust with influencers.",
];

type Props = {
  text: string;
};

type Source = {
  source: string;
  text: string;
  width?: string | number;
  height?: string | number;
};

export const FeatureText: React.FC<Props> = ({ text }): React.ReactElement => {
  const [one, two] = text.split(":");
  return (
    <h2 className={styles.sidebox__feature}>
      {one}: <span>{two}</span>
    </h2>
  );
};

export const BrandLogo: React.FC<Source> = ({
  source,
  text,
  width,
  height,
}): React.ReactElement => {
  return (
    <div className={styles.sidebox__brand}>
      <img
        src={source}
        alt={text}
        className={styles.sidebox__brand__image}
        width={width}
        height={height}
      />
    </div>
  );
};

const auth = getAuth();

const Signup: React.FC = () => {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const initialMailValue = searchParams.get("email") || "";
  const hash = searchParams.get("hash") || "";

  const [form] = Form.useForm();

  const { signup, googleSignUp } = useUserAuth();
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const onFinish = (values: any) => {
    form.setFields([
      {
        name: "email",
        errors: validateEmail(values.email) ? [] : ["email is not valid"],
      },
      {
        name: "password",
        errors: values.password
          ? String(values.password).length > 7
            ? []
            : ["password length too short"]
          : ["password required"],
      },
    ]);
    if (
      validateEmail(values.email) &&
      String(values.password).length >= 6 &&
      values.password
    ) {
      setLoading(true);
      signup(values.email, values.password)
        .then(async (userCred) => {
          if (hash) {
            // await sleep(5000);
            await auth.currentUser?.reload();
            await sleep(2000);
            await queryClient.invalidateQueries();
            // navigate("/activate", { replace: true });
          }
          if (!userCred.user.emailVerified && !hash)
            return navigate("/verifyMail", { replace: true });

          // return navigate("/activate", { replace: true });
        })
        .catch(async (error) => {
          let errorCode = error.code;
          console.log("sign-up-error", error.message);

          if ((error.message as string).includes("email-already-in-use")) {
            return openNotification(
              "Account already exists, please Log in instead"
            );
          }

          if ((error.message as string).includes("PERMISSION_DENIED")) {
            return openNotification(
              "Please use your company account to sign up"
            );
          }
          await logBackend("firebase-signup", "", {
            err: error.message,
            email: values.email,
            password: values.password,
          });

          return openNotification("Some error occurred please retry");
          // navigate(`/login?email=${mail}`, { replace: true });
        })
        .finally(() => setLoading(false));
    }
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      const cred = GoogleAuthProvider.credential(credentialResponse.credential);

      // Sign in with credential from the Google user.
      return signInWithCredential(auth, cred)
        .then((user) => {
          if (!user.user.emailVerified)
            return navigate("/verifyMail", { replace: true });
          return navigate("/discover", { replace: true });
        })
        .catch(async (err) => {
          if ((err.message as string).includes("disabled")) {
            return openNotification("This account is disabled by the admin");
          }

          if ((err.message as string).includes("Unauthorized email")) {
            return openNotification("Please use a company mail to signUp");
          } else {
            openNotification("Some error occurred please retry");
          }
          await logBackend("google-err-onetap-signup", "", {
            err: JSON.stringify(err),
          });
        });
    },
    onError: () => {
      console.log("something went wrong");
    },
  });

  const handleGoogleSignIn = () => {
    googleSignUp()
      .then((user) => {
        if (!user.user.emailVerified)
          return navigate("/verifyMail", { replace: true });
        return navigate("/discover", { replace: true });
      })
      .catch(async (err) => {
        console.log("google err", err, "1", err.code, "2", err.message);
        if ((err.message as string).includes("Unauthorized email")) {
          openNotification("Please use a company mail to signIn");
        } else {
          openNotification("Some error occurred please retry");
        }
        await logBackend("google-err", "", {
          err: JSON.stringify(err),
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const openNotification = (message: string) => {
    notification["error"]({
      message,
      placement: "top",
    });
  };

  return (
    <main className={styles.main}>
      <section className={styles.container}>
        <div className={styles.block}>
          <div className={styles.logo}>
            <img src={LOGO as string} alt="cloutflow logo" />
          </div>
          <Row justify="center">
            <Col span={8} style={{ maxWidth: "560px" }}>
              <div style={{ marginBottom: "24px" }}>
                <h2>Get Started Now</h2>
                {/* <p style={{ opacity: 0.5 }}>
                  Register for free to start influencing..
                </p> */}
              </div>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                requiredMark={false}
                form={form}>
                <div style={{ display: "grid", gap: "10px" }}>
                  <Form.Item
                    label="Enter your work Email"
                    name="email"
                    className={styles.label}
                    initialValue={initialMailValue}
                    // rules={[
                    //   {
                    //     pattern:
                    //       /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/,
                    //     message: "Please enter a valid company mail",
                    //   },
                    // ]}

                    // {...formItemLayout}
                  >
                    <Input
                      placeholder="e.g. stefan@cloutflow.com"
                      className={styles.input}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Set Your Password"
                    className={styles.label}
                    name="password"
                    // extra="Password should contain at least 6 characters"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a Password",
                      },
                      {
                        min: 6,
                        message: "Password length should be greater than 6",
                      },
                    ]}
                    hasFeedback>
                    <Input.Password
                      placeholder="Password"
                      className={styles.input}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Retype Your Password"
                    className={styles.label}
                    name="rePassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please retype your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    hasFeedback>
                    <Input.Password
                      placeholder="Re-type Password"
                      className={styles.input}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className={styles.submitButton}
                      block
                      size="large"
                      htmlType="submit"
                      loading={loading}
                      type="primary"

                      // onClick={() => setIsModalVisible(true)}
                    >
                      Register
                      {/* <HiArrowRight size={"24px"} /> */}
                    </Button>
                    <Divider plain>Or, Sign up with</Divider>
                    <Button
                      className={styles.googleButton}
                      block
                      size="large"
                      onClick={handleGoogleSignIn}
                      style={{ marginTop: "0" }}>
                      <img
                        src="/assets/icons/google.svg"
                        // style={{ marginRight: "10px" }}
                        alt="google logo"
                      />
                      Sign up with Google
                    </Button>

                    <Row style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <p className={styles.footer}>
                          By continuing, you agree to Cloutflow’s{" "}
                          <a
                            className={styles.highlight}
                            href="https://cloutflow.com/terms-condition"
                            target="_blank"
                            rel="noreferrer">
                            Conditions of Use
                          </a>{" "}
                          and {""}
                          <a
                            className={styles.highlight}
                            href="https://cloutflow.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer">
                            Privacy policy
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <p
                      className={styles.helpTool}
                      style={{ marginTop: "15px" }}>
                      Have an account?{" "}
                      <span
                        className={styles.resend}
                        onClick={() => navigate("/login", { replace: true })}>
                        Sign In
                      </span>
                    </p>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
        {/* <div className={styles.block}>
          <div className={styles.sidebox}>
            <h1 className={styles.sidebox__heading}>
              Unlock your brand's potential with Cloutflow, the platform that
              simplifies influencer marketing and drives success with:
            </h1>
            <div>
              {FEATURE_DATA.map((item, idx) => {
                return <FeatureText key={idx} text={item} />;
              })}
            </div>
            <Marquee className={styles.sidebox__marquee} autoFill>
              {LOGO_DATA.map((item, idx) => {
                return (
                  <BrandLogo
                    key={idx}
                    source={item.source}
                    text={item.text}
                    width={120}
                    height={120}
                  />
                );
              })}
            </Marquee>
          </div>
        </div> */}
      </section>
    </main>
  );
};

export default Signup;
