import { Badge, Drawer, Empty, Row, Spin, Tabs } from "antd";
import React from "react";
import { Notification as NotificationData } from "../../../../typings/Notification";
import styles from "./notification.module.css";
import parse from "html-react-parser";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import Cookies from "universal-cookie";

import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { firebaseDb } from "../../../../firebase";
import { useUserAuth } from "../../../../context/userAuthcontext";
import { useNavigate } from "react-router-dom";

import { getNavigateUrl, getIcon } from "./utils";

interface NotificationProps {
  notificationVisible: boolean;
  setNotificationVisible: (val: boolean) => void;
  setNewNotificationsCount: (val: number) => void;
  setNotifications: (val: NotificationData[]) => void;
  setReminderNotifications: (val: NotificationData[]) => void;
  newNotificationsCount: number;
  notifications: NotificationData[];
  reminderNotifications: NotificationData[];
  isLoading: boolean;
  fetchNextPage: () => void;
  fetchNextPageReminder: () => void;
  hasMore: boolean;
  hasMoreReminders: boolean;
  setVisible: (val: boolean) => void;
  setIsFirstTime: (val: boolean) => void;
}

const Notification: React.FC<NotificationProps> = ({
  notificationVisible,
  setNotificationVisible,
  setNewNotificationsCount,
  newNotificationsCount,
  notifications,
  setNotifications,
  isLoading,
  fetchNextPage,
  hasMore,
  setVisible,
  setIsFirstTime,
  fetchNextPageReminder,
  reminderNotifications,
  setReminderNotifications,
  hasMoreReminders,
}) => {
  const { currentUser } = useUserAuth();
  const cookies = new Cookies();

  const navigate = useNavigate();
  const onClose = () => {
    setNotificationVisible(false);
  };

  const changeRead = async (isRead: boolean, id: string) => {
    const docRef = doc(firebaseDb, "Notifications", id);
    updateDoc(docRef, { isRead: !isRead }).then((_doc) => {
      if (isRead === false) setNewNotificationsCount(newNotificationsCount - 1);
      else setNewNotificationsCount(newNotificationsCount + 1);

      const newData = notifications.map((obj) => {
        if (obj.id === id) {
          return { ...obj, isRead: !isRead };
        }
        return obj;
      });

      setNotifications(newData);
    });
  };
  const changeReadRemainders = async (isRead: boolean, id: string) => {
    const docRef = doc(firebaseDb, "Notifications", id);
    updateDoc(docRef, { isRead: !isRead }).then((_doc) => {
      if (isRead === false) setNewNotificationsCount(newNotificationsCount - 1);
      else setNewNotificationsCount(newNotificationsCount + 1);

      const newData = reminderNotifications.map((obj) => {
        if (obj.id === id) {
          return { ...obj, isRead: !isRead };
        }
        return obj;
      });

      setReminderNotifications(newData);
    });
  };
  const markAllRead = async () => {
    const q = query(
      collection(firebaseDb, "Notifications"),
      where("userId", "==", cookies.get("brandId") || currentUser?.uid)
    );

    const docs = await getDocs(q);
    const batch = writeBatch(firebaseDb);

    docs.forEach((ele) => {
      const docRef = doc(firebaseDb, "Notifications", ele.id);
      batch.update(docRef, { isRead: true });
    });
    await batch
      .commit()
      .then(() => {
        setNewNotificationsCount(0);
        const newData = notifications.map((obj) => {
          return { ...obj, isRead: true };
        });

        setNotifications(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Drawer
      title={<p style={{ fontWeight: 700, fontSize: "16px" }}>Notifications</p>}
      placement="right"
      onClose={onClose}
      visible={notificationVisible}
      bodyStyle={{ overflowY: "hidden", padding: "0px" }}
      extra={
        <p onClick={() => markAllRead()} className={styles.extra}>
          Mark all as read
        </p>
      }>
      {/* <div style={{ height: "70vh", overflowY: "scroll" }} className=""> */}

      <Tabs defaultActiveKey="1" style={{ padding: "0 20px " }}>
        <Tabs.TabPane tab="All" key="1">
          <InfiniteScroll
            next={() => {
              fetchNextPage();
            }}
            inverse={false}
            hasMore={hasMore}
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            height={"80vh"}
            dataLength={notifications.length}>
            {notifications?.length === 0 && (
              <Empty description="No notifications" />
            )}
            {notifications.map((notification) => (
              <div className={styles.notification} key={notification.id}>
                <Badge
                  dot={!notification.isRead ? true : false}
                  style={{ left: "-20px", top: "10px" }}
                  color="blue">
                  <div className={styles.details}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        changeRead(false, notification.id);
                        const route = getNavigateUrl(
                          notification.type,
                          notification.ref
                        );
                        if (route === "kyc") {
                          setVisible(true);
                          return setIsFirstTime(false);
                        }
                        navigate(route);
                      }}
                      className="">
                      {parse(notification.msg)}
                    </div>

                    <div className={styles.extraOptions}>
                      <span>
                        {format(
                          new Date(
                            notification.createdDateTime.toDate() as string
                          ),
                          "LLL dd, yyyy "
                        )}
                        at
                        {format(
                          new Date(
                            notification.createdDateTime.toDate() as string
                          ),
                          " hh:mm a"
                        )}
                      </span>
                    </div>
                  </div>
                </Badge>

                <img
                  className={styles.profile}
                  src={
                    getIcon(notification.type) === ""
                      ? `${notification.logo}`
                      : `${getIcon(notification.type)}`
                  }
                  alt=""
                />
              </div>
            ))}
          </InfiniteScroll>
        </Tabs.TabPane>

        {/* <Tabs.TabPane tab="Reminders" key="2">
          <div id="scrollableDivReminders">
            <InfiniteScroll
              next={() => {
                fetchNextPageReminder();
              }}
              inverse={false}
              hasMore={hasMoreReminders}
              loader={
                <Row justify="center">
                  <Spin />
                </Row>
              }
              height={"80vh"}
              dataLength={reminderNotifications.length}
              scrollableTarget="scrollableDivReminders">
              {reminderNotifications?.length === 0 && (
                <Empty description="No Reminders" />
              )}
              {reminderNotifications.map((notification) => (
                <div className={styles.notification} key={notification.id}>
                  <Badge
                    dot={!notification.isRead ? true : false}
                    style={{ left: "-20px", top: "10px" }}
                    color="blue">
                    <div className={styles.details}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          changeReadRemainders(false, notification.id);
                          const route = getNavigateUrl(
                            notification.type,
                            notification.ref
                          );
                          if (route === "kyc") {
                            setVisible(true);
                            return setIsFirstTime(false);
                          }
                          navigate(route);
                        }}
                        className="">
                        {parse(notification.msg)}
                      </div>

                      <div className={styles.extraOptions}>
                        <span>
                          {format(
                            new Date(
                              notification.createdDateTime.toDate() as string
                            ),
                            "LLL dd, yyyy "
                          )}
                          at
                          {format(
                            new Date(
                              notification.createdDateTime.toDate() as string
                            ),
                            " hh:mm a"
                          )}
                        </span>
                      </div>
                    </div>
                  </Badge>

                  <img
                    className={styles.profile}
                    src={
                      getIcon(notification.type) === ""
                        ? `${notification.logo}`
                        : `${getIcon(notification.type)}`
                    }
                    alt=""
                  />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </Tabs.TabPane> */}
      </Tabs>
    </Drawer>
  );
};

export default Notification;
