import { sendRequest } from "../../helper";

export type inviteInfluencerProps = {
  dealId: string;
  socialMediaKitId: string;
};

export const inviteInfluencer = (details: inviteInfluencerProps) => {
  return sendRequest("post", `/brand/campaign/offer/invite-deal`, false, {
    ...details,
  }).then((res) => res.data);
};
