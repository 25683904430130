import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Menu,
  Empty,
  Popconfirm,
  Timeline,
  Dropdown,
  Checkbox,
  Card,
  Alert,
  Divider,
  Row,
  message,
} from "antd";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import {
  updateCampaignTimeline,
  UpdateCampaignTimelineProps,
} from "../../../../handler/cloutflowCampaign/updateDefaultTimeline";

const deliverableOptions = [
  "Reel",
  "Story",
  "Post",
  "Shorts",
  "Youtube video",
  "Others",
];

interface CampaignSettingsPageProps {
  campaign: {
    _id: string;
    timeline: Array<{
      deliverable: string;
      tasks: Array<{
        type: string;
        isApprovalRequired: boolean;
      }>;
    }>;
  };
}

const TimelineSettings: React.FC<CampaignSettingsPageProps> = ({
  campaign,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [timeline, setTimeline] = useState(campaign.timeline);
  const [selectedDeliverable, setSelectedDeliverable] = useState<string>("");
  const [currentDeliverable, setCurrentDeliverable] = useState<number>(0);

  const mutation = useMutation(updateCampaignTimeline, {
    onSuccess: () => {
      message.success("Campaign timeline updated successfully");
      queryClient.invalidateQueries(["campaign", campaign._id]);
    },
    onError: (error: any) => {
      message.error(error.message);
    },
  });

  const handleMenuClick = (e: any) => {
    setSelectedDeliverable(e.key);
    if (e.key !== "Others") {
      handleAddDeliverable(e.key);
    } else {
      handleAddDeliverable("");
    }
  };

  const handleAddDeliverable = (type: string) => {
    setTimeline([...timeline, { deliverable: type, tasks: [] }]);
    setSelectedDeliverable("");
    setCurrentDeliverable(timeline.length);
  };

  const handleAddTask = (index: number) => {
    const newTimeline = [...timeline];
    newTimeline[index].tasks.push({ type: "", isApprovalRequired: false });
    setTimeline(newTimeline);
  };

  const handleTaskChange = (
    deliverableIndex: number,
    taskIndex: number,
    field: string,
    value: any
  ) => {
    const newTimeline: any = [...timeline];
    newTimeline[deliverableIndex].tasks[taskIndex][field] = value;
    setTimeline(newTimeline);
  };

  const handleDeliverableChange = (index: number, value: string) => {
    const newTimeline = [...timeline];
    newTimeline[index].deliverable = value;
    setTimeline(newTimeline);
  };

  const handleDeleteTask = (deliverableIndex: number, taskIndex: number) => {
    const newTimeline = [...timeline];
    newTimeline[deliverableIndex].tasks.splice(taskIndex, 1);
    setTimeline(newTimeline);
  };

  const handleDeleteDeliverable = (index: number) => {
    const newTimeline = [...timeline];
    newTimeline.splice(index, 1);
    setTimeline(newTimeline);
    if (newTimeline.length > 0) {
      setCurrentDeliverable(
        currentDeliverable >= newTimeline.length
          ? newTimeline.length - 1
          : currentDeliverable
      );
    } else {
      setCurrentDeliverable(0);
    }
  };

  const handleSave = () => {
    for (let deliverable of timeline) {
      if (!deliverable.deliverable.trim()) {
        message.error("Deliverable type cannot be empty.");
        return;
      }
      for (let task of deliverable.tasks) {
        if (!task.type.trim()) {
          message.error("Task type cannot be empty.");
          return;
        }
      }
    }

    const updateData: UpdateCampaignTimelineProps = {
      campaignId: campaign._id,
      timeline,
    };
    mutation.mutate(updateData);
  };

  useEffect(() => {
    setTimeline(campaign.timeline);
  }, [campaign]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      {deliverableOptions.map((option) => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <section>
      <Alert
        message="Create Tasks"
        description="Add deliverable's, then the tasks that are required to be completed for it. Each task can require approval. These timelines can later be added to Influencers in execution."
        type="info"
        showIcon
        style={{ marginBottom: 16, borderRadius: "8px" }}
        closable
      />
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
      >
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            type="default"
            style={{ marginBottom: 16, borderRadius: "8px" }}
            onClick={(e) => e.preventDefault()}
          >
            Add Deliverable <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <Card style={{ borderRadius: "10px" }}>
        {timeline.length !== 0 && (
          <Menu
            onClick={(e) => setCurrentDeliverable(parseInt(e.key))}
            selectedKeys={[currentDeliverable.toString()]}
            mode="horizontal"
          >
            {timeline.map((item, index) => (
              <Menu.Item key={index.toString()}>
                {item.deliverable}
                <Popconfirm
                  title="Are you sure to delete this deliverable?"
                  onConfirm={() => handleDeleteDeliverable(index)}
                >
                  <DeleteOutlined style={{ marginLeft: 8 }} />
                </Popconfirm>
              </Menu.Item>
            ))}
          </Menu>
        )}

        {timeline.length === 0 ? (
          <Empty description="Add a deliverable" />
        ) : (
          <div style={{ marginTop: 24 }}>
            <Form form={form} layout="vertical" style={{ width: "100%" }}>
              <Form.Item
                label="Deliverable Type"
                labelCol={{ style: { fontWeight: "bold" } }}
              >
                <Input
                  value={timeline[currentDeliverable]?.deliverable || ""}
                  onChange={(e) =>
                    handleDeliverableChange(currentDeliverable, e.target.value)
                  }
                  placeholder="Deliverable ex: Youtube Dedicated Video"
                />
              </Form.Item>
              <Form.Item
                label="Tasks"
                labelCol={{ style: { fontWeight: "bold" } }}
              >
                <Timeline>
                  {timeline[currentDeliverable]?.tasks.map(
                    (task: any, taskIndex: any) => (
                      <Timeline.Item
                        key={taskIndex}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "400px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "400px",
                          }}
                        >
                          <Input
                            placeholder="Create a task, for example: Send a draft"
                            value={task.type}
                            onChange={(e) =>
                              handleTaskChange(
                                currentDeliverable,
                                taskIndex,
                                "type",
                                e.target.value
                              )
                            }
                            style={{ marginBottom: 8, width: "80%" }}
                          />
                          <DeleteOutlined
                            style={{
                              color: "red",
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDeleteTask(currentDeliverable, taskIndex)
                            }
                          />
                        </div>
                        <Checkbox
                          checked={task.isApprovalRequired}
                          onChange={(e) =>
                            handleTaskChange(
                              currentDeliverable,
                              taskIndex,
                              "isApprovalRequired",
                              e.target.checked
                            )
                          }
                          style={{ marginBottom: 8 }}
                        >
                          Approval required for this task
                        </Checkbox>
                      </Timeline.Item>
                    )
                  )}
                  <Timeline.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAddTask(currentDeliverable)}
                    >
                      Add Task
                    </Button>
                  </Timeline.Item>
                </Timeline>
              </Form.Item>
            </Form>
          </div>
        )}
      </Card>
      <Divider />
      <Row justify="end">
        <Button
          style={{ marginRight: 8, borderRadius: 8 }}
          onClick={() => form.resetFields()}
        >
          Cancel
        </Button>
        <Button type="primary" style={{ borderRadius: 8 }} onClick={handleSave}>
          Save
        </Button>
      </Row>
    </section>
  );
};

export default TimelineSettings;
