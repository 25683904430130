import { sendRequest } from "../../helper";
import { FinalizedResponse } from "../../../typings";

interface getFinalizedApplicantProps {
  pageNo: string;
  sortOrder: "Asc" | "Desc";
  campaignId: string;
  text: string;
  isRequirementNotSent: boolean;
  isDraftWaitingForReview: boolean;
  isLiveLinkWaitingForReview: boolean;
  isPaidToShow: boolean;
  status?: string;
}

export const getFinalizedApplicant = ({
  pageNo,
  sortOrder,
  campaignId,
  text,
  isDraftWaitingForReview,
  isLiveLinkWaitingForReview,
  isRequirementNotSent,
  status,
  isPaidToShow,
}: getFinalizedApplicantProps) => {
  const queryKey = [
    "applicants",
    campaignId.toString(),
    "page",
    pageNo.toString(),
    "finalized",
    sortOrder.toString(),
    {
      isDraftWaitingForReview,
      isLiveLinkWaitingForReview,
      isRequirementNotSent,
      isPaidToShow,
    },
    status,
    text,
  ];

  const queryFunction = () =>
    sendRequest<FinalizedResponse>(
      "put",
      `/brand/execution/finalized-search`,
      false,
      {
        campaignId,
        pageNo,
        limit: "5",
        text,
        isDraftWaitingForReview,
        isLiveLinkWaitingForReview,
        isRequirementNotSent,
        status,
        isPaidToShow,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
