import React from "react";

import TableHead from "./TableHead";
import TableRow from "./TableRow";

import { CampaignsResponse } from "../../../handler/report";
import { CampaignsResponseAll } from "../../../handler/campaign";

type TableProps = {
  loading: boolean;
  data?: CampaignsResponseAll;
  campaignkey: (string | number | boolean)[];
  campaignCountKey: (string | number | boolean)[];
  campaigns?: CampaignsResponse;
};

const CampaignTable: React.FC<TableProps> = (props) => {
  const { campaigns, campaignkey, campaignCountKey, loading, data } = props;

  return (
    <div>
      <TableHead />
      {!loading && data && (
        <>
          {campaigns?.data.map((item, idx) => {
            return (
              <TableRow
                campaignkey={campaignkey}
                campaignCountKey={campaignCountKey}
                key={idx}
                campaign={item}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
export default CampaignTable;
