import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type deleteDeliverableProps = {
  campaignId: string;
  deliverableID: string;
};

export const deleteDeliverable = ({
  campaignId,
  deliverableID,
}: deleteDeliverableProps) => {
  return sendRequest<CampaignResponse>(
    "delete",
    `/brand/campaign/create-campaign-form/deliverable/${campaignId}/${deliverableID}`,
    false
  ).then((res) => res.data);
};
