import React from "react";
import { Space, Radio, Upload, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import { useQuery } from "react-query";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";
import { auth } from "../../../../../../firebase";

import CustomInput from "../../../../../shared/Custom/Input";
// import ChipInput from "../../../../../shared/ChipInput";

import { UserType } from "..";

// import { getNiche } from "../../../../../../handler/discovery";

import styles from "../styles.module.css";

interface BusinessDetailsProps {
  inputValues: UserType;
  errors: UserType;
  setInputValues: React.Dispatch<React.SetStateAction<UserType>>;
  setErrors: React.Dispatch<React.SetStateAction<UserType>>;
}

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
  inputValues,
  errors,
  setInputValues,
  setErrors,
}) => {
  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/brand/${auth.currentUser?.email}/logo/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setInputValues((inputValues) => ({
            ...inputValues,
            businessLogoUrl: downloadURL,
          }));
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setInputValues((inputValues) => ({
        ...inputValues,
        businessLogoUrl: "",
      }));
    },
  };

  const uploadButton = (
    <div className={styles.uploadButtonContainer}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {inputValues.businessType} Logo</div>
    </div>
  );

  // const handleTileClick = (checked: boolean, item: string) => {
  //   if (!checked) {
  //     if (inputValues.businessNiche.length >= 3) {
  //       return setErrors((errors) => ({
  //         ...errors,
  //         businessNiche: ["You can select upto 3 communities only"],
  //       }));
  //     }
  //     return setInputValues((inputValues) => ({
  //       ...inputValues,
  //       businessNiche: [...inputValues.businessNiche, item],
  //     }));
  //   }
  //   return setInputValues((userData) => {
  //     return {
  //       ...userData,
  //       businessNiche: inputValues.businessNiche.filter(
  //         (nicheItem) => nicheItem !== item
  //       ),
  //     };
  //   });
  // };

  // const [nicheKey, nicheFn] = getNiche("instagram");

  // const { data: nicheData } = useQuery(nicheKey, nicheFn);

  return (
    <div>
      <h1 className={styles.heading}> Business Details:</h1>
      <div style={{ display: "grid", gap: "32px" }}>
        <Radio.Group
          value={inputValues.businessType}
          onChange={(e) =>
            setInputValues((inputValues) => ({
              ...inputValues,
              businessType: e.target.value,
            }))
          }>
          <Radio value={"Brand"}> Brand</Radio>
          <Radio value={"Agency"}> Agency</Radio>
        </Radio.Group>
        {/* {inputValues.businessType === "Agency" ? (
          <>
            <CustomInput
              value={inputValues.businessName}
              onChange={(e) =>
                setInputValues((inputValues) => ({
                  ...inputValues,
                  businessName: e.target.value || "",
                }))
              }
              label={"Agency Name"}
            />
            <p>
              As an agency, kindly ask your brand to create a parent account and
              invite you to access all the platform's features and
              functionalities.
              <br />
              <span> More Info</span>
            </p>
          </>
        ) : ( */}
        <>
          <Row gutter={44}>
            <Col span={4}>
              <CustomInput
                error={errors.businessLogoUrl}
                children={
                  <Upload
                    listType="picture"
                    // style={{ width: "344px !important", height: "149px !important" }}
                    fileList={
                      inputValues.businessLogoUrl
                        ? [
                            {
                              uid: inputValues.businessLogoUrl,
                              name: "logo.png",
                              status: "done",
                              url: inputValues.businessLogoUrl,
                              type: "image/jpeg",
                            },
                          ]
                        : []
                    }
                    {...UploadButtonProps}>
                    {inputValues.businessLogoUrl ? null : uploadButton}
                  </Upload>
                }
              />
            </Col>

            <Col span={8} offset={2}>
              <CustomInput
                label={`${inputValues.businessType} Name`}
                error={errors.businessName}
                value={inputValues.businessName}
                onChange={(e) =>
                  setInputValues((inputValues) => ({
                    ...inputValues,
                    businessName: e.target.value || "",
                  }))
                }
              />
            </Col>
            <Col span={8}>
              <CustomInput
                label="Website URL"
                error={errors.businessWebsiteUrl}
                value={inputValues.businessWebsiteUrl}
                onChange={(e) =>
                  setInputValues((inputValues) => ({
                    ...inputValues,
                    businessWebsiteUrl: e.target.value || "",
                  }))
                }
              />
            </Col>
          </Row>
          {/* <CustomInput
            label={`What communities does this ${inputValues.businessType} belong to?`}
            subLabel="You can select upto 3 communities"
            error={errors.businessNiche[0]}
            children={
              <Space wrap size={8} style={{ marginTop: "8px" }}>
                {nicheData &&
                  nicheData.data.map((item, idx) => {
                    return (
                      <ChipInput
                        isChecked={inputValues.businessNiche.includes(
                          item.name
                        )}
                        label={item.name}
                        handleCheck={handleTileClick}
                        key={idx}
                      />
                    );
                  })}
              </Space>
            }
          /> */}
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default BusinessDetails;
