import React, { useState, useEffect } from "react";
import { Row, Space, Col, InputNumber, Button, message, Select } from "antd";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import CustomInput from "../../shared/Custom/Input";

import { sendOTP } from "../../../handler/sendOTP";
import { verifyOTP } from "../../../handler/verifyOTP";

import styles from "../activate.module.css";

import { UserType } from "..";

import { countryCodeArray } from "../../assets/country_code";

const { Option } = Select;

interface Props {
  inputValues: UserType;
  errors: UserType;
  setInputValues: React.Dispatch<React.SetStateAction<UserType>>;
  setErrors: React.Dispatch<React.SetStateAction<UserType>>;
}

const PersonalDetails: React.FC<Props> = ({
  inputValues,
  errors,
  setErrors,
  setInputValues,
}) => {
  const [isVerifyClicked, setIsVerifyClicked] = useState<boolean>(false);
  const [otpTimer, setOtpTimer] = useState<number>(31);
  const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(
    inputValues.phoneNumber !== "" ? true : false
  );
  const [OTP, setOTP] = useState<string>("");

  const [countryCode, setCountryCode] = useState<string>("+91");

  const sendOTPMutation = useMutation(sendOTP, {
    onSuccess: () => {
      setIsVerifyClicked(true);
      setOtpTimer(30);
      message.success("OTP sent");
    },
    onError: (err: AxiosError) => {
      setIsVerifyClicked(false);
      console.log(err.code, err.message, "error-message", err);
      message.error((err.response?.data as any)?.message);
    },
  });

  const verifyOTPMutation = useMutation(verifyOTP, {
    onSuccess: () => {
      setIsVerifyClicked(false);
      message.success("OTP Verified");
      setInputValues((userData) => {
        return { ...userData, isPhoneVerified: true };
      });
      setErrors((err) => ({ ...err, isPhoneVerified: true }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err.response?.data as any)?.message);
    },
  });

  const phoneInputBefore = () => {
    return (
      <Select
        value={countryCode}
        onChange={(value) => setCountryCode(value)}
        disabled={editPhoneNumber}
        showSearch
        style={{ minWidth: "90px" }}
        filterOption={(input, option) =>
          String(option?.value ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }>
        {countryCodeArray.map((item) => (
          <Option value={item.dial_code} key={item.name}>
            {item.flag} {item.dial_code}
          </Option>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    if (otpTimer > 0 && isVerifyClicked) {
      const interval = setInterval(() => {
        setOtpTimer((otpTimer) => otpTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimer]);

  return (
    <div>
      <p className={styles.heading}>Let us try to know you better</p>
      <p className={styles.subHeading}>You can change these setting anytime</p>
      <Col span={10}>
        <div style={{ display: "grid", gap: "14px" }}>
          <CustomInput
            value={inputValues.name}
            label="First Name"
            error={errors.name}
            onChange={(e) => {
              setErrors((errors) => ({
                ...errors,
                name: "",
              }));
              setInputValues((inputValues) => ({
                ...inputValues,
                name: e.target.value || "",
              }));
            }}
          />
          <CustomInput
            value={inputValues.lastname}
            label="Last Name"
            error={errors.lastname}
            onChange={(e) => {
              setErrors((errors) => ({
                ...errors,
                lastname: "",
              }));
              setInputValues((inputValues) => ({
                ...inputValues,
                lastname: e.target.value || "",
              }));
            }}
          />
          <CustomInput
            value={inputValues.designation}
            label="Designation"
            error={errors.designation}
            onChange={(e) => {
              setErrors((errors) => ({
                ...errors,
                designation: "",
              }));

              setInputValues((inputValues) => ({
                ...inputValues,
                designation: e.target.value || "",
              }));
            }}
          />
          <CustomInput
            value={inputValues.phoneNumber}
            label="Phone Number"
            error={errors.phoneNumber}
            onChange={(e) => {
              setErrors((errors) => ({
                ...errors,
                phoneNumber: "",
              }));

              setInputValues((inputValues) => ({
                ...inputValues,
                phoneNumber: e.target.value || "",
              }));
            }}
            // style={{ width: "53%" }}
            children={
              <>
                <Space>
                  <InputNumber
                    addonBefore={phoneInputBefore()}
                    type={"number"}
                    disabled={editPhoneNumber}
                    maxLength={10}
                    controls={false}
                    placeholder="xxxxxxxxxx"
                    size="large"
                    style={{ width: "300px" }}
                    value={(inputValues?.phoneNumber || "+91-")
                      .split("-")
                      .at(-1)}
                    onPressEnter={(e) => {
                      setIsVerifyClicked(true);
                      setEditPhoneNumber(true);
                      sendOTPMutation.mutate({
                        phoneNumber: inputValues?.phoneNumber.toString(),
                      });
                      setOTP("");
                    }}
                    onChange={(value) => {
                      setErrors((errors) => ({
                        ...errors,
                        phoneNumber: "",
                      }));

                      setInputValues((inputValues) => {
                        return {
                          ...inputValues,
                          phoneNumber: countryCode + "-" + (value || ""),
                        };
                      });
                      setIsVerifyClicked(false);
                    }}
                  />
                  {isVerifyClicked ? (
                    <Button
                      type="text"
                      onClick={() => {
                        setEditPhoneNumber(false);
                        setIsVerifyClicked(false);
                      }}>
                      Edit
                    </Button>
                  ) : (
                    <Button
                      type="text"
                      disabled={
                        inputValues.phoneNumber
                          .split(countryCode + "-")
                          .join("")
                          .trim().length <= 0
                      }
                      onClick={() => {
                        sendOTPMutation.mutate({
                          phoneNumber: inputValues.phoneNumber.toString(),
                        });
                        setOTP("");
                        setEditPhoneNumber(true);
                      }}
                      loading={sendOTPMutation.isLoading}>
                      {" "}
                      Verify
                    </Button>
                  )}
                </Space>
                {isVerifyClicked && (
                  <>
                    <Space>
                      <InputNumber
                        type={"number"}
                        onPressEnter={(e) =>
                          verifyOTPMutation.mutate({
                            OTP: OTP.toString(),
                          })
                        }
                        maxLength={4}
                        placeholder="Enter OTP"
                        controls={false}
                        size="middle"
                        style={{ width: "150px" }}
                        value={OTP}
                        onChange={(value) => {
                          setErrors((err) => ({ ...err, phoneNumber: "" }));
                          setOTP(value || "");
                        }}
                      />
                      <Button
                        type="text"
                        disabled={
                          inputValues.phoneNumber
                            .split(countryCode + "-")
                            .join("")
                            .trim().length <= 0
                        }
                        onClick={() =>
                          verifyOTPMutation.mutate({
                            OTP: OTP.toString(),
                          })
                        }
                        loading={verifyOTPMutation.isLoading}>
                        {" "}
                        Verify
                      </Button>
                    </Space>
                    <div className="">
                      <Button
                        disabled={otpTimer !== 0}
                        onClick={() => {
                          sendOTPMutation.mutate({
                            phoneNumber: inputValues.phoneNumber.toString(),
                          });
                          setOTP("");
                        }}
                        type="text"
                        loading={sendOTPMutation.isLoading}>
                        Resend OTP {otpTimer !== 0 && `in ${otpTimer}`}
                      </Button>
                    </div>
                  </>
                )}
              </>
            }
          />
        </div>
      </Col>
    </div>
  );
};

export default PersonalDetails;
