import { sendRequest } from "../helper";

interface Props {
  priceId: string;
}

export const createCheckoutSession = ({ priceId }: Props) => {
  return sendRequest(
    "post",
    `/brand/saas-billing/stripe/checkout?priceId=${priceId}`,
    false
  ).then((res) => res.data);
};
