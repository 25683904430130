import { createContext, useContext, useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { message } from "antd";
import Sound from "react-sound";
import { useQueryClient } from "react-query";
import Cookies from "universal-cookie";

import { useUserAuth } from "./userAuthcontext";
import { getAllConversations } from "../handler/chat";
import { getAppState } from "../handler/getAppState";

type ContextState = {
  messagesReceived: number;
};

const ChatNotificationContext = createContext<ContextState | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}

export const ChatNotificationContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [messagesReceived, setMessagesReceived] = useState(0);
  const { currentUser } = useUserAuth();
  const queryClient = useQueryClient();
  const [getAllConversationsKey] = getAllConversations();
  const [getAppStateKey] = getAppState();
  const cookies = new Cookies();

  const connectSocket = async () => {
    const token = await currentUser?.getIdToken();
    let headers: any = {
      Authorization: `Bearer ${token}`,
    };
    if (cookies.get("brandId")) {
      headers = {
        ...headers,
        orgId: cookies.get("brandId"),
      };
    }
    const conversationSocket = io(process.env.REACT_APP_GLOBAL_CHAT_URL || "", {
      extraHeaders: headers,
    });
    conversationSocket.on("connect", () => {});
    conversationSocket.on("new-message", (args) => {
      playAudio();
    });
    conversationSocket.on("connect_error", () => {
      console.log("connect_error");
    });
    conversationSocket.on("disconnect", () => {
      // socket?.disconnect();
      conversationSocket.disconnect();
      conversationSocket.close();
      // return setSocket(null);
      // setNewMessage(JSON.parse(args as unknown as string));
      // message.error("Invalid_id");
      // setConversationId("");
    });
    return conversationSocket;
  };
  useEffect(() => {
    if (currentUser !== null) {
      connectSocket();
    }
  }, [currentUser]);

  //   if (currentUser && status) {
  //     connectSocket();
  //   }
  function playAudio() {
    // if (audioElem) {
    setMessagesReceived((message) => message + 1);
    message.success("New Message");
    queryClient.invalidateQueries(getAllConversationsKey);
    queryClient.invalidateQueries(getAppStateKey);

    // }
  }
  //   "http://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3"
  //codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3

  //drive.google.com/uc?authuser=0&id={id}&export=download
  return (
    <ChatNotificationContext.Provider value={{ messagesReceived }}>
      {children}
      {Boolean(messagesReceived) && (
        <Sound
          // https://drive.google.com/file/d/1SRwv93BhM-e_CEttruyBoaU5Hj6Nnnxq/view?usp=share_link
          // https://drive.google.com/uc?authuser=0&id=1FcmjBsPXPcw2O77GR4pQHIQze3BwdMxC&export=download

          url={
            "https://drive.google.com/uc?authuser=0&id=1SRwv93BhM-e_CEttruyBoaU5Hj6Nnnxq&export=download"
          }
          playStatus="PLAYING"

          // playFromPosition={300 /* in milliseconds */}
        />
      )}
    </ChatNotificationContext.Provider>
  );
};

export const useChatAuth = () => {
  const context = useContext(ChatNotificationContext);

  if (!context) {
    throw new Error("useAuth must be used in <AuthProvider />");
  }

  return context;
};
