import React from "react";
import { Button, Typography } from "antd";

const { Title, Paragraph } = Typography;

type TemplateProps = {
  handleMenuChange: (type: any) => void;
};

const Template: React.FC<TemplateProps> = ({ handleMenuChange }) => {
  const handleChangeSettings = () => {
    handleMenuChange("settings");
  };

  const handleDiscover = () => {
    // Navigate to /discover
    window.open("/discover");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Title level={3}>
        We are currently working on your campaign requirements
      </Title>
      <Paragraph>
        You will get suggested Influencers to achieve the best results for your
        campaign, backed by first-party data and AI.
      </Paragraph>
      <Paragraph>
        Right now, you can request pricing for some creators you would like to
        be a part of this campaign, or change settings for this campaign.
      </Paragraph>
      <div style={{ marginTop: "20px" }}>
        <Button
          type="default"
          onClick={handleChangeSettings}
          style={{ marginRight: "10px", borderRadius: "8px" }}
        >
          Change Settings
        </Button>
        <Button
          type="primary"
          onClick={handleDiscover}
          style={{ borderRadius: "8px" }}
        >
          Request Influencer's
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <a
          href="https://cloutflow.com/influencer-marketing-resource/blog/first-party-data"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn about First-Party Data
        </a>
      </div>
    </div>
  );
};

export default Template;
