import React from "react";
import clsx from "clsx";
import { Space } from "antd";
import YouTube from "react-youtube";
import { capitalizeFirstLetter } from "../../../../../../utils";

import styles from "./styles.module.css";

interface DeliverableSelectProps {
  type: "VIDEO" | "IMAGE" | "yt-video";
  url: string;
  id?: string;
  isSelected: boolean;
  handleClick: () => void;
  label?: string;
  isLabelClickEnabled: boolean;
  permaLink?: string;
  username?: string;
  caption?: string;
  profilePic?: string;
}

const DeliverableSelect: React.FC<DeliverableSelectProps> = ({
  type,
  url,
  isSelected,
  handleClick,
  label,
  isLabelClickEnabled,
  permaLink = "",
  username = "",

  caption,
  profilePic,
}) => {
  const getIconUrl = (postType: React.ReactNode | string) => {
    if (postType) {
      const postTypeLowerCase = postType.toString().toLowerCase();

      if (
        postTypeLowerCase === "ig-feed-image" ||
        postTypeLowerCase === "image"
      ) {
        return `/assets/icons/post-${isSelected ? "blue" : "grey"}.svg`;
      } else if (
        postTypeLowerCase === "ig-reel" ||
        postTypeLowerCase === "video" ||
        postTypeLowerCase === "ig-feed-video"
      ) {
        return `/assets/icons/reel-${isSelected ? "blue" : "grey"}.svg`;
      } else if (
        postTypeLowerCase === "ig-feed-carousel" ||
        postTypeLowerCase === "carousel"
      ) {
        return `/assets/icons/carousel-${isSelected ? "blue" : "grey"}.svg`;
      } else if (
        postTypeLowerCase === "ig-feed-story" ||
        postTypeLowerCase === "story" ||
        postTypeLowerCase === "YT-Video" ||
        postTypeLowerCase === "YT-Shorts"
      ) {
        return `/assets/icons/story-${isSelected ? "blue" : "grey"}.svg`;
      } else {
        return `/assets/icons/reel-${isSelected ? "blue" : "grey"}.svg`;
      }
    } else {
      return `/assets/icons/reel-${isSelected ? "blue" : "grey"}.svg`;
    }
  };

  return (
    <section>
      <Space
        size={10}
        align="center"
        className={clsx(styles.label, {
          [styles.selectedLabel]: isSelected,
        })}
        onClick={(e) => {
          if (isLabelClickEnabled && permaLink) {
            return window.open(permaLink, "_blank");
          }
          e.stopPropagation();
        }}>
        {label && (
          <img
            src={getIconUrl(label || "")}
            alt="delliverable"
            width={15}
            height={15}
          />
        )}
        <p>
          {capitalizeFirstLetter(
            label?.toString()?.split("-").at(-1)?.toLowerCase() || ""
          )}
        </p>
      </Space>
      {username && (
        <p style={{ color: "#533AF5", marginBlock: 4 }}>@{username}</p>
      )}
      <div
        className={clsx(styles.container, {
          [styles.selected]: isSelected,
          [styles.imageContainer]: type === "IMAGE",
        })}
        onClick={(e) => {
          handleClick();
          e.stopPropagation();
        }}>
        {type === "IMAGE" && (
          <img src={url} alt="post" className={styles.image} />
        )}
        {type === "VIDEO" && (
          <video
            muted={true}
            src={url}
            controls={false}
            style={{ objectFit: "cover" }}
            width={"100%"}
            height={"100%"}
            className={styles.image}
          />

          // <video className={styles.image}>
          //   <source src={url} type="video/mp4"></source>
          // </video>
        )}
        {type === "yt-video" && (
          <div className={styles.ytContainer}>
            <img alt="yt thumbnail" src={url} className={styles.thumbnail} />
            <Space align="center" size={5} style={{ marginTop: 5 }}>
              <img
                alt="profile pic"
                src={profilePic}
                className={styles.profilePic}
              />{" "}
              <p className={styles.caption}>
                {(caption || "")?.length > 22
                  ? caption?.slice(0, 22) + "..."
                  : caption}
              </p>
            </Space>
          </div>

          // <video className={styles.image}>
          //   <source src={url} type="video/mp4"></source>
          // </video>
        )}
      </div>
    </section>
  );
};

export default DeliverableSelect;
