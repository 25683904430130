import { CampaignOfferCount } from "../../typings";
import { sendRequest } from "../helper";

interface getCampaignOfferCountResponse {
  data: CampaignOfferCount;
  message: string;
  status: string;
}

export const getCampaignOfferCount = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  const queryKey = ["campaigns", "count"];

  const queryFunction = () =>
    sendRequest<getCampaignOfferCountResponse>(
      "get",
      `/brand/campaign/offer-aggregates?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
