export function validateUsername(username: string): [string, boolean] {
  const pattern = /^[a-zA-Z0-9_.]+$/;

  if (pattern.test(username)) {
    if (username.length >= 1 && username.length <= 30) {
      return ["", false];
    } else {
      return ["Username length should be between 1 and 30 characters", true];
    }
  } else {
    return [
      "Invalid username. Usernames can only contain letters, numbers, periods, and underscores.",
      true,
    ];
  }
}
