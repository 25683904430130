import { sendRequest } from "../../helper";

export type finalizeOfferProps = {
  campaignId: string;
  offerIds: string[];
};

type finalizeOfferResults = {
  data: string;
  message: string;
  status: string;
};

export const finalizeOffer = ({ campaignId, offerIds }: finalizeOfferProps) => {
  return sendRequest<finalizeOfferResults>(
    "post",
    `/brand/campaign/offer/finalize-create-payin`,
    false,
    { campaignId, offerIds }
  ).then((res) => res.data);
};
