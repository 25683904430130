import { sendRequest } from "../helper";

export type getWorkflowByMediaIdResult = {
  status: string;
  message: string;
  data: {
    workflowId: string;
    canApplyWorkflowOnMedia: boolean;
    reason: string;
  };
};

type RProps = {
  mediaId: string;
};

export const getWorkflowByMediaId = (props: RProps) => {
  const queryKey = ["smart-replies", "workflows", "media", props.mediaId];
  const queryFunction = () =>
    sendRequest<getWorkflowByMediaIdResult>(
      "GET",
      `/brand/smart-replies/workflow/media?mediaId=${props.mediaId}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
