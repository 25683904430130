import React, { useState, useRef, useEffect } from "react";
import { Col } from "antd";
import { useQuery } from "react-query";

import RemoveMail from "./RemoveMail";
import EmailVerify from "./EmailVerify";

import { getUser } from "../../../handler/getUser";
import { auth } from "../../../firebase";

import styles from "./styles.module.css";
import profileStyles from "../profile.module.css";

const EmailConfig: React.FC<{
  inModal?: boolean;
  handleModalSubmit?: () => void;
}> = ({ inModal = false, handleModalSubmit = () => {} }) => {
  const [selectedSection, setsSelectedSection] = useState<"add" | "remove">(
    "add"
  );

  const [getUserKey, getUserFn] = getUser();

  const { data } = useQuery(getUserKey, getUserFn);

  useEffect(() => {
    if (data) {
      console.log("Invalidated");
      if (data?.data?.brevo?.isSendersMailVerified) {
        if (inModal) {
          return handleModalSubmit();
        }
        return setsSelectedSection("remove");
      }
      return setsSelectedSection("add");
    }
  }, [data?.data?.brevo?.isSendersMailVerified]);

  const firebaseUserId = auth?.currentUser?.uid;

  const isOwner = firebaseUserId === data?.data?._id;

  return (
    <Col span={20} style={{ padding: 20 }}>
      {isOwner ? (
        <>
          {/* {data && !data?.data?.brevo?.isSendersMailVerified && <StaticCard />} */}
          {selectedSection === "add" ? <EmailVerify /> : <RemoveMail />}
        </>
      ) : (
        <h3 className={styles.profileStyles}>
          Contact The Owner of the Organization to add/edit email
        </h3>
      )}
    </Col>
  );
};

export default EmailConfig;

const StaticCard = () => {
  return (
    <div className={styles.staticCard}>
      <p className={styles.staticDesc}>
        This Email is used to invite influencers when you invite them to your
        network
        <br />
        The default Email is send by app@cloutflow.com. <br />
        To increase conversions 📈, you can add your own email-Id
      </p>
    </div>
  );
};
