import { Route, Routes, Navigate } from "react-router-dom";
import PhoneUpdate from "../Onboarding/PhoneUpdate";
import AddOrg from "../Onboarding/AddOrg";
import VerifyOrg from "../Onboarding/VerifyOrg";
import Profile from "../Profile";
import VerifyMail1 from "../Onboarding-1/verifyMail";
import Campaign from "../Campaign";
import Create from "../Campaign/Create";
import AddProduct from "../Campaign/Create/AddProduct";
import CreatorFilters from "../Campaign/Create/CreatorFilters";
import Guidelines from "../Campaign/Create/Guidelines";
import Deliverables from "../Campaign/Create/Deliverables";
import Review from "../Campaign/Create/Review";
import ManageCampaign from "../Campaign/Manage";
import CreateCampaignWrapper from "../Campaign/CreateUpdated";
import Report from "../Campaign/Report";
import Pay from "../Pay";
import ChatTest from "../ChatTest";
import Billing from "../Billing";
import Invite from "../Invite";
import InvitedInfluencers from "../InvitedInfluencers";
import Activate from "../Activate";

import TestComp from "../TestComp";

import CustomSignIn from "../CustomSignIn";

import AlgoliaSearch from "../DiscoverAlgolia";

import InfluencerProfile from "../Influencer";
import ProfileAnalysis from "../ProfileAnalysis";
import Locked from "../Locked";
import Plan from "../Profile/Plan";

import DiscoverV2 from "../DiscoveryV2";
import OrgSelect from "../OrgSelect";
import AcceptInvite from "../AcceptInvite";
import GlobalTrack from "../GlobalTrack";
import Workflows from "../Workflows";
import GlobalCreatorSearch from "../InsightIq";
import CampaignDashboard from "../CloutflowCampaign/Dashboard";

const PrivateRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<Campaign />} />
      <Route path="/locked" element={<Locked />} />
      <Route path="/activate" element={<Activate />} />

      {/* <Route path="/signup" element={<Signup />} /> */}
      {/* <Route path="/login" element={<Signin />} /> */}
      <Route path="/verifyMail" element={<VerifyMail1 />} />

      <Route path="/phoneUpdate" element={<PhoneUpdate />} />
      <Route path="/addOrganization" element={<AddOrg />} />
      <Route path="/verifyOrganization" element={<VerifyOrg />} />

      <Route path="/campaign/create/:id" element={<CreateCampaignWrapper />} />

      <Route path="/campaign/create-old" element={<Create />} />
      <Route path="/campaign/create-old/:id" element={<Create />} />
      <Route
        path="/campaign/create/:id/add-products"
        element={<AddProduct />}
      />
      <Route
        path="/campaign/create-old/:id/add-deliverables"
        element={<Deliverables />}
      />
      <Route
        path="/campaign/create-old/:id/creators"
        element={<CreatorFilters />}
      />
      <Route
        path="/campaign/create-old/:id/guidelines"
        element={<Guidelines />}
      />
      <Route path="campaign/create-old/:id/under-review" element={<Review />} />
      <Route path="/campaign/manage/:id" element={<ManageCampaign />} />
      <Route path="/campaign/manage/report/:campaignId" element={<Report />} />
      <Route
        path="/campaign/manage/report/:campaignId/:socialMediaId"
        element={<Report />}
      />

      {/* Discovery */}
      {/* <Route path="/discover">
        <Route index element={<Discover />} />
        <Route path=":platform" element={<Discover isSearch />} />
      </Route> */}
      <Route path="/pay" element={<Pay />} />
      <Route path="/chat" element={<ChatTest />} />
      <Route path="/billing" element={<Billing />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/invite" element={<Invite />} />
      <Route path="/workflows" element={<Workflows />} />

      <Route path="/invited-influencers" element={<InvitedInfluencers />} />

      <Route path="/public/custom/" element={<CustomSignIn />} />
      <Route path="/public/custom/:uid" element={<CustomSignIn />} />

      <Route path="/test" element={<TestComp />} />
      <Route path="/algolia" element={<AlgoliaSearch />} />
      {/* 
      <Route
        path="/discover"
        element={<Navigate to={"/discover/connected/instagram"} />}
      /> */}
      <Route path="/discover" element={<GlobalCreatorSearch />} />
      <Route
        path="/connected/instagram"
        element={<DiscoverV2 platform="instagram" />}
      />
      <Route
        path="/connected/youtube"
        element={<DiscoverV2 platform="youtube" />}
      />
      <Route
        path="/track/instagram"
        element={<GlobalTrack platform="instagram" />}
      />
      <Route
        path="/track/youtube"
        element={<GlobalTrack platform="youtube" />}
      />

      <Route path="/influencer/:id" element={<InfluencerProfile />} />
      <Route
        path="/influencerAnalysis/:id"
        element={<ProfileAnalysis isQueryCalled />}
      />

      <Route path="/test-plan" element={<Plan />} />

      <Route path="/influencer/" element={<Navigate to="/discover" />} />

      <Route path="/orgSelect" element={<OrgSelect />} />
      <Route path="/acceptInvite" element={<AcceptInvite />} />

      <Route path="/campaign/:id" element={<CampaignDashboard />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PrivateRouting;
