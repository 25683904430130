import React, { useEffect } from "react";
import { Button, Divider, Form, Input, Row, Typography, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  updateCampaign,
  UpdateCampaignProps,
} from "../../../../handler/cloutflowCampaign/updateCampaign";
import { getUser } from "../../../../handler/getUser";
import { getOrgAppState } from "../../../../handler/organization";

const { TextArea } = Input;
const { Text } = Typography;

interface CampaignSettingsPageProps {
  campaign: {
    _id: string;
    name: string;
    requirement?: string;
    notes?: string;
    internalNotes?: string;
  };
}

const BasicSettings: React.FC<CampaignSettingsPageProps> = ({ campaign }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [orgUserKey, orgUserFn] = getOrgAppState();
  const { data: orgUserData, isLoading: orgUserDataLoading } = useQuery(
    orgUserKey,
    orgUserFn
  );

  const mutation = useMutation(updateCampaign, {
    onSuccess: () => {
      message.success("Campaign updated successfully");
      queryClient.invalidateQueries(["campaign", campaign._id]);
    },
    onError: () => {
      message.error("Error updating campaign");
    },
  });

  const onFinish = (values: any) => {
    const updateData: UpdateCampaignProps = {
      campaignId: campaign._id,
      ...values,
    };
    mutation.mutate(updateData);
  };

  return (
    <section>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: campaign.name,
          requirement: campaign.requirement,
          notes: campaign.notes,
          internalNotes: campaign.internalNotes,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Campaign Name"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Name your campaign"
        >
          <Input placeholder="Enter campaign name" />
        </Form.Item>
        <Form.Item
          name="requirement"
          label="Requirement"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Just enter your requirements here, like type of Influencer's, goals
            to achieve etc, & you will get suggested a list of best Influencer's
            to achieve your goals."
        >
          <TextArea rows={4} placeholder="Enter requirements" />
        </Form.Item>
        <Form.Item
          name="notes"
          label="Notes"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Keep notes about your campaign here, it could be anything related to
            the campaign."
        >
          <TextArea rows={4} placeholder="Enter notes" />
        </Form.Item>
        {orgUserData?.data?.accessLevel !== "brand" && (
          <Form.Item
            name="internalNotes"
            label="Internal Notes"
            labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
            help=" Cloutflow users can enter their notes over here, brand cannot see
            these notes."
          >
            <TextArea rows={4} placeholder="Enter internal notes" />
          </Form.Item>
        )}
        <Divider style={{ marginTop: "50px", marginBottom: "25px" }} />
        <Row justify="end">
          <Button
            style={{ marginRight: 8, borderRadius: 8 }}
            onClick={() => form.resetFields()}
          >
            Cancel
          </Button>
          <Button type="primary" style={{ borderRadius: 8 }} htmlType="submit">
            Save
          </Button>
        </Row>
      </Form>
    </section>
  );
};

export default BasicSettings;
