import React from "react";
import { Menu } from "antd";

import { HeaderType } from "..";

interface HeaderProps {
  selectedState: HeaderType;
  setState: React.Dispatch<React.SetStateAction<HeaderType>>;
}

const Header: React.FC<HeaderProps> = ({ selectedState, setState }) => {
  return (
    <Menu
      defaultSelectedKeys={[selectedState]}
      selectedKeys={[selectedState]}
      mode="horizontal"
      style={{ position: "relative" }}
      onSelect={(e) => {
        setState(e.key as HeaderType);
      }}>
      <Menu.Item key={"single"}>Add Single</Menu.Item>
      <Menu.Item key={"bulk"}>Bulk Invite</Menu.Item>
    </Menu>
  );
};

export default Header;
