import { sendRequest } from "./helper";

interface addORGDetailsProps {
  organizationDescription: string;
  organizationName: string;
  organizationLogoUrl?: string;
}

export const addORGDetails = ({
  organizationDescription,
  organizationName,
  organizationLogoUrl = "",
}: addORGDetailsProps) => {
  return sendRequest(
    "post",
    `/brand/me/organization-details`,
    false,
    !organizationLogoUrl
      ? {
          organizationDescription,
          organizationName,
        }
      : {
          organizationDescription,
          organizationName,
          organizationLogoUrl,
        }
  ).then((res) => res.data);
};
