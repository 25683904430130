import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import CustomInput from "../../shared/Custom/Input";
import { useQuery } from "react-query";

import { getUser } from "../../../handler/getUser";

import styles from "./billingSettings.module.css";

import ActivateScreen from "./Modals/ActivateScreen";
import Details from "./Modals/Details";

interface BillingSettingsProps {}

const BillingSettings: React.FC<BillingSettingsProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [queryKey, queryFn] = getUser();

  const { data: userData } = useQuery(queryKey, queryFn);

  return (
    <React.Fragment>
      {userData?.data?.isZohoCustomerCreated ? (
        <>
          <div style={{ margin: "30px 0" }}>
            <Button
              onClick={() => setOpen(true)}
              size="large"
              type="primary"
              ghost
              style={{
                borderRadius: 10,
                height: "55px",
                padding: "10px 32px",
              }}>
              Update Billing Details
            </Button>
            <div style={{ marginTop: "30px" }}>
              <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                <Col span={12}>
                  <CustomInput
                    label="First Name"
                    disabled
                    value={
                      userData?.data.businessDetails?.billing?.personFirstName
                    }
                  />
                </Col>
                <Col span={12}>
                  <CustomInput
                    label="Last Name"
                    disabled
                    value={
                      userData?.data.businessDetails?.billing?.personLastName
                    }
                  />
                </Col>
              </Row>
              <div style={{ margin: "10px 0" }}></div>
              <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                <Col span={10}>
                  <CustomInput
                    label="Company Name"
                    disabled
                    value={userData?.data.businessDetails?.billing?.companyName}
                  />
                </Col>
                <Col span={14}>
                  <CustomInput
                    label="GSTIN"
                    disabled
                    value={userData?.data.businessDetails?.gst}
                  />
                </Col>
              </Row>
              {/* === Billing Address === */}
              <div style={{ margin: "10px 0" }}></div>
              <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                <Col span={24}>
                  <p className={styles.addressLabel}>Billing Address*</p>
                  <div className={styles.addressInput}>
                    <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                      <Col span={12}>
                        <CustomInput
                          placeholder="Street Name"
                          disabled
                          value={
                            userData?.data.businessDetails?.address?.street
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <CustomInput
                          placeholder="Country"
                          disabled
                          value={
                            userData?.data.businessDetails?.address?.country
                          }
                        />
                      </Col>
                    </Row>
                    <div style={{ margin: "10px 0" }}></div>
                    <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                      <Col span={8}>
                        <CustomInput
                          placeholder="State"
                          disabled
                          value={userData?.data.businessDetails?.address?.state}
                        />
                      </Col>
                      <Col span={8}>
                        <CustomInput
                          placeholder="City"
                          disabled
                          value={userData?.data.businessDetails?.address?.city}
                        />
                      </Col>
                      <Col span={8}>
                        <CustomInput
                          placeholder="Pincode"
                          disabled
                          value={
                            userData?.data.businessDetails?.address?.pincode
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.box}>
            <h2 className={styles.heading}>
              Your billing details haven't been added.
            </h2>
            <p className={styles.text}>
              Add your Billing Information and Enable Your Payment Process.
            </p>
            <Button
              onClick={
                userData?.data.signUpStatus === "businessVerified"
                  ? () => setOpen(true)
                  : () => setShow(true)
              }
              size="large"
              type="primary"
              style={{
                borderRadius: 10,
                height: "55px",
                padding: "10px 32px",
              }}>
              Create New
            </Button>
          </div>
        </>
      )}
      <ActivateScreen handleClose={() => setShow(false)} show={show} />
      <Details open={open} userData={userData} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default BillingSettings;
