import React from "react";
import { Space } from "antd";

import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";

import { CreatorType } from "..";

import styles from "./styles.module.css";

const CreatorSection: React.FC<{
  creator: CreatorType;
  containerClass?: string;
}> = ({ creator, containerClass = "" }) => {
  return (
    <div className={containerClass}>
      <Space size={16} align="center">
        <img
          className={styles.profile}
          alt="profile"
          src={creator?.profilePic}
        />
        <div>
          <p className={styles.name}>{creator?.name}</p>
          <Space align="center" size={2}>
            {creator?.platform === "instagram" ? (
              <AiOutlineInstagram
                size={22}
                height={22}
                width={22}
                color="rgba(0, 0, 0, 0.5)"
              />
            ) : (
              <AiOutlineYoutube
                height={22}
                width={22}
                color="rgba(0, 0, 0, 0.5)"
              />
            )}

            <p className={styles.username}>{creator?.username}</p>
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default CreatorSection;
