import { sendRequest } from "../../helper";
import { ApplicantsResponse } from "../../../typings";

interface getAllApplicantsProps {
  pageNumber: number;
  sortOrder: "Asc" | "Desc";
  status:
    | "Applied"
    | "Shortlisted"
    | "Rejected"
    | "Invited"
    | "Finalized"
    | "Completed"
    | "";
  statusLastChangedBy?: "brand" | "influencer";
  campaignId: string;
  sortBy: "none" | "follower" | "engagement";
  text?: string;
}

export const getAllApplicants = ({
  pageNumber,
  sortOrder,
  status,
  statusLastChangedBy = "influencer",
  campaignId,
  sortBy,
  text = "",
}: getAllApplicantsProps) => {
  const queryKey = [
    "applicants",
    campaignId.toString(),
    "page",
    pageNumber.toString(),
    status.toString(),
    sortOrder.toString(),
    sortBy,
    text,
  ];

  const queryFunction = () =>
    sendRequest<ApplicantsResponse>(
      "get",
      `/brand/campaign/offer/${campaignId}?limit=15&pageNo=${pageNumber}&sortOrder=${sortOrder}&status=${status}&sortBy=${sortBy}${
        text ? `&text=${text}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
