import { useState } from "react";
import {
  Drawer,
  Divider,
  Collapse,
  Button,
  Badge,
  Popconfirm,
  message,
} from "antd";
// import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import ProgressIndicator from "../Card/ProgressIndicator";

import RequirementsTile from "./RequirementsTile";
import DraftsTile from "./DraftsTile";
import LinksTile from "./LinksTile";
import ReportTile from "./ReportsTile";
import ReviewModals from "./LinksTile/ReviewModals";

import {
  getFinalizedWorkflow,
  getTimelineDetailsV2,
} from "../../../../../handler/campaign/applicants";

import {
  getFinalizedOfferById,
  releasePayment,
  pushToComplete,
} from "../../../../../handler/campaign/offer";
// import TimelineChat from "./ChatDrawer";
// import {
//   checkConversation,
//   createConversation,
//   getAllConversations,
// } from "../../../../../handler/chat";

import { CommentOutlined, DollarOutlined } from "@ant-design/icons";

import styles from "../finalized.module.css";

interface TimeLineDrawerProps {
  isOpen: boolean;
  applicantId: string;
  handleClose: () => void;
  campaignId: string;
  influencerId: string;
}

const TimeLineDrawer: React.FC<TimeLineDrawerProps> = ({
  isOpen,
  applicantId,
  handleClose,
  campaignId,
  influencerId,
}) => {
  const queryClient = useQueryClient();

  const [reviewModalVisible, setReviewModalVisible] = useState<boolean>(false);

  const [getWorkflowKey, getWorkflowFn] = getFinalizedWorkflow({
    offerId: applicantId,
  });

  const { data } = useQuery(getWorkflowKey, getWorkflowFn, {
    enabled: Boolean(applicantId) && isOpen,
  });
  const [geEscrowStatusKey, getEscrowStatusFn] = getFinalizedOfferById({
    offerId: applicantId,
  });

  const { data: escrowData } = useQuery(geEscrowStatusKey, getEscrowStatusFn, {
    enabled: Boolean(applicantId) && isOpen,
  });

  const { Panel } = Collapse;

  const onTileChange = (key: string | string[]) => {};
  const [getTimelineDetailsKey, getTimelineDetailsFn] = getTimelineDetailsV2({
    finalizedOffersId: applicantId,
  });

  const { data: timelineData } = useQuery(
    getTimelineDetailsKey,
    getTimelineDetailsFn,
    {
      enabled: applicantId !== "",
    }
  );

  const navigate = useNavigate();

  const releasePaymentMutation = useMutation(releasePayment, {
    onSuccess: () => {
      message.success("Payment Released Successfully");
      queryClient.invalidateQueries(geEscrowStatusKey);
      queryClient.invalidateQueries(["applicants"]);
      queryClient.invalidateQueries({ queryKey: ["completed-campaign"] });
      setReviewModalVisible(true);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const transitiontoCompletedMutation = useMutation(pushToComplete, {
    onSuccess: () => {
      message.success("Applicant Moved to Completed Tab");
      queryClient.invalidateQueries(geEscrowStatusKey);
      queryClient.invalidateQueries(["applicants"]);
      queryClient.invalidateQueries({ queryKey: ["completed-campaign"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  // Chat

  // const [conversationId, setConversationId] = useState<string>("");
  // const [isFirst, setIsFirst] = useState<boolean>(true);

  // const queryClient = useQueryClient();
  // const [getAllConversationsKey] = getAllConversations();

  // useEffect(() => {
  //   if (conversationId !== "") {
  //     queryClient.invalidateQueries(getAllConversationsKey);
  //     setIsFirst(false);
  //   }
  // }, [conversationId]);
  // useEffect(() => {
  //   const getConversationId = async () => {
  //     if (conversationId) {
  //       return;
  //     } else {
  //       const response = await checkConversation({
  //         userId: influencerId || "",
  //       });
  //       if (response.data.conversationId) {
  //         return setConversationId(response.data.conversationId);
  //       }
  //     }
  //   };
  //   getConversationId();
  // }, [influencerId]);

  // const handleFirstMessage = async (message: string) => {
  //   try {
  //     await createConversation({ message, userId: influencerId }).then(
  //       (response) => {
  //         setConversationId(response.data.conversationId);
  //       }
  //     );
  //   } catch (error) {
  //     console.log("err", error);
  //   }
  // };

  return (
    <Drawer
      title="Campaign Timeline"
      width={400}
      key={"Campaign Timeline"}
      visible={isOpen}
      headerStyle={{ background: "#F0F2F5" }}
      bodyStyle={{ padding: "0px" }}
      onClose={handleClose}
      closable={true}>
      {/* <div
        style={{
          display: "flex",
          gap: "10px",
          overflowY: "hidden",
          height: "100%",
        }}> */}
      {/* <div style={{ width: "60%" }}>
          <TimelineChat
            conversationId={conversationId}
            handleFirstMessage={handleFirstMessage}
            influencerId={influencerId}
            isFirst={isFirst}
          />
        </div> */}
      <section
        style={{
          background: "#FAFAFA",
          // margin: "-24px",
          padding: "16px",
          // minHeight: "100vh",
          overflowY: "auto",
          width: "100%",
          height: "100%",
        }}>
        <h3 style={{ marginLeft: "4px" }}>Timeline</h3>
        <div
          style={{
            display: "flex",
            gap: "18px",
            alignItems: "center",
            marginBottom: "25px",
          }}>
          <ProgressIndicator
            metricFor="Requirements"
            numerator={(data?.data || []).reduce(
              (current, item) => current + item.isRequirementClosed,
              0
            )}
            denominator={(data?.data || []).reduce(
              (current, item) => current + item.isRequirementsEnabled,
              0
            )}
            workflow={data?.data}
          />
          <Divider style={{ minWidth: "44px", paddingBottom: "12px" }} />
          <ProgressIndicator
            metricFor="Drafts"
            numerator={(data?.data || []).reduce(
              (current, item) => current + item.isDraftsClosed,
              0
            )}
            denominator={(data?.data || []).reduce(
              (current, item) => current + item.isDraftsEnabled,
              0
            )}
            workflow={data?.data}
          />
          <Divider style={{ minWidth: "44px", paddingBottom: "12px" }} />
          <ProgressIndicator
            numerator={(data?.data || []).reduce(
              (current, item) => current + item.isLiveClosed,
              0
            )}
            denominator={(data?.data || []).reduce(
              (current, item) => current + item.isLiveEnabled,
              0
            )}
            metricFor="Links"
            workflow={data?.data}
          />
        </div>

        <Collapse
          defaultActiveKey={"1"}
          onChange={onTileChange}
          accordion
          ghost
          bordered={false}
          expandIconPosition={"end"}
          style={{
            display: "grid",
            gap: "6px",
          }}
          expandIcon={() => {
            return <img src="/assets/icons/arrowDown--bold.svg" alt="arrow" />;
          }}>
          <Panel
            header={
              <Badge
                offset={[5, 5]}
                dot={timelineData?.data?.some((item) => {
                  return item.requirements.status !== "Send";
                })}>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: " 16px",
                    lineHeight: "24px",
                  }}>
                  Requirements
                </p>
              </Badge>
            }
            key="1"
            style={{ background: "#FFFFFF", borderRadius: "8px" }}>
            <RequirementsTile
              offerId={applicantId}
              campaignId={campaignId}
              isCompleted={escrowData?.data?.status === "Completed"}
            />
          </Panel>
          <Panel
            header={
              <Badge
                offset={[5, 5]}
                dot={timelineData?.data?.some((item) => {
                  return item.drafts.status === "Awaiting-Review";
                })}>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: " 16px",
                    lineHeight: "24px",
                  }}>
                  Drafts
                </p>
              </Badge>
            }
            key="2"
            style={{ background: "#FFFFFF", borderRadius: "8px" }}>
            <DraftsTile offerId={applicantId} />
          </Panel>
          <Panel
            header={
              <Badge
                offset={[5, 5]}
                dot={timelineData?.data?.some((item) => {
                  return item.live.status === "Awaiting-Review";
                })}>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: " 16px",
                    lineHeight: "24px",
                  }}>
                  Links
                </p>
              </Badge>
            }
            key="3"
            style={{ background: "#FFFFFF", borderRadius: "8px" }}>
            <LinksTile offerId={applicantId} influencerId={influencerId} />
          </Panel>
          {/* <Panel
            header={
              <p
                style={{
                  fontWeight: 500,
                  fontSize: " 16px",
                  lineHeight: "24px",
                }}>
                Commercials
              </p>
            }
            key="4"
            style={{ background: "#FFFFFF", borderRadius: "8px" }}>
            <LinksTile offerId={applicantId} influencerId={influencerId} />
          </Panel> */}
          {/* <Panel
            header={
              <p
                style={{
                  fontWeight: 500,
                  fontSize: " 16px",
                  lineHeight: "24px",
                }}>
                Reports
              </p>
            }
            key="5"
            style={{ background: "#FFFFFF", borderRadius: "8px" }}>
            <ReportTile offerId={applicantId} />
          </Panel> */}
        </Collapse>

        <div className={styles.download__card}>
          {" "}
          <div
            style={{
              fontWeight: "100",
              color: "#9D9D9D",
              marginTop: "1rem",
              textAlign: "center",
              width: "100%",
            }}>
            Need to pay the Creator in Advance?
          </div>
          <div style={{ width: "100%" }}>
            <Popconfirm
              placement="top"
              title={"Are you sure you want to pay ?"}
              onConfirm={() =>
                releasePaymentMutation.mutate({ offerId: applicantId })
              }
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: releasePaymentMutation.isLoading }}
              disabled={escrowData?.data?.ecrowChequeStatus !== "Acquired"}>
              <Button
                icon={<DollarOutlined />}
                block
                style={{ marginTop: "1rem" }}
                disabled={escrowData?.data?.ecrowChequeStatus !== "Acquired"}>
                Pay {escrowData?.data?.influencerPrice} to Creator
              </Button>
            </Popconfirm>
          </div>
        </div>
        {escrowData?.data?.status !== "Completed" && (
          <div className={styles.download__card}>
            {" "}
            <div
              style={{
                fontWeight: "100",
                color: "#9D9D9D",
                marginTop: "1rem",
              }}>
              Has Creator Completed All the deliverables assigned to Him?
            </div>
            <div style={{ width: "100%" }}>
              <Popconfirm
                placement="top"
                title={"Are you sure you want to Move ?"}
                onConfirm={() =>
                  transitiontoCompletedMutation.mutate({
                    finalizedOfferId: applicantId,
                  })
                }
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  loading: transitiontoCompletedMutation.isLoading,
                }}
                // disabled={escrowData?.data?.ecrowChequeStatus !== "Acquired"}
              >
                <Button
                  type="primary"
                  block
                  style={{ marginTop: "1rem" }}
                  // disabled={escrowData?.data?.ecrowChequeStatus !== "Acquired"}
                >
                  Move Creator to Completed Tab
                </Button>
              </Popconfirm>
            </div>
          </div>
        )}
        <div className={styles.download__card}>
          {" "}
          <div
            style={{
              fontWeight: "100",
              color: "#9D9D9D",
              marginTop: "1rem",
            }}>
            Still have any questions about the deliverables Timeline? Chat with
            Creator
          </div>
          <div style={{ width: "100%" }}>
            <Button
              icon={<CommentOutlined />}
              block
              style={{ marginTop: "1rem" }}
              onClick={() => navigate(`/chat?influencerId=${influencerId}`)}>
              Chat
            </Button>
          </div>
        </div>
      </section>
      <ReviewModals
        isVisible={reviewModalVisible}
        setVisible={setReviewModalVisible}
        influencerId={influencerId}
        finalizeOfferId={applicantId}
      />
      {/* </div> */}
    </Drawer>
  );
};

export default TimeLineDrawer;
