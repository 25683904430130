import React from "react";
import { Space, message, Button } from "antd";
import { useState, useRef, useEffect } from "react";

import { useQuery, useMutation, useQueryClient } from "react-query";

// import CustomButton from "../../../shared/Custom/CustomButton";
import ReportButton from "../../../Campaign/Report/ReportButton";
import CustomInput from "../../../shared/Custom/Input";

import { getUser } from "../../../../handler/getUser";
import { sendEmailOtp, verifyOtp } from "../../../../handler/marketing";

import { validateEmail } from "../../../../utils";

import styles from "../styles.module.css";

const EmailVerify = () => {
  const [userData, setUserData] = useState<{
    email: string | undefined;
    name: string | undefined;
    otp: string | undefined;
  }>({ email: undefined, name: undefined, otp: undefined });

  const [showErr, setShowErr] = useState<{
    email: boolean;
    name: boolean;
    otp: boolean;
  }>({ email: false, name: false, otp: false });

  const firstRender = useRef(true);

  const [getUserKey, getUserFn] = getUser();

  const { data } = useQuery(getUserKey, getUserFn);

  const [selectedSection, setSelectedSection] = useState<"add" | "verify">(
    "add"
  );

  const queryClient = useQueryClient();

  const sendEmailOtpMutation = useMutation(sendEmailOtp, {
    onSuccess: () => {
      setSelectedSection("verify");
      startTimer();
    },
    onError: () => {
      message.error("Something went wrong while sending otp");
    },
  });
  const verifyOtpMutation = useMutation(verifyOtp, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getUserKey);
    },
    onError: () => {
      message.error("Invalid OTP");
    },
  });

  useEffect(() => {
    return () => {
      setSelectedSection("add");
    };
  }, []);

  useEffect(() => {
    if (data?.data && firstRender.current) {
      setUserData({
        name: data?.data?.brevo?.sendersName,
        email: data?.data?.brevo?.sendersEmail,
        otp: undefined,
      });
      firstRender.current = false;
    }
  }, [data]);

  useEffect(() => {
    if (selectedSection === "verify") {
      return startTimer();
    }
    return setTimer({ isRunning: false, seconds: 60 });
  }, [selectedSection]);

  const [timer, setTimer] = useState({
    seconds: 60,
    isRunning: false,
  });

  useEffect(() => {
    let interval: any = null;
    if (timer.isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => ({
          seconds: prevTimer.seconds === 1 ? 60 : prevTimer.seconds - 1,
          isRunning: prevTimer.seconds === 1 ? false : true,
        }));
      }, 1000);
    } else if (!timer.isRunning && timer.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer.isRunning, timer.seconds]);

  const startTimer = () => {
    setTimer((prevTimer) => ({
      ...prevTimer,
      isRunning: true,
    }));
  };

  return (
    <>
      {selectedSection === "add" ? (
        <div className={styles.grid40}>
          <p className={styles.label}>Add Email-Id</p>
          <div className={styles.grid20}>
            <CustomInput
              placeholder="name"
              label="From Name"
              subLabel="This will be shown to the influencers when you invite them"
              style={{ width: "45%", marginTop: "10px" }}
              labelClassName={styles.labelInput}
              subLabelClassName={styles.subLabelInput}
              value={userData.name}
              onChange={(e) => {
                setShowErr((showErr) => ({ ...showErr, name: false }));
                setUserData((userData) => ({
                  ...userData,
                  name: e.target.value,
                }));
              }}
              error={showErr?.name ? "Invalid Name" : undefined}
            />
            <CustomInput
              placeholder="Eg: shivam@cloutflow.com"
              label="From Email-Id"
              subLabel="We will send invite email using this e-mail Id"
              style={{ width: "45%", marginTop: "10px" }}
              labelClassName={styles.labelInput}
              subLabelClassName={styles.subLabelInput}
              value={userData.email}
              onChange={(e) => {
                setShowErr((showErr) => ({ ...showErr, email: false }));
                setUserData((userData) => ({
                  ...userData,
                  email: e.target.value,
                }));
              }}
              error={showErr?.email ? "Please Enter a valid Email" : ""}
            />
          </div>
          <ReportButton
            type="primary"
            style={{ width: "max-content", padding: "10px 20px" }}
            onClick={() => {
              if (
                !userData?.name?.trim() ||
                !validateEmail(userData?.email || "")
              ) {
                return setShowErr((showErr) => ({
                  ...showErr,
                  email: !Boolean(validateEmail(userData?.email || "")),
                  name: !Boolean(userData?.name?.trim()),
                }));
              }
              sendEmailOtpMutation.mutate({
                senderEmail: userData?.email || "",
                senderName: userData?.name || "",
              });
            }}
            loading={sendEmailOtpMutation.isLoading}
          >
            Proceed
          </ReportButton>
        </div>
      ) : (
        <div className={styles.grid40}>
          <p className={styles.label}>Add Email-Id</p>
          <div>
            <div className={styles.p20G10}>
              <p className={styles.desc}>
                To use this sender, your email address must be verified. We have
                sent you a verification code by email.If you have not received
                the verification code, click the Resend -OTP button.
              </p>
            </div>
            <div className={styles.p20G10}>
              <p className={styles.desc}>
                Enter the verification code sent on {userData?.email} to verify
                your sender {userData?.name}
              </p>
              <CustomInput
                placeholder="0 0 0 0 0 0"
                maxLength={6}
                max={999999}
                style={{ width: "30%", marginTop: "10px" }}
                value={userData?.otp}
                type="number"
                onChange={(e) =>
                  setUserData((userData) => ({
                    ...userData,
                    otp: e.target.value,
                  }))
                }
              />
              <Button
                type="link"
                className="resend-otp-btn"
                style={{
                  width: "max-content",
                  padding: "10px 0",
                }}
                onClick={() =>
                  sendEmailOtpMutation.mutate({
                    senderEmail: userData?.email || "",
                    senderName: userData?.name || "",
                  })
                }
                disabled={timer?.isRunning}
                loading={sendEmailOtpMutation.isLoading}
              >
                Resend - OTP ({timer.seconds})
              </Button>
            </div>
          </div>
          <Space size={10}>
            <ReportButton
              ghost
              isOutline
              style={{ width: "max-content", padding: "10px 20px" }}
              onClick={() => setSelectedSection("add")}
            >
              Back
            </ReportButton>
            <ReportButton
              type="primary"
              style={{ width: "max-content", padding: "10px 20px" }}
              onClick={() =>
                verifyOtpMutation.mutate({
                  otp: userData?.otp || "",
                })
              }
              disabled={!userData?.otp}
              loading={verifyOtpMutation.isLoading}
            >
              Verify Email-Id
            </ReportButton>
          </Space>
        </div>
      )}
    </>
  );
};

export default EmailVerify;
