import { sendRequest } from "../../helper";

interface getDeliveryAddressProps {
	campaignId: string;
}

interface getDeliveryAddressResponse {
	status: string;
	message: string;
	data: {
		id: number;
		name: string;
		lastname: string;
		line: string;
		city: string;
		state: string;
		pincode: string;
		country: string;
	}[];
}

export const getDeliveryAddress = ({ campaignId }: getDeliveryAddressProps) => {
	const queryKey = ["campaign", "delivery-address", campaignId];

	const queryFunction = () =>
		sendRequest<getDeliveryAddressResponse>(
			"get",
			`/brand/execution/finalized-delivery-address?campaignId=${campaignId}`,
			false,
		).then((res) => res.data);

	return [queryKey, queryFunction] as const;
};
