import React, { useState } from "react";
import {
  Modal,
  ModalProps,
  InputNumber,
  Form,
  Input,
  Radio,
  Checkbox,
  Row,
  Col,
  message,
} from "antd";

import { AxiosError } from "axios";
import { useQueryClient, useMutation } from "react-query";

import styles from "../../../Create.module.css";

import { YoutubeDel } from "../../Deliverables";
import { Product } from "../../../../../../typings";

import {
  postDeliverable,
  updateDeliverable,
} from "../../../../../../handler/campaign";
import { CampaignResponse } from "../../../../../../typings";

interface YoutubeModalProps extends ModalProps {
  initialValues?: YoutubeDel;
  type: "Add" | "Edit";
  setDeliverableList: (deliverables: YoutubeDel[]) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDeliverable: React.Dispatch<React.SetStateAction<YoutubeDel | null>>;
  productList: Product[] | null;
  campaignId: string;
  hasProduct: boolean;
  modalFor?: "Campaign" | "Deal";
}

const YoutubeModal: React.FC<YoutubeModalProps> = ({
  visible,
  initialValues = {
    type: "Integrated",
    duration: "",
    placement: "Beginning",
    products: [],
    description: "",
    reference: [],
    script: true,
    _id: "",
    platform: "youtube",
    typeDel: "Video",
  },
  setDeliverableList,
  setIsVisible,
  type,
  setDeliverable,
  productList,
  campaignId,
  hasProduct,
  modalFor = "Campaign",
}) => {
  const queryClient = useQueryClient();
  const postDeliverableMutation = useMutation(postDeliverable, {
    onSuccess: (response) => {
      const ytList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "youtube"
      );
      setDeliverableList((ytList as unknown as YoutubeDel[]) || []);
      // set(response.data.deal.productDetails);
      setIsVisible(false);
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const updateDeliverableMutation = useMutation(updateDeliverable, {
    onSuccess: (response) => {
      // setProductList(response.data.deal.productDetails);
      const ytList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "youtube"
      );
      setDeliverableList((ytList as unknown as YoutubeDel[]) || []);
      setIsVisible(false);
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const { TextArea } = Input;
  const [videoType, setVideoType] = useState<YoutubeDel["type"]>(
    initialValues.type
  );

  const [form] = Form.useForm();
  const [referenceLinks, setReferenceLinks] = useState<string[]>(
    initialValues.reference || []
  );

  const handleAddOk = () => {
    form
      .validateFields()
      .then((values) => {
        let updatedDeliverable = {
          type: values.type,
          duration: values.duration,
          products: values.productList,
          description: values.description,
          reference: referenceLinks,
          script: values.script,
          placement: undefined,
          platform: initialValues.platform,
          typeDel: "Video" as "Video" | "Shorts",
        };
        if (videoType === "Integrated") {
          updatedDeliverable["placement"] = values.placement;
        }
        if (videoType === "Shorts") {
          updatedDeliverable["typeDel"] = "Shorts";
        }
        if (modalFor === "Campaign") {
          postDeliverableMutation.mutate({
            campaignId,
            deliverable: updatedDeliverable,
          });
        } else {
          // setDeliverableList((deliverableList) => [
          //   ...deliverableList,
          //   { ...updatedDeliverable, _id: Date.now().toString() },
          // ]);
          setIsVisible(false);
        }

        setReferenceLinks([]);
        setVideoType(initialValues.type);
        setIsVisible(false);
        form.resetFields();
      })
      .catch((err) => console.log("err", err));
    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleEditOk = () => {
    form
      .validateFields()
      .then((values) => {
        let updatedDeliverable = {
          type: values.type,
          duration: values.duration,
          products: values.productList,
          description: values.description,
          reference: referenceLinks,
          script: values.script,
          placement: undefined,
          platform: initialValues.platform,
          _id: initialValues._id!,
          typeDel: "Video" as "Video" | "Shorts",
        };
        if (videoType === "Integrated") {
          updatedDeliverable["placement"] = values.placement;
        }
        if (videoType === "Shorts") {
          updatedDeliverable["typeDel"] = "Shorts";
        }
        if (modalFor === "Campaign") {
          updateDeliverableMutation.mutate({
            campaignId,
            deliverable: updatedDeliverable,
          });
        } else {
          // setDeliverableList((deliverableList) => [
          //   ...deliverableList,
          //   { ...updatedDeliverable, _id: Date.now().toString() },
          // ]);
          setIsVisible(false);
        }

        form.resetFields();
        setReferenceLinks([]);
        setVideoType(initialValues.type);
        setDeliverable(null);
      })
      .catch((err) => console.log("err", err));
    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleCancel = () => {
    form.resetFields();
    setIsVisible(false);
    setReferenceLinks([]);
    setDeliverable(null);
  };

  return (
    <Modal
      title="Add a Youtube Video"
      visible={visible}
      okText="DONE"
      onCancel={handleCancel}
      centered
      onOk={type === "Add" ? handleAddOk : handleEditOk}
      bodyStyle={{ maxHeight: "70vh", overflowY: "scroll" }}>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
        // {...formItemLayout}
      >
        <Form.Item
          label="Type"
          name="type"
          initialValue={initialValues.type}
          className={styles.label}
          rules={[{ required: true, message: "Please Select a type" }]}
          tooltip="This is a required Field">
          <Radio.Group
            size="middle"
            onChange={(e) => setVideoType(e.target.value)}>
            <Radio.Button style={{ fontWeight: 400 }} value="Integrated">
              Integrated
            </Radio.Button>
            <Radio.Button style={{ fontWeight: 400 }} value="Dedicated">
              Dedicated
            </Radio.Button>
            <Radio.Button style={{ fontWeight: 400 }} value="Shorts">
              Shorts
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Duration of Video"
          className={styles.label}
          name="duration"
          initialValue={initialValues.duration}
          wrapperCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please input Video Duration",
            },
          ]}>
          <InputNumber
            type="number"
            size="large"
            addonAfter="minutes"
            min={0}
          />
        </Form.Item>
        {videoType === "Integrated" && (
          <Form.Item
            label="Placement inside video"
            name="placement"
            initialValue={initialValues.placement}
            className={styles.label}
            rules={[{ required: true, message: "Please Select a type" }]}
            tooltip="This is a required Field">
            <Radio.Group size="middle">
              <Radio.Button
                style={{ padding: "0px 24px", fontWeight: 400 }}
                value="Beginning">
                Beginning
              </Radio.Button>
              <Radio.Button
                style={{ padding: "0px 30px", fontWeight: 400 }}
                value="Middle">
                Middle
              </Radio.Button>
              <Radio.Button
                style={{ padding: "0px 38px", fontWeight: 400 }}
                value="End">
                End
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        )}

        {hasProduct && productList && Boolean(productList.length) && (
          <Form.Item
            label="Select Products"
            name="productList"
            initialValue={initialValues.products}>
            <Checkbox.Group>
              <Row gutter={[0, 12]}>
                {productList.map((product) => {
                  return (
                    <Col span={24}>
                      <Checkbox
                        value={product._id}
                        key={product._id}
                        // defaultChecked={initialValues.products?.some(
                        //   (checkProduct) => {
                        //
                        //     return checkProduct._id === product._id;
                        //   }
                        // )}
                        // disabled={true}
                        defaultChecked={true}
                        checked={true}>
                        {product.productTitle.length > 20
                          ? product.productTitle.substring(0, 19) + "...."
                          : product.productTitle}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        )}
        <Form.Item
          label={
            <div>
              <p>Add a brief</p>
              <p className={styles.hintDescription}>
                Give a rough idea for the creator about what you need them to
                create. Detailed requirements will be added later.
              </p>
            </div>
          }
          className={styles.label}
          name="description"
          initialValue={initialValues.description}
          rules={[
            {
              required: true,
              message: "Please input your requirements!",
            },
          ]}>
          <TextArea
            rows={2}
            placeholder="Add a brief"
            showCount={{
              formatter: (args) => `${args.count}/${args.maxLength}`,
            }}
            maxLength={100}
          />
        </Form.Item>

        {/* <Form.Item
          label={
            <p>
              Upload some references{" "}
              <span className={styles.hint}>(Optional)</span>
            </p>
          }
          name="reference"
          className={styles.label}>
          <>
            <Dragger {...DraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </>
        </Form.Item> */}
        {/* <Form.Item
          label="Do you want the creator to share the script?"
          name="script"
          initialValue={initialValues.script}
          className={styles.label}
          rules={[{ required: true, message: "Please Select a type" }]}
          tooltip="This is a required Field">
          <Radio.Group size="small">
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default YoutubeModal;
