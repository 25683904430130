import { useEffect, useState } from "react";
import { format, eachDayOfInterval } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, InfiniteData, useQueryClient } from "react-query";
import { Skeleton, Row, Divider, Input, Space } from "antd";
import { useSearchParams } from "react-router-dom";

import {
  getAllConversations,
  getAllConversationsResult,
} from "../../../handler/chat";

import styles from "./sidebar.module.css";

import { useUserAuth } from "../../../context/userAuthcontext";
import { useChatContext } from "../../../context/ChatContext";

const { Search } = Input;

const getDateString = (date: string) => {
  try {
    const resultArrayLength = (
      eachDayOfInterval({
        start: new Date(date),
        end: new Date(),
      }) || []
    ).length;
    if (resultArrayLength === 1) return format(new Date(date), "hh:mm bb");
    return format(new Date(date), " dd/LL/yyyy");
  } catch (error) {
    return format(new Date(date), "hh:mm bb");
  }
};

const ChatSideBar = () => {
  const {
    setConversationId,
    setInfluencerId,
    conversationId,
    setSearchCreator,
    searchCreator,
    setNoConversation,
  } = useChatContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const { currentUser } = useUserAuth();

  const [getAllConversationsKey, getAllConversationsFn] =
    getAllConversations(searchCreator);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    getAllConversationsKey,
    getAllConversationsFn,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );
  const [convTileData, setConvTileData] =
    useState<InfiniteData<getAllConversationsResult> | null>();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(getAllConversationsKey);
  }, [searchCreator]);

  useEffect(() => {
    setConvTileData(data);
    if (data) {
      if (data?.pages[0].data.userConversation.length === 0)
        setNoConversation(true);
    }
  }, [data]);

  const handleConcat = (value?: string) => {
    const valToCheck = String(value);
    if (valToCheck.length > 30) {
      return valToCheck.substring(0, 30) + "...";
    }
    return valToCheck;
  };

  return (
    <div style={{ overflowY: "hidden", height: "100vh" }}>
      <Row
        justify="center"
        style={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          marginTop: "12px",
          marginBottom: "40px",
        }}>
        Messages
      </Row>
      <Row>
        <Space
          size={"middle"}
          style={{ width: "100%", margin: "0px 22px" }}
          direction="vertical">
          <Search
            onSearch={(e) => setSearchCreator(e)}
            style={{
              width: "100%",
              borderRadius: "6px",
            }}
            placeholder="Search Creator"
          />
          <div
            style={{
              height: "1px",
              backgroundColor: "#CECECE",

              width: "100%",
            }}></div>
        </Space>
      </Row>

      <div
        style={{
          height: "75vh",
          paddingBottom: "45px",
          overflowY: "scroll",
          // minHeight: "75vh",
          overflowX: "hidden",
        }}>
        {isLoading ? (
          <div style={{ padding: "16px" }}>
            <Skeleton
              active
              avatar={{ shape: "circle" }}
              // title={{ width: 200 }}
              paragraph={{ rows: 1 }}
            />
            <Skeleton
              active
              avatar={{ shape: "circle" }}
              // title={{ width: 200 }}
              paragraph={{ rows: 1 }}
            />
            <Skeleton
              active
              avatar={{ shape: "circle" }}
              // title={{ width: 200 }}
              paragraph={{ rows: 1 }}
            />
          </div>
        ) : (
          <>
            {convTileData &&
              convTileData?.pages[0].data.userConversation.length >= 0 && (
                <div
                  id="scrollableDiv"
                  style={{
                    height: "100%",
                    overflow: "auto",
                    padding: "0 16px",
                  }}>
                  <InfiniteScroll
                    dataLength={
                      (convTileData?.pages.at(-1)?.data?.userConversation || [])
                        .length
                    }
                    next={fetchNextPage}
                    hasMore={Boolean(hasNextPage)}
                    endMessage={
                      <Divider plain>It is all, nothing more 🤐</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                    loader={
                      <>
                        <Skeleton
                          active
                          avatar={{ shape: "circle" }}
                          paragraph={{ rows: 1 }}
                        />
                        <Skeleton
                          active
                          avatar={{ shape: "circle" }}
                          paragraph={{ rows: 1 }}
                        />
                        <Skeleton
                          active
                          avatar={{ shape: "circle" }}
                          paragraph={{ rows: 1 }}
                        />
                      </>
                    }>
                    {convTileData &&
                      convTileData.pages.map((page) =>
                        page.data.userConversation.map((user) => {
                          return (
                            user.latestMessage?.data && (
                              <div
                                className={styles.chatTile}
                                onClick={() => {
                                  setConversationId(user.conversationId);
                                  setInfluencerId(user.userInfo[0]._id);
                                  if (searchParams.has("influencerId")) {
                                    searchParams.delete("influencerId");
                                    setSearchParams(searchParams);
                                  }
                                }}
                                style={{
                                  background:
                                    conversationId === user.conversationId
                                      ? "#f5f5f5"
                                      : "transparent",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "12px",
                                  }}>
                                  <img
                                    src={
                                      user?.userInfo[0]?.profilePicUrl ||
                                      "/assets/img/test-profile.png"
                                    }
                                    alt="profile"
                                    className={styles.profile}
                                  />

                                  <div
                                    style={{
                                      maxWidth: "250px",
                                    }}>
                                    <p className={styles.name}>
                                      {user.userInfo[0]?.name || "Name"}
                                    </p>
                                    <p className={styles.message}>
                                      {user.latestMessage.data?.type && (
                                        <>
                                          {user.latestMessage?.data?.type !==
                                          "string" ? (
                                            <>
                                              {user.latestMessage.data.type ===
                                                "media" && (
                                                <>
                                                  {currentUser?.uid ===
                                                  user.latestMessage.author
                                                    ? handleConcat(
                                                        user?.latestMessage
                                                          ?.isDeleted
                                                          ? "Deleted Message"
                                                          : user?.latestMessage
                                                              ?.data?.msg ||
                                                              user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.me +
                                                                "sent an attachment"
                                                      )
                                                    : handleConcat(
                                                        user?.latestMessage
                                                          ?.isDeleted
                                                          ? "Deleted Message"
                                                          : user?.latestMessage
                                                              ?.data?.msg ||
                                                              user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.other +
                                                                "sent an attachment"
                                                      )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {handleConcat(
                                                user.latestMessage?.isDeleted
                                                  ? "Deleted Message"
                                                  : user.latestMessage?.data
                                                      ?.msg
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                  }}>
                                  {user.latestMessage?.createDateTime && (
                                    <p
                                      style={
                                        user.unreadCount > 0
                                          ? { color: "#2130ff" }
                                          : {}
                                      }
                                      className={styles.time}>
                                      {getDateString(
                                        user.latestMessage.createDateTime
                                      )}
                                    </p>
                                  )}
                                  {user.unreadCount > 0 &&
                                    user.conversationId !== conversationId && (
                                      <p className={styles.unread}>
                                        {user.unreadCount}
                                      </p>
                                    )}
                                </div>
                              </div>
                            )
                          );
                        })
                      )}
                  </InfiniteScroll>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatSideBar;
