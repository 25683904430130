import { Col, Divider, Skeleton, Row, Empty, Pagination } from "antd";
import { useQuery } from "react-query";
import React, { useState } from "react";

import ProgressIndicator from "./ProgressIndicator";
import AggregateData from "./AggregateData";
// import ReportsEntry from "./ReportsEntry";
import ReportsEntryUpdated from "./ReportsEntryUpdated";
import Card from "../../Finalized/Card";
import ModalComponent from "./Modal";

import {
  // getCompletedOfferAggregate,
  getCompletedOfferV2,
  getCompletedOfferCountV2,
} from "../../../../../handler/campaign/offer";
import { getCampaign } from "../../../../../handler/campaign";

const Reports: React.FC<{ campaignId: string }> = ({ campaignId }) => {
  // const [getCompletedOfferAggregateKey, getCompletedOfferAggregateFn] =
  //   getCompletedOfferAggregate({ campaignId });
  const [open, setOpen] = React.useState<boolean>(false);

  // const [getCampaignKey, getCampaignFn] = getCampaign({
  //   campaignId,
  // });

  // const {
  //   data: completedOfferAggregateData,
  //   isLoading: isCompletedOfferAggregateLoading,
  // } = useQuery(getCompletedOfferAggregateKey, getCompletedOfferAggregateFn);

  const [searchProps, setSearchProps] = useState<{
    pageNo: number;
    limit: number;
  }>({ pageNo: 1, limit: 10 });

  const [getCompletedOfferKey, getCompletedOfferFn] = getCompletedOfferV2({
    campaignId,
    ...searchProps,
  });

  const [getCompletedOfferCountKey, getCompletedOfferCountFn] =
    getCompletedOfferCountV2({
      campaignId,
    });

  // const { data: campaignData } = useQuery(getCampaignKey, getCampaignFn);

  const { data: completedOfferData, isLoading: isOfferDataLoading } = useQuery(
    getCompletedOfferKey,
    getCompletedOfferFn
  );
  const { data: completedOfferCountData, isLoading: isCountDataLoading } =
    useQuery(getCompletedOfferCountKey, getCompletedOfferCountFn);

  const isLoadingData = isCountDataLoading || isOfferDataLoading;

  return (
    <React.Fragment>
      <ModalComponent open={open} setOpen={setOpen} />
      <Col style={{ padding: "50px" }} span={19}>
        <Row justify="space-between">
          <Col span={12}>
            <h1>Completed</h1>
          </Col>
          {/* <Col span={12} style={{ textAlign: "right" }}>
            <Button
              onClick={() => setOpen(true)}
              type="primary"
              style={{
                padding: "12px 24px",
                height: "48px",
                borderRadius: "5px",
              }}>
              Add links to Report
            </Button>
          </Col> */}
        </Row>
        {/* {isCompletedOfferAggregateLoading ? (
          <>
            <Skeleton active />
          </>
        ) : (
          <div style={{ display: "flex", gap: "44px" }}>
            <ProgressIndicator
              total={completedOfferAggregateData?.data?.totalCreators || 1}
              amount={completedOfferAggregateData?.data?.count || 0}
            />
            <AggregateData
              completedOfferAggregateData={completedOfferAggregateData?.data}
              showReach={campaignData?.data?.campaign?.platform === "instagram"}
            />
          </div>
        )}

        <h2>Individual Reports</h2> */}
        <Divider />
        {/* <ReportsEntry
        campaignId={campaignId}
        platform={campaignData?.data?.campaign?.platform || "instagram"}
      /> */}
        {/* <ReportsEntryUpdated
          campaignId={campaignId}
          platform={campaignData?.data?.campaign?.platform || "instagram"}
        /> */}

        {isLoadingData ? (
          [1, 2, 3, 4].map((_) => {
            return (
              <Col span={15} key={_}>
                <Skeleton active avatar paragraph={{ rows: 1 }} />
              </Col>
            );
          })
        ) : (
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            <div style={{ minHeight: "50%" }}>
              <div
                style={{
                  display: "grid",
                  gap: "16px",
                  // minHeight: "50%",
                }}>
                {completedOfferData?.data &&
                completedOfferData.data.result.length > 0 &&
                !isLoadingData ? (
                  <>
                    {completedOfferData!.data.result.map((applicant) => {
                      return (
                        <Col span={24} key={applicant._id}>
                          <Card
                            applicant={applicant}
                            handleCheck={() => {}}
                            isCardChecked={false}
                            campaignId={campaignId}
                            finalizedApplicantKey={getCompletedOfferKey}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    style={{
                      marginTop: "30%",
                      marginLeft: "10%",
                    }}
                    description={
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "22px",
                          marginTop: "10px",
                        }}>
                        No Applicants
                      </p>
                    }
                  />
                )}
              </div>
            </div>

            {Boolean(completedOfferCountData?.data.count) && (
              <Row justify="center">
                <Pagination
                  total={completedOfferCountData?.data.count}
                  style={{ margin: "46px auto" }}
                  onChange={(page, _) =>
                    setSearchProps((props) => ({ ...props, pageNo: page }))
                  }
                  pageSize={searchProps.limit}
                  showSizeChanger={false}
                  current={searchProps.pageNo}
                />
              </Row>
            )}
          </div>
        )}
      </Col>
    </React.Fragment>
  );
};

export default Reports;
