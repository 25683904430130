import React from "react";
import { Progress, Button, Row } from "antd";

import { formatData } from "../../../../utils";

import styles from "./styles.module.css";

interface StatsCardProps {
  items: {
    label: string;
    percent: number;
  }[];
  isSeeMoreEnabled?: boolean;
  handleSeeMoreEnabled?: () => void;
}

const StatsCard: React.FC<StatsCardProps> = ({
  items,
  isSeeMoreEnabled,
  handleSeeMoreEnabled,
}) => {
  return (
    <div style={{ width: "100%", height: "308px" }}>
      {items?.reduce((prev, current) => prev + current?.percent || 0, 0) ===
      0 ? (
        <p className="global-no-data"> Not enough Data To show this Metric </p>
      ) : (
        <>
          <section className={styles.scrollableItem}>
            {items.map((item, idx) => {
              return (
                <div key={idx}>
                  <p className={styles.label}>
                    <span>{item.label}</span>
                    <span>{formatData(item.percent, "0.00")}%</span>
                  </p>
                  <Progress
                    percent={item.percent}
                    status="active"
                    strokeColor={"#2130FF"}
                    showInfo={false}
                  />
                </div>
              );
            })}
          </section>
          {(isSeeMoreEnabled === true || isSeeMoreEnabled === false) && (
            <Row justify="end" style={{ marginTop: "1em" }}>
              <Button
                type="link"
                onClick={() => handleSeeMoreEnabled && handleSeeMoreEnabled()}>
                {isSeeMoreEnabled ? "See Less" : "See More"}
              </Button>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default StatsCard;
