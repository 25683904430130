import React, { useState } from "react";
import { Button, Progress } from "antd";
import clsx from "clsx";
import { useQuery, useQueryClient } from "react-query";
import nf from "@tuplo/numberfmt";

import styles from "./paycard.module.css";

import { getOfferSummary } from "../../../../handler/campaign/offer/getOfferSummary";
import { getPaymentStatus } from "../../../../handler/campaign/offer";

interface PayCardProps {
  checkList: string[];
  campaignId: string;
  setPayModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  queries: string[][];
}

const PayCard: React.FC<PayCardProps> = ({
  checkList,
  campaignId,
  setPayModalVisible,
  queries,
}) => {
  const queryClient = useQueryClient();
  const [percent, setPercent] = useState(0);
  const [isLongProcessing, setLongProcessing] = useState<boolean>(false);

  const [refetchInterval, setRefetchInterval] = React.useState<boolean>(true);

  const [getOfferSummaryKey, getOfferSummaryFunction] = getOfferSummary({
    offerIds: checkList,
    campaignId,
  });

  const { data, isLoading } = useQuery(
    getOfferSummaryKey,
    getOfferSummaryFunction
  );

  const [getPaymentStatusKey, getPaymentStatusFn] = getPaymentStatus({
    campaignId,
  });
  const { data: paymentData, isLoading: isPaymentStatusLoading } = useQuery(
    getPaymentStatusKey,
    getPaymentStatusFn,
    {
      refetchInterval: refetchInterval ? 1000 : false,
      onSuccess: (response) => {
        if (response.data.length === 0) {
          setRefetchInterval(false);

          queryClient.invalidateQueries(queries[0]);

          queryClient.invalidateQueries(queries[1]);
          setPercent(0);
          setLongProcessing(false);
          // queryClient.invalidateQueries(queries);
        }

        if (percent >= 95) {
          return setLongProcessing(true);
        } else {
          return setPercent((percent) => percent + 5);
        }
      },
    }
  );

  const getChildren = () => {
    if (paymentData?.data && paymentData?.data.length === 0) {
      return (
        <>
          <div className={clsx(styles.space, styles.highlight)}>
            <span>Total Creators</span>
            {/* <span>{nf(data?.data.totalReach, "a")}</span> */}
            <span>
              {" "}
              {data?.data.reciept.length}{" "}
              {data?.data.reciept.length === 1 ? "Creator" : "Creators"}
            </span>
          </div>
          <div
            className={clsx(styles.space, styles.light)}
            style={{ marginBottom: "17px" }}>
            {/* <p>
              {data?.data.reciept.length}{" "}
              {data?.data.reciept.length === 1 ? "Creator" : "Creators"}
            </p> */}
            {/* <span>views</span> */}
          </div>
          <p className={styles.space} style={{ marginBottom: "6px" }}>
            <span className={styles.lightThin}>Subtotal</span>
            <span className={styles.boldLight}>
              ₹{nf(data?.data.totalReciept, "0,0.00")}
            </span>
          </p>
          <p className={styles.space} style={{ marginBottom: "12px" }}>
            {/* <span className={styles.lightThin}>CPV (Cost Per View)</span> */}
            {/* <span>
              {nf(
                Number(data!.data.totalReciept) / data!.data.totalReach,
                "0.00"
              )}
            </span> */}
          </p>

          <Button type="primary" block onClick={() => setPayModalVisible(true)}>
            Proceed to Finalize
          </Button>
        </>
      );
    } else if (
      paymentData?.data &&
      paymentData?.data.length >= 0 &&
      paymentData?.data[0].payIn.paymentStatus === "Processing"
    ) {
      return (
        <div style={{ display: "grid", gap: "12px" }}>
          <p className={styles.highlight}> Payment Pending</p>
          <p className={styles.light}>
            Payment link generated, but payment has not been initiated yet.
          </p>
          <Button type="primary" block onClick={() => setPayModalVisible(true)}>
            View Payment details
          </Button>
        </div>
      );
    } else if (
      (paymentData?.data &&
        paymentData?.data.length >= 0 &&
        paymentData?.data[0].payIn.paymentStatus === "Success" &&
        paymentData?.data[0].payIn.status === "Processing") ||
      paymentData?.data[0].payIn.status === "Partial-Paid"
    ) {
      return (
        <>
          {isLongProcessing ? (
            <section
              style={{
                display: "flex",
                gap: "12px",
                alignItems: "flex-start",
              }}>
              <img
                src="/assets/icons/warning.svg"
                alt="warning"
                width={22}
                style={{ paddingTop: "4px" }}
              />
              <div>
                <p className={styles.highlight}>
                  {" "}
                  Payment Processing is taking too long, we’re having a look at
                  the problem
                </p>
                <p className={styles.light} style={{ marginTop: "8px" }}>
                  We have received your payment, but there’s been an issue while
                  processing it on our servers. This doesn’t usually happen.
                  We’re having a look at the problem and will give you an update
                  within 24 business hours. If the problem still persists,
                  please let us know at{" "}
                  <u>
                    <a href="mailto:help@cloutflow.com">help@cloutflow.com.</a>
                  </u>
                </p>
              </div>
            </section>
          ) : (
            <div>
              <p className={styles.highlight}> Payment Processing</p>
              <p className={styles.light} style={{ marginTop: "8px" }}>
                Your payment has been received, we’re processing it on our end.
              </p>
              <Progress percent={percent} showInfo={false} />
            </div>
          )}
        </>
      );
    } else if (
      paymentData?.data &&
      paymentData?.data.length >= 0 &&
      paymentData?.data[0].payIn.paymentStatus === "Failure"
    ) {
      return (
        <div style={{ display: "grid", gap: "12px" }}>
          <p className={styles.highlight}> Payment Failed</p>
          <p className={styles.light}>
            Payment was not processed, please try again.
          </p>
          <Button type="primary" block onClick={() => setPayModalVisible(true)}>
            View Payment details
          </Button>
        </div>
      );
    }
    return <div />;
  };

  return (
    <section className={styles.container}>
      {!isLoading && !isPaymentStatusLoading ? (
        getChildren()
      ) : (
        <div>Loading.....</div>
      )}
    </section>
  );
};

export default PayCard;
