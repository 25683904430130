import React, { useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Space } from "antd";
import { BiCaretDown } from "react-icons/bi";
import { LuPin, LuPinOff } from "react-icons/lu";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut, Bar } from "react-chartjs-2";

import Option from "../../shared/UI/Option";
import CustomDropDown from "../../shared/Custom/DropDown";
import StatsCard from "./StatsCard";

import styles from "./styles.module.css";

import { getIgAnalytics } from "../../../handler/Profile";

import { formatData } from "../../../utils";
import { PinnedMetric } from "../IgAnalytics";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const AudienceInsights: React.FC<{
  influencerId: string;
  pinnedMetrices: PinnedMetric[];
  setPinnedMetrices: React.Dispatch<React.SetStateAction<PinnedMetric[]>>;
}> = ({ influencerId, pinnedMetrices, setPinnedMetrices }) => {
  const [influencerKey, influencerFn] = getIgAnalytics(influencerId || "");

  const { data, isError } = useQuery(influencerKey, influencerFn, {
    enabled: Boolean(influencerId),
  });

  const [breakDownSelected] = useState<"week" | "month">("week");

  const [typeSelected, setTypeSelected] = useState<
    "followers" | "engAudience" | "reAudience"
  >("followers");

  const handlePin = (args: PinnedMetric) => {
    args = { ...args, period: breakDownSelected, type: typeSelected };
    const idx = pinnedMetrices
      .filter((item) => item.title === args.title)
      .filter((item) => item.period === args.period)
      .findIndex((item) => item.type === args.type);

    if (idx > -1) {
      setPinnedMetrices((prev) =>
        prev.filter(
          (item) =>
            !(
              item.title === args.title &&
              item.period === args.period &&
              item.type === args.type
            )
        )
      );
    } else {
      setPinnedMetrices((prev) => [...prev, args]);
    }
  };

  const isPinned = (title: string) => {
    const arr = pinnedMetrices.filter(
      (item) =>
        item.title === title &&
        item.period === breakDownSelected &&
        item.type === typeSelected
    );

    return arr.length > 0;
  };

  const getAgeGenderData = () => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};
    try {
      switch (typeSelected) {
        case "followers": {
          if (
            data?.data?.user?.follower_demographics_last_30_days_age_gender
              ?.isBreakDown &&
            !data?.data?.user?.follower_demographics_last_30_days_age_gender
              ?.isError &&
            data?.data?.user?.follower_demographics_last_30_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user?.follower_demographics_last_30_days_age_gender
                ?.breakDowns?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
            break;
          }
          break;
        }
        case "engAudience":
          if (
            data?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isBreakDown &&
            !data?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isError &&
            data?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user
                ?.engaged_audience_demographics_last_90_days_age_gender
                ?.breakDowns?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
          }
          break;

        case "reAudience":
          if (
            data?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isBreakDown &&
            !data?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isError &&
            data?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user
                ?.reached_audience_demographics_last_90_days_age_gender
                ?.breakDowns?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
          }
          break;
      }
    } catch (error) {
      console.log("err", error);
    }

    return totalObject;
  };

  // conso;

  const barData = {
    labels: Object.keys(getAgeGenderData()),
    datasets: [
      {
        label: "Female",
        data: Object.values(getAgeGenderData()).map((item) => item?.F),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(getAgeGenderData()).map((item) => item?.M || 0),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown ",
        data: Object.values(getAgeGenderData()).map((item) => item?.U || 0),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  };

  const getAgeRangeData = () => {
    const totalObjectArray = Object.entries(getAgeGenderData()).map((ele) => {
      return {
        label: ele[0],
        percent: Object.values(ele[1]).reduce(
          (prev, current) => (current || 0) + prev,
          0
        ),
      };
    });

    const totalValue = totalObjectArray?.reduce(
      (prev, current) => (current.percent || 0) + prev,
      0
    );

    return totalObjectArray?.map((item) => ({
      ...item,
      percent: (item.percent / (totalValue || 1)) * 100,
    }));
  };

  const getMaleFemaleRatioData = () => {
    const female =
      Object.values(getAgeGenderData())
        .map((item) => item?.F || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    const male =
      Object.values(getAgeGenderData())
        .map((item) => item?.M || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    const unknown =
      Object.values(getAgeGenderData())
        .map((item) => item?.U || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    return [female, male, unknown, female + male + unknown];
  };

  const getCityData = () => {
    let totalCityObject: { [key: string]: any } = {};

    try {
      switch (typeSelected) {
        case "followers": {
          if (
            data?.data?.user?.follower_demographics_last_30_days_city
              ?.isBreakDown &&
            !data?.data?.user?.follower_demographics_last_30_days_city
              ?.isError &&
            data?.data?.user?.follower_demographics_last_30_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user?.follower_demographics_last_30_days_city
                ?.breakDowns?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
          break;
        }
        case "engAudience":
          if (
            data?.data?.user?.engaged_audience_demographics_last_90_days_city
              ?.isBreakDown &&
            !data?.data?.user?.engaged_audience_demographics_last_90_days_city
              ?.isError &&
            data?.data?.user?.engaged_audience_demographics_last_90_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user?.engaged_audience_demographics_last_90_days_city
                ?.breakDowns?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
          break;

        case "reAudience":
          if (
            data?.data?.user?.reached_audience_demographics_last_90_days_city
              ?.isBreakDown &&
            !data?.data?.user?.reached_audience_demographics_last_90_days_city
              ?.isError &&
            data?.data?.user?.reached_audience_demographics_last_90_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user?.reached_audience_demographics_last_90_days_city
                ?.breakDowns?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
          break;
      }
    } catch (error) {
      console.log("err", error);
    }

    const totalValue = Object.values(totalCityObject)?.reduce(
      (prev, current) => current + prev,
      0
    );

    return Object.entries(totalCityObject)
      .map((ele) => {
        return {
          label: ele[0],
          percent:
            (ele[1] /
              ((typeSelected === "followers"
                ? data?.data?.user?.followers_count
                : totalValue) || 1)) *
            100,
        };
      })
      .sort((a, b) => b.percent - a.percent);
  };

  const getCountryData = () => {
    let totalCountryObject: { [key: string]: any } = {};

    try {
      switch (typeSelected) {
        case "followers": {
          if (
            data?.data?.user?.follower_demographics_last_30_days_country
              ?.isBreakDown &&
            !data?.data?.user?.follower_demographics_last_30_days_country
              ?.isError &&
            data?.data?.user?.follower_demographics_last_30_days_country
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user?.follower_demographics_last_30_days_country
                ?.breakDowns?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
          break;
        }
        case "engAudience":
          if (
            data?.data?.user?.engaged_audience_demographics_last_90_days_country
              ?.isBreakDown &&
            !data?.data?.user
              ?.engaged_audience_demographics_last_90_days_country?.isError &&
            data?.data?.user?.engaged_audience_demographics_last_90_days_country
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user
                ?.engaged_audience_demographics_last_90_days_country?.breakDowns
                ?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
          break;

        case "reAudience":
          if (
            data?.data?.user?.reached_audience_demographics_last_90_days_country
              ?.isBreakDown &&
            !data?.data?.user
              ?.reached_audience_demographics_last_90_days_country?.isError &&
            data?.data?.user?.reached_audience_demographics_last_90_days_country
              ?.isMetricDataAviable
          ) {
            Object.entries(
              data?.data?.user
                ?.reached_audience_demographics_last_90_days_country?.breakDowns
                ?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
      }
    } catch (error) {
      console.log("err", error);
    }

    const totalValue = Object.values(totalCountryObject)?.reduce(
      (prev, current) => current + prev,
      0
    );

    return Object.entries(totalCountryObject)
      .map((ele) => {
        return {
          label: ele[0],
          percent:
            (ele[1] /
              ((typeSelected === "followers"
                ? data?.data?.user?.followers_count
                : totalValue) || 1)) *
            100,
        };
      })
      .sort((a, b) => b.percent - a.percent);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Audience Insights</p>
      <Row justify="space-between">
        <Space size={10}>
          <Option
            title="Followers"
            handleClick={() => setTypeSelected("followers")}
            isSelected={typeSelected === "followers"}
          />
          <Option
            title="Engaged Audience"
            handleClick={() => setTypeSelected("engAudience")}
            isSelected={typeSelected === "engAudience"}
          />
          <Option
            title="Reached Audience"
            handleClick={() => setTypeSelected("reAudience")}
            isSelected={typeSelected === "reAudience"}
          />
        </Space>

        <CustomDropDown
          disabled
          items={[
            {
              label: "Last 90 Days",
              key: "1",
              onClick: () => {},
            },
          ]}>
          <a
            onClick={(e) => e.preventDefault()}
            className={styles.optionsContainer}>
            Last 90 Days
            {/* <BiCaretDown color="#00000080" size={8} /> */}
          </a>
        </CustomDropDown>
      </Row>
      <p className={styles.desc}>
        Demographic split of the followers of the influencers.
        <br />
        Lifetime value gives the split of all the followers of the influencers
        that still follows them.
      </p>
      <Row gutter={[20, 20]}>
        <Col span={15}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() =>
                handlePin({
                  title: "Age-Gender Ratio",
                })
              }>
              {isPinned("Age-Gender Ratio") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <p className={styles.graphLabel}>Age-Gender Ratio</p>
            <div
              style={{
                width: "100%",
                height: "308px",
              }}>
              {Object.values(getAgeGenderData()).reduce(
                (prev, current) =>
                  prev +
                    Object.values(current)?.reduce((x, y) => x + (y || 0), 0) ||
                  0,
                0
              ) === 0 ? (
                <p className="global-no-data">
                  Not enough Data To show this Metric
                </p>
              ) : (
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: false,
                      },
                      datalabels: {
                        anchor: "end",
                        align: "top",
                        formatter: (value) => formatData(value || 0, "a"),
                      },
                    },
                    scales: {
                      y: {
                        // display: false,
                      },
                      x: {
                        grid: {
                          // display: false,
                        },
                      },
                    },
                  }}
                  data={barData}
                  style={{ height: "320px" }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col span={7}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => handlePin({ title: "City wise split" })}>
              {isPinned("City wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <p className={styles.graphLabel}>City wise split</p>
            <StatsCard items={getCityData()} />
          </div>
        </Col>
        <Col span={7}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => handlePin({ title: "Country wise split" })}>
              {isPinned("Country wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <p className={styles.graphLabel}>Country wise split</p>
            <StatsCard items={getCountryData()} />
          </div>
        </Col>
        <Col span={7}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => handlePin({ title: "Age Range" })}>
              {isPinned("Age Range") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <p className={styles.graphLabel}>Age Range</p>
            <StatsCard items={getAgeRangeData()} />
          </div>
        </Col>
        <Col span={7}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => handlePin({ title: "Male Female Ratio" })}>
              {isPinned("Male Female Ratio") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
            <p className={styles.graphLabel}>Male Female Ratio</p>
            <div
              style={{
                height: "308px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              {getMaleFemaleRatioData().reduce(
                (prev, current) => prev + current || 0,
                0
              ) === 0 ? (
                <p className="global-no-data">
                  {" "}
                  Not enough Data To show this Metric{" "}
                </p>
              ) : (
                <Doughnut
                  options={{
                    plugins: {
                      legend: { position: "top" },
                      datalabels: {
                        anchor: "end",
                        align: "start",

                        formatter: (value) => formatData(value || 0, "a"),
                        font: {
                          size: 16,
                          style: "normal",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [
                      `Female ${formatData(
                        getMaleFemaleRatioData()[0] /
                          (getMaleFemaleRatioData()[3] || 1),
                        "%"
                      )}`,
                      `Male ${formatData(
                        getMaleFemaleRatioData()[1] /
                          (getMaleFemaleRatioData()[3] || 1),
                        "%"
                      )}`,
                      `Unknown ${formatData(
                        getMaleFemaleRatioData()[2] /
                          (getMaleFemaleRatioData()[3] || 1),
                        "%"
                      )}`,
                    ],
                    datasets: [
                      {
                        label: "No",
                        data: getMaleFemaleRatioData().slice(0, 3),
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCD56"],
                        borderColor: ["#FF6384", "#36A2EB", "#FFCD56"],
                        borderWidth: 1,
                        borderJoinStyle: "round",
                        borderRadius: 10,
                        hoverOffset: 4,
                        offset: 0,
                      },
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AudienceInsights;
