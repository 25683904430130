import { Card, Col, Row } from "antd";
import { format } from "date-fns";
import React from "react";
import styles from "./card.module.css";

interface CampaignCompletedCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    cardMessage: {
      normal: string;
      blue: string;
    };
    cardTimestamp?: string;
  };
}

const CampaignCompletedCard: React.FC<CampaignCompletedCardProps> = ({
  item,
}) => {
  return (
    <div className={styles.card}>
      <Card
        bodyStyle={{
          fontWeight: 400,
          padding: "12px 16px",
        }}
        style={{
          borderRadius: "10px",
          borderWidth: "",
        }}
        headStyle={{
          backgroundColor: "#2130FF",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderWidth: "1.5px",
          color: "white",
          padding: "0px 16px",
        }}
        title={
          <div style={{ display: "grid", gap: "5px" }}>
            <p style={{ fontWeight: 600, fontSize: "14px" }}>{item.cardName}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  opacity: "0.6",
                }}>
                {item.cardTitle}
              </p>
              <p
                style={{
                  fontSize: "10px",
                }}>
                {format(new Date(item.cardTimestamp as string), "hh:mm")}
              </p>
            </div>
          </div>
        }>
        <Row>
          <Col>{item.cardMessage.normal}</Col>
        </Row>
      </Card>
    </div>
  );
};

export default CampaignCompletedCard;
