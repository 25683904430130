import React, { useState } from "react";
import { Modal, Input, DatePicker, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import type { DatePickerProps } from "antd/es/date-picker";
import { approveDraftV2 } from "../../../../../../../../handler/campaign/applicants";
import { CardType } from "../../../../../../../Chat/ChatWindow/Message/CardTypes";
import { AxiosError } from "axios";

interface ApproveModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  workflowId: string;
  prevDraftTimeLineId: string;
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  workflowId,
  prevDraftTimeLineId,
}) => {
  const { TextArea } = Input;
  const queryClient = useQueryClient();

  const approveDraftMutation = useMutation(approveDraftV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries([
        "chat",
        CardType.deliverableDraftSentType,
      ]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const onChange = (_: DatePickerProps["value"], dateString: string) => {
    setInputVal((formInput) => {
      return { ...formInput, deadline: dateString };
    });
  };

  const [inputVal, setInputVal] = useState<{
    deadline: string;
    comments: string;
  }>({ deadline: moment(Date.now()).add(3, "days").toString(), comments: "" });

  return (
    <Modal
      title="Approve Changes"
      visible={isVisible}
      onOk={() =>
        approveDraftMutation.mutate({
          workflowId,
          comments: inputVal.comments,
          prevDraftTimeLineId,
        })
      }
      onCancel={handleCancel}
      okButtonProps={{ loading: approveDraftMutation.isLoading }}>
      <TextArea
        autoSize={{ minRows: 4 }}
        placeholder="Add any comments here"
        value={inputVal.comments}
        onChange={(e) =>
          setInputVal((inputVal) => {
            return { ...inputVal, comments: e.target.value };
          })
        }
      />
      {/* <p
        style={{
          margin: "10px 0 4px",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
        }}>
        Set a deadline to go Live
      </p>
      <DatePicker
        size="middle"
        value={moment(inputVal.deadline)}
        onChange={onChange}
        allowClear={false}
      /> */}
    </Modal>
  );
};

export default ApproveModal;
