import { sendRequest } from "../helper";

type getInfluencerOnboardStatusResult = {
  status: string | null;
  message: string | null;
  data: {
    isSocialMediaFound: boolean;
    status: "Success" | "Pending" | "Not-Requested";
    influencerId?: string;
    socialMediaKitId?: string;
  };
};

export const getInfluencerOnboardStatus = ({
  saasId,
  platform,
}: {
  saasId: string;
  platform: "instagram" | "youtube";
}) => {
  const queryKey = ["saas", "influencer-status", saasId];
  const queryFunction = () =>
    sendRequest<getInfluencerOnboardStatusResult>(
      "get",
      `/brand/discovery/saas/connected-influencer?saasId=${saasId}&platform=${platform}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
