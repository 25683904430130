import React, { useState } from "react";
import { Row, Col, Spin, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getCampaign } from "../../../../handler/campaign";

import Navbar from "../../../shared/Navbar";
import CreateSteps from "../Steps";
import Footer from "../Footer";

import SuccessModal from "./Modal";

const Review = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId: id!,
  });
  const { data: campaignData, isLoading } = useQuery(
    getCampaignKey,
    getCampaignFunction
  );

  const [modalVisible, setModalVisible] = useState<boolean>(true);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }
  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "100px 0 50px" }}>
        <Col span={24} style={{ display: "none" }}>
          <Col offset={2}>
            <Row
              justify="center"
              style={{
                fontWeight: 500,
                fontSize: "30px",
                lineHeight: "40px",
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.85)",
              }}>
              Campaign Under review
            </Row>
            <Row justify="center">
              <p
                style={{
                  marginTop: "6px",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "rgba(0, 0, 0, 0.45)",
                  maxWidth: "400px",
                }}>
                We’ll review your campaign and provide you feedback within 1-2
                business hours. Keep a look at your email.
              </p>
            </Row>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={2}>
          {campaignData && (
            <CreateSteps
              currentStep={5}
              statusHistory={campaignData?.data.campaign.statusHistory}
            />
          )}
        </Col>
        <Col span={8} offset={2} style={{ paddingTop: "10px" }}>
          <Row justify="center" style={{ height: "70px", margin: "0 0 50px" }}>
            <Col span={24}>
              <Col offset={2}>
                <Row
                  justify="center"
                  style={{
                    fontWeight: 500,
                    fontSize: "30px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, 0.85)",
                  }}>
                  Campaign Under review
                </Row>
                <Row justify="center">
                  <p
                    style={{
                      marginTop: "6px",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "22px",
                      textAlign: "center",
                      color: "rgba(0, 0, 0, 0.45)",
                      maxWidth: "400px",
                    }}>
                    We’ll review your campaign and provide you feedback within
                    1-2 business hours. Keep a look at your email.
                  </p>
                </Row>
              </Col>
            </Col>
            <Button
              type="primary"
              size="large"
              style={{
                padding: "8px 20px",
                borderRadius: "4px",
                marginTop: "20px",
              }}
              onClick={() => {
                navigate(`/`);
              }}>
              {" "}
              View All campaigns
            </Button>
          </Row>
          {/* <ReactPlayer
            url="https://www.youtube.com/watch?v=YWq3QH4vFvQ"
            light={true}
          /> */}
          <SuccessModal
            isVisible={modalVisible}
            handleSubmit={() => setModalVisible(false)}
          />
        </Col>
      </Row>
      <Footer
        onNext={() => {
          navigate(`/`);
        }}
        onBack={() => {
          navigate(`/campaign/create/${id}/guidelines`);
        }}
        isNextDisabled={true}
        isType={"Next"}
      />
    </div>
  );
};

export default Review;
