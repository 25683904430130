import React, { useState } from "react";
import { useQuery } from "react-query";
import { Col, Row, Space, Popover } from "antd";
import { MdTimeline } from "react-icons/md";
import { PiLinkSimpleHorizontalBold } from "react-icons/pi";
import { LuPin } from "react-icons/lu";
import { BiCaretDown } from "react-icons/bi";
import { BsPersonFillCheck } from "react-icons/bs";
import { IoMdInformationCircleOutline } from "react-icons/io";

import { Doughnut, Bar } from "react-chartjs-2";

import styles from "./../style.module.css";
import styles2 from "./styles.module.css";

import { PinnedMetric } from "../../IgAnalytics";
import MetricCard from "../../Shared/MetricCard";
import LineGraph from "../../BasicInsights/LineGraph";
import CustomDropDown from "../../../shared/Custom/DropDown";

import { getIgAnalytics } from "../../../../handler/Profile";
import { getReportPlatformConfiguration } from "../../../../handler/report";
import {
  formatData,
  getDayName,
  DayType,
  findSequentialObjectsFromEnd,
  calculateArrayAverage,
  addArrays,
} from "../../../../utils";

import { timesArray } from "../../../../constants";

import AudienceInsights from "./AudienceInsights";

import {
  igReelUserAnalysisConfig,
  igAllUserAnalysisConfig,
  igFeedUserAnalysisConfig,
  igStoryUserAnalysisConfig,
} from "../../../../constants";

type PinnedMetricesProps = {
  influencerId: string;
  pinnedMetrices: PinnedMetric[];
  setPinnedMetrices?: React.Dispatch<React.SetStateAction<PinnedMetric[]>>;
};

const PinnedMetrices: React.FC<PinnedMetricesProps> = ({
  pinnedMetrices,
  setPinnedMetrices,
  influencerId,
}) => {
  const postInsights: PinnedMetric[] = pinnedMetrices.filter(
    (item) => item.title && !item.period && !item.type
  );

  const [onlineFollowerOption, setOnlineFollowerOption] = useState<
    "day" | "hour"
  >("day");

  const basicInsights: PinnedMetric[] = pinnedMetrices.filter((item) =>
    item.title.includes("Reach-breakdown")
      ? false
      : item.title && item.period && !item.type
  );

  const audienceInsights: PinnedMetric[] = pinnedMetrices.filter(
    (item) => item.title && item.period && item.type
  );

  const isOnlineFollowersPinned = pinnedMetrices?.findIndex(
    (item) => item.title === "Average Online Followers"
  );

  const [influencerKey, influencerFn] = getIgAnalytics(influencerId || "");

  const togglePin = (title: string) => {
    const idx = pinnedMetrices.findIndex((item) => item.title === title);
    if (idx > -1) {
      setPinnedMetrices?.((prev) =>
        prev.filter((item) => item.title !== title)
      );
    } else {
      setPinnedMetrices?.((prev) => [...prev, { title }]);
    }
  };

  const { data: influencerData, isError } = useQuery(
    influencerKey,
    influencerFn,
    {
      enabled: Boolean(influencerId),
    }
  );
  const [configKey, configFn] = getReportPlatformConfiguration({
    platform: "instagram",
  });
  const { data: configData } = useQuery(configKey, configFn, {
    enabled: Boolean(influencerId),
  });

  // FOR POST INSIGHTS
  const getPostInsightsData = () => {
    const variableArr = configData?.data?.userFieldsConfiguration.filter(
      (item) => {
        return postInsights.find((x) => x.title === item.name);
      }
    );

    const data = Object.entries(
      influencerData?.data?.user?.all?.calculatedMetrics || {}
    ).filter((item) => {
      return variableArr?.find((x) => {
        return x.variableName === item[0];
      });
    });

    return data;
  };

  // FOR BASIC INSIGHTS
  const getBasicInsightsData = () => {
    const arr = basicInsights.map((item) => {
      let data;
      if (item.title === "Link in bio") {
        if (
          influencerData?.data?.user?.website_clicks_last_30_days?.isValue &&
          !influencerData?.data?.user?.website_clicks_last_30_days?.isError &&
          influencerData?.data?.user?.website_clicks_last_30_days
            ?.isMetricDataAviable
        ) {
          if (item.period === "month")
            data =
              (
                influencerData?.data?.user?.website_clicks_last_30_days
                  ?.values as Array<any>
              )?.map((item) => item?.value || 0) || new Array(30).fill(0);
          else {
            data =
              (
                influencerData?.data?.user?.website_clicks_last_30_days
                  ?.values as Array<any>
              )
                ?.slice(-7)
                ?.map((item) => item?.value || 0) || new Array(30).fill(0);
          }
        } else {
          data = new Array(item.period === "week" ? 7 : 30).fill(0);
        }
      } else if (item.title === "Follower growth") {
        if (
          influencerData?.data?.user?.follower_count_last_30_days?.isValue &&
          !influencerData?.data?.user?.follower_count_last_30_days?.isError &&
          influencerData?.data?.user?.follower_count_last_30_days
            ?.isMetricDataAviable
        ) {
          if (item.period === "month")
            data =
              (
                influencerData?.data?.user?.follower_count_last_30_days
                  ?.values as Array<any>
              )
                ?.slice(0, 28)
                .map((item) => item?.value || 0) || new Array(28).fill(0);
          else {
            data =
              (
                influencerData?.data?.user?.follower_count_last_30_days
                  ?.values as Array<any>
              )
                ?.slice(-7)
                ?.slice(0, 5)
                ?.map((item) => item?.value || 0) || new Array(30).fill(0);
          }
        } else {
          data = new Array(item.period === "week" ? 5 : 28).fill(0);
        }
      } else {
        if (
          influencerData?.data?.user?.online_followers_lifetime?.isValue &&
          !influencerData?.data?.user?.online_followers_lifetime?.isError &&
          influencerData?.data?.user?.online_followers_lifetime
            ?.isMetricDataAviable
        ) {
          if (item.period === "month")
            data =
              (
                influencerData?.data?.user?.online_followers_lifetime
                  ?.values as Array<any>
              )
                ?.slice(0, 28)
                .map(
                  (item) =>
                    Math.max(
                      ...(Object.keys(item?.value)?.length
                        ? (Object.values(item?.value) as number[])
                        : [0])
                    ) || 0
                ) || new Array(28).fill(0);
          else {
            data =
              (
                influencerData?.data?.user?.online_followers_lifetime
                  ?.values as Array<any>
              )
                ?.slice(-7)
                ?.slice(0, 5)
                ?.map(
                  (item) =>
                    Math.max(
                      ...(Object.keys(item?.value)?.length
                        ? (Object.values(item?.value) as number[])
                        : [0])
                    ) || 0
                ) || new Array(5).fill(0);
          }
        } else {
          data = new Array(item.period === "week" ? 5 : 28).fill(0);
        }
      }

      return { ...item, data };
    });

    return arr;
  };

  const getLabelsArray = (totalLength: number) => {
    return new Array(totalLength)
      .fill("Day")
      .map((item, idx) => `${item} ${idx}`);
  };

  const getColor = (value: number) => {
    const percentageToVal = value * 100;
    if (percentageToVal < 10) {
      return "red";
    }
    if (percentageToVal >= 10 && percentageToVal <= 50) {
      return "#F0BF4C";
    }
    return "#398200";
  };

  const getOnlineFollowersDataDays = () => {
    let totalFollowerObjectArray: { [key in DayType]: number[] } = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    };

    if (
      influencerData?.data?.user?.online_followers_lifetime?.isValue &&
      !influencerData?.data?.user?.online_followers_lifetime?.isError &&
      influencerData?.data?.user?.online_followers_lifetime?.isMetricDataAviable
    ) {
      const getSequenceDays = findSequentialObjectsFromEnd(
        influencerData?.data?.user?.online_followers_lifetime?.values,
        7
      );

      getSequenceDays.forEach((item) => {
        const dayName = getDayName(item.end_time);
        const hoursArray = Object.values(item.value) as number[];

        totalFollowerObjectArray[dayName] = hoursArray;
      });
    }
    return totalFollowerObjectArray;
  };

  const getBarData = (isDay = false) => {
    const data = getOnlineFollowersDataDays();
    if (isDay) {
      return {
        labels: Object.keys(data),
        datasets: [
          {
            label: "Online Followers",
            data: Object.values(data).map((valItem) =>
              Math.round(calculateArrayAverage(valItem))
            ),
            backgroundColor: "#533AF5",
            borderRadius: 4,
          },
        ],
      };
    }

    return {
      labels: timesArray,
      datasets: [
        {
          label: "Online Followers",
          data: addArrays(Object.values(data)).map((item) =>
            Math.round(item / 7)
          ),
          backgroundColor: "#533AF5",
          borderRadius: 4,
        },
      ],
    };
  };

  const getGoodTime = (isDay = false) => {
    const labelsArray = getBarData(isDay)?.labels;
    const data = getBarData(isDay)?.datasets[0]?.data || [];
    const maxIndex = data?.indexOf(Math.max(...data));
    return labelsArray.at(maxIndex);
  };

  const getOnlineFollowersAggregate = () => {
    let maxOnlineFollowers = 0;
    let averageOnlineFollowers = 0;
    let totalOnlineFollowersSum = 0;
    let totalOnlineFollowers = 0;
    let estimatedGenuineFollower = 0;

    const dataToIterate =
      (influencerData?.data?.user?.online_followers_lifetime
        ?.values as Array<any>) || [];

    if (
      influencerData?.data?.user?.online_followers_lifetime?.isValue &&
      !influencerData?.data?.user?.online_followers_lifetime?.isError &&
      influencerData?.data?.user?.online_followers_lifetime?.isMetricDataAviable
    ) {
      dataToIterate?.forEach((item) => {
        const dataArray = Object.values(item?.value || {}) as number[];
        const maxNumber = Math.max(...dataArray) || 0;

        maxOnlineFollowers = Math.max(maxNumber, maxOnlineFollowers);
        const arraySum = dataArray?.reduce((prev, current) => {
          return (prev || 0) + (current || 0);
        }, 0);
        totalOnlineFollowersSum += arraySum;
        totalOnlineFollowers += dataArray?.length;
      });
    }
    averageOnlineFollowers =
      (totalOnlineFollowersSum || 0) / (totalOnlineFollowers || 1);

    estimatedGenuineFollower = Math.min(
      ((maxOnlineFollowers || 0) * 2.5) /
        (influencerData?.data?.user?.followers_count || 1),
      1
    );

    return { estimatedGenuineFollower, averageOnlineFollowers };
  };

  // FOR REACH BREAKDOWN
  const getReachData = (period: "week" | "month", deliverableType: string) => {
    if (period === "week") {
      if (
        influencerData?.data?.user
          ?.reach_last_7_days_media_product_type_follow_type?.isBreakDown &&
        !influencerData?.data?.user
          ?.reach_last_7_days_media_product_type_follow_type?.isError &&
        influencerData?.data?.user
          ?.reach_last_7_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          influencerData?.data?.user
            ?.reach_last_7_days_media_product_type_follow_type?.breakDowns
            ?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          if (deliverableType === "all") {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (
            deliverableType === "feed" &&
            (key.includes("CAROUSEL") ||
              key.includes("IGTV") ||
              key.includes("POST"))
          ) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (deliverableType === "reel" && key.includes("REEL")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (deliverableType === "story" && key.includes("STORY")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
        });

        return [followers, nonFollowers];
      }
    } else {
      if (
        influencerData?.data?.user
          ?.reach_last_30_days_media_product_type_follow_type?.isBreakDown &&
        !influencerData?.data?.user
          ?.reach_last_30_days_media_product_type_follow_type?.isError &&
        influencerData?.data?.user
          ?.reach_last_30_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          influencerData?.data?.user
            ?.reach_last_30_days_media_product_type_follow_type?.breakDowns
            ?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          if (deliverableType === "all") {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (
            deliverableType === "feed" &&
            (key.includes("CAROUSEL") ||
              key.includes("IGTV") ||
              key.includes("POST"))
          ) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (deliverableType === "reel" && key.includes("REEL")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (deliverableType === "story" && key.includes("STORY")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
        });

        return [followers, nonFollowers];
      }
    }

    return [0, 0];
  };

  const reachBreakdownInsight = pinnedMetrices
    .filter((item) => item.title.includes("Reach-breakdown"))
    .map((item) => ({
      ...item,
      data: getReachData(
        item.period || "week",

        item.title.split("Reach-breakdown").length > 1
          ? item.title.split("Reach-breakdown").at(-1) || ""
          : "all"
      ),
    }));

  if (isError || !influencerData?.data) {
    return <>No Data Found</>;
  }

  return (
    <div>
      <p className={styles.pinned}>Pinned Metrics</p>
      {reachBreakdownInsight.length > 0 && (
        <Row style={{ marginBlock: 20 }} gutter={[20, 20]}>
          {reachBreakdownInsight.map((item, key) => {
            return (
              <Col span={8} key={key}>
                <div className={styles2.reachContainer}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPinnedMetrices?.((prev) => {
                        return prev.filter(
                          (x) =>
                            !(
                              x.title === item.title && x.period === item.period
                            )
                        );
                      });
                    }}>
                    <LuPin size={10} color={"#00000066"} />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <p className={styles2.reach}>Reach-breakdown</p>

                    {item.title.split("Reach-breakdown").length > 1 && (
                      <p style={{ marginLeft: "6px" }}>
                        ( {item.title.split("Reach-breakdown").at(-1)} )
                      </p>
                    )}

                    <p style={{ marginLeft: 10 }}>
                      {item.period && item.period === "week" ? "Week" : "Month"}
                    </p>
                  </div>
                  <div>
                    {item?.data?.reduce(
                      (prev, current) => prev + current || 0,
                      0
                    ) === 0 ? (
                      <p className="global-no-data">
                        {" "}
                        Not enough Data To show this Metric{" "}
                      </p>
                    ) : (
                      <Doughnut
                        options={{
                          plugins: {
                            legend: { position: "top" },
                            datalabels: {
                              anchor: "end",
                              align: "start",
                              formatter: (value) => formatData(value || 0, "a"),
                              font: {
                                size: 16,
                                style: "normal",
                              },
                            },
                          },
                        }}
                        data={{
                          labels: ["Follower", "Non Follower"],
                          datasets: [
                            {
                              label: "No",
                              data: getReachData(
                                item.period || "week",
                                item.title.split("Reach-breakdown").length > 1
                                  ? item.title
                                      .split("Reach-breakdown")
                                      .at(-1) || ""
                                  : "all"
                              ),
                              backgroundColor: ["#FF6384", "#FFCD56"],
                              borderColor: ["#FF6384", "#FFCD56"],
                              borderWidth: 1,
                              borderJoinStyle: "round",
                              borderRadius: 10,
                              hoverOffset: 4,
                              offset: 0,
                            },
                          ],
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      {postInsights.length > 0 && (
        <>
          <Space size={20} wrap style={{ marginBlock: 20 }}>
            {getPostInsightsData().map((ele, idx) => {
              const [key, value] = ele;
              const fieldName =
                configData?.data?.userFieldsConfiguration?.find(
                  (fieldItem) => fieldItem.variableName === key
                )?.name || key;
              const fieldDesc =
                configData?.data?.userFieldsConfiguration?.find(
                  (fieldItem) => fieldItem.variableName === key
                )?.description || "";
              const isPinned = pinnedMetrices.findIndex(
                (item) => item.title === fieldName
              );

              const userLevelConfig = [
                ...igReelUserAnalysisConfig,
                ...igAllUserAnalysisConfig,
                ...igStoryUserAnalysisConfig,
                ...igFeedUserAnalysisConfig,
              ];

              const showPercent =
                userLevelConfig?.find((item) => item.name === key)?.isPercent ||
                false;

              return (
                <MetricCard
                  key={idx}
                  title={fieldName}
                  desc={fieldDesc}
                  // metric={formatData((value as unknown as number) || 0, "a")}
                  metric={`${formatData(
                    (value as unknown as number) || 0,
                    "a"
                  )}${showPercent ? "%" : ""}`}
                  isPinned={isPinned > -1}
                  handlePin={(title: string) => {
                    togglePin(title);
                  }}
                />
              );
            })}
          </Space>
        </>
      )}
      {basicInsights.length > 0 && (
        <>
          <Row gutter={[20, 20]} style={{ marginBlock: 20 }}>
            {getBasicInsightsData().map((item, key) => {
              return (
                <Col span={12} key={key}>
                  <LineGraph
                    metric={
                      item.title === "Online Followers"
                        ? formatData(
                            (item.data.reduce(
                              (prev, current) => current + prev,
                              0
                            ) || 0) / (item.data.length || 1),
                            "a"
                          )
                        : formatData(
                            item.data.reduce(
                              (prev, current) => current + prev,
                              0
                            ) || 0,
                            "a"
                          )
                    }
                    isPinned={true}
                    handlePin={() => {
                      setPinnedMetrices?.((prev) =>
                        prev.filter(
                          (x) =>
                            !(
                              x.title === item.title && x.period === item.period
                            )
                        )
                      );
                    }}
                    title={item.title}
                    optionsItemArray={[]}
                    selectedOption={item.period || ""}
                    labelArray={getLabelsArray(item.data.length)}
                    metricLabel={
                      item.title === "Link in bio"
                        ? `Clicks in one ${item.period || ""}`
                        : item.title === "Online Followers"
                        ? "Avg Online Followers"
                        : "Follows in one " + item.period
                    }
                    description={
                      item.title === "Follower growth" ? (
                        <p
                          className={styles.followerGrowth}
                          style={{
                            color: getColor(
                              Math.min(
                                (item.data.reduce(
                                  (prev, current) => current + prev,
                                  0
                                ) || 0) /
                                  (influencerData?.data?.user
                                    ?.followers_count || 1),
                                1
                              )
                            ),
                          }}>
                          Follows in one {item.period || ""}
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            {" "}
                            {formatData(
                              Math.min(
                                (item.data.reduce(
                                  (prev, current) => current + prev,
                                  0
                                ) || 0) /
                                  (influencerData?.data?.user
                                    ?.followers_count || 1),
                                1
                              ),
                              "%"
                            )}
                          </span>
                        </p>
                      ) : item.title === "Online Followers" ? (
                        <p
                          className={styles.onlineFollowersGrowth}
                          style={{
                            color: getColor(
                              influencerData?.data?.user?.followers_count
                                ? Math.min(
                                    (Math.max(...item.data) * 250) /
                                      influencerData?.data?.user
                                        ?.followers_count,
                                    1
                                  )
                                : 0
                            ),
                          }}>
                          Estimated Genuine Followers
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            {influencerData?.data?.user?.followers_count
                              ? formatData(
                                  Math.min(
                                    (Math.max(...item.data) * 2.5) /
                                      influencerData?.data?.user
                                        ?.followers_count,
                                    1
                                  ),
                                  "%"
                                )
                              : "%"}
                          </span>
                        </p>
                      ) : influencerData?.data?.user?.website ? (
                        <p>
                          Current Link in bio
                          <br />
                          <a
                            className={styles.anchor}
                            href={influencerData?.data?.user?.website}
                            target="_blank"
                            rel="noopener noreferrer">
                            {influencerData?.data?.user?.website}
                          </a>
                        </p>
                      ) : (
                        <div />
                      )
                    }
                    graphData={item.data}
                    secondaryDescription={
                      item.title === "Link in bio" ? (
                        <p>
                          Clicks on link in bio
                          <br /> for past 1 {item.period || ""}
                        </p>
                      ) : item.title === "Follower growth" ? (
                        <p>
                          Followers gained or lost in
                          <br /> past one {item.period || ""}
                        </p>
                      ) : (
                        <p>
                          Max Followers who were online per
                          <br /> day calculated per hour.
                        </p>
                      )
                    }
                    secondaryIcon={
                      item.title === "Link in bio" ? (
                        <MdTimeline size={15} color="#000000B2" />
                      ) : (
                        <PiLinkSimpleHorizontalBold
                          size={15}
                          color="#000000B2"
                        />
                      )
                    }
                    barColor={
                      item.title === "Follower growth" ? "#398200" : undefined
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      {audienceInsights.length > 0 && (
        <AudienceInsights
          audienceInsights={audienceInsights}
          influencerData={influencerData}
          setPinnedMetrices={setPinnedMetrices}
        />
      )}
      {isOnlineFollowersPinned && (
        <Col span={24}>
          <div className={styles.graphContainer}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                return setPinnedMetrices?.((prev) => {
                  return prev.filter(
                    (x) => !(x.title === "Average Online Followers")
                  );
                });
              }}>
              <LuPin size={10} color={"#00000066"} />
            </div>
            <Row justify="space-between" style={{ width: "100%" }}>
              <p className={styles.graphLabel}>Average Online Followers</p>
            </Row>
            {getBarData().datasets[0]?.data.reduce(
              (prev, current) => prev + current || 0,
              0
            ) !== 0 && (
              <>
                <p
                  className={styles.secondaryDescription}
                  style={{ fontSize: 14, maxWidth: "fit-content" }}>
                  Estimated Genuine Follower{" "}
                  <Popover
                    title={
                      <p>
                        25% of followers of any account come online at any given
                        hour on an average,
                        <br /> multiplying the average online followers by 4, we
                        get estimated genuine <br /> followers of an account.
                      </p>
                    }
                    placement="right"
                    arrowPointAtCenter>
                    <IoMdInformationCircleOutline style={{ marginLeft: 5 }} />
                  </Popover>
                  <br />
                  <span
                    style={{
                      color: getColor(
                        getOnlineFollowersAggregate().estimatedGenuineFollower
                      ),
                    }}>
                    {formatData(
                      getOnlineFollowersAggregate().estimatedGenuineFollower,
                      "%"
                    )}
                  </span>
                </p>
                <Row justify="space-between" style={{ width: "100%" }}>
                  <Space align="center" size={10}>
                    <BsPersonFillCheck size={15} color="#000000B2" />
                    <p className={styles.secondaryDescription}>
                      Best Time to Post is <b>{getGoodTime()}</b> on{" "}
                      <b>{getGoodTime(true)}</b>
                    </p>
                  </Space>
                  <div>
                    <p className={styles.metric}>
                      {formatData(
                        getOnlineFollowersAggregate()?.averageOnlineFollowers,
                        "a"
                      )}
                    </p>
                    <p className={styles.metricLabel}>Avg Online Followers</p>
                  </div>
                </Row>

                <p style={{ color: "#533AF5" }}></p>
              </>
            )}
            <div
              style={{
                width: "100%",
                height: "308px",
              }}>
              {getBarData().datasets[0]?.data.reduce(
                (prev, current) => prev + current || 0,
                0
              ) === 0 ? (
                <p className="global-no-data">
                  Not enough Data To show this Metric
                </p>
              ) : (
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: false,
                      },
                      datalabels: {
                        anchor: "end",
                        align: "top",
                        formatter: (value) => formatData(value || 0, "a"),
                      },
                    },
                  }}
                  data={getBarData()}
                  style={{ height: "320px" }}
                />
              )}
            </div>
          </div>
        </Col>
      )}
    </div>
  );
};
export default PinnedMetrices;
