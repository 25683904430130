import { sendRequest } from "../../helper";

import { FinalizedWorkFlow } from "../../../typings";

interface getFinalizedWorkflowProps {
  offerId: string;
}

interface getFinalizedWorkflowResponse {
  status: string;
  message: string;
  data: FinalizedWorkFlow[];
}

export const getFinalizedWorkflow = ({
  offerId,
}: getFinalizedWorkflowProps) => {
  const queryKey = ["tester", "finalized", offerId];

  const queryFunction = () =>
    sendRequest<getFinalizedWorkflowResponse>(
      "get",
      `/brand/execution/workflow?finalizedOffersId=${offerId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
