import { createContext, useContext } from "react";

import { useQuery, useQueryClient } from "react-query";

import {
  getProfileAnalysisInsightIq,
  insightsiqPlatformWorkIdMap,
} from "../../../../handler/insightIq/getProfileAnalysis";

import { DiscoverCreator } from "../../../../typings";

type ContextState = {
  creatorData?: DiscoverCreator;
  workplatformId: string;
  identifier: string;
  isLoading: boolean;
  handleInvalidate: () => void;
  error?: any;
};

const creatorPageContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
  username: string;
  platform: "instagram" | "youtube";
}

export const CreatorPageContextProvider: React.FC<Props> = ({
  children,
  username,
  platform,
}) => {
  const identifier = username;

  const workplatformId = insightsiqPlatformWorkIdMap[platform];

  const [getCreatorDataKey, getCreatorDataFn] = getProfileAnalysisInsightIq({
    identifier,
    work_platform_id: workplatformId,
  });
  const { data, isLoading, error } = useQuery(
    getCreatorDataKey,
    getCreatorDataFn,
    {
      enabled: Boolean(identifier) && Boolean(workplatformId),
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidate = () => {
    queryClient.invalidateQueries(getCreatorDataKey);
  };

  return (
    <creatorPageContext.Provider
      value={{
        creatorData: data?.data,
        workplatformId,
        identifier,
        isLoading,
        handleInvalidate,
        error,
      }}
    >
      {children}
    </creatorPageContext.Provider>
  );
};

export const useCreatorPageContext = () => {
  const context = useContext(creatorPageContext);

  if (!context) {
    throw new Error(
      "useCreatorPageContext must be used in <CreatorPageContextProvider />"
    );
  }

  return context;
};
