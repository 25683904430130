import { sendRequest } from "../helper";
import { CampaignResponse, Product } from "../../typings";

export type postProductProps = {
  campaignId: string;
  product: {
    productTitle: string;
    productLink: string;
    price: string;
  };
};

export const postProduct = ({ campaignId, product }: postProductProps) => {
  //   const queryKey = ["campaign", "create"];
  //   const updatedProducts = products.map((product) => {
  //     return { title: product.title, price: product.price, link: product.link };
  //   });
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/create-campaign-form/product/${campaignId}`,
    false,
    { ...product }
  ).then((res) => res.data);
  //   return [queryKey, queryFn];
};
