import { sendRequest } from "../../helper";

export type withdrawOfferProps = {
  offerId: string;
};

export const withdrawOffer = ({ offerId }: withdrawOfferProps) => {
  return sendRequest(
    "patch",
    `/brand/campaign/offer/withdraw/${offerId}`,
    false
  ).then((res) => res.data);
};
