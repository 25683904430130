import { sendRequest } from "../helper";
import { MessageReply } from "../../typings";

interface addProductsProps {
  ig: MessageReply;
}

export const addProducts = ({ ig }: addProductsProps) => {
  return sendRequest("post", `/brand/products`, false, { ig }).then(
    (res) => res.data
  );
};
