import { sendRequest } from "../helper";
import { Campaign } from "../../typings";

export interface CampaignsResponseAll {
  status: string;
  message: string;
  data: Campaign[];
}

export const getAllCampaigns = ({
  pageNumber,
  status = "",
  influencerId = "",
  platform = "",
  isArchived = false,
}: {
  pageNumber: number;
  status?: string;
  influencerId?: string;
  platform?: string;
  isArchived?: boolean;
}) => {
  const queryKey = [
    "campaigns",
    "page",
    status,
    pageNumber,
    influencerId,
    platform,
    isArchived,
  ];

  const queryFunction = () =>
    sendRequest<CampaignsResponseAll>(
      "get",
      `/brand/campaign?limit=5&pageNo=${pageNumber}${
        status === "" ? "" : `&status=${status}`
      }${influencerId === "" ? "" : `&influencerId=${influencerId}`}${
        platform === "" ? "" : `&platform=${platform}`
      }&isArchived=${isArchived}
      `,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
