import React from "react";
import { Pagination as AntdPagination } from "antd";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { sleep } from "../../../utils";

const Pagination = () => {
  const { creatorData, setSearchParams, getResults, searchParams } =
    useCreatorSearchContext();

  if (!creatorData) return null;
  return (
    <AntdPagination
      current={
        Math.floor((searchParams?.offset || 0) / (searchParams?.limit || 1)) + 1
      }
      total={creatorData?.data?.metadata?.total_results}
      pageSize={searchParams?.limit}
      onChange={(page, _) => {
        setSearchParams((searchParams) => ({
          ...searchParams,
          offset: (page - 1) * (searchParams?.limit || 1),
        }));
        sleep(10).then(() => getResults());
      }}
      showSizeChanger={false}
    />
  );
};

export default Pagination;
