import { Radio } from "antd";
import { sleep } from "../../utils/sleep";
import { useCreatorSearchContext } from "../../context/CreatorSearchContext";

const TypeSelector = () => {
  const { searchParams, setSearchParams, getResults } =
    useCreatorSearchContext();

  const handleTypeChange = async (e: any) => {
    setSearchParams((sParams) => ({
      searchType: e.target.value,
      limit: 10,
      offset: 0,
      platform: sParams.platform,
      sort_by:
        e.target.value === "search-by-filter"
          ? {
              field: "AVERAGE_LIKES",
              order: "DESCENDING",
            }
          : null,
    }));
    await sleep(2);
    getResults();
  };

  return (
    <Radio.Group
      onChange={handleTypeChange}
      defaultValue={searchParams.searchType}
      value={searchParams.searchType}
      size="large"
    >
      <Radio.Button
        value="search-by-filter"
        style={{ borderRadius: "8px 0px 0px 8px" }}
      >
        Search By Filter
      </Radio.Button>
      <Radio.Button
        style={{ borderRadius: "0px 8px 8px 0px" }}
        value="search-by-profile"
      >
        Search By Profile
      </Radio.Button>
    </Radio.Group>
  );
};

export default TypeSelector;
