import React, { useState } from "react";
import { Button, Space, Table } from "antd";
import { useInstantSearch } from "react-instantsearch";
import { ColumnsType } from "antd/es/table";

import CreatorProfile from "../../Campaign/Manage/CreatorProfile";

import ReportButton from "../../Campaign/Report/ReportButton";

import styles from "../styles.module.css";

import { formatData } from "../../../utils";

import socialMediaUrlGen from "../../../utils/socialMediaUrlGen";
import { getOrgAppState } from "../../../handler/organization";
import { useQuery } from "react-query";
import RequestInfluencerModal from "../../CloutflowCampaign/Common/RequestInfluencerModal";
import InfluencerModal from "../../CloutflowCampaign/Common/SuggestInfluencerModal";

interface DataType {
  key: React.Key;
  handleName: string;
  username: string;
  dp: string;
  followers: number;
  engagement: number;
  socialMediaId: string;
  influencerId: string;
}

const ResultsTable = ({ platform }: { platform: "instagram" | "youtube" }) => {
  const { results } = useInstantSearch();
  const [orgUserKey, orgUserFn] = getOrgAppState();
  const { data: orgUserData, isLoading: orgUserDataLoading } = useQuery(
    orgUserKey,
    orgUserFn
  );
  const handleClose = () => {
    setCampaignModalProps({
      ...campaignModalProps,
      visible: false,
    });
  };
  const [campaignModalProps, setCampaignModalProps] = useState<{
    visible: boolean;
    platform: string;
    username: string;
  }>({ visible: false, platform: "instagram", username: "" });

  const columns: ColumnsType<DataType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { dp, handleName, username }) => (
        <Space size={10} align="center">
          <div className={styles.profilePictureContainer}>
            <img src={dp ?? ""} className={styles.profile} alt="profile" />
          </div>
          <div>
            <p className={styles.name}>{handleName ?? ""}</p>
            <a
              className={styles.username}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              href={socialMediaUrlGen({
                userNameOrChannelId: username,
                platform,
              })}
              onClick={() => {
                //   if (isLocked) {
                //     return setIsPurchaseModalVisible(true);
                //   }
                //   navigate(`/influencerAnalysis/${influencerId ?? " "}`);
              }}
            >
              @{username ?? ""}
            </a>
          </div>
        </Space>
      ),
    },
    {
      title: "Followers",
      dataIndex: "followers",
      key: "Followers",
      render: (_, { followers }) => (
        <>
          <p className={styles.followers}>{formatData(followers, "a")}</p>
          <p className={styles.label}>Followers</p>
        </>
      ),
    },
    {
      title: platform === "instagram" ? "Engagement" : "Avg. Video Views",
      dataIndex: "engagement",
      key: "Engagement",
      render: (_, { engagement }) => (
        <>
          <p className={styles.followers}>
            {formatData(engagement, platform === "instagram" ? "0.00" : "a")}
            {platform === "instagram" ? "%" : ""}
          </p>
          <p className={styles.label}>
            {platform === "instagram" ? "Engagement" : "Avg. Video Views"}
          </p>
        </>
      ),
    },
    {
      title: "Actions",
      render: (_, { influencerId, username }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReportButton
            isOutline
            onClick={() => {
              if (influencerId) {
                setCreatorProfileProps({ visible: true, influencerId });
              }
            }}
          >
            Open Profile
          </ReportButton>
          {orgUserData?.data.accessLevel !== "brand" && (
            <Button
              type="primary"
              style={{ borderRadius: "8px", marginLeft: "5px" }}
              onClick={() => {
                setCampaignModalProps({
                  platform: platform,
                  username: username,
                  visible: true,
                });
              }}
            >
              Suggest Influencer
            </Button>
          )}
          {orgUserData?.data.accessLevel === "brand" && (
            <Button
              type="primary"
              style={{ borderRadius: "8px", marginLeft: "5px" }}
              onClick={() => {
                setCampaignModalProps({
                  platform: platform,
                  username: username,
                  visible: true,
                });
              }}
            >
              Request Price
            </Button>
          )}
        </div>
      ),
    },
  ];

  const [creatorProfileProps, setCreatorProfileProps] = useState<{
    influencerId: string;
    visible: boolean;
  }>({ influencerId: "", visible: false });

  return (
    <>
      <Table
        // rowSelection={{
        //   selectedRowKeys: selectedCreatorList,
        //   onChange: (_, selectedRows) => {
        //     return setSelectedCreatorList(
        //       selectedRows?.map((item) => item.socialMediaId)
        //     );
        //   },
        // }}
        columns={columns}
        dataSource={results?.hits?.map((hitItem) => ({
          key: hitItem?.socialMediaKitId || "",
          handleName: hitItem?.name || "",
          username: hitItem?.username || "",
          dp: hitItem?.profilePicUrl || "",
          followers: hitItem?.followers || 0,
          engagement:
            platform === "youtube"
              ? hitItem?.video_avgViews
              : hitItem?.all_engagementRate,
          socialMediaId: hitItem?.socialMediaKitId || "",
          influencerId: hitItem?.influencerId || "",
        }))}
        pagination={false}
        scroll={{ y: 540 }}
      />
      <CreatorProfile
        handleClose={() =>
          setCreatorProfileProps({ visible: false, influencerId: "" })
        }
        isVisible={creatorProfileProps.visible}
        influencerId={creatorProfileProps.influencerId}
      />
      {orgUserData?.data.accessLevel === "brand" && (
        <RequestInfluencerModal
          visible={campaignModalProps.visible}
          onClose={handleClose}
          username={campaignModalProps.username}
          platform={campaignModalProps.platform}
        />
      )}
      {orgUserData?.data.accessLevel !== "brand" && (
        <InfluencerModal
          visible={campaignModalProps.visible}
          onClose={handleClose}
          username={campaignModalProps.username}
          platform={campaignModalProps.platform}
        />
      )}
    </>
  );
};

export default ResultsTable;
