import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Space, Col } from "antd";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { HiOutlinePencil } from "react-icons/hi";

import ReportButton from "../../ReportButton";
import EditCommercial from "../../Modals/EditCommercial";

import { IndividualInfluencerReport } from "../../../../../typings";

import { formatData } from "../../../../../utils";

import styles from "./styles.module.css";
import func from "../../../../../utils/socialMediaUrlGen";

interface CreatorCardProps {
  data: IndividualInfluencerReport;
}

const CreatorCard: React.FC<CreatorCardProps> = ({ data }) => {
  const [commercialVisible, setCommercialVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleReportClick = () => {
    window.open(window.location.pathname + "/" + data.socialMediaId);
  };

  return (
    <>
      <div className={styles.container}>
        <p className={styles.title}>Report</p>
        <Row justify="space-between">
          <div>
            <Space size={10}>
              <img
                src={data?.socialMediaKit?.profilePicUrl}
                alt="profile"
                className={styles.pic}
              />
              <div>
                <p className={styles.name}>
                  <a
                    style={{ color: "#333" }}
                    href={func({
                      userNameOrChannelId:
                        data?.socialMediaKit?.socialMediaName,
                      platform: data?.platform,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {data?.socialMediaKit?.socialMediaName}
                  </a>
                </p>
                <div style={{ display: "flex", gap: "6px" }}>
                  {data?.platform === "instagram" ? (
                    <AiOutlineInstagram size={20} />
                  ) : (
                    <AiOutlineYoutube size={20} />
                  )}

                  <p className={styles.username}>
                    @ {data?.socialMediaKit?.influencerName} (
                    {formatData(data?.socialMediaKit?.followerCount || "", "a")}
                    )
                  </p>
                </div>
              </div>
            </Space>
            <Col style={{ margin: "10px" }}>
              <Space size={10}>
                <ReportButton
                  ghost
                  isOutline
                  onClick={() =>
                    navigate(`/influencerAnalysis/${data?.influencerId}`)
                  }
                >
                  Open Profile
                </ReportButton>
                {!window.location.href.includes(data.socialMediaId) && (
                  <ReportButton onClick={handleReportClick} type="primary">
                    Open Report In Full Page
                  </ReportButton>
                )}
              </Space>
            </Col>
          </div>
          <div style={{ display: "grid", gap: "10px" }}>
            <p className={styles.priceLabel}>Price Added</p>
            <p className={styles.price}>
              {data?.brandPrice ? formatData(data?.brandPrice) : "NA"}
            </p>
            <ReportButton
              type="link"
              style={{
                textDecoration: "underline",
                padding: "0px",
              }}
              onClick={() => setCommercialVisible(true)}
            >
              <Space size={4}>
                <HiOutlinePencil />
                Edit Commercials
              </Space>
            </ReportButton>
          </div>
        </Row>
      </div>
      <EditCommercial
        campaignId={data.campaignId}
        platform={data?.platform}
        visible={commercialVisible}
        setVisible={setCommercialVisible}
        creator={{ ...data, noOfMedias: 0 }}
      />
    </>
  );
};

export default CreatorCard;
