import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

interface postCampaignProps {
  campaignName: string;
  platform: "instagram" | "youtube";
  startDate?: string;
  endDate?: string;
}

export const postCampaign = (props: postCampaignProps) => {
  //   const queryKey = ["campaign", "create"];
  return sendRequest<CampaignResponse>("post", "/campaign", false, {
    ...props,
  }).then((res) => res.data);
  //   return [queryKey, queryFn];
};
