import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";

import Lottie from "react-lottie";

import Nav from "./Nav";
import Home from "./Home";
import Pay from "./Pay";

import animationData from "../assets/lottie/Rocket.json";

import { getUser } from "../../handler/getUser";
import { getInvitations } from "../../handler/organization";
import { getAppState } from "../../handler/getAppState";

import { getDefaultLottieOptions } from "../../utils";

type LockedProps = {};

const Locked: React.FC<LockedProps> = () => {
  const [screen, setScreen] = useState<"home" | "pay">("home");

  const navigate = useNavigate();

  const [userKey, userFn] = getUser();
  const [getInvitationsKey, getInvitationsFn] = getInvitations({
    status: "Accepted",
    send: false,
  });
  const [appStateKey, appStateFn] = getAppState();

  const { isLoading: userDataLoading } = useQuery(userKey, userFn);
  const { isLoading: orgDataLoading } = useQuery(
    getInvitationsKey,
    getInvitationsFn
  );
  const { data, isLoading: appStateLoading } = useQuery(
    appStateKey,
    appStateFn,
    {
      refetchInterval: (userResult) => {
        if (
          userResult?.data?.subscriptionStatus === "active" ||
          userResult?.data?.subscriptionStatus === "past_due"
        )
          return false;
        return 5000;
      },
    }
  );

  useEffect(() => {
    if (
      data?.data?.subscriptionStatus === "active" ||
      data?.data?.subscriptionStatus === "past_due"
    ) {
      return navigate("/");
    }
  }, [data?.data?.subscriptionStatus]);

  if (orgDataLoading || userDataLoading || appStateLoading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <React.Fragment>
      <Nav />
      {screen === "home" ? <Home setScreen={setScreen} /> : <Pay />}
    </React.Fragment>
  );
};
export default Locked;
