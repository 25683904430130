import { sendRequest } from "../helper";

import { ReplyWorkflow } from "../../typings";

export type getWorkflowByIdResult = {
  status: string;
  message: string;
  data: { workflow: ReplyWorkflow };
};

type RProps = {
  workflowId: string;
};

export const getWorkflowById = (props: RProps) => {
  const queryKey = ["smart-replies", "workflows", "id", props.workflowId];
  const queryFunction = () =>
    sendRequest<getWorkflowByIdResult>(
      "GET",
      `/brand/smart-replies/workflow-by-id?workflowId=${props.workflowId}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
