import { sendRequest } from "../helper";

import { InfluencerReviews } from "../../typings";

type InfluencerReviewsResult = {
  status: string | null;
  message: string | null;
  data: InfluencerReviews;
};

export const getInfluencerReviews = (influencerId: string) => {
  const queryKey = ["influencer", influencerId, "reviews"];
  const queryFunction = ({ pageParam }: { pageParam?: string }) => {
    return sendRequest<InfluencerReviewsResult>(
      "get",
      `/brand/brand-influencer/reviews?limit=4&influencerId=${influencerId}${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => {
      return res.data;
    });
  };
  return [queryKey, queryFunction] as const;
};
