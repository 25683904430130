import { fieldConfiguration } from "../../typings/Report";
import { sendRequest } from "../helper";

export type Props = {
  data: {
    campaignId: string;
    fieldConfiguration: Partial<fieldConfiguration>[];
  };
};

export const updateReportOnlyCampaignConfiguration = async ({
  data,
}: Props) => {
  const clensedData = {
    campaignId: data?.campaignId,
    fieldConfiguration: data?.fieldConfiguration?.map((item) => {
      const newItem = item;
      delete newItem["_id"];
      return newItem;
    }),
  };

  const res = await sendRequest(
    "put",
    `/brand/report-only/campaign/configuration`,
    false,
    clensedData
  );

  return res.data;
};
