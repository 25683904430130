import { notificationTypes } from "../../../../shared/Navbar/Notification/Types";

export const getIcon = (notificationType: string) => {
  if (
    notificationType === notificationTypes.CampaignApprovalIssue ||
    notificationType === notificationTypes.KycIssue
  )
    return "/assets/icons/issue.svg";
  else if (notificationType === notificationTypes.PaymentFailed)
    return "/assets/icons/failed.svg";
  else if (
    notificationType === notificationTypes.CampaignApproved ||
    notificationType === notificationTypes.KycApproved ||
    notificationType === notificationTypes.PaymentDone
  )
    return "/assets/icons/approved.svg";
  else if (
    notificationType === notificationTypes.SendRequirementsReminder ||
    notificationType === notificationTypes.DraftsApprovedReminder ||
    notificationType === notificationTypes.PaymentPendingReminder ||
    notificationType === notificationTypes.LiveApprovedReminder
  )
    return "/assets/icons/reminder.svg";
  else return "";
};
