import { Card, Divider, Space, Row, Spin } from "antd";
import React, { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { format } from "date-fns";

import ReportButton from "../../Campaign/Report/ReportButton";
import MetricCard from "./../../Campaign/Report/ReportScreen/OverviewSection/MetricCard";
import TimeLineDrawer from "../../Campaign/Manage/Finalized/Timeline";

import { getInfluencerCampaigns } from "../../../handler/Profile";
import { getInfluencerCampaignsReport } from "../../../handler/report";

import styles from "./styles.module.css";

type CampaignsProps = {
  type: "report" | "exec";
  influencerId: string;
  socialMediaId?: string;
};

const Campaigns: React.FC<CampaignsProps> = (props) => {
  const { type, influencerId, socialMediaId = "" } = props;

  const [timelineDrawerProps, setTimelineDrawerProps] = useState<{
    visible: boolean;
    campaignId: string;
    applicantId: string | undefined;
  }>({ visible: false, campaignId: "", applicantId: "" });

  const elementRef = React.useRef<HTMLDivElement>(null);

  let scrollAmount = 0;
  let scrollMin = 0;
  let scrollMax = elementRef?.current?.clientWidth;

  const handleNext = () => {
    if (elementRef)
      elementRef?.current?.scrollTo({
        top: 0,
        left: Math.max((scrollAmount += 150), scrollMax || 150),
        behavior: "smooth",
      });
  };

  const handlePrev = () => {
    if (elementRef)
      elementRef?.current?.scrollTo({
        top: 0,
        left: Math.max((scrollAmount -= 150), scrollMin),
        behavior: "smooth",
      });
  };

  const [getInfluencerCampaignsKey, getInfluencerCampaignsFn] =
    getInfluencerCampaigns(influencerId, 100);

  const { data: campaignData, isLoading: isLoadingCampaignData } =
    useInfiniteQuery(getInfluencerCampaignsKey, getInfluencerCampaignsFn, {
      enabled: type === "exec" && Boolean(influencerId),
    });
  const [getInfluencerCampaignsReportKey, getInfluencerCampaignsReportFn] =
    getInfluencerCampaignsReport(socialMediaId, 100);

  const { data: campaignReportData, isLoading: isLoadingCampaignReportData } =
    useInfiniteQuery(
      getInfluencerCampaignsReportKey,
      getInfluencerCampaignsReportFn,
      {
        enabled: type === "report" && Boolean(socialMediaId),
      }
    );

  const getCampaignChipLabel = (action: string) => {
    try {
      switch (action) {
        case "Finalized":
          return { color: "#78f098", label: "Ongoing" };
        case "Completed":
          return { color: "#E7E6FE", label: "Completed" };
        case "Cancel":
          return { color: "#FF3333", label: "Cancelled" };
        default:
          return { color: "#428beb", label: "Unknown" };
      }
    } catch (error) {
      console.log(error);
      return { color: "#E7E6FE", label: "Pending" };
    }
  };

  return (
    <Card>
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div>
          <span
            style={{
              color: "rgba(0, 0, 0, 0.80)",
              fontFamily: "Inter",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {type === "exec" ? "⚡Connect Campaign" : "📈 Reporting Campaign"}
          </span>
        </div>
        <div>
          {/* <ReportButton
            type="primary"
            size="large"
            style={{ backgroundColor: "#533AF5", borderColor: "#533AF5" }}
          >
            {type === "exec" ? "Invite" : "Add"} to Campaign
          </ReportButton> */}
        </div>
      </section>
      <p
        style={{
          color: "#7F7F7F",
          fontFamily: "Inter",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
      >
        {type === "report"
          ? "Generate reports for self-managed campaigns"
          : "End-to-end campaign for your influencer marketing campaigns"}
      </p>
      <Divider style={{ marginBlock: 20 }} />
      <section>
        <Space size={0} style={{ margin: "20px 0" }} align="start">
          <MdKeyboardArrowLeft
            color=" rgba(0, 0, 0, 0.5)"
            size={22}
            style={{ cursor: "pointer" }}
            onClick={handlePrev}
          />
          <p className={styles.titleGrey}>Campaigns</p>
          <MdKeyboardArrowRight
            color=" rgba(0, 0, 0, 0.5)"
            size={22}
            style={{ cursor: "pointer" }}
            onClick={handleNext}
          />
        </Space>
        <div
          style={{
            width: "1100px",
            display: "flex",
            gap: "20px",
            overflowX: "scroll",
            paddingBottom: "20px",
            scrollbarWidth: "none",
          }}
          ref={elementRef}
          className={styles.check}
        >
          {isLoadingCampaignData || isLoadingCampaignReportData ? (
            <Row justify="center" style={{ width: "100%", marginBlock: 40 }}>
              <Spin />
            </Row>
          ) : (
            <>
              {type === "exec" ? (
                <>
                  {campaignData?.pages.length &&
                  campaignData?.pages[0]?.data?.campaigns?.length ? (
                    <>
                      {campaignData?.pages[0]?.data?.campaigns.map(
                        (campaignItem, idx) => {
                          return (
                            <div
                              onClick={() =>
                                setTimelineDrawerProps({
                                  visible: true,
                                  campaignId: campaignItem?.campaignId,
                                  applicantId:
                                    campaignItem.finalizedStatus?._id || "",
                                })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <MetricCard
                                title={campaignItem?.campaign?.campaignName}
                                desc={`Started on ${format(
                                  new Date(campaignItem?.createdDateTime),
                                  "d LLLL, uuuu "
                                )}`}
                                isChip
                                chipLabel={
                                  getCampaignChipLabel(
                                    campaignItem?.finalizedStatus?.status || ""
                                  ).label
                                }
                                chipColor={
                                  getCampaignChipLabel(
                                    campaignItem?.finalizedStatus?.status || ""
                                  ).color
                                }
                              />
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <Row
                      justify="center"
                      style={{ width: "100%", marginBlock: 40 }}
                    >
                      <h3> No Campaigns </h3>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  {campaignReportData?.pages.length &&
                  campaignReportData?.pages[0]?.data?.campaigns?.length ? (
                    <>
                      {campaignReportData?.pages[0]?.data?.campaigns.map(
                        (campaignItem, idx) => {
                          return (
                            <MetricCard
                              title={campaignItem?.campaign?.campaignName}
                              desc={`Added on ${format(
                                new Date(campaignItem?.createdDateTime),
                                "d LLLL, uuuu "
                              )}`}
                              isChip
                              chipLabel={` ${campaignItem?.campaign?.noOfMedias} Medias Added`}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <Row
                      justify="center"
                      style={{ width: "100%", marginBlock: 40 }}
                    >
                      <h3> No Campaigns </h3>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      <TimeLineDrawer
        isOpen={timelineDrawerProps.visible}
        // applicantId={campaign.finalizedStatus?._id || ""}
        // campaignId={campaign.campaignId}
        applicantId={timelineDrawerProps.applicantId || ""}
        campaignId={timelineDrawerProps.campaignId}
        influencerId={influencerId}
        handleClose={() => {
          setTimelineDrawerProps({
            visible: false,
            applicantId: "",
            campaignId: "",
          });
        }}
      />
    </Card>
  );
};
export default Campaigns;
