import React from "react";
import { Button, Col, Row } from "antd";

import { formatData } from "../../../../utils";

import styles from "./styles.module.css";

type MetricBoxProps = {
  title: string;
  occupany: number;
  description: string;
  buttonText: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  maxLimit: number;
  isOwner: boolean;
  buttonLoading?: boolean;
};

const MetricBox: React.FC<MetricBoxProps> = (props) => {
  const {
    title,
    description,
    occupany,
    buttonText,
    onClick = () => {},
    maxLimit,
    isOwner,
    buttonLoading = false,
  } = props;
  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <h6 className={styles.metricTitle}>{title}</h6>
      </Col>
      <Col span={24}>
        <p className={styles.metricOcc}>
          {occupany}/{maxLimit} Occupied{" "}
          <span>({formatData(occupany / (maxLimit || 1), "%")})</span>
        </p>

        <meter
          min="0"
          max={maxLimit}
          className={styles.metricSlider}
          value={occupany}
        />
      </Col>
      <Col span={24}>
        <p className={styles.metricDesc}>{description}</p>
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          onClick={onClick}
          disabled={!isOwner}
          loading={buttonLoading}
          style={
            isOwner
              ? {
                  borderRadius: 4,
                  background: "rgba(83, 58, 245, 0.15)",
                  color: "#533AF5",
                  borderColor: "transparent",
                }
              : {}
          }>
          {buttonText}
        </Button>
      </Col>
    </Row>
  );
};
export default MetricBox;
