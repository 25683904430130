import { formatData } from "../../../../../utils";

export const getRangeFormatter = (item: string) => {
  const [min, max] = item.split(":");
  if (min && max) {
    return `${formatData(Number(min), "a")} - ${formatData(Number(max), "a")}`;
  } else if (min) {
    return ` ${formatData(Number(min), "a")}+`;
  } else if (max) {
    return `< ${formatData(Number(max), "a")}`;
  }
};
