import React from "react";

import styles from "./title.module.css";

import { Divider } from "antd";

type TitleProps = {
  name?: string;
  tagline?: string;
  icon?: React.ReactNode;
};

const Title: React.FC<TitleProps> = (props) => {
  const {
    name = "Campaigns",
    tagline = "Learn how you can optimise your influencer Marketing using Cloutflow Ads",
    icon = <></>,
  } = props;
  return (
    <React.Fragment>
      <section className={styles.title}>
        <h1 className={styles.name}>{name}</h1>
        <Divider
          type="vertical"
          style={{
            height: 28,
            backgroundColor: "#00000033",
          }}
        />
        <div className={styles.taglineContainer}>
          <h2 className={styles.tagline}>{tagline}</h2>
          {icon}
        </div>
      </section>
    </React.Fragment>
  );
};
export default Title;
