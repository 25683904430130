import React from "react";
import { Input, InputProps } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { ReactNode } from "react";
import clsx from "clsx";

import styles from "./styles.module.css";

interface CustomInputProps extends InputProps {
  label: string | ReactNode;
  subLabel: string;
  error: string;
  labelClassName: string;
  subLabelClassName: string;
  containerStyles: React.CSSProperties;
  errorClassName: string;
}
interface CustomTextAreaProps extends TextAreaProps {
  label: string;
  subLabel: string;
  error: string;
  labelClassName: string;
  subLabelClassName: string;
}

const { TextArea } = Input;

const CustomInput: React.FC<Partial<CustomInputProps>> = ({
  label = "",
  subLabel = "",
  error = "",
  labelClassName = "",
  subLabelClassName = "",
  containerStyles,
  errorClassName = "",
  ...props
}) => {
  return (
    <div className={styles.container} style={containerStyles}>
      {label && <p className={clsx(styles.label, labelClassName)}>{label}</p>}
      {subLabel && (
        <p className={clsx(styles.subLabel, subLabelClassName)}>{subLabel}</p>
      )}
      {props.children ? (
        props.children
      ) : (
        <>
          <Input
            {...props}
            className={clsx(styles.inputContainer, props.className)}
            value={(props?.value?.toString() || " ").trimStart()}
          />
        </>
      )}
      {error && <p className={clsx(styles.error, errorClassName)}>{error}</p>}
    </div>
  );
};

export const CustomTextArea: React.FC<Partial<CustomTextAreaProps>> = ({
  label = "",
  subLabel = "",
  error = "",
  labelClassName = "",
  subLabelClassName = "",
  ...props
}) => {
  return (
    <div className={styles.container}>
      {label && <p className={clsx(styles.label, labelClassName)}>{label}</p>}
      {subLabel && (
        <p className={clsx(styles.subLabel, subLabelClassName)}>{subLabel}</p>
      )}
      <TextArea
        {...props}
        className={clsx(styles.inputContainerTextArea, props.className)}
        value={(props?.value?.toString() || "").trimStart()}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default CustomInput;
