import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type transProductProps = {
  campaignId: string;
  isProduct: boolean;
};

export const transProduct = ({ campaignId, isProduct }: transProductProps) => {
  return sendRequest<CampaignResponse>(
    "put",
    "/brand/campaign/create-campaign-form/trans-product",
    false,
    { campaignId, isProduct }
  ).then((res) => res.data);
};
