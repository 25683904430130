import React, { useState } from "react";
import { Typography, Radio } from "antd";

import { formatData } from "../../../../utils";

import { Bar } from "react-chartjs-2";

import { useCreatorPageContext } from "../context";

const { Title } = Typography;

const Demographics = () => {
  const { creatorData } = useCreatorPageContext();
  const [demographicType, setDemographicType] = useState<
    "age" | "language" | "ethnicity"
  >("age");

  const getBarData = () => {
    if (demographicType === "age") {
      return {
        labels: creatorData?.profile?.audience?.gender_age_distribution?.map(
          (item) => item.age_range
        ),
        datasets: [
          {
            label: "",
            data: creatorData?.profile?.audience?.gender_age_distribution?.map(
              (item) => Number(item?.value?.toPrecision(2) || 0)
            ),
            backgroundColor: "#533AF5",
            borderRadius: 4,
          },
        ],
      };
    }
    if (demographicType === "language") {
      return {
        labels: creatorData?.profile?.audience?.languages?.map(
          (item) =>
            new Intl.DisplayNames(["en"], { type: "language" }).of(
              item?.code || ""
            ) || item?.code
        ),
        datasets: [
          {
            label: "",
            data: creatorData?.profile?.audience?.languages?.map((item) =>
              Number(item?.value?.toPrecision(2) || 0)
            ),
            backgroundColor: "#533AF5",
            borderRadius: 4,
          },
        ],
      };
    }

    return {
      labels: creatorData?.profile?.audience?.ethnicities?.map(
        (item) => item.name
      ),
      datasets: [
        {
          label: "",
          data: creatorData?.profile?.audience?.ethnicities?.map((item) =>
            Number(item?.value?.toPrecision(2) || 0)
          ),
          backgroundColor: "#533AF5",
          borderRadius: 4,
        },
      ],
    };
  };

  return (
    <div>
      <Title level={5}>Audience demographics</Title>
      <Radio.Group
        options={[
          { label: "Age", value: "age" },
          { label: "Language", value: "language" },
          creatorData?.work_platform?.name === "Instagram"
            ? [{ label: "Ethnicity", value: "ethnicity" }]
            : [],
        ].flat()}
        optionType="button"
        buttonStyle="solid"
        value={demographicType}
        onChange={(e) => setDemographicType(e.target.value)}
      />
      <div style={{ height: "400px", marginTop: 30 }}>
        <Bar
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                position: "top" as const,
                display: false,
              },
              title: {
                display: false,
              },
              datalabels: {
                anchor: "end",
                align: "top",
                formatter: (value) => formatData(value || 0, "a"),
              },
            },
            scales: {
              x: { grid: { display: false } },
            },
          }}
          data={getBarData()}
        />
      </div>
    </div>
  );
};

export default Demographics;
