import React, { useState, useEffect } from "react";
import { Space, Row, Col, message } from "antd";
import Lottie from "react-lottie";
import { BsClockHistory } from "react-icons/bs";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import animationData from "../../../../assets/lottie/threeDotLoading.json";

import ReportModal from "../../ReportModal";
import CreatorCard from "../SharedComponents/CreatorCard";
import Option from "../../../../shared/UI/Option";
import DeliverableSelect from "../SharedComponents/DeliverableSelect";
import AddReportLink from "../AddReportLink";
import PurchaseMediaCredits from "../../../../Credits/PurchaseMediaCredits";

// import { useIsInViewport } from "../../../../../hooks/useIsInViewport";

import globalStyles from "../styles.module.css";

import styles from "../AddInfluencer/styles.module.css";

import {
  getYoutubeFeed,
  getInstaStories,
  getInstaFeed,
  addToReport,
  getCampaignAggregate,
  getInfluencerMedia,
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
  getCampaignReport,
  getInfluencerCommunityRelation,
} from "../../../../../handler/report";

import { getAppState } from "../../../../../handler/getAppState";

import {
  CreatorReport,
  YoutubeVideo,
  InstagramStories,
  InstagramFeedType,
} from "../../../../../typings";
import { capitalizeFirstLetter } from "../../../../../utils";
import socialMediaUrlGen from "../../../../../utils/socialMediaUrlGen";
import ReportButton from "../../ReportButton";

interface AddReportProps {
  creator: CreatorReport;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const AddReport: React.FC<AddReportProps> = ({
  creator,
  visible,
  setVisible,
  campaignId,
  platform,
}) => {
  const queryClient = useQueryClient();
  const [_, setSearchParams] = useSearchParams();

  const [influencerCommunityRelationKey, influencerCommunityRelationFn] =
    getInfluencerCommunityRelation({
      socialMediaId: creator?.socialMediaKit?.socialMediaId || "",
    });

  const { data: relationData, isLoading: relationLoading } = useQuery(
    influencerCommunityRelationKey,
    influencerCommunityRelationFn,
    {
      enabled: Boolean(creator?.socialMediaKit?.socialMediaId),
    }
  );

  const getErrorStatus = (accountStatus: string) => {
    switch (accountStatus) {
      case "Disconnected":
        return `is disconnected their ${
          platform === "instagram" ? "Instagram" : "Youtube"
        } account from our app. To get data for ${
          creator?.socialMediaKit?.socialMediaName
        } , Ask them to connect their Account Again with Cloutflow `;
      case "Session-Expired":
        return `${
          platform === "instagram" ? "Facebook" : "Youtube"
        } account session's has been expired. You cannot add their media to campaign`;

      default:
        break;
    }
  };

  const [userStateKey, userStateFn] = getAppState();

  const [mediaLimitModalVisible, setMediaLimitModalVisible] =
    useState<boolean>(false);

  const [campaignReportKey] = getCampaignAggregate({ campaignId });
  const [campaignKey] = getCampaignReport({ campaignId });

  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [mediaKey] = getInfluencerMedia({
    campaignId,
    socialMediaId: creator.socialMediaId,
  });

  const [postsSelected, setPostsSelected] = useState<
    (InstagramFeedType | InstagramStories | YoutubeVideo)[]
  >([]);
  const [typeSelected, setTypeSelected] = useState<
    "post" | "story" | "videos" | ""
  >("post");

  const [addReportLinkVisible, setAddReportLinkVisible] =
    useState<boolean>(false);

  const [getAllInstaFeedKey, getAllInstaFeedFn] = getInstaFeed(
    creator?.socialMediaKit._id
  );
  const [getAllInstaStoriesKey, getAllInstaStoriesFn] = getInstaStories(
    creator?.socialMediaKit._id
  );
  const [getAllInstaStoriesSavedKey, getAllInstaStoriesSavedFn] =
    getInstaStories(creator?.socialMediaKit._id, true);

  const [getAllYoutubeFeedKey, getAllYoutubeFeedFn] = getYoutubeFeed(
    creator?.socialMediaKit._id
  );

  const isQueryEnabled =
    relationData?.data?.accountStatus === "Connected" && visible;

  const {
    data: feedData,
    isLoading: feedLoading,
    hasNextPage: hasNextPagePageFeed,
    fetchNextPage: fetchNextPageFeed,
  } = useInfiniteQuery(getAllInstaFeedKey, getAllInstaFeedFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    enabled: typeSelected === "post" && isQueryEnabled,
  });
  const {
    data: storiesData,
    isLoading: storiesLoading,
    hasNextPage: hasNextPageStories,
    fetchNextPage: fetchNextPageStories,
  } = useInfiniteQuery(getAllInstaStoriesKey, getAllInstaStoriesFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    enabled: typeSelected === "story" && isQueryEnabled,
  });
  const {
    data: storiesSavedData,
    isLoading: storiesSavedLoading,
    hasNextPage: hasNextPageStoriesSaved,
    fetchNextPage: fetchNextPageStoriesSaved,
  } = useInfiniteQuery(getAllInstaStoriesSavedKey, getAllInstaStoriesSavedFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    enabled:
      typeSelected === "story" &&
      hasNextPageStories === false &&
      isQueryEnabled,
  });
  const {
    data: youtubeFeedData,
    isLoading: youtubeFeedLoading,
    hasNextPage: hasNextPageFeedYoutube,
    fetchNextPage: fetchNextPageFeedYoutube,
  } = useInfiniteQuery(getAllYoutubeFeedKey, getAllYoutubeFeedFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    enabled: typeSelected === "videos" && isQueryEnabled,
  });

  const { data: userData } = useQuery(userStateKey, userStateFn, {
    enabled: visible,
  });

  const addToReportMutation = useMutation(addToReport, {
    onSuccess: () => {
      message.success("Report Added");
      queryClient.invalidateQueries(campaignReportKey);
      queryClient.invalidateQueries(mediaKey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries("reports");
      queryClient.invalidateQueries(campaignKey);
      setSearchParams((searchParams) => {
        searchParams.set("influencer", creator?.socialMediaId);
        return searchParams;
      });

      setVisible(false);
    },
    onError: (err) => {
      message.error("Something went wrong while adding to report");
      console.log(err);
    },
  });

  useEffect(() => {
    setPostsSelected([]);
  }, [typeSelected]);

  useEffect(() => {
    if (!visible) {
      return setPostsSelected([]);
    }
  }, [visible]);

  useEffect(() => {
    if (platform === "youtube" && typeSelected !== "videos") {
      setTypeSelected("videos");
    }
  }, [platform, typeSelected]);

  const handleSelect = (
    item: InstagramFeedType | InstagramStories | YoutubeVideo
  ) => {
    if (postsSelected.findIndex((post) => post?._id === item?._id) === -1) {
      return setPostsSelected((postsSelected) => [...postsSelected, item]);
    }

    setPostsSelected((postsSelected) => {
      return postsSelected.filter((post) => post?._id !== item?._id);
    });
  };

  const getContentParams = () => {
    if (typeSelected === "post") {
      return {
        data: feedData,
        isLoading: feedLoading,
        hasNextPage: hasNextPagePageFeed,
        fetchNextPage: fetchNextPageFeed,
      };
    } else if (typeSelected === "story") {
      return {
        data: storiesData,
        isLoading: storiesLoading,
        hasNextPage: hasNextPageStories,
        fetchNextPage: fetchNextPageStories,
      };
    } else if (typeSelected === "videos") {
      return {
        data: youtubeFeedData,
        isLoading: youtubeFeedLoading,
        hasNextPage: hasNextPageFeedYoutube,
        fetchNextPage: fetchNextPageFeedYoutube,
      };
    } else {
      return {
        data: feedData,
        isLoading: feedLoading,
        hasNextPage: hasNextPagePageFeed,
        fetchNextPage: fetchNextPageFeed,
      };
    }
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (visible) {
      if (inView) {
        if (getContentParams().hasNextPage) {
          getContentParams().fetchNextPage();
        }
      }
      if (
        typeSelected === "story" &&
        !hasNextPageStories &&
        hasNextPageStoriesSaved
      ) {
        fetchNextPageStoriesSaved();
      }
    }
  }, [
    // isInViewport1,
    inView,
    typeSelected,
    hasNextPageStories,
    hasNextPageStoriesSaved,
    visible,
  ]);

  const isLimitReached =
    (userData?.data?.tierPlanUsed?.featureLimit?.addMedia?.addOnLimit || 0) +
      (userData?.data?.tierPlanUsed?.featureLimit?.addMedia?.maxLimit || 0) <
    (userData?.data?.tierPlanUsed?.featureLimit?.addMedia?.usedLimit || 0) +
      postsSelected.length;

  const handleSubmit = () => {
    if (isLimitReached) {
      return setMediaLimitModalVisible(true);
    }
    if (typeSelected === "story") {
      return addToReportMutation.mutate({
        campaignId,
        socialMediaId: creator.socialMediaId,
        socialMediaKitId: creator.socialMediaKit._id,
        deliverables: postsSelected.map((item) => ({
          mediaType: "story",
          deliverableMediaId: item._id,
        })),
      });
    } else if (typeSelected === "videos") {
      return addToReportMutation.mutate({
        campaignId,
        socialMediaId: creator.socialMediaId,
        socialMediaKitId: creator.socialMediaKit._id,
        deliverables: postsSelected.map((item) => ({
          mediaType: "video",
          deliverableMediaId: item._id,
        })),
      });
    } else {
      return addToReportMutation.mutate({
        campaignId,
        socialMediaId: creator.socialMediaId,
        socialMediaKitId: creator.socialMediaKit._id,
        deliverables: postsSelected.map((item, _) => {
          const delItem = item as InstagramFeedType;
          if ("productType" in delItem) {
            let mediaType = "none";
            if (delItem.productType === "REELS") {
              mediaType = "reel";
            } else {
              mediaType =
                delItem.type === "CAROUSEL_ALBUM"
                  ? "carousel"
                  : delItem?.type === "IMAGE"
                  ? "image"
                  : "video";
            }
            return {
              mediaType,
              deliverableMediaId: item._id,
            };
          }
          return { mediaType: "video", deliverableMediaId: item._id };
        }),
      });
    }
  };

  return (
    <>
      <ReportModal
        visible={visible}
        centered
        maskClosable={false}
        onCancel={() => setVisible(false)}
        onOk={() => handleSubmit()}
        okText={
          isLimitReached
            ? "Purchase Media Credits"
            : `Add ${postsSelected.length} ${typeSelected}`
        }
        okButtonProps={{
          disabled:
            postsSelected.length === 0 ||
            typeSelected === "" ||
            relationLoading ||
            !isQueryEnabled,
          loading: addToReportMutation.isLoading,
        }}
        // footer={null}
        title="Add report ">
        {visible && (
          <main style={{ display: "grid", gap: "20px" }}>
            <CreatorCard
              creator={creator}
              handleClick={() => {}}
              isSelected={false}
              campaignId={campaignId}
            />
            {Boolean(relationData?.data?.accountStatus === "Connected") &&
              creator.platform === "instagram" && (
                <div>
                  <p
                    className={globalStyles.label}
                    style={{ marginBottom: "20px" }}>
                    Select the type of media to add to report
                  </p>

                  <Space>
                    <Option
                      title="Post/ Reel"
                      isSelected={typeSelected === "post"}
                      handleClick={() => setTypeSelected("post")}
                    />
                    <Option
                      title="Story"
                      isSelected={typeSelected === "story"}
                      handleClick={() => setTypeSelected("story")}
                    />
                  </Space>
                </div>
              )}
            {Boolean(relationData?.data?.accountStatus === "Connected") &&
              typeSelected !== "" && (
                <div>
                  <p className={globalStyles.label}>
                    Select the {typeSelected} to add to report
                  </p>

                  <p className={globalStyles.subLabel}>
                    You can add multiple {typeSelected} at once
                  </p>
                  {platform === "youtube" && (
                    <ReportButton
                      type="link"
                      style={{
                        padding: 0,
                        textDecoration: "underline",
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#533AF5",
                      }}
                      onClick={() => {
                        setVisible(false);
                        setAddReportLinkVisible(true);
                      }}>
                      Add media by Link
                    </ReportButton>
                  )}
                  <div
                    style={{
                      height: "40vh",
                      overflowY: "scroll",
                      marginTop: "10px",
                    }}>
                    {getContentParams().isLoading ? (
                      <Lottie
                        options={defaultOptions}
                        height={50}
                        width={100}
                      />
                    ) : (
                      <div style={{ marginTop: "30px" }}>
                        {(getContentParams().data?.pages[0]?.data?.data || [])
                          .length > 0 ||
                        (storiesSavedData?.pages[0]?.data?.data || []).length >
                          0 ? (
                          <>
                            <Row gutter={[0, 10]}>
                              {getContentParams().data?.pages?.map(
                                (pageItem, pageNo) => {
                                  return pageItem.data?.data?.map(
                                    (item, idx) => {
                                      return (
                                        <Col
                                          span={
                                            typeSelected === "videos" ? 12 : 7
                                          }
                                          // offset={1}
                                        >
                                          <DeliverableSelect
                                            isLabelClickEnabled={false}
                                            handleClick={() =>
                                              handleSelect(item)
                                            }
                                            isSelected={
                                              postsSelected.findIndex(
                                                (post) => post._id === item._id
                                              ) !== -1
                                            }
                                            id={item._id}
                                            caption={
                                              platform === "youtube"
                                                ? (item as YoutubeVideo)[
                                                    "title"
                                                  ]
                                                : undefined
                                            }
                                            profilePic={
                                              platform === "youtube"
                                                ? (item as YoutubeVideo)[
                                                    "channelProfilePicUrl"
                                                  ] || ""
                                                : undefined
                                            }
                                            label={
                                              "productType" in item
                                                ? item.productType === "REELS"
                                                  ? "Reel"
                                                  : item.productType === "STORY"
                                                  ? "Story"
                                                  : "children" in item
                                                  ? "Carousel"
                                                  : "type" in item
                                                  ? item["media_url"]
                                                    ? item.type
                                                    : "Image"
                                                  : "Video"
                                                : " Youtube Video"
                                            }
                                            url={
                                              "children" in item
                                                ? item["children"]?.at(0)
                                                    ?.thumbnail_url ||
                                                  item["children"]?.at(0)
                                                    ?.media_url ||
                                                  ""
                                                : "media_url" in item
                                                ? item["media_url"] ||
                                                  item["thumbnail_url"] ||
                                                  ""
                                                : "thumbnail_url" in item
                                                ? item["thumbnail_url"] || ""
                                                : (item as YoutubeVideo)[
                                                    "thumbnail"
                                                  ] || ""
                                            }
                                            type={
                                              "type" in item
                                                ? item.type !== "CAROUSEL_ALBUM"
                                                  ? item.media_url
                                                    ? item.type
                                                    : "IMAGE"
                                                  : item["children"]?.at(0)
                                                      ?.type || "IMAGE"
                                                : "yt-video"
                                            }
                                          />
                                        </Col>
                                      );
                                    }
                                  );
                                }
                              )}
                              {typeSelected === "story" && (
                                <>
                                  <Col span={24} style={{ margin: "10px 0" }}>
                                    <Row justify="center">
                                      <Space>
                                        <BsClockHistory size={12} />
                                        <span
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            textDecoration: "underline",
                                            color: "rgba(0, 0, 0, 0.6)",
                                          }}>
                                          {" "}
                                          Older Stories
                                        </span>
                                      </Space>
                                    </Row>
                                  </Col>
                                  {storiesSavedData?.pages?.map(
                                    (pageItem, pageNo) => {
                                      return pageItem.data?.data?.map(
                                        (item, idx) => (
                                          <Col
                                            span={7}
                                            offset={1}
                                            ref={
                                              pageNo ===
                                                (storiesSavedData?.pages || [])
                                                  .length -
                                                  1 &&
                                              idx ===
                                                pageItem.data?.data.length - 1
                                                ? ref
                                                : null
                                            }>
                                            <DeliverableSelect
                                              handleClick={() =>
                                                handleSelect(item)
                                              }
                                              isSelected={
                                                postsSelected.findIndex(
                                                  (post) =>
                                                    post._id === item._id
                                                ) !== -1
                                              }
                                              id={item._id}
                                              label={"story"}
                                              url={item?.media_url}
                                              type={item?.type}
                                              isLabelClickEnabled={false}
                                            />
                                          </Col>
                                        )
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </Row>
                            <div
                              style={{
                                height: "2px",
                                width: "2px",
                                background: "red",
                              }}
                              ref={ref}></div>
                            {(getContentParams().hasNextPage ||
                              storiesSavedLoading ||
                              (typeSelected === "story" &&
                                hasNextPageStories === false &&
                                hasNextPageStoriesSaved)) && (
                              <Col span={24}>
                                <Row justify="center">
                                  <Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                  />
                                </Row>
                              </Col>
                            )}
                          </>
                        ) : (
                          <div style={{ display: "grid", gap: "10px" }}>
                            <p>
                              Cannot find {typeSelected} for{" "}
                              {creator.socialMediaKit.socialMediaName}
                            </p>
                            <p>
                              If you think there is an issue from our side{" "}
                              <a
                                href="mailto:app@cloutflow.com?subject=Issue While Adding Media"
                                className={globalStyles.highlightedLink}
                                style={{ textDecoration: "underline" }}>
                                contact us
                              </a>
                            </p>
                            <p>
                              Open {capitalizeFirstLetter(platform)} profile of{" "}
                              <span
                                className={globalStyles.highlightedLink}
                                style={{
                                  textDecoration: "underline",
                                  marginLeft: 2,
                                }}
                                onClick={() =>
                                  window.open(
                                    socialMediaUrlGen({
                                      userNameOrChannelId:
                                        creator.socialMediaKit.socialMediaName,
                                      platform,
                                    }),
                                    "_blank"
                                  )
                                }>
                                {creator.socialMediaKit.socialMediaName}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            {!Boolean(relationData?.data?.accountStatus === "Connected") &&
              !relationLoading && (
                <p className={styles.error} style={{ marginTop: 10 }}>
                  @{creator?.socialMediaKit?.socialMediaName}{" "}
                  {getErrorStatus(relationData?.data?.accountStatus || "")}
                </p>
              )}
          </main>
        )}
      </ReportModal>
      <AddReportLink
        visible={addReportLinkVisible}
        setVisible={setAddReportLinkVisible}
        campaignId={campaignId}
        platform={platform}
      />
      <PurchaseMediaCredits
        visible={mediaLimitModalVisible}
        handleClose={() => setMediaLimitModalVisible(false)}
      />
    </>
  );
};

export default AddReport;
