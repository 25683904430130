import { sendRequest } from "../helper";

import { ReplyWorkflow } from "../../typings";

interface editWorkflowProps {
  data: {
    mediaId: string;
    rulesApplied: string[];
    workflowId: string;
  };
}

export const editWorkflow = ({ data }: editWorkflowProps) => {
  return sendRequest("put", `/brand/smart-replies/workflow`, false, {
    ...data,
    platform: "instagram",
  }).then((res) => res.data);
};
