import React from "react";

import styles from "./chipInput.module.css";

const ChipInput = ({
  label,
  handleClick,
  isActive,
}: {
  label: string;
  isActive: boolean;
  handleClick: () => void;
}) => {
  return (
    <span
      className={isActive ? styles.active : styles.inActive}
      style={{ cursor: "pointer" }}
      onClick={handleClick}>
      {label}
    </span>
  );
};

export default ChipInput;
