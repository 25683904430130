import React from "react";

import { FiltersType } from "../index";
import { INSTANT_SEARCH_PLATFORM_FACET } from "../../constants";

import Chip from "./Chip";

import type { IndexUiState } from "instantsearch.js";

import { getRangeFormatter } from "./Utils";

interface FilterChipsAggregateProps {
  filters: FiltersType;
  setIndexUIState: React.Dispatch<React.SetStateAction<IndexUiState>>;
}

const FilterChipsAggregate: React.FC<FilterChipsAggregateProps> = ({
  filters,
  setIndexUIState,
}) => {
  const handleCloseFilter = (filterName: string, itemName?: string) => {
    if (filterName === `${INSTANT_SEARCH_PLATFORM_FACET}.user.followerCount`) {
      return setIndexUIState((indexUiState) => ({
        ...indexUiState,
        range: {
          ...indexUiState.range,
          [`${INSTANT_SEARCH_PLATFORM_FACET}.user.followerCount`]: "",
        },
      }));
    } else if (filterName === "influencerAge") {
      return setIndexUIState((indexUiState) => ({
        ...indexUiState,
        range: { ...indexUiState.range, influencerAge: "" },
      }));
    } else {
      return setIndexUIState((indexUiState) => ({
        ...indexUiState,
        refinementList: {
          ...indexUiState.refinementList,
          [filterName]: (
            indexUiState?.refinementList?.[filterName] || []
          ).filter((item) => item !== itemName),
        },
      }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        margin: "20px 0",
        flexWrap: "wrap",
      }}>
      {filters?.range &&
        Object.keys(filters.range).map((filterName) => {
          if (
            filterName === `${INSTANT_SEARCH_PLATFORM_FACET}.user.followerCount`
          ) {
            return (
              <Chip
                handleCloseFilter={handleCloseFilter}
                label={`followers: ${getRangeFormatter(
                  filters?.range?.[filterName] || ""
                )}`}
                filterName={filterName}
              />
            );
          }

          return (
            <Chip
              handleCloseFilter={handleCloseFilter}
              label={`Age: ${getRangeFormatter(
                filters?.range?.[filterName] || ""
              )}`}
              filterName={filterName}
            />
          );
        })}
      {filters?.refinementList &&
        Object.keys(filters.refinementList).map((filterName) => {
          return filters?.refinementList?.[filterName].map((item) => {
            return (
              <Chip
                handleCloseFilter={handleCloseFilter}
                label={item}
                filterName={filterName}
                isArrayItem
              />
            );
          });
        })}
    </div>
  );
};

export default FilterChipsAggregate;
