import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type editCampaignProps = {
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
};

export const editCampaign = (props: editCampaignProps) => {
  return sendRequest<CampaignResponse>(
    "put",
    "/brand/campaign/create-campaign-form/edit-campaign",
    false,
    { ...props }
  ).then((res) => res.data);
};
