import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { getCampaign, previewCampaign } from "../../../../handler/campaign";

import { CampaignResponse } from "../../../../typings";
import { message } from "antd";

type ContextState = {
  campaign: CampaignResponse["data"];
  setCampaign: React.Dispatch<React.SetStateAction<CampaignResponse["data"]>>;
  campaignId: string;
  handleInvalidate: () => void;
  fetchCampaignById: (id: string) => void;
  isLoading: boolean;
  postCampaignLoading: boolean;
  error: {
    cName: string;
    timeline: string;
    banner: string;
    products: string;
    promotionDescription: string;
    cType: string;
    category: string;
    deliverables: string;
  };
  handleSubmit: () => void;
};

const campaignContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CampaignContextProvider: React.FC<Props> = ({ children }) => {
  const [campaignId, setCampaignId] = useState<string>("");
  const firstRender = useRef(true);
  const [campaign, setCampaign] = useState<CampaignResponse["data"]>({
    campaign: {
      isArchived: false,
      brandId: "",
      brandGuidelines: "",
      campaignName: "",
      defaultDeal: "",
      filter: {
        followerCategory: [],
        niche: [],
      },
      isPublic: true,
      campaignBannerUrl: "",
      platform: "instagram",
      status: "",
      remarks: "",
      statusHistory: [],
      _id: "",
      createdDateTime: "",
      startDate: "",
      endDate: "",
    },
    deal: {
      brandId: "",
      campaignId: "",
      deliverables: [],
      isDefaultDeal: true,
      isProduct: true,
      platform: "instagram",
      productDetails: [],
      promotionDescription: "",
      _id: "",
    },
  });

  const navigate = useNavigate();

  const [error, setError] = useState<{
    cName: string;
    timeline: string;
    banner: string;
    products: string;
    promotionDescription: string;
    cType: string;
    category: string;
    deliverables: string;
  }>({
    cName: "",
    timeline: "",
    banner: "",
    products: "",
    promotionDescription: "",
    cType: "",
    category: "",
    deliverables: "",
  });

  const isCDataValid = () => {
    let valid = true;

    let errorObjet = { ...error };
    try {
      if (campaign.deal.isProduct) {
        errorObjet.promotionDescription = "";
      } else {
        errorObjet.products = "";
      }

      if (campaign.campaign.campaignName.trim() === "") {
        errorObjet.cName = "Please enter a Campaign Name";
        valid = false;
      }
      if (
        (campaign.campaign?.startDate || "").trim() === "" ||
        (campaign.campaign?.endDate || "").trim() === ""
      ) {
        errorObjet.timeline = "Please select Campaign Timeline";
        valid = false;
      }
      if (
        !campaign?.campaign?.campaignBannerUrl ||
        campaign.campaign.campaignBannerUrl.trim() === ""
      ) {
        errorObjet.banner = "Please upload Campaign Banner";
        valid = false;
      }
      if (
        campaign.deal.isProduct &&
        campaign.deal.productDetails.length === 0
      ) {
        errorObjet.products = "Please add atleast one product ";
        valid = false;
      }
      if (
        !campaign.deal.isProduct &&
        campaign.deal.promotionDescription.trim() === ""
      ) {
        errorObjet.promotionDescription = "Please add promotion description";
        valid = false;
      }
      if (campaign.deal.deliverables.length === 0) {
        errorObjet.deliverables = "Please add atleast one deliverable";
        valid = false;
      }
      if (campaign.campaign.filter.followerCategory.length === 0) {
        errorObjet.category = "Please select atleast one category";
        valid = false;
      }
    } catch (error) {
      console.log("error occured", error);
    }

    if (valid) {
      errorObjet = {
        banner: "",
        category: "",
        cName: "",
        cType: "",
        deliverables: "",
        products: "",
        promotionDescription: "",
        timeline: "",
      };
    }
    setError({ ...errorObjet });
    return valid;
  };

  const handleSubmit = () => {
    if (isCDataValid()) {
      postCampaignMutation.mutate({
        campaignObject: campaign,
        campaignId: campaign.campaign._id,
      });
    } else {
      message.error("Please recheck the form for errors");
    }
  };

  const [queryKey, queryFn] = getCampaign({ campaignId });
  const { data, isLoading } = useQuery(queryKey, queryFn, {
    enabled: campaignId !== "",
  });

  const postCampaignMutation = useMutation(previewCampaign, {
    onSuccess: () => {
      message.success("Campaign Created");
      handleInvalidate();
      navigate("/", {
        replace: true,
      });
    },
    onError: () => {
      message.error(
        "Something went wrong while Creating Campaign, Please try again "
      );
    },
  });

  const queryClient = useQueryClient();

  const handleInvalidate = () => {
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(["campaigns"]);
  };

  useEffect(() => {
    if (data?.data && firstRender.current) {
      setCampaign(data?.data);
      firstRender.current = false;
    }
  }, [data]);

  const fetchCampaignById = (id: string) => {
    setCampaignId(id);
  };

  return (
    <campaignContext.Provider
      value={{
        campaign,
        setCampaign,
        campaignId,
        handleInvalidate,
        fetchCampaignById,
        isLoading,
        error,
        handleSubmit,
        postCampaignLoading: postCampaignMutation.isLoading,
      }}>
      {children}
    </campaignContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(campaignContext);

  if (!context) {
    throw new Error("useCampaign must be used in <CampaignContextProvider />");
  }

  return context;
};
