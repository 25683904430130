import { sendRequest } from "../helper";
import { CreatorReport } from "../../typings";

export type addToReportProps = {
  socialMediaId: string;
  campaignId: string;
  socialMediaKitId: string;
  deliverables: { deliverableMediaId: string; mediaType: string }[];
};

export const addToReport = (props: addToReportProps) => {
  return sendRequest<CreatorReport>(
    "post",
    `/brand/report-only/report`,
    false,
    { ...props }
  ).then((res) => res.data);
};
