import React, { useEffect, useRef, useState } from "react";
import { Modal, Select, message, Radio, Space, Input } from "antd";

import { useMutation, useQueryClient } from "react-query";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { AxiosError } from "axios";
import type { RadioChangeEvent } from "antd";
import { Requirement } from "../../../../../typings";

import CollapsibleReq from "../ReqModal/CollapsibleReq";
import TextEditor from "../../../../shared/TextEditor";

import styles from "../ReqModal/reqModal.module.css";

import { postWorkFlowReqV2 } from "../../../../../handler/campaign/applicants/";

// import QuillTextEditor from "../../../../shared/TextEditor/QuillTextEditor";

const { Option } = Select;

const initialReqState: Requirement = {
  summary: "",
  mentions: [],
  caption: "",
  references: [],
  _id: "new-item",
  name: "",
  isDraftNeeded: true,
};

const instaDel = ["Video", "Post", "Story", "Reel"];
const youtubeDel = ["Integrated", "Shorts", "Dedicated"];

interface ReqModalNoDelProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  campaignId: string;
  applicantIds?: string[];
  isView: boolean;
  initialData?: Requirement;
  platform: "instagram" | "youtube";
}

const ReqModalNoDel: React.FC<ReqModalNoDelProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  campaignId,
  applicantIds = [],
  initialData = initialReqState,
  isView,
  platform,
}) => {
  const [requirement, setRequirement] = useState<Requirement>(initialData);
  const [otherDelType, setOtherDelType] = useState<string>("");
  const queryClient = useQueryClient();
  const firstRender = useRef(true);

  const postReqMutation = useMutation(postWorkFlowReqV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      handleSubmit();
      clearAppState();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err.response?.data as any).message);
    },
  });

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const clearAppState = () => {
    setEditorState(EditorState.createEmpty());
    setRequirement(initialReqState);
    setRadioValue("");
    setOtherDelType("");
  };

  const [radioValue, setRadioValue] = useState<string>("");

  const handleFormSubmit = () => {
    postReqMutation.mutate({
      finalizedOffersIds: applicantIds,
      campaignId,
      isDraftNeeded: true,
      summary: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      type: radioValue === "Others" ? otherDelType : radioValue,
    });
  };

  useEffect(() => {
    if (initialData.summary && firstRender) {
      const blocksFromHtml = convertFromHTML(initialData.summary);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      firstRender.current = false;
    }
  }, [initialData.summary]);

  return (
    <Modal
      title={`${isView ? "View" : "Send"} Requirement${
        applicantIds.length ? "s" : ""
      }`}
      visible={isVisible}
      onOk={handleFormSubmit}
      okText={isView ? "View" : "Send"}
      onCancel={() => {
        handleCancel();
        clearAppState();
      }}
      okButtonProps={{
        loading: postReqMutation.isLoading,
        disabled: !radioValue || (radioValue === "Others" && !otherDelType),
      }}
      centered>
      {!isView && (
        <>
          <div className={styles.item}>
            <p className={styles.heading}>Select Deliverable Type</p>
            <Radio.Group
              onChange={(e) => {
                setRadioValue(e.target.value);
              }}
              value={radioValue}>
              <Space direction="vertical">
                {(platform === "instagram" ? instaDel : youtubeDel)?.map(
                  (item) => (
                    <Radio value={item}>{item}</Radio>
                  )
                )}
                <Radio value={"Others"}>
                  Others...
                  {radioValue === "Others" ? (
                    <Input
                      style={{ width: 200, marginLeft: 10 }}
                      value={otherDelType}
                      onChange={(e) => setOtherDelType(e.target.value || "")}
                      placeholder="Enter Deliverable Name"
                    />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className={styles.item}>
            <p className={styles.heading}>
              {isView ? "Sent" : "Add"} Requirements
            </p>

            {!isView ? (
              <>
                <TextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  placeholder="Add your requirements here"
                />
              </>
            ) : (
              <div
                style={{ wordBreak: "break-all" }}
                dangerouslySetInnerHTML={{
                  __html: requirement?.summary,
                }}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReqModalNoDel;
