import { sendRequest } from "../helper";
import { InstagramFeedType } from "../../typings";

export type getInstaFeedResult = {
  status: string;
  message: string;
  data: {
    data: InstagramFeedType[];
    nextPageToken: string | null;
  };
};

export const getInstaFeed = (socialMediaKitId: string) => {
  const queryKey = ["reports", "influencer", socialMediaKitId, "feed"];

  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<getInstaFeedResult>(
      "get",
      `/brand/brand-influencer/media-kit/instagram/feed?socialMediaKitId=${socialMediaKitId}&limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
