import { Button, Card, Col, Row, Tag } from "antd";
import React, { useState, useEffect } from "react";
import styles from "./card.module.css";
import {
  CheckOutlined,
  LinkOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import { useQuery } from "react-query";
import { RequirementsCard } from "../../../../../typings";
import ReqModalTimeline from "../../../../Campaign/Manage/Finalized/ReqModalTimeline";
import { format } from "date-fns";
interface RequirementSentCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    ref?: {
      offerId?: string;
      requirementId?: string;
      workflowId?: string;
      campaignId?: string;
      deliverableId?: string;
      finalizedOfferId?: string;
      requirementsTimeLineIndex?: string;
    };
    messageId: string;
    cardTimestamp?: string;
    type: string;
    cardMessage: {
      blue: string;
      normal: string;
    };
  };
}

const RequirementSentCard: React.FC<RequirementSentCardProps> = ({ item }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewRequirement, setViewRequirement] = useState<boolean>(false);

  const [getChatCardKey, getChatCardDataFn] = getChatCardData({
    ref: item.ref,
    messageId: item.messageId,
    type: item.type,
  });

  const { data: cardData } = useQuery(getChatCardKey, getChatCardDataFn);

  useEffect(() => {
    if (cardData) {
      setViewRequirement((cardData?.data as RequirementsCard).isEdited);
    }
  }, [cardData]);

  return (
    <>
      {cardData && (
        <div className={styles.card}>
          <Card
            bodyStyle={{
              fontWeight: 400,
              padding: "12px 16px",
            }}
            style={{
              borderRadius: "10px",
              borderWidth: "",
            }}
            headStyle={{
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "10px 10px 0 0",
              padding: "0 16px",
            }}
            title={
              <div style={{ display: "grid", gap: "5px", padding: "0" }}>
                <p style={{ fontWeight: 600, fontSize: "14px" }}>
                  {item.cardName}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.6",
                    }}>
                    {item.cardTitle}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                    }}>
                    {format(new Date(item.cardTimestamp as string), "hh:mm")}
                  </p>
                </div>
              </div>
            }>
            {viewRequirement ? (
              <>
                <Row
                  style={{
                    borderBottom: "1px solid #e4e0e0",
                    paddingBottom: "10px",
                  }}>
                  <Col>Requirements have been updated</Col>
                </Row>
                <div className={styles.cardButton}>
                  <Button
                    onClick={() => setViewRequirement(false)}
                    size="small"
                    style={{
                      padding: "0px 7px",
                      marginTop: "12px",
                    }}>
                    View <UpOutlined />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.requirementCardInner}>
                  <div className={styles.deliverable}>
                    <h2 className={styles.requirementsHeading}>
                      Deliverable Details
                    </h2>
                    <p>
                      [
                      {
                        (cardData?.data as RequirementsCard).deliverablesDetails
                          .duration
                      }
                      ]s{" "}
                      {
                        (cardData?.data as RequirementsCard).deliverablesDetails
                          .typeDel
                      }
                    </p>
                    <div className="">
                      {(cardData?.data as RequirementsCard).deliverablesDetails
                        .contentRights !== "Not Needed" &&
                        (cardData?.data as RequirementsCard).deliverablesDetails
                          .contentRights !== undefined && (
                          <div className="">
                            <CheckOutlined />
                            <p>
                              [
                              {
                                (cardData?.data as RequirementsCard)
                                  .deliverablesDetails.contentRights
                              }
                              ] Content Rights
                            </p>
                          </div>
                        )}
                      {(cardData?.data as RequirementsCard).deliverablesDetails
                        .storyShare && (
                        <div className="">
                          <CheckOutlined />
                          <p>Share on Story</p>
                        </div>
                      )}
                      {(cardData?.data as RequirementsCard).deliverablesDetails
                        .link !== "" && (
                        <div className="">
                          <CheckOutlined />
                          <p>Swipe up link</p>
                          <LinkOutlined />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.requirements}>
                    <h2 className={styles.requirementsHeading}>Requirements</h2>
                    <p>
                      {(cardData?.data as RequirementsCard).requirement.summary}
                    </p>
                  </div>
                  <div className={styles.captions}>
                    <h2 className={styles.requirementsHeading}>Captions</h2>
                    <p>
                      {(cardData?.data as RequirementsCard).requirement.caption}
                    </p>
                  </div>
                  <div className={styles.mentions}>
                    <h2 className={styles.requirementsHeading}>Mentions</h2>
                    {(
                      cardData?.data as RequirementsCard
                    ).requirement.mentions.map((men) => (
                      <Tag
                        key={men}
                        style={{ borderRadius: "14px", padding: "4px 8px" }}
                        color="#F0EFF8">
                        <span style={{ color: "#4065D4" }}>{men}</span>
                      </Tag>
                    ))}
                  </div>
                  {(cardData?.data as RequirementsCard).requirement.references
                    .length !== 0 && (
                    <div className={styles.references}>
                      <h2 className={styles.requirementsHeading}>
                        Refereneces
                      </h2>
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          flexWrap: "wrap",
                        }}
                        className="">
                        {(
                          cardData?.data as RequirementsCard
                        ).requirement.references.map((ref) => (
                          <>
                            {ref.type.toLowerCase() === "image" ? (
                              <img
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  objectFit: "cover",
                                }}
                                src={ref.url}
                                alt=""
                              />
                            ) : (
                              <video
                                muted={true}
                                src={ref.url}
                                controls={true}
                                style={{ objectFit: "cover" }}
                                width={"90px"}
                                height={"90px"}
                              />
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className={styles.lastDate}>{item.cardMessage.blue}</div>
                </div>
                {(cardData?.data as RequirementsCard).isEdited ? (
                  <div className={styles.cardButton}>
                    <Button
                      onClick={() => setViewRequirement(true)}
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Hide <DownOutlined />
                    </Button>
                  </div>
                ) : (
                  <div className={styles.cardButton}>
                    <Button
                      onClick={() => {
                        setIsEdit(true);
                        setVisible(true);
                      }}
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Edit
                    </Button>
                  </div>
                )}
              </>
            )}
          </Card>
          <ReqModalTimeline
            isVisible={visible}
            handleCancel={() => setVisible(false)}
            handleSubmit={() => setVisible(false)}
            id={item.ref?.campaignId as string}
            deliverableId={item.ref?.deliverableId as string}
            requirementId={item.ref?.requirementId as string}
            isView={!isEdit}
            workflowId={item.ref?.workflowId as string}
          />
        </div>
      )}
    </>
  );
};

export default RequirementSentCard;
