import React, { useState, useEffect } from "react";
import { Col, message, Row } from "antd";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import CreatorCard from "../SharedComponents/CreatorCard";
import ReportModal from "../../ReportModal";
import Search from "../SharedComponents/Search";
import DeliverableSelect from "../SharedComponents/DeliverableSelect";

import { getDefaultLottieOptions } from "../../../../../utils";
import socialMediaUrlGen from "../../../../../utils/socialMediaUrlGen";
import animationData from "../../../../assets/lottie/Search.json";
import dotAnimationData from "../../../../assets/lottie/threeDotLoading.json";

import {
  getMediaInfo,
  addToReport,
  getCampaignAggregate,
  getCampaignReport,
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
} from "../../../../../handler/report";

import styles from "./styles.module.css";

interface AddReportLinkProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const AddReportLink: React.FC<AddReportLinkProps> = ({
  visible,
  setVisible,
  campaignId,
  platform,
}) => {
  const queryClient = useQueryClient();
  const [campaignReportKey] = getCampaignAggregate({ campaignId });
  const [campaignKey] = getCampaignReport({ campaignId });

  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });

  const [searchInput, setSearchInput] = useState<string>("");

  const [postSelected, setPostSelected] = useState<{ id: string } | null>(null);

  const isYouTubeVideoLink = () => {
    const regex =
      /^(https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11}))|(https:\/\/www\.youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}))$/;
    return regex.test(searchInput);
  };

  const [mediaKey, mediaFn] = getMediaInfo({
    mediaId: searchInput?.includes("shorts")
      ? searchInput?.split("shorts/").at(-1) || ""
      : searchInput?.split("?v=").at(-1) || "",
    platform,
  });

  const { data: mediaData, isLoading } = useQuery(mediaKey, mediaFn, {
    enabled: searchInput.trim().length > 0 && isYouTubeVideoLink(),
  });

  useEffect(() => {
    setPostSelected(null);
  }, [searchInput]);

  useEffect(() => {
    if (
      mediaData?.data?.media?.videoId &&
      mediaData?.data?.isOnCloutflow &&
      mediaData?.data?.connectionStatus === "Connected" &&
      mediaData?.data?.isInfluencerInBrandCommunity
      // mediaData?.data?.isInfluencerInBrandCommunity
    ) {
      setPostSelected({ id: mediaData?.data?.media?.videoId });
    } else {
      setPostSelected(null);
    }
  }, [mediaData?.data]);

  useEffect(() => {
    if (!visible) {
      setSearchInput("");
      setPostSelected(null);
    }
  }, [visible]);

  const addToReportMutation = useMutation(addToReport, {
    onSuccess: () => {
      message.success("Media Added");
      queryClient.invalidateQueries(campaignReportKey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries("reports");
      queryClient.invalidateQueries(campaignKey);
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong while adding Media");
    },
  });

  const navigate = useNavigate();

  return (
    <ReportModal
      visible={visible}
      centered
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={() =>
        addToReportMutation.mutate({
          campaignId,
          socialMediaId: mediaData?.data?.user?.socialMediaId || "",
          socialMediaKitId: mediaData?.data?.user?.socialMediaKitId || "",
          deliverables: [
            { mediaType: "video", deliverableMediaId: postSelected?.id || "" },
          ],
        })
      }
      okText={`Add Media`}
      okButtonProps={{
        disabled: !postSelected || searchInput === "",
        loading: addToReportMutation.isLoading,
      }}
      title="Add report ">
      <Search searchQuery={searchInput} setSearchQuery={setSearchInput} />
      {!isYouTubeVideoLink() && (
        <p className={styles.error}>Please enter correct Youtube Link</p>
      )}
      <div className={styles.g20} style={{ marginTop: 20 }}>
        {searchInput.trim() === "" ? (
          <Col
            span={24}
            offset={0}
            style={{
              textAlign: "left",
            }}>
            <Lottie
              style={{
                margin: "20px 0",
              }}
              options={getDefaultLottieOptions(animationData)}
              height={136}
              width={200}
            />
          </Col>
        ) : (
          <>
            {isLoading ? (
              <Row justify="center">
                <Col
                  span={24}
                  offset={0}
                  style={{
                    textAlign: "left",
                  }}>
                  <Lottie
                    style={{
                      margin: "20px 0",
                    }}
                    options={getDefaultLottieOptions(dotAnimationData)}
                    height={136}
                    width={200}
                  />
                </Col>
              </Row>
            ) : (
              <>
                {isYouTubeVideoLink() && mediaData && (
                  <>
                    {mediaData?.data?.isLinkBroken ? (
                      <p className={styles.primaryText}>
                        The link appears to be broken, cannot find any media for
                        this link
                      </p>
                    ) : !mediaData?.data?.isOnCloutflow ? (
                      <>
                        <p className={styles.primaryText}>
                          The media belongs to{" "}
                          <span
                            className={styles.link}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              window.open(
                                socialMediaUrlGen({
                                  userNameOrChannelId:
                                    mediaData?.data?.user?.username || "",
                                  platform: "youtube",
                                })
                              )
                            }>
                            @{mediaData?.data?.user?.username}
                          </span>{" "}
                          and this profile is not connected with Cloutflow.
                        </p>
                        <p className={styles.primaryText}>
                          You need to{" "}
                          <span
                            className={styles.link}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/invite")}>
                            invite @{mediaData?.data?.user?.username}
                          </span>{" "}
                          to your network to add their media to reports
                        </p>
                      </>
                    ) : mediaData?.data?.connectionStatus &&
                      mediaData?.data?.connectionStatus !== "Connected" ? (
                      <p className={styles.primaryText}>
                        The media belongs to{" "}
                        <span
                          className={styles.link}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              socialMediaUrlGen({
                                userNameOrChannelId:
                                  mediaData?.data?.user?.username || "",
                                platform: "youtube",
                              })
                            )
                          }>
                          @{mediaData?.data?.user?.username}
                        </span>{" "}
                        but we are unable to fetch their data, Ask them To
                        Reconnect Their Account
                      </p>
                    ) : (
                      <>
                        <CreatorCard
                          isSelected={false}
                          campaignId={campaignId}
                          dummyCreator={{
                            followers:
                              mediaData?.data?.user?.subscribersCount || 0,
                            influencerId:
                              mediaData?.data?.user?.influencerId || "",
                            name: mediaData?.data?.user?.title || "",
                            profilePic:
                              mediaData?.data?.user?.profilePicUrl || "",
                            username:
                              "@" + mediaData?.data?.user?.username || "",
                            platform: "youtube",
                            connectionStatus:
                              mediaData?.data?.connectionStatus || "",
                          }}
                        />

                        <DeliverableSelect
                          isLabelClickEnabled={false}
                          isSelected={false}
                          id={mediaData?.data?.media?.thumbnailUrl}
                          caption={
                            platform === "youtube"
                              ? mediaData?.data?.media?.title
                              : undefined
                          }
                          profilePic={mediaData?.data?.user?.profilePicUrl}
                          label={" Youtube Video"}
                          url={mediaData?.data?.media?.thumbnailUrl || ""}
                          type={"yt-video"}
                          handleClick={() => {}}
                        />

                        {!mediaData?.data?.isInfluencerInBrandCommunity && (
                          <p className={styles.error}>
                            @{mediaData?.data?.user?.username} is not a part of
                            your community. You need to{" "}
                            <span
                              className={styles.link}
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate("/invite")}>
                              {" "}
                              invite @{mediaData?.data?.user?.username}
                            </span>{" "}
                            to add their medias to Report
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ReportModal>
  );
};

export default AddReportLink;
