import { Row, Col, Button, message } from "antd";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { updateUsername, checkUserName } from "../../../../handler/invite/";
import { getUser } from "../../../../handler/getUser";

import { validateUsername } from "../../../../utils/validateUsername";

import UniCard from "../UniCard";
import CustomInput from "../../../shared/Custom/Input";

import styles from "../index.module.css";

const GlobalCreate = () => {
  const [state, setState] = useState<{
    value: string;
    touched: boolean;
    error: string;
  }>({
    value: "",
    touched: false,
    error: "",
  });

  // const [touched, setTouched] = useState<boolean>(false);

  const [userKey] = getUser();

  const queryClient = useQueryClient();

  const updateUserNameMutation = useMutation(updateUsername, {
    onSuccess: (response) => {
      if (response.status === "SUCCESS") {
        message.success("Username updated successfully");
        queryClient.invalidateQueries(userKey);
      }
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const checkMutation = useMutation(checkUserName, {
    onSuccess: (response) => {
      if (response.data?.isSlugAvailable) {
        updateUserNameMutation.mutate({ username: state.value.trim() });
      } else {
        setState((state) => ({
          ...state,
          error: "This Slug is already taken",
        }));
      }
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <React.Fragment>
      <section>
        <Row style={{ minHeight: "560px" }}>
          <Col span={10}>
            {/* <Col span={20}>
              {" "}
              <UniCard slug={""} isEdit={false} />
            </Col> */}
            <div
              style={{
                width: 420,
                marginTop: 0,
                padding: 20,
                paddingTop: 0,
              }}>
              <h2 className={styles.heading}>🔗 Craft Your Global Uni-Link</h2>
              <h6 className={styles.subHeading}>
                Create Your Unique Invitation Link
              </h6>
              <p className={styles.text}>
                It’s simple, just enter a unique name for your page. You can
                change this later.
              </p>
              <span className={styles.link}>
                connect.cloutflow.com/brand/{state?.value}
              </span>

              <div style={{ marginTop: 16 }}>
                <CustomInput
                  placeholder="Enter Name"
                  containerStyles={{ width: "342px", gap: 12 }}
                  value={state.value}
                  // onClick={() =>
                  //   setState((prev) => ({ ...prev, touched: true }))
                  // }
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, value: e.target.value }));
                  }}
                  error={
                    state?.error
                      ? state.error
                      : state.touched
                      ? state.value.trim() === ""
                        ? "Slug can't be empty"
                        : validateUsername(state.value.trim())[0]
                      : undefined
                  }
                />
                <div className={styles.buttonContainer}>
                  <Button
                    style={{
                      borderRadius: "10px",
                      padding: "10px 26px",
                      width: "auto",
                      height: "46px",
                      fontSize: "16px",
                    }}
                    type="primary"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        touched: true,
                      }));
                      checkMutation.mutate({
                        username: state.value.trim(),
                      });
                    }}
                    disabled={
                      state.value.trim().length === 0 ||
                      validateUsername(state.value.trim())[1]
                    }
                    loading={
                      checkMutation.isLoading ||
                      updateUserNameMutation.isLoading
                    }>
                    Create a Network Page
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12} offset={2}>
            <div
              style={{
                borderRadius: 15,
                border: "1px solid rgba(255, 255, 255, 0.05)",
                background: "rgba(83, 58, 245, 0.12)",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                backdropFilter: "blur(20px)",
                width: "100%",
                height: "100%",
                padding: "74px 30px",
              }}>
              <img
                src={"/assets/img/Frame 4219.png"}
                width={"100%"}
                height={"auto"}
                alt="infographics"
              />
            </div>
          </Col>
        </Row>
      </section>
    </React.Fragment>
  );
};

export default GlobalCreate;
