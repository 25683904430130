import { sendRequest } from "../helper";

type getBillProps = {
  campaignId: string;
  fromDate: string;
  toDate: string;
  text: string;
  paymentType: "Payment" | "Refund" | "";
};

type getBillResult = {
  status: string;
  message: string;
  data: {
    count: number;
  };
};

export const getBillCount = (billProps: getBillProps) => {
  const queryKey = ["bill-count", billProps];
  const queryFunction = () =>
    sendRequest<getBillResult>("put", `/brand/transaction/offer-count`, false, {
      campaignId: billProps.campaignId,
      fromDate: billProps.fromDate,
      toDate: billProps.toDate,
      text: billProps.text,
      paymentType: billProps.paymentType,
    }).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
