import React, { useState } from "react";
import { Row, Col, Layout, Menu } from "antd";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Lottie from "react-lottie";

import { BsPersonSquare } from "react-icons/bs";
import { HiOutlineSquare2Stack } from "react-icons/hi2";
import { FiPieChart } from "react-icons/fi";

import animationData from "../../assets/lottie/Rocket.json";

import { ReportOnlyContextProvider } from "./Context/ReportOnlyContext";
import { getCampaignReport } from "../../../handler/report";

import Navbar from "../../shared/Navbar";
import InfluencerScreen from "./InfluencerScreen";
import ReportScreen from "./ReportScreen";
import IndividualReportScreen from "./IndividualReportScreen";
import ContentScreen from "./ContentScreen";

import { getDefaultLottieOptions } from "../../../utils";

const { Sider, Content } = Layout;

const Report = () => {
  const { campaignId, socialMediaId } = useParams();
  const [getCampaignKey, getCmapaignFn] = getCampaignReport({
    campaignId: campaignId || "",
  });

  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useQuery(getCampaignKey, getCmapaignFn);

  const handleChangeScreen = (type: string) => {
    if (socialMediaId) {
      return navigate(`/campaign/manage/report/${campaignId}?type=${type}`, {
        replace: true,
      });
    }
    if (type !== searchParams.get("type")) {
      return setSearchParams({ type });
    }
  };

  return (
    <div>
      <Navbar />
      <Row style={{ height: "90vh" }}>
        {isLoading ? (
          <Row
            justify="center"
            style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
            <Lottie
              options={getDefaultLottieOptions(animationData)}
              height={300}
              width={370}
            />
          </Row>
        ) : (
          <>
            <Layout style={{ background: "#ffffff" }}>
              <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                onMouseEnter={() => setCollapsed(false)}
                onMouseLeave={() => setCollapsed(true)}
                trigger={null}>
                <Menu
                  theme="light"
                  // mode="inline"
                  _internalRenderMenuItem={(
                    node,
                    menuItemProps,
                    stateProps
                  ) => {
                    return React.cloneElement(node, {
                      style: {
                        ...node.props.style,
                        backgroundColor: stateProps?.selected
                          ? "#533AF51A"
                          : "",
                        borderRadius: "8px",
                        padding: collapsed ? undefined : "0 10px ",
                        height: "fit-content",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: collapsed ? undefined : "10px",
                      },
                    });
                  }}
                  selectedKeys={[
                    searchParams.get("type") !== "report" &&
                    searchParams.get("type") !== "content-box"
                      ? "1"
                      : searchParams.get("type") === "report"
                      ? "2"
                      : "3",
                  ]}
                  style={{
                    height: "100%",
                    borderRight: "1px solid rgba(0, 0, 0, 0.10)",
                    background: "#ECEEF3",
                    padding: "20px",
                  }}
                  items={[
                    {
                      key: "1",
                      icon: <BsPersonSquare size={collapsed ? 20 : 20} />,
                      label: `Influencers `,
                      onClick: () => handleChangeScreen("influencer"),
                    },
                    {
                      key: "2",
                      icon: <FiPieChart size={collapsed ? 20 : 20} />,
                      label: `Reports `,
                      onClick: () => handleChangeScreen("report"),
                    },
                    {
                      key: "3",
                      icon: (
                        <HiOutlineSquare2Stack size={collapsed ? 20 : 20} />
                      ),
                      label: `Content `,
                      onClick: () => handleChangeScreen("content-box"),
                    },
                  ]}
                />
              </Sider>

              <Content
                style={{
                  height: "85vh",
                  overflowY: "scroll",
                  margin: "40px 0",
                }}>
                <Col span={20} offset={2}>
                  {campaignId && socialMediaId ? (
                    <IndividualReportScreen
                      campaignId={campaignId}
                      socialMediaId={socialMediaId}
                      platform={data?.data?.platform || "instagram"}
                    />
                  ) : (
                    <>
                      {searchParams.get("type") !== "report" &&
                      searchParams.get("type") !== "content-box" ? (
                        <InfluencerScreen
                          platform={data?.data?.platform || "instagram"}
                          campaignId={campaignId || ""}
                        />
                      ) : searchParams.get("type") !== "content-box" ? (
                        <ReportOnlyContextProvider
                          platform={data?.data?.platform || "instagram"}
                          campaignId={campaignId || ""}>
                          <ReportScreen />
                        </ReportOnlyContextProvider>
                      ) : (
                        <ReportOnlyContextProvider
                          platform={data?.data?.platform || "instagram"}
                          campaignId={campaignId || ""}>
                          <ContentScreen
                            platform={data?.data?.platform || "instagram"}
                            campaignId={campaignId || ""}
                          />
                        </ReportOnlyContextProvider>
                      )}
                    </>
                  )}
                </Col>
              </Content>
            </Layout>
          </>
        )}
      </Row>
    </div>
  );
};

export default Report;
