import React from "react";
import clsx from "clsx";

import { DropDownProps, Dropdown, Menu } from "antd";
import type { MenuProps } from "antd";

import styles from "./styles.module.css";

export interface CustomDropDownProps extends Partial<DropDownProps> {
  items: {
    label: React.ReactElement | string;
    onClick?: MenuProps["onClick"];
    key: string;
    disabled?: boolean;
  }[];
  menuClassName?: string;
  menuItemClassName?: string;
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({
  items,
  menuClassName,
  menuItemClassName,
  ...props
}) => {
  const menu = (
    <Menu className={clsx(styles.dropDownContainer, menuClassName)}>
      {items?.map((item) => {
        return (
          <Menu.Item
            disabled={item.disabled || false}
            key={item.key}
            className={clsx(
              {
                [styles.item]: !(item.disabled || false),
              },
              menuItemClassName
            )}
            onClick={item.onClick}
            style={item.disabled ? { cursor: "default" } : {}}
          >
            {item.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown {...props} overlay={menu}>
      {props.children}
    </Dropdown>
  );
};

export default CustomDropDown;
