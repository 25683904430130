import React, { useState, useEffect } from "react";
import { Modal, Input, Upload, message, Switch } from "antd";

import { Radio } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import type { RadioChangeEvent } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { AxiosError } from "axios";
import cuid from "cuid";

import { Requirement } from "../../../../../typings";

import {
  getTimelineRequirements,
  postTimelineReq,
} from "../../../../../handler/campaign/applicants/";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import Chip from "../ReqModal/Chip";
import TextEditor from "../../../../shared/TextEditor";

import styles from "../ReqModal/reqModal.module.css";

import { CardType } from "../../../../Chat/ChatWindow/Message/CardTypes";

interface ReqModalTimelineProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  deliverableId: string;
  requirementId?: string;
  workflowId: string;
  isView: boolean;
  id: string;
}

const ReqModalTimeline: React.FC<ReqModalTimelineProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  requirementId,
  deliverableId,
  workflowId,
  isView,
  id,
}) => {
  const queryClient = useQueryClient();
  const initialReqState: Requirement = {
    summary: "",
    mentions: [],
    caption: "",
    references: [],
    _id: "new-item",
    name: "",
    isDraftNeeded: true,
  };

  const [getTimelineRequirementsKey, getTimelineRequirementsFn] =
    getTimelineRequirements({ deliverableId });

  const { data: timelineRequirementsData } = useQuery(
    getTimelineRequirementsKey,
    getTimelineRequirementsFn
  );

  const postReqMutation = useMutation(postTimelineReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries([
        "chat",
        CardType.deliverableRequirementSentType,
      ]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;
      const storageRef = ref(
        storage,
        `/campaign/${id}/requirements/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          onSuccess && onSuccess(downloadURL);
          setFormInput((formInput) => {
            return {
              ...formInput,
              refs: [
                ...formInput.refs,
                {
                  uid: downloadURL,
                  name: fileName,
                  status: "done",
                  url: downloadURL,
                },
              ],
            };
          });
        }
      );
    },
    accept: "image/png, image/jpeg, video/mp4 ",
    onRemove(file) {
      setFormInput((formInput) => {
        return {
          ...formInput,
          refs: formInput.refs.filter(
            (reference) => reference.uid !== file.uid
          ),
        };
      });
    },
  };

  const [mentionInput, setMentionInput] = useState<string>("");
  const [reqSelected, setReqSelected] = useState<Requirement>(initialReqState);
  const [formInput, setFormInput] = useState<{
    req: string;
    caption: string;
    mentions: string[];
    refs: UploadFile[];
    draftReq: boolean;
  }>({
    req: "",
    caption: "",
    mentions: [],
    refs: [],
    draftReq: true,
  });
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    setFormInput({
      req: reqSelected.summary,
      caption: reqSelected.caption,
      mentions: reqSelected.mentions,
      refs: reqSelected.references.map((imgItem) => {
        return {
          uid: imgItem.url,
          name: imgItem.type,
          status: "done",
          url: imgItem.url,
        };
      }),
      draftReq: reqSelected.isDraftNeeded,
    });
  }, [reqSelected]);

  useEffect(() => {
    if (timelineRequirementsData && isView) {
      const selectedReq = timelineRequirementsData.data.requirements.filter(
        (item) => item._id === requirementId
      )[0];

      setReqSelected(selectedReq);
    }
  }, [timelineRequirementsData]);

  const onRadioChange = (e: RadioChangeEvent) => {
    if (e.target.value === "new-item") {
      return setReqSelected({
        name: `Requirement ${
          timelineRequirementsData &&
          timelineRequirementsData.data.requirements.length + 1
        }`,
        summary: "",
        mentions: [],
        caption: "",
        references: [],
        _id: "new-item",
        isDraftNeeded: true,
      });
    }
    if (timelineRequirementsData) {
      const selectedReq = timelineRequirementsData.data.requirements.filter(
        (item) => item._id === e.target.value
      )[0];
      return setReqSelected(selectedReq);
    }
  };

  const onChipClose = (id: string) => {
    setFormInput((formInput) => {
      return {
        ...formInput,
        mentions: formInput.mentions.filter((item) => item !== id),
      };
    });
  };

  const handleFormSubmit = () => {
    if (isView) {
      return handleSubmit();
    }
    postReqMutation.mutate({
      workflowId: workflowId,
      deliverableId: deliverableId,
      requirementId:
        reqSelected._id === "new-item" ? undefined : reqSelected._id,
      name:
        reqSelected.name.trim() === ""
          ? `Requirement-${timelineRequirementsData?.data.requirements.length}`
          : reqSelected.name,
      summary: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      mentions: formInput.mentions,
      caption: formInput.caption,
      references: formInput.refs.map((item) => {
        return { type: item.type || "", url: item.url || "" };
      }),
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const { TextArea } = Input;

  return (
    <Modal
      title={isView ? "View Requirements" : "Edit Requirements"}
      visible={isVisible}
      onOk={handleFormSubmit}
      onCancel={handleCancel}
      okButtonProps={{
        loading: postReqMutation.isLoading,
      }}
      centered>
      <div style={{ height: "70vh", overflow: "scroll" }}>
        {!isView && (
          <>
            {(timelineRequirementsData?.data.requirements || []).length > 0 && (
              <>
                <div
                  style={{
                    // marginTop: "32px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}>
                  Previously created requirements
                </div>
                <Radio.Group onChange={onRadioChange} value={reqSelected?._id}>
                  <div
                    style={{ display: "grid", gap: "20px", margin: "16px 0" }}>
                    <>
                      {timelineRequirementsData?.data.requirements.map(
                        (item) => {
                          return <Radio value={item._id}>{item.name}</Radio>;
                        }
                      )}
                    </>
                  </div>
                </Radio.Group>
              </>
            )}
          </>
        )}

        <div className={styles.item}>
          <p className={styles.heading}>Deliverable Details</p>
          {timelineRequirementsData !== undefined && (
            <div className={styles.description}>
              <p>
                {/* {timelineRequirementsData?.data.deliverableDetails.duration &&
                  `
                  [${timelineRequirementsData?.data.deliverableDetails.duration}s] 
                  `} */}
                {timelineRequirementsData?.data.deliverableDetails.typeDel}
              </p>
              {/* {timelineRequirementsData?.data.deliverableDetails
                .contentRights !== "Not Needed" &&
                timelineRequirementsData?.data.deliverableDetails
                  .contentRights !== undefined && (
                  <p>
                    [
                    {
                      timelineRequirementsData?.data.deliverableDetails
                        .contentRights
                    }
                    ] Content Rights
                  </p>
                )} */}
            </div>
          )}
        </div>

        {/* <div className={styles.item}>
          <p className={styles.heading}>Brief</p>
          <p className={styles.description}>
            {timelineRequirementsData?.data.deliverableDetails.description}
          </p>
        </div> */}

        <div className={styles.item}>
          <p className={styles.heading}>Add Requirements</p>
          {reqSelected._id === "new-item" ? (
            <TextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              placeholder="Add your requirements here"
            />
          ) : (
            <div
              style={{
                wordBreak: "break-all",
                backgroundColor: " #f5f5f5",
                borderColor: "#d9d9d9",
                border: "1px solid #d9d9d9",
                padding: "10px",
              }}
              dangerouslySetInnerHTML={{
                __html: reqSelected.summary,
              }}
            />
          )}
        </div>

        {/* <div className={styles.item}>
          <p className={styles.heading}>Caption</p>
          <TextArea
            size="middle"
            placeholder="Caption"
            autoSize={{ minRows: 3 }}
            value={formInput.caption}
            onChange={(event) =>
              setFormInput((formInput) => {
                return { ...formInput, caption: event.target.value };
              })
            }
            disabled={reqSelected._id !== "new-item"}
          />
        </div> */}
        {/* <div className={styles.item}>
          <p className={styles.heading}>Mentions</p>
          <Input
            value={mentionInput}
            onChange={(event) => setMentionInput(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setFormInput((formInput) => {
                  return {
                    ...formInput,
                    mentions: [...formInput.mentions, mentionInput],
                  };
                });
                setMentionInput("");
              }
            }}
            disabled={reqSelected._id !== "new-item"}
          />
          <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
            {formInput.mentions.map((item) => {
              return <Chip text={item} onclose={onChipClose} />;
            })}
          </div>
        </div> */}
        {/* <div style={{ width: "100%" }}>
          <div className={styles.draftReq}>
            <p>Creator needs to send draft:</p>
            <span className="">
              <Switch checked={reqSelected?.isDraftNeeded} disabled={true} />
            </span>
          </div>
        </div> */}
        {/* <div className={styles.item}>
          <p className={styles.heading}>Add References (Optional)</p>
          <Upload
            listType="picture-card"
            fileList={formInput.refs}
            {...UploadButtonProps}
            disabled={reqSelected._id !== "new-item"}>
            {formInput.refs.length >= 8 ? null : uploadButton}
          </Upload>
        </div> */}
      </div>
    </Modal>
  );
};

export default ReqModalTimeline;
