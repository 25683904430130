import { Spin, message, Dropdown, Menu } from "antd";
import { useQuery } from "react-query";
import React from "react";
import { format, eachDayOfInterval } from "date-fns";
import styles from "./message.module.css";
import { BsCheckAll } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { useMutation } from "react-query";
import { CgCloseO } from "react-icons/cg";
import Cookies from "universal-cookie";

import { getUserProfile, deleteMessage } from "../../../../handler/chat";
import DraftApprovedCard from "./Cards/DraftApprovedCard";
import RequirementSentCard from "./Cards/RequirementSentCard";
import CampaignStartedCard from "./Cards/CampaignStartedCard";
import InvitationAcceptedCard from "./Cards/InvitationAcceptedCard";
import InvitationSentCard from "./Cards/InvtitationSentCard";
import DraftReceivedCard from "./Cards/DraftReceivedCard";
import { Message as MessageType } from "../../../../typings";
import { useUserAuth } from "../../../../context/userAuthcontext";
import DraftRequestedChangesCard from "./Cards/DraftRequestedChangesCard";
import { CardType } from "./CardTypes";

import CampaignCompletedCard from "./Cards/CampaignCompletedCard";
import LiveReceivedCard from "./Cards/LiveReceivedCard";
import LiveApprovedCard from "./Cards/LiveApprovedCard";
import LiveRequestedChangesCard from "./Cards/LiveRequestedChanges";
import InvitationRejectedCard from "./Cards/InvitationRejectedCard";
import MediaCard from "./Cards/MediaCard";

import { getUser } from "../../../../handler/getUser";
import { useChatContext } from "../../../../context/ChatContext";

import { BiChevronDown } from "react-icons/bi";
import clsx from "clsx";

interface MessageProps {
  messageItem: MessageType;
  influencerId: string;
  previousMessageAuthor: string;
  previousMessageDate: Date;
  conversationId: string;
}

const getDateString = (date: string) => {
  try {
    const resultArrayLength = (
      eachDayOfInterval({
        start: new Date(date),
        end: new Date(),
      }) || []
    ).length;
    if (resultArrayLength === 1) return "Today";
    else if (resultArrayLength === 2) return "Yesterday";
    return format(new Date(date), "EEE, dd/LL/yyyy");
  } catch (error) {
    return "Today";
  }
};

const Message: React.FC<MessageProps> = ({
  messageItem,
  influencerId,
  previousMessageAuthor,
  conversationId,
  previousMessageDate,
}) => {
  const { currentUser } = useUserAuth();
  const { setMessages } = useChatContext();

  const cookies = new Cookies();

  const resultArrayLength = () => {
    try {
      return (
        eachDayOfInterval({
          start: previousMessageDate,
          end: new Date(messageItem.createDateTime),
        }) || []
      ).length;
    } catch (error) {
      return 1;
    }
  };

  const deleteMessageMutation = useMutation(deleteMessage, {
    onSuccess: () => {
      message.success("Message Deleted");
      setMessages((messages) =>
        messages.map((message) =>
          message._id !== messageItem._id
            ? message
            : { ...message, isDeleted: true }
        )
      );
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const [getUserProfileKey, getUserProfileFn] = getUserProfile(influencerId);
  const [getUserkey, getUserFn] = getUser();
  const { data } = useQuery(getUserProfileKey, getUserProfileFn);
  const { data: brandData } = useQuery(getUserkey, getUserFn);

  const cuid = cookies.get("brandId") || currentUser?.uid;

  return (
    <>
      <div
        style={{
          marginTop: `${
            messageItem.author !== previousMessageAuthor ? "16px" : "5px"
          }`,
        }}
        className={
          cuid !== messageItem.author
            ? styles.messageContainerMe
            : styles.messageContainerOther
        }>
        {messageItem.author !== previousMessageAuthor ? (
          <img
            src={
              influencerId === messageItem.author
                ? data?.data.profilePicUrl || "/assets/img/lock.png"
                : brandData?.data?.businessDetails?.logoUrl ||
                  "/assets/img/test-profile.png"
            }
            alt="profile"
            className={styles.profile}
          />
        ) : (
          <div style={{ width: "30px", height: "30px" }} className=""></div>
        )}
        <section style={{ maxWidth: "65%" }}>
          {messageItem.data.type === "string" && (
            <p
              className={clsx(
                cuid === messageItem.author
                  ? styles.messageMe
                  : styles.messageOther,
                messageItem.isDeleted && styles.deletedMessage
              )}>
              {messageItem.isDeleted ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CgCloseO style={{ marginRight: "4px" }} />
                  Message Deleted
                </div>
              ) : (
                <>
                  {messageItem.data.msg}
                  {cuid === messageItem.author && (
                    <Dropdown
                      overlay={
                        <Menu
                          items={[
                            {
                              key: "2",
                              label: (
                                <p
                                  onClick={() =>
                                    deleteMessageMutation.mutate({
                                      messageId: messageItem._id,
                                    })
                                  }>
                                  Delete Message
                                </p>
                              ),
                            },
                          ]}
                          onClick={(event) => {
                            event.domEvent.stopPropagation();
                          }}
                        />
                      }>
                      <BiChevronDown className={styles.iconPosition} />
                    </Dropdown>
                  )}
                  <span
                    className={
                      cuid !== messageItem.author
                        ? styles.bottomRight
                        : styles.bottomLeft
                    }>
                    <span
                      className={
                        cuid !== messageItem.author
                          ? styles.time
                          : styles.timeWhite
                      }>
                      {format(new Date(messageItem.createDateTime), "hh:mm bb")}
                    </span>
                    {cuid === messageItem.author &&
                      (messageItem.sending ? (
                        <BiTime size={16} style={{ color: "white" }} />
                      ) : (
                        <BsCheckAll style={{ color: "white" }} size={16} />
                      ))}
                  </span>
                </>
              )}
            </p>
          )}
          {messageItem.status && messageItem.status === "loading" && <Spin />}
          {messageItem.data.type === CardType.invitationSentType && (
            <InvitationSentCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.invitationRejectedType && (
            <InvitationRejectedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                cardMessage: messageItem.data.cardMessage,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.invitationAcceptedType && (
            <InvitationAcceptedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.campaignStartedType && (
            <CampaignStartedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
              conversationId={conversationId}
            />
          )}
          {(messageItem.data.type === CardType.deliverableRequirementSentType ||
            messageItem.data.type ===
              CardType.deliverableRequirementEditType) && (
            <RequirementSentCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
                cardMessage: messageItem.data.cardMessage,
              }}
            />
          )}
          {messageItem.data.type === CardType.deliverableDraftSentType && (
            <DraftReceivedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type ===
            CardType.deliverableDraftRequestedChangesType && (
            <DraftRequestedChangesCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                cardMessage: messageItem.data.cardMessage,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.deliverableDraftApprovedType && (
            <DraftApprovedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                cardMessage: messageItem.data.cardMessage,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.deliverableLiveSentType && (
            <LiveReceivedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                ref: messageItem.data.ref,
                messageId: messageItem._id,
                type: messageItem.data.type,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.deliverableLiveApprovedType && (
            <LiveApprovedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                cardMessage: messageItem.data.cardMessage,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type ===
            CardType.deliverableLiveRequestedChangesType && (
            <LiveRequestedChangesCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                cardMessage: messageItem.data.cardMessage,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.campaignCompletedType && (
            <CampaignCompletedCard
              item={{
                cardName:
                  cuid === messageItem.author
                    ? messageItem.data.cardName?.me
                    : messageItem.data.cardName?.other,
                cardTitle: messageItem.data.cardTitle,
                cardMessage: messageItem.data.cardMessage,
                cardTimestamp: messageItem.data.cardTimestamp,
              }}
            />
          )}
          {messageItem.data.type === CardType.mediaType && (
            <MediaCard
              item={{
                mediaName: messageItem.data.mediaName as string,
                mediaUrl: messageItem.data.mediaUrl as string,
                mediaType: messageItem.data.mediaType as string,
                cardTimestamp: messageItem.data.cardTimestamp,
                author: messageItem.author,
                time: messageItem.createDateTime,
                msg: messageItem?.data?.msg,
                sending: messageItem?.sending,
                isDeleted: messageItem.isDeleted,
              }}
              deleteMessage={() => {
                deleteMessageMutation.mutate({ messageId: messageItem._id });
              }}
            />
          )}
        </section>
      </div>
      {resultArrayLength() > 1 && (
        <p className={styles.dateContainer}>
          {getDateString(messageItem?.createDateTime)}
          {/* {format(new Date(messageItem?.createDateTime), "EEE, dd/LL/yyyy")} */}
        </p>
      )}
    </>
  );
};

export default Message;
