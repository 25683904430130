import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

interface addPromDetailsProps {
  campaignId: string;
  promotionDescription: string;
}

export const addPromDetails = ({
  campaignId,
  promotionDescription,
}: addPromDetailsProps) => {
  //   const queryKey = ["campaign", "create"];
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/create-campaign-form/promotion/${campaignId}`,
    false,
    {
      promotionDescription,
    }
  ).then((res) => res.data);
  //   return [queryKey, queryFn];
};
