import React from "react";
import { Progress, Typography } from "antd";
import { formatData } from "../../../../utils";

const { Text } = Typography;

interface Props {
  items: { name: string; value: number }[];
}
const StatsItem: React.FC<Props> = ({ items }) => {
  return (
    <section style={{ maxHeight: "308px", overflowY: "auto", marginTop: 20 }}>
      {items.map((item, idx) => {
        return (
          <div key={idx}>
            <Text type="secondary" strong>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}>
                <span>{item.name}</span>
                <span>{formatData(item?.value || 0, "0.00")}%</span>
              </div>
            </Text>
            <Progress
              percent={item.value}
              status="active"
              strokeColor={"#2130FF"}
              showInfo={false}
            />
          </div>
        );
      })}
    </section>
  );
};

export default StatsItem;
