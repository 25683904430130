import React, { useState, useEffect } from "react";
import { Drawer, Space, Input, Row, Modal, Col, message } from "antd";
import { HiMagnifyingGlass } from "react-icons/hi2";

import { useReportOnly } from "../../Context/ReportOnlyContext";
import { fieldConfiguration } from "../../../../../typings";

import AggregatedBlock from "./AggregatedBlock";

import styles from "./AggregatedBlock/styles.module.css";

import { MdOutlineDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import CustomInput from "../../../../shared/Custom/Input";
import CustomMetricInput from "../CustomMetricInput";
import { PiWarningCircleFill } from "react-icons/pi";

interface FilterDrawerProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ visible, setVisible }) => {
  const { tabelFullColumns, updateCampaignConfig, isUpdatingConfig } =
    useReportOnly();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [modalView, setModalView] = useState(false);
  const [isExpValid, setisExpValid] = useState<boolean>(true);
  // const [customMetrics, setCustomMetrics] = useState<fieldConfiguration[]>([
  //   {
  //     _id: crypto.randomUUID(),
  //     name: "Average likes per follower",
  //     formula: "1 + 1 * {cpv}",
  //   },
  // ]);
  const [editId, setEditId] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [exp, setExp] = useState("");
  const { confirm } = Modal;

  const fieldTypes = new Set(
    tabelFullColumns
      .filter((columItem) => columItem.fieldType)
      .map((item) => item.fieldType)
  );

  const menuItems = tabelFullColumns
    ?.filter((item) => item.fieldType === "postData")
    .map((item, key) => {
      return {
        key: (key + 1).toString(),
        label: item.title as string,
        variable: item.key as string,
      };
    });

  // console.log(tabelFullColumns);

  const handleCancel = () => {
    setModalView(false);
    setName("");
    setExp("");
  };

  const onSave = (values: { name: string; formula: string }) => {
    if (
      !values.name ||
      values.name.trim().length === 0 ||
      !values.formula ||
      values.formula.trim().length === 0
    ) {
      message.warning("Invalid field values");
      return;
    }

    //     name: "Views"
    // variableName: "views"
    // mappedName: "views"
    // fieldType: "postData"
    // description: "The number of times that the video was viewed."
    const oldFieldConfig = tabelFullColumns
      ?.filter(
        (item) => item.fieldType === "customCalculatedMetrics-Influencer"
      )
      ?.map((item) => ({
        name: item?.key || "",
        variableName:
          (item?.key.split(" ").join("_") || "").toLowerCase() || "",
        mappedName: item?.mappedName,
        description: "",
        fieldType: "customCalculatedMetrics-Influencer" as const,
        _id: item?._id,
      }));

    if (editId) {
      const newFieldConfig = oldFieldConfig?.map((item) => {
        return item._id === editId
          ? {
              name: values?.name || "",
              variableName:
                (
                  values?.name?.split(" ").join("_").split("-").join("_") || ""
                ).toLowerCase() || "",
              mappedName: values?.formula || "",
              description: "",
              fieldType: "customCalculatedMetrics-Influencer" as const,
              _id: editId,
            }
          : item;
      });
      updateCampaignConfig(newFieldConfig, () => {
        setModalView(false);
        setExp("");
        setName("");
        setEditId(null);
      });
    } else {
      const newFieldConfig = [
        ...oldFieldConfig,
        {
          name: values?.name || "",
          variableName:
            (
              values?.name.split(" ").join("_").split("-").join("_") || ""
            ).toLowerCase() || "",
          mappedName: values?.formula || "",
          description: "",
          fieldType: "customCalculatedMetrics-Influencer" as const,
        },
      ];
      updateCampaignConfig(newFieldConfig, () => {
        setModalView(false);
        setExp("");
        setName("");
        setEditId(null);
      });
    }
  };

  const handleOk = () => {
    onSave({ name, formula: exp });
  };

  const handleDelete = (id: string) => {
    const updatedConfig = customMetrics
      ?.filter((item) => item._id !== id)
      ?.map((item) => ({
        name: item?.key || "",
        variableName:
          (item?.key.split(" ").join("_") || "").toLowerCase() || "",
        mappedName: item?.mappedName,
        description: "",
        fieldType: "customCalculatedMetrics-Influencer" as const,
        _id: item?._id,
      }));
    updateCampaignConfig(updatedConfig, () => {});
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      icon: null,
      centered: true,
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
          <PiWarningCircleFill color={"#ffd43b"} size={24} />
          <span>Are you sure delete this metric?</span>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { loading: isUpdatingConfig },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const customMetrics = (tabelFullColumns as any[])?.filter(
    (item) => item.fieldType === "customCalculatedMetrics-Influencer"
  );

  useEffect(() => {
    if (editId) {
      const item = (tabelFullColumns as any[])
        ?.filter(
          (item) => item.fieldType === "customCalculatedMetrics-Influencer"
        )
        .find((x) => x._id === editId);

      if (item) {
        setName(item.key);
        setExp(item.mappedName);
      }
    } else {
      setName("");
      setExp("");
    }
  }, [editId, tabelFullColumns]);

  return (
    <React.Fragment>
      <Drawer
        headerStyle={{ background: "#FFFFFF" }}
        title="Customise Reports"
        placement="right"
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        width={400}
      >
        <div style={{ display: "grid", gap: "40px" }}>
          <section
            style={{
              display: "flex",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "3px",
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            <Space size={2} align="center">
              <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value || "")}
                onPressEnter={() => setSearchQuery(searchQuery)}
                placeholder={"Search by name or username"}
                size="large"
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  width: "100%",
                  minWidth: "300px",
                }}
                allowClear
              />
            </Space>
          </section>
          {Array.from(fieldTypes).map((setItem) => (
            <AggregatedBlock title={setItem} searchQuery={searchQuery} />
          ))}
          <div>
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <span className={styles.title}>Edit Custom Metrics</span>
              <p
                onClick={() => {
                  setEditId(null);
                  setModalView(true);
                }}
                className={styles.seeMoreButton}
                style={{ color: "blue" }}
              >
                Add Metric
              </p>
            </Row>
            <div className={styles.grid}>
              {customMetrics
                .filter((item) =>
                  item.key.toLowerCase().includes(searchQuery.toLowerCase())
                )
                ?.map((entry, key) => (
                  <div key={key} className={styles.metricContainer}>
                    <span>{entry.title}</span>
                    <div className={styles.metricCtaContainer}>
                      <button
                        onClick={() => showDeleteConfirm(entry._id)}
                        className={styles.metricCta}
                      >
                        <MdOutlineDeleteOutline />
                      </button>
                      <button
                        onClick={() => {
                          setEditId(entry._id);
                          setModalView(true);
                        }}
                        className={styles.metricCta}
                      >
                        <MdOutlineModeEdit />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Drawer>
      <Modal
        destroyOnClose
        title="Add a new custom metric"
        centered
        visible={modalView}
        closable={false}
        onCancel={handleCancel}
        okText={"Save"}
        onOk={handleOk}
        okButtonProps={{ loading: isUpdatingConfig, disabled: !isExpValid }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
          <Col span={24}>
            <div>
              <CustomInput
                label="Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <CustomMetricInput
                setExpressionValid={setisExpValid}
                exp={exp}
                setExp={setExp}
                label="Formula*"
                menuItems={menuItems}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default FilterDrawer;
