import React, { useState } from "react";
import { Button, Modal } from "antd";
import { motion } from "framer-motion";
import { BsInfoCircle } from "react-icons/bs";

// import Modals from "../../Onboarding-1/Modals";
import ContactModal from "../../shared/ContactModal";

import styles from "./styles.module.css";

type EmptyScreenProps = {
  kycStatus: "completed" | "started" | "issue" | "pending";
  type: "exec" | "report";
  setCreateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmptyScreen: React.FC<EmptyScreenProps> = (props) => {
  const { type, setCreateModalVisible, kycStatus } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const info = () => {
    Modal.info({
      icon: null,
      width: 576,
      maskClosable: true,
      centered: true,
      title: (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 10,
            }}>
            <BsInfoCircle
              color="#533AF5"
              style={{
                width: 24,
                height: 20,
              }}
            />
            <span>Account Status Pending</span>
          </div>
        </>
      ),
      okButtonProps: {
        type: "primary",
        style: {
          padding: "12px 26px",
          borderRadius: "8px",
          height: "46px",
          border: "1px solid #FFF",
          background: "#533AF5",
        },
      },
      content: (
        <div>
          <p
            style={{
              fontSize: "16px",
              // textAlign: "center",
              opacity: "0.5",
              textTransform: "capitalize",
              margin: "32px auto",
            }}>
            We noticed you have submitted request for activation of your
            account, Our Team will verify the details and will activate your
            account ASAP.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleClick = () => {
    switch (kycStatus) {
      case "completed":
        return setCreateModalVisible(true);
      case "issue":
        return setVisible(true);
      case "started":
        return setVisible(true);
      case "pending":
        return info();

      default:
        break;
    }
  };

  const bg =
    type === "exec"
      ? "linear-gradient(315deg, #533AF5 0.17%, #eb3f79 100%)"
      : "linear-gradient(133deg, #533af5 0.17%, #eb3f79 100%)";

  const initialBg =
    type === "exec"
      ? "linear-gradient(133deg, #533af5 0.17%, #eb3f79 100%)"
      : "linear-gradient(315deg, #533AF5 0.17%, #eb3f79 100%)";

  return (
    <React.Fragment>
      <motion.main
        initial={{ background: initialBg }}
        animate={{ background: bg }}
        transition={{ duration: 0.5 }}
        className={styles.main}>
        <div className={styles.flex}>
          <section className={styles.textSection}>
            <h1>
              {type === "report" ? (
                <>
                  <span>📈 Reporting</span>
                  <br /> <span>Campaign</span>
                </>
              ) : (
                <>
                  <span>⚡Connect</span>
                  <br /> <span>Campaign</span>
                </>
              )}
            </h1>
            <h2>
              {type === "report"
                ? "Generate Reports for self-managed influencer marketing campaings."
                : "Manage your end-to-end influencer marketing campaigns."}
            </h2>
            <p>
              {type === "report"
                ? "In the era of data-driven marketing, report your influencer marketing campaigns. Leveraging first-party, consent-based data that goes way beyond likes,views and comments."
                : "Close deals with influencers faster than ever, no need to waste time on endless calls with influencers. Directly connect with influencers, set deadlines for tasks and manage the overall campaign with connect campaigns."}
            </p>
            <div>
              <Button
                onClick={handleClick}
                className="btn"
                style={{
                  padding: "14px 30px",
                  height: "50px",
                  marginTop: 36,
                }}>
                Create a {type === "report" ? "reporting" : "managed"} campaign
              </Button>
            </div>
          </section>
          <section className={styles.videoSection}>
            <img
              src={
                type === "exec"
                  ? "/assets/img/executionEmpty.png"
                  : "/assets/img/reportEmpty.png"
              }
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="create new campagin graphics by cloutflow"
            />
            {/* <iframe
              style={{
                borderRadius: 10,
              }}
              width="100%"
              height="320"
              src="https://www.youtube.com/embed/uYPbbksJxIg"
              title="Oppenheimer | New Trailer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe> */}
          </section>
        </div>
      </motion.main>
      <ContactModal show={visible} handleClose={() => setVisible(false)} />
    </React.Fragment>
  );
};
export default EmptyScreen;
