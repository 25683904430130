import React, { useState } from "react";
import { useMutation } from "react-query";
import { Row, Col, Space, Pagination, Input, message } from "antd";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

import { useQuery, useQueryClient } from "react-query";

import ReportButton from "../../Report/ReportButton";
import ReportModal from "../../Report/ReportModal";
import Search from "../../Report/Modals/SharedComponents/Search";
import CreatorCard from "./CreatorCard";

import animationData from "../../../assets/lottie/Search.json";
import dotAnimationData from "../../../assets/lottie/threeDotLoading.json";

import { ApplicantUpdated } from "../../../../typings";

import { getDefaultLottieOptions } from "../../../../utils";

import styles from "../../Report/Modals/styles.module.css";

import { getSearchResults } from "../../../../handler/discovery";

import { inviteInfluencerCustomOffer } from "../../../../handler/campaign/offer";
import { getAllApplicants } from "../../../../handler/campaign/applicants";
import { getUser } from "../../../../handler/getUser";

interface Props {
  dealId: string;
  visible: boolean;
  handleClose: () => void;
  platform: "instagram" | "youtube";
  campaignId: string;
}

const InviteInfluencerModal: React.FC<Props> = ({
  visible,
  handleClose,
  dealId,
  platform,
  campaignId,
}) => {
  const [search, setSearch] = useState<string>("");

  const [userDataKey, userDataFn] = getSearchResults({
    platform,
    text: search,
    sortBy: "followers",
  });
  const [getUserkey, getUserFn] = getUser();

  const { data: influencerData, isLoading } = useQuery(
    userDataKey,
    userDataFn,
    {
      enabled: visible && Boolean(search),
    }
  );

  const { data: userData, isLoading: userDataLoading } = useQuery(
    getUserkey,
    getUserFn
  );

  const [creator, setCreator] = useState<ApplicantUpdated | null>(null);
  const [commercial, setCommercial] = useState<number>(0);
  const [brandCommercial, setBrandCommercial] = useState<number>(0);

  const [getAllApplicantsKey, _] = getAllApplicants({
    campaignId: campaignId,
    pageNumber: 1,
    sortOrder: "Desc",
    sortBy: "none",
    status: "Invited",
  });
  const queryClient = useQueryClient();

  const inviteInfluencerMutation = useMutation(inviteInfluencerCustomOffer, {
    onSuccess: () => {
      message.success("Invite Sent");
      queryClient.invalidateQueries(getAllApplicantsKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      console.log(err?.response);
      console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any).message || "");
    },
  });

  return (
    <ReportModal
      visible={visible}
      centered
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <main style={{ display: "grid", gap: "23px" }}>
        {search.trim() === "" && (
          <div style={{ marginBlock: 14 }}>
            <p
              style={{
                color: "rgba(0, 0, 0, 0.60)",
                fontFamily: "Inter",
                fontSize: 22,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Search for the influencer by
              <br /> name or username
            </p>
          </div>
        )}
        <Search searchQuery={search} setSearchQuery={setSearch} />
        <Row gutter={0}>
          {search.trim() === "" ? (
            <>
              <Col
                span={24}
                offset={0}
                style={{
                  textAlign: "left",
                }}
              >
                <Lottie
                  style={{
                    margin: "20px 0",
                  }}
                  options={getDefaultLottieOptions(animationData)}
                  height={136}
                  width={200}
                />
              </Col>
              <Col span={24} offset={0}>
                <Col>
                  <span
                    style={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontFamily: "Manrope",
                      fontSize: 14,
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    or Find and add influencers from{" "}
                    <Link
                      to="/discover"
                      style={{ textDecoration: "underline" }}
                    >
                      discovery
                    </Link>{" "}
                  </span>
                </Col>
              </Col>
            </>
          ) : (
            <>
              {isLoading ? (
                <Col span={12} offset={5}>
                  <Lottie
                    options={getDefaultLottieOptions(dotAnimationData)}
                    height={250}
                    width={300}
                  />
                </Col>
              ) : (
                <>
                  <Col style={{ marginBottom: "20px" }}>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "#000000",
                      }}
                    >
                      Select the influencer to add to campaign
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "#000000",
                      }}
                    >
                      You need to add influencer to campaign to add their
                      reports.
                    </p>
                  </Col>
                  <div
                    style={{
                      display: "grid",
                      gap: "20px",
                      width: "100%",
                      maxHeight: "40vh",
                      overflowY: "auto",
                      height: "fit-content",
                    }}
                  >
                    {influencerData?.data?.result?.map((item, idx) => (
                      <Col span={17}>
                        <CreatorCard
                          creator={item}
                          handleClick={(applicant) => setCreator(applicant)}
                          isSelected={item._id === creator?._id}
                          platform={platform}
                        />
                      </Col>
                    ))}
                  </div>
                  <Col span={23}>
                    {(influencerData?.data?.result || []).length <= 0 && (
                      <Row
                        style={{
                          backgroundColor: "white",
                          padding: "30px 0px",
                        }}
                        justify="center"
                      >
                        <Space direction="vertical" align="center">
                          <img src="/assets/img/empty.png" alt="" />
                          <p style={{ fontWeight: 500, fontSize: "16px" }}>
                            No search results found
                          </p>
                        </Space>
                      </Row>
                    )}
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <div
          style={{
            marginTop: creator ? "20px" : 0,
          }}
        >
          {creator && (
            <div>
              {userData?.data?.accountType === "Managed" && (
                <>
                  <p className={styles.label}>Price for Brand</p>
                  <Input
                    addonBefore="₹"
                    placeholder="Enter Brand Price"
                    type="number"
                    className={styles.input}
                    size="large"
                    value={brandCommercial}
                    onChange={(e) => {
                      if (e.target.value) {
                        return setBrandCommercial(Number(e.target.value) || 0);
                      }
                      setBrandCommercial(0);
                    }}
                  />
                  <div style={{ height: "20px" }} />
                </>
              )}
              <p className={styles.label}>Price for {creator.influencerName}</p>
              <Input
                addonBefore="₹"
                placeholder="Enter Creator Price"
                type="number"
                className={styles.input}
                size="large"
                value={commercial}
                onChange={(e) => {
                  if (e.target.value) {
                    return setCommercial(Number(e.target.value) || 0);
                  }
                  setCommercial(0);
                }}
              />
              {/* <p className={styles.subLabel}>
                This is used to calculate CPV, CPC, etc
              </p> */}
            </div>
          )}
        </div>

        {creator && (
          <Row justify="end" gutter={10}>
            <ReportButton
              type="primary"
              loading={inviteInfluencerMutation.isLoading}
              style={{ padding: "10px 20px" }}
              disabled={creator === null || commercial < 0}
              onClick={() =>
                inviteInfluencerMutation.mutate({
                  brandPrice:
                    userData?.data?.accountType === "Managed"
                      ? brandCommercial
                      : commercial,
                  influencerPrice: commercial,
                  socialMediaKitId: creator._id,
                  campaignId,
                })
              }
            >
              Add {creator.socialMediaName} to Campaign
            </ReportButton>
          </Row>
        )}
      </main>
    </ReportModal>
  );
};

export default InviteInfluencerModal;
