import React, { useState } from "react";
import {
  Modal,
  ModalProps,
  InputNumber,
  Form,
  Input,
  Radio,
  Upload,
  UploadProps,
  Checkbox,
  Row,
  Col,
  Select,
  Switch,
  message,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFileStatus } from "antd/lib/upload/interface";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { InboxOutlined } from "@ant-design/icons";

import styles from "../../../../Create.module.css";

import { InstaVideo, InstaModal, InstaContent } from "../../../Deliverables";

import { Product } from "../../../../../../../typings";
import { useUserAuth } from "../../../../../../../context/userAuthcontext";

import {
  postDeliverable,
  updateDeliverable,
} from "../../../../../../../handler/campaign";
import { CampaignResponse } from "../../../../../../../typings";
import cuid from "cuid";
interface VideoModalProps extends ModalProps {
  initialValues?: InstaVideo;
  type: "Add" | "Edit";
  setDeliverableList: (deliverables: InstaContent[]) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<InstaModal>>;
  setDeliverable: React.Dispatch<React.SetStateAction<InstaContent | null>>;
  productList: Product[] | null;
  campaignId: string;
  hasProduct: boolean;
  modalFor?: "Campaign" | "Deal";
}

const VideoModal: React.FC<VideoModalProps> = ({
  visible,
  initialValues = {
    platform: "instagram",
    typeDel: "Video",
    repost: false,
    duration: "",
    products: [],
    description: "",
    reference: [],
    script: false,
    contentRights: "Not Needed",
    storyShare: false,
    link: "",
    _id: "",
    repostData: {
      content: [],
      instructions: "",
      storyShare: false,
    },
  },
  setDeliverableList,
  setIsVisible,
  type,
  setDeliverable,
  productList,
  campaignId,
  hasProduct,
  modalFor = "Campaign",
}) => {
  const { TextArea } = Input;
  const queryClient = useQueryClient();
  const { Option } = Select;
  // const { Option } = Select;
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [isRepost, setIsRepost] = useState<boolean>(initialValues.repost);
  const [referenceLinks, setReferenceLinks] = useState<string[]>(
    initialValues?.reference || []
  );
  const [contentLinks, setContentLinks] = useState<string[]>(
    initialValues?.repostData?.content || []
  );
  const [showContentRights, setShowContentRights] = useState<boolean>(
    initialValues.contentRights === "Not Needed" ? false : true
  );

  const getDefaultUploadList = () => {
    if (isRepost) {
      return contentLinks.map((item) => {
        return {
          uid: item,
          name: "xxx.png",
          status: "done" as UploadFileStatus, // custom error message to show
          url: item,
          response: item,
        };
      });
    }
    return referenceLinks.map((item) => {
      return {
        uid: item,
        name: "xxx.png",
        status: "done" as UploadFileStatus, // custom error message to show
        url: item,
        response: item,
      };
    });
  };

  const DraggerProps: UploadProps = {
    name: "file",
    multiple: false,

    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;
      const storageRef = ref(
        storage,
        `/campaign/${campaignId}/deliverables/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          // setLoading(false);
          //   setLogo(downloadURL);

          if (isRepost) {
            setContentLinks((value) => {
              if (value.length) {
                return [...value, downloadURL];
              }
              return [downloadURL];
            });
          } else {
            setReferenceLinks((value) => {
              if (value.length) {
                return [...value, downloadURL];
              }
              return [downloadURL];
            });
          }

          onSuccess && onSuccess(downloadURL);
        }
      );
    },
    accept: "image/png, image/jpeg video/mp4",
    onRemove(file) {
      if (!isRepost) {
        setReferenceLinks((references) => {
          if (references) {
            return references.filter(
              (reference) => reference !== file.response
            );
          }
          return [];
        });
      } else {
        setContentLinks((references) => {
          if (references) {
            return references.filter(
              (reference) => reference !== file.response
            );
          }
          return [];
        });
      }
    },
    defaultFileList: getDefaultUploadList(),
  };

  const postDeliverableMutation = useMutation(postDeliverable, {
    onSuccess: (response) => {
      const instaList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "instagram"
      );
      setDeliverableList((instaList as unknown as InstaContent[]) || []);
      setIsVisible((visibleStates) => {
        return { ...visibleStates, Video: false };
      });
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const updateDeliverableMutation = useMutation(updateDeliverable, {
    onSuccess: (response) => {
      const instaList = response.data.deal.deliverables.filter(
        (deliverable) => deliverable!.platform === "instagram"
      );
      setDeliverableList((instaList as unknown as InstaContent[]) || []);
      setIsVisible((visibleStates) => {
        return { ...visibleStates, Video: false };
      });
      form.resetFields();
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get single backend data
          return response;
        }
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const handleAddOk = () => {
    form
      .validateFields()
      .then((values) => {
        let updatedDeliverable: Omit<InstaVideo, "_id"> = {
          typeDel: "Video",
          repost: isRepost,
          duration: values.duration,
          products: values.products,
          description: values.description,
          reference: referenceLinks,
          script: values.script,
          contentRights: values.contentRights,
          storyShare: values.storyShare,
          link: values.link,
          platform: "instagram",
        };
        if (isRepost) {
          updatedDeliverable["repostData"] = {
            content: contentLinks,
            instructions: values.instructions,
            storyShare: values.storyShareRepost,
            link: values.linkRepost,
          };
        }

        if (modalFor === "Campaign") {
          postDeliverableMutation.mutate({
            campaignId,
            deliverable: updatedDeliverable,
          });
        } else {
          // setDeliverableList((deliverableList) => [
          //   ...deliverableList,
          //   { ...updatedDeliverable, _id: Date.now().toString() },
          // ]);
          // setIsVisible((visibleStates) => {
          //   return { ...visibleStates, Video: false };
          // });
        }
        setReferenceLinks([]);
        setContentLinks([]);
        setIsRepost(false);
        form.resetFields();
      })
      .catch((err) => console.log("err", err));
    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleEditOk = () => {
    form
      .validateFields()
      .then((values) => {
        let updatedDeliverable: InstaVideo = {
          typeDel: "Video",
          repost: isRepost,
          duration: values.duration,
          products: values.products,
          description: values.description,
          reference: referenceLinks,
          script: values.script,
          contentRights: values.contentRights,
          storyShare: values.storyShare,
          link: values.link,
          platform: "instagram",
          _id: initialValues._id,
        };
        if (isRepost) {
          updatedDeliverable["repostData"] = {
            content: contentLinks,
            instructions: values.instructions,
            storyShare: values.storyShareRepost,
            link: values.linkRepost,
          };
        }

        if (modalFor === "Campaign") {
          updateDeliverableMutation.mutate({
            campaignId,
            deliverable: updatedDeliverable,
          });
        } else {
          // setDeliverableList((deliverableList) => [
          //   ...deliverableList,
          //   { ...updatedDeliverable, _id: Date.now().toString() },
          // ]);
          setIsVisible((visibleStates) => {
            return { ...visibleStates, Video: false };
          });
        }
        setReferenceLinks([]);
        setContentLinks([]);
        setIsRepost(false);
        form.resetFields();
        setDeliverable(null);
      })
      .catch((err) => console.log("err", err));
    // form.validateFields(["title", "description", "link", "price"]);
    // .then(() => setIsModalVisible(false));
  };

  const handleCancel = () => {
    form.resetFields();
    setIsVisible((prev) => {
      return { ...prev, Video: false };
    });
    setReferenceLinks([]);
    setContentLinks([]);
    setDeliverable(null);
    setIsRepost(false);
  };

  return (
    <Modal
      title="Add a Video"
      visible={visible}
      okText="DONE"
      onCancel={handleCancel}
      centered
      okButtonProps={{
        loading:
          postDeliverableMutation.isLoading ||
          updateDeliverableMutation.isLoading,
      }}
      onOk={type === "Add" ? handleAddOk : handleEditOk}
      bodyStyle={{ maxHeight: "70vh", overflowY: "scroll" }}>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
        // {...formItemLayout}
      >
        <Form.Item
          label="Is it a Repost"
          name="repost"
          initialValue={initialValues.repost}
          className={styles.label}
          rules={[{ required: true, message: "Please Select a type" }]}
          tooltip="This is a required Field">
          <Radio.Group
            className={styles.hintDescription}
            size="middle"
            onChange={(event) => setIsRepost(event.target.value)}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {!isRepost ? (
          <>
            <Form.Item
              label="Duration of Video"
              name="duration"
              initialValue={initialValues.duration}
              className={styles.label}
              rules={[{ required: true, message: "Please Select Duration" }]}
              wrapperCol={{ span: 8 }}>
              <InputNumber
                type="number"
                // style={{ padding: "0px 24px" }}
                size="large"
                addonAfter="minutes"
                min={0}
              />
            </Form.Item>

            {hasProduct && productList && Boolean(productList.length) && (
              <Form.Item
                label="Select Products"
                name="products"
                className={styles.label}
                initialValue={initialValues.products}>
                <Checkbox.Group>
                  <Row gutter={[0, 12]}>
                    {productList.map((product) => {
                      return (
                        <Col span={24}>
                          <Checkbox value={product._id}>
                            <p style={{ fontWeight: 400 }}>
                              {product.productTitle.length > 20
                                ? product.productTitle.substring(0, 19) + "...."
                                : product.productTitle}
                            </p>
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            )}
            <Form.Item
              label={
                <div>
                  <p>Add a brief</p>
                  <p className={styles.hintDescription}>
                    Give a rough idea for the creator about what you need them
                    to create. Detailed requirements will be added later.
                  </p>
                </div>
              }
              className={styles.label}
              name="description"
              initialValue={initialValues.description}
              rules={[
                {
                  required: true,
                  message: "Please input your requirements!",
                },
              ]}>
              <TextArea
                rows={2}
                placeholder="Add a brief"
                showCount={{
                  formatter: (args) => `${args.count}/${args.maxLength}`,
                }}
                maxLength={100}
              />
            </Form.Item>
            {/* 
            <Form.Item
              label={
                <p>
                  Upload some references{" "}
                  <span className={styles.hint}>(Optional)</span>
                </p>
              }
              name="reference"
              className={styles.label}>
              <>
                <Dragger {...DraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </>
            </Form.Item> */}
            {/* 
            <Form.Item
              label="Do you want the creator to share the script?"
              name="script"
              initialValue={initialValues.script}
              className={styles.label}
              rules={[{ required: true, message: "Please Select a type" }]}
              tooltip="This is a required Field">
              <Radio.Group size="small">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item> */}

            <Form.Item label={<p>Do you want content right?</p>}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ width: "80%" }} className={styles.hintDescription}>
                  If you wish to use the content created on your profile or for
                  ads, you need to request creator for content rights.
                </p>
                <p>
                  {" "}
                  <Switch
                    onChange={() => setShowContentRights(!showContentRights)}
                    defaultChecked={showContentRights}
                  />
                </p>
              </div>
            </Form.Item>
            {showContentRights && (
              <Form.Item
                label="Duration of content rights"
                className={styles.label}
                name="contentRights"
                initialValue={
                  initialValues.contentRights === "Not Needed"
                    ? ""
                    : initialValues.contentRights
                }
                tooltip="This is a required Item"
                rules={[
                  {
                    required: true,
                    message: "Please input your requirements!",
                  },
                ]}>
                <Select
                  defaultValue={
                    initialValues.contentRights === "Not Needed"
                      ? ""
                      : initialValues.contentRights
                  }>
                  <Option value="1 month">1 month</Option>
                  <Option value="3 months">3 month</Option>
                  <Option value="6 months">6 month</Option>
                  <Option value="12 months">12 month</Option>
                  <Option value="Lifetime">Lifetime</Option>
                </Select>
              </Form.Item>
            )}

            {/* <Form.Item
              label="Share on story"
              name="storyShare"
              initialValue={initialValues.storyShare}
              className={styles.label}
              rules={[{ required: true, message: "Please Select a type" }]}
              tooltip="This is a required Field">
              <Radio.Group size="small">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Swipe up link (optional)"
              className={styles.label}
              name="link"
              initialValue={initialValues.link}
              tooltip="This is a required Item">
              <Input size="middle" />
            </Form.Item> */}
          </>
        ) : (
          <>
            <Form.Item
              label={<p>Upload the content</p>}
              name="content"
              className={styles.label}>
              <>
                <Dragger {...DraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </>
            </Form.Item>

            <Form.Item
              label={
                <div>
                  <p>
                    Add a brief
                    <span className={styles.hint}> (Optional)</span>
                  </p>
                  <p className={styles.hintDescription}>
                    Give a rough idea for the creator about what you need them
                    to do. Detailed requirements will be added later.
                  </p>
                </div>
              }
              className={styles.label}
              name="instructions"
              initialValue={initialValues.repostData?.instructions}>
              <TextArea
                placeholder="Add a brief"
                rows={7}
                showCount={{
                  formatter: (args) => `${args.count}/${args.maxLength}`,
                }}
                maxLength={100}
              />
            </Form.Item>
            {/* <Form.Item
              label="Share on story"
              name="storyShareRepost"
              initialValue={initialValues.repostData?.storyShare}
              className={styles.label}
              rules={[{ required: true, message: "Please Select a type" }]}
              tooltip="This is a required Field">
              <Radio.Group size="small">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Swipe up link (optional)"
              className={styles.label}
              name="linkRepost"
              initialValue={initialValues.repostData?.link}
              tooltip="This is a required Item">
              <Input size="middle" />
            </Form.Item> */}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default VideoModal;
