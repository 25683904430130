import React, { useState, useRef, useEffect } from "react";
import { Image, Skeleton, Row, Input, Button, message, Upload } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";

import { auth } from "../../../firebase";

import { getUser } from "../../../handler/getUser";
import { getUserUniversal } from "../../../handler/getUserUniversal";
import { editUserDetails } from "../../../handler/editUserDetails";

import ChipInput from "../../shared/ChipInput";
import DisabledPopOver from "../../shared/DisabledPopOver";

import styles from "../profile.module.css";

import { isLinkValid } from "../../../utils";

const AdminHoc = ({
  children,
  isOwner,
}: {
  children: React.ReactNode;
  isOwner: boolean;
}) => {
  if (isOwner) return <React.Fragment>{children}</React.Fragment>;

  return (
    <DisabledPopOver
      isDisabled={true}
      customText="Contact Org Admin to update this">
      {children}
    </DisabledPopOver>
  );
};

const BusinessDetails = () => {
  const [queryKey, queryFn] = getUser();
  const [universalUserKey, _] = getUserUniversal();

  const [loading, setLoading] = useState<boolean>(false);

  const firstRender = useRef(true);

  const queryClient = useQueryClient();

  const { data: userData, isLoading } = useQuery(queryKey, queryFn);

  const [personalInputValues, setPersonalInputValues] = useState<{
    businessName: { value: string; isEditing: boolean };
    businessLogoUrl: { value: string; isEditing: boolean };
    businessWebsiteUrl: { value: string; isEditing: boolean };
  }>({
    businessName: { value: "", isEditing: false },
    businessLogoUrl: { value: "", isEditing: false },
    businessWebsiteUrl: { value: "", isEditing: false },
  });

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/brand/${auth.currentUser?.email}/logo/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setLoading(false);
          onSuccess && onSuccess(downloadURL);
          setPersonalInputValues((inputValues) => ({
            ...inputValues,
            businessLogoUrl: { isEditing: true, value: downloadURL },
          }));
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setPersonalInputValues((inputValues) => ({
        ...inputValues,
        businessLogoUrl: { isEditing: true, value: "" },
      }));
    },
  };

  const putMeMutation = useMutation(editUserDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(universalUserKey);
      queryClient.invalidateQueries(queryKey);
    },
  });

  useEffect(() => {
    if (userData && firstRender.current) {
      setPersonalInputValues({
        businessName: {
          value: userData?.data.businessDetails.name,
          isEditing: false,
        },
        businessLogoUrl: {
          value: userData?.data.businessDetails.logoUrl,
          isEditing: false,
        },
        businessWebsiteUrl: {
          value: userData?.data.businessDetails.websiteUrl,
          isEditing: false,
        },
      });
      firstRender.current = false;
    }
  }, [userData]);

  if (isLoading) {
    return <Skeleton active />;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const isOrgAdmin = userData?.data?._id === auth?.currentUser?.uid;

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.inputLabel}>Brand Logo</p>

        <Row justify="space-between">
          {personalInputValues?.businessLogoUrl?.isEditing ? (
            <div style={{ width: "104px" }}>
              <Upload
                listType="picture-card"
                // style={{ width: "344px !important", height: "149px !important" }}
                fileList={
                  personalInputValues?.businessLogoUrl?.value
                    ? [
                        {
                          uid: personalInputValues?.businessLogoUrl?.value,
                          name: "logo.png",
                          status: "done",
                          url: personalInputValues?.businessLogoUrl?.value,
                          type: "image/jpeg",
                        },
                      ]
                    : []
                }
                {...UploadButtonProps}>
                {personalInputValues?.businessLogoUrl?.value
                  ? null
                  : uploadButton}
              </Upload>
            </div>
          ) : (
            <Image
              fallback="/assets/img/test-profile.png"
              src={userData?.data.businessDetails.logoUrl}
              width={96}
              height={96}
              className={styles.rounded__12}
              style={{ objectFit: "cover" }}
              preview={{ maskClassName: styles.rounded__12 }}
            />
          )}
          {personalInputValues?.businessLogoUrl?.isEditing ? (
            <Button
              // loading={putMeMutation.isLoading}
              onClick={() => {
                if (!personalInputValues?.businessLogoUrl?.value) {
                  return message.error("Please Upload Logo");
                }
                putMeMutation.mutate(
                  {
                    businessLogoUrl: personalInputValues.businessLogoUrl.value,
                  },
                  {
                    onSuccess: () => {
                      setPersonalInputValues((inptValues) => ({
                        ...inptValues,
                        businessLogoUrl: {
                          ...inptValues.businessLogoUrl,
                          isEditing: false,
                        },
                      }));
                    },

                    onError: (err: any) => {
                      message.error(err.response?.data?.message);
                      return;
                    },
                  }
                );
              }}>
              <FaCheck />
            </Button>
          ) : (
            <AdminHoc isOwner={isOrgAdmin}>
              <Button
                disabled={!isOrgAdmin}
                onClick={() =>
                  setPersonalInputValues((inptValues) => ({
                    ...inptValues,
                    businessLogoUrl: {
                      ...inptValues.businessLogoUrl,
                      isEditing: true,
                    },
                  }))
                }>
                <MdModeEdit />
              </Button>
            </AdminHoc>
          )}
        </Row>
      </div>
      <div>
        <p className={styles.inputLabel}>Brand Name</p>
        <Row justify="space-between">
          {personalInputValues?.businessName?.isEditing ? (
            <Input
              value={personalInputValues?.businessName?.value}
              style={{ width: "50%" }}
              onChange={(e) =>
                setPersonalInputValues((inptValues) => ({
                  ...inptValues,
                  businessName: {
                    ...inptValues.businessName,
                    value: e.target.value,
                  },
                }))
              }
            />
          ) : (
            <p className={styles.inputValue}>
              {personalInputValues?.businessName?.value}
            </p>
          )}
          {personalInputValues?.businessName?.isEditing ? (
            <Button
              disabled={!Boolean(personalInputValues.businessName.value.trim())}
              onClick={() => {
                putMeMutation.mutate(
                  { businessName: personalInputValues.businessName.value },
                  {
                    onSuccess: () => {
                      setPersonalInputValues((inptValues) => ({
                        ...inptValues,
                        businessName: {
                          ...inptValues.businessName,
                          isEditing: false,
                        },
                      }));
                    },

                    onError: (err: any) => {
                      message.error(err.response?.data?.message);
                      return;
                    },
                  }
                );
              }}>
              <FaCheck />
            </Button>
          ) : (
            <AdminHoc isOwner={isOrgAdmin}>
              <Button
                disabled={!isOrgAdmin}
                onClick={() =>
                  setPersonalInputValues((inptValues) => ({
                    ...inptValues,
                    businessName: {
                      ...inptValues.businessName,
                      isEditing: true,
                    },
                  }))
                }>
                <MdModeEdit />
              </Button>
            </AdminHoc>
          )}
        </Row>
      </div>
      <div>
        <p className={styles.inputLabel}>Website link</p>

        <Row justify="space-between">
          {personalInputValues?.businessWebsiteUrl?.isEditing ? (
            <Input
              value={personalInputValues?.businessWebsiteUrl?.value}
              style={{ width: "50%" }}
              onChange={(e) =>
                setPersonalInputValues((inptValues) => ({
                  ...inptValues,
                  businessWebsiteUrl: {
                    ...inptValues.businessWebsiteUrl,
                    value: e.target.value,
                  },
                }))
              }
            />
          ) : (
            <a
              className={styles.inputValue}
              href={personalInputValues?.businessWebsiteUrl.value}
              target="_blank"
              rel="noreferrer">
              {personalInputValues?.businessWebsiteUrl.value}
            </a>
          )}
          {personalInputValues?.businessWebsiteUrl?.isEditing ? (
            <Button
              // loading={putMeMutation.isLoading}
              onClick={() => {
                if (
                  !isLinkValid(personalInputValues?.businessWebsiteUrl?.value)
                ) {
                  return message.error("Enter a Valid Link");
                }
                putMeMutation.mutate(
                  {
                    businessWebsiteUrl:
                      personalInputValues.businessWebsiteUrl.value,
                  },
                  {
                    onSuccess: () => {
                      setPersonalInputValues((inptValues) => ({
                        ...inptValues,
                        businessWebsiteUrl: {
                          ...inptValues.businessWebsiteUrl,
                          isEditing: false,
                        },
                      }));
                    },

                    onError: (err: any) => {
                      message.error(err.response?.data?.message);
                      return;
                    },
                  }
                );
              }}>
              <FaCheck />
            </Button>
          ) : (
            <AdminHoc isOwner={isOrgAdmin}>
              <Button
                disabled={!isOrgAdmin}
                onClick={() =>
                  setPersonalInputValues((inptValues) => ({
                    ...inptValues,
                    businessWebsiteUrl: {
                      ...inptValues.businessWebsiteUrl,
                      isEditing: true,
                    },
                  }))
                }>
                <MdModeEdit />
              </Button>
            </AdminHoc>
          )}
        </Row>
      </div>
    </div>
  );
};

export default BusinessDetails;
