import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

type previewCampaignData = {
  campaignId: string;
  campaignObject: CampaignResponse["data"];
};

export const previewCampaign = ({
  campaignId,
  campaignObject,
}: previewCampaignData) => {
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/active/${campaignId}`,
    false,
    { ...campaignObject }
  ).then((res) => {
    return res.data;
  });
};
