import React, { useState } from "react";
import { Modal, Input, Radio, Space, Button } from "antd";

import styles from "../CreatorCard.module.css";

interface RejectionModalProps {
  isVisible: boolean;
  handleSubmit: (description: string) => void;
  handleCancel: () => void;
}
const RejectionModal: React.FC<RejectionModalProps> = ({
  isVisible,
  handleSubmit,
  handleCancel,
}) => {
  const { TextArea } = Input;
  const [otherDesc, setOtherDesc] = useState("");
  const [value, setValue] = useState<string>("");

  const handleFormSubmit = () => {
    if (value === "Other") {
      return handleSubmit(otherDesc);
    }
    return handleSubmit(value);
  };
  return (
    <Modal
      title="Reason for Rejection?"
      visible={isVisible}
      onCancel={handleCancel}
      footer={
        <Button
          disabled={value === "" || (value === "Other" && otherDesc === "")}
          type="primary"
          onClick={handleFormSubmit}>
          Send
        </Button>
      }
      destroyOnClose={true}
      centered>
      <section>
        <div className={styles.rejectionDesc}>
          This will be sent to the Creator.
        </div>
        <Radio.Group
          value={value}
          onChange={(event) => setValue(event.target.value)}>
          <Space direction="vertical">
            <Radio value={"Want to negotiate commercials"}>
              Want to negotiate commercials
            </Radio>
            <Radio value={"Profile doesn’t match requirements"}>
              Profile doesn’t match requirements
            </Radio>
            <Radio value={"Unpleasant experience with the creator"}>
              Unpleasant experience with the creator
            </Radio>
            <Radio value={"Other"}>Other</Radio>
          </Space>
        </Radio.Group>
        <TextArea
          size="middle"
          showCount
          maxLength={100}
          value={otherDesc}
          onChange={(event) => setOtherDesc(event.target.value)}
          rows={2}
          placeholder="Please add a reason for rejection"
          disabled={value !== "Other"}
          style={{ marginTop: "16px" }}
        />
      </section>
    </Modal>
  );
};

export default RejectionModal;
