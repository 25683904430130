import React, { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  message,
  Modal,
  Row,
  Col,
  Input,
  Spin,
  Space,
  Checkbox,
  Select,
  Divider,
} from "antd";
import { AxiosError } from "axios";

import AddMessageModal from "./AddMessage";
import CustomInput from "../../shared/Custom/Input";
import CommentTags from "./CommentTags";

import Rule from "./Rule";

import {
  Catalogue,
  ProductCatalogue,
  MessageReply,
  RuleById,
  SingularRule,
} from "../../../typings";

import {
  createRule,
  getRuleById,
  editRule,
  getRules,
} from "../../../handler/replies";

import { trimData, getTrimmedLength } from "../../../utils";

import globalStyles from "../workflows.module.css";
import ReportButton from "../../Campaign/Report/ReportButton";

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  ruleId?: string;
  isView?: boolean;
  isEdit?: boolean;
}

const AddRule: React.FC<Props> = ({
  isVisible,
  handleClose,
  ruleId = "",
  isEdit,
  isView,
}) => {
  const queryClient = useQueryClient();
  const [addMessageModal, setAddMessageModal] = useState<{
    visible: boolean;
    messageV: MessageReply | undefined;
  }>({ visible: false, messageV: undefined });

  const [rulesKey] = getRules({});

  const [userInput, setUserInput] = useState<RuleById>({
    name: "",
    rules: [],
  });

  const [userInputErr, setUserInputErr] = useState<RuleById>({
    name: "",
    rules: [],
  });

  const resetForm = () => {
    setUserInput({
      name: "",
      rules: [],
    });
    setUserInputErr({
      name: "",
      rules: [],
    });
  };

  const [getRuleKey, getRuleFn] = getRuleById({ id: ruleId });

  const { data: ruleData, isLoading: ruleLoading } = useQuery(
    getRuleKey,
    getRuleFn,
    { enabled: Boolean(ruleId) }
  );

  const firstRender = useRef(true);

  useEffect(() => {
    if (!isVisible) {
      firstRender.current = true;
      return resetForm();
    }
    if (
      isVisible &&
      !ruleLoading &&
      ruleData?.data?._id &&
      firstRender.current
    ) {
      firstRender.current = false;
      return setUserInput(ruleData?.data);
    }
  }, [isVisible, ruleLoading, ruleData?.data?._id]);

  const isDataValid = () => {
    let isError = false;

    let errorObj = userInputErr;

    if (getTrimmedLength(userInput?.name) <= 0) {
      isError = true;
      errorObj = { ...errorObj, name: "Please Enter a Title" };
    } else {
      errorObj = { ...errorObj, name: "" };
    }

    if (userInput?.rules?.length <= 0) {
      isError = true;

      message?.error("Please Select a rule");
    } else {
      let rulesError = false;

      userInput?.rules?.forEach((ruleItem, idx) => {
        const { errObject, isError: isErrorRule } = isRuleValid(ruleItem);
        if (idx < userInputErr?.rules.length) {
          userInputErr.rules[idx] = errObject;
        }

        // updateArrayAtIndex({
        //   index: idx,
        //   initialArray: userInputErr?.rules,
        //   value: errObject,
        // });

        rulesError = rulesError || isErrorRule;
      });

      isError = isError || rulesError;
    }
    setUserInputErr(errorObj);

    return !isError;
  };

  const isRuleValid = (rule: SingularRule) => {
    let errObject: SingularRule = {};
    let isError = false;

    if (
      rule?.toCommentBack &&
      getTrimmedLength(rule?.commentToReplyBack) <= 0
    ) {
      isError = true;
      errObject = { ...errObject, commentToReplyBack: "Please Enter a Reply" };
    } else {
      errObject = { ...errObject, commentToReplyBack: "" };
    }
    if ((rule?.triggeredOnTextContains || [])?.length <= 0) {
      isError = true;
      errObject = {
        ...errObject,
        triggeredOnTextContains: ["Please Select a Keyword"],
      };
    } else {
      errObject = {
        ...errObject,
        triggeredOnTextContains: [],
      };
    }
    if (rule?.attachedType && rule?.attachedProductCatalogue) {
      errObject = {
        ...errObject,
        attachedProductCatalogue: "",
      };
    } else {
      isError = true;
      errObject = {
        ...errObject,
        attachedProductCatalogue: "Please select a message/catalogue",
      };
    }

    return { isError: isError, errObject };
  };

  const createRuleMutation = useMutation(createRule, {
    onSuccess: () => {
      message.success("Rule Created");
      queryClient.invalidateQueries(rulesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      console.log(err?.response);
      message.error((err?.response?.data as any)?.message);
    },
  });

  const editRuleMutation = useMutation(editRule, {
    onSuccess: () => {
      message.success("Rule Edited");
      queryClient.invalidateQueries(rulesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  const updateArrayAtIndex = ({
    index,
    value,
    initialArray = [],
  }: {
    index: number;
    value: SingularRule;
    initialArray?: SingularRule[];
  }) => {
    const array =
      initialArray?.length > 0 ? initialArray : userInput?.rules || [];

    if (index < 0 || index >= array.length) {
      // throw new Error("Index out of bounds");
      return;
    }

    // Create a shallow copy using the spread operator
    const updatedArray = [...array];

    // Update the value at the specified index
    updatedArray[index] = value;

    if (initialArray?.length > 0) {
      return setUserInputErr((uInput) => ({ ...uInput, rules: updatedArray }));
    }

    setUserInput((uInput) => ({ ...uInput, rules: updatedArray }));
  };

  return (
    <Modal
      width={1000}
      title={null}
      footer={null}
      bodyStyle={{
        borderRadius: "8px",
        padding: 28,
        minHeight: "550px",
      }}
      onCancel={() => {
        handleClose();
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}
      destroyOnClose>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={globalStyles.heading}>
          {isView ? "View" : isEdit ? "Edit" : "Add "} Rule
        </p>
      </Row>
      {ruleLoading ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <Col
          span={16}
          style={{
            display: "grid",
            gap: "12px",
            // height: "340px",
            // overflowY: "scroll",
          }}>
          <CustomInput
            label="Title"
            error={userInputErr?.name}
            children={
              <Input
                disabled={isView}
                value={userInput.name}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    name: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />

          <div>
            <Row justify="space-between">
              Rules
              <ReportButton
                disabled={isView}
                onClick={() => {
                  setUserInput((uInput) => ({
                    ...uInput,
                    rules: [...uInput?.rules, {}],
                  }));
                  setUserInputErr((uErr) => ({
                    ...uErr,
                    rules: [...uErr?.rules, {}],
                  }));
                }}>
                Add Rule
              </ReportButton>
            </Row>
            <ol style={{ height: 350, overflowY: "scroll" }}>
              {userInput?.rules?.map((rItem, idx) => (
                <li key={idx} style={{ marginBlock: 10 }}>
                  <Divider style={{ marginBlock: 0, marginBottom: 5 }} />
                  <Row justify="space-between">
                    <Col span={19}>
                      <Rule
                        data={rItem}
                        handleChange={(rule) =>
                          updateArrayAtIndex({ index: idx, value: rule })
                        }
                        errData={userInputErr?.rules?.at(idx)}
                        isDisabled={isView}
                      />
                    </Col>
                    <Col span={4}>
                      <ReportButton
                        danger
                        type="link"
                        disabled={isView}
                        onClick={() => {
                          setUserInput((uInput) => ({
                            ...uInput,
                            rules: uInput.rules?.filter(
                              (_fItem, fIdx) => fIdx !== idx
                            ),
                          }));
                          setUserInputErr((uErr) => ({
                            ...uErr,
                            rules: uErr.rules?.filter(
                              (_fItem, fIdx) => fIdx !== idx
                            ),
                          }));
                        }}>
                        Delete
                      </ReportButton>
                    </Col>
                  </Row>
                </li>
              ))}
            </ol>
          </div>

          <ReportButton
            type="primary"
            style={{ width: "max-content", marginTop: 100 }}
            loading={editRuleMutation.isLoading || createRuleMutation.isLoading}
            onClick={() => {
              console.log("clicked");
              if (isView) {
                return handleClose();
              }
              console.log(isDataValid());
              if (isDataValid()) {
                if (isEdit) {
                  return editRuleMutation.mutate({
                    ruleId: userInput?._id || "",
                    rules: userInput,
                  });
                }
                return createRuleMutation.mutate({
                  data: userInput,
                });
              }

              return message.error("Please Recheck the form");
            }}>
            {isView ? "Close" : "Submit"}
          </ReportButton>
        </Col>
      )}
      <AddMessageModal
        isVisible={addMessageModal.visible}
        handleClose={() =>
          setAddMessageModal({ visible: false, messageV: undefined })
        }
        initialData={addMessageModal.messageV}
        isView={Boolean(addMessageModal.messageV)}
      />
    </Modal>
  );
};

export default AddRule;
