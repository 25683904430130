import { useState, useEffect } from "react";
import Transactions from "./Transactions";
import { Row, Pagination } from "antd";
import { getBill, getBillCount } from "../../handler/Billing";
import { useQuery } from "react-query";

import Navbar from "../shared/Navbar";

import Header from "./Header";
import Invoices from "./Invoices";
import CreditNotes from "./CreditNotes";
import BillingSettings from "./BillingSettings";

export interface SearchQuery {
  campaignId: string;
  fromDate: string;
  toDate: string;
  text: string;
  type: "transactions" | "invoices" | "creditNote" | "billingSettings";
  paymentType: "Payment" | "Refund" | "";
  page: number;
}

const Billing = () => {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    fromDate: "",
    toDate: "",
    campaignId: "",
    text: "",
    type: "transactions",
    page: 1,
    paymentType: "",
  });

  const [getBillKey, getBillFn] = getBill(searchQuery);
  const [getBillCountKey, getBillCountFn] = getBillCount(searchQuery);

  const { data: billData } = useQuery(getBillKey, getBillFn);
  const { data: billCountData } = useQuery(getBillCountKey, getBillCountFn);

  useEffect(() => {
    setSearchQuery((searchQuery) => {
      return { ...searchQuery, page: 1 };
    });
  }, [searchQuery.type]);

  return (
    <main style={{ overflowY: "auto", height: "100vh" }}>
      <Navbar selectedItem="billing" />
      <div style={{ padding: "22px 107px" }}>
        <p
          style={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "38px",
            lineHeight: "46px",
            marginBottom: "28px",
          }}
        >
          Billing
        </p>
        <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {searchQuery.type === "transactions" ? (
          <Transactions
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        ) : searchQuery.type === "invoices" ? (
          <Invoices searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        ) : searchQuery.type === "creditNote" ? (
          <CreditNotes
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        ) : (
          <BillingSettings />
        )}
        {billData?.data.length !== 0 &&
          searchQuery.type !== "billingSettings" && (
            <Row justify="center">
              <Pagination
                total={billCountData?.data.count}
                style={{ margin: "46px auto" }}
                onChange={(page, _) =>
                  setSearchQuery((searchQuery) => {
                    return { ...searchQuery, page };
                  })
                }
                pageSize={10}
                showSizeChanger={false}
                current={searchQuery.page}
              />
            </Row>
          )}
      </div>
    </main>
  );
};

export default Billing;
