import { sendRequest } from "../helper";

interface MediaResponse {
  status: string;
  message: string;
  data: {
    user?: {
      profilePicUrl: string;
      username: string;
      subscribersCount: 64900;
      title: string;
      influencerId: string;
      socialMediaId: string;
      socialMediaKitId: string;
    };
    isOnCloutflow?: boolean;
    connectionStatus?: string;
    media?: {
      title: string;
      videoId: string;
      thumbnailUrl: string;
    };
    isInfluencerInBrandCommunity?: boolean;
    isLinkBroken: boolean;
  };
}

type getMediaInfoProps = {
  platform: string;
  mediaId: string;
};

export const getMediaInfo = ({ platform, mediaId }: getMediaInfoProps) => {
  const queryKey = ["media", "public", platform, mediaId];

  const queryFunction = () =>
    sendRequest<MediaResponse>(
      "get",
      `/brand/report-only/report/media-link-info?platform=${platform}&mediaId=${mediaId}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
