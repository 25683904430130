import React from "react";

import clsx from "clsx";

import styles from "../styles.module.css";
import { capitalizeFirstLetter } from "../../../utils";

interface TagProps {
  status: string;
}

const Tag: React.FC<TagProps> = ({ status }) => {
  return (
    <div
      className={clsx(styles.tagContainer, {
        [styles.success]: status === "Success",
        [styles.error]: status === "Canceled",
        [styles.processing]: status === "Processing",
        [styles.processing]: status === "Requires Action",
        [styles.error]: status === "Failed",
      })}>
      {capitalizeFirstLetter(status)}
    </div>
  );
};

export default Tag;
