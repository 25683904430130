import React, { useState } from "react";

import {
  Card,
  Checkbox,
  Row,
  Col,
  Button,
  Divider,
  Image,
  Space,
  Tooltip,
  Tag,
  message,
  Typography,
} from "antd";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { formatData } from "../../../utils/formatData";

import styles from "./styles.module.css";

import { IoAddCircle } from "react-icons/io5";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { BsFillHeartFill, BsEyeFill } from "react-icons/bs";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { FaComment } from "react-icons/fa";
import { AlgoliaInstagramUser } from "../../../typings";

import { useSaasDiscoveryContext } from "../../SaasDiscovery/context";

import {
  getInfluencerOnboardStatus,
  inviteInfluencer,
} from "../../../handler/saas";

import CreatorProfile from "../../Campaign/Manage/CreatorProfile";

const MetricBlock = ({
  label,
  value,
  span = 8,
}: {
  label: string;
  value: string;
  span?: number;
}) => {
  return (
    <Col span={span}>
      <h2 className={styles.metricValue}>{value}</h2>
      <h4 className={styles.metricLabel}>{label}</h4>
    </Col>
  );
};

const FeedPost = ({
  likes,
  comments,
  url,
  platform,
  thumbnailUrl,
}: {
  likes: string;
  comments: string;
  url: string;
  thumbnailUrl: string;
  platform: "instagram" | "youtube";
}) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  return (
    <Col span={8}>
      <Image
        src={thumbnailUrl}
        width={100}
        height={100}
        style={{ objectFit: "cover" }}
        preview={{
          visible: previewVisible,
          mask: (
            <Space>
              <div className={styles.feetMetric}>
                {platform === "instagram" ? <BsFillHeartFill /> : <BsEyeFill />}{" "}
                {likes}
              </div>
              <div className={styles.feetMetric}>
                <FaComment />
                {""} {comments}
              </div>
            </Space>
          ),
          onVisibleChange(value) {
            if (platform === "instagram") return setPreviewVisible(value);
            if (value) {
              window.open(url, "_blank");
            }
          },
        }}
      />
    </Col>
  );
};

const { Paragraph } = Typography;

interface CreatorCardProps {
  creatorData: AlgoliaInstagramUser;
}

const CreatorOnboardingCard: React.FC<CreatorCardProps> = ({ creatorData }) => {
  const { showMetrics, setSelectedCreatorList, selectedCreatorList, platform } =
    useSaasDiscoveryContext();

  const queryClient = useQueryClient();

  const [getInfluencerOnboardStatusKey, getInfluencerOnboardStatusFn] =
    getInfluencerOnboardStatus({ saasId: creatorData.objectID, platform });

  const { data: onboardStatus } = useQuery(
    getInfluencerOnboardStatusKey,
    getInfluencerOnboardStatusFn
  );

  const [cProfileProps, setCProfileProps] = useState<{
    visible: boolean;
    influencerId: string;
  }>({
    visible: false,
    influencerId: " ",
  });

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Creator Invited Successfully");
      queryClient.invalidateQueries(getInfluencerOnboardStatusKey);
    },
    onError: () => {
      console.log("Something went wrong while Inviting Creator");
    },
  });

  const getTag = (status?: string) => {
    if (!status) return "";

    if (status === "Success") {
      return <Tag color="lime">Ready For Invite</Tag>;
    }
    if (status === "Pending") {
      return <Tag color="orange">Getting Onboarded</Tag>;
    }
    if (status === "Not-Requested") {
      return "";
    }
  };

  return (
    <Card
      className={styles.container}
      style={{
        boxShadow: "0px 0px 2px rgba(33, 48, 255, 0.25)",
        borderRadius: "8px",
      }}
      bordered
      bodyStyle={{ padding: 0 }}>
      <section className={styles.headerSection}>
        <div className={styles.toolbar}>
          <Space size={[8, 8]}>
            {!onboardStatus?.data?.isSocialMediaFound ? (
              <Tooltip title="Add to Network" placement="right">
                <IoAddCircle
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (
                      onboardStatus?.data?.isSocialMediaFound ||
                      onboardStatus?.data?.status === "Pending"
                    )
                      return message.warning(
                        "Creator is already onboarded or is getting onboarded"
                      );
                    return inviteInfluencerMutation.mutate({
                      platform,
                      saasIds: [creatorData.objectID],
                    });
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Open Creator Profile" placement="right">
                <MdOutlineArrowForwardIos
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setCProfileProps({
                      visible: true,
                      influencerId: onboardStatus?.data?.influencerId || "",
                    })
                  }
                />
              </Tooltip>
            )}
            {getTag(onboardStatus?.data?.status)}
          </Space>
          <Checkbox
            checked={selectedCreatorList.includes(creatorData.objectID)}
            disabled={
              onboardStatus?.data?.isSocialMediaFound ||
              onboardStatus?.data?.status === "Pending"
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCreatorList((selectedCreatorList) => [
                  ...selectedCreatorList,
                  creatorData.objectID,
                ]);
              } else {
                setSelectedCreatorList((selectedCreatorList) =>
                  selectedCreatorList.filter(
                    (item) => item !== creatorData.objectID
                  )
                );
              }
            }}
          />
        </div>
        <section className={styles.profile} onClick={() => {}}>
          <img
            src={creatorData?.profilepicurl}
            alt="profile"
            className={styles.profilePhoto}
          />
          <Row gutter={[15, 10]}>
            <MetricBlock
              label={platform === "instagram" ? "Followers" : "Subscribers"}
              value={formatData(creatorData?.followers || 0, "a")}
              span={8}
            />
            <MetricBlock
              label="Eng. Rate"
              value={formatData(creatorData?.engagementRate || 0, "%")}
              span={9}
            />
            <MetricBlock
              label="Avg. Likes"
              value={formatData(creatorData?.avgLikes || 0, "a")}
              span={7}
            />
            {showMetrics.showMoreMetrics && (
              <>
                <MetricBlock
                  label="Avg. Comments"
                  value={formatData(creatorData?.avgComments || 0, "a")}
                  span={8}
                />
                <MetricBlock
                  label="Total Posts"
                  value={formatData(creatorData?.postCount || 0, "a")}
                  span={9}
                />
              </>
            )}
          </Row>
        </section>
        <Button
          type="link"
          onClick={() => {
            window.open(
              platform === "instagram"
                ? `https://www.instagram.com/${creatorData?.username}`
                : `https://www.youtube.com/channel/${creatorData?.username}`,

              "_blank"
            );
          }}
          style={{
            padding: 0,
            width: "fit-content",
            alignItems: "center",
            display: "inline-flex",
            color: "rgba(35,38,59,0.5)",
          }}>
          {" "}
          {platform === "instagram" ? (
            <AiOutlineInstagram
              style={{ marginRight: "4px" }}
              size={"16px"}
              color="rgba(35,38,59,0.5)"
            />
          ) : (
            <AiFillYoutube
              style={{ marginRight: "4px" }}
              size={"16px"}
              color="rgba(35,38,59,0.5)"
            />
          )}
          @
          {creatorData?.full_name?.length > 35
            ? creatorData?.full_name?.slice(0, 35) + "..."
            : creatorData?.full_name}
        </Button>
        {creatorData?.isPhoneNumber && showMetrics.showPhoneNumber && (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <FiPhoneCall />
            <Paragraph
              style={{ margin: "0" }}
              copyable={{ tooltips: ["Copy Phone Number", "Copied"] }}>
              {creatorData?.phoneNumber}
            </Paragraph>
          </div>
        )}
      </section>
      <Divider style={{ margin: "11px 0 20px 0" }} />

      {(creatorData?.feed || []).length > 0 && (
        <Row gutter={[5, 5]} style={{ padding: "8px" }}>
          {creatorData?.feed
            .slice(0, showMetrics.showMorePosts ? 9 : 6)
            .map((item, i) => (
              <FeedPost
                thumbnailUrl={item.url}
                likes={formatData(
                  platform === "instagram" ? item.likes : item.views || 0,
                  "a"
                )}
                comments={formatData(item.comments, "a")}
                key={i}
                platform={platform}
                url={item.url}
              />
            ))}
        </Row>
      )}
      <CreatorProfile
        isVisible={cProfileProps.visible}
        influencerId={cProfileProps.influencerId}
        handleClose={() =>
          setCProfileProps({ visible: false, influencerId: "" })
        }
        key={"cProfile"}
        platformContext={platform}
      />
    </Card>
  );
};

export default CreatorOnboardingCard;
