import { PlusOutlined } from "@ant-design/icons";
import { message, Upload, Input, Button, Modal } from "antd";
import { useState } from "react";

import styles from "./chatInput.module.css";

import cuid from "cuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { useChatContext } from "../../../../context/ChatContext";

const { TextArea } = Input;

const SendMedia = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Modal
      footer={null}
      visible={openModal}
      onCancel={() => setOpenModal(false)}
      destroyOnClose
      title="Send Image / Video"
      centered
      bodyStyle={{ borderRadius: "0 0 8px 8px", padding: 0 }}>
      <SendMediaComponent setOpenModal={setOpenModal} />
    </Modal>
  );
};

export default SendMedia;

const SendMediaComponent = ({
  setOpenModal,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [messageL, setMessageL] = useState<string>("");
  const [mediaName, setMediaName] = useState<string>("");
  const { handleMessageSend } = useChatContext();
  const [type, setType] = useState("image");
  const [isUploading, setIsUploading] = useState(false);

  const handleSendMessage = () => {
    let val = "media";
    if (messageL) {
      val = messageL;
    }
    handleMessageSend(val, "media", imageUrl, type, mediaName);
    setOpenModal(false);
    setMessageL("");
    setImageUrl("");
    setIsUploaded(false);
    setMediaName("");
  };

  const props = {
    name: "file",
    customRequest: (options: any) => {
      const { onSuccess, file, onError } = options;
      setIsUploading(true);
      const storage = getStorage();
      const fileName = file.name;
      const fileType = file.type.split("/")[0];

      const storageRef = ref(
        storage,
        `/drafts/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          setIsUploading(false);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setMediaName(fileName);
          setImageUrl(downloadURL);
          setType(fileType);
          setIsUploading(false);
        }
      );
    },

    onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setIsUploaded(true);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <>
      <div
        style={{
          margin: "30px auto",
          // marginBottom: "60px",
          width: "20%",
        }}>
        <Upload
          {...props}
          accept="image/*,video/*"
          maxCount={1}
          listType="picture-card"
          onRemove={(e) => setIsUploaded(false)}
          onPreview={(e) => {
            window && window.open(e?.response, "_blank");
          }}>
          {!isUploaded && !isUploading && uploadButton}
        </Upload>
      </div>
      <div className={styles.container}>
        <TextArea
          placeholder="Add a caption"
          style={{ borderRadius: "8px" }}
          value={messageL}
          defaultValue={""}
          onChange={(event) => setMessageL(event.target.value)}
          onBlur={() => null}
          autoSize={{ minRows: 1, maxRows: 2 }}
        />

        <Button
          style={{}}
          type="primary"
          size="middle"
          color="primary"
          onClick={() => handleSendMessage()}
          disabled={!isUploaded}>
          Send
        </Button>
      </div>
    </>
  );
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}>
      Upload
    </div>
  </div>
);
