import { Button, Modal, Row, Col } from "antd";
import React, { useState } from "react";

import styles from "../../Create.module.css";

interface SuccessModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isVisible,
  handleSubmit,
}) => {
  return (
    <Modal
      closable={false}
      footer={null}
      title={null}
      onCancel={handleSubmit}
      visible={isVisible}
      centered
      bodyStyle={{
        padding: "24px 54px",
        background: "#FFFFFF",
        borderRadius: "14px",
      }}>
      <Col>
        <Row justify="center">
          <img
            src="/assets/img/Success.png"
            alt="Success"
            width={"154px"}
            style={{ marginBottom: "25px" }}
          />
        </Row>
        <Row justify="center">
          <p
            style={{ fontSize: "24px", fontWeight: "600" }}
            className={styles.label}>
            Campaign Sent for review
          </p>
        </Row>
        <p className={styles.secondaryLabel} style={{ margin: "30px 0" }}>
          Campaign created successfully, all we have to do is review it before
          making it active. We’ll review your campaign and provide you feedback
          within 1-2 business hours. Keep a look at your email.
        </p>
      </Col>

      <Button
        type="primary"
        block
        // style={{ padding: "11px 16px" }}
        size="large"
        onClick={handleSubmit}>
        GREAT!
      </Button>
    </Modal>
  );
};

export default SuccessModal;
