import React, { useState } from "react";
import { Collapse, Tag, Button, Row, message, Badge } from "antd";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";
import clsx from "clsx";

// import ReviewModals from "./ReviewModals";

import styles from "../tile.module.css";

import {
  getTimelineDetailsV2,
  approveLiveV2,
} from "../../../../../../handler/campaign/applicants";

import LiveChangesModal from "../../../../../Chat/ChatWindow/Message/Cards/Modal/LiveChangesModal";
import AddLive from "./Modal/Add";
import ReportButton from "../../../../Report/ReportButton";

import { CardType } from "../../../../../Chat/ChatWindow/Message/CardTypes";
import { AxiosError } from "axios";

const getDaysLeft = (lastDate: string) => {
  const difference = new Date(lastDate).getTime() - new Date().getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

interface TimeLineProps {
  linksTimeline: {
    name: string;
    type: string;
    createDateTime: string;
    message?: string;
    references: {
      type: string;
      url: string;
      platform: string;
      permalink?: string;
    }[];
    _id: string;
    expectedClosureDateTime?: string;
  }[];
  lastDate: string;
  workflowId: string;
  offerId: string;
  influencerId: string;
}

const TimeLine: React.FC<TimeLineProps> = ({
  linksTimeline,
  lastDate,
  workflowId,
  offerId,
  influencerId,
}) => {
  const [isChangesModalVisible, setChangesModalVisible] =
    useState<boolean>(false);

  // const [reviewModalVisible, setReviewModalVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const daysLeft = getDaysLeft(lastDate);

  const approveLiveMutation = useMutation(approveLiveV2, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries(["chat", CardType.deliverableLiveSentType]);

      // if (response?.data?.isLiveLast) {
      //   setReviewModalVisible(true);
      // }
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <div
      style={{
        borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
        marginLeft: "10px",
        paddingLeft: "20px",
        display: "grid",
        gap: "16px",
      }}>
      {linksTimeline.map((item, idx) => {
        return (
          <Row justify="space-between">
            <div>
              <p
                className={clsx(styles.subTileHeading, {
                  [styles.subTileHeadingClickable]:
                    item.type === "Live-Uploaded" ||
                    item.type === "Live-Edited",
                })}>
                {item.name}
                {(item.type === "Live-Uploaded" ||
                  item.type === "Live-Edited") && (
                  <Button
                    style={{ display: "blocK", padding: "0" }}
                    type="link"
                    onClick={() =>
                      window.open(item?.references[0]?.permalink, "_blank")
                    }>
                    View link
                  </Button>
                )}
              </p>

              <p className={styles.description}>
                {item.name} on {""}
                {format(new Date(item.createDateTime), "d LLLL, uuuu ")}
              </p>

              {item.expectedClosureDateTime && (
                <p className={styles.description}>
                  Deadline:{" "}
                  {format(
                    new Date(item?.expectedClosureDateTime),
                    "d LLLL, uuuu "
                  )}
                </p>
              )}
              {item.type === "Live-Require-Changes" && (
                <p>Changes Requested: {item.message}</p>
              )}
              {(item.type === "Live-Uploaded" || item.type === "Live-Edited") &&
                idx === linksTimeline.length - 1 && (
                  <div style={{ width: "200px" }}>
                    <>
                      <Button
                        onClick={() =>
                          approveLiveMutation.mutate({
                            workflowId: workflowId,
                            prevLiveLinkTimeLineId: item._id,
                          })
                        }
                        loading={approveLiveMutation.isLoading}
                        type="primary"
                        block
                        style={{
                          margin: "10px 0 5px 0",
                          borderRadius: "2px",
                        }}
                        // onClick={() => setApproveVisible(true)}
                      >
                        Approve
                      </Button>
                      <Button
                        type="default"
                        block
                        style={{ borderRadius: "2px" }}
                        onClick={() => setChangesModalVisible(true)}>
                        Request Changes
                      </Button>
                    </>
                  </div>
                )}
            </div>
            <div>
              {item.type === "Live-Uploaded" &&
                idx !== linksTimeline.length - 1 && (
                  <Button
                    type="link"
                    style={{ padding: "0 8px" }}
                    onClick={() =>
                      window.open(item.references[0].url, "_blank")
                    }>
                    View
                  </Button>
                )}

              {item.type === "Live-Require-Changes" &&
                idx === linksTimeline.length - 1 &&
                !isNaN(daysLeft) && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color:
                        daysLeft >= 5
                          ? "#BFBFBF"
                          : daysLeft >= 2
                          ? "#FA8C16"
                          : "#F5222D",
                    }}>
                    {Math.max(daysLeft, 0)} d left
                  </span>
                )}
            </div>

            <LiveChangesModal
              isVisible={isChangesModalVisible}
              handleSubmit={() => setChangesModalVisible(false)}
              handleCancel={() => setChangesModalVisible(false)}
              workflowId={workflowId}
              prevLiveLinkTimeLineId={item._id}
            />
            {/* <ReviewModals
              isVisible={reviewModalVisible}
              setVisible={setReviewModalVisible}
              influencerId={influencerId}
              finalizeOfferId={offerId}
            /> */}
          </Row>
        );
      })}
    </div>
  );
};

interface LinksTileProps {
  offerId: string;
  influencerId: string;
}

const LinksTile: React.FC<LinksTileProps> = ({ offerId, influencerId }) => {
  const [getTimelineDetailsKey, getTimelineDetailsFn] = getTimelineDetailsV2({
    finalizedOffersId: offerId,
  });

  const [addLiveProps, setAddLiveProps] = useState<{
    visible: boolean;
    workflowId: string;
    platform: string;
  }>({ visible: false, workflowId: "", platform: "" });

  const { data: timelineData } = useQuery(
    getTimelineDetailsKey,
    getTimelineDetailsFn,
    { enabled: offerId !== "" }
  );
  const { Panel } = Collapse;

  const getTagColor = (state: string) => {
    if (state === "Going-Live") {
      return "gold";
    } else if (state === "Awaiting-Review") {
      return "red";
    } else if (state === "Approved") return "green";
    return "";
  };

  return (
    <div>
      <Collapse
        accordion
        ghost
        bordered={false}
        expandIconPosition={"end"}
        style={{ display: "grid", gap: "6px" }}
        expandIcon={() => {
          return null;
        }}
        defaultActiveKey={
          timelineData?.data.map((_, idx) => idx) || ["0", "1", "2", "3", "4"]
        }>
        {timelineData?.data.map((item, idx) => {
          return (
            <Panel
              header={
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "280px",
                    }}>
                    <Badge
                      dot={getTagColor(item.live.status) === "red"}
                      offset={[5, 5]}>
                      <p className={styles.tileHeader}>
                        <span className={styles.tileNumber}>{idx + 1}</span>
                        {`${item.deliverablesType} `}
                      </p>
                    </Badge>
                    <Tag
                      color={getTagColor(item.live.status)}
                      style={{ borderRadius: "4px" }}>
                      {item.live.status}
                    </Tag>
                  </div>
                  {item.live.status !== "Approved" && (
                    <Row justify="end" style={{ marginTop: 4 }}>
                      <ReportButton
                        size="small"
                        type="primary"
                        onClick={() =>
                          setAddLiveProps({
                            visible: true,
                            workflowId: item._id || "",
                            platform: item.platform,
                          })
                        }>
                        Add Live
                      </ReportButton>
                    </Row>
                  )}
                </div>
              }
              key={idx}
              style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid rgba(157, 157, 157,0.4)",
              }}
              collapsible={
                item.liveTimeLine.length > 0 ? "header" : "disabled"
              }>
              <TimeLine
                linksTimeline={item.liveTimeLine}
                lastDate={item.live.actionExceptedClosureDateTime || ""}
                workflowId={item._id || ""}
                offerId={offerId}
                influencerId={influencerId}
              />
            </Panel>
          );
        })}
      </Collapse>
      <AddLive
        isVisible={addLiveProps.visible}
        handleSubmit={() =>
          setAddLiveProps({ visible: false, workflowId: "", platform: "" })
        }
        handleCancel={() =>
          setAddLiveProps({ visible: false, workflowId: "", platform: "" })
        }
        workflowId={addLiveProps.workflowId}
        platform={addLiveProps.platform}
      />
    </div>
  );
};

export default LinksTile;
