import React, { useEffect, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useQuery } from "react-query";
import { Divider, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import { getDefaultLottieOptions } from "../../../../utils";

import animationData from "../../../assets/lottie/Rocket.json";

import CreatorCard from "./CreatorCard";
import Medias from "./Medias";
import IndividualReport from "./IndividualReport";
import GaCard from "../ReportScreen/GaCard";
import ReportButton from "../ReportButton";
import NoMedia from "./NoMedia";

import { getInfluencerReport } from "../../../../handler/report";

import styles from "./styles.module.css";

interface IndividualReportScreenProps {
  campaignId: string;
  socialMediaId: string;
  platform: "instagram" | "youtube";
  deliverableMediaId?: string;
  inDrawer?: boolean;
}

const IndividualReportScreen: React.FC<IndividualReportScreenProps> = ({
  campaignId,
  socialMediaId,
  platform,
  deliverableMediaId,
  inDrawer = false,
}) => {
  const [getInfluencerReportKey, getInfluencerReportFn] = getInfluencerReport({
    campaignId,
    socialMediaId,
  });

  const [foo] = useSearchParams();
  const mediaId = foo.get("mediaId");

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    getInfluencerReportKey,
    getInfluencerReportFn,
    { enabled: Boolean(campaignId && socialMediaId) }
  );

  const [selectedDeliverableId, setSelectedDeliverableId] = useState<string>(
    mediaId || deliverableMediaId || ""
  );

  useEffect(() => {
    setSelectedDeliverableId((_) => {
      return mediaId || deliverableMediaId || "";
    });
  }, [deliverableMediaId, mediaId]);

  if (isLoading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}
      >
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <>
      {!isLoading && data?.data && (
        <section className={styles.container}>
          {!inDrawer && (
            <ReportButton
              ghost
              style={{
                textDecoration: "underline",
                width: "fit-content",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={() =>
                navigate(`/campaign/manage/report/${campaignId}`, {
                  replace: true,
                })
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <HiChevronLeft size={20} />
                Camapign
              </div>
            </ReportButton>
          )}
          <div className={styles.reportContainer}>
            <CreatorCard data={data?.data} />
            {!data?.data?.reportInfluencer?.length ? (
              <NoMedia data={data?.data} />
            ) : (
              <>
                <Divider style={{ margin: 0 }} />
                <Medias
                  data={data?.data!}
                  handleSelect={(id: string) => {
                    setSelectedDeliverableId(id);
                  }}
                  selectedId={selectedDeliverableId}
                />
                <Divider style={{ margin: 0 }} />
                <IndividualReport
                  data={data?.data!}
                  handleSelect={(id: string) => {
                    setSelectedDeliverableId(id);
                  }}
                  selectedId={selectedDeliverableId}
                  campaignId={campaignId}
                  platform={platform}
                />
                <Divider style={{ margin: 0 }} />
              </>
            )}
            <GaCard inReport />
          </div>
        </section>
      )}
    </>
  );
};

export default IndividualReportScreen;
