import { Modal } from "antd";
import React from "react";

import styles from "./activateScreen.module.css";

interface ActivateScreenProps {
  show: boolean;
  handleClose: () => void;
}

const ActivateScreen: React.FC<ActivateScreenProps> = (props) => {
  const { show, handleClose } = props;
  return (
    <Modal
      visible={show}
      title={
        <span className={styles.modalTitle}>Activate your account first</span>
      }
      centered
      onCancel={() => handleClose()}
      footer={null}>
      <p className={styles.activateText}>
        You're almost there to launch your influencer marketing campaigns!.
        Simply activate your account to begin.
      </p>
    </Modal>
  );
};
export default ActivateScreen;
