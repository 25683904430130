import { sendRequest } from "../helper";

import { RuleById } from "../../typings";

interface editRuleProps {
  rules: RuleById;
  ruleId: string;
}

export const editRule = ({ rules, ruleId }: editRuleProps) => {
  const updatedRules = rules.rules?.map((rItem) => {
    delete rItem["attachedProductCatalogueObj"];
    delete rItem["_id"];
    return rItem;
  });

  console.log(rules, "hello", updatedRules);
  // rules.rules = updatedRules;
  return sendRequest("put", `/brand/smart-replies/rule`, false, {
    name: rules.name,
    rules: updatedRules,
    ruleId,
  }).then((res) => res.data);
};
