import React from "react";
import { Button, Row, Spin, message } from "antd";
import { useQuery, useMutation } from "react-query";

import { getAppState } from "../../../handler/getAppState";
import { getCustomerPortalLink } from "../../../handler/plan";

import styles from "../profile.module.css";

import { capitalizeFirstLetter } from "../../../utils";
import { format } from "date-fns";

const BillingDetails = () => {
  const [appStateKey, appStateFn] = getAppState(true);

  const { data, isLoading } = useQuery(appStateKey, appStateFn);

  const getCustomerPortalLinkMutation = useMutation(getCustomerPortalLink, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Getting Link");
      console.log(err);
    },
  });

  if (isLoading) {
    return (
      <Row justify="center" style={{ marginTop: "200px" }}>
        <Spin />
      </Row>
    );
  }

  if (!data?.data?.isSubscriptionManagedByStripe) {
    return (
      <p
        className={styles.tileDesc}
        style={{ textAlign: "center", marginTop: "200px" }}>
        You have a Custom Plan Enabled. For any help regarding Invoices,
        <br /> Contact your Account Manager
      </p>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.containerHeading}>Billing</h2>
        <section className={styles.innerGrid}>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Billing Period </p>
              <p className={styles.tileDesc}>
                {capitalizeFirstLetter(
                  data?.data?.subscriptionPlan?.cycle || ""
                )}
                ly
              </p>
            </section>
            <div className={styles.actionBlock}>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Edit period
              </Button>
            </div>
          </div>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Plan</p>
              <p className={styles.tileDesc}>{data?.data?.tier?.planName}</p>
            </section>
            <div className={styles.actionBlock}>
              <p className={styles.tileDesc}>
                {data?.data?.isIndia
                  ? `₹${data?.data?.tier?.price?.inr}`
                  : `$${data?.data?.tier?.price?.usd}`}{" "}
                per {data?.data?.tier?.cycle}. Started on{" "}
                {format(
                  new Date(
                    data?.data?.subscriptionPlan?.createdOnDateTime ||
                      new Date()
                  ),
                  "d LLLL, uuuu "
                )}
              </p>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Change Plan
              </Button>
            </div>
          </div>
        </section>
      </div>
      <div>
        <h2 className={styles.containerHeading}>Payment Details</h2>
        <section className={styles.innerGrid}>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Payment Method </p>
              <p className={styles.tileDesc}>
                {data?.data?.cardDetails?.paymentMethod}
              </p>
            </section>
            <div className={styles.actionBlock}>
              <p className={styles.tileDesc}>
                {data?.data?.cardDetails?.brand} ending{" "}
                {data?.data?.cardDetails?.last4}
              </p>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Edit method
              </Button>
            </div>
          </div>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Billed to</p>
              <p className={styles.tileDesc}>
                {data?.data?.cardDetails?.billingDetailsCountry}
              </p>
            </section>
            <div className={styles.actionBlock}>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Edit Information
              </Button>
            </div>
          </div>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Billing email</p>
              <p className={styles.tileDesc}>
                {data?.data?.cardDetails?.email}
              </p>
            </section>
            <div className={styles.actionBlock}>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Edit Email
              </Button>
            </div>
          </div>
          {/* <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>VAT/GST number</p>
              <p className={styles.tileDesc}>-</p>
            </section>
            <div className={styles.actionBlock}>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                Edit Number
              </Button>
            </div>
          </div> */}
        </section>
      </div>

      <div>
        <h2 className={styles.containerHeading}>Invoices</h2>
        <section className={styles.innerGrid}>
          <div className={styles.tile}>
            <section className={styles.tileBlock}>
              <p className={styles.tileHeading}>Upcoming Invoice</p>
            </section>
            <div className={styles.actionBlock}>
              <Button
                onClick={() => getCustomerPortalLinkMutation.mutate()}
                loading={getCustomerPortalLinkMutation.isLoading}>
                {" "}
                View Invoice
              </Button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BillingDetails;
