import React, { useState } from "react";
import { Input, message, Space } from "antd";
import { useQueryClient } from "react-query";
import { useMutation, useQueries, useQuery } from "react-query";

import ReportModal from "../../ReportModal";

import styles from "./styles.module.css";

interface GaModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const GaModal: React.FC<GaModalProps> = ({ visible, setVisible }) => {
  return (
    <ReportModal
      visible={visible}
      centered
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      footer={null}
      title="Connect Google Analytics"
      width={540}>
      <main style={{ display: "grid", gap: "25px" }}>
        <Space size={20}>
          <img
            src="/assets/icons/googleAnalytics.svg"
            alt="google"
            width={100}
            height={50}
          />
          <p className={styles.modalGADesc}>
            Google Analytics is not enabled for your
            <br /> account.
          </p>
        </Space>
        <p className={styles.modalGADesc}>
          Connect with us at{" "}
          <span>
            <a
              href="mailto:app@cloutflow.com?subject=GA%20Integration"
              style={{ textDecoration: "underline" }}>
              app@cloutflow.com
            </a>
          </span>{" "}
          to enable GA for your account.
        </p>
        {/* <Space align="center" size={25} style={{ padding: "10px 15px" }}>
          <img
            src="/assets/img/rishi.png"
            alt="google"
            className={styles.profile}
          />
          <div>
            <p className={styles.modalGADesc}>Rishikesh Ranjan</p>
            <p
              className={styles.modalDescLight}
              style={{ marginTop: "6px", marginBottom: "10px" }}>
              GA Integration @Cloutflow
            </p>
            <div>
              <p className={styles.modalGADesc}>Drop me an e-mail at</p>
              <a
                style={{ color: "#533AF5", textDecoration: "underline" }}
                href={`mailto:rishi@cloutflow.com?subject=${
                  encodeURIComponent("Regarding GA Integration") || ""
                }`}>
                rishi@cloutflow.com
              </a>
            </div>
          </div>
        </Space> */}
      </main>
    </ReportModal>
  );
};

export default GaModal;
