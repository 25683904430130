import Invoices from "../Invoices";

import React, { useState, useRef } from "react";

interface Props {
  videoUrl: string;
}

const VideoFrameExtractor: React.FC<Props> = ({ videoUrl }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const extractFirstFrame = () => {
    const video = videoRef.current;

    if (video) {
      // Capture the current frame from the video
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a data URL
        const dataURL = canvas.toDataURL("image/jpeg");

        // Set the data URL as the source for an image element
        setImageSrc(dataURL);
      }
    }
  };

  return (
    <div>
      <video ref={videoRef} controls width="400" height="300" src={videoUrl} />
      <br />
      <button onClick={extractFirstFrame}>Extract First Frame</button>
      <br />
      {imageSrc && <img src={imageSrc} alt="First Frame" />}
    </div>
  );
};

const TestComp = () => {
  return (
    <VideoFrameExtractor videoUrl="https://storage.googleapis.com/prod-cloutflow-platform.appspot.com/data_layer%2F17841400163341127%2F17934577154774331" />
  );
};

export default TestComp;
