import { sendRequest } from "../helper";
import { Catalogue } from "../../typings";

export type getCataloguesResult = {
  status: string;
  message: string;
  data: {
    catalogue: Catalogue[];
    pageNo: number;
  };
};

export type CProps = {
  limit: number;
};

export const getCatalogues = (props: CProps) => {
  const queryKey = ["smart-replies", "catalogues"];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getCataloguesResult>(
      "GET",
      `/brand/products/catalogue?limit=${props.limit}&pageNo=${pageParam || 1}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
