import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { transFilters, getCampaign } from "../../../../handler/campaign";
import { CampaignResponse } from "../../../../typings";
import { getNiche } from "../../../../handler/discovery";

import styles from "../Create.module.css";

import {
  Radio,
  Form,
  Button,
  Select,
  Row,
  Col,
  message,
  Space,
  Alert,
  Spin,
} from "antd";

import Navbar from "../../../shared/Navbar";
import CreateSteps from "../Steps";
import Footer from "../Footer";

import { capitalizeFirstLetter } from "../../../../utils";

import CheckBox from "./Checkbox";

const { Option } = Select;

const CreatorFilters: React.FC = () => {
  let { id } = useParams();
  const [campaignType, setCampaignType] = useState<"Public" | "Invite-Only">();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const [checkboxOptions, setCheckboxOptions] = useState<string[]>([""]);
  // const [commercialType, setCommercialType] = useState("variable");
  // const fixedCommercialRef = useRef();
  // const variableCommercialRef = useRef();

  const followerOptions = [
    "All",
    "Nano (1K-10K)",
    "Micro (10K-100K)",
    "Macro (100K - 1M)",
    "Celebrity (1M and above)",
  ];

  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [isEditEnabled, setEditEnabled] = useState<boolean>(false);
  const [platform, setPlatform] = useState<string>("instagram");

  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId: id!,
  });
  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFunction);

  const [getNicheKey, getNicheFunction] = getNiche(platform);
  const { data: nicheData } = useQuery(getNicheKey, getNicheFunction);

  useEffect(() => {
    if (campaignData) {
      setPlatform(campaignData.data.campaign.platform);
      if (campaignData.data.campaign.isPublic) {
        setCampaignType("Public");
      } else {
        setCampaignType("Invite-Only");
      }
      // if (campaignData.data.campaign.isVariablePriced) {
      //   setCommercialType("variable");
      // } else {
      //   setCommercialType("fixed");
      // }
      if (campaignData.data.campaign.filter.followerCategory.length > 0) {
        setCheckboxOptions(campaignData.data.campaign.filter.followerCategory);
      }

      setIsLoading(false);

      if (
        campaignData.data.campaign.statusHistory.findIndex(
          (item) => item.status === "Added-Commerical-Filters"
        ) !== -1
      ) {
        setEditEnabled(true);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (isEditEnabled) {
      return setSaveEnabled(false);
    }
    return setSaveEnabled(true);
  }, [isEditEnabled]);

  const postFiltersMutation = useMutation(transFilters, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get sinngle backend data
          return response;
        }
      );
      setEditEnabled(true);
      message.success("Details Updated");
    },
    onError: (err: AxiosError) => {
      if (
        (err!.response!.data! as any).status ===
        "ERR_INVITE_CANNOT_FIXED_PRICED"
      ) {
        message.error("Invite only campaign can not be fixed priced");
      } else message.error("Something went wrong");
    },
  });

  const handleChange = (value: string[]) => {};

  const handleButtonClick = (value: string, checked: boolean) => {
    const valToCheck = value.split(" ")[0].toLowerCase();
    if (checked) {
      const newList = checkboxOptions.filter((item) => {
        return item !== "all" && item !== valToCheck;
      });
      setCheckboxOptions(newList);
    } else {
      if (valToCheck === "all") {
        return setCheckboxOptions([
          "all",
          "nano",
          "micro",
          "macro",
          "celebrity",
        ]);
      }
      setCheckboxOptions((options) => [...options, valToCheck]);
    }
  };

  const onFinish = (values: any) => {
    // console.log(values);
    if (campaignType === "Invite-Only") {
      // if (commercialType === "variable") {
      return postFiltersMutation.mutate({
        campaignId: id!,
        isPublic: false,
        niche: values.niche,
        followerCategory: checkboxOptions.filter((item) => item !== ""),
      });
      // } else {
      //   return postFiltersMutation.mutate({
      //     campaignId: id!,
      //     isPublic: false,
      //     isVariablePriced: false,
      //     followerCategory: checkboxOptions.filter((item) => item !== ""),
      //     nano: values.nano,
      //     micro: values.micro,
      //     macro: values.macro,
      //     celebrity: values.celebrity,
      //   });
      // }
    } else {
      // if (commercialType === "variable") {
      return postFiltersMutation.mutate({
        campaignId: id!,
        isPublic: true,
        followerCategory: checkboxOptions.filter((item) => item !== ""),
        niche: values.niche,
      });
      // }
      // return postFiltersMutation.mutate({
      //   campaignId: id!,
      //   isPublic: true,
      //   isVariablePriced: false,
      //   followerCategory: checkboxOptions.filter((item) => item !== ""),
      //   niche: values.niche,
      //   nano: values.nano,
      //   micro: values.micro,
      //   macro: values.macro,
      //   celebrity: values.celebrity,
      // });
    }
  };

  const CheckProps = (props: CustomTagProps) => {
    return (
      <div className={styles.selectTag}>
        <span className={styles.selectTagText}>{props.label}</span>
        <img
          src="/assets/icons/close-blue.svg"
          alt="close icon"
          style={{ cursor: "pointer" }}
          onClick={props.onClose}
        />
      </div>
    );
  };

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "50px 0" }}>
        <Col span={24}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "40px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.85)",
            }}>
            Create an{" "}
            {capitalizeFirstLetter(campaignData?.data.campaign.platform || "")}{" "}
            Campaign
            <img
              src={`/assets/icons/${campaignData?.data.campaign.platform}Icon.png`}
              alt="instagram"
              style={{ width: "30px", marginLeft: "8px" }}
            />
          </div>

          <p
            style={{
              marginTop: "8px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.45)",
            }}>
            Need assistance? Please watch this{" "}
            <span
              style={{
                color: "#2130FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}>
              video
            </span>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: "90px" }}>
        <Col span={4} offset={2}>
          {campaignData && (
            <CreateSteps
              currentStep={3}
              statusHistory={campaignData?.data.campaign.statusHistory}
            />
          )}
        </Col>
        <Col
          span={11}
          offset={2}
          style={{
            maxHeight: "57vh",
            overflowY: "scroll",
            paddingRight: "10px",
          }}>
          <div
            style={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
              color: "rgba(0, 0, 0, 0.45)",
              marginBottom: "10px",
            }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.85)",
              }}>
              Creator Details
            </p>
            <p>Creator Details What kind of creators are you looking for?</p>
          </div>
          <div
            style={{
              width: "100%",
              padding: "16px 20px",
              background: "#FAFAFA",
              minHeight: "30vh",
            }}>
            <>
              <div>
                <Form
                  layout="vertical"
                  autoComplete="on"
                  requiredMark={false}
                  onFinish={onFinish}>
                  <Form.Item
                    label="Campaign Type"
                    name="cType"
                    className={styles.label}
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one category",
                      },
                    ]}
                    wrapperCol={{ span: 20 }}
                    initialValue={campaignType}>
                    <Radio.Group
                      style={{ width: "100%" }}
                      size="middle"
                      onChange={(event) => {
                        setCampaignType(event.target.value);
                        // if (event.target.value === "Public")
                        //   setCommercialType("fixed");
                        // else setCommercialType("variable");
                      }}
                      disabled={!isSaveEnabled}>
                      <Radio value={"Public"} className={styles.secondaryLabel}>
                        Public (Anyone can apply)
                      </Radio>
                      <Radio
                        value={"Invite-Only"}
                        className={styles.secondaryLabel}>
                        Invite-Only (Only people you invite can participate)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Select Communities"
                    name="niche"
                    className={styles.label}
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one niche",
                      },
                    ]}
                    wrapperCol={{ span: 20 }}
                    initialValue={campaignData?.data.campaign.filter.niche}
                    // {...formItemLayout}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Community"
                      onChange={handleChange}
                      tagRender={(props) => CheckProps(props)}
                      disabled={!isSaveEnabled}>
                      {nicheData?.data?.map((item) => {
                        return <Option key={item.name}>{item.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  {/* <Input size="large" placeholder="e.g. June | Kesh Kanti"></Input> */}

                  <Form.Item
                    label="Category (followers)"
                    name="followType"
                    className={styles.label}
                    wrapperCol={{ span: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one category",
                      },
                    ]}
                    initialValue={checkboxOptions}

                    // {...formItemLayout}
                  >
                    <Row gutter={[8, 8]}>
                      {followerOptions.map((item, idx) => {
                        return (
                          <CheckBox
                            key={idx}
                            value={item}
                            checked={checkboxOptions.includes(
                              item.split(" ")[0].toLowerCase()
                            )}
                            handleCheck={handleButtonClick}
                            disabled={!isSaveEnabled}
                          />
                        );
                      })}
                    </Row>
                  </Form.Item>

                  {/* <Form.Item
                    label="Commercials"
                    name="commercialType"
                    style={{ marginTop: "24px", flexFlow: "column" }}
                    className={styles.label}
                    tooltip={
                      "Invite only campaigns cannot have fixed commercials"
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Select a type",
                      },
                    ]}
                    valuePropName="checked"
                    initialValue={commercialType}>
                    <Radio.Group
                      style={{
                        display: "flex",
                        width: "85%",
                      }}
                      size="middle"
                      onChange={(e) => setCommercialType(e.target.value)}
                      disabled={!isSaveEnabled}
                      value={commercialType}>
                      <Radio.Button
                        ref={fixedCommercialRef}
                        disabled={campaignType === "Invite-Only"}
                        value={"fixed"}
                        style={{
                          padding: "24px 16px",
                          textAlign: "center",
                          width: "50%",
                          height: "100%",
                        }}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}>
                          Fixed (Non-Negotiable)
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}>
                          A fixed price for everyone
                        </p>
                      </Radio.Button>
                      <Radio.Button
                        ref={variableCommercialRef}
                        value={"variable"}
                        style={{
                          padding: "24px 16px",
                          textAlign: "center",
                          width: "50%",
                          height: "100%",
                        }}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}>
                          Variable (Negotiable)
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}>
                          The creator sends their quote.
                        </p>
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {commercialType === "fixed" && (
                    <h1 style={{ fontWeight: 450, fontSize: "14px" }}>
                      Add Commercials
                    </h1>
                  )}
                  {commercialType === "fixed" &&
                    followerOptions
                      .filter((temp, idx) => idx !== 0)
                      .map((item, idx) => {
                        if (item === "all") {
                          return null;
                        }
                        return (
                          <Form.Item
                            label={item}
                            className={styles.label}
                            name={item.split(" ")[0].toLowerCase()}
                            rules={[
                              {
                                required: true,
                                message: "Please input Value",
                              },
                            ]}
                            extra={`Suggested commercial: ${nf(
                              400 * 10 * Math.max(idx, 1),
                              "0,0"
                            )}`}
                            key={idx}
                            wrapperCol={{ span: 7 }}
                            initialValue={
                              campaignData?.data.campaign.fixedPrices[
                                item.split(" ")[0].toLowerCase() as unknown as
                                  | "nano"
                                  | "micro"
                                  | "macro"
                                  | "celebrity"
                              ]
                            }>
                            <InputNumber
                              type="number"
                              size="large"
                              addonBefore="₹"
                              placeholder="4000"
                              disabled={!isSaveEnabled}
                              key={idx}
                            />
                          </Form.Item>
                        );
                      })} */}

                  <Form.Item>
                    <Space>
                      <Button
                        type="primary"
                        size="large"
                        title="Save"
                        style={{ width: "144px", marginTop: "32px" }}
                        htmlType="submit"
                        loading={postFiltersMutation.isLoading}
                        disabled={
                          (isSaveEnabled
                            ? campaignType === "Public" &&
                              checkboxOptions.length === 0
                            : true) ||
                          checkboxOptions.filter((item) => item !== "")
                            .length === 0
                        }>
                        Save
                      </Button>
                      {campaignData?.data.campaign.statusHistory.findIndex(
                        (item) => item.status === "Active"
                      ) === -1 && (
                        <Button
                          type="text"
                          size="large"
                          title="Next"
                          style={{ width: "144px", marginTop: "32px" }}
                          onClick={() => setEditEnabled(false)}
                          disabled={!isEditEnabled}>
                          Edit
                        </Button>
                      )}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </>
          </div>
        </Col>
        <Col
          style={{ overflowY: "auto", maxHeight: "47vh" }}
          offset={1}
          span={3}>
          {campaignData?.data.campaign.status === "Needs-Attention" && (
            <Alert type="error" message={campaignData?.data.campaign.remarks} />
          )}
        </Col>
      </Row>
      <Footer
        onNext={() => {
          navigate(`/campaign/create/${id}/guidelines`);
        }}
        onBack={() => {
          navigate(`/campaign/create/${id}/add-deliverables`);
        }}
        isNextDisabled={
          !campaignData
            ? true
            : isSaveEnabled
            ? true
            : campaignData.data.campaign.statusHistory.findIndex(
                (item) => item.status === "Added-Commerical-Filters"
              ) === -1
        }
      />
    </div>
  );
};

export default CreatorFilters;
