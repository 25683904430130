import { sendRequest } from "../../helper";

export type shortlistOfferProps = {
  offerId: string;
};

export const shortlistOffer = ({ offerId }: shortlistOfferProps) => {
  return sendRequest(
    "patch",
    `/brand/campaign/offer/shortlist/${offerId}`,
    false
  ).then((res) => res.data);
};
