import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Divider, Row } from "antd";
import { UseQueryResult } from "react-query";
import Lottie from "react-lottie";

import InformationCard from ".././InformationCard";
import BasicInfo from ".././BasicInfo";
import PostsInsights from ".././PostInsights";
import BasicInsights from ".././BasicInsights";
import AudienceInsights from ".././AudienceInsights";
import IndividualPostsInsights from "../IndividualPostsInsights";

import styles from "./styles.module.css";

import { formatData } from "../../../utils";

import {
  getIgAnalytics,
  getInfluencerMedia,
  getPinnedMetrics,
  updatePinnedMetrics,
} from "../../../handler/Profile";

import { getDefaultLottieOptions } from "../../../utils";

import animationData from "../../assets/lottie/Rocket.json";
import ThirdParty from "../ThirdParty";

export type PinnedMetric = {
  title: string;
  period?: "week" | "month";
  type?: "followers" | "engAudience" | "reAudience";
};

type IgProps = {
  socialMediaId: string;
  influencerId: string;
  socialMediaName: string;
  socialMediaKitId: string;
  inDrawer: boolean;
};

const IgAnalytics: React.FC<IgProps> = (props) => {
  const { socialMediaId, influencerId, socialMediaName } = props;
  const queryClient = useQueryClient();

  const [getInfluencerMediaKey, getInfluencerMediaFn] = getInfluencerMedia(
    influencerId || ""
  );
  const { data: influencerMedia, isLoading: isInfluencerMediaLoading } =
    useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {
      enabled: Boolean(influencerId),
    });

  const instaData = influencerMedia?.data?.find(
    (item) => item.socialMediaId === socialMediaId
  );

  // const [getInfluencerCommunityRelationKey, getInfluencerCommunityRelationFn] =
  //   getInfluencerCommunityRelation({ socialMediaId });

  const isInitialMount = useRef(true);

  const [pinnedMetrices, setPinnedMetrices] = useState<PinnedMetric[]>([]);

  const updatePinnedMetricsMutation = useMutation(updatePinnedMetrics);

  const onPinnedMetricesChange = () => {
    const payload = pinnedMetrices.map((item) => Object.values(item).join("%"));
    updatePinnedMetricsMutation.mutate(
      {
        platform: "instagram",
        pinnedFields: payload,
      },
      {
        onSuccess: () => console.log("Success"),
        onError: () => console.log("Something went wrong!"),
      }
    );
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onPinnedMetricesChange();
    }
  }, [pinnedMetrices]);

  const [key, fn] = getPinnedMetrics("instagram");

  const {
    data: metricsData,
    isLoading: loadingMetrics,
    isError: errorLoadingMetrics,
  } = useQuery(key, fn, {
    enabled:
      !Boolean(instaData?.isProfileLockedForBrand) &&
      Boolean(instaData?.isInBrandCommunity) &&
      instaData?.dataProvider === "default",
  });

  useEffect(() => {
    if (metricsData) {
      setPinnedMetrices(
        metricsData.data.map((item) => {
          const arr = item.split("%");
          return {
            title: arr[0],
            ...(arr[1] && {
              period: arr[1] as PinnedMetric["period"],
            }),
            ...(arr[2] && { type: arr[2] as PinnedMetric["type"] }),
          };
        })
      );
    }
  }, [metricsData]);

  const [influencerKey, influencerFn] = getIgAnalytics(socialMediaId || "");

  const { data, isLoading: influencerLoading } = useQuery(
    influencerKey,
    influencerFn,
    {
      enabled:
        Boolean(socialMediaId) &&
        !Boolean(instaData?.isProfileLockedForBrand) &&
        Boolean(instaData?.isInBrandCommunity) &&
        instaData?.dataProvider === "default",
    }
  );

  if (isInfluencerMediaLoading) {
    return (
      <Row justify="center" style={{ marginBlock: 200 }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={500}
          width={270}
        />
      </Row>
    );
  }

  const handleInvalidate = () => {
    queryClient.invalidateQueries(influencerKey);
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(getInfluencerMediaKey);
  };

  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <InformationCard
          hasAccess={Boolean(instaData?.isInBrandCommunity)}
          platform="instagram"
          username={socialMediaName}
          isRevoked={instaData?.connectionStatus === "Session-Expired"}
          color={!instaData ? "blue" : "pink"}
          lastUpdated={data?.data?.lastUpdatedDateTime}
          isProfileLocked={Boolean(instaData?.isProfileLockedForBrand)}
          soicalMediaKitId={instaData?._id || ""}
          handleInvalidate={handleInvalidate}
          accountStatus={instaData?.accountStatus || "Connected"}
          isThirdParty={instaData?.dataProvider !== "default"}
          isAutoInvite={instaData?.isAutoInvite || false}
        />
      </div>
      <div className={styles.innerContainer}>
        <BasicInfo
          showInsightsButton={false}
          socialMediaId={socialMediaId}
          socialMediaKitId={instaData?._id || ""}
          id=""
          bio={instaData?.instagram?.user?.biography || ""}
          followers={formatData(
            instaData?.instagram?.user?.followerCount || 0,
            "a"
          )}
          following={formatData(
            instaData?.instagram?.user?.followsCount || 0,
            "a"
          )}
          noOfMedia={formatData(
            instaData?.instagram?.user?.contentCount || 0,
            "a"
          )}
          name={instaData?.instagram?.user?.name || ""}
          platform="instagram"
          profilePic={instaData?.instagram?.user?.profilePicUrl || ""}
          username={instaData?.instagram?.user?.name || ""}
          influencerId={socialMediaId || ""}
          pinnedMetricesIG={
            loadingMetrics ? [] : errorLoadingMetrics ? [] : pinnedMetrices
          }
          setPinnedMetricesIG={setPinnedMetrices}
          dataShow={
            Boolean(socialMediaId) &&
            !Boolean(instaData?.isProfileLockedForBrand) &&
            Boolean(instaData?.isInBrandCommunity) &&
            instaData?.dataProvider === "default"
          }
          isInBrandCommunity={Boolean(instaData?.isInBrandCommunity)}
        />
        <Divider />

        {influencerLoading || loadingMetrics ? (
          <Row justify="center" style={{ marginBlock: 20 }}>
            <Lottie
              options={getDefaultLottieOptions(animationData)}
              height={500}
              width={270}
            />
          </Row>
        ) : (
          <>
            {Boolean(
              instaData?.isInBrandCommunity &&
                !instaData?.isProfileLockedForBrand &&
                instaData?.dataProvider === "default"
            ) && (
              <>
                {/* <PostsInsights
                  influencerId={socialMediaId || ""}
                  setPinnedMetrices={setPinnedMetrices}
                  pinnedMetrices={pinnedMetrices}
                />
                <Divider /> */}
                <IndividualPostsInsights
                  influencerId={socialMediaId || ""}
                  inDrawer={props.inDrawer}
                  setPinnedMetrices={setPinnedMetrices}
                  pinnedMetrices={pinnedMetrices}
                  canApplyWorkflow={
                    instaData?.canSendIgMessageStatus === "Success"
                  }
                />
                <Divider />
                <BasicInsights
                  influencerId={socialMediaId || ""}
                  pinnedMetrices={pinnedMetrices}
                  setPinnedMetrices={setPinnedMetrices}
                />
                <Divider />
                <AudienceInsights
                  influencerId={socialMediaId || ""}
                  pinnedMetrices={pinnedMetrices}
                  setPinnedMetrices={setPinnedMetrices}
                />
              </>
            )}

            {Boolean(
              instaData?.isInBrandCommunity &&
                !instaData?.isProfileLockedForBrand &&
                instaData?.dataProvider !== "default"
            ) && (
              <ThirdParty
                platform="instagram"
                username={instaData?.instagram?.user?.name || ""}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default IgAnalytics;
