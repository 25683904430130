import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
// import Marquee from "react-fast-marquee";
import {
  Form,
  Input,
  Divider,
  Button,
  notification,
  // Typography,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";

import { useUserAuth } from "../../../context/userAuthcontext";

import { validateEmail } from "../../../utils/validEmail";

import { logBackend } from "../../../handler/logBackend";

import styles from "../Onboarding1.module.css";

import MOCKUP from "./../../assets/login-mockup.svg";

import LOGO from "./../../assets/logo.svg";

// import { auth } from "../firebase";

// import { BrandLogo, FEATURE_DATA, FeatureText, LOGO_DATA } from "../Signup";

const auth = getAuth();

const Signin: React.FC = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialMailValue = searchParams.get("email") || "";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");

  const { login, googleSignIn, resetPassword } = useUserAuth();

  const onFinish = (values: any) => {
    // if (credentialsError !== "") {
    //   setCredentialsError("");
    // }
    form.setFields([
      {
        name: "email",
        errors: validateEmail(values.email) ? [] : ["email is not valid"],
      },
      {
        name: "password",
        errors: values.password
          ? String(values.password).length > 7
            ? []
            : ["password length too short"]
          : ["password required"],
      },
    ]);
    if (
      validateEmail(values.email) &&
      String(values.password).length >= 6 &&
      values.password
    ) {
      login(values.email, values.password)
        .then((userCred) => {
          if (!userCred.user.emailVerified)
            return navigate("/verifyMail", { replace: true });
          return navigate("/discover", { replace: true });
        })
        .catch(async (error) => {
          let errorCode = error.code;

          if ((error.message as string).includes("PERMISSION_DENIED")) {
            return openNotification(
              "Please use your company account to sign in"
            );
          }

          if (
            errorCode.split("/")[1] === "wrong-password" ||
            errorCode.split("/")[1] === "user-not-found"
          ) {
            // setCredentialsError(errorCode.split("/")[1]);
            if (errorCode.split("/")[1] === "user-not-found")
              form.setFields([
                {
                  name: "email",
                  errors: [
                    "Couldn't find a Cloutflow account associated with this email.Please try again",
                  ],
                },
              ]);
            else
              form.setFields([
                {
                  name: "password",
                  errors: ["That's not the right password. Try again"],
                },
              ]);
          }
          await logBackend("firebase-login", "", {
            err: error.message,
            email: values.email,
            password: values.password,
          });

          // openNotification("Some error occurred please retry");
          // navigate(`/login?email=${mail}`, { replace: true });
        })
        .finally(() => setLoading(false));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((user) => {
        if (!user.user.emailVerified)
          return navigate("/verifyMail", { replace: true });
        return navigate("/discover", { replace: true });
      })
      .catch(async (err) => {
        if ((err.message as string).includes("disabled")) {
          return openNotification("This account is disabled by the admin");
        }
        if ((err.message as string).includes("Unauthorized email")) {
          return openNotification("Please use a company mail to signIn");
        } else {
          openNotification("Some error occurred please retry");
        }
        console.log("google err", err, "1", err.code, "2", err.message);
        await logBackend("google-err", "", {
          err: JSON.stringify(err),
        });
      });
  };

  const handleResetEmail = () => {
    resetPassword(email)
      .then(() => {
        notification.success({
          message: "Reset Password Email Sent",
          placement: "top",
        });
      })
      .catch((err) => {
        console.log(err, err.code, err.message);
        openNotification("User not found");
      });
  };

  const openNotification = (message: string) => {
    notification["error"]({
      message,
      placement: "top",
    });
  };
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      // console.log("credfs", credentialResponse);

      const cred = GoogleAuthProvider.credential(credentialResponse.credential);

      // Sign in with credential from the Google user.
      return signInWithCredential(auth, cred)
        .then((user) => {
          if (!user.user.emailVerified)
            return navigate("/verifyMail", { replace: true });
          return navigate("/discover", { replace: true });
        })
        .catch(async (err) => {
          if ((err.message as string).includes("Unauthorized email")) {
            openNotification("Please use a company mail to signIn");
          } else {
            openNotification("Some error occurred please retry");
          }
          console.log("google err", err, "1", err.code, "2", err.message);
          await logBackend("google-err-onetap", "", {
            err: JSON.stringify(err),
          });
        });
    },
    onError: () => {
      console.log("something went wrong");
    },
  });

  // const formItemLayout = {
  //   labelCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
  //   wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
  // };

  // const { Title } = Typography;

  const handleFieldChange = (event: any) => {
    if (event[0] && event[0].name[0] === "email") {
      setEmail(event[0].value);
      if (validateEmail(event[0].value)) {
        return setValid(true);
      }
      return setValid(false);
    }
  };

  return (
    <main className={styles.main}>
      <section className={styles.container}>
        <div className={styles.block}>
          <div className={styles.logo}>
            <img src={LOGO as string} alt="cloutflow logo" />
          </div>
          <Row justify="center">
            <Col span={8} style={{ maxWidth: "560px" }}>
              <div style={{ marginBottom: "24px" }}>
                <h2>Welcome Back</h2>
                {/* <p style={{ opacity: 0.5 }}>Login back and Start Crafting</p> */}
              </div>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                onFieldsChange={handleFieldChange}
                autoComplete="on">
                <div style={{ display: "grid", gap: "14px" }}>
                  <Form.Item
                    label="Enter your Email Address"
                    name="email"
                    initialValue={initialMailValue}
                    className={styles.label}>
                    <Input
                      placeholder="e.g. stefan@cloutflow.com"
                      className={styles.input}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Enter your Password"
                    className={styles.label}
                    style={{ textAlign: "center" }}
                    name="password"
                    extra={
                      <Button
                        type="link"
                        disabled={!valid}
                        style={{ padding: "4px 0", marginTop: 12 }}
                        onClick={handleResetEmail}
                        size="large">
                        Forgot Password?
                      </Button>
                    }>
                    <Input.Password
                      placeholder="e.g. ••••••••"
                      className={styles.input}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className={styles.submitButton}
                      block
                      size="large"
                      htmlType="submit"
                      loading={loading}
                      type="primary"

                      // onClick={() => setIsModalVisible(true)}
                    >
                      Login
                      {/* <HiArrowRight size={"24px"} /> */}
                    </Button>
                    <Divider plain>Or, Sign up with</Divider>
                    <Button
                      className={styles.googleButton}
                      block
                      size="large"
                      onClick={handleGoogleSignIn}>
                      <img src="/assets/icons/google.svg" alt="google logo" />
                      Sign In with Google
                    </Button>
                  </Form.Item>
                </div>
                <Form.Item>
                  <p className={styles.helpTool} style={{ marginTop: 20 }}>
                    New to CloutFlow ?
                    <span
                      className={styles.resend}
                      onClick={() => navigate("/signup", { replace: true })}>
                      Create an Account
                    </span>
                  </p>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        {/* <div className={styles.block}>
          <div className={styles.sidebox}>
            <div>
              <h1
                className={styles.sidebox__heading}
                style={{ marginBottom: 4, fontSize: 36, fontWeight: 500 }}>
                Welcome back !
              </h1>
              <p className={styles.sidebox__text}>
                login back and start crafting campaigns.
              </p>
            </div>
            <div
              style={{
                width: "70%",
                height: "auto",
                overflow: "hidden",
                margin: "0 auto",
              }}>
              <img
                src={MOCKUP as string}
                alt="illustration"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div> */}
      </section>
    </main>
  );
};

export default Signin;
