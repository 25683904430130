import { sendRequest } from "../helper";

import { fieldConfiguration } from "../../typings/Report";

type getReportPlatformConfigurationProps = {
  platform: string;
};

type getReportPlatformConfigurationResponse = {
  data: {
    userFieldsConfiguration: fieldConfiguration[];
  };
  status: string;
  message: string;
};

export const getReportPlatformConfiguration = ({
  platform,
}: getReportPlatformConfigurationProps) => {
  const queryKey = [
    "reportOnlyReports",
    "configurationReportOnlyPlatform",
    platform,
  ];

  const queryFunction = () =>
    sendRequest<getReportPlatformConfigurationResponse>(
      "get",
      `/brand/report-only/user/configuration?platform=${platform}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
