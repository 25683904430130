import { sendRequest } from "../../helper";

type OfferSummary = {
  message: string;
  status: string;
  data: {
    influencerPrice: number;
    ecrowChequeStatus: string;
    status: string;
  };
};

export const getFinalizedOfferById = ({ offerId }: { offerId: string }) => {
  const queryKey = ["finalizedOffer", "id", "escrow", offerId];
  const queryFunction = () =>
    sendRequest<OfferSummary>(
      "get",
      `/brand/execution/finalized-by-id?finalizedOfferId=${offerId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
