import { sendRequest } from "../helper";
import { Rules } from "../../typings";

export type getRulesResult = {
  status: string;
  message: string;
  data: {
    rules: Rules[];
    pageNo: number;
  };
};

export type RProps = {
  limit?: number;
};

export const getRules = (props: RProps) => {
  const queryKey = ["smart-replies", "rule"];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getRulesResult>(
      "GET",
      `/brand/smart-replies/rule?limit=${props?.limit || 20}&pageNo=${
        pageParam || 1
      }`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
