import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select, Switch, Typography } from "antd";
import { useQuery } from "react-query";
import { getAllCampaigns } from "../../../handler/cloutflowCampaign/getAllCampaigns";
import { getCampaignById } from "../../../handler/cloutflowCampaign/getCampaignById";
import {
  addInfluencerToCampaign,
  RequestInfluencerToCampaignProps,
} from "../../../handler/cloutflowCampaign/Influencer/requestInfluencer";

const { Option } = Select;
const { Text } = Typography;

interface RequestInfluencerModalProps {
  visible: boolean;
  onClose: () => void;
  username: string;
  platform: string;
}

const RequestInfluencerModal: React.FC<RequestInfluencerModalProps> = ({
  visible,
  onClose,
  username,
  platform,
}) => {
  const [form] = Form.useForm();
  const [selectedCampaignId, setSelectedCampaignId] = useState<
    string | undefined
  >(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showArchived, setShowArchived] = useState(false);
  const [isMediaRightsOpen, setIsMediaRightsOpen] = useState<Boolean>(false);

  const [getCampaignsKey, getCampaignsFn] = getAllCampaigns({
    page,
    limit: pageSize,
    isArchived: showArchived,
  });
  const { data: campaignsData, isLoading: campaignsLoading } = useQuery(
    getCampaignsKey,
    getCampaignsFn
  );

  const handleCampaignSelect = (campaignId: string) => {
    setSelectedCampaignId(campaignId);
  };

  const handleSubmit = async (values: any) => {
    if (!selectedCampaignId) {
      console.error("Campaign ID is required");
      return;
    }

    const payload: RequestInfluencerToCampaignProps = {
      campaignId: selectedCampaignId,
      username,
      platform,
      deliverables: values.deliverables,
      isMediaRights: values.isMediaRights,
      mediaRights: values.mediaRights,
      notes: values.notes,
    };

    try {
      const result = await addInfluencerToCampaign(payload);
      console.log(result);
      handleModalClose(); // Close the modal on success and reset the fields
    } catch (error) {
      console.error("Error requesting influencer for campaign:", error);
    }
  };

  const handleModalClose = () => {
    form.resetFields(); // Reset all form values
    onClose(); // Call the original onClose function
  };

  return (
    <Modal
      visible={visible}
      title="Request Influencer Price for Campaign"
      onCancel={handleModalClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          style={{ borderRadius: "8px" }}
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
      ]}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ marginTop: 20, height: "60vh", overflow: "scroll" }}
      >
        <Form.Item
          label="Select Campaign"
          name="campaign"
          rules={[{ required: true, message: "Please select a campaign" }]}
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
        >
          <Select
            loading={campaignsLoading}
            placeholder="Select a campaign"
            onChange={handleCampaignSelect}
            style={{ width: "100%" }}
          >
            {campaignsData?.data.map((campaign) => (
              //@ts-ignore
              <Option key={campaign._id} value={campaign._id}>
                {campaign.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Deliverables"
          name="deliverables"
          help="Deliverables for which you need price"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          rules={[{ required: true, message: "Deliverables are required" }]}
        >
          <Input.TextArea placeholder="1 Reel + 2 Stories on Instagram" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "25px",
            }}
          >
            <Text strong>Media Rights</Text>
            <Text>Do you need media rights?</Text>
          </div>
          <Switch
            checked={form.getFieldValue("isMediaRights")}
            onChange={(checked) => {
              form.setFieldsValue({ isMediaRights: checked });
              setIsMediaRightsOpen(checked);
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
        {isMediaRightsOpen && (
          <Form.Item
            label="Media Rights Details"
            name="mediaRights"
            labelCol={{ style: { fontWeight: "bold" } }}
            rules={[
              {
                required: true,
                message: "Media rights details are required",
              },
            ]}
          >
            <Input.TextArea placeholder="Define the media rights types, usage, and timeline, e.g., Advertising Rights" />
          </Form.Item>
        )}
        <Form.Item
          label="Notes"
          name="notes"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
          help="Leave a note here if we should keep something in mind for this Influencer"
        >
          <Input.TextArea placeholder="Optional notes" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RequestInfluencerModal;
