import React from "react";

import styles from "../../Create.module.css";

interface CheckboxProps {
  checked: boolean;
  handleCheck: (value: string, checked: boolean) => void;
  value: string;
  disabled: boolean;
}

const CheckBox: React.FC<CheckboxProps> = ({
  checked,
  value,
  handleCheck,
  disabled,
}) => {
  return (
    <div
      className={!checked ? styles.selectTag : styles.selectTagActive}
      onClick={() => !disabled && handleCheck(value, checked)}>
      <span
        className={
          !checked ? styles.selectTagText : styles.selectTagTextActive
        }>
        {value}
      </span>
    </div>
  );
};

export default CheckBox;
