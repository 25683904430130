import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type transFiltersProps = {
  campaignId: string;
  isPublic: boolean;
  niche?: string[];
  followerCategory?: string[];
};

export const transFilters = ({
  campaignId,
  isPublic,
  niche = [],
  followerCategory = [],
}: transFiltersProps) => {
  return sendRequest<CampaignResponse>(
    "put",
    `/brand/campaign/create-campaign-form/trans-commerical-filters`,
    false,
    {
      campaignId,
      isPublic,
      niche,
      followerCategory,
    }
  ).then((res) => res.data);
};
