import { sendRequest } from "../helper";

export type UpdateSpecialFieldsProps = {
  campaignId: string;
  specialField: {
    key?: string;
    isVisible: boolean;
    _id?: string; // Required for editing
  };
  type: "add" | "edit";
};

export const updateCampaignSpecialFields = (
  props: UpdateSpecialFieldsProps
) => {
  return sendRequest(
    "put",
    `/campaign/update-campaign-fields`,
    false,
    props
  ).then((res) => res.data);
};
