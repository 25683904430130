import { sendRequest } from ".././helper";

type PlanResult = {
  status: string;
  message: string;
  data: {
    created: string;
    url: string;
    status: string;
    total: string;
  }[];
};

export const getAllInvoices = () => {
  const queryKey = ["stripe", "invoices"];
  const queryFunction = () =>
    sendRequest<PlanResult>(
      "get",
      `/brand/saas-billing/stripe/open-invoices`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
