import React, { useState, useEffect, useRef } from "react";
import { Radio, Row, Col, Space, Input, Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";

import { AiFillFilter } from "react-icons/ai";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useInstantSearch } from "react-instantsearch";

import ReportButton from "../../Campaign/Report/ReportButton";

import YTFilters from "./YTFilters";
import IGFilters from "./IGFilters";
import FilterChipsAggregate from "./FilterChipsAggregate";

import styles from "./styles.module.css";
import { formatData } from "../../../utils";

export type FiltersChipType = Partial<{
  range: {
    [key: string]: string;
  };
  refinementList: {
    [key: string]: string[];
  };
}>;

const FIlters = ({ platform }: { platform: "instagram" | "youtube" }) => {
  // const { platform, setPlatform, setSelectedCreatorList } =
  //   useRevampSaasDiscoveryContext();
  const { indexUiState, setIndexUiState, results } = useInstantSearch();

  const firstRender = useRef(true);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FiltersChipType>({});

  useEffect(() => {
    const updatedFilters: typeof filters = {};
    if (indexUiState.refinementList) {
      updatedFilters["refinementList"] = indexUiState.refinementList;
    }
    if (indexUiState.range) {
      updatedFilters["range"] = indexUiState.range;
    }
    setFilters(updatedFilters);
  }, [indexUiState]);

  useEffect(() => {
    if (firstRender?.current && indexUiState.query) {
      setSearchQuery(indexUiState.query);
      firstRender.current = false;
    }
  }, [firstRender?.current, indexUiState.query]);

  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <div
        style={{
          padding: "16px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <div
          style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}
        >
          {platform === "instagram" ? <IGFilters /> : <YTFilters />}
          <Col style={{ marginTop: "15px" }}>
            {/* <Space align="start"> */}
            <section
              className={styles.inputContainer}
              style={{ width: "100%", marginBottom: 80 }}
            >
              <Space size={2} align="center" style={{ width: "100%" }}>
                <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
                <Input
                  value={searchQuery}
                  onChange={(e) => {
                    return setSearchQuery(e.target.value || "");
                  }}
                  onPressEnter={() =>
                    setIndexUiState((indexUiState) => ({
                      ...indexUiState,
                      query: searchQuery.trim(),
                    }))
                  }
                  placeholder={"Search by name or username"}
                  className={styles.input}
                  size="small"
                  style={{
                    borderColor: "transparent",
                    boxShadow: "none",
                    width: "100%",
                    minWidth: "400px",
                  }}
                />
              </Space>
              <ReportButton
                type="primary"
                onClick={() =>
                  setIndexUiState((indexUiState) => ({
                    ...indexUiState,
                    query: searchQuery.trim(),
                  }))
                }
                // style={{ padding: "10px 20px " }}
              >
                Search
              </ReportButton>
            </section>
            {/* <Button
                onClick={() => setModalVisible(true)}
                style={{ height: "54px", width: "150px" }}>
                <Row justify="space-between" align="middle">
                  Filters
                  <AiFillFilter />
                </Row>
              </Button> */}
            {/* </Space> */}
          </Col>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          backgroundColor: "#f5f5f5",
          padding: "20px",
          width: "calc( 100% - 32px)",
        }}
      >
        <Row justify="space-between">
          <p style={{ color: "rgb(51, 51, 51)", lineHeight: "24px" }}>
            {" "}
            Results :{" "}
            <span style={{ fontWeight: 700 }}>
              {formatData(results?.nbHits, "a")} influencers{" "}
            </span>{" "}
          </p>
          <Button
            type="default"
            onClick={() => window.open(`/discover/v2/${platform}`, "_self")}
          >
            {" "}
            Clear Filters
          </Button>
        </Row>
      </div>
    </div>
  );
};

const AdvanceFiltersModal = ({
  isVisible,
  handleClose,
  platform,
}: {
  isVisible: boolean;
  handleClose: () => void;
  platform: string;
}) => {
  return (
    <Modal
      width={1100}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        handleClose();
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}
    >
      <Row justify="start" style={{ marginTop: "0px", marginBottom: 40 }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 22,
            lineHeight: "normal",
            fontFamily: "Inter",
          }}
        >
          Advance Filters
        </p>
      </Row>
      <div style={{ height: "80vh", overflowY: "scroll" }}>
        {platform === "instagram" ? <IGFilters /> : <YTFilters />}
      </div>
    </Modal>
  );
};

export default FIlters;
