import { sendRequest } from "../helper";

interface ConvResult {
  status: string;
  message: string;
  data: {
    conversationId: string | null;
  };
}

export const checkConversation = ({ userId }: { userId: string }) => {
  return sendRequest<ConvResult>(
    "get",
    `/brand/chat/conversation-exist?userId=${userId || "12121"}`,
    false
  ).then((res) => res.data);
};
