import React from "react";
import { Pagination } from "antd";

import { usePagination } from 'react-instantsearch';

const AlgoliaPagination = () => {
  const { currentRefinement, refine, nbPages, nbHits } = usePagination();

  if (!nbHits) return null;
  return (
    <Pagination
      current={currentRefinement + 1}
      total={nbPages * 10}
      pageSize={10}
      onChange={(page, _) => refine(page - 1)}
      showSizeChanger={false}
    />
  );
};

export default AlgoliaPagination;
