import React, { useState } from "react";
// import { useQuery } from "react-query";
import { MdOutlineInfo } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

import Navbar from "../shared/Navbar";
import Title from "../shared/Title";
import Selector from "./Selector";
// import Steps from "./Steps";
import Community from "./Community";
import InvitedInfluencers from "./InvitedInfluencers";

import styles from "./invite.module.css";
import Network from "./Network";

// import { getUser } from "../../handler/getUser";

export type headerType = "my" | "invited" | "page";

const Invite: React.FC = (): React.ReactElement => {
  // const [getUserKey, getUserFn] = getUser();

  // const { data } = useQuery(getUserKey, getUserFn)

  const [searchParams, _] = useSearchParams();

  // const [selectedSection, setSelectedSection] = useState<headerType>("my");

  const selectedSection = searchParams.get("type") || "community";

  return (
    <main className={styles.main}>
      <Navbar selectedItem="invite" />
      <div className={styles.innerContainer}>
        <section style={{ paddingBlock: 20 }}>
          <Title
            name="Community"
            tagline="Influencers who are a part of your brand’s Community"
            icon={<MdOutlineInfo color="rgba(0, 0, 0, 0.50)" />}
          />
        </section>
        <Selector selectedState={selectedSection} />
        <div className={styles.childContainer}>
          {selectedSection === "community" ? (
            <Community />
          ) : selectedSection === "network" ? (
            <Network />
          ) : (
            <InvitedInfluencers />
          )}
        </div>
      </div>
    </main>
  );
};
export default Invite;
