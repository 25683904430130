import { CreatorReport } from "../../typings";
import { sendRequest } from "../helper";

interface getCampaignAggregateResponse {
  data: CreatorReport[];
  message: string;
  status: string;
}

export const getCampaignAggregate = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  const queryKey = ["campaigns", "report", campaignId];

  const queryFunction = () =>
    sendRequest<getCampaignAggregateResponse>(
      "get",
      `/brand/report-only/influencer/campaign?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
