import { sendRequest } from "../helper";

interface postNotesProps {
  notes: string;
  influencerId: string;
}

export const postNotes = ({ notes, influencerId }: postNotesProps) => {
  return sendRequest("post", `/brand/brand-influencer/notes`, false, {
    notes,
    influencerId,
  }).then((res) => res.data);
};
