import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Upload, Typography, Row, Col, Button } from "antd";
import { InboxOutlined, CloseCircleOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";

import { useUserAuth } from "../../../context/userAuthcontext";
// import { useUserAuth } from "../../../context/userAuthcontext";

import { addORGDetails } from "../../../handler/addORGDetails";
import cuid from "cuid";
import ProgressBar from "../../shared/ProgressBar";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import styles from "../Onboarding.module.css";

const AddOrg: React.FC = () => {
  const { Dragger } = Upload;
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");

  const { status, currentUser } = useUserAuth();

  if (status === "registered") {
    navigate("/", { replace: true });
    return null;
  }

  const DraggerProps: UploadProps = {
    name: "file",
    multiple: false,

    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;
      const storageRef = ref(
        storage,
        `/brand/${currentUser?.uid}/brand-logo/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          // setLoading(false);
          setLogo(downloadURL);
          onSuccess && onSuccess(downloadURL);
        }
      );
    },
    accept: "image/png, image/jpeg",
  };

  const onFinish = (values: any) => {
    form.setFields([
      {
        name: "orgName",
        errors: values.orgName
          ? String(values.orgName).length
            ? []
            : ["Name is required"]
          : ["Name is required"],

        // String(values.orgName).length ? [] : ["Name is required"],
      },
      {
        name: "orgDetails",
        errors: values.orgDetails
          ? String(values.orgDetails).length
            ? []
            : ["Description is required"]
          : ["Description is required"],
      },
    ]);

    if (
      values.orgName &&
      values.orgDetails &&
      String(values.orgName).length &&
      String(values.orgDetails).length
    ) {
      setLoading(true);
      addORGDetails({
        organizationName: values.orgName,
        organizationDescription: values.orgDetails,
        organizationLogoUrl: logo,
      })
        .then((res) => {
          navigate("/verifyOrganization", { replace: true });
        })
        .catch((err) => console.log("backend err", err))
        .finally(() => setLoading(false));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const { Title } = Typography;
  const { TextArea } = Input;

  const formItemLayout = {
    labelCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
  };
  if (!currentUser) {
    return null;
  }

  return (
    <>
      <ProgressBar size={75} />
      <div className={styles.container}>
        <Row justify="center">
          <Col sm={10} xs={22} md={8}>
            <Title>Add your Organisation Details</Title>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
              form={form}
              {...formItemLayout}>
              <Form.Item
                label="Enter your Organisation's Name"
                name="orgName"
                initialValue={""}
                className={styles.label}>
                <Input
                  placeholder="e.g. Cloutflow"
                  className={styles.input}
                  size="large"
                />
              </Form.Item>

              <Form.Item
                label="Enter a Description for your Organisation"
                className={styles.label}
                name="orgDetails">
                <TextArea
                  rows={4}
                  showCount={{
                    formatter: (args) => `${args.count}/${args.maxLength}`,
                  }}
                  maxLength={1000}
                  size="small"
                />
              </Form.Item>

              <Form.Item
                label={
                  <p>
                    Upload your logo{" "}
                    <span className={styles.hint}>(Optional)</span>
                  </p>
                }
                name="logo"
                className={styles.label}>
                {logo ? (
                  <div style={{ position: "relative", width: "max-content" }}>
                    <img src={logo} alt="Logo" className={styles.logo} />
                    <CloseCircleOutlined
                      className={styles.close}
                      onClick={() => setLogo("")}
                    />
                  </div>
                ) : (
                  <>
                    <Dragger {...DraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                    <p className={styles.hint}>
                      Recommended resolution is 640*640 with file size
                    </p>
                  </>
                )}
              </Form.Item>

              <Form.Item>
                {/* <button
                className={styles.submitButton}
                // onClick={() => setIsModalVisible(true)}
              >
                Continue to Verify Organisation
              </button> */}
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  title="Continue to Verify Organisation"
                  className={styles.submitButton}
                  block
                  loading={loading}>
                  <span className={styles.submitButtonText}>
                    Continue to Verify Organisation
                  </span>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddOrg;
