import { Row, Col, Skeleton, Space, Pagination } from "antd";
import React, { useEffect } from "react";

import { Configure } from 'react-instantsearch';
import { InstantSearch, useInstantSearch, useRefinementList, usePagination } from 'react-instantsearch';
import algoliasearch, { SearchClient } from "algoliasearch/lite";

import {
  INSTANT_SEARCH_INDEX_NAME,
  preDefinedFilters,
} from "../../../../../../SaasDiscovery/constants";
import { CreatorType } from "..";

import { useSaasDiscoveryContext } from "../../../../../../SaasDiscovery/context";

import Search from "../../../../../../SaasDiscovery/Search";
import CreatorCard from "../CreatorCard";

type InfluencerViewProps = {
  creator: CreatorType;
  setCreator: React.Dispatch<React.SetStateAction<CreatorType>>;
};

const InfluencerView: React.FC<InfluencerViewProps> = ({
  creator,
  setCreator,
}) => {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY as string
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <InstantSearch
            searchClient={searchClient}
            indexName={INSTANT_SEARCH_INDEX_NAME}
            routing>
            <Configure hitsPerPage={4} />

            <Child
              searchClient={searchClient}
              setCreator={setCreator}
              creator={creator}
            />
          </InstantSearch>
        </Col>
      </Row>
    </div>
  );
};
export default InfluencerView;

const Child = ({
  searchClient,
  setCreator,
  creator,
}: {
  searchClient: SearchClient;
  setCreator: React.Dispatch<React.SetStateAction<CreatorType>>;
  creator: CreatorType;
}) => {
  const { status, results, setIndexUiState } = useInstantSearch();
  const { platform } = useSaasDiscoveryContext();
  const { currentRefinement, refine, nbPages, nbHits } = usePagination();

  // For Profile Completion Modals

  // const [getUserkey, getUserFn] = getUser();
  // const { data: userData } = useQuery(getUserkey, getUserFn);

  useEffect(() => {
    setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      refinementList: {
        ...prevIndexUiState.refinementList,
        socialMediaPlatform: [platform],
        ...preDefinedFilters,
      },
    }));
  }, []);

  useEffect(() => {
    setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      refinementList: {
        socialMediaPlatform: [platform],
        ...preDefinedFilters,
      },
      range: {},
    }));
  }, [platform]);

  useRefinementList({ attribute: "socialMediaPlatform" });

  // To select the required Dataset
  useRefinementList({ attribute: "isSocialMediaConnected" });
  useRefinementList({ attribute: "isSocialMediaRevoked" });
  useRefinementList({ attribute: "isDeliverablePriceApproved" });

  // //for initialization of filters to avoid flickering
  // useRefinementList({ attribute: "influencerNiche" });
  // useRefinementList({ attribute: "influencerGender" });
  // useRefinementList({ attribute: "influencerLocationNames" });
  // useRefinementList({
  //   attribute: `${INSTANT_SEARCH_PLATFORM_FACET}.content.hashtagArray`,
  // });
  // useRange({
  //   attribute: `${INSTANT_SEARCH_PLATFORM_FACET}.user.followerCount`,
  // });

  return (
    <>
      <div>
        <main>
          <Search searchClient={searchClient} inCampaign />
          <Row
            style={{
              height: "40vh",
              overflowY: "scroll",
            }}
            gutter={[15, 20]}>
            {status === "loading" ? (
              <>{/* <Skeleton active /> */}</>
            ) : (
              <>
                {results?.hits?.map((item, idx) => (
                  <Col span={17}>
                    <CreatorCard
                      creator={{
                        id: item._id,
                        name: item?.socialMediaData?.user?.fullname,
                        profilePic: item?.socialMediaData?.user?.profilePicUrl,
                        username: item?.socialMediaData?.user?.name,
                        platform: item?.socialMediaPlatform,
                      }}
                      key={idx}
                      handleClick={(creator: CreatorType) =>
                        setCreator(creator)
                      }
                      isSelected={item._id === creator?.id}
                    />
                  </Col>
                ))}
                <Col span={17}>
                  {results?.hits?.length <= 0 && (
                    <Row
                      style={{
                        backgroundColor: "white",
                        padding: "30px 0px",
                      }}
                      justify="center">
                      <Space direction="vertical" align="center">
                        <img src="/assets/img/empty.png" alt="" />
                        <p style={{ fontWeight: 500, fontSize: "16px" }}>
                          No search results found
                        </p>
                      </Space>
                    </Row>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row
            justify="center"
            style={{
              marginTop: "20px",
              // background: "#FAFAFA",
              // padding: "25px 0",
            }}>
            <Col offset={2}>
              <Pagination
                current={currentRefinement + 1}
                total={nbPages * 21}
                pageSize={21}
                onChange={(page, _) => refine(page - 1)}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </main>
      </div>
    </>
  );
};
