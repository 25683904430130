import { sendRequest } from "./helper";

interface SendOTPProps {
  phoneNumber: string;
}

export const sendOTP = ({ phoneNumber }: SendOTPProps) => {
  return sendRequest("post", `/brand/otp/send-otp/login`, false, {
    phoneNumber,
  }).then((res) => res.data);
};
