import { sendRequest } from "../helper";
import { YoutubeVideo } from "../../typings";

export type getYoutubeFeedResult = {
  status: string;
  message: string;
  data: {
    data: YoutubeVideo[];
    nextPageToken: string | null;
  };
};

export const getYoutubeFeed = (socialMediaKitId: string) => {
  const queryKey = ["reports", "influencer", socialMediaKitId, "youtube"];

  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<getYoutubeFeedResult>(
      "get",
      `/brand/brand-influencer/media-kit/youtube/videos?socialMediaKitId=${socialMediaKitId}&limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
