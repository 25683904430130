import React, { useEffect, useState } from "react";
import { Space, Row, Col, InputNumber } from "antd";
import clsx from "clsx";

import CreatorSection from "../CreatorSection";

import DeliverableSelect from "../ProductView/DeliverableSelect";

import styles from "../ProductView/styles.module.css";

import { CreatorType, SelectPost } from "..";

type ProductViewProps = {
  creator: CreatorType;
  selectedPosts: SelectPost[];
  setSelectedPosts: React.Dispatch<React.SetStateAction<SelectPost[]>>;
};

const ProductCommercial: React.FC<ProductViewProps> = ({
  creator,
  selectedPosts,
  setSelectedPosts,
}) => {
  return (
    <div className={styles.grid}>
      <CreatorSection creator={creator} />

      <div>
        <h3 style={{ marginBottom: "2px" }}>
          Set commercials for the deliverables. (Optional)
        </h3>
        <h5
          style={{
            color: "rgba(0, 0, 0, 0.5)",
          }}>
          This is used to calculate CPV, CPR etc.
        </h5>
      </div>
      <div id="postSelect">
        <Row
          gutter={[8, 16]}
          style={{ height: "30vh", overflowY: "scroll", overflowX: "hidden" }}>
          {selectedPosts.map((item, idx) => {
            return (
              <Col span={6} key={idx}>
                <DeliverableSelect
                  url={item.url}
                  id={item.id_}
                  isSelected={true}
                  type="image"
                  handleClick={() => {}}
                />
                <InputNumber
                  style={{ marginTop: "4px", width: "130px" }}
                  value={item.price}
                  type="number"
                  onChange={(value) => {
                    setSelectedPosts((selectedPosts) =>
                      selectedPosts.map((sItem) => {
                        if (item.id_ === sItem.id_) {
                          return {
                            ...sItem,
                            price: String(value) || undefined,
                          };
                        }
                        return sItem;
                      })
                    );
                  }}
                  status={
                    item.price ? (Number(item?.price) <= 0 ? "error" : "") : ""
                  }
                />
                {item.price && Number(item?.price) <= 0 && (
                  <p style={{ color: "#BD002A", marginTop: "4px" }}>
                    Price Should be greater than zero{" "}
                  </p>
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default ProductCommercial;
