import { sendRequest } from "../helper";

interface getInfluencerReportStatusResponse {
  data: {
    isInfluencerHasReport: boolean;
    isInfluencerInCampaign: boolean;
    countReports: number;
    brandPrice: number;
  };
  message: string;
  status: string;
}

export const getInfluencerReportStatus = ({
  campaignId,
  socialMediaId,
}: {
  campaignId: string;
  socialMediaId: string;
}) => {
  const queryKey = ["report", campaignId, socialMediaId];

  const queryFunction = () =>
    sendRequest<getInfluencerReportStatusResponse>(
      "get",
      `/brand/report-only/influencer/status?campaignId=${campaignId}&socialMediaId=${socialMediaId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
