import { sendRequest } from "../../helper";
import { Orders } from "../../../typings";

export type getOfferSummaryProps = {
  offerIds: string[];
  campaignId: string;
};
type OfferSummary = {
  message: "Offer Reciept";
  status: "SUCCESS";
  data: Orders;
};

export const getOfferSummary = ({
  offerIds,
  campaignId,
}: getOfferSummaryProps) => {
  const queryKey = ["campaign", campaignId, offerIds];

  const queryFunction = () =>
    sendRequest<OfferSummary>(
      "patch",
      `/brand/campaign/offer/finalize-receipt`,
      false,
      {
        offerIds,
        campaignId,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
