import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type transDeliverableProps = {
  campaignId: string;
};

export const transDeliverable = ({ campaignId }: transDeliverableProps) => {
  return sendRequest<CampaignResponse>(
    "put",
    "/brand/campaign/create-campaign-form/trans-deliverables",
    false,
    { campaignId }
  ).then((res) => res.data);
};
