import { Button } from "antd";
import { createRoot } from "react-dom/client";
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  Fragment,
  createElement,
} from "react";

import type { SearchClient } from "algoliasearch/lite";

import { autocomplete, getAlgoliaFacets } from "@algolia/autocomplete-js";
// import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { debounce } from "@algolia/autocomplete-shared";

import { useSaasDiscoveryContext } from "../context";

import { useInstantSearch, useSearchBox, usePagination } from 'react-instantsearch';

import {
  INSTANT_SEARCH_INDEX_NAME,
  INSTANT_SEARCH_USERNAME_FACET,
  preDefinedFilters,
} from "../constants";

import "@algolia/autocomplete-theme-classic";

import FilterChipsAggregate from "./FilterChipsAggregate";

import styles from "./Search.module.css";

// import { getNiche } from "../../../handler/discovery";

interface SearchProps {
  isDisabled?: boolean;
  searchClient: SearchClient;
  inCampaign?: boolean;
}

export type FiltersType = Partial<{
  range: {
    [key: string]: string;
  };
  refinementList: {
    [key: string]: string[];
  };
}>;

const Search: React.FC<SearchProps> = ({
  isDisabled = false,
  searchClient,
  inCampaign = false,
}) => {
  const [query, setQuery] = useState<string>("");
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const panelRootRef = useRef<any>(null);
  const rootRef = useRef<any>(null);
  const [filters, setFilters] = useState<FiltersType>({});

  const { indexUiState, setIndexUiState } = useInstantSearch();
  const { platform } = useSaasDiscoveryContext();

  const { refine: setPage } = usePagination();

  const debouncedSetIndexUiState = debounce(setIndexUiState, 500);

  const plugins = useMemo(() => {
    //Instargam HashTag Suggestion
    // const instagramHashtagSuggestionPlugin = createQuerySuggestionsPlugin({
    //   searchClient,
    //   indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,

    //   getSearchParams() {
    //     return { hitsPerPage: 5 };
    //   },

    //   transformSource({ source }) {
    //     return {
    //       ...source,
    //       onSelect({ item }) {
    //         debouncedSetIndexUiState((prevIndexUiState) => ({
    //           query: item.query,
    //           refinementList: {
    //             ...prevIndexUiState.refinementList,
    //             socialMediaPlatform: ["instagram"],
    //             isSocialMediaConnected: ["true"],
    //             isSocialMediaRevoked: ["false"],
    //             isDeliverablePriceApproved: ["true"],
    //           },
    //         }));
    //       },

    //       getItems(params) {
    //         if (!params.state.query) {
    //           return [];
    //         }
    //         return source.getItems(params);
    //       },
    //       templates: {
    //         ...source.templates,
    //         header({ items }) {
    //           if (items.length === 0) {
    //             return <Fragment />;
    //           }
    //           return (
    //             <Fragment>
    //               <span className="aa-SourceHeaderTitle">#Tags</span>
    //               <span className="aa-SourceHeaderLine" />
    //             </Fragment>
    //           );
    //         },
    //         item({ item }) {
    //           return <div>{item.query}</div>;
    //         },
    //       },
    //     };
    //   },
    // });

    const profileSuggestionPlugin = {
      getSources() {
        return [
          {
            sourceId: INSTANT_SEARCH_INDEX_NAME,
            onActive({ item, setQuery }: { item: any; setQuery: any }) {
              setQuery(item?.label);
            },
            async onSelect({ item, setIsOpen }: { item: any; setIsOpen: any }) {
              setIsOpen(false);

              debouncedSetIndexUiState((prevIndexUiState) => ({
                query: item.label,
                refinementList: {
                  ...prevIndexUiState.refinementList,
                  ...preDefinedFilters,
                  socialMediaPlatform: [platform],
                },
              }));
            },
            getItems({ query }: { query: any }) {
              return getAlgoliaFacets({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX_NAME,
                    facet: INSTANT_SEARCH_USERNAME_FACET,
                    params: {
                      facetQuery: query,
                      maxFacetHits: 5,
                      filters: `socialMediaPlatform:${platform} AND isSocialMediaConnected:true AND isSocialMediaRevoked:false AND isDeliverablePriceApproved:true`,
                    },
                  },
                ],
              });
            },
            templates: {
              header({ items }: { items: any[] }) {
                if (items.length === 0) {
                  return <Fragment />;
                }
                return (
                  <Fragment>
                    <span className="aa-SourceHeaderTitle">Profile</span>
                    <span className="aa-SourceHeaderLine" />
                  </Fragment>
                );
              },
              item({ item }: { item: any }) {
                return <div>{item?.label}</div>;
              },
            },
          },
        ];
      },
    };

    return [profileSuggestionPlugin];
  }, [platform]);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      container: autocompleteContainer.current,
      initialState: { query: indexUiState.query },
      openOnFocus: true,
      placeholder: "Search for influencers",
      detachedMediaQuery: "none",
      plugins: plugins,
      onReset() {
        debouncedSetIndexUiState((prevIndexUiState) => ({
          query: "",
          refinementList: {
            ...prevIndexUiState.refinementList,
            ...preDefinedFilters,
            socialMediaPlatform: [platform],
          },
        }));
      },
      onSubmit({ state }) {
        debouncedSetIndexUiState((prevIndexUiState) => ({
          query: state.query,
          refinementList: {
            ...prevIndexUiState.refinementList,
            ...preDefinedFilters,
            socialMediaPlatform: [platform],
          },
        }));
      },

      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setQuery(state.query);
        }
      },
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    return () => autocompleteInstance.destroy();
  }, [plugins]);

  useEffect(() => {
    if (indexUiState.query && indexUiState.query !== query) {
      setQuery(indexUiState.query);
    }
  }, [indexUiState.query]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    const updatedFilters: typeof filters = {};
    if (indexUiState.refinementList) {
      let {
        isDeliverablePriceApproved: _,
        isSocialMediaConnected: __,
        isSocialMediaRevoked: ___,
        socialMediaPlatform: ____,
        ...updatedListExceptBooleans
      } = indexUiState.refinementList;
      updatedFilters["refinementList"] = updatedListExceptBooleans;
    }
    if (indexUiState.range) {
      updatedFilters["range"] = indexUiState.range;
    }
    setFilters(updatedFilters);
  }, [indexUiState]);

  // to render search box empty  widget from algolia
  useSearchBox();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}>
        <div
          style={{ width: "90%", margin: 6, marginRight: 0, borderRadius: 0 }}
          ref={autocompleteContainer}
          className={inCampaign ? styles.searchContainer : ""}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIndexUiState((indexUiState) => ({
                ...indexUiState,
                query,
                refinementList: {
                  ...indexUiState.refinementList,
                  ...preDefinedFilters,
                  socialMediaPlatform: [platform],
                },
              }));
            }
          }}
        />
        {!inCampaign && (
          <Button
            onClick={() => {
              setIndexUiState((indexUiState) => ({
                ...indexUiState,
                query,
                refinementList: {
                  ...indexUiState.refinementList,
                  ...preDefinedFilters,
                  socialMediaPlatform: [platform],
                },
              }));
            }}
            type="primary"
            style={{
              height: "56px",
              borderRadius: "0 8px 8px 0",
              width: "130px",
            }}>
            Search
          </Button>
        )}
      </div>
      <FilterChipsAggregate
        filters={filters}
        setIndexUIState={debouncedSetIndexUiState}
      />
    </>
  );
};

export default Search;
