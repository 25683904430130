import React, { useState } from "react";
import { Spin, Row, Col, Tag, Button, Empty, Table } from "antd";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { ColumnsType } from "antd/es/table";

import InviteModal from "./InviteModal";
import DisabledPopOver from "../../shared/DisabledPopOver";

import { getInvitations } from "../../../handler/organization";
import { getAppState } from "../../../handler/getAppState";
import { getUser } from "../../../handler/getUser";
import { getUserUniversal } from "../../../handler/getUserUniversal";

import styles from "../styles.module.css";

const getTagColor = (status: string) => {
  switch (status) {
    case "Invited":
      return "processing";
    case "Accepted":
      return "success";
    case "Rejected":
      return "error";
    default:
      return "default";
  }
};

interface DataType {
  key: React.Key;
  email: string;
  invitedOn: string;
  status: string;
}

const Sent = ({ isTeam }: { isTeam: boolean }) => {
  const [queryKey, queryFn] = getInvitations({ send: true });

  const [userStateKey, userStateFn] = getAppState();

  const { data: userStateData } = useQuery(userStateKey, userStateFn);

  const { data: invitationData, isLoading } = useQuery(queryKey, queryFn);

  const [inviteVisible, setInviteVisible] = useState<boolean>(false);

  const [userKey, userFn] = getUser();

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    userKey,
    userFn
  );

  const canInviteTeam =
    userStateData?.data?.tier?.featureLimit?.canInviteTeamMembers || false;
  const canInviteAgency =
    userStateData?.data?.tier?.featureLimit?.canInviteAgencyMembers || false;

  const isManagedAccount = userData?.data?.accountType === "Managed";

  const isInviteButtonDisabled = !isManagedAccount
    ? isTeam
      ? !canInviteTeam
      : !canInviteAgency
    : false;

  const columns: ColumnsType<DataType> = [
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => <p className={styles.headerItem}>{email}</p>,
    },
    {
      title: "Invited On",
      dataIndex: "invitedOn",
      key: "invitedOn",
      render: (_, { invitedOn }) => (
        <p className={styles.headerItem}>
          {" "}
          {format(new Date(invitedOn), "d LLL, yyyy")}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <p className={styles.headerItem}>
          {" "}
          <Tag color={getTagColor(status)}>{status}</Tag>
        </p>
      ),
    },
  ];

  if (isLoading || isLoadingUser) {
    return (
      <Row justify="center" style={{ marginTop: "150px" }}>
        <Spin />
      </Row>
    );
  }

  const userDomain = userData?.data?.emailId?.split("@").at(-1) || "";

  const resultData = (invitationData?.data || [])?.filter((item) => {
    return isTeam
      ? item.emailId.includes(userDomain)
      : !item.emailId.includes(userDomain);
  });

  // console.log(userDomain, invitationData?.data);
  return (
    <div style={{ padding: 20 }}>
      <Row justify="end">
        <Col
        //   span={3}
        //   offset={20}
        >
          <DisabledPopOver isDisabled={isInviteButtonDisabled}>
            <Button
              disabled={isInviteButtonDisabled}
              type="primary"
              style={{ borderRadius: "4px", marginBottom: "20px" }}
              size="large"
              onClick={() => setInviteVisible(true)}>
              Invite {isTeam ? "Team" : "External Collaborators"}
            </Button>
          </DisabledPopOver>
        </Col>
      </Row>
      <Col span={24}>
        {/* <Row className={styles.header}>
          <Col span={8} className={styles.headerItem}>
            Email
          </Col>
          <Col span={8} className={styles.headerItem}>
            Invited On
          </Col>
          <Col span={8} className={styles.headerItem}>
            Status
          </Col>
        </Row> */}
        {
          (resultData || [])?.length > 0 && (
            <Table
              columns={columns}
              dataSource={resultData?.map((item) => ({
                key: item.emailId,
                email: item.emailId,
                invitedOn: item.createdDateTime,
                status: item.status,
              }))}
              pagination={false}
              scroll={{ y: 440 }}
            />
          )
          //   .map((item) => {
          //     return (
          //       <Row style={{ padding: "16px 10px 16px 44px" }}>
          //         <Col span={8} className={styles.headerItem}>
          //           {item.emailId}
          //         </Col>
          //         <Col span={8} className={styles.headerItem}>
          //           {format(new Date(item.createdDateTime), "d LLL, yyyy")}
          //         </Col>
          //         <Col span={8} className={styles.headerItem}>
          //           <Tag color={getTagColor(item.status)}>{item.status}</Tag>
          //         </Col>
          //       </Row>
          //     );
          //   })
        }
        {resultData.length === 0 && (
          <Empty description="No Invites" style={{ marginTop: "50px" }} />
        )}
      </Col>
      <InviteModal
        isModalOpen={inviteVisible}
        handleClose={() => setInviteVisible(false)}
        queryKeyToInvalidate={queryKey}
        canInviteAgency={isTeam ? false : canInviteAgency || isManagedAccount}
      />
    </div>
  );
};

export default Sent;
