import { Image, Tag, Dropdown, Menu } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { FileTextOutlined, DownloadOutlined } from "@ant-design/icons";
import { BsCheckAll } from "react-icons/bs";
import { BiTime, BiChevronDown } from "react-icons/bi";
import { CgCloseO } from "react-icons/cg";

import styles from "./card.module.css";
import { useUserAuth } from "../../../../../context/userAuthcontext";
import { format } from "date-fns";
import clsx from "clsx";
interface MediaCardProps {
  item: {
    mediaType: string;
    mediaUrl: string;
    mediaName: string;
    cardTimestamp?: string;
    author: string;
    time: string;
    msg?: string;
    sending?: boolean;
    isDeleted: boolean;
  };
  deleteMessage: () => void;
}

const MediaCard: React.FC<MediaCardProps> = ({ item, deleteMessage }) => {
  const { currentUser } = useUserAuth();
  return (
    <div
      className={clsx(
        currentUser?.uid === item.author
          ? styles.messageMe
          : styles.messageOther,
        item.isDeleted && styles.deletedMessage
      )}>
      {currentUser?.uid === item.author && !item.isDeleted && (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: "2",
                  label: <p onClick={() => deleteMessage()}>Delete Message</p>,
                },
              ]}
              onClick={(event) => {
                event.domEvent.stopPropagation();
              }}
            />
          }>
          <BiChevronDown className={styles.iconPosition} />
        </Dropdown>
      )}
      {item.isDeleted ? (
        <p className={styles.deletedMessage}>
          <CgCloseO style={{ marginRight: "4px" }} />
          Message Deleted
        </p>
      ) : (
        <>
          {item.mediaType === "video" && (
            <div
              style={{
                width: "237.5px",
                height: "384px",
                padding: "10px",
                // border: "1px solid gray",
              }}>
              <ReactPlayer
                controls={true}
                url={item.mediaUrl}
                volume={1}
                width="100%"
                height="100%"
                onReady={() => console.log("ready now")}
              />
            </div>
          )}
          {item.mediaType === "image" && (
            <Image
              width={200}
              src={item.mediaUrl}
              alt=""
              style={{ borderRadius: "4px", padding: "10px" }}
            />
          )}
          {item.mediaType === "application" && (
            <Tag
              color="blue"
              style={{
                width: "calc(100%-20px)",
                margin: "10px",
                // backgroundColor: "#eeeeee",
                borderRadius: "4px",
                display: "flex",
                padding: "10px 10px",
                alignItems: "center",
                justifyContent: "space-between",
                // marginBottom: "15px",
              }}>
              <p
                style={{
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                  overflow: "hidden",
                }}>
                {item.mediaName}
              </p>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <FileTextOutlined style={{ fontSize: "20px" }} />
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "20px",
                    color: "blue",
                  }}
                  href={item.mediaUrl}
                  rel="noreferrer"
                  target="_blank"
                  download>
                  <DownloadOutlined />
                </a>
              </div>
            </Tag>
          )}
          <span
            className={
              currentUser?.uid !== item.author
                ? styles.bottomRight
                : styles.bottomLeft
            }>
            <span
              className={
                currentUser?.uid === item.author
                  ? styles.timeForDocWhite
                  : styles.timeForDoc
              }>
              {format(new Date(item.time), "hh:mm bb")}
            </span>
            {currentUser?.uid === item.author &&
              (item.sending ? (
                <BiTime size={16} style={{ color: "white" }} />
              ) : (
                <BsCheckAll style={{ color: "white" }} size={16} />
              ))}
          </span>

          {item?.msg && (
            <h4
              style={{ padding: "6px" }}
              className={
                currentUser?.uid === item.author
                  ? styles.messageWhite
                  : styles.message
              }>
              {item?.msg}
            </h4>
          )}
        </>
      )}
    </div>
  );
};

export default MediaCard;
