import { sendRequest } from "../helper";

import { ProductCatalogue } from "../../typings";

type Result = {
  status: string;
  message: string;
  data: ProductCatalogue[];
};

export const getProductCatalogue = () => {
  const queryKey = ["smart-replies", "product-catalogue"];
  const queryFunction = () =>
    sendRequest<Result>("get", `/brand/products/product-catalogue`, false).then(
      (res) => res.data
    );
  return [queryKey, queryFunction] as const;
};
