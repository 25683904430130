import React, { useRef, useState, useEffect } from "react";
import Lottie from "react-lottie";
import {
  Space,
  Row,
  Col,
  Input,
  Spin,
  Table,
  Checkbox,
  Divider,
  Button,
} from "antd";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { FaLock, FaLockOpen } from "react-icons/fa6";
import { useInfiniteQuery, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import animationData from "../../assets/lottie/Rocket.json";
import { ColumnsType } from "antd/es/table";

import Option from "../../shared/UI/Option";
import ReportButton from "../../Campaign/Report/ReportButton";
import CustomDropDown from "../../shared/Custom/DropDown";
// import CreatorCard from "./CreatorCard";
import NoDataScreen from "../InvitedInfluencers/NoDataScreen";
import PurchaseCommunityCredits from "../../Credits/PurchaseCommunityCredits";

import { getCommunityInfluencers } from "../../../handler/community";

import styles from "./community.module.css";

import {
  capitalizeFirstLetter,
  formatData,
  getDefaultLottieOptions,
} from "../../../utils";
import socialMediaUrlGen from "../../../utils/socialMediaUrlGen";

import { BsFilterLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

interface DataType {
  dp: string;
  username: string;
  followers: number;
  // campaigns: number;
  influencerId: string;
  isLocked: boolean;
  platform: "instagram" | "youtube";
  joiningDate: string;
  name: string;
}

const Community = () => {
  const [isPurchaseModalVisible, setIsPurchaseModalVisible] =
    useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [sortType, setSortType] = useState<"createdDateTime" | "followers">(
    "createdDateTime"
  );

  type PlatformConstant = "instagram" | "youtube";
  type AccountStatusConstant = "connected" | "disconnected" | "session-expired";

  const [filters, setFilters] = useState<{
    platform: PlatformConstant[];
    accountStatus: AccountStatusConstant[];
  }>({
    platform: [],
    accountStatus: [],
  });

  const [influencersKey, influencersFn] = getCommunityInfluencers({
    search: searchQuery,
    limit: 20,
    platform: filters.platform,
    sortBy: sortType,
    accountStatus: filters.accountStatus,
  });

  const {
    data: InfluencerCampaignsData,
    isLoading: isInfluencerCampaignDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(influencersKey, influencersFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.community?.length ? lastPage.data.pageNo + 1 : null,
  });

  // const [queryKey, queryFn] = getCommunityAggregate();
  // const { data, isLoading: isAggregateLoading } = useQuery(queryKey, queryFn);

  const [loading, setLoading] = useState<boolean>(true);

  const firstRender = useRef(true);

  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { dp, name, isLocked, influencerId }) => (
        <Space size={10} align="center">
          <div className={styles.profilePictureContainer}>
            <img src={dp ?? ""} className={styles.profile} alt="profile" />
            <div className={styles.lockContainer}>
              {isLocked ? <FaLock size={10} /> : <FaLockOpen size={10} />}
            </div>
          </div>
          <p
            className={styles.name}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              if (isLocked) {
                return setIsPurchaseModalVisible(true);
              }
              navigate(`/influencerAnalysis/${influencerId ?? " "}`);
            }}>
            {name ?? ""}
          </p>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, { username, platform }) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {/* {platform === "instagram" ? (
            <FaInstagram size={15} color="rgba(0, 0, 0, 0.70)" />
          ) : (
            <FaYoutube size={15} color="rgba(0, 0, 0, 0.70)" />
          )} */}
          <a
            href={socialMediaUrlGen({
              userNameOrChannelId: username,
              platform,
            })}>
            <p className={styles.username}>{username ?? ""}</p>
          </a>
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      render: (_, record) => (
        <span>{capitalizeFirstLetter(record.platform)}</span>
      ),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, display: "grid", gap: 10 }}>
          <p>Platform</p>
          {["instagram", "youtube"].map((platformItem) => (
            <Checkbox
              checked={filters.platform.includes(
                platformItem as PlatformConstant
              )}
              onChange={(event) => {
                let newFiltersPlatform = [...filters.platform];
                if (event.target.checked) {
                  newFiltersPlatform = [
                    ...newFiltersPlatform,
                    platformItem as PlatformConstant,
                  ];
                } else if (
                  newFiltersPlatform.includes(platformItem as PlatformConstant)
                ) {
                  newFiltersPlatform = newFiltersPlatform.filter(
                    (item) => item !== platformItem
                  );
                }
                setFilters((filters) => ({
                  ...filters,
                  platform: newFiltersPlatform,
                }));
              }}
              style={{ margin: 0 }}>
              {capitalizeFirstLetter(platformItem)}
            </Checkbox>
          ))}

          <Divider type="horizontal" style={{ marginBlock: 5 }} />

          <p>Account Status</p>
          {["connected", "disconnected", "session-expired"].map(
            (accountStatusItem) => (
              <Checkbox
                checked={filters.accountStatus.includes(
                  accountStatusItem as AccountStatusConstant
                )}
                style={{ margin: 0 }}
                onChange={(event) => {
                  let newFiltersAccountStatus = [...filters.accountStatus];
                  if (event.target.checked) {
                    newFiltersAccountStatus = [
                      ...newFiltersAccountStatus,
                      accountStatusItem as AccountStatusConstant,
                    ];
                  } else if (
                    newFiltersAccountStatus.includes(
                      accountStatusItem as AccountStatusConstant
                    )
                  ) {
                    newFiltersAccountStatus = newFiltersAccountStatus.filter(
                      (item) => item !== accountStatusItem
                    );
                  }
                  setFilters((filters) => ({
                    ...filters,
                    accountStatus: newFiltersAccountStatus,
                  }));
                }}>
                {capitalizeFirstLetter(accountStatusItem)}
              </Checkbox>
            )
          )}

          <div style={{ marginTop: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedKeys([JSON.stringify(filters)]);
                confirm();
              }}>
              OK
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => {
        // const filterData = JSON.parse(value);
        // if (filterData.name) {
        //   return record.name.toLowerCase().includes("name");
        // }
        // if (filterData.age) {
        //   return record.age > 30;
        // }
        return true; // If no filters are selected, show all data
      },
    },
    {
      title: "Followers",
      dataIndex: "followers",
      key: "followers",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { followers }) => (
        <p className={styles.followers}> {formatData(followers ?? 0, "a")}</p>
      ),
    },
    {
      title: "Joined On",
      dataIndex: "joiningDate",
      key: "joiningDate",

      render: (_, { joiningDate }) => (
        <p className={styles.followers}>
          {" "}
          {format(new Date(joiningDate), "d MMM, RRRR")}
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (!isInfluencerCampaignDataLoading && firstRender.current) {
      firstRender.current = false;
      setLoading(false);
    }
  }, [isInfluencerCampaignDataLoading]);

  if (loading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <div className={styles.container}>
      {true ? (
        <>
          {/* <Space size={15}>
            <Option
              title={`Instagram (${data?.data?.instagramCount})`}
              handleClick={() => setPlatform("instagram")}
              isSelected={platform === "instagram"}
            />
            <Option
              title={`Youtube (${data?.data?.youtubeCount})`}
              handleClick={() => setPlatform("youtube")}
              isSelected={platform === "youtube"}
            />
          </Space> */}
          <div className={styles.pX20}>
            <Row justify="space-between" style={{ marginBottom: 20 }}>
              <Col span={11} className={styles.inputContainer}>
                <Space size={2} align="center" style={{ width: "100%" }}>
                  <HiMagnifyingGlass size={18} color="rgba(0, 0, 0, 0.45)" />
                  <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value || "")}
                    onPressEnter={() => setSearchQuery(searchQuery)}
                    placeholder={"Search by name or username"}
                    className={styles.input}
                    size="large"
                    style={{
                      borderColor: "transparent",
                      boxShadow: "none",
                      width: "100%",
                      minWidth: "400px",
                    }}
                  />
                </Space>
                <ReportButton
                  type="primary"
                  onClick={() => setSearchQuery(searchQuery)}
                  style={{ padding: "10px 20px " }}>
                  Search
                </ReportButton>
              </Col>

              <Col span={2} style={{ justifyContent: "end", display: "flex" }}>
                <CustomDropDown
                  items={[
                    {
                      label: "Recently Added",
                      key: "1",
                      onClick: () => setSortType("createdDateTime"),
                    },

                    {
                      label: "Followers",
                      key: "2",
                      onClick: () => setSortType("followers"),
                    },
                  ]}>
                  <a
                    onClick={(e) => e.preventDefault()}
                    className={styles.optionsContainer}>
                    <span style={{ textDecorationLine: "underline" }}>
                      Sort By
                    </span>
                    <BsFilterLeft color="#00000080" size={18} />
                  </a>
                </CustomDropDown>
              </Col>
            </Row>
            <div className={styles.g10}>
              {/* <section className={styles.tableHeader}>
                <span>Profile</span>
                <span>Username</span>
                <span>Followers</span>
                <span>Campaigns Done</span>
                <span></span>
              </section> */}
              <div
                className={styles.g10}
                style={{
                  height: "auto",
                  overflowY: "scroll",
                }}>
                <InfiniteScroll
                  loadMore={() => fetchNextPage()}
                  className={styles.g10}
                  style={{ alignItems: "flex-start" }}
                  hasMore={hasNextPage}
                  loader={
                    <Row justify="center">
                      <Spin />
                    </Row>
                  }
                  useWindow={false}>
                  {isInfluencerCampaignDataLoading ? (
                    <Row justify="center">
                      <Spin />
                    </Row>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={
                        InfluencerCampaignsData?.pages?.flatMap((pageItem) =>
                          pageItem.data?.community?.map((dataItem) => {
                            return {
                              dp: dataItem?.profilePicUrl,
                              username: dataItem?.socialMediaName,
                              followers: dataItem?.followers,
                              joiningDate: dataItem?.createdDateTime,
                              // campaigns: dataItem?.campaigns,
                              influencerId: dataItem?.influencerId,
                              isLocked: dataItem?.isProfileLockedForBrand,
                              platform: dataItem?.platform,
                              name: dataItem?.name || "",
                            } as DataType;
                          })
                        ) || []
                      }
                      scroll={{ y: 400 }}
                      pagination={false}
                    />
                  )}
                  {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
                    pageItem.data?.community?.map((dataItem) => (
                      <CreatorCard creatorData={dataItem} platform={platform} />
                    ))
                  )} */}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoDataScreen />
      )}
      <PurchaseCommunityCredits
        visible={isPurchaseModalVisible}
        handleClose={() => setIsPurchaseModalVisible(false)}
      />
    </div>
  );
};

export default Community;
