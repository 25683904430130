import { Product } from "../typings";
import { Deliverable } from "../components/Campaign/Create/Deliverables/Deliverables";

export const isProductDeletable = (
  product: Product,
  deliverableList: Deliverable[]
) => {
  if (deliverableList.length === 0) {
    return true;
  }

  return !deliverableList.some((deliverable) =>
    deliverable?.products?.some((productItem) => productItem === product._id)
  );
};

export const isProductToggleDisabled = (deliverableList: Deliverable[]) => {
  if (deliverableList.length === 0) {
    return false;
  }

  return deliverableList.some((deliverable) => {
    if (deliverable!.products) {
      return deliverable!.products.length > 0;
    }
    return false;
  });
};
