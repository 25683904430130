import { sendRequest } from "../helper";
import { Organization } from "../../typings";

type OrganizationResponse = {
  data: {
    accepted: Organization[];
    invitations: Organization[];
    brandId: string;
    accessLevel: string;
  };
  status: string;
  message: string;
};

export const getOrgAppState = () => {
  const queryKey = ["org", "app-state"];

  const queryFunction = () =>
    sendRequest<OrganizationResponse>(
      "get",
      `/brand/me/org/app-state`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
