import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { Row, Col, Skeleton, Space, Button } from "antd";
import { useState, useEffect } from "react";

import {
  INSTANT_SEARCH_SAAS_INDEX_NAME,
  INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET,
} from "../SaasDiscovery/constants";
// import { RangeSlider } from "./RangeSelector";

import Filters from "./Filters";

import CreatorOnboardingCard from "./CreatorOnboardingCard";

import Search from "./Search";

import {
  useSaasDiscoveryContext,
  SassDiscoveryContextProvider,
} from "../SaasDiscovery/context";

import Navbar from "../shared/Navbar";

import AlgoliaPagination from "../SaasDiscovery/AlgoliaPagination";

import { InstantSearch, useInstantSearch, useRefinementList, useRange } from 'react-instantsearch';

const AlgoliaSearch = () => {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID_PROD as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY_PROD as string
  );

  return (
    <>
      <SassDiscoveryContextProvider>
        <InstantSearch
          searchClient={searchClient}
          indexName={INSTANT_SEARCH_SAAS_INDEX_NAME}
          routing>
          <div style={{ background: "#FAFAFA" }}>
            <Child searchClient={searchClient} />
          </div>
        </InstantSearch>
      </SassDiscoveryContextProvider>
    </>
  );
};
const Child = ({ searchClient }: { searchClient: SearchClient }) => {
  const { status, results, setIndexUiState } = useInstantSearch();
  const { platform, setSelectedCreatorList, showMetrics } =
    useSaasDiscoveryContext();

  useEffect(() => {
    // setIndexUiState((prevIndexUiState) => ({
    //   ...prevIndexUiState,
    //   refinementList: {
    //     socialMediaPlatform: [platform],
    //     ...preDefinedFilters,
    //   },
    //   range: {},
    // }));
    setSelectedCreatorList([]);
  }, [platform]);

  // To select the required Dataset

  //for initialization of filters to avoid flickering
  useRefinementList({ attribute: "cities" });
  useRefinementList({
    attribute: `hashtagArray`,
  });
  useRefinementList({
    attribute: `mainCategories`,
  });
  useRefinementList({
    attribute: `isPhoneNumber`,
  });

  useRange({
    attribute: `followers`,
  });

  useEffect(() => {
    if (showMetrics.showPhoneNumber) {
      return setIndexUiState((prevIndexUiState) => ({
        ...prevIndexUiState,
        refinementList: {
          ...prevIndexUiState.refinementList,
          [INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET]: ["true"],
        },
        range: prevIndexUiState.range,
      }));
    }

    return setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      refinementList: {
        ...prevIndexUiState.refinementList,
        [INSTANT_SEARCH_SAAS_PHONE_NUMBER_FACET]: ["false", "true"],
      },
      range: {},
    }));
  }, [showMetrics.showPhoneNumber]);

  return (
    <>
      <div>
        <Navbar selectedItem="discover" />
        <Row>
          <Col
            span={5}
            style={{
              background: "#FFFFFF",
              borderRight: "1px solid #DCDCDC",
            }}>
            <Filters />
          </Col>
          <Col span={17} offset={1}>
            <main>
              <section style={{ padding: "25px 0" }}>
                <h2>Explore Influencers</h2>
                <Search isDisabled={false} searchClient={searchClient} />
              </section>

              {status === "loading" ? (
                <>
                  <Skeleton active />
                  <Skeleton active />
                </>
              ) : (
                <>
                  <Row
                    style={{
                      height: "73vh",
                      overflowY: "scroll",
                    }}
                    gutter={[15, 20]}>
                    {results?.hits?.map((item, idx) => (
                      <Col span={8}>
                        <CreatorOnboardingCard creatorData={item} key={idx} />
                      </Col>
                    ))}
                    <Col span={24}>
                      {results?.hits?.length <= 0 && (
                        <Row
                          style={{
                            backgroundColor: "white",
                            padding: "30px 0px",
                          }}
                          justify="center">
                          <Space direction="vertical" align="center">
                            <img src="/assets/img/empty.png" alt="" />
                            <p style={{ fontWeight: 500, fontSize: "16px" }}>
                              No search results found
                            </p>
                          </Space>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </main>
            <Row
              justify="center"
              style={{
                marginTop: "30px",
                background: "#FAFAFA",
                // padding: "25px 0",
              }}>
              <Col offset={2}>
                <AlgoliaPagination />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AlgoliaSearch;
