import { sendRequest } from "../helper";

interface editUserDetailsProps {
  collectionId: string;
  collectionName: string;
  data: string[] | { numberOf: string; number: string }[];
  type: string;
}

export const editUserDetails = ({
  collectionId,
  collectionName,
  data,
  type,
}: editUserDetailsProps) => {
  return sendRequest("put", `/brand/global-tracking-influencer`, false, {
    collectionName,
    collectionId,
    [type]: data,
  }).then((res) => res.data);
};
