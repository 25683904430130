import { sendRequest } from ".././helper";

import { tierDetail } from "../../typings";

interface PlanDetailsProps {
  cycle: "year" | "month";
  isAll: boolean;
}

type PlanResult = {
  status: string;
  message: string;
  data: tierDetail[];
};

export const getPlanDetails = ({ cycle, isAll }: PlanDetailsProps) => {
  const queryKey = ["plans", cycle, isAll];
  const queryFunction = () =>
    sendRequest<PlanResult>(
      "get",
      `/brand/saas-billing/subscriptions?cycle=${cycle}&isAll=${isAll}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
