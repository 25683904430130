import React from "react";
import { Space, Row, Spin, message, Popconfirm } from "antd";
import Cookies from "universal-cookie";
import clsx from "clsx";
import { useQuery, useMutation } from "react-query";

import { auth } from "../../../firebase";

import ReportButton from "../../Campaign/Report/ReportButton";

import { getInvitations } from "../../../handler/organization";
import { getUserUniversal } from "../../../handler/getUserUniversal";
import { leaveOrg } from "../../../handler/leaveOrg";

import { trimData } from "../../../utils";

import styles from "./styles.module.css";

const OrganizationManage = () => {
  const [getInvitationsKey, getInvitationsFn] = getInvitations({
    status: "Accepted",
    send: false,
  });

  const [userKey, userFn] = getUserUniversal();

  const { data: orgData, isLoading } = useQuery(
    getInvitationsKey,
    getInvitationsFn
  );

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    userKey,
    userFn
  );

  const cookies = new Cookies();

  const selectedBrandId = cookies.get("brandId");

  const leaveOrgMutation = useMutation(leaveOrg, {
    onSuccess: () => {
      message.info("Left Organization");
      handleOrgChange("default");
    },
    onError: () => {
      message.error("Something Went wrong while leaving");
    },
  });

  const handleOrgChange = (val: string) => {
    if (val === "default") {
      cookies.remove("brandId", {
        path: "/",
      });
    } else {
      cookies.set("brandId", val, {
        path: "/",
        maxAge: 360 * 24 * 60 * 60,
      });
    }
    // to go to homepage
    window.open(process.env.REACT_APP_DEPLOYMENT_URL, "_self", "noreferrer");
  };

  if (isLoading || isLoadingUser) {
    return (
      <Row justify="center" style={{ margin: "100px 0" }}>
        <Spin size="large" />
      </Row>
    );
  }

  const firebaseUid = auth?.currentUser?.uid || "";

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.rowItem}>
          <th
            className={clsx(styles.rowCell, styles.headCell)}
            style={{ width: "45%" }}>
            Organization
          </th>
          <th
            className={clsx(styles.rowCell, styles.headCell)}
            style={{ width: "20%", textAlign: "left" }}>
            Role
          </th>
          <th
            className={clsx(styles.rowCell, styles.headCell)}
            style={{ width: "20%", textAlign: "center" }}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {userData?.data?.isOwnDefaultOrgCreated && (
          <tr className={styles.rowItem}>
            <td className={styles.rowCell} style={{ width: "45%" }}>
              <div
                style={{ display: "flex", gap: "6px", alignItems: "center" }}>
                <img
                  src={userData?.data?.businessDetails?.logoUrl}
                  alt="orgLogo"
                  style={{
                    objectFit: "cover",
                    height: "28px",
                    width: "28px",
                    borderRadius: "999px",
                  }}
                />
                <p>
                  {userData?.data?.businessDetails?.name}{" "}
                  {selectedBrandId === userData?.data?._id && (
                    <span className={styles.connected}>
                      <span className={styles.connectedLabel} />
                      <span>Current</span>
                    </span>
                  )}
                  {/* <br /> Organization{" "} */}
                </p>
              </div>
            </td>
            <td className={styles.rowCell} style={{ width: "20%" }}>
              {"Owner"}
            </td>
            <td className={styles.rowCell} style={{ width: "20%" }}>
              <Space size={8}>
                <ReportButton
                  title="Switch"
                  type="primary"
                  style={{ padding: "6px 12px" }}
                  disabled={Boolean(selectedBrandId === userData?.data?._id)}
                  onClick={() => handleOrgChange(userData?.data?._id || "")}>
                  Switch
                </ReportButton>

                <ReportButton danger style={{ padding: "6px 12px" }} disabled>
                  Leave
                </ReportButton>
              </Space>
            </td>
          </tr>
        )}
        {orgData?.data?.map((orgItem) => {
          return (
            <tr className={styles.rowItem}>
              <td className={styles.rowCell} style={{ width: "45%" }}>
                <div
                  style={{ display: "flex", gap: "6px", alignItems: "center" }}>
                  <img
                    src={orgItem?.brandUser?.businessDetails?.logoUrl}
                    alt="orgLogo"
                    style={{
                      objectFit: "cover",
                      height: "28px",
                      width: "28px",
                      borderRadius: "999px",
                    }}
                  />
                  <p>
                    {orgItem?.brandUser?.businessDetails?.name}{" "}
                    {selectedBrandId === orgItem?.brandId && (
                      <span className={styles.connected}>
                        <span className={styles.connectedLabel} />
                        <span>Current</span>
                      </span>
                    )}
                    <br /> Invited by{" "}
                    {trimData({
                      value: orgItem?.brandUser?.name || "",
                      limit: 40,
                    })}
                  </p>
                </div>
              </td>
              <td className={styles.rowCell} style={{ width: "20%" }}>
                {orgItem?.brandUser?._id === firebaseUid ? "Owner" : "Member"}
              </td>
              <td className={styles.rowCell} style={{ width: "20%" }}>
                <Space size={8}>
                  <ReportButton
                    title="Switch"
                    type="primary"
                    style={{ padding: "6px 12px" }}
                    disabled={selectedBrandId === orgItem?.brandId}
                    onClick={() => handleOrgChange(orgItem?.brandId)}>
                    Switch
                  </ReportButton>
                  <Popconfirm
                    title={
                      "Are You sure that you want to leave this Organization ?"
                    }
                    okText="Yes"
                    cancelText="No"
                    // placement="bottom"
                    arrowPointAtCenter
                    onConfirm={() => {
                      leaveOrgMutation.mutate({ orgId: orgItem?._id });
                    }}
                    okButtonProps={{
                      loading:
                        leaveOrgMutation.variables?.orgId === orgItem?._id &&
                        leaveOrgMutation.isLoading,
                    }}>
                    <ReportButton
                      danger
                      style={{ padding: "6px 12px" }}
                      disabled={orgItem?.brandUser?._id === firebaseUid}
                      loading={
                        leaveOrgMutation.variables?.orgId === orgItem?._id &&
                        leaveOrgMutation.isLoading
                      }>
                      Leave
                    </ReportButton>
                  </Popconfirm>
                </Space>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrganizationManage;
