import React from "react";
import { Modal, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { HiArrowLeft } from "react-icons/hi";

import { deleteCampaign } from "../../../../handler/campaign/deleteCampaign";

import { useCampaign } from "../Context/campaignContext";

import styles from "./styles.module.css";

const Footer = () => {
  const { campaign, handleSubmit, postCampaignLoading } = useCampaign();
  const navigate = useNavigate();

  const deleteCampaignMutation = useMutation(deleteCampaign, {
    onSuccess: () => navigate("/", { replace: true }),
    onError: (err) => {
      message.error("Something went wrong while deleting Campaign");
    },
  });

  return (
    <div className={styles.container}>
      <Button
        danger
        type="link"
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          color: "#BD002A",
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "22px",
        }}
        icon={<HiArrowLeft />}
        onClick={() =>
          Modal.confirm({
            onOk: () =>
              deleteCampaignMutation.mutate({
                campaignId: campaign.campaign._id,
              }),
            title: "Delete Campaign",
            content: "Are you sure you want to discard all the changes?",
            okText: "Delete",
            okButtonProps: {
              danger: true,
              loading: deleteCampaignMutation.isLoading,
            },
          })
        }
      >
        {campaign.campaign.status !== "Active"
          ? "Discard Changes"
          : "Delete Campaign"}
      </Button>

      <Button
        ghost
        style={{
          borderRadius: "10px",
          color: " #533AF5",
          border: "1px solid #533AF5",
          // padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50px",
        }}
        onClick={() => handleSubmit()}
        loading={postCampaignLoading}
        size="large"
        className={campaign.campaign.status !== "Active" ? styles.button : ""}
        disabled={campaign.campaign.status === "Active"}
      >
        {campaign.campaign.status === "Active"
          ? "Published"
          : "Publish Campaign"}
      </Button>
    </div>
  );
};

export default Footer;
