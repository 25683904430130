export const calculateArrayAverage = (inputArray: number[]): number => {
  if (inputArray.length === 0) {
    return 0; // Return 0 if the array is empty to avoid division by zero
  }

  const sum: number = inputArray.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  );
  const average: number = sum / inputArray.length;
  return average;
};
