import React, { useState, ReactNode } from "react";
import { Modal, Input, message, Row, Popconfirm } from "antd";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { inviteBrand } from "../../../../handler/organization";
import { getUser } from "../../../../handler/getUser";
import { getUserUniversal } from "../../../../handler/getUserUniversal";
import { auth } from "../../../../firebase";

import ReportButton from "../../../Campaign/Report/ReportButton";

import { validateEmail } from "../../../../utils";

interface InviteModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  queryKeyToInvalidate: (string | boolean | undefined)[];
  canInviteAgency: boolean;
}

const InviteModal: React.FC<InviteModalProps> = ({
  isModalOpen,
  handleClose,
  queryKeyToInvalidate,
  canInviteAgency,
}) => {
  const queryClient = useQueryClient();

  const [inputProps, setInputProps] = useState<{
    value: string;
    error: string;
  }>({ value: "", error: "" });

  const [userKey, userFn] = getUser();
  const [universalUserKey, universalUserFn] = getUserUniversal();
  const { data: userData } = useQuery(userKey, userFn, {
    enabled: isModalOpen,
  });
  const { data: universalUserData } = useQuery(userKey, userFn, {
    enabled: isModalOpen,
  });

  const inviteBrandMutation = useMutation(inviteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyToInvalidate);
      message.success("Invitation sent successfully");
      handleClose();
      setInputProps({ value: "", error: "" });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });
  const userDomain = userData?.data?.emailId?.split("@").at(-1);

  const userEmail = canInviteAgency
    ? inputProps.value
    : inputProps.value + `@${userDomain}`;

  const handleSubmit = () => {
    if (
      userEmail === userData?.data?.emailId ||
      userEmail === universalUserData?.data?.emailId
    ) {
      return setInputProps((inputProps) => ({
        ...inputProps,
        error: "You can not invite yourself",
      }));
    }
    if (!validateEmail(userEmail)) {
      return setInputProps((inputProps) => ({
        ...inputProps,
        error: "Please enter a valid email address",
      }));
    }

    return inviteBrandMutation.mutate({
      emailId: userEmail,
    });
  };

  const showConfirmation =
    canInviteAgency && inputProps?.value.includes(userDomain || "");

  const ConfirmHoc = ({ children }: { children: ReactNode }) => {
    if (showConfirmation)
      return (
        <Popconfirm
          onConfirm={() => handleSubmit()}
          title={
            "Are You Sure You want to invite them as External Collaborator instead of Team? "
          }>
          {children}
        </Popconfirm>
      );

    return <>{children}</>;
  };

  return (
    <Modal
      title="Invite Members"
      visible={isModalOpen}
      footer={
        <Row justify="end" gutter={[10, 10]}>
          <ReportButton
            onClick={() => {
              handleClose();
              setInputProps({ value: "", error: "" });
            }}>
            Cancel
          </ReportButton>
          <ConfirmHoc>
            <ReportButton
              type="primary"
              loading={inviteBrandMutation.isLoading}
              onClick={showConfirmation ? () => {} : () => handleSubmit()}>
              {" "}
              Invite
            </ReportButton>
          </ConfirmHoc>
        </Row>
      }
      //   onOk={() => handleSubmit()}
      //   okButtonProps={{
      //     loading: inviteBrandMutation.isLoading,
      //     style: { borderRadius: "4px" },
      //   }}
      //   okText="Invite"
      //   cancelButtonProps={{
      //     style: { borderRadius: "4px" },
      //   }}
      onCancel={() => {
        handleClose();
        setInputProps({ value: "", error: "" });
      }}>
      <h3>Please enter the email address that you want to invite</h3>
      <Input
        placeholder={
          canInviteAgency ? "ex: stephen@workmail.com" : "ex: stephen"
        }
        value={inputProps.value}
        status={inputProps.error ? "error" : ""}
        addonAfter={canInviteAgency ? undefined : `@${userDomain}`}
        onChange={(e) => {
          if (e.target.value)
            return setInputProps((inputProps) => ({
              ...inputProps,
              value: e.target.value.trim(),
            }));
          return setInputProps((inputProps) => ({
            ...inputProps,
            value: "",
          }));
        }}
        onPressEnter={() => handleSubmit()}
      />
      {inputProps.error && <h5 style={{ color: "red" }}>{inputProps.error}</h5>}

      {showConfirmation && (
        <h4 style={{ color: "#faad14", marginTop: 10 }}>
          This Domain is a part of Owner Organization. Invite them as a part of
          your Team instead in{" "}
          <b>
            <u>Manage Team Section</u>
          </b>
        </h4>
      )}
    </Modal>
  );
};

export default InviteModal;
