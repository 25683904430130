import React from "react";
import { Space, Upload, DatePicker, Popover } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";

import CustomInput from "../../../shared/Custom/Input";

import { useCampaign } from "../Context/campaignContext";

import styles from "../styles.module.css";
import moment from "moment";

const { RangePicker } = DatePicker;

const BasicDetails = () => {
  const { campaign, setCampaign, error } = useCampaign();

  const uploadButton = (
    <div className={styles.uploadButtonContainer}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Allowed file formats are png, jpg and jpeg.
      </div>
    </div>
  );

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/campaign/${campaign.campaign._id}/banner/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setCampaign((campaign) => ({
            ...campaign,
            campaign: { ...campaign.campaign, campaignBannerUrl: downloadURL },
          }));
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setCampaign((campaign) => ({
        ...campaign,
        campaign: { ...campaign.campaign, campaignBannerUrl: "" },
      }));
    },
  };

  if (!campaign) {
    return null;
  }
  return (
    <div>
      <h2 className={styles.title} style={{ marginBottom: "32px" }}>
        Campaign Details
      </h2>
      <Space size={64} style={{ alignItems: "flex-start" }}>
        <Space direction="vertical" size={27}>
          <CustomInput
            label="Campaign Name"
            value={campaign.campaign.campaignName}
            onChange={(e) => {
              setCampaign((campaign) => ({
                ...campaign,
                campaign: {
                  ...campaign.campaign,
                  campaignName: e.target.value || "",
                },
              }));
            }}
            placeholder="eg Best Brand Smartwatch Campaign"
            error={error?.cName}
          />
          <CustomInput
            label={
              <Popover
                trigger={"hover"}
                title="This is just an estimated timeline.">
                Timeline
              </Popover>
            }
            subLabel="When do you plan for all the creators’ content to go live?"
            // value={campaign.campaign.startDate}
            // onChange={(e) => {
            //   setCampaign((campaign) => ({
            //     ...campaign,
            //     campaign: {
            //       ...campaign.campaign,
            //       startDate: e.target.value || "",
            //     },
            //   }));
            // }}
            placeholder="Timeline it is"
            error={error?.timeline}
            children={
              <RangePicker
                format={`DD/MM/YYYY`}
                size="large"
                style={{ width: "100%" }}
                value={
                  campaign.campaign?.startDate && campaign.campaign?.endDate
                    ? [
                        moment(campaign.campaign.startDate),
                        moment(campaign.campaign.endDate),
                      ]
                    : undefined
                }
                onChange={(values, _) => {
                  if (values) {
                    setCampaign((campaign) => ({
                      ...campaign,
                      campaign: {
                        ...campaign.campaign,
                        endDate: new Date(values[1]!.toDate()).toDateString(),
                        startDate: new Date(values[0]!.toDate()).toDateString(),
                      },
                    }));
                  }
                }}
              />
            }
          />
        </Space>
        <div>
          <CustomInput
            children={
              <Upload
                listType="picture"
                // style={{ width: "344px !important", height: "149px !important" }}
                fileList={
                  campaign?.campaign?.campaignBannerUrl
                    ? [
                        {
                          uid: campaign?.campaign?.campaignBannerUrl,
                          name: "banner.png",
                          status: "done",
                          url: campaign?.campaign?.campaignBannerUrl,
                          type: "image/jpeg",
                        },
                      ]
                    : []
                }
                {...UploadButtonProps}>
                {campaign?.campaign?.campaignBannerUrl ? null : uploadButton}
              </Upload>
            }
            label={
              <Popover
                trigger={"hover"}
                content="Upload High Quality Banner to increase your Campaign Clicks"
                title="Note"
                placement="right">
                Upload Campaign Banner
              </Popover>
            }
            error={error.banner}
            subLabel="This would be the banner image for your campaign"
          />
        </div>
      </Space>
    </div>
  );
};

export default BasicDetails;
