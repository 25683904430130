import React, { useRef, useEffect, useState, ReactNode } from "react";
import Lottie from "react-lottie";
import { Row, Col, Space, Button, Spin, Table, Divider } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import animationData from "../../assets/lottie/Rocket.json";
import { useQueryClient } from "react-query";

import { useInfiniteQuery } from "react-query";

import { getCatalogues } from "../../../handler/replies";

import { formatData, getDefaultLottieOptions, trimData } from "../../../utils";

import { Catalogue, MessageReply } from "../../../typings";
import { ColumnsType } from "antd/es/table";

import AddCatalogue from "../Modal/AddCatalogue";
import AddMessageModal from "../Modal/AddMessage";

import { format } from "date-fns";

import styles from "../Messages/messages.module.css";
import ReportButton from "../../Campaign/Report/ReportButton";

const Catalogues = () => {
  const [cataloguesKey, cataloguesFn] = getCatalogues({ limit: 20 });
  const queryClient = useQueryClient();
  const [addMessageModal, setAddMessageModal] = useState<{
    visible: boolean;
    messageV: MessageReply | undefined;
  }>({ visible: false, messageV: undefined });

  const [messageModalProps, setMessageModalProps] = useState<{
    isView: boolean;
    isEdit: boolean;
    visible: boolean;
    catalogue?: Catalogue;
  }>({
    isEdit: false,
    isView: false,
    visible: false,
    catalogue: undefined,
  });

  const firstRender = useRef(true);

  const [loading, setLoading] = useState<boolean>(true);

  const {
    data: cataLogueData,
    isLoading: cataLogueDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(cataloguesKey, cataloguesFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.catalogue.length ? lastPage.data.pageNo + 1 : null,
  });

  useEffect(() => {
    if (!cataLogueDataLoading && firstRender.current) {
      firstRender.current = false;
      setLoading(false);
    }
  }, [cataLogueDataLoading]);

  if (loading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "80vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  const columns: ColumnsType<Catalogue> = [
    {
      title: "Catalogue Name",
      // dataIndex: "message",
      key: "cName",
      render: (_, { name }) => (
        <p
          className={styles.name}
          // style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {trimData({ value: name || "", limit: 50 })}
        </p>
      ),
    },

    {
      title: "Messages length",
      // dataIndex: "followers",
      key: "uActions",
      render: (_, { products }) => (
        <p
          className={styles.name}
          // style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {formatData((products || [])?.length, "a")}
        </p>
      ),
    },
    {
      title: "Created on On",
      // dataIndex: "joiningDate",
      key: "joiningDate",

      render: (_, { createdDateTime }) => (
        <p className={styles.followers}>
          {createdDateTime && (
            <>{format(new Date(createdDateTime), "d MMM, RRRR")}</>
          )}
        </p>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "followers",
      key: "Actions",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, record) => (
        <Space size={10} wrap>
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setMessageModalProps({
                isEdit: false,
                isView: true,
                visible: true,
                catalogue: record,
              })
            }>
            View
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button
            key={"Edit"}
            style={{ borderRadius: 8 }}
            type="link"
            onClick={() =>
              setMessageModalProps({
                isEdit: true,
                isView: false,
                visible: true,
                catalogue: record,
              })
            }>
            Edit
          </Button>
          <Divider type="vertical" style={{ margin: 0 }} />
          <Button key={"Delete"} style={{ borderRadius: 8 }} danger type="link">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div
      className={styles.g10}
      style={{
        height: "70vh",
        overflowY: "scroll",
      }}>
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        className={styles.g10}
        style={{ alignItems: "flex-start" }}
        hasMore={hasNextPage}
        loader={
          <Row justify="center">
            <Spin />
          </Row>
        }
        useWindow={false}>
        {cataLogueDataLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <Table
            columns={columns}
            dataSource={cataLogueData?.pages?.flatMap(
              (pageItem) =>
                pageItem.data?.catalogue?.map((cItem) => ({
                  ...cItem,
                  key: cItem?._id,
                })) || []
            )}
            expandable={{
              expandedRowRender: (record) => (
                <ol style={{ display: "grid", padding: "0 100px" }}>
                  {record?.products?.map((pItem) => (
                    <li key={pItem?._id}>
                      <Row justify="space-between">
                        <Space size={4} align="center">
                          {pItem?.image_url && (
                            <img
                              src={pItem?.image_url}
                              className={styles.profile}
                              style={{ width: 20, height: 20 }}
                              alt="profile"
                            />
                          )}
                          <p className={styles.name}>
                            {trimData({ value: pItem?.title || "", limit: 50 })}
                          </p>
                        </Space>
                        <ReportButton
                          type="link"
                          onClick={() =>
                            setAddMessageModal({
                              visible: true,
                              messageV: pItem,
                            })
                          }>
                          View
                        </ReportButton>
                      </Row>
                      <Divider style={{ margin: "5px 0" }} />
                    </li>
                  ))}
                </ol>
              ),
            }}
            scroll={{ y: 700 }}
            pagination={false}
          />
        )}
      </InfiniteScroll>
      <AddCatalogue
        isVisible={messageModalProps.visible}
        isView={messageModalProps.isView}
        isEdit={messageModalProps.isEdit}
        initialData={messageModalProps.catalogue}
        handleClose={() => {
          setMessageModalProps({
            isEdit: false,
            isView: false,
            visible: false,
            catalogue: undefined,
          });
        }}
      />
      <AddMessageModal
        isVisible={addMessageModal.visible}
        handleClose={() =>
          setAddMessageModal({ visible: false, messageV: undefined })
        }
        initialData={addMessageModal.messageV}
        isView={true}
      />
    </div>
  );
};

export default Catalogues;
