import React from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import { Avatar } from "antd";

import {
  MdWorkOutline,
  MdOutlineSettings,
  MdUpgrade,
  MdPersonAddAlt,
  MdOutlinePersonOutline,
  MdMailOutline,
} from "react-icons/md";
import { LuCreditCard } from "react-icons/lu";

import { getUserUniversal } from "../../../../handler/getUserUniversal";
import { getAppState } from "../../../../handler/getAppState";

import { MenuType } from "..";

import styles from "./styles.module.css";

interface ProfileMenuProps {
  sectionSelected: MenuType;
  handleSelect: (type: MenuType) => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  sectionSelected,
  handleSelect,
}) => {
  const [queryKey, queryFn] = getUserUniversal();

  const { data: userData } = useQuery(queryKey, queryFn);

  const [appStateKey, appStateFn] = getAppState();

  const { data: appStateData } = useQuery(appStateKey, appStateFn);

  return (
    <section>
      <div className={styles.profileContainer}>
        {userData?.data?.businessDetails?.logoUrl ? (
          <img
            className={styles.profilePic}
            src={userData?.data?.businessDetails?.logoUrl}
            alt="profile_pic"
          />
        ) : (
          <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}>
            {userData?.data?.emailId.at(0)?.toUpperCase()}
          </Avatar>
        )}
        <div>
          <p className={styles.bName}>
            {userData?.data?.businessDetails?.name}
          </p>
          <p className={styles.email}> {userData?.data?.emailId}</p>
        </div>
      </div>
      <section style={{ display: "grid", gap: "8px" }}>
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Personal",
          })}
          onClick={() => handleSelect("Personal")}>
          <MdOutlinePersonOutline size={20} />
          Personal Details
        </div>
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Business",
          })}
          onClick={() => handleSelect("Business")}>
          <MdWorkOutline size={20} />
          Business Details
        </div>
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Subscription",
          })}
          onClick={() => handleSelect("Subscription")}>
          <MdUpgrade size={20} />
          Upgrade Plan
        </div>

        {!appStateData?.data?.tier?.isBasePlan && (
          <div
            className={clsx(styles.menuItem, {
              [styles.selected]: sectionSelected === "viewPlan",
            })}
            onClick={() => handleSelect("viewPlan")}>
            <LuCreditCard size={20} />
            View Plan
          </div>
        )}
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Organization",
          })}
          onClick={() => handleSelect("Organization")}>
          <MdOutlineSettings size={20} />
          Manage Organization
        </div>
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "InvitationTeam",
          })}
          onClick={() => handleSelect("InvitationTeam")}>
          <MdPersonAddAlt size={20} />
          Manage Team
        </div>
        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Invitation",
          })}
          onClick={() => handleSelect("Invitation")}>
          <MdPersonAddAlt size={20} />
          Manage External Collaborators
        </div>

        <div
          className={clsx(styles.menuItem, {
            [styles.selected]: sectionSelected === "Email",
          })}
          onClick={() => handleSelect("Email")}>
          <MdMailOutline size={20} />
          E-mail
        </div>
      </section>
    </section>
  );
};

export default ProfileMenu;
