import React, { useState } from "react";
import { List, Button, Typography, message } from "antd";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { useCampaign } from "../../Context/campaignContext";

import { deleteDeliverable } from "../../../../../handler/campaign";

import PostModal from "../../../Create/Deliverables/Modal/Instagram/Post";
import ReelModal from "../../../Create/Deliverables/Modal/Instagram/Reel";
import StoryModal from "../../../Create/Deliverables/Modal/Instagram/Story";
import VideoModal from "../../../Create/Deliverables/Modal/Instagram/Video";

import {
  InstaModal,
  InstaContent,
} from "../../../Create/Deliverables/Deliverables";

import styles from "../../styles.module.css";

const InstagramDeliverables = () => {
  const { campaign, setCampaign, error } = useCampaign();

  const [instaModalVisible, setInstaModalVisible] = useState<InstaModal>({
    Reel: false,
    Story: false,
    Post: false,
    Video: false,
  });
  const [isEditInstaModalVisible, setEditInstaModalVisible] =
    useState<InstaModal>({
      Reel: false,
      Story: false,
      Post: false,
      Video: false,
    });
  const [instaDeliverable, setInstaDeliverable] = useState<InstaContent | null>(
    null
  );

  const deleteInstaDeliverableMutation = useMutation(deleteDeliverable, {
    onSuccess: (response) => {
      setCampaign((campaign) => ({
        ...campaign,
        deal: {
          ...campaign.deal,
          deliverables: response.data.deal.deliverables,
        },
      }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong while deleting Product");
    },
  });

  return (
    <div>
      {campaign.deal.deliverables &&
        Boolean(campaign.deal.deliverables.length) && (
          <>
            <List
              dataSource={campaign.deal.deliverables}
              bordered
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="link"
                      key="list-loadmore-edit"
                      onClick={() => {
                        setInstaDeliverable(item as InstaContent);
                        setEditInstaModalVisible((prev) => {
                          return {
                            ...prev,
                            [item!.typeDel]: true,
                          };
                        });
                      }}>
                      Edit
                    </Button>,
                    <Button
                      type="link"
                      key="list-loadmore-more"
                      onClick={() =>
                        deleteInstaDeliverableMutation.mutate({
                          campaignId: campaign?.campaign?._id,
                          deliverableID: item?._id || "",
                        })
                      }>
                      Delete
                    </Button>,
                  ]}>
                  <Typography.Text>
                    {item?.typeDel === "Reel" &&
                      (item.repost ? "Repost" : item?.duration + "s")}
                    {item?.typeDel === "Video" &&
                      ((item as InstaContent)?.repost
                        ? "Repost"
                        : item?.duration + " minute")}
                    {item?.typeDel === "Story" &&
                      (item.repost
                        ? "Repost"
                        : item?.type === "Video" && item?.duration + "s")}
                    {item?.typeDel === "Post" &&
                      (item.repost ? "Repost" : item?.type)}{" "}
                    {item?.typeDel}{" "}
                  </Typography.Text>
                </List.Item>
              )}
            />
            {error?.deliverables && (
              <p className={styles.error}>{error?.deliverables}</p>
            )}
          </>
        )}

      <div
        style={{
          display: "flex",
          gap: "48px",
          marginTop: "16px",
          justifyContent: "flex-start",
        }}>
        <Button
          type="link"
          onClick={() =>
            setInstaModalVisible((prev) => {
              return { ...prev, Reel: true };
            })
          }
          style={{ height: "min-content" }}
          onMouseOver={(event) => (event.currentTarget.style.opacity = "0.7")}
          onMouseOut={(event) => (event.currentTarget.style.opacity = "1")}>
          <img
            src="/assets/icons/insta-reel.svg"
            alt="reel icon"
            width="34px"
          />
          <div>Reel</div>
        </Button>
        <Button
          type="link"
          onClick={() =>
            setInstaModalVisible((prev) => {
              return { ...prev, Story: true };
            })
          }
          style={{ height: "min-content" }}
          onMouseOver={(event) => (event.currentTarget.style.opacity = "0.7")}
          onMouseOut={(event) => (event.currentTarget.style.opacity = "1")}>
          <img
            src="/assets/icons/insta-story.svg"
            alt="story icon"
            width="34px"
          />
          <div>Story</div>
        </Button>
        <Button
          type="link"
          onClick={() =>
            setInstaModalVisible((prev) => {
              return { ...prev, Video: true };
            })
          }
          style={{ height: "min-content" }}
          onMouseOver={(event) => (event.currentTarget.style.opacity = "0.7")}
          onMouseOut={(event) => (event.currentTarget.style.opacity = "1")}>
          <img
            src="/assets/icons/insta-video.svg"
            alt="video icon"
            width="34px"
          />
          <div>Video</div>
        </Button>
        <Button
          type="link"
          onMouseOver={(event) => (event.currentTarget.style.opacity = "0.7")}
          onMouseOut={(event) => (event.currentTarget.style.opacity = "1")}
          onClick={() =>
            setInstaModalVisible((prev) => {
              return { ...prev, Post: true };
            })
          }
          style={{ height: "min-content" }}>
          <img
            src="/assets/icons/insta-post.svg"
            alt="post icon"
            width="34px"
          />
          <div>Post</div>
        </Button>
      </div>

      <PostModal
        type="Add"
        visible={instaModalVisible.Post}
        setIsVisible={setInstaModalVisible}
        setDeliverableList={(deliverables: InstaContent[]) => {
          if (deliverables) {
            setCampaign((campaign) => ({
              ...campaign,
              deal: { ...campaign.deal, deliverables },
            }));
          }
        }}
        setDeliverable={setInstaDeliverable}
        productList={[]}
        campaignId={campaign.campaign._id}
        hasProduct={campaign.deal.isProduct}
      />

      {instaDeliverable && instaDeliverable.typeDel === "Post" && (
        <PostModal
          type="Edit"
          visible={isEditInstaModalVisible.Post}
          setIsVisible={setEditInstaModalVisible}
          setDeliverableList={(deliverables: InstaContent[]) => {
            if (deliverables) {
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, deliverables },
              }));
            }
          }}
          setDeliverable={setInstaDeliverable}
          productList={[]}
          initialValues={instaDeliverable}
          campaignId={campaign.campaign._id}
          hasProduct={campaign.deal.isProduct}
        />
      )}

      <ReelModal
        type="Add"
        visible={instaModalVisible.Reel}
        setIsVisible={setInstaModalVisible}
        setDeliverableList={(deliverables: InstaContent[]) => {
          if (deliverables) {
            setCampaign((campaign) => ({
              ...campaign,
              deal: { ...campaign.deal, deliverables },
            }));
          }
        }}
        setDeliverable={setInstaDeliverable}
        productList={[]}
        campaignId={campaign.campaign._id}
        hasProduct={campaign.deal.isProduct}
      />

      {instaDeliverable && instaDeliverable.typeDel === "Reel" && (
        <ReelModal
          type="Edit"
          visible={isEditInstaModalVisible.Reel}
          setIsVisible={setEditInstaModalVisible}
          setDeliverableList={(deliverables: InstaContent[]) => {
            if (deliverables) {
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, deliverables },
              }));
            }
          }}
          setDeliverable={setInstaDeliverable}
          productList={[]}
          initialValues={instaDeliverable}
          campaignId={campaign.campaign._id}
          hasProduct={campaign.deal.isProduct}
        />
      )}

      <StoryModal
        type="Add"
        visible={instaModalVisible.Story}
        setIsVisible={setInstaModalVisible}
        setDeliverableList={(deliverables: InstaContent[]) => {
          if (deliverables) {
            setCampaign((campaign) => ({
              ...campaign,
              deal: { ...campaign.deal, deliverables },
            }));
          }
        }}
        setDeliverable={setInstaDeliverable}
        productList={[]}
        campaignId={campaign.campaign._id}
        hasProduct={campaign.deal.isProduct}
      />

      {instaDeliverable && instaDeliverable.typeDel === "Story" && (
        <StoryModal
          type="Edit"
          visible={isEditInstaModalVisible.Story}
          setIsVisible={setEditInstaModalVisible}
          setDeliverableList={(deliverables: InstaContent[]) => {
            if (deliverables) {
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, deliverables },
              }));
            }
          }}
          setDeliverable={setInstaDeliverable}
          productList={[]}
          initialValues={instaDeliverable}
          campaignId={campaign.campaign._id}
          hasProduct={campaign.deal.isProduct}
        />
      )}

      <VideoModal
        type="Add"
        visible={instaModalVisible.Video}
        setIsVisible={setInstaModalVisible}
        setDeliverableList={(deliverables: InstaContent[]) => {
          if (deliverables) {
            setCampaign((campaign) => ({
              ...campaign,
              deal: { ...campaign.deal, deliverables },
            }));
          }
        }}
        setDeliverable={setInstaDeliverable}
        productList={[]}
        campaignId={campaign.campaign._id}
        hasProduct={campaign.deal.isProduct}
      />

      {instaDeliverable && instaDeliverable.typeDel === "Video" && (
        <VideoModal
          type="Edit"
          visible={isEditInstaModalVisible.Video}
          setIsVisible={setEditInstaModalVisible}
          setDeliverableList={(deliverables: InstaContent[]) => {
            if (deliverables) {
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, deliverables },
              }));
            }
          }}
          setDeliverable={setInstaDeliverable}
          productList={[]}
          initialValues={instaDeliverable}
          campaignId={campaign.campaign._id}
          hasProduct={campaign.deal.isProduct}
        />
      )}
    </div>
  );
};

export default InstagramDeliverables;
