import { sendRequest } from "../helper";
import { MessageReply } from "../../typings";

export type getProductsResult = {
  status: string;
  message: string;
  data: {
    product: MessageReply[];
    pageNo: number;
  };
};

export type Props = {
  limit: number;
};

export const getProducts = (props: Props) => {
  const queryKey = ["smart-replies"];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getProductsResult>(
      "GET",
      `/brand/products/?limit=${props.limit}&pageNo=${pageParam || 1}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
