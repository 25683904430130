import { sendRequest } from "../helper";
import { CreatorReport } from "../../typings";

export type deleteReportProps = {
  campaignId: string;
  deliverableMediaIds: string[];
};

export const deleteReport = (props: deleteReportProps) => {
  return sendRequest<CreatorReport>(
    "delete",
    `/brand/report-only/report`,
    false,
    { ...props }
  ).then((res) => res.data);
};
