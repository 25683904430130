import React, { useState, useEffect, useRef } from "react";
import Navbar from "../shared/Navbar";
import { useQuery } from "react-query";
import { Row, Col, Button, Pagination, Modal, Skeleton, Radio } from "antd";
import { SlGraduation } from "react-icons/sl";
import { useSearchParams } from "react-router-dom";
import CreateModal from "./Create/Modal";
import Tile from "./Tile";
import Received from "../Invitations/Received";
import DemoCall from "../Onboarding-1/Modals/DemoCall";
import CampaignSelector from "./CampaignSelector";
import CampaignTable from "./CampaignTable";
import EmptyScreen from "./EmptyScreen";
import Title from "../shared/Title";
import { getAllCampaigns, getCampaignCount } from "../../handler/campaign";
import {
  getReportCampaignCount,
  getAllReportCampaigns,
} from "../../handler/report";
import { getUser } from "../../handler/getUser";
import { CampaignType } from "../../typings";
import styles from "./Campaign.module.css";
import CampaignsComponent from "../CloutflowCampaign";
import CloutflowCampaignTable from "../CloutflowCampaign";

const Campaign = () => {
  const [page, SetPage] = useState(1);
  const firstRender = useRef(true);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  // const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [campaignTye, setCampaignType] = useState<CampaignType>("campaign");
  const [searchParams, setSearchParams] = useSearchParams();

  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [kycNotVerifiedModal, setKycNotVerifiedModal] =
    useState<boolean>(false);
  const [getUserkey, getUserFn] = getUser();
  const { data: userData, isLoading: userDataLoading } = useQuery(
    getUserkey,
    getUserFn
  );

  const [getCampaignCountKey, getCampaignCountFn] =
    getCampaignCount(isArchived);
  const [getAllCampaignsKey, getAllCampaignsFn] = getAllCampaigns({
    pageNumber: page,
    isArchived,
  });
  const [getReportCampaignCountKey, getReportCampaignCountFn] =
    getReportCampaignCount();
  const [getAllReportCampaignsKey, getAllReportCampaignsFn] =
    getAllReportCampaigns({
      pageNumber: page,
    });

  // const isKycNotInitiated =
  //   userData?.data?.signUpStatus !== "businessVerified" &&
  //   userData?.data.kyc.status !== "Not-Initiated";

  const kycStatus =
    userData?.data?.signUpStatus === "businessVerified"
      ? "completed"
      : userData?.data.signUpStatus === "Not-Initiated"
      ? "started"
      : userData?.data.signUpStatus === "businessVerificationPending"
      ? "pending"
      : "issue";

  const { data: countData, isLoading: isLoadingCount } = useQuery(
    getCampaignCountKey,
    getCampaignCountFn
  );

  const { data: campaignsData, isLoading: isLoadingCampaigns } = useQuery(
    getAllCampaignsKey,
    getAllCampaignsFn
  );

  const { data: reportCountData, isLoading: isLoadingReportCount } = useQuery(
    getReportCampaignCountKey,
    getReportCampaignCountFn,
    { enabled: campaignTye === "report" }
  );

  const { data: reportCampaignsData, isLoading: isLoadingReportCampaigns } =
    useQuery(getAllReportCampaignsKey, getAllReportCampaignsFn, {
      enabled: campaignTye === "report",
    });

  useEffect(() => {
    SetPage(1);
  }, [campaignTye]);

  useEffect(() => {
    if (userDataLoading) {
      return;
    }
    if (campaignTye === "exec" && userData?.data) {
      if (
        !searchParams.get("showArchive") &&
        userData?.data?.accountType !== "Report"
      )
        return setSearchParams((searchParams) => ({
          ...searchParams,
          showArchive: "no",
        }));
      if (searchParams.get("showArchive") !== (isArchived ? "yes" : "no"))
        return setIsArchived(
          searchParams.get("showArchive") === "yes" ? true : false
        );
    } else {
      if (searchParams.get("showArchive")) {
        return setSearchParams((searchParams) => {
          searchParams.delete("showArchive");
          return searchParams;
        });
      }
    }
  }, [
    campaignTye,
    isArchived,
    searchParams.get("showArchive"),
    userData?.data,
    userDataLoading,
  ]);

  useEffect(() => {
    if (
      userData?.data?.accountType === "Report" &&
      firstRender?.current &&
      !userDataLoading
    ) {
      setCampaignType("report");
      firstRender.current = false;
    }
  }, [userData?.data, userDataLoading]);

  return (
    <div style={{ overflowY: "auto", height: "100vh" }}>
      <Navbar />

      <Row justify="center">
        <Col style={{ width: "1300px" }}>
          <div className={styles.title_container}>
            <Title icon={<SlGraduation color="#533AF5" />} />
          </div>
          <Row style={{ marginTop: 0 }}>
            <Col
              span={
                campaignTye === "exec" &&
                campaignsData &&
                campaignsData.data.length > 0
                  ? 22
                  : 22
              }
              offset={
                campaignTye === "exec" &&
                campaignsData &&
                campaignsData.data.length > 0
                  ? 1
                  : 1
              }
            >
              <Received />
            </Col>
          </Row>
          <div style={{ padding: "0 20px " }}>
            <CampaignSelector type={campaignTye} setType={setCampaignType} />
          </div>

          <Row style={{ marginTop: 0, padding: "0 40px" }}>
            <Col
              span={
                campaignTye === "exec" &&
                campaignsData &&
                campaignsData.data.length > 0
                  ? 22
                  : 22
              }
              offset={
                campaignTye === "exec" &&
                campaignsData &&
                campaignsData.data.length > 0
                  ? 1
                  : 1
              }
              style={{ paddingTop: "20px" }}
            >
              {campaignTye != "campaign" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="primary"
                    size="middle"
                    title="Create Campaign"
                    style={{
                      padding: "12px 28px",
                      borderRadius: "10px",
                      height: "46px",
                      border: "1px solid #FFF",
                      backgroundColor: "#533AF5",
                    }}
                    disabled={
                      isLoadingCampaigns ||
                      isLoadingCount ||
                      kycStatus !== "completed"
                    }
                    onClick={() => {
                      setCreateModalVisible(true);
                    }}
                  >
                    Create Campaign
                  </Button>
                </div>
              )}
              {campaignTye === "exec" && (
                <Row>
                  <Col>
                    <h4>Campaign Type </h4>
                    <Radio.Group
                      onChange={(e) => {
                        setSearchParams((searchParams) => ({
                          ...searchParams,
                          showArchive: e.target.value,
                        }));
                        SetPage(1);
                      }}
                      value={isArchived ? "yes" : "no"}
                    >
                      <Radio.Button value={"no"}>Active </Radio.Button>
                      <Radio.Button value={"yes"}>Inactive</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              )}
              {/* )} */}

              {isLoadingCampaigns ||
              isLoadingCount ||
              isLoadingReportCount ||
              isLoadingReportCampaigns ? (
                <div style={{ marginTop: "50px" }}>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {" "}
                  {campaignTye === "exec" && (
                    <>
                      {campaignsData && (
                        <>
                          {campaignsData.data.length <= 0 ? (
                            <EmptyScreen
                              kycStatus={kycStatus}
                              type="exec"
                              setCreateModalVisible={setCreateModalVisible}
                            />
                          ) : (
                            <>
                              <div
                                className="campaign  list"
                                style={{ marginTop: "20px" }}
                              >
                                <section className={styles.campaignHeader}>
                                  <div className={styles.headerItem}>
                                    Campaign Name
                                  </div>
                                  <div
                                    className={styles.headerItem}
                                    style={{ textAlign: "center" }}
                                  >
                                    Campaign visibility
                                  </div>
                                  <div
                                    className={styles.headerItem}
                                    style={{ textAlign: "center" }}
                                  >
                                    Created At
                                  </div>
                                  <div
                                    className={styles.headerItem}
                                    style={{ textAlign: "center" }}
                                  >
                                    Status
                                  </div>
                                  <div className={styles.headerItem}></div>
                                </section>
                                <div
                                  style={{
                                    minHeight: "300px",
                                  }}
                                >
                                  {!isLoadingCampaigns && campaignsData && (
                                    <div
                                      style={{ display: "grid", gap: "4px" }}
                                    >
                                      {campaignsData?.data.map(
                                        (campaignItem, idx) => {
                                          return (
                                            // <Link to={getNextLink(campaignItem)}>
                                            <Tile
                                              key={idx}
                                              campaignkey={getAllCampaignsKey}
                                              campaign={campaignItem}
                                              campaignCountKey={
                                                getCampaignCountKey
                                              }
                                            />
                                            // </Link>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <Row justify="center">
                                <Pagination
                                  defaultCurrent={page}
                                  total={countData?.data}
                                  style={{ margin: "46px auto" }}
                                  onChange={(page, _) => SetPage(page)}
                                  pageSize={5}
                                  showSizeChanger={false}
                                />
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {campaignTye === "report" && (
                    <>
                      {reportCampaignsData && (
                        <>
                          {reportCampaignsData.data.length <= 0 ? (
                            <EmptyScreen
                              kycStatus={kycStatus}
                              type="report"
                              setCreateModalVisible={setCreateModalVisible}
                            />
                          ) : (
                            <>
                              <CampaignTable
                                loading={isLoadingCampaigns}
                                data={campaignsData}
                                campaignkey={getAllReportCampaignsKey}
                                campaignCountKey={getCampaignCountKey}
                                campaigns={reportCampaignsData}
                              />
                              <Row justify="center">
                                <Pagination
                                  defaultCurrent={page}
                                  total={reportCountData?.data?.count}
                                  style={{ margin: "46px auto" }}
                                  onChange={(page, _) => SetPage(page)}
                                  pageSize={5}
                                  showSizeChanger={false}
                                />
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {campaignTye === "campaign" && <CloutflowCampaignTable />}
                </>
              )}
            </Col>
          </Row>

          <DemoCall isVisible={open} handleClose={() => setOpen(false)} />
          <CreateModal
            isVisible={createModalVisible}
            handleCancel={() => setCreateModalVisible(false)}
          />

          <Modal
            centered={true}
            title="Account Verification Pending"
            visible={kycNotVerifiedModal}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => setKycNotVerifiedModal(false)}
            onOk={() => setKycNotVerifiedModal(false)}
          >
            We are still under the process of verifying your Account. It will
            take less than 24 hours. If it’s taking longer or you're facing an
            issue, write to us at support@cloutflow.com
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default Campaign;
