import { sendRequest } from "../helper";

import { ReplyWorkflow } from "../../typings";

interface createWorkflowProps {
  data: {
    mediaId: string;
    rulesApplied: string[];
  };
}

export const createWorkflow = ({ data }: createWorkflowProps) => {
  return sendRequest("post", `/brand/smart-replies/workflow`, false, {
    ...data,
    platform: "instagram",
  }).then((res) => res.data);
};
