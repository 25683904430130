import React from "react";
import { Progress, Button, Row } from "antd";

import { formatData } from "../../../../../../utils";

import styles from "../demographic.module.css";

interface StatsCardProps {
  title: string;
  items: {
    label: string;
    percent: number;
  }[];
  isSeeMoreEnabled?: boolean;
  handleSeeMoreEnabled?: () => void;
}

const StatsCard: React.FC<StatsCardProps> = ({
  title,
  items,
  isSeeMoreEnabled,
  handleSeeMoreEnabled,
}) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <section className={styles.scrollableItem}>
        {items.map((item, idx) => {
          return (
            <div key={idx}>
              <p className={styles.label}>
                <span>{item.label}</span>

                <span>({Number(formatData(item.percent, "0.00"))}%)</span>
              </p>
              <Progress
                percent={Number(formatData(item.percent, "0.00"))}
                status="active"
                strokeColor={"#2130FF"}
                showInfo={false}
                // success={{ percent: 100, strokeColor: "#2130FF" }}
              />
            </div>
          );
        })}
      </section>
      {(isSeeMoreEnabled === true || isSeeMoreEnabled === false) && (
        <Row justify="end" style={{ marginTop: "1em" }}>
          <Button
            type="link"
            onClick={() => handleSeeMoreEnabled && handleSeeMoreEnabled()}>
            {isSeeMoreEnabled ? "See Less" : "See More"}
          </Button>
        </Row>
      )}
    </div>
  );
};

export default StatsCard;
