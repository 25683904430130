import React from "react";
import { Modal, message, Col, Row, Spin } from "antd";
import { useQuery, useMutation } from "react-query";

import MetricBox from "../Profile/Plan/MetricBox";

import { getAddOns, createAddonCheckoutSession } from "../../handler/plan";
import { getUser } from "../../handler/getUser";
import { getAppState } from "../../handler/getAppState";

import { auth } from "../../firebase";

import styles from "./styles.module.css";

interface Props {
  visible: boolean;
  handleClose: () => void;
}

const PurchaseMediaCredits: React.FC<Props> = ({ visible, handleClose }) => {
  const [userKey, userFn] = getUser();

  const { data: universalUserData } = useQuery(userKey, userFn);
  const firebaseUid = auth?.currentUser?.uid || "";

  const isOwner = firebaseUid === (universalUserData?.data?._id || "");

  const [addOnsKey, addOnFn] = getAddOns();

  const { data: addOnsData, isLoading: addOnsLoading } = useQuery(
    addOnsKey,
    addOnFn
  );

  const [userStateKey, userStateFn] = getAppState();
  const { data, isLoading } = useQuery(userStateKey, userStateFn);

  const createCheckoutAddonSessionMutation = useMutation(
    createAddonCheckoutSession,
    {
      onSuccess: (res) => {
        window.open(res?.data, "_blank");
      },
      onError: (err) => {
        message.error("Something went wrong while Creating Checkout Session");
        console.log(err);
      },
    }
  );

  return (
    <Modal
      width={400}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        handleClose();
        // return setTouched(false);
      }}
      visible={visible}
      centered
      className={"campaign-create-modal"}>
      <p className={styles.heading}>Purchase Media Size Credits</p>
      {isLoading || addOnsLoading ? (
        <Row justify="center" style={{ margin: "40px 0" }}>
          <Spin />
        </Row>
      ) : (
        <div style={{ marginTop: "40px" }}>
          {addOnsData?.data
            ?.filter((item) => item?.addOnType === "addMedia")
            ?.map((addOnItem) => (
              <Col span={20}>
                <MetricBox
                  buttonLoading={createCheckoutAddonSessionMutation.isLoading}
                  title={addOnItem?.name}
                  description={addOnItem?.description}
                  isOwner={isOwner}
                  maxLimit={
                    (data?.data?.tierPlanUsed?.featureLimit?.[
                      addOnItem?.addOnType
                    ]?.maxLimit || 1) +
                    (data?.data?.tierPlanUsed?.featureLimit?.[
                      addOnItem?.addOnType
                    ]?.addOnLimit || 0)
                  }
                  onClick={() =>
                    createCheckoutAddonSessionMutation.mutate({
                      priceId: addOnItem?.stripePriceId,
                    })
                  }
                  buttonText={
                    addOnItem?.addOnType === "community"
                      ? "Purchase More"
                      : "Add Media Credits"
                  }
                  occupany={
                    data?.data?.tierPlanUsed?.featureLimit?.[
                      addOnItem?.addOnType
                    ]?.usedLimit || 0
                  }
                />
              </Col>
            ))}
        </div>
      )}
    </Modal>
  );
};

export default PurchaseMediaCredits;
