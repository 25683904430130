import React, { useState } from "react";
import { Row, Col, Space, Typography, Card, Radio, Divider } from "antd";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut, Bar } from "react-chartjs-2";

import { useCreatorPageContext } from "../context";

import StatsItem from "./StatsItem";
import Demographics from "./Demographics";
import BrandInterests from "./BrandInterests";

const { Title, Text } = Typography;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const Audience = () => {
  const { creatorData } = useCreatorPageContext();
  const malePercentage =
    creatorData?.profile?.audience?.gender_distribution?.find(
      (item) => item.gender === "MALE"
    )?.value || 0;
  const femalePercentage =
    creatorData?.profile?.audience?.gender_distribution?.find(
      (item) => item.gender === "FEMALE"
    )?.value || 0;
  const othersPercentage =
    creatorData?.profile?.audience?.gender_distribution?.find(
      (item) => item.gender === "OTHERS"
    )?.value || 0;

  const [locationType, setLocationType] = useState<"countries" | "cities">(
    "countries"
  );

  const getLocationData = () => {
    if (locationType === "cities")
      return (
        creatorData?.profile?.audience?.cities?.map((item) => ({
          name: item.name,
          value: item.value,
        })) || []
      );
    return (
      creatorData?.profile?.audience?.countries?.map((item) => ({
        name:
          new Intl.DisplayNames(["en"], { type: "region" }).of(item.code) || "",
        value: item.value,
      })) || []
    );
  };

  return (
    <div>
      <Title level={4}>Audience</Title>
      <Row justify={"space-between"}>
        <Col span={11}>
          <Col span={10}>
            <Card style={{ height: "400px", width: "360px" }}>
              <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
                Gender Distribution
              </Title>
              <div
                style={{
                  display: "flex",
                }}>
                <Doughnut
                  options={{
                    cutout: "95%",
                    plugins: {
                      legend: { position: "top" },
                      datalabels: {
                        anchor: "start",
                        align: "start",
                        font: {
                          size: 16,
                          style: "normal",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [
                      `Male  ${malePercentage.toPrecision(2)} %`,
                      `Female  ${femalePercentage.toPrecision(2)} %`,
                      `Others  ${othersPercentage.toPrecision(2)} %`,
                    ],
                    datasets: [
                      {
                        label: "",
                        data: [
                          malePercentage.toPrecision(2),
                          femalePercentage.toPrecision(2),
                          othersPercentage.toPrecision(2),
                        ],
                        backgroundColor: [
                          "rgb(104, 13, 228)",
                          "rgb(4, 219, 142)",
                          "rgb(229, 229, 232)",
                        ],
                        // borderColor: ["rgb(4, 219, 142)", "rgb(229, 229, 232)"],
                        borderWidth: 1,
                        borderJoinStyle: "round",
                        borderRadius: 10,
                        hoverOffset: 4,
                        offset: 0,
                      },
                    ],
                  }}
                />
              </div>
            </Card>
          </Col>
        </Col>
        <Col span={10}>
          <Card style={{ height: "420px", width: "360px" }}>
            <Title level={5} style={{ marginBlock: 0, marginBottom: 10 }}>
              Audience Location
            </Title>
            {creatorData?.work_platform?.name === "Instagram" ? (
              <Radio.Group
                options={[
                  { label: "Countries", value: "countries" },
                  { label: "Cities", value: "cities" },
                ]}
                optionType="button"
                buttonStyle="solid"
                value={locationType}
                onChange={(e) => setLocationType(e.target.value)}
              />
            ) : (
              <Text type="secondary" style={{ display: "block" }}>
                Top audience location by counties
              </Text>
            )}

            <StatsItem items={getLocationData()} />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Demographics />
      {creatorData?.work_platform?.name === "Instagram" && <BrandInterests />}
    </div>
  );
};

export default Audience;
