import React from "react";
import Nav from "../Locked/Nav";
import { Row, Col, Spin, Card, Button, Avatar, Divider, message } from "antd";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { acceptInvitation, getOrgAppState } from "../../handler/organization";

import { getDefaultLottieOptions, handleCookieChange } from "../../utils";

import animationData from "../assets/lottie/Rocket.json";

const { Meta } = Card;

const AcceptInvite = () => {
  const [orgUserKey, orgUserFn] = getOrgAppState();

  const { data, isLoading } = useQuery(orgUserKey, orgUserFn);

  const navigate = useNavigate();

  const acceptInviteMutation = useMutation(acceptInvitation, {
    onSuccess: (res, { brandOrgRoleId }) => {
      handleCookieChange(
        data?.data?.invitations?.find((item) => item._id === brandOrgRoleId)
          ?.brandId || ""
      );
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(
        `${String((err?.response?.data as any)?.message || "").substring(
          0,
          40
        )} Please try after sometime`
      );
    },
  });

  if (isLoading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <div>
      <Nav />
      <Row justify="center" style={{ marginTop: 150 }}>
        <Col span={14}>
          <h2>You have been invited in the following organizations</h2>
          <h3
            style={{
              color: "rgba(0, 0, 0, 0.6)",
              // fontSize: "13px",
              fontWeight: "400",
              marginBlock: 5,
            }}>
            Accept an invite{" "}
          </h3>
          <Row gutter={[20, 20]} align="middle">
            {data?.data?.invitations?.map((inviteItem) => (
              <Col>
                <Card
                  style={{ width: 300, marginTop: 16 }}
                  actions={[
                    <Button
                      type="primary"
                      loading={
                        acceptInviteMutation?.isLoading &&
                        acceptInviteMutation?.variables?.brandOrgRoleId ===
                          inviteItem?._id
                      }
                      onClick={() =>
                        acceptInviteMutation.mutate({
                          brandOrgRoleId: inviteItem?._id,
                        })
                      }>
                      {" "}
                      Accept
                    </Button>,
                  ]}>
                  <Meta
                    avatar={
                      <Avatar
                        src={inviteItem?.brandUser?.businessDetails?.logoUrl}
                      />
                    }
                    title={
                      inviteItem?.brandUser?.businessDetails?.name +
                      " Organization"
                    }
                    description={`Invited by ${inviteItem?.brandUser?.name}`}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Divider>Or</Divider>
          <Row justify="center">
            <Button
              type="primary"
              size="large"
              style={{ borderRadius: "8px" }}
              onClick={() => navigate("/activate")}>
              Create your own organization
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AcceptInvite;
