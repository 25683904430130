import React, { useState } from "react";
import { Modal, Input, InputNumber, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { postDraftChangesV2 } from "../../../../../../../../handler/campaign/applicants";
import { CardType } from "../../../../../../../Chat/ChatWindow/Message/CardTypes";
import { AxiosError } from "axios";

interface ChangesModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  workflowId: string;
  prevDraftTimeLineId: string;
}

const ChangesModal: React.FC<ChangesModalProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  workflowId,
  prevDraftTimeLineId,
}) => {
  const { TextArea } = Input;
  const queryClient = useQueryClient();

  const postDraftMutation = useMutation(postDraftChangesV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries([
        "chat",
        CardType.deliverableDraftSentType,
      ]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const [inputVal, setInputVal] = useState<{
    deadline: number;
    comments: string;
  }>({ deadline: 2, comments: "" });

  return (
    <Modal
      title="Approve Changes"
      visible={isVisible}
      onOk={() =>
        postDraftMutation.mutate({
          workflowId,
          changes: inputVal.comments,
          prevDraftTimeLineId,
        })
      }
      onCancel={handleCancel}
      centered
      okButtonProps={{ loading: postDraftMutation.isLoading }}>
      <TextArea
        autoSize={{ minRows: 4 }}
        placeholder="Add any comments here"
        value={inputVal.comments}
        onChange={(e) =>
          setInputVal((inputVal) => {
            return { ...inputVal, comments: e.target.value };
          })
        }
      />
      {/* <p
        style={{
          margin: "10px 0 4px",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
        }}>
        Set a deadline
      </p>
      <InputNumber
        size="middle"
        addonAfter="days"
        type={"number"}
        value={inputVal.deadline}
        onChange={(val) =>
          setInputVal((inputVal) => {
            return { ...inputVal, deadline: val as number };
          })
        }
      /> */}
    </Modal>
  );
};

export default ChangesModal;
