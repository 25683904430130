import React from "react";
import { Space } from "antd";
import clsx from "clsx";

import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";

import styles from "./creator.module.css";

import { CreatorType } from "..";

const CreatorCard: React.FC<{
  creator: CreatorType;
  handleClick: (creator: CreatorType) => void;
  isSelected: boolean;
}> = ({ creator, handleClick, isSelected }) => {
  return (
    <div
      className={clsx(styles.container, { [styles.selectedItem]: isSelected })}
      onClick={() => handleClick(creator)}>
      <Space size={16} align="center">
        <img
          className={styles.profile}
          alt="profile"
          src={creator?.profilePic}
        />
        <div>
          <p className={styles.name}>{creator?.name}</p>

          <Space align="center" size={2}>
            {creator?.platform === "instagram" ? (
              <AiOutlineInstagram
                size={22}
                height={22}
                width={22}
                color="rgba(0, 0, 0, 0.5)"
              />
            ) : (
              <AiOutlineYoutube
                height={22}
                width={22}
                color="rgba(0, 0, 0, 0.5)"
              />
            )}

            <p className={styles.username}>{creator?.username}</p>
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default CreatorCard;
