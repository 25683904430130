import React from "react";
import { Typography, Row, Col, Button } from "antd";
import InputRange from "../Common/InputRange";
import InputList from "../Common/InputList";
import CustomMultiSelect from "../Common/CustomMultiSelect";
import MultiInputRange from "../Common/MultiInputRange";
import RangeSlider from "../Common/RangeSlider";
import ContactOptions from "../Common/ContactOptions";
import CustomCheckBox from "../Common/CustomCheckBox";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import {
  IGFilters,
  TikTokFilters,
  YtFilters,
} from "../../../typings/CreatorSearch";
import DisabledPopOver from "../../shared/DisabledPopOver";
import CustomDrawer from "../Common/CustomDrawer";
import { sleep } from "../../../utils";

const { Title } = Typography;

interface Props {
  visible: boolean;
  handleClose: () => void;
}

const FiltersDrawer: React.FC<Props> = ({ handleClose, visible }) => {
  const { getResults, setSearchParams, searchParams, ObjToFilter } =
    useCreatorSearchContext();

  const filtersToSelect =
    searchParams?.platform === "youtube"
      ? YtFilters
      : searchParams?.platform === "tiktok"
      ? TikTokFilters
      : IGFilters;

  const creatorFilters = filtersToSelect?.filter(
    (item) => item.fType === "creator"
  );
  const audienceFilters = filtersToSelect?.filter(
    (item) => item.fType === "audience"
  );
  const performanceFilters = filtersToSelect?.filter(
    (item) => item.fType === "performance"
  );

  return (
    <CustomDrawer
      visible={visible}
      handleClose={() => handleClose()}
      width={1100}
      footer={
        <Row justify={"space-between"}>
          <DisabledPopOver
            // isDisabled={Object.keys(ObjToFilter || {}).length < 3}
            isDisabled={false}
            customText={`You need to select at least 3 filters to enable Search`}
          >
            <Button
              type="primary"
              onClick={() => {
                getResults();
                handleClose();
              }}
              size="large"
              // disabled={Object.keys(ObjToFilter || {}).length < 3}
            >
              Get Results
            </Button>
          </DisabledPopOver>

          <Button
            disabled={Object.keys(ObjToFilter || {}).length < 1}
            onClick={() => {
              setSearchParams({
                limit: 10,
                offset: 0,
                platform: searchParams.platform,
                sort_by: searchParams.sort_by,
                searchType: searchParams.searchType,
              });
              sleep(10).then(() => getResults());
            }}
            size="large"
          >
            Remove Filters
          </Button>
        </Row>
      }
    >
      <Title level={2}>All Filters</Title>
      <Row
        justify={"space-between"}
        style={{ height: "80vh", overflowY: "scroll" }}
      >
        <Col
          span={7}
          style={{
            display: "grid",
            gap: 20,
            height: "fit-content",
          }}
        >
          <Title level={3} style={{ marginBottom: 0 }}>
            Creator Filters
          </Title>
          {creatorFilters?.map((igItem, idx) => {
            if (igItem?.compType === "inputRange") {
              return (
                <InputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }
            if (igItem?.compType === "checkbox") {
              return (
                <CustomCheckBox
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }
            if (igItem?.compType === "contact") {
              return (
                <ContactOptions
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }

            if (igItem?.compType === "list") {
              return (
                <InputList
                  fType={igItem?.fType}
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  maxCount={igItem?.maxCount}
                />
              );
            }

            if (igItem?.compType === "custom-multi-select")
              return (
                <CustomMultiSelect
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            if (igItem?.compType === "custom-multi-inputRange")
              return (
                <MultiInputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            if (igItem?.compType === "slider")
              return (
                <RangeSlider
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
          })}
        </Col>
        <Col
          span={7}
          style={{
            display: "grid",
            gap: 20,
            height: "fit-content",
          }}
        >
          <Title level={3} style={{ marginBottom: 0 }}>
            Audience Filters
          </Title>
          {audienceFilters?.map((igItem, idx) => {
            if (igItem?.compType === "inputRange") {
              return (
                <InputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                />
              );
            }

            if (igItem?.compType === "checkbox") {
              return (
                <CustomCheckBox
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }
            if (igItem?.compType === "list") {
              return (
                <InputList
                  fType={igItem?.fType}
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                />
              );
            }

            if (igItem?.compType === "custom-multi-select")
              return (
                <CustomMultiSelect
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                />
              );
            if (igItem?.compType === "custom-multi-inputRange")
              return (
                <MultiInputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                />
              );
            if (igItem?.compType === "slider")
              return (
                <RangeSlider
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                />
              );
          })}
        </Col>
        <Col
          span={7}
          style={{
            display: "grid",
            gap: 20,
            height: "fit-content",
          }}
        >
          <Title level={3} style={{ marginBottom: 0 }}>
            Performance Filters
          </Title>
          {performanceFilters?.map((igItem, idx) => {
            if (igItem?.compType === "inputRange") {
              return (
                <InputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }
            if (igItem?.compType === "checkbox") {
              return (
                <CustomCheckBox
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            }

            if (igItem?.compType === "list") {
              return (
                <InputList
                  fType={igItem?.fType}
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  maxCount={igItem?.maxCount}
                />
              );
            }

            if (igItem?.compType === "custom-multi-select")
              return (
                <CustomMultiSelect
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            if (igItem?.compType === "custom-multi-inputRange")
              return (
                <MultiInputRange
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
            if (igItem?.compType === "slider")
              return (
                <RangeSlider
                  compType={igItem?.compType}
                  desc={igItem?.desc}
                  facetName={igItem?.facetName}
                  isSearchable={igItem?.isSearchable}
                  title={igItem?.title}
                  placeHolder={igItem?.placeHolder}
                  type={igItem?.type}
                  key={idx}
                  fType={igItem?.fType}
                  maxCount={igItem?.maxCount}
                />
              );
          })}
        </Col>
      </Row>
    </CustomDrawer>
  );
};

export default FiltersDrawer;
