import React, { useState } from "react";
import {
  Spin,
  Row,
  Col,
  Tag,
  Button,
  Empty,
  Space,
  message,
  Alert,
} from "antd";
import { format } from "date-fns";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { auth } from "../../../firebase";

import {
  getInvitations,
  acceptInvitation,
} from "../../../handler/organization";

import styles from "../styles.module.css";

const Received = () => {
  const queryClient = useQueryClient();
  const [queryKey, queryFn] = getInvitations({
    send: false,
    status: "Invited",
  });

  const { data: invitationData, isLoading } = useQuery(queryKey, queryFn);

  const acceptInvitationMutation = useMutation(acceptInvitation, {
    onSuccess: () => {
      message.success("Invitation accepted successfully");
      queryClient.invalidateQueries("invitations");
      auth.currentUser?.reload();
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  if (isLoading) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }
  return (
    <>
      {invitationData?.data.length === 0 ||
      (invitationData &&
        invitationData?.data?.every(
          (item) => item.status === "Accepted"
        )) ? null : (
        <div style={{ display: "grid", gap: "10px", marginTop: "50px" }}>
          {invitationData?.data
            .filter((item) => item.status !== "Accepted")
            .map((item) => {
              return (
                <Alert
                  message={
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#282626c7",
                      }}>
                      Invitation Received
                    </div>
                  }
                  style={{ borderRadius: "8px" }}
                  description={
                    <div>
                      <section
                        style={{
                          fontWeight: 300,
                          margin: "10px 0",
                          color: " rgb(104 82 82 / 85%)",
                        }}>
                        {" "}
                        You have been invited by <b>
                          {item?.brandUser?.name}
                        </b>{" "}
                        on{" "}
                        {format(new Date(item.createdDateTime), "d LLL, yyyy")}{" "}
                        to be part of their organization
                      </section>
                      <Button
                        type="primary"
                        disabled={item.status === "Accepted"}
                        style={{ borderRadius: "4px" }}
                        loading={acceptInvitationMutation.isLoading}
                        onClick={() =>
                          acceptInvitationMutation.mutate({
                            brandOrgRoleId: item?._id,
                          })
                        }>
                        {item.status === "Accepted" ? "Accepted" : "Accept"}
                      </Button>
                    </div>
                  }
                  type="info"
                />
              );
            })}
        </div>
      )}
    </>
  );
};

export default Received;
