import { sendRequest } from "../helper";

type InfluencerCampaignsResult = {
  status: string | null;
  message: string | null;
  data: {
    nextPageToken: string;
    campaigns: {
      _id: string;
      brandId: string;
      campaignId: string;
      platform: "instagram" | "youtube";
      socialMediaId: string;
      influencerId: string;
      socialMediaKitId: string;
      createdDateTime: string;
      campaign: {
        _id: string;
        campaignName: string;
        noOfMedias: number;
      };
    }[];
  };
};

export const getInfluencerCampaignsReport = (
  socialMediaId: string,
  limit?: number
) => {
  const queryKey = ["report", "influencer", socialMediaId, "campaigns", limit];
  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<InfluencerCampaignsResult>(
      "get",
      `/brand/brand-influencer/report-only-campaign?limit=${
        limit ? limit : 4
      }&socialMediaId=${socialMediaId}${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
