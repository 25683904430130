import React from "react";
import { ConfigTypeSingle } from "../../../typings/CreatorSearch";
import { useState, useEffect } from "react";
import { Select, Space, Typography, Button } from "antd";
import { FiPlus, FiMinus } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

import { useQuery } from "react-query";

import { getFacetValues } from "../../../handler/insightIq";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import HelpPopOver from "./HelpPopover";

const { Title, Text } = Typography;

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}

const InputRange: React.FC<Props> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);
  const [query, setQuery] = useState<{
    search: string;
    min?: number;
    max?: number;
  }>({ search: "", min: undefined, max: undefined });

  const [getFacetMinKey, getFacetMinValue] = getFacetValues({
    facetName: `${facetName}_min`,
    body: { min: query.min, max: query.max },
  });
  const [getFacetMaxKey, getFacetMaxValue] = getFacetValues({
    facetName: `${facetName}_max`,
    body: { min: query.min, max: query.max },
  });

  const { data: facetMinData } = useQuery(getFacetMinKey, getFacetMinValue, {
    enabled: visible,
  });

  const { data: facetMaxData } = useQuery(getFacetMaxKey, getFacetMaxValue, {
    enabled: visible,
  });

  useEffect(() => {
    if (!visible) {
      setQuery({ search: "", min: undefined, max: undefined });
    }
  }, [visible]);

  const { searchParams, setSearchParams } = useCreatorSearchContext();

  const selectedValueFrom = (searchParams[facetName] || ({} as any))?.min;
  const selectedValueTo = (searchParams[facetName] || ({} as any))?.max;

  const handleChangeObject = ({
    type,
    value,
  }: {
    type: "min" | "max";
    value: string;
  }) => {
    const newFacetObject = {
      ...searchParams,
      [facetName]: { ...searchParams[facetName], [type]: value },
      filterTags: {
        ...searchParams["filterTags"],
        [facetName]: { ...searchParams[facetName], [type]: value },
      },
    };
    setSearchParams((searchParams) => ({ ...searchParams, ...newFacetObject }));
  };

  return (
    <div>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      {visible && (
        <Space wrap align="start">
          <div style={{ width: "100px", display: "grid", gap: "2px" }}>
            <Text type="secondary">From</Text>
            <Select
              searchValue={query.search}
              value={selectedValueFrom}
              defaultActiveFirstOption={false}
              onSearch={(value) =>
                setQuery((query) => ({ ...query, search: value }))
              }
              onChange={(value) => handleChangeObject({ type: "min", value })}
              notFoundContent={null}
              options={(facetMinData?.data || []).map((d) => ({
                value: d.value,
                label: d.name,
              }))}
            />
          </div>
          <div style={{ width: "100px", display: "grid", gap: "2px" }}>
            <Text type="secondary">To</Text>
            <Select
              searchValue={query.search}
              showSearch={isSearchable}
              value={selectedValueTo}
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              onSearch={(value) =>
                setQuery((query) => ({ ...query, search: value }))
              }
              onChange={(value) => handleChangeObject({ type: "max", value })}
              notFoundContent={null}
              options={(facetMaxData?.data || []).map((d) => ({
                value: d.value,
                label: d.name,
              }))}
            />
          </div>
          {searchParams[facetName] && (
            <Button
              icon={<TiDelete size={18} />}
              danger
              onClick={() => {
                setSearchParams((searchParams) => ({
                  ...searchParams,
                  [facetName]: undefined,
                  filterTags: {
                    ...searchParams["filterTags"],
                    [facetName]: undefined,
                  },
                }));
              }}
              type="link"
            />
          )}
        </Space>
      )}
    </div>
  );
};

export default InputRange;
