import React, { useState, useRef, useEffect } from "react";
import { Space, Row, Col, Button } from "antd";
import { FiArrowUpRight } from "react-icons/fi";
import { useQuery } from "react-query";
import { MdTimeline } from "react-icons/md";
import YouTube from "react-youtube";
import { Doughnut, Bar } from "react-chartjs-2";

import LineGraph from "../../../../ProfileAnalysis/BasicInsights/LineGraph";
import Option from "../../../../shared/UI/Option";
import MetricCard from "../../ReportScreen/OverviewSection/MetricCard";
import StatsCard from "../../../../ProfileAnalysis/AudienceInsights/StatsCard";

import {
  igReelMetrics,
  igStoryMetrics,
  igPostMetrics,
  ytMetrics,
} from "../../../../../constants";
import {
  IndividualInfluencerReport,
  YtMediaResponseType,
} from "../../../../../typings";

import styles from "./report.module.css";

import { capitalizeFirstLetter } from "../../../../../utils";
import { formatData } from "../../../../../utils";

import { getConfigurationReportOnlyCampaign } from "../../../../../handler/report";

interface IndividualReportProps {
  data: IndividualInfluencerReport;
  handleSelect: (id: string) => void;
  selectedId: string;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const IndividualReport: React.FC<IndividualReportProps> = ({
  data,
  handleSelect,
  selectedId,
  campaignId,
  platform,
}) => {
  const [configKey, configFn] = getConfigurationReportOnlyCampaign({
    platform,
    campaignId,
  });

  const youtubeRef = useRef(null);

  const { data: configData } = useQuery(configKey, configFn, {
    enabled: Boolean(campaignId && platform),
  });
  const [seek, setSeek] = useState<number>(0);

  const opts = {
    height: "280",
    width: "450",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      start: seek,
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (youtubeRef.current && seek !== 0) {
      setTimeout(
        () => (youtubeRef?.current as any)?.internalPlayer?.pauseVideo(),
        300
      );
    }
  }, [seek, youtubeRef]);

  const getMedia = () => {
    let mappedMediaItem: { [key: string]: any } = {};
    try {
      const mediaItem: { [key: string]: any } =
        data?.reportInfluencer?.find(
          (reportItem) => reportItem.deliverableMediaId === selectedId
        )?.media || {};

      const deliverableType =
        data?.reportInfluencer?.find(
          (reportItem) => reportItem.deliverableMediaId === selectedId
        )?.deliverableType || "";

      const mappedDeliverableArray =
        deliverableType === "IG-Reel"
          ? igReelMetrics
          : deliverableType === "IG-Feed-Story"
          ? igStoryMetrics
          : deliverableType === "YT-Video"
          ? ytMetrics
          : igPostMetrics;

      mappedDeliverableArray.forEach((delItem) => {
        if (delItem in mediaItem) {
          mappedMediaItem = {
            ...mappedMediaItem,
            [delItem]: mediaItem[delItem],
          };
        }
      });
      return mappedMediaItem;
    } catch (error) {
      console.log(error);
      return mappedMediaItem;
    }
  };

  const getTotalMedia = () => {
    let mappedMediaItem: { [key: string]: any } = {};

    data?.reportInfluencer?.forEach((reportItem) => {
      const mappedDeliverableArray =
        reportItem?.deliverableType === "IG-Reel"
          ? igReelMetrics
          : reportItem?.deliverableType === "IG-Feed-Story"
          ? igStoryMetrics
          : reportItem?.deliverableType === "YT-Video"
          ? ytMetrics
          : igPostMetrics;

      Object.entries(reportItem?.media || {}).forEach(([key, value]) => {
        if (mappedDeliverableArray.includes(key)) {
          mappedMediaItem = {
            ...mappedMediaItem,
            [key]:
              key in mappedMediaItem ? mappedMediaItem[key] + value : value,
          };
        }
      });
    });

    return mappedMediaItem;
  };

  const selectedMedia = data?.reportInfluencer?.find(
    (reportItem) => reportItem.deliverableMediaId === selectedId
  )?.media as YtMediaResponseType;

  const extractData = () => {
    let totalObjectData: { [key: string]: { [key: string]: number } } = {};

    if (!selectedMedia) return totalObjectData;

    try {
      Object.entries(selectedMedia as { [key: string]: any }).forEach(
        ([objKey, objValue]) => {
          if (
            objKey !== "basicStats_lifetime" &&
            objKey !== "savedMedias" &&
            objKey !== "audienceRetention_lifetime" &&
            typeof objValue === "object" &&
            !objValue?.isError &&
            !objValue?.isErrorTransformingPayload &&
            !Object.is(objValue?.data, null) &&
            !Object.is(objValue?.data, undefined)
          ) {
            if (!(objKey in totalObjectData)) {
              totalObjectData = { ...totalObjectData, [objKey]: {} };
            }
            Object.entries(objValue?.data as { [key: string]: any }).forEach(
              ([internalKey, internalObj]) => {
                totalObjectData = {
                  ...totalObjectData,
                  [objKey]: {
                    ...totalObjectData[objKey],
                    [internalKey]:
                      internalObj?.views || internalObj?.viewerPercentage || 0,
                  },
                };
              }
            );
          }
        }
      );
    } catch (error) {
      console.log("sd", error);
      return totalObjectData;
    }
    return totalObjectData;
  };

  const getDayViews = () => {
    let result: { labels: string[]; values: number[] } = {
      labels: [],
      values: [],
    };
    const response = extractData();

    if (!("basicStats_last_30_days" in response)) return result;

    Object.entries(response["basicStats_last_30_days"]).forEach(
      ([key, value]) => {
        result = {
          labels: [...result.labels, key.split("$").at(-1) || ""],
          values: [...result.values, value],
        };
      }
    );

    return result;
  };

  const getCountryViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = extractData();
    if (!("countryStats_lifetime" in response)) return result;

    Object.entries(response["countryStats_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getSubscribedViews = () => {
    const response = extractData();
    if (!("subscribedStatus_lifetime" in response))
      return { subs: 0, unSubs: 0 };
    let subs = response["subscribedStatus_lifetime"]["$SUBSCRIBED"] || 0;
    let unSubs = response["subscribedStatus_lifetime"]["$UNSUBSCRIBED"] || 0;

    return { subs, unSubs };
  };

  const getPlaybackLocationsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = extractData();
    if (!("playBackLocation_lifetime" in response)) return result;

    Object.entries(response["playBackLocation_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getOsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = extractData();
    if (!("operatingSystemDeviceType_lifetime" in response)) return result;

    Object.entries(response["operatingSystemDeviceType_lifetime"]).forEach(
      ([key, value]) => {
        result.push({
          value,
          label: key.split("**$").at(-1)?.split("$").join(" ") || "",
        });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getTrafficViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = extractData();
    if (!("trafficSource_lifetime" in response)) return result;

    Object.entries(response["trafficSource_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getDemographicViews = () => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};

    const response = extractData();
    if (!("viewerDemographics_lifetime" in response)) return totalObject;

    Object.entries(response["viewerDemographics_lifetime"]).forEach(
      ([key, value]) => {
        // const age_genderKey = key.split("**$").at(-1) || "age18-24_female";
        const ageKey = key.split("$").at(1) || "age18-24";
        const genderKey = key.split("$").at(-1) || "female";
        if (!(ageKey in totalObject)) {
          totalObject = { ...totalObject, [ageKey]: {} };
        }

        if (!(genderKey in totalObject[ageKey])) {
          totalObject = {
            ...totalObject,
            [ageKey]: { ...totalObject[ageKey], [genderKey]: value },
          };
        }
      }
    );

    return totalObject;
  };

  const totalCountryViews =
    getCountryViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalLocationViews =
    getPlaybackLocationsViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalTrafficViews =
    getTrafficViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalOsViews =
    getOsViews().reduce((prev, current) => prev + (current.value || 0), 0) || 1;

  const barData = {
    labels: Object.keys(getDemographicViews()),
    datasets: [
      {
        label: "Female",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.female || 0
        ),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.male || 0
        ),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.genderUserSpecified || 0
        ),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  };

  return (
    <div className={styles.grid}>
      <p className={styles.title}>Report</p>
      <Space size={10}>
        <img
          src={`/assets/icons/${
            platform === "instagram" ? "meta" : "google"
          }.svg`}
          alt="google"
          width={48}
          height={24}
        />
        <p className={styles.desc}>
          We get the data from{" "}
          {platform === "instagram" ? "Facebook" : "Google"} APIs.
          <br />
          We provide with only first part consent based data.{" "}
          <span className={styles.learnMore}>Learn More</span>
        </p>
      </Space>
      <Space size={10} style={{ maxWidth: 1500, overflowX: "scroll" }}>
        <Option
          title="Total"
          isSelected={selectedId === ""}
          handleClick={() => handleSelect("")}
        />
        {data?.reportInfluencer?.map((reportItem) => (
          <Option
            title={reportItem?.deliverableType?.split("-").at(-1)}
            isSelected={selectedId === reportItem.deliverableMediaId}
            handleClick={() => handleSelect(reportItem.deliverableMediaId)}
          />
        ))}
      </Space>
      <Row gutter={[20, 20]} align="middle" wrap>
        {selectedId !== "" && (
          <Col span={platform === "instagram" ? 5 : 24}>
            {platform === "youtube" ? (
              <Row gutter={[20, 20]}>
                <Col span={9}>
                  <YouTube
                    videoId={selectedMedia?.videoId}
                    opts={opts}
                    ref={youtubeRef}
                    onReady={(event) => {
                      event?.target?.mute();
                      setTimeout(() => event?.target?.pauseVideo(), 1000);
                    }}
                  />
                </Col>
                {!selectedMedia?.audienceRetention_lifetime?.isError &&
                  !selectedMedia?.audienceRetention_lifetime
                    ?.isErrorTransformingPayload &&
                  !Object.is(
                    selectedMedia?.audienceRetention_lifetime?.data,
                    null
                  ) &&
                  !Object.is(
                    selectedMedia?.audienceRetention_lifetime?.data,
                    undefined
                  ) && (
                    <Col span={12} offset={2}>
                      <LineGraph
                        title={"Audience Retention"}
                        optionsItemArray={[]}
                        graphData={Object.values(
                          selectedMedia?.audienceRetention_lifetime?.data
                        ).map(
                          (item) =>
                            item?.audienceWatchRatio *
                            (selectedMedia?.views || 0)
                        )}
                        labelArray={Object.keys(
                          selectedMedia?.audienceRetention_lifetime?.data
                        ).map((item) =>
                          (
                            Number(item.split("$").at(-1) || "0.1") *
                            (selectedMedia?.durationSeconds || 0)
                          ).toString()
                        )}
                        isAudience
                        params={{
                          pointRadius: 1.5,
                          ylabel: "Viewers",
                          xlabel: "Time Elapsed (s)",
                        }}
                        handleClick={(_y, x) => {
                          setSeek(x);
                        }}
                        hidePin
                      />
                    </Col>
                  )}

                {getCountryViews().length > 0 && (
                  <Col span={12}>
                    <div className={styles.graphContainer} key={1}>
                      <p className={styles.graphLabel}>Country wise split</p>
                      <StatsCard
                        items={getCountryViews().map((item) => ({
                          label: item.label,
                          percent: (item.value / totalCountryViews) * 100,
                        }))}
                      />
                    </div>
                  </Col>
                )}
                {!(
                  getSubscribedViews().subs === 0 &&
                  getSubscribedViews().unSubs === 0
                ) && (
                  <Col span={12}>
                    <div
                      className={styles.container}
                      key={2}
                      style={{ height: "380px" }}>
                      <Row
                        justify="space-between"
                        style={{ width: "100%" }}
                        gutter={[10, 10]}>
                        <Col span={24}>
                          <p className={styles.label}>Views by subscribers</p>
                        </Col>
                        <Col span={24}>
                          <p className={styles.sublabel}>
                            <MdTimeline size={15} color="#000000B2" />
                            Views by subscribers
                          </p>
                        </Col>
                      </Row>
                      <div style={{ height: "290px" }}>
                        <Doughnut
                          // style={{ height: "285px" }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                              legend: { position: "top" },
                              datalabels: {
                                anchor: "end",
                                align: "start",
                                formatter: (value) =>
                                  formatData(value || 0, "a"),
                                font: {
                                  size: 16,
                                  style: "normal",
                                },
                              },
                            },
                          }}
                          data={{
                            labels: ["Subscribed", "Not-Subscribed"],
                            datasets: [
                              {
                                label: "No",
                                data: [
                                  getSubscribedViews().subs,
                                  getSubscribedViews().unSubs,
                                ],
                                backgroundColor: ["#FF6384", "#FFCD56"],
                                borderColor: ["#FF6384", "#FFCD56"],
                                borderWidth: 1,
                                borderJoinStyle: "round",
                                borderRadius: 10,
                                hoverOffset: 4,
                                offset: 0,
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                )}

                {getPlaybackLocationsViews().length > 0 && (
                  <Col span={12}>
                    <div className={styles.graphContainer} key={3}>
                      <p className={styles.graphLabel}>
                        PlaybackLocation wise split
                      </p>
                      <StatsCard
                        items={getPlaybackLocationsViews().map((item) => ({
                          label: capitalizeFirstLetter(
                            item.label.split("_").join(" ")
                          ),
                          percent: (item.value / totalLocationViews) * 100,
                        }))}
                      />
                    </div>
                  </Col>
                )}

                {getTrafficViews().length > 0 && (
                  <Col span={12}>
                    <div className={styles.graphContainer} key={4}>
                      <p className={styles.graphLabel}>Traffic wise split</p>
                      <StatsCard
                        items={getTrafficViews().map((item) => ({
                          label: capitalizeFirstLetter(
                            item.label.split("_").join(" ")
                          ),
                          percent: (item.value / totalTrafficViews) * 100,
                        }))}
                      />
                    </div>
                  </Col>
                )}
                {getOsViews().length > 0 && (
                  <Col span={12}>
                    <div className={styles.graphContainer} key={5}>
                      <p className={styles.graphLabel}>Device wise split</p>
                      <StatsCard
                        items={getOsViews().map((item) => ({
                          label: capitalizeFirstLetter(
                            item.label.split("_").join(" ")
                          ),
                          percent: (item.value / totalOsViews) * 100,
                        }))}
                      />
                    </div>
                  </Col>
                )}
                {Object.keys(getDemographicViews()).length > 0 && (
                  <Col span={12}>
                    <div className={styles.graphContainer}>
                      <p className={styles.graphLabel}>Age-Gender Ratio</p>
                      <div
                        style={{
                          width: "100%",
                          height: "308px",
                        }}>
                        <Bar
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "top" as const,
                              },
                              title: {
                                display: false,
                              },
                              datalabels: {
                                anchor: "end",
                                align: "top",
                                formatter: (value) =>
                                  `${formatData(value || 0, "0.0a")}%`,
                              },
                            },
                          }}
                          data={barData}
                          style={{ height: "320px" }}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {getDayViews().values.length >= 0 && (
                  <Col span={12}>
                    <LineGraph
                      key={0}
                      title="Views per day"
                      optionsItemArray={[]}
                      selectedOption={""}
                      labelArray={getDayViews().labels}
                      metric={formatData(
                        getDayViews().values.reduce(
                          (prev, current) => prev + current,
                          0
                        ),
                        "a"
                      )}
                      metricLabel={` Total Views`}
                      description={<></>}
                      graphData={getDayViews().values}
                      secondaryDescription={<p>Views per day</p>}
                      secondaryIcon={<MdTimeline size={15} color="#000000B2" />}
                      barColor="#398200"
                    />
                  </Col>
                )}
              </Row>
            ) : (
              <>
                {data?.reportInfluencer?.find(
                  (reportItem) => reportItem.deliverableMediaId === selectedId
                )?.media?.savedMedias[0]?.mediaType === "VIDEO" ? (
                  <video
                    muted={true}
                    src={
                      data?.reportInfluencer?.find(
                        (reportItem) =>
                          reportItem.deliverableMediaId === selectedId
                      )?.media?.savedMedias[0]?.url
                    }
                    controls={true}
                    style={{ objectFit: "cover" }}
                    width={"100%"}
                    height={"100%"}
                    className={styles.image}
                  />
                ) : (
                  <img
                    src={
                      data?.reportInfluencer?.find(
                        (reportItem) =>
                          reportItem.deliverableMediaId === selectedId
                      )?.media?.savedMedias[0]?.url
                    }
                    className={styles.image}
                    alt="media"
                  />
                )}
              </>
            )}

            <div
              className={styles.instaContainer}
              style={{ cursor: "pointer" }}>
              <p
                className={styles.instaButton}
                onClick={() =>
                  window.open(
                    data?.reportInfluencer?.find(
                      (reportItem) =>
                        reportItem.deliverableMediaId === selectedId
                    )?.media?.permalink || ""
                  )
                }>
                Open in {platform}
              </p>
              <FiArrowUpRight color="#000000B2" size={18} />
            </div>
          </Col>
        )}
        <Col
          span={selectedId === "" || platform === "youtube" ? 24 : 19}
          style={{ height: "40vh", overflowY: "auto" }}>
          <Row gutter={[20, 20]}>
            {Object.entries(
              selectedId === "" ? getTotalMedia() : getMedia()
            ).map(([key, value]) => {
              return (
                <Col span={selectedId ? 7 : 6}>
                  <MetricCard
                    desc={
                      configData?.data?.reportConfiguration?.fields?.find(
                        (configItem) => configItem.variableName === key
                      )?.description || ""
                    }
                    metric={value}
                    title={capitalizeFirstLetter(
                      configData?.data?.reportConfiguration?.fields?.find(
                        (configItem) => configItem.variableName === key
                      )?.name || ""
                    )}
                    key={
                      configData?.data?.reportConfiguration?.fields?.find(
                        (configItem) => configItem.variableName === key
                      )?.name
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IndividualReport;
