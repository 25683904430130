import React from "react";
import { Space, Row, Col, Divider, Tooltip } from "antd";
import { RefinementList, RangeInput } from "react-instantsearch";
// import { FaQuestionCircle } from "react-icons/fa";
import { GoQuestion } from "react-icons/go";

import { IGConfig } from "../../constants";

import AgeRangeSlider from "../../../SaasDiscovery/Filters/AgeRangeSlider";

import MultiDimensionFilter from "../Custom/MultiDimensionFilter";

import styles from "../styles.module.css";

const IGFilters = () => {
  return (
    <div style={{ display: "grid", gap: "20px" }}>
      <p className={styles.filterHeading}>
        Influencer filters •{" "}
        <span className={styles.filterDesc}>
          Try starting with number of followers and audience filters narrowing
          your search
        </span>
      </p>
      <Row wrap gutter={[20, 50]}>
        {IGConfig.filter((item) => (item?.type || "") !== "audience").map(
          ({ title, compType, facetName, isSearchable, desc, placeHolder }) => {
            return (
              <Col span={6} key={facetName}>
                <h4
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                  {title}
                  {desc && (
                    <Tooltip placement="top" title={desc}>
                      {" "}
                      <GoQuestion style={{ marginTop: 1 }} />
                    </Tooltip>
                  )}
                </h4>
                {compType === "list" ? (
                  <RefinementList
                    attribute={facetName}
                    searchable={isSearchable}
                    searchablePlaceholder={placeHolder || `Search ${facetName}`}
                    limit={4}
                    showMore={true}
                  />
                ) : compType === "inputRange" ? (
                  <RangeInput
                    attribute={facetName}
                    translations={{
                      separatorElementText: "-",
                      submitButtonText: "Apply",
                    }}
                    classNames={{
                      form: styles.followersRoot,
                      submit: styles.followersSubmit,
                    }}
                  />
                ) : compType === "slider" ? (
                  <AgeRangeSlider
                    attribute={facetName}
                    isPercent={
                      title.toLowerCase().includes("percent") ||
                      title.toLowerCase().includes("rate")
                    }
                  />
                ) : compType === "custom-multi" ? (
                  <MultiDimensionFilter
                    facet={{
                      title,
                      compType,
                      facetName,
                      isSearchable,
                      desc,
                      placeHolder,
                    }}
                    platform="instagram"
                  />
                ) : (
                  <div />
                )}
              </Col>
            );
          }
        )}
      </Row>
      <Divider />
      <p className={styles.filterHeading}>
        Audience filters •{" "}
        <span className={styles.filterDesc}>
          For best results, start with location, gender and age before adding
          more filters
        </span>
      </p>
      <Row wrap gutter={[20, 50]}>
        {IGConfig.filter((item) => (item?.type || "") === "audience").map(
          ({ title, compType, facetName, isSearchable, desc, placeHolder }) => {
            return (
              <Col span={6} key={facetName}>
                <h4>
                  {title}
                  {desc && (
                    <Tooltip placement="top" title={desc}>
                      {" "}
                      <GoQuestion style={{ marginLeft: 5 }} />
                    </Tooltip>
                  )}
                </h4>
                {compType === "list" ? (
                  <RefinementList
                    attribute={facetName}
                    searchable={isSearchable}
                    searchablePlaceholder={placeHolder || `Search ${facetName}`}
                    limit={4}
                    showMore={true}
                  />
                ) : compType === "inputRange" ? (
                  <RangeInput
                    attribute={facetName}
                    translations={{
                      separatorElementText: "-",
                      submitButtonText: "Apply",
                    }}
                    classNames={{
                      form: styles.followersRoot,
                      submit: styles.followersSubmit,
                    }}
                  />
                ) : compType === "slider" ? (
                  <AgeRangeSlider
                    attribute={facetName}
                    isPercent={
                      title.toLowerCase().includes("percent") ||
                      title.toLowerCase().includes("rate")
                    }
                  />
                ) : compType === "custom-multi" ? (
                  <MultiDimensionFilter
                    facet={{
                      title,
                      compType,
                      facetName,
                      isSearchable,
                      desc,
                      placeHolder,
                    }}
                    platform="instagram"
                  />
                ) : (
                  <div />
                )}
              </Col>
            );
          }
        )}
      </Row>
      <Divider />
      <p className={styles.filterHeading}>
        Search by Username •{" "}
        <span className={styles.filterDesc}>
          Successful users often use this to check specific accounts and find
          similar influencers
        </span>
      </p>
    </div>
  );
};

export default IGFilters;
