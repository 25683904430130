import React from "react";
import { Modal, Button } from "antd";

import styles from "../styles.module.css";

interface WelcomeProps {
  isVisible: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
  influencerName: string;
}

const WelcomeModal: React.FC<WelcomeProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  influencerName,
}) => {
  return (
    <Modal
      centered
      title={null}
      footer={null}
      visible={isVisible}
      onCancel={handleCancel}
      bodyStyle={{ borderRadius: "14px", paddingTop: "100px" }}>
      <h1 style={{ textAlign: "center" }}>YAY!</h1>
      <p className={styles.description}>
        {influencerName} completed the campaign. How was your experience, let us
        know with a review
      </p>
      <Button
        type="primary"
        block
        style={{ marginTop: "25px" }}
        onClick={() => handleSubmit()}>
        Leave a review
      </Button>
    </Modal>
  );
};

export default WelcomeModal;
