import React, { useState } from "react";
import { Layout, Button } from "antd";

// import SidebarModal from "./SidebarModal";
import CampaignDrawer from "../../Campaign/CampaignDrawer";

import styles from "./Sidebar.module.css";
import { useQuery } from "react-query";
import { getCampaignOfferCount } from "../../../handler/campaign/getCampaignOfferCount";
import { useParams } from "react-router-dom";
import { IoIosArrowDroprightCircle } from "react-icons/io";

interface SidebarProps {
  subMenuType:
    | "appRec"
    | "shortList"
    | "invite"
    | "reject"
    | "finalized"
    | "completed"
    | "search";
  handleMenuChange: (
    type:
      | "appRec"
      | "shortList"
      | "invite"
      | "reject"
      | "finalized"
      | "completed"
      | "search"
  ) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ subMenuType, handleMenuChange }) => {
  const { Sider } = Layout;
  const { id } = useParams();

  const [getCampaignOfferCountKey, getCampaignOfferCountFn] =
    getCampaignOfferCount({ campaignId: id as string });

  const { data: count } = useQuery(
    getCampaignOfferCountKey,
    getCampaignOfferCountFn
  );

  const [openCampaignDrawer, setOpenCampaignDrawer] = useState<boolean>(false);

  const handleMenuItemChange = (value: string) => {
    handleMenuChange(
      value as
        | "appRec"
        | "shortList"
        | "invite"
        | "reject"
        | "finalized"
        | "completed"
        | "search"
    );
  };
  if (!id) {
    return null;
  }

  return (
    <Sider width={"100%"} className={styles.sidebar} theme="light">
      <div className={styles.heading}>
        <div
          className={styles.campaignButton}
          onClick={() => setOpenCampaignDrawer(true)}>
          <p
            style={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              color: "black",
            }}>
            {count?.data.campaignName}
            <img
              src={`/assets/img/${count?.data.campaignPlatform}.png`}
              alt="platform"
              style={{ width: "20px", height: "20px", marginLeft: "12px" }}
            />
          </p>

          <Button
            type="link"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
              padding: "0",
            }}>
            {" "}
            <IoIosArrowDroprightCircle size={24} />
          </Button>
        </div>
      </div>
      <div className={styles.navs}>
        <div className={styles.options}>
          <div
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
            className={
              subMenuType !== "appRec" ? styles.option : styles.optionActive
            }
            key={"appRec"}
            onClick={() => handleMenuItemChange("appRec")}>
            <p>Applications Received</p>
            <span
              className={
                subMenuType !== "appRec" ? styles.count : styles.countActive
              }>
              {count?.data.applicationRecieved}
            </span>
          </div>
          <div
            className={
              subMenuType !== "shortList" ? styles.option : styles.optionActive
            }
            key={"shortList"}
            onClick={() => handleMenuItemChange("shortList")}>
            <p>Shortlisted</p>
            <span
              className={
                subMenuType !== "shortList" ? styles.count : styles.countActive
              }>
              {count?.data.shortListed}
            </span>
          </div>
          <div
            className={
              subMenuType !== "invite" ? styles.option : styles.optionActive
            }
            key={"invite"}
            onClick={() => handleMenuItemChange("invite")}>
            <span>Invited</span>
            <span
              className={
                subMenuType !== "invite" ? styles.count : styles.countActive
              }>
              {count?.data.invited}
            </span>
          </div>
          <div
            // style={{
            //   borderBottomLeftRadius: "8px",
            //   borderBottomRightRadius: "8px",
            // }}
            className={
              subMenuType !== "reject" ? styles.option : styles.optionActive
            }
            key={"reject"}
            onClick={() => handleMenuItemChange("reject")}>
            <span>Not interested</span>
            <span
              className={
                subMenuType !== "reject" ? styles.count : styles.countActive
              }>
              {count?.data.rejected}
            </span>
          </div>
          <div
            style={{
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
            className={
              subMenuType !== "search" ? styles.option : styles.optionActive
            }
            key={"search"}
            onClick={() => handleMenuItemChange("search")}>
            <p>Global Search</p>
            {/* <span
              className={
                subMenuType !== "search" ? styles.count : styles.countActive
              }></span> */}
          </div>
        </div>
        <div
          style={{ borderRadius: "8px" }}
          className={
            subMenuType !== "finalized" ? styles.option : styles.optionActive
          }
          key={"finalized"}
          onClick={() => handleMenuItemChange("finalized")}>
          <span>Ongoing</span>
          <span
            className={
              subMenuType !== "finalized" ? styles.count : styles.countActive
            }>
            {count?.data.finalized}
          </span>
        </div>
        <div
          style={{ borderRadius: "8px" }}
          className={
            subMenuType !== "completed" ? styles.option : styles.optionActive
          }
          key={"completed"}
          onClick={() => handleMenuItemChange("completed")}>
          <span>Completed</span>
          <span
            className={
              subMenuType !== "completed" ? styles.count : styles.countActive
            }>
            {count?.data?.completed}
          </span>
        </div>
      </div>
      <CampaignDrawer
        id={id as string}
        openDrawer={openCampaignDrawer}
        setOpenDrawer={setOpenCampaignDrawer}
      />
    </Sider>
  );
};

export default Sidebar;
