import { sendRequest } from "../../helper";
import { FinalizedResponse } from "../../../typings";

type getCompletedOfferV2Props = {
  limit: number;
  campaignId: string;
  pageNo: number;
};

export const getCompletedOfferV2 = ({
  limit,
  pageNo,
  campaignId,
}: getCompletedOfferV2Props) => {
  const queryKey = ["completed-campaign", campaignId, limit, pageNo, "v2"];

  const queryFunction = () =>
    sendRequest<FinalizedResponse>(
      "put",
      `/brand/execution/completed/completed-search`,
      false,
      {
        pageNo: pageNo.toString(),
        campaignId,
        limit: limit.toString(),
      },
      null,
      true
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
