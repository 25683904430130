import { sendRequest } from "../helper";
import { DiscoverCreator } from "../../typings";
import { sendSearchRequest } from "../searchHelper";

export interface InsightsIqProfileSearchResponse {
  status: string;
  message: string;
  data: DiscoverCreator;
}

export const insightsiqPlatformWorkIdMap = {
  tiktok: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  instagram: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  youtube: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  twitter: "7645460a-96e0-4192-a3ce-a1fc30641f72",
  twitch: "e4de6c01-5b78-4fc0-a651-24f44134457b",
  facebook: "ad2fec62-2987-40a0-89fb-23485972598c",
  substack: "fbf76083-710b-439a-8b8c-956f607ef2c1",
  instagramLite: "fb7cafcf-60ed-468a-ad6d-6bb6899d0871",
  adSense: "3e6f4cdd-2119-4125-aa0d-86eee4e20fcc",
  spotify: "1db0b86c-a547-4119-9834-da927171a685",
  linkedIn: "36410629-f907-43ba-aa0d-434ca9c0501a",
  beehiiv: "24fcaa09-0a79-4deb-b074-d8efd900265b",
};

export const getProfileAnalysisInsightIq = ({
  identifier,
  work_platform_id,
}: {
  identifier: string;
  work_platform_id: string;
}) => {
  const queryKey = [
    "insights-iq-quick-search",
    "profile-analysis",
    identifier,
    work_platform_id,
  ];

  const queryFunction = () =>
    sendSearchRequest<InsightsIqProfileSearchResponse>(
      "post",
      "/insightsIq/by-pass?url=/v1/internal/social/creators/profiles/analytics",
      false,
      {
        identifier,
        work_platform_id,
      }
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
