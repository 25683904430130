import { sendRequest } from "../helper";
import { Organization } from "../../typings";

type getInvitationsProps = {
  send: boolean;
  status?: string;
};

type OrganizationResponse = {
  data: Organization[];
  status: string;
  message: string;
};

export const getInvitations = ({ send, status }: getInvitationsProps) => {
  const queryKey = ["invitations", send, status];

  const queryFunction = () =>
    sendRequest<OrganizationResponse>(
      "get",
      `/brand/organisation?send=${String(send)}${
        status ? "&status=" + status : ""
      }`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
