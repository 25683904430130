export const INSTANT_SEARCH_INDEX_INSTA_NAME =
  "global-tracking-discovery-ig-v1";
export const INSTANT_SEARCH_INDEX_YT_NAME = "global-tracking-discovery-yt-v1";
export type ConfigType = ConfigTypeSingle[];
export type ConfigTypeSingle = {
  facetName: string;
  title: string;
  isSearchable: boolean;
  compType: "list" | "inputRange" | "slider" | "custom-multi" | "checkbox";
  desc: string;
  type?: string;
  placeHolder?: string;
};

export const YTconfig: ConfigType = [
  {
    facetName: "isOnBoarded",
    title: "Is Profile Onboarded?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isDataFetched",
    title: "Is Data Fetched?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isPhoneNumber",
    title: "Has Phone Number?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isEmail",
    title: "Has Email?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "hashtags",
    title: "Hashtags Used",
    placeHolder: "Search Hashtags",
    isSearchable: true,
    compType: "list",
    desc: "Search by hashtags used by Influencers in their recent posts or reels",
  },
  // {
  //   facetName: "emailIds",
  //   title: "Email Ids Used",
  //   placeHolder: "Search Hashtags",
  //   isSearchable: true,
  //   compType: "list",
  //   desc: "Search by  email Id's Added",
  // },
  {
    facetName: "biography",
    title: "Biography",
    isSearchable: true,
    placeHolder: "Search Biography",
    compType: "list",
    desc: "Search by keywords used by influencers in their Bio",
  },
  {
    facetName: "tags",
    title: "Tags",
    isSearchable: true,
    placeHolder: "Search Tags",
    compType: "list",
    desc: "Search by Tags",
  },

  {
    facetName: "followerCount",
    title: "Followers Range",
    isSearchable: true,
    compType: "slider",
    desc: "Followers of Influencer",
    placeHolder: "Search Influencer Followers",
  },
  {
    facetName: "all_engagementRate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Engagement Rate of influencers is the Average of Engagement of Posts and Reels divided by Followers",
    placeHolder: "Search Engagement Rate",
  },
  {
    facetName: "all_avg_likes",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },

  {
    facetName: "all_avg_comments",
    title: "Average Comments",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },

  {
    facetName: "all_avg_views",
    title: "Average views",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },
];

export const IGConfig: ConfigType = [
  {
    facetName: "isOnBoarded",
    title: "Is Profile Onboarded?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isDataFetched",
    title: "Is Data Fetched?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isPhoneNumber",
    title: "Has Phone Number?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "isEmail",
    title: "Has Email?",
    isSearchable: false,
    compType: "list",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "hashtags",
    title: "Hashtags Used",
    placeHolder: "Search Hashtags",
    isSearchable: true,
    compType: "list",
    desc: "Search by hashtags used by Influencers in their recent posts or reels",
  },
  {
    facetName: "mentions",
    title: "Mentions Used",
    placeHolder: "Search Mentions",
    isSearchable: true,
    compType: "list",
    desc: "Search by mentions used by Influencers in their recent posts or reels",
  },
  // {
  //   facetName: "emailIds",
  //   title: "Email Ids Used",
  //   placeHolder: "Search Hashtags",
  //   isSearchable: true,
  //   compType: "list",
  //   desc: "Search by  email Id's Added",
  // },
  {
    facetName: "biography",
    title: "Biography",
    isSearchable: true,
    placeHolder: "Search Biography",
    compType: "list",
    desc: "Search by keywords used by influencers in their Bio",
  },
  {
    facetName: "tags",
    title: "Tags",
    isSearchable: true,
    placeHolder: "Search Tags",
    compType: "list",
    desc: "Search by Tags",
  },
  {
    facetName: "all_engagementRate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Engagement Rate of influencers is the Average of Engagement of Posts and Reels divided by Followers",
    placeHolder: "Search Engagement Rate",
  },
  {
    facetName: "reel_avg_engagementRate",
    title: "Reels Avg Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Average engagement per Reel, Average of (engagement / reach ) of recent Reels",
    placeHolder: "Search Reel Engagement Rate",
  },
  {
    facetName: "followerCount",
    title: "Followers Range",
    isSearchable: true,
    compType: "slider",
    desc: "Followers of Influencer",
    placeHolder: "Search Influencer Followers",
  },
  {
    facetName: "all_avg_likes",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },

  {
    facetName: "all_avg_comments",
    title: "Average Comments",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },

  {
    facetName: "feed_avg_likes",
    title: "Feed Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "feed_avg_comments",
    title: "Feed Average Comments",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },

  {
    facetName: "reel_avg_likes",
    title: "Reel Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "reel_avg_comments",
    title: "Reel Average Comments",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },
  {
    facetName: "reel_avg_views",
    title: "Reel Average views",
    isSearchable: false,
    compType: "inputRange",
    desc: "",
    placeHolder: "",
  },
];
