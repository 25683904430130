import React, { useState, useRef, useEffect } from "react";
import {
  Image,
  Skeleton,
  Row,
  Button,
  Input,
  Select,
  Space,
  message,
} from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { AxiosError } from "axios";

import { countryCodeArray } from "../../assets/country_code";

import { getUserUniversal } from "../../../handler/getUserUniversal";
import { sendOTP } from "../../../handler/sendOTP";
import { verifyOTP } from "../../../handler/verifyOTP";
import { editUserDetails } from "../../../handler/editUserDetails";

import styles from "../profile.module.css";

const { Option } = Select;

const PersonalDetails = () => {
  const [queryKey, queryFn] = getUserUniversal();

  const queryClient = useQueryClient();

  const [otpTimer, setOtpTimer] = useState<number>(31);

  const [OTP, setOTP] = useState<string>("");

  const putMeMutation = useMutation(editUserDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const [inputValues, setInputValues] = useState<{
    firstName: { value: string; isEditing: boolean };
    lastName: { value: string; isEditing: boolean };
    phone: {
      number: string;
      countryCode: string;
      isOTPSent: boolean;
      isEditing: boolean;
    };
  }>({
    firstName: { value: "", isEditing: false },
    lastName: { value: "", isEditing: false },
    phone: {
      number: "",
      countryCode: "",
      isOTPSent: false,
      isEditing: false,
    },
  });

  const firstRender = useRef(true);

  const { data: userData, isLoading } = useQuery(queryKey, queryFn);

  const sendOTPMutation = useMutation(sendOTP, {
    onSuccess: () => {
      setInputValues((inputValues) => ({
        ...inputValues,
        phone: { ...inputValues.phone, isOTPSent: true },
      }));
      setOtpTimer(30);
      message.success("OTP sent");
    },
    onError: (err: AxiosError) => {
      setInputValues((inputValues) => ({
        ...inputValues,
        phone: { ...inputValues.phone, isOTPSent: false },
      }));
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const verifyOTPMutation = useMutation(verifyOTP, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setInputValues((inputValues) => ({
        ...inputValues,
        phone: { ...inputValues.phone, isOTPSent: false, isEditing: false },
      }));
      message.success("OTP Verified");

      // setInputValues((userData) => {
      //   return { ...userData, isPhoneVerified: true };
      // });
    },
    onError: (err: AxiosError) => {
      setInputValues((inputValues) => ({
        ...inputValues,
        phone: { ...inputValues.phone, isOTPSent: false, isEditing: true },
      }));
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  useEffect(() => {
    if (otpTimer > 0 && inputValues.phone.isOTPSent) {
      const interval = setInterval(() => {
        setOtpTimer((otpTimer) => otpTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimer]);

  useEffect(() => {
    if (userData && firstRender.current) {
      setInputValues({
        firstName: { value: userData?.data?.name, isEditing: false },
        lastName: { value: userData?.data?.lastname, isEditing: false },

        phone: {
          number: (userData?.data?.phoneNumber || " ").split("-").at(-1) || "",
          countryCode:
            (userData?.data?.phoneNumber || " ").split("-").at(0) || "+91",
          isOTPSent: false,
          isEditing: false,
        },
      });
      firstRender.current = false;
    }
  }, [userData]);

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.inputLabel}> First Name</p>
        <Row justify="space-between">
          {inputValues?.firstName?.isEditing ? (
            <Input
              value={inputValues?.firstName?.value}
              style={{ width: "50%" }}
              onChange={(e) =>
                setInputValues((inptValues) => ({
                  ...inptValues,
                  firstName: {
                    ...inputValues.firstName,
                    value: e.target.value,
                  },
                }))
              }
            />
          ) : (
            <p className={styles.inputValue}>{userData?.data.name}</p>
          )}
          {inputValues?.firstName?.isEditing ? (
            <Button
              loading={putMeMutation.isLoading}
              disabled={!Boolean(inputValues?.firstName?.value.trim())}
              onClick={() => {
                putMeMutation.mutate(
                  { name: inputValues.firstName.value },
                  {
                    onSuccess: () => {
                      setInputValues((inptValues) => ({
                        ...inptValues,
                        firstName: {
                          ...inputValues.firstName,
                          isEditing: false,
                        },
                      }));
                    },

                    onError: (err: any) => {
                      message.error(err.response?.data?.message);
                      return;
                    },
                  }
                );
              }}>
              <FaCheck />
            </Button>
          ) : (
            <Button
              onClick={() =>
                setInputValues((inptValues) => ({
                  ...inptValues,
                  firstName: { ...inputValues.firstName, isEditing: true },
                }))
              }>
              <MdModeEdit />
            </Button>
          )}
        </Row>
      </div>
      <div>
        <p className={styles.inputLabel}> Last Name</p>
        <Row justify="space-between">
          {inputValues?.lastName?.isEditing ? (
            <Input
              value={inputValues?.lastName?.value}
              style={{ width: "50%" }}
              onChange={(e) =>
                setInputValues((inptValues) => ({
                  ...inptValues,
                  lastName: {
                    ...inputValues.lastName,
                    value: e.target.value,
                  },
                }))
              }
            />
          ) : (
            <p className={styles.inputValue}>{userData?.data.lastname}</p>
          )}
          {inputValues?.lastName?.isEditing ? (
            <Button
              disabled={!Boolean(inputValues?.lastName?.value.trim())}
              onClick={() => {
                putMeMutation.mutate(
                  { lastname: inputValues.lastName.value },
                  {
                    onSuccess: () => {
                      setInputValues((inptValues) => ({
                        ...inptValues,
                        lastName: {
                          ...inputValues.lastName,
                          isEditing: false,
                        },
                      }));
                    },
                    onError: (err: any) => {
                      message.error(err.response?.data?.message);
                      return;
                    },
                  }
                );
              }}
              loading={putMeMutation.isLoading}>
              <FaCheck />
            </Button>
          ) : (
            <Button
              onClick={() =>
                setInputValues((inptValues) => ({
                  ...inptValues,
                  lastName: { ...inputValues.lastName, isEditing: true },
                }))
              }>
              <MdModeEdit />
            </Button>
          )}
        </Row>
      </div>
      <div>
        <p className={styles.inputLabel}>Mobile number</p>
        <Row justify="space-between">
          {inputValues?.phone?.isEditing ? (
            <div>
              <Input.Group style={{ display: "flex", width: "80%" }}>
                <Select
                  size={"middle"}
                  value={inputValues.phone.countryCode}
                  onChange={(value) =>
                    setInputValues((userInput) => ({
                      ...userInput,
                      phone: { ...userInput.phone, countryCode: value },
                    }))
                  }
                  showSearch
                  style={{ minWidth: "90px" }}
                  filterOption={(input, option) =>
                    String(option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }>
                  {countryCodeArray.map((item) => (
                    <Option value={item.dial_code} key={item.name}>
                      {item.flag} {item.dial_code}
                    </Option>
                  ))}
                </Select>
                <Input
                  type="number"
                  value={inputValues.phone.number}
                  style={{ width: "90%" }}
                  onChange={(e) =>
                    setInputValues((userInput) => ({
                      ...userInput,
                      phone: { ...userInput.phone, number: e.target.value },
                    }))
                  }
                />
              </Input.Group>
              {inputValues?.phone?.isOTPSent && (
                <Input
                  style={{ width: "50%", marginTop: 10 }}
                  type="number"
                  value={OTP}
                  placeholder="Enter OTP"
                  onChange={(e) => setOTP(e.target.value || "")}
                />
              )}
            </div>
          ) : (
            <p className={styles.inputValue}>{userData?.data.phoneNumber}</p>
          )}
          {inputValues?.phone?.isEditing ? (
            <>
              {inputValues?.phone?.isOTPSent ? (
                // Resend Otp
                <Space>
                  <Button
                    disabled={otpTimer !== 0}
                    onClick={() => {
                      sendOTPMutation.mutate({
                        phoneNumber:
                          inputValues.phone.countryCode +
                          "-" +
                          inputValues.phone.number,
                      });
                    }}
                    type="text">
                    Resend OTP {otpTimer !== 0 && `in ${otpTimer}`}
                  </Button>

                  <Button onClick={() => verifyOTPMutation.mutate({ OTP })}>
                    Verify
                  </Button>
                </Space>
              ) : (
                <Button
                  onClick={() =>
                    sendOTPMutation.mutate({
                      phoneNumber:
                        inputValues.phone.countryCode +
                        "-" +
                        inputValues.phone.number,
                    })
                  }
                  loading={sendOTPMutation.isLoading}
                  disabled={!Boolean(inputValues.phone.number.trim())}>
                  Send OTP
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={() =>
                setInputValues((inptValues) => ({
                  ...inptValues,
                  phone: { ...inputValues.phone, isEditing: true },
                }))
              }>
              <MdModeEdit />
            </Button>
          )}
        </Row>
      </div>
      <div>
        <p className={styles.inputLabel}>Email</p>
        <p className={styles.inputValue}>{userData?.data.emailId}</p>
      </div>
    </div>
  );
};

export default PersonalDetails;
