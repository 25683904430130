import React, { useState } from "react";
import { message, List, Button, Typography, Row } from "antd";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { useCampaign } from "../../Context/campaignContext";
import { deleteDeliverable } from "../../../../../handler/campaign";

import { YoutubeDel } from "../../../Create/Deliverables/Deliverables";

import YoutubeModal from "../../../Create/Deliverables/Modal/Youtube";

import styles from "../../styles.module.css";

const YoutubeDeliverables = () => {
  const { campaign, setCampaign, error } = useCampaign();

  const [youtubeModalVisible, setYoutubeModalVisible] = useState(false);
  const [isEditYoutubeModalVisible, setEditYoutubeModalVisible] =
    useState(false);
  const [ytDeliverable, setYtDeliverable] = useState<YoutubeDel | null>(null);

  const deleteYtDeliverableMutation = useMutation(deleteDeliverable, {
    onSuccess: (response) => {
      setCampaign((campaign) => ({
        ...campaign,
        deal: {
          ...campaign.deal,
          deliverables: response.data.deal.deliverables,
        },
      }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong while deleting deliverable");
    },
  });

  return (
    <div>
      {campaign.deal.deliverables &&
        Boolean(campaign.deal.deliverables.length) && (
          <>
            <List
              dataSource={campaign.deal.deliverables}
              style={{
                marginTop: "0px",
                backgroundColor: "#ffffff",
                paddingLeft: "20px",
              }}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="link"
                      key="list-loadmore-edit"
                      onClick={() => {
                        setYtDeliverable(item as YoutubeDel);
                        setEditYoutubeModalVisible(true);
                      }}>
                      Edit
                    </Button>,
                    <Button
                      type="link"
                      key="list-loadmore-more"
                      onClick={() =>
                        deleteYtDeliverableMutation.mutate({
                          campaignId: campaign.campaign._id,
                          deliverableID: item?._id || "",
                        })
                      }>
                      Delete
                    </Button>,
                  ]}>
                  <Typography.Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    {(item as YoutubeDel)?.duration} minute{" "}
                    {(item as YoutubeDel).type} Video
                  </Typography.Text>
                </List.Item>
              )}
              bordered
            />
            {error?.deliverables && (
              <p className={styles.error}>{error?.deliverables}</p>
            )}
          </>
        )}
      <Row justify="start">
        <Button
          type="link"
          style={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            marginTop: "10px",
            // color: "rgba(0, 0, 0, 0.45)",
          }}
          onClick={() => setYoutubeModalVisible(true)}>
          + Add Deliverable
        </Button>
      </Row>

      <YoutubeModal
        type="Add"
        visible={youtubeModalVisible}
        setIsVisible={setYoutubeModalVisible}
        setDeliverableList={(deliverables: YoutubeDel[]) => {
          if (deliverables) {
            setCampaign((campaign) => ({
              ...campaign,
              deal: { ...campaign.deal, deliverables },
            }));
          }
        }}
        setDeliverable={setYtDeliverable}
        productList={[]}
        campaignId={campaign.campaign._id}
        hasProduct={campaign.deal.isProduct}
      />

      {ytDeliverable && (
        <YoutubeModal
          type="Edit"
          visible={isEditYoutubeModalVisible}
          setIsVisible={setEditYoutubeModalVisible}
          setDeliverableList={(deliverables: YoutubeDel[]) => {
            if (deliverables) {
              setCampaign((campaign) => ({
                ...campaign,
                deal: { ...campaign.deal, deliverables },
              }));
            }
          }}
          setDeliverable={setYtDeliverable}
          productList={[]}
          initialValues={ytDeliverable}
          campaignId={campaign.campaign._id}
          hasProduct={campaign.deal.isProduct}
        />
      )}
    </div>
  );
};

export default YoutubeDeliverables;
