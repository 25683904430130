import React, { useState } from "react";
import { ReactNode } from "react";
import { Button, Space, Row, Col, Image, Badge, Spin } from "antd";
import { useQuery } from "react-query";
import { MdArrowBackIosNew } from "react-icons/md";
import { FiArrowUpRight } from "react-icons/fi";
import { LuPinOff, LuPin } from "react-icons/lu";
import { BiCaretDown } from "react-icons/bi";
import { Doughnut } from "react-chartjs-2";

import Option from "../../shared/UI/Option";

import MetricCard from "../Shared/MetricCard";
import AddWorkflow from "../../Workflows/Modal/AddWorkflow";
import CustomDropDown from "../../shared/Custom/DropDown";

import { getIgAnalytics } from "../../../handler/Profile";
import { getReportPlatformConfiguration } from "../../../handler/report";
import { getWorkflowByMediaId } from "../../../handler/replies";

import { PinnedMetric } from "../IgAnalytics";

import {
  igPostMetrics,
  igStoryMetrics,
  igReelMetrics,
} from "../../../constants";

import { formatData } from "../../../utils";
import styles from "./styles.module.css";
import IgDeliverable from "./IgDeliverable";
import ReportButton from "../../Campaign/Report/ReportButton";

interface IndividualPostsInsightsProps {
  influencerId: string;
  inDrawer: boolean;
  pinnedMetrices: PinnedMetric[];
  setPinnedMetrices: React.Dispatch<React.SetStateAction<PinnedMetric[]>>;
  canApplyWorkflow: boolean;
}

const IndividualPostsInsights: React.FC<IndividualPostsInsightsProps> = ({
  influencerId,
  inDrawer,
  pinnedMetrices,
  setPinnedMetrices,
  canApplyWorkflow,
}) => {
  const [influencerKey, influencerFn] = getIgAnalytics(influencerId || "");

  const { data, isError } = useQuery(influencerKey, influencerFn, {
    enabled: Boolean(influencerId),
  });

  const [workflowModalPros, setWorkflowModalPros] = useState<{
    visible: boolean;
    workflowId: string;
  }>({ visible: false, workflowId: "" });

  const [configKey, configFn] = getReportPlatformConfiguration({
    platform: "instagram",
  });

  const { data: configData } = useQuery(configKey, configFn, {
    enabled: Boolean(influencerId),
  });

  const [optionSelected, setOptionSelected] = useState<
    "feed" | "reels" | "story"
  >("feed");

  const [breakDownType, setBreakDownType] = useState<"week" | "month">("month");

  const [selectedMedia, setSelectedMedia] = useState<{
    media: { [key: string]: any };
  }>({ media: {} });

  const [getWorkflowDetailsKey, getWorkflowDetailsFn] = getWorkflowByMediaId({
    mediaId: selectedMedia?.media?.ig_media_id,
  });

  const { data: workflowData, isLoading: workflowLoading } = useQuery(
    getWorkflowDetailsKey,
    getWorkflowDetailsFn,
    {
      enabled: Boolean(selectedMedia?.media?.ig_media_id),
    }
  );

  if (isError || !data?.data) {
    return <div></div>;
  }

  const togglePin = (title: string, period?: typeof breakDownType) => {
    const idx = pinnedMetrices.findIndex((item) =>
      period
        ? item.title === title && item.period === period
        : item.title === title
    );

    if (idx > -1) {
      if (period) {
        setPinnedMetrices((prev) =>
          prev.filter(
            (item) => !(item.title === title && item.period === period)
          )
        );
      } else {
        setPinnedMetrices((prev) =>
          prev.filter((item) => item.title !== title)
        );
      }
    } else {
      if (period) {
        setPinnedMetrices((prev) => [...prev, { title, period }]);
      } else {
        setPinnedMetrices((prev) => [...prev, { title }]);
      }
    }
  };

  const isReachBreakdownPinned = () => {
    return (
      pinnedMetrices.filter(
        (item) =>
          item.title === `Reach-breakdown${optionSelected}` &&
          item.period === breakDownType
      ).length > 0
    );
  };

  const getReachData = () => {
    if (breakDownType === "week") {
      if (
        data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isBreakDown &&
        !data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isError &&
        data?.data?.user?.reach_last_7_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          data?.data?.user?.reach_last_7_days_media_product_type_follow_type
            ?.breakDowns?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          if (
            optionSelected === "feed" &&
            (key.includes("CAROUSEL") ||
              key.includes("IGTV") ||
              key.includes("POST"))
          ) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (optionSelected === "reels" && key.includes("REEL")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (optionSelected === "story" && key.includes("STORY")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
        });

        return [followers, nonFollowers];
      }
    } else {
      if (
        data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isBreakDown &&
        !data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isError &&
        data?.data?.user?.reach_last_30_days_media_product_type_follow_type
          ?.isMetricDataAviable
      ) {
        let nonFollowers = 0;
        let followers = 0;

        Object.entries(
          data?.data?.user?.reach_last_30_days_media_product_type_follow_type
            ?.breakDowns?.media_product_type_follow_type || {}
        ).forEach((ele) => {
          const [key, value] = ele;

          if (
            optionSelected === "feed" &&
            (key.includes("CAROUSEL") ||
              key.includes("IGTV") ||
              key.includes("POST"))
          ) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (optionSelected === "reels" && key.includes("REEL")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
          if (optionSelected === "story" && key.includes("STORY")) {
            if (key.includes("NON_FOLLOWER")) {
              nonFollowers += (value as number) || 0;
            } else {
              followers += (value as number) || 0;
            }
          }
        });

        return [followers, nonFollowers];
      }
    }

    return [0, 0];
  };

  const RibbonHoc = ({
    enable,
    type,
    children,
  }: {
    enable: boolean;
    type: string;
    children: ReactNode;
  }) => {
    if (enable) return <Badge.Ribbon text={type}>{children}</Badge.Ribbon>;

    return <>{children}</>;
  };

  // console.log("workflowData", selectedMedia?.media?.ig_media_id);

  return (
    <div className={styles.grid}>
      <p className={styles.title}>Media Insights</p>
      <p className={styles.info}>
        Select any Media To view it's Individual Insights
      </p>
      {Object.values(selectedMedia.media).length <= 0 ? (
        <>
          <Space size={10}>
            <Option
              title="Feed"
              isSelected={optionSelected === "feed"}
              handleClick={() => setOptionSelected("feed")}
            />
            <Option
              title="Reels"
              isSelected={optionSelected === "reels"}
              handleClick={() => setOptionSelected("reels")}
            />
            <Option
              title="Story"
              isSelected={optionSelected === "story"}
              handleClick={() => setOptionSelected("story")}
            />
          </Space>
          <Row justify="space-between">
            <Col
              span={17}
              style={{
                height: "340px",
                overflowY: "scroll",
                maxWidth: "750px",
              }}>
              <Row wrap gutter={[5, 5]}>
                {data?.data?.user?.[optionSelected]?.individualMetrics?.map(
                  (item: any) => (
                    <Col span={7}>
                      <div style={{ width: "210px" }}>
                        <RibbonHoc
                          enable={item.is_sponsored || false}
                          type="Sponsored">
                          <IgDeliverable
                            caption={item?.caption || ""}
                            isSelected={false}
                            handleClick={() =>
                              setSelectedMedia({ media: item })
                            }
                            key={item?.ig_media_id}
                            url={(item?.savedMedias || [])[0]?.url || ""}
                            //   type={
                            //     data?.platform === "instagram"
                            //       ? item?.media?.savedMedias[0]?.mediaType || "IMAGE"
                            //       : "yt-video"
                            //   }
                            type={
                              (item?.savedMedias || [])[0]?.mediaType || "IMAGE"
                            }
                          />
                        </RibbonHoc>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Col>

            <Col span={7}>
              <div className={styles.reachContainer}>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    togglePin(
                      `Reach-breakdown${optionSelected}`,
                      breakDownType
                    );
                  }}>
                  {isReachBreakdownPinned() ? (
                    <LuPinOff size={10} color={"#00000066"} />
                  ) : (
                    <LuPin size={10} color={"#00000066"} />
                  )}
                </div>
                <Row justify="space-between" style={{ width: "100%" }}>
                  <p className={styles.reach}>Reach-breakdown</p>

                  <CustomDropDown
                    items={[
                      {
                        label: "Week",
                        key: "2",
                        onClick: () => setBreakDownType("week"),
                      },
                      {
                        label: "Month",
                        key: "3",
                        onClick: () => setBreakDownType("month"),
                      },
                    ]}>
                    <a
                      onClick={(e) => e.preventDefault()}
                      className={styles.optionsContainer}>
                      {breakDownType}
                      <BiCaretDown color="#00000080" size={8} />
                    </a>
                  </CustomDropDown>
                </Row>
                <div>
                  {getReachData().reduce(
                    (prev, current) => prev + current || 0,
                    0
                  ) === 0 ? (
                    <p className="global-no-data">
                      Not enough Data To show this Metric
                    </p>
                  ) : (
                    <Doughnut
                      options={{
                        plugins: {
                          legend: { position: "top" },
                          datalabels: {
                            anchor: "end",
                            align: "start",
                            formatter: (value) => formatData(value || 0, "a"),
                            font: {
                              size: 16,
                              style: "normal",
                            },
                          },
                        },
                      }}
                      data={{
                        labels: ["Follower", "Non Follower"],
                        datasets: [
                          {
                            label: "No",
                            data: getReachData(),
                            backgroundColor: ["#FF6384", "#FFCD56"],
                            borderColor: ["#FF6384", "#FFCD56"],
                            borderWidth: 1,
                            borderJoinStyle: "round",
                            borderRadius: 10,
                            hoverOffset: 4,
                            offset: 0,
                          },
                        ],
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Button
            type="primary"
            icon={<MdArrowBackIosNew />}
            style={{
              borderRadius: "12px",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
            onClick={() => setSelectedMedia({ media: {} })}>
            Back
          </Button>
          <Row gutter={10} style={{ height: "280px", overflowY: "scroll" }}>
            <Col span={5}>
              <div style={{ width: "210px" }}>
                <RibbonHoc
                  enable={selectedMedia?.media.is_sponsored || false}
                  type="Sponsored">
                  <IgDeliverable
                    isSelected={true}
                    caption={selectedMedia?.media?.caption || ""}
                    handleClick={() => {}}
                    key={selectedMedia?.media?.ig_media_id}
                    url={
                      (selectedMedia?.media?.savedMedias || [])[0]?.url || ""
                    }
                    type={
                      (selectedMedia?.media?.savedMedias || [])[0]?.mediaType ||
                      "IMAGE"
                    }
                  />
                </RibbonHoc>
              </div>

              {optionSelected !== "story" &&
                canApplyWorkflow &&
                String(selectedMedia?.media?.permalink || "").trim().length >
                  0 && (
                  <div
                    className={styles.instaContainer}
                    style={{ cursor: "pointer" }}>
                    <p
                      className={styles.instaButton}
                      onClick={() => {
                        window.open(selectedMedia?.media?.permalink || "");
                      }}>
                      Open in Instagram
                    </p>
                    <FiArrowUpRight color="#000000B2" size={18} />
                  </div>
                )}

              {optionSelected !== "story" && (
                <div style={{ padding: 4 }}>
                  {workflowLoading ? (
                    <Row justify="center">
                      <Spin />
                    </Row>
                  ) : (
                    <>
                      {workflowData?.data?.canApplyWorkflowOnMedia ? (
                        <ReportButton
                          onClick={() =>
                            setWorkflowModalPros({
                              visible: true,
                              workflowId: "",
                            })
                          }>
                          Add a Workflow
                        </ReportButton>
                      ) : (
                        <>
                          {workflowData?.data?.workflowId ? (
                            <ReportButton
                              onClick={() =>
                                setWorkflowModalPros({
                                  visible: true,
                                  workflowId:
                                    workflowData?.data?.workflowId || "",
                                })
                              }>
                              View Added Workflow
                            </ReportButton>
                          ) : (
                            <h5>
                              You can not apply workflow to this media because{" "}
                              {workflowData?.data?.reason}
                            </h5>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </Col>
            <Col span={17} offset={1}>
              <Space
                size={10}
                wrap
                style={{ height: "35vh", overflowY: "scroll", padding: 2 }}>
                {Object.entries(selectedMedia?.media)
                  .filter((metricItem) => {
                    const elementToShowConfig =
                      optionSelected === "feed"
                        ? igPostMetrics
                        : optionSelected === "reels"
                        ? igReelMetrics
                        : igStoryMetrics;

                    const elementKey = metricItem[0];

                    return elementToShowConfig.includes(elementKey);
                  })
                  .map((ele, index) => {
                    const [key, value] = ele;
                    const fieldName =
                      configData?.data?.userFieldsConfiguration?.find(
                        (fieldItem) => fieldItem.variableName === key
                      )?.name || key;
                    const fieldDesc =
                      configData?.data?.userFieldsConfiguration?.find(
                        (fieldItem) => fieldItem.variableName === key
                      )?.description || "";

                    return (
                      <MetricCard
                        handlePin={() => {}}
                        key={index}
                        title={fieldName}
                        desc={fieldDesc}
                        metric={`${formatData((value as number) || 0, "a")}`}
                        isPinned={false}
                        disablePin={true}
                      />
                    );
                  })}
              </Space>
            </Col>
          </Row>
        </>
      )}
      <AddWorkflow
        handleClose={() =>
          setWorkflowModalPros({ visible: false, workflowId: "" })
        }
        isVisible={workflowModalPros?.visible}
        workflowId={workflowModalPros?.workflowId}
        isView={Boolean(workflowModalPros?.workflowId)}
        selectedMedia={
          Boolean(workflowModalPros?.workflowId)
            ? undefined
            : {
                type:
                  (selectedMedia?.media?.savedMedias || [])[0]?.mediaType ||
                  "IMAGE",
                url: (selectedMedia?.media?.savedMedias || [])[0]?.url || "",
                permaUrl: selectedMedia?.media?.permalink || "",
                mediaId: selectedMedia?.media?.ig_media_id || "",
              }
        }
      />
    </div>
  );
};

export default IndividualPostsInsights;
