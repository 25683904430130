import { sendRequest } from "../helper";
import { ReplyWorkflow } from "../../typings";

export type getWorkflowsResult = {
  status: string;
  message: string;
  data: {
    workflows: ReplyWorkflow[];
    pageNo: number;
  };
};

type RProps = {
  limit?: number;
};

export const getWorkflows = (props: RProps) => {
  const queryKey = ["smart-replies", "workflows"];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getWorkflowsResult>(
      "GET",
      `/brand/smart-replies/workflow?limit=${props?.limit || 20}&pageNo=${
        pageParam || 1
      }`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
