import { Campaign } from "../../../typings";

export const getNextLink = (campaign: Campaign) => {
  const lastStatus = campaign.statusHistory.at(-1)!.status;
  const preLink = `/campaign/create/${campaign._id}/`;

  let linkState = "";
  switch (lastStatus) {
    case "Created-Campaign":
      linkState = "add-products";
      break;
    case "Added-Product":
      linkState = "add-deliverables";
      break;
    case "Added-Diliverables":
      linkState = "creators";
      break;
    case "Added-Commerical-Filters":
      linkState = "guidelines";
      break;
    case "Under-Review":
      linkState = "add-products";
      break;
    case "Needs-Attention":
      linkState = "";
      break;
    case "Active":
      return `/campaign/manage/${campaign._id}?type=appRec`;
    case "Complete":
      return `/campaign/manage/${campaign._id}?type=appRec`;
    default:
      linkState = "preview";
      break;
  }
  return preLink;
};
