import React, { useState } from "react";
import { Space, Radio, Upload, Row, Col, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import { useQuery } from "react-query";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";

import { auth } from "../../../firebase";

import CustomInput from "../../shared/Custom/Input";

import { UserType } from "..";

import styles from "../activate.module.css";

import { countryCodeArray } from "../../assets/country_code";

const { Option } = Select;

interface Props {
  inputValues: UserType;
  errors: UserType;
  setInputValues: React.Dispatch<React.SetStateAction<UserType>>;
  setErrors: React.Dispatch<React.SetStateAction<UserType>>;
}

const BusinessDetails: React.FC<Props> = ({
  inputValues,
  setInputValues,
  errors,
  setErrors,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/brand/${auth.currentUser?.email}/logo/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      setLoading(true);
      setErrors((errors) => ({ ...errors, businessLogoUrl: "" }));
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setInputValues((inputValues) => ({
            ...inputValues,
            businessLogoUrl: downloadURL,
          }));
          setLoading(false);
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setInputValues((inputValues) => ({
        ...inputValues,
        businessLogoUrl: "",
      }));
    },
  };

  const uploadButton = (
    <div className={styles.uploadButtonContainer} style={{ cursor: "pointer" }}>
      {loading ? (
        <div style={{ marginTop: 8 }}>Uploading</div>
      ) : (
        <>
          {" "}
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload Logo</div>
        </>
      )}
    </div>
  );

  return (
    <div>
      <p className={styles.heading}>Tell Us more about Your Organization</p>
      <p className={styles.subHeading}>
        We’ll streamline your setup experience accordingly.
      </p>
      <Col span={10}>
        <div style={{ display: "grid", gap: "24px" }}>
          <CustomInput
            // error={errors.}
            label={"Organization Type"}
            children={
              <Radio.Group
                value={inputValues.businessType}
                onChange={(e) =>
                  setInputValues((inputValues) => ({
                    ...inputValues,
                    businessType: e.target.value,
                  }))
                }>
                <Radio.Button value={"Brand"}> Brand</Radio.Button>
                <Radio.Button value={"Agency"}> Agency</Radio.Button>
              </Radio.Group>
            }
          />

          <CustomInput
            error={errors.businessLogoUrl}
            label={"Organization Logo"}
            children={
              // <Row justify="center">
              <Upload
                listType="picture"
                // style={{ width: "344px !important", height: "149px !important" }}
                fileList={
                  inputValues.businessLogoUrl
                    ? [
                        {
                          uid: inputValues.businessLogoUrl,
                          name: "logo.png",
                          status: "done",
                          url: inputValues.businessLogoUrl,
                          type: "image/jpeg",
                        },
                      ]
                    : []
                }
                {...UploadButtonProps}>
                {inputValues.businessLogoUrl ? null : uploadButton}
              </Upload>
              // </Row>
            }
          />

          <CustomInput
            label={`${inputValues.businessType} Name`}
            error={errors.businessName}
            value={inputValues.businessName}
            onChange={(e) => {
              setInputValues((inputValues) => ({
                ...inputValues,
                businessName: e.target.value || "",
              }));
              setErrors((errors) => ({ ...errors, businessName: "" }));
            }}
          />

          <CustomInput
            label="Website URL"
            error={errors.businessWebsiteUrl}
            value={inputValues.businessWebsiteUrl}
            onChange={(e) => {
              setErrors((errors) => ({ ...errors, businessWebsiteUrl: "" }));
              setInputValues((inputValues) => ({
                ...inputValues,
                businessWebsiteUrl: e.target.value || "",
              }));
            }}
          />

          <CustomInput
            error={errors.countryCode}
            label={"Country"}
            children={
              <Select
                value={inputValues?.countryCode}
                onChange={(value) =>
                  setInputValues((inputValues) => ({
                    ...inputValues,
                    countryCode: value || "",
                  }))
                }
                showSearch
                filterOption={(input, option) => {
                  const selectedOption = countryCodeArray?.find(
                    (item) => item.code === option?.value
                  );

                  return String(selectedOption?.name ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                size="large">
                {countryCodeArray.map((item) => (
                  <Option value={item.code} key={item.name}>
                    {item.flag} ({item.code}) {item.name}
                  </Option>
                ))}
              </Select>
            }
          />
        </div>
      </Col>
    </div>
  );
};

export default BusinessDetails;
