import React, { useState } from "react";
import { Typography, Divider, Row, Button, Menu } from "antd";
import BasicSettings from "./BasicSettings";
import ExecutionSettings from "./ExecutionSettings";
import TimelineSettings from "./TimelineSettings";
import SpecialFieldsSettings from "./SpecialFieldsSettings";

const { Title } = Typography;
interface CampaignSettingsPageProps {
  campaign: any;
}

const CampaignSettingsPage: React.FC<CampaignSettingsPageProps> = ({
  campaign,
}) => {
  const [current, setCurrent] = useState<string>("basic-settings");

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  return (
    <div style={{ padding: 24, maxWidth: "600px" }}>
      <Title level={3}>Settings</Title>

      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="basic-settings">Basic Settings</Menu.Item>
        <Menu.Item key="execution-settings">Execution Settings</Menu.Item>
        <Menu.Item key="timeline-settings">Timeline</Menu.Item>
        <Menu.Item key="special-fields-settings">Special Fields</Menu.Item>
      </Menu>
      <div style={{ padding: "25px 10px 0px 10px" }}>
        {/* Render selected section */}
        {current === "basic-settings" && (
          <section>
            <BasicSettings campaign={campaign} />
          </section>
        )}

        {current === "execution-settings" && (
          <section>
            <ExecutionSettings campaign={campaign} />
          </section>
        )}

        {current === "timeline-settings" && (
          <section>
            <TimelineSettings campaign={campaign} />
          </section>
        )}

        {current === "special-fields-settings" && (
          <section>
            <SpecialFieldsSettings campaign={campaign} />
          </section>
        )}
      </div>

      {/* Save and Cancel Buttons */}
    </div>
  );
};

export default CampaignSettingsPage;
