import React from "react";
import { MdTimeline } from "react-icons/md";
import { Row, Col } from "antd";

import LineGraph from "../../../../../ProfileAnalysis/BasicInsights/LineGraph";
import StatsCard from "../../../../../ProfileAnalysis/AudienceInsights/StatsCard";
import { Doughnut, Bar } from "react-chartjs-2";

import { useReportOnly } from "../../../Context/ReportOnlyContext";

import { formatData, capitalizeFirstLetter } from "../../../../../../utils";

import styles from "./styles.module.css";

const YtGraphs = () => {
  const { advanceMetricsData, platform } = useReportOnly();

  const calculateAggregate = () => {
    let totalObjectData: { [key: string]: { [key: string]: number } } = {};

    if (!advanceMetricsData) return totalObjectData;

    try {
      advanceMetricsData?.data?.forEach((metricItem) => {
        Object.entries(metricItem?.media)?.forEach(
          ([metricMediaItemKey, metricMediaItemValue]) => {
            if (typeof metricMediaItemValue !== "object") {
              return;
            }
            if (
              metricMediaItemKey === "basicStats_lifetime" ||
              metricMediaItemKey === "_id"
            )
              return;

            if (
              metricMediaItemValue?.isError ||
              metricMediaItemValue?.isErrorTransformingPayload
            )
              return;

            if (!(metricMediaItemKey in totalObjectData)) {
              totalObjectData = {
                ...totalObjectData,
                [metricMediaItemKey]: {},
              };
            }

            Object.entries(metricMediaItemValue?.data).forEach(
              ([objectKey, objValue]) => {
                if (
                  typeof objValue === "object" &&
                  !Object.is(objValue, null)
                ) {
                  if (metricMediaItemKey === "viewerDemographics_lifetime") {
                    const additionValue =
                      ((objValue?.viewerPercentage || 0) *
                        (metricItem?.media?.basicStats_lifetime?.data?.views ||
                          1)) /
                      100;

                    totalObjectData[metricMediaItemKey][objectKey] =
                      totalObjectData[metricMediaItemKey][objectKey]
                        ? totalObjectData[metricMediaItemKey][objectKey] +
                          additionValue
                        : additionValue;
                  } else {
                    totalObjectData[metricMediaItemKey][objectKey] =
                      totalObjectData[metricMediaItemKey][objectKey]
                        ? totalObjectData[metricMediaItemKey][objectKey] +
                            objValue?.views || 0
                        : objValue?.views || 0;
                  }
                }
              }
            );
          }
        );
      });
      return totalObjectData;
    } catch (error) {
      console.log("this err", error);
      return {};
    }
  };

  const getDayViews = () => {
    let result: { labels: string[]; values: number[] } = {
      labels: [],
      values: [],
    };
    const response = calculateAggregate();

    if (!("basicStats_last_30_days" in response)) return result;

    Object.entries(response["basicStats_last_30_days"]).forEach(
      ([key, value]) => {
        result = {
          labels: [...result.labels, key.split("$").at(-1) || ""],
          values: [...result.values, value],
        };
      }
    );

    return result;
  };

  const getCountryViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = calculateAggregate();
    if (!("countryStats_lifetime" in response)) return result;

    Object.entries(response["countryStats_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getSubscribedViews = () => {
    const response = calculateAggregate();
    if (!("subscribedStatus_lifetime" in response))
      return { subs: 0, unSubs: 0 };
    let subs = response["subscribedStatus_lifetime"]["$SUBSCRIBED"] || 0;
    let unSubs = response["subscribedStatus_lifetime"]["$UNSUBSCRIBED"] || 0;

    return { subs, unSubs };
  };

  const getPlaybackLocationsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = calculateAggregate();
    if (!("playBackLocation_lifetime" in response)) return result;

    Object.entries(response["playBackLocation_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getOsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = calculateAggregate();
    if (!("operatingSystemDeviceType_lifetime" in response)) return result;

    Object.entries(response["operatingSystemDeviceType_lifetime"]).forEach(
      ([key, value]) => {
        result.push({
          value,
          label: key.split("**$").at(-1)?.split("$").join(" ") || "",
        });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getTrafficViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = calculateAggregate();
    if (!("trafficSource_lifetime" in response)) return result;

    Object.entries(response["trafficSource_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getDemographicViews = () => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};

    const response = calculateAggregate();
    if (!("viewerDemographics_lifetime" in response)) return totalObject;

    Object.entries(response["viewerDemographics_lifetime"]).forEach(
      ([key, value]) => {
        // const age_genderKey = key.split("**$").at(-1) || "age18-24_female";
        const ageKey = key.split("$").at(1) || "age18-24";
        const genderKey = key.split("$").at(-1) || "female";
        if (!(ageKey in totalObject)) {
          totalObject = { ...totalObject, [ageKey]: {} };
        }

        if (!(genderKey in totalObject[ageKey])) {
          totalObject = {
            ...totalObject,
            [ageKey]: { ...totalObject[ageKey], [genderKey]: value },
          };
        }
      }
    );

    return totalObject;
  };

  const totalCountryViews =
    getCountryViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalLocationViews =
    getPlaybackLocationsViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalTrafficViews =
    getTrafficViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalOsViews =
    getOsViews().reduce((prev, current) => prev + (current.value || 0), 0) || 1;

  const barData = {
    labels: Object.keys(getDemographicViews()),
    datasets: [
      {
        label: "Female",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.female || 0
        ),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.male || 0
        ),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown",
        data: Object.values(getDemographicViews()).map(
          (item) => item?.genderUserSpecified || 0
        ),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  };

  if (platform === "instagram" || !advanceMetricsData?.data) return null;

  return (
    <>
      {getDayViews().values.length >= 0 && (
        <LineGraph
          hidePin
          key={0}
          title="Views per day"
          optionsItemArray={[]}
          selectedOption={""}
          labelArray={getDayViews().labels}
          metric={formatData(
            getDayViews().values.reduce((prev, current) => prev + current, 0),
            "a"
          )}
          metricLabel={` Total Views`}
          description={<></>}
          graphData={getDayViews().values}
          secondaryDescription={<p>Views per day</p>}
          secondaryIcon={<MdTimeline size={15} color="#000000B2" />}
          barColor="#398200"
        />
      )}
      {getCountryViews().length > 0 && (
        <div className={styles.graphContainer} key={1}>
          <p className={styles.graphLabel}>Country wise split</p>
          <StatsCard
            items={getCountryViews().map((item) => ({
              label: item.label,
              percent: (item.value / totalCountryViews) * 100,
            }))}
          />
        </div>
      )}
      {!(
        getSubscribedViews().subs === 0 && getSubscribedViews().unSubs === 0
      ) && (
        <div className={styles.container} key={2}>
          <Row
            justify="space-between"
            style={{ width: "100%" }}
            gutter={[10, 10]}>
            <Col span={24}>
              <p className={styles.label}>Views by subscribers</p>
            </Col>
            <Col span={24}>
              <p className={styles.sublabel}>
                <MdTimeline size={15} color="#000000B2" />
                Views by subscribers
              </p>
            </Col>
          </Row>
          <Doughnut
            options={{
              plugins: {
                legend: { position: "top" },
                datalabels: {
                  anchor: "end",
                  align: "start",
                  formatter: (value) => formatData(value || 0, "a"),
                  font: {
                    size: 16,
                    style: "normal",
                  },
                },
              },
            }}
            data={{
              labels: ["Subscribed", "Not-Subscribed"],
              datasets: [
                {
                  label: "No",
                  data: [
                    getSubscribedViews().subs,
                    getSubscribedViews().unSubs,
                  ],
                  backgroundColor: ["#FF6384", "#FFCD56"],
                  borderColor: ["#FF6384", "#FFCD56"],
                  borderWidth: 1,
                  borderJoinStyle: "round",
                  borderRadius: 10,
                  hoverOffset: 4,
                  offset: 0,
                },
              ],
            }}
          />
        </div>
      )}

      {getPlaybackLocationsViews().length > 0 && (
        <div className={styles.graphContainer} key={3}>
          <p className={styles.graphLabel}>PlaybackLocation wise split</p>
          <StatsCard
            items={getPlaybackLocationsViews().map((item) => ({
              label: capitalizeFirstLetter(item.label.split("_").join(" ")),
              percent: (item.value / totalLocationViews) * 100,
            }))}
          />
        </div>
      )}

      {getTrafficViews().length > 0 && (
        <div className={styles.graphContainer} key={4}>
          <p className={styles.graphLabel}>Traffic wise split</p>
          <StatsCard
            items={getTrafficViews().map((item) => ({
              label: capitalizeFirstLetter(item.label.split("_").join(" ")),
              percent: (item.value / totalTrafficViews) * 100,
            }))}
          />
        </div>
      )}
      {getOsViews().length > 0 && (
        <div className={styles.graphContainer} key={5}>
          <p className={styles.graphLabel}>Device wise split</p>
          <StatsCard
            items={getOsViews().map((item) => ({
              label: capitalizeFirstLetter(item.label.split("_").join(" ")),
              percent: (item.value / totalOsViews) * 100,
            }))}
          />
        </div>
      )}
      {Object.keys(getDemographicViews()).length > 0 && (
        <div className={styles.graphContainer}>
          <p className={styles.graphLabel}>Age-Gender Ratio</p>
          <div
            style={{
              width: "100%",
              height: "308px",
            }}>
            <Bar
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: false,
                  },
                  datalabels: {
                    anchor: "end",
                    align: "top",
                    formatter: (value) => `${formatData(value || 0, "0.0a")}%`,
                  },
                },
              }}
              data={barData}
              style={{ height: "320px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default YtGraphs;
