import { Modal, Alert } from "antd";
import React from "react";
import { useQuery } from "react-query";

import styles from "./styles.module.css";

import ReportButton from "../../Campaign/Report/ReportButton";

import { getUser } from "../../../handler/getUser";

interface ContactModalProps {
  show: boolean;
  handleClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = (props) => {
  const { show, handleClose } = props;

  const [getUserKey, getUserFn] = getUser();

  const { data: initialUserData } = useQuery(getUserKey, getUserFn);

  return (
    <Modal
      visible={show}
      title={<span className={styles.modalTitle}>Issue In Activation</span>}
      centered
      onCancel={() => handleClose()}
      footer={null}>
      {initialUserData?.data?.kyc?.remarks && (
        <Alert
          message="Alert"
          description={initialUserData?.data?.kyc?.remarks}
          type="warning"
          showIcon
          //   style={{ margin: "20px 0" }}
        />
      )}
      <p className={styles.activateText} style={{ marginBlock: 10 }}>
        Contact us if there is an issue in activating your Account.
      </p>
      <ReportButton
        type="primary"
        style={{ marginBlock: 10 }}
        onClick={() => {
          const email = "app@cloutflow.com"; // Change this to the recipient's email address
          const subject = "Account Activation"; // Change this to the desired email subject
          //   const body = "Write your message here..."; // Change this to the desired email body
          const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
            subject
          )}`;

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = mailtoLink;

          anchor.click();
        }}>
        Contact Us
      </ReportButton>
    </Modal>
  );
};
export default ContactModal;
