import React, { useState } from "react";
import styles from "../Campaign.module.css";
import { Dropdown, Tag, Menu, message, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";

import { Campaign } from "../../../typings";
import { changeVisibility } from "../../../handler/campaign";

import { getCampaignStatus, getNextLink } from "../utils";
import { AxiosError } from "axios";
import { deleteCampaign, archiveCampaign } from "../../../handler/campaign/";

interface TitleProps {
  campaignkey: (string | number | boolean)[];
  campaignCountKey: (string | number | boolean)[];
  campaign: Campaign;
}

const getTagColor = (status: string) => {
  switch (status) {
    case "Under Review":
      return "processing";
    case "Draft":
      return "warning";
    case "Active":
      return "success";
    case "Needs-Attention":
      return "error";
    default:
      return "default";
  }
};

const Tile: React.FC<TitleProps> = ({
  campaignkey,
  campaign,
  campaignCountKey,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const status = getCampaignStatus(campaign);
  const campLink = getNextLink(campaign);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const deleteCampaignMutation = useMutation(deleteCampaign, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(campaignkey);
      queryClient.invalidateQueries(campaignCountKey);
      setConfirmationModalOpen(false);
      message.success("Campaign Deleted");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const changeVisibilityMutation = useMutation(changeVisibility, {
    onSuccess: () => {
      queryClient.invalidateQueries(campaignkey);
      setIsModalOpen(false);
      message.success("Campaign Updated");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  const handleOk = () => {
    changeVisibilityMutation.mutate({
      campaignId: campaign._id,
      isPublic: !campaign.isPublic,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const archiveCampaignMutation = useMutation(archiveCampaign, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(campaignkey);
      queryClient.invalidateQueries(campaignCountKey);
      message.success("Campaign Archived");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any)?.message);
    },
  });

  const menu = () => {
    return (
      <>
        <Menu
          items={[
            getCampaignStatus(campaign) === "Active"
              ? [
                  {
                    key: "1",
                    label: (
                      <p
                        onClick={() =>
                          navigator.clipboard
                            .writeText(
                              `https://cloutflow.com/creators/ongoing-campaigns/${campaign._id}$${campaign.defaultDeal}`
                            )
                            .then(() =>
                              message.success("Link coped to clipboard")
                            )
                        }>
                        {" "}
                        Share Campaign
                      </p>
                    ),
                  },
                ]
              : [],
            {
              key: "3",
              label: (
                <>
                  <p
                    onClick={() => {
                      if (getCampaignStatus(campaign) === "Draft") {
                        return navigate(getNextLink(campaign));
                      }
                      if (getCampaignStatus(campaign) === "Under-Review") {
                        return setConfirmationModalOpen(true);
                      }
                      return setIsModalOpen(true);
                    }}>
                    {getCampaignStatus(campaign) === "Draft"
                      ? "Continue Editing"
                      : getCampaignStatus(campaign) === "Under-Review"
                      ? "Delete Campaign"
                      : `Make campaign ${
                          campaign.isPublic ? "Private" : "Public"
                        }`}
                  </p>
                </>
              ),
            },
            {
              key: "archive",
              label: (
                <p
                  onClick={() => {
                    return archiveCampaignMutation.mutate({
                      campaignId: campaign?._id,
                      isArchived: !campaign?.isArchived,
                    });
                  }}>
                  Make Campaign {campaign?.isArchived ? "Active" : "Inactive"}
                </p>
              ),
            },

            {
              key: "2",
              label: (
                <p
                  onClick={() => {
                    if (getCampaignStatus(campaign) === "Draft") {
                      return setConfirmationModalOpen(true);
                    }
                    return navigate(
                      `/campaign/create/${campaign._id}?platform=${campaign.platform}`
                    );
                  }}>
                  {getCampaignStatus(campaign) === "Draft"
                    ? "Delete Draft"
                    : "Preview"}
                </p>
              ),
            },
          ].flat()}
          onClick={(event) => {
            // console.log(event);
            if (event.key !== "archive") event.domEvent.stopPropagation();
          }}
        />
        <Modal
          title={
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                padding: "10px 0px",
              }}>
              <img
                style={{ width: "22px", height: "22px" }}
                src="/assets/icons/info.svg"
                alt=""
              />
              <p>{campaign.isPublic ? "PRIVATE" : "PUBLIC"}</p>
            </div>
          }
          visible={isModalOpen}
          onOk={handleOk}
          okText={`Yes, make it ${campaign.isPublic ? "private" : "public"}`}
          cancelText="Go back"
          okButtonProps={{ loading: changeVisibilityMutation.isLoading }}
          onCancel={handleCancel}>
          <p>
            {campaign.isPublic
              ? "You won’t be able to receive more applications, you can still process previous applications and invite creators to participate.You can switch back anytime."
              : "Everyone will be able to apply to the campaign. You can switch back anytime."}
          </p>
        </Modal>
        <Modal
          title={
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                padding: "10px 0px",
              }}>
              <img
                style={{ width: "22px", height: "22px" }}
                src="/assets/icons/info.svg"
                alt=""
              />
              <p>Delete Campaign</p>
            </div>
          }
          visible={confirmationModalOpen}
          onOk={() =>
            deleteCampaignMutation.mutate({
              campaignId: campaign._id,
            })
          }
          okText={`Delete`}
          cancelText="Cancel"
          okButtonProps={{ loading: changeVisibilityMutation.isLoading }}
          onCancel={() => setConfirmationModalOpen(false)}>
          <p>
            Are you sure you want to delete <b>{campaign.campaignName}</b>{" "}
            campaign
          </p>
        </Modal>
      </>
    );
  };

  return (
    <section className={styles.tileContainer}>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(campLink)}>
        {status === "Under-Review" ? (
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <img
              src={`/assets/img/${campaign.platform}.png`}
              alt="platform"
              style={{ width: "20px", height: "20px" }}
            />
            <p className={styles.cName}>{campaign.campaignName}</p>
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img
                src={`/assets/img/${campaign.platform}.png`}
                alt="platform"
                style={{ width: "20px", height: "20px" }}
              />
              <p className={styles.cName}>{campaign.campaignName}</p>
            </div>
          </div>
        )}
      </div>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(campLink)}>
        <p className={styles.cDate}>
          {status === "Draft" ||
          status === "Under-Review" ||
          status === "Needs-Attention"
            ? "-"
            : campaign.isPublic
            ? "Public"
            : "Private"}
        </p>
      </div>
      <p className={styles.cDate}>
        {format(new Date(campaign.createdDateTime), "d LLL, yyyy")}
      </p>
      <div
        style={status !== "Under-Review" ? { cursor: "pointer" } : {}}
        onClick={() => status !== "Under-Review" && navigate(campLink)}>
        {status === "Under-Review" ? (
          <div className="">
            <Tag color={getTagColor(status)} className={styles.tag}>
              {status}
            </Tag>
          </div>
        ) : (
          <Link to={campLink}>
            <Tag color={getTagColor(status)} className={styles.tag}>
              {status === "Needs-Attention"
                ? "Issue in Review"
                : status === "Active"
                ? "On Going"
                : status}
            </Tag>
          </Link>
        )}
      </div>

      <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
        <img
          src="/assets/icons/options.svg"
          alt="options icons"
          style={{ cursor: "pointer" }}
        />
      </Dropdown>
    </section>
  );
};

export default Tile;
