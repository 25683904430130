import React from "react";
import { Progress, Row } from "antd";

import nf from "@tuplo/numberfmt";

import styles from "../demographic.module.css";

interface CircularStatsCardProps {
  title: string;
  items: { label: string; percent: number }[];
}

const CircularStatsCard: React.FC<CircularStatsCardProps> = ({
  title,
  items,
}) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <Row style={{ marginTop: "30px" }} justify="center">
        <Progress
          type="circle"
          success={{
            percent: items[0].percent,
            strokeColor: "#2130FF",
          }}
          strokeColor="rgba(33, 48, 255, 0.4)"
          strokeLinecap="square"
          percent={items[0].percent + items[1].percent}
          showInfo={false}
          strokeWidth={10}
        />
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "24px",
          gap: "15px",
        }}>
        {items.map(
          (item, idx) =>
            idx <= 1 && (
              <Row style={{ marginLeft: "40px" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <div
                    style={{
                      background:
                        idx === 0
                          ? "#2130FF"
                          : idx === 1
                          ? "rgba(33, 48, 255, 0.4)"
                          : "#f5f5f5",
                      borderRadius: "2px",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                  <span className={styles.label}>{item.label}</span>
                  <span className={styles.percent}>
                    {nf(item.percent / 100, "0.00%")}
                  </span>
                </div>
              </Row>
            )
        )}
      </div>
    </div>
  );
};

export default CircularStatsCard;
