import React, { useState } from "react";
import { FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Table, Space, Typography, Button, Grid, Row, Spin } from "antd";
import type { TableColumnsType } from "antd";
import { ResultItem } from "../../../typings";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { formatData } from "../../../utils";
import ProfileModal from "../../ProfileAnalysis3p/ProfileModal";
import InfluencerModal from "../../CloutflowCampaign/Common/SuggestInfluencerModal";
import { useQuery } from "react-query";
import RequestInfluencerModal from "../../CloutflowCampaign/Common/RequestInfluencerModal";
import { getOrgAppState } from "../../../handler/organization";
import EmptySearchTemplate from "./EmptySearch";

const { Title, Text } = Typography;

interface DataType extends ResultItem {
  key: React.Key;
}

const ResultsTable = () => {
  const {
    creatorData,
    searchParams,
    setSelectedCreators,
    selectedCreators,
    isLoading,
    isSearchEnabled,
  } = useCreatorSearchContext();

  const [orgUserKey, orgUserFn] = getOrgAppState();
  const { data: orgUserData, isLoading: orgUserDataLoading } = useQuery(
    orgUserKey,
    orgUserFn
  );
  const handleClose = () => {
    setCampaignModalProps({
      ...campaignModalProps,
      visible: false,
    });
  };
  const [campaignModalProps, setCampaignModalProps] = useState<{
    visible: boolean;
    platform: string;
    username: string;
  }>({ visible: false, platform: "instagram", username: "" });

  const [open, setOpen] = useState(false);
  const [influencer, setInfluencer] = useState({ platform: "", username: "" });

  const columns: TableColumnsType<DataType> = [
    {
      title: "Creator",
      dataIndex: "name",
      render: (
        _,
        {
          image_url,
          is_verified,
          full_name,
          url,
          work_platform,
          platform_username,
        }
      ) => (
        <Space>
          <img
            style={{ height: "60px", width: "60px", borderRadius: "999px" }}
            src={image_url}
            alt="dp"
          />
          <div style={{ display: "grid", gap: "5px" }}>
            <Title
              level={4}
              style={{
                margin: 0,
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {full_name}
              {is_verified && (
                <RiVerifiedBadgeFill size={21} color="rgb(29, 155, 240)" />
              )}
            </Title>
            <a href={url} target="_blank" rel="noreferrer">
              <div style={{ display: "flex", alignItems: "center" }}>
                {work_platform?.name === "Instagram" ? (
                  <FaInstagram size={16} color="rgba(0, 0, 0, 0.45)" />
                ) : work_platform?.name === "TikTok" ? (
                  <FaTiktok size={16} color="rgba(0, 0, 0, 0.45)" />
                ) : (
                  <FaYoutube size={16} color="rgba(0, 0, 0, 0.45)" />
                )}
                <Text type="secondary" style={{ marginLeft: "5px" }}>
                  @{platform_username}
                </Text>
                <GoArrowUpRight size={16} color="rgba(0, 0, 0, 0.45)" />
              </div>
            </a>
          </div>
        </Space>
      ),
    },
    {
      title:
        searchParams?.platform === "instagram" ? "Followers" : "Subscribers",
      render: (_, { follower_count, subscriber_count }) => (
        <p>
          {searchParams?.platform === "instagram"
            ? formatData(follower_count || 0, "a")
            : formatData(subscriber_count || 0, "a")}
        </p>
      ),
    },
    {
      title: "Average Likes",
      dataIndex: "avg_likes",
      render: (_, { average_likes }) => (
        <p>{formatData(average_likes || 0, "a")}</p>
      ),
    },
    {
      title: "Engagement rate",
      render: (_, { engagement_rate }) => (
        <p>{formatData(engagement_rate || 0, "%")}</p>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            style={{ borderRadius: "8px" }}
            onClick={() => {
              setInfluencer({
                username: record?.platform_username,
                platform: record?.work_platform?.name.toLocaleLowerCase(),
              });
              setOpen(true);
            }}
          >
            Open Profile
          </Button>
          {orgUserData?.data.accessLevel !== "brand" && (
            <Button
              type="primary"
              style={{ borderRadius: "8px", marginLeft: "5px" }}
              onClick={() => {
                setCampaignModalProps({
                  username: record?.platform_username,
                  platform: record?.work_platform?.name.toLocaleLowerCase(),
                  visible: true,
                });
              }}
            >
              Suggest Influencer
            </Button>
          )}
          {orgUserData?.data.accessLevel === "brand" && (
            <Button
              type="primary"
              style={{ borderRadius: "8px", marginLeft: "5px" }}
              onClick={() => {
                setCampaignModalProps({
                  username: record?.platform_username,
                  platform: record?.work_platform?.name.toLocaleLowerCase(),
                  visible: true,
                });
              }}
            >
              Request Price
            </Button>
          )}
        </div>
      ),
    },
  ];

  if (isLoading)
    return (
      <Row justify={"center"}>
        <Spin size="large" />
      </Row>
    );

  if (creatorData) {
    return (
      <>
        <Table
          // rowSelection={{
          //   type: "checkbox",
          //   selectedRowKeys: selectedCreators?.map(
          //     (sItem) => sItem?.external_id
          //   ) as React.Key[],
          //   onChange: (
          //     selectedRowKeys: React.Key[],
          //     selectedRows: DataType[]
          //   ) => {
          //     setSelectedCreators(selectedRows);
          //   },
          // }}
          columns={columns}
          pagination={false}
          dataSource={
            Array.isArray(creatorData?.data?.data)
              ? creatorData.data.data.map((dItem: any) => ({
                  ...dItem,
                  key: dItem?.external_id || "",
                }))
              : []
          }
        />

        <ProfileModal
          username={influencer.username}
          platform={influencer.platform}
          open={open}
          setOpen={setOpen}
        />
        {orgUserData?.data.accessLevel === "brand" && (
          <RequestInfluencerModal
            visible={campaignModalProps.visible}
            onClose={handleClose}
            username={campaignModalProps.username}
            platform={campaignModalProps.platform}
          />
        )}
        {orgUserData?.data.accessLevel !== "brand" && (
          <InfluencerModal
            visible={campaignModalProps.visible}
            onClose={handleClose}
            username={campaignModalProps.username}
            platform={campaignModalProps.platform}
          />
        )}
      </>
    );
  }
  return <EmptySearchTemplate />;
};

export default ResultsTable;
