import { sendRequest } from "../helper";

interface postReviewProps {
  influencerId: string;
  finalizedOfferId?: string;
  review: string;
  rating: number;
}

export const postReview = (props: postReviewProps) => {
  return sendRequest("post", `/brand/brand-influencer/reviews`, false, {
    ...props,
  }).then((res) => res.data);
};
