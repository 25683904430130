import { sendRequest } from "./helper";
interface verifyORGDetailsProps {
  verificationType: string;
  socialMediaProfileUrl?: string;
  socialMediaUsed?: string;
  cin?: string;
  officalDoc?: string[];
}

export const verifyORGDetails = ({
  verificationType,
  socialMediaProfileUrl,
  socialMediaUsed,
  cin,
  officalDoc,
}: verifyORGDetailsProps) => {
  if (verificationType === "offical-doc") {
    //   return sendRequest("post", `/brand/me/organization-verification`, false, {
    //     verificationType,
    //     cin: cin ? cin : null,
    //     officalDoc: officalDoc && officalDoc.length ? officalDoc : null,
    //   }).then((res) => res.data);
    if (cin && officalDoc && officalDoc.length) {
      return sendRequest("post", `/brand/me/organization-verification`, false, {
        verificationType,
        cin,
        officalDoc,
      }).then((res) => res.data);
    } else if (cin) {
      return sendRequest("post", `/brand/me/organization-verification`, false, {
        verificationType,
        cin,
      }).then((res) => res.data);
    } else {
      return sendRequest("post", `/brand/me/organization-verification`, false, {
        verificationType,
        officalDoc,
      }).then((res) => res.data);
    }
  } else {
    return sendRequest("post", `/brand/me/organization-verification`, false, {
      verificationType,
      socialMediaProfileUrl,
      socialMediaUsed,
    }).then((res) => res.data);
  }
};
