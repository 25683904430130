import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import {
  Modal,
  Row,
  Col,
  Divider,
  Input,
  Button,
  Space,
  Select,
  Upload,
  message,
} from "antd";
import { MdDeleteForever } from "react-icons/md";
import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import type { RcFile, UploadProps } from "antd/es/upload";
import cuid from "cuid";
import { AxiosError } from "axios";

import CustomInput from "../../shared/Custom/Input";
import InfoPopover from "../../shared/InfoPopover";
import PreviewMessage from "./PreviewMessage";
import ReportButton from "../../Campaign/Report/ReportButton";

import { auth } from "../../../firebase";
import {
  addProducts,
  getProducts,
  editProduct,
} from "../../../handler/replies";

import globalStyles from "../workflows.module.css";

import { MessageReply } from "../../../typings";
import { isLinkValid } from "../../../utils";

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  initialData?: MessageReply;
  isView?: boolean;
  isEdit?: boolean;
}

const AddMessageModal: React.FC<Props> = ({
  isVisible,
  handleClose,
  isView = false,
  isEdit = false,
  initialData,
}) => {
  const [userInput, setUserInput] = useState<MessageReply>({
    title: "",
  });

  const queryClient = useQueryClient();

  const [userInputErr, setUserInputErr] = useState<MessageReply>({
    title: "",
    image_url: "",
    subtitle: "",
  });

  const resetForm = () => {
    setUserInput({
      title: "",
      buttons: [],
      default_action: undefined,
      image_url: undefined,
      subtitle: undefined,
    });
    setUserInputErr({
      title: "",
      image_url: "",
      subtitle: "",
    });
  };

  useEffect(() => {
    if (!isVisible) {
      return resetForm();
    }
    if (isVisible && initialData?.title) {
      return setUserInput(initialData);
    }
  }, [isVisible]);

  const handleButtonInputChange = (
    fieldName: "url" | "title" | "type",
    fieldValue: string,
    id: number
  ) => {
    const oldButtons = userInput?.buttons || [];

    const updatedButtons = oldButtons?.map((bItem, idx) => {
      if (idx === id) {
        bItem = { ...bItem, [fieldName]: fieldValue };
      }
      return bItem;
    });

    setUserInput((userInput) => ({ ...userInput, buttons: updatedButtons }));
  };

  const uploadButton = (
    <div className={globalStyles.uploadButtonContainer}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Allowed file formats are png, jpg and jpeg.
      </div>
    </div>
  );

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/replies/${auth?.currentUser?.uid}/img/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setUserInput((inp) => ({ ...inp, image_url: downloadURL }));
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setUserInput((inp) => ({ ...inp, image_url: undefined }));
    },
  };

  const getTrimmedLength = (val?: string) => String(val || "").trim().length;

  const isDataValid = () => {
    let isError = false;

    let errorObj: MessageReply = userInputErr;

    if (getTrimmedLength(userInput?.title) <= 0) {
      isError = true;
      errorObj = { ...errorObj, title: "Please Enter a title" };
    } else {
      errorObj = { ...errorObj, title: "" };
    }

    if (
      getTrimmedLength(userInput?.default_action?.url) > 0 &&
      !isLinkValid(userInput?.default_action?.url || "")
    ) {
      isError = true;
      errorObj = {
        ...errorObj,
        default_action: { url: "Please Enter a valid Url", type: "web_url" },
      };
    } else {
      errorObj = {
        ...errorObj,
        default_action: { url: "", type: "web_url" },
      };
    }

    if ((userInput?.buttons || [])?.length > 0) {
      let buttonError: MessageReply["buttons"] =
        userInput?.buttons?.map((item) => {
          if (item.type === "web_url") {
            return { type: "web_url", url: "", title: "" };
          }

          return {
            type: "postback",
            title: "",
            payload: "DEVELOPER_DEFINED_PAYLOAD",
          };
        }) || [];
      userInput?.buttons?.forEach((button, index) => {
        if (getTrimmedLength(button.title) <= 0) {
          isError = true;
          buttonError![index].title = "Please Enter button Title";
        }

        if (button.type === "web_url") {
          if (getTrimmedLength(button.url) <= 0 || !isLinkValid(button.url)) {
            isError = true;
            if (buttonError![index].type === "web_url") {
              (buttonError![index] as any).url =
                "Please Enter a valid button URL";
            }
          }
        }
      });

      errorObj = { ...errorObj, buttons: buttonError };
    }

    setUserInputErr(errorObj);

    return !isError;
  };
  const [messagesKey] = getProducts({ limit: 20 });

  const addProductsMutation = useMutation(addProducts, {
    onSuccess: () => {
      message.success("Message Added");
      queryClient.invalidateQueries(messagesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  const editProductsMutation = useMutation(editProduct, {
    onSuccess: () => {
      message.success("Message Edited");
      queryClient.invalidateQueries(messagesKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  return (
    <Modal
      width={1000}
      title={null}
      footer={null}
      bodyStyle={{
        borderRadius: "8px",
        padding: 28,
        minHeight: "550px",
      }}
      onCancel={() => {
        handleClose();
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={globalStyles.heading}>
          {isView ? "View" : isEdit ? "Edit" : "Add "} Message Template
        </p>
      </Row>
      <Row>
        <Col
          span={11}
          style={{
            display: "grid",
            gap: "12px",
            height: "540px",
            overflowY: "scroll",
          }}>
          <CustomInput
            label="Title"
            error={userInputErr?.title}
            children={
              <Input
                disabled={isView}
                value={userInput.title}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    title: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />

          <CustomInput
            children={
              <Upload
                listType="picture"
                // style={{ width: "344px !important", height: "149px !important" }}
                fileList={
                  userInput?.image_url
                    ? [
                        {
                          uid: userInput?.image_url,
                          name: "banner.png",
                          status: "done",
                          url: userInput?.image_url,
                          type: "image/jpeg",
                        },
                      ]
                    : []
                }
                {...UploadButtonProps}
                disabled={isView}>
                {userInput?.image_url ? null : uploadButton}
              </Upload>
            }
            label={
              <div className={globalStyles.titleLabel}>
                Message Banner (Optional)
                <InfoPopover desc="Upload a reference image to let the creator know what this message is about " />
              </div>
            }
            error={userInputErr?.image_url}
          />

          <CustomInput
            label={
              <div className={globalStyles.titleLabel}>
                Subtitle (Optional)
                <InfoPopover desc="Subtitle of the message" />
              </div>
            }
            error={userInputErr?.subtitle}
            // className={styles.inputContainer}
            children={
              <Input
                disabled={isView}
                value={userInput?.subtitle}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    subtitle: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />

          <CustomInput
            label={
              <div>
                <div className={globalStyles.titleLabel}>
                  Action Buttons (Optional)
                  <InfoPopover desc="Message Buttons on which creator can take actions" />
                </div>
                <Button
                  style={{ marginLeft: 5 }}
                  disabled={isView}
                  onClick={() =>
                    setUserInput((userInput) => ({
                      ...userInput,
                      buttons: [
                        ...(userInput.buttons || []),
                        { title: "", type: "web_url", url: "" },
                      ],
                    }))
                  }>
                  Add
                </Button>
              </div>
            }
            //   error={userInputErr?.subtitle}
            // className={styles.inputContainer}
            children={
              <ol style={{ display: "grid", gap: 5, marginTop: 10 }}>
                {userInput?.buttons?.map((bItem, idx) => (
                  <li key={idx}>
                    <Space size={15} align="start">
                      <div style={{ display: "grid", gap: "5px" }}>
                        <div>
                          <span>Button Type: </span>

                          <Select
                            disabled={isView}
                            value={bItem?.type}
                            defaultValue={bItem?.type}
                            style={{ width: 120 }}
                            onChange={(val) =>
                              handleButtonInputChange("type", val, idx)
                            }
                            options={[
                              {
                                value: "web_url",
                                label: "Web Url",
                              },
                              {
                                value: "postback",
                                label: "Message Reply",
                              },
                            ]}
                          />
                        </div>
                        <div>
                          <Input
                            disabled={isView}
                            value={bItem.title}
                            style={{ width: "252px" }}
                            prefix="Title : "
                            onChange={(e) =>
                              handleButtonInputChange(
                                "title",
                                e.target.value || "",
                                idx
                              )
                            }
                          />
                          {(userInputErr?.buttons || [])?.length > idx &&
                            userInputErr!.buttons![idx]?.title && (
                              <p className={globalStyles.errorInput}>
                                {userInputErr!.buttons![idx].title}
                              </p>
                            )}
                        </div>

                        {bItem.type === "web_url" && (
                          <div>
                            <Input
                              value={bItem.url}
                              disabled={isView}
                              prefix="Callback Url : "
                              onChange={(e) =>
                                handleButtonInputChange(
                                  "url",
                                  e.target.value || "",
                                  idx
                                )
                              }
                            />
                            {(userInputErr?.buttons || [])?.length > idx &&
                              (userInputErr!.buttons![idx] as any)?.url && (
                                <p className={globalStyles.errorInput}>
                                  {(userInputErr!.buttons![idx] as any)?.url}
                                </p>
                              )}
                          </div>
                        )}
                        {idx !== (userInput?.buttons || [])?.length - 1 && (
                          <Divider style={{ margin: "5px 0" }} />
                        )}
                      </div>

                      <Button
                        // shape="circle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                        }}
                        icon={<MdDeleteForever size={16} />}
                        danger
                        disabled={isView}
                        // type="primary"
                        onClick={() =>
                          setUserInput((userInput) => ({
                            ...userInput,
                            buttons: (userInput?.buttons || []).filter(
                              (_fItem, _fIdx) => _fIdx !== idx
                            ),
                          }))
                        }>
                        Delete
                      </Button>
                    </Space>
                  </li>
                ))}
              </ol>
            }
          />

          <CustomInput
            label={
              <div className={globalStyles.titleLabel}>
                Callback Url on card click (Optional)
                <InfoPopover desc="" />
              </div>
            }
            error={userInputErr?.default_action?.url}
            // className={styles.inputContainer}
            children={
              <Input
                value={userInput.default_action?.url}
                disabled={isView}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    default_action: {
                      url: e.target.value || "",
                      type: "web_url",
                    },
                  }))
                }
                size="large"
              />
            }
          />

          <ReportButton
            type="primary"
            style={{ width: "max-content" }}
            loading={
              addProductsMutation.isLoading || editProductsMutation.isLoading
            }
            onClick={() => {
              if (isView) {
                return handleClose();
              }
              if (isDataValid()) {
                if (isEdit) {
                  return editProductsMutation.mutate({
                    ig: {
                      ...userInput,
                      buttons: userInput?.buttons?.map((bItem) => {
                        if (bItem?.type === "postback") {
                          return {
                            type: bItem?.type,
                            title: bItem?.title,
                            payload: "DEVELOPER_DEFINED_PAYLOAD",
                          };
                        }
                        return bItem;
                      }),
                    },
                  });
                }
                return addProductsMutation.mutate({
                  ig: {
                    ...userInput,
                    buttons: userInput?.buttons?.map((bItem) => {
                      if (bItem?.type === "postback") {
                        return {
                          type: bItem?.type,
                          title: bItem?.title,
                          payload: "DEVELOPER_DEFINED_PAYLOAD",
                        };
                      }
                      return bItem;
                    }),
                  },
                });
              }

              return message.error("Please Recheck the form");
            }}>
            {isView ? "Close" : "Submit"}
          </ReportButton>
        </Col>
        <Divider type="vertical" style={{ height: "auto", margin: "0 20px" }} />
        <Col span={11}>
          <h4>Preview</h4>
          {userInput?.title && <PreviewMessage message={userInput} />}
        </Col>
      </Row>
    </Modal>
  );
};

export default AddMessageModal;
