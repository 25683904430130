import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Row } from "antd";
import Lottie from "react-lottie";
import Cookies from "universal-cookie";

import PrivateRouting from "./components/Routes";
import Signin from "./components/Onboarding-1/Signin";
import Signup from "./components/Onboarding-1/Signup";
import Pay from "./components/Pay";
import Locked from "./components/Locked";
import InAptScreenSize from "./components/InAptScreenSize";
import CustomSignIn from "./components/CustomSignIn";

import animationData from "./components/assets/lottie/Rocket.json";

// import Chat from "./components/Chat";
import { useUserAuth } from "./context/userAuthcontext";
import { useWindowSize } from "./hooks/useWindowSize";
import { getAppState } from "./handler/getAppState";
import { getUser } from "./handler/getUser";
import { getOrgAppState } from "./handler/organization";

import { getDefaultLottieOptions, handleCookieChange } from "./utils";
import "./App.less";

const App = () => {
  const { currentUser } = useUserAuth();
  const navigate = useNavigate();
  const [width] = useWindowSize();
  const cookies = new Cookies();

  const [cookieLoading, setCookieLoading] = useState<boolean>(false);

  const [appStateKey, appStateFn] = getAppState();
  const [userKey, userFn] = getUser();
  const [orgUserKey, orgUserFn] = getOrgAppState();
  const { data: orgUserData, isLoading: orgUserDataLoading } = useQuery(
    orgUserKey,
    orgUserFn,
    {
      enabled: Boolean(currentUser?.uid),
    }
  );

  const { data: appStateData, isLoading } = useQuery(appStateKey, appStateFn, {
    enabled: Boolean(currentUser?.uid),
  });

  const { data: userData, isLoading: userDataLoading } = useQuery(
    userKey,
    userFn,
    { enabled: Boolean(currentUser?.uid) }
  );

  useEffect(() => {
    if (!currentUser || orgUserDataLoading || isLoading || !orgUserData) {
      return;
    }

    if (!currentUser?.emailVerified) {
      return navigate("/verifyMail", { replace: true });
    }

    if (
      orgUserData?.data?.accepted?.length === 0 &&
      orgUserData?.data?.invitations?.length === 0
    ) {
      if (!cookies.get("brandId")) {
        setCookieLoading(true);
        handleCookieChange(orgUserData?.data?.brandId || "");
      }
      return navigate("/activate", { replace: true });
    }
    if (
      (orgUserData?.data?.accepted?.length || 0) === 1 &&
      !cookies.get("brandId")
    ) {
      setCookieLoading(true);
      handleCookieChange(orgUserData?.data?.accepted.at(0)?.brandId || "");
    }

    if (
      (orgUserData?.data?.accepted?.length || 0) > 1 &&
      !cookies.get("brandId") &&
      window.location.pathname !== "/activate"
    ) {
      return navigate("/orgSelect", { replace: true });
    }

    if (
      (orgUserData?.data?.accepted?.length || 0) === 0 &&
      (orgUserData?.data?.invitations?.length || 0) > 0 &&
      !cookies.get("brandId")
    ) {
      return navigate("/acceptInvite", { replace: true });
    }

    if (!cookies.get("brandId")) {
      setCookieLoading(true);
      handleCookieChange(orgUserData?.data?.brandId || "");
    }
  }, [
    currentUser,
    orgUserData,
    orgUserDataLoading,
    isLoading,
    window.location.pathname,
  ]);

  if (isLoading || userDataLoading || orgUserDataLoading || cookieLoading) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}
      >
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  // if (width < 1200) {
  //   return <InAptScreenSize />;
  // }

  return (
    <div className="App" style={{ height: "100vh", overflowY: "auto" }}>
      {currentUser ? (
        <>
          {appStateData?.data?.subscriptionStatus &&
          (appStateData?.data?.subscriptionStatus === "active" ||
            appStateData?.data?.subscriptionStatus === "past_due") ? (
            <PrivateRouting />
          ) : (
            <Routes>
              <Route path="/locked" element={<Locked />} />
              <Route path="*" element={<Navigate to="locked" />} />
            </Routes>
          )}
        </>
      ) : (
        <Routes>
          {/* <Route path="/test-signin" element={<SigninTest />} /> */}
          {/* <Route path="/test-signup" element={<SignupTest />} /> */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/public/custom/" element={<CustomSignIn />} />
          <Route path="/public/custom/:uid" element={<CustomSignIn />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
