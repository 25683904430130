import { sendRequest } from "./helper";

import { tierDetail, tierUsageDetail, SubscriptionPlan } from "../typings";

type UserResult = {
  status: string;
  message: string;
  data: {
    isSubscriptionManagedByStripe: boolean;
    hasMessages: boolean;
    tier: tierDetail;
    tierPlanUsed: tierUsageDetail;
    subscriptionPlan: SubscriptionPlan;
    currencySymbol: string;
    isIndia: boolean;
    subscriptionStatus?: string;
    cardDetails?: {
      brand: string;
      last4: string;
      billingDetailsCountry: string;
      paymentMethod: string;
      email: string;
    };
  };
};

export const getAppState = (isCardRequired = false) => {
  const queryKey = ["user", "app-state", isCardRequired];
  const queryFunction = () =>
    sendRequest<UserResult>(
      "get",
      `/brand/me/app-state${isCardRequired ? "?isCardRequired=true" : ""}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
