import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  Row,
  Col,
  Typography,
  Pagination,
  Skeleton,
  Button,
  Space,
  Select,
  message,
} from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Empty } from "antd";

import SideBar from "../../shared/Sidebar";
import Navbar from "../../shared/Navbar";
import CreatorCard from "../../Campaign/CreatorCard";
import CampaignNotification from "./CamapignNotification";
import PayCard from "./PayCard";
import FinalizedLink from "./FinalizedLink";
import { PayModal } from "../CreatorCard/Modals";
import InviteInfluencerModal from "./InviteInfluencerModal";
import { RejectionModal } from "../CreatorCard/Modals";
import ReportButton from "../Report/ReportButton";

import FinalizedScreen from "./Finalized/Screen";
import SearchPage from "./SearchPage";
import Reports from "./Completed/Reports";

import {
  getAllApplicants,
  getApplicantCount,
} from "../../../handler/campaign/applicants";
import { getPaymentStatus, rejectOffer } from "../../../handler/campaign/offer";
import { getCampaignOfferCount } from "../../../handler/campaign/getCampaignOfferCount";

const ManageCampaign = () => {
  const { id } = useParams();
  const { Title } = Typography;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlAppType = searchParams.get("type");
  const [applicantType, setApplicantType] = useState<
    | "appRec"
    | "shortList"
    | "invite"
    | "reject"
    | "finalized"
    | "completed"
    | "search"
  >("appRec");
  const [sortFields, setSortFields] = useState<{
    sortBy: "none" | "follower" | "engagement";
    sortOrder: "Asc" | "Desc";
  }>({ sortBy: "none", sortOrder: "Desc" });
  const [visible, setVisible] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [payInId, setPayInId] = useState<string>("");
  const queryClient = useQueryClient();
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
  const [addInfluencerModalVisible, setaddInfluencerModalVisible] =
    useState<boolean>(false);

  const getQueryStatus = () => {
    switch (applicantType) {
      case "appRec":
        return "Applied";
      case "shortList":
        return "Shortlisted";
      case "invite":
        return "Invited";
      case "reject":
        return "Rejected";
      case "finalized":
        return "Finalized";
      case "completed":
        return "Completed";
      case "search":
        return "";
    }
  };

  const [getApplicantCountKey, getApplicantCountFn] = getApplicantCount({
    campaignId: id!,
    status: getQueryStatus(),
  });

  const [getPaymentStatusKey, getPaymentStatusFn] = getPaymentStatus({
    campaignId: id!,
  });

  const [getAllApplicantsKey, getAllApplicantsFn] = getAllApplicants({
    campaignId: id!,
    pageNumber: page,
    sortOrder: sortFields.sortOrder,
    sortBy: sortFields.sortBy,
    status: getQueryStatus(),
  });

  const [getCampaignOfferCountKey, getCampaignOfferCountFn] =
    getCampaignOfferCount({ campaignId: id as string });

  const { data: countData } = useQuery(
    getApplicantCountKey,
    getApplicantCountFn,
    {
      enabled: !(getQueryStatus() in ["Finalized", ""]),
    }
  );

  const { data: paymentData, isLoading: isPaymentStatusLoading } = useQuery(
    getPaymentStatusKey,
    getPaymentStatusFn
  );

  const { data: applicantsData, isLoading: isLoadingApplicants } = useQuery(
    getAllApplicantsKey,
    getAllApplicantsFn,
    {
      enabled: !(getQueryStatus() in ["Finalized", ""]),
    }
  );

  const { data: campaignCountData } = useQuery(
    getCampaignOfferCountKey,
    getCampaignOfferCountFn,
    {
      enabled: applicantType === "shortList",
    }
  );

  useEffect(() => {
    if (
      urlAppType &&
      [
        "appRec",
        "shortList",
        "invite",
        "reject",
        "finalized",
        "completed",
        "search",
      ].includes(urlAppType)
    ) {
      return setApplicantType(urlAppType as typeof applicantType);
    }
    return handleMenuChange("appRec");
  }, [urlAppType]);

  useEffect(() => {
    setPage(1);
    setCheckedList([]);
  }, [applicantType]);

  useEffect(() => {
    if (paymentData?.data && paymentData.data.length > 0) {
      const updatedCheckList = paymentData.data[0].offers.map(
        (item) => item._id
      );
      setCheckedList(updatedCheckList);
      setPayInId(paymentData.data[0].payIn._id);
      return setPaymentLink(
        paymentData?.data && paymentData.data.length > 0
          ? paymentData.data[0].payIn.paymentUrl
          : ""
      );
    }
    if (paymentData?.data && paymentData?.data.length === 0) {
      queryClient.invalidateQueries([
        getAllApplicantsKey,
        getApplicantCountKey,
      ]);
      queryClient.invalidateQueries(["campaigns", "count"]);
      setCheckedList([]);
      setPaymentLink("");
      setPayInId("");
      setPage(1);
      return handleCancel();
    }
  }, [paymentData?.data]);

  const handleMenuChange = (type: typeof applicantType) => {
    setApplicantType(type);
    setSortFields({ sortBy: "none", sortOrder: "Desc" });
    queryClient.invalidateQueries(getApplicantCountKey);
    queryClient.invalidateQueries(getAllApplicantsKey);
    // return setSearchParams({ type });
    return navigate(`/campaign/manage/${id}?type=${type}`, { replace: true });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const rejectOfferMutation = useMutation(rejectOffer);

  const RejectionSubmit = async (desc: string) => {
    Promise.allSettled(
      applicantsData?.data
        ? applicantsData?.data?.map((applicantsData) => {
            return rejectOfferMutation.mutateAsync({
              offerId: applicantsData._id,
              reason: desc,
            });
          })
        : []
    ).then(() => {
      queryClient.invalidateQueries("applicants");
      queryClient.invalidateQueries(["campaigns", "count"]);
      setRejectVisible(false);
      setPage(1);
      message.success("Operation Completed");
    });
    // rejectOfferMutation.mutate({ offerId: applicantData._id, reason: desc });
  };

  const handleCheck = (id: string) => {
    if (checkedList.includes(id)) {
      return setCheckedList((checkedList) => {
        return checkedList.filter((checkId) => checkId !== id);
      });
    }
    return setCheckedList((checkedList) => [...checkedList, id]);
  };

  const handleInvalidate = () => {
    queryClient.invalidateQueries(getPaymentStatusKey).then(() => {
      queryClient.invalidateQueries(getAllApplicantsKey);
      queryClient.invalidateQueries(getApplicantCountKey);
    });
  };

  if (!id) {
    return null;
  }

  const getTitle = () => {
    switch (applicantType) {
      case "appRec":
        return "Applications Received";
      case "shortList":
        return "Shortlisted";
      case "invite":
        return "Invited";
      case "reject":
        return "Rejected";
      case "finalized":
        return "Finalized";
      case "completed":
        return "Completed";
    }
  };

  return (
    <>
      <Navbar />

      <Row style={{ height: "100vh" }}>
        <Col span={5}>
          <SideBar
            subMenuType={applicantType}
            handleMenuChange={handleMenuChange}
          />
        </Col>
        {applicantType === "finalized" ? (
          <FinalizedScreen id={id} />
        ) : applicantType === "completed" ? (
          <>
            <Reports campaignId={id} />
          </>
        ) : applicantType === "search" ? (
          <>
            <SearchPage campaignId={id} />
          </>
        ) : (
          <Col
            span={19}
            style={{ background: "#FAFAFA", padding: "20px 0 0 20px" }}>
            <Title style={{ fontWeight: 500 }} level={2}>
              {getTitle()}
            </Title>
            {applicantType === "appRec" && (
              <Col span={15}>
                <Row justify="space-between">
                  <Space
                    style={{
                      justifyContent: "end",

                      marginBottom: "10px",
                    }}>
                    <h4>Sort By : </h4>
                    <Select
                      defaultValue={sortFields.sortBy}
                      style={{ width: 120 }}
                      value={sortFields.sortBy}
                      onChange={(value) => {
                        setSortFields((sortFields) => {
                          return {
                            ...sortFields,
                            sortBy: value as "follower" | "engagement" | "none",
                          };
                        });
                      }}
                      options={[
                        {
                          value: "",
                          label: "Sort By",
                          disabled: true,
                        },
                        {
                          value: "follower",
                          label: "Followers",
                        },
                        {
                          value: "engagement",
                          label: "Engagement",
                        },
                        {
                          value: "none",
                          label: "None",
                        },
                      ]}
                    />
                    <h4 style={{ marginLeft: "30px" }}>Sort Order : </h4>
                    <Select
                      defaultValue={sortFields.sortOrder}
                      style={{ width: 120 }}
                      value={sortFields.sortOrder}
                      onChange={(value) => {
                        setSortFields((sortFields) => {
                          return {
                            ...sortFields,
                            sortOrder: value as "Asc" | "Desc",
                          };
                        });
                      }}
                      options={[
                        {
                          value: "",
                          label: "Sort Order",
                          disabled: true,
                        },
                        {
                          value: "Asc",
                          label: "Ascending",
                        },
                        {
                          value: "Desc",
                          label: "Descending",
                        },
                      ]}
                    />
                  </Space>
                  <Button onClick={() => setRejectVisible(true)}>
                    Reject All
                  </Button>
                </Row>
              </Col>
            )}
            {applicantType === "shortList" &&
              countData &&
              !isPaymentStatusLoading && (
                <Col span={15} style={{ marginBottom: "16px" }}>
                  <Row justify="space-between">
                    <Button
                      type="link"
                      size="middle"
                      onClick={() => setCheckedList([])}
                      style={{
                        display: "flex",
                        gap: "10px",
                        padding: "4px",
                        alignContent: "center",
                      }}
                      disabled={
                        checkedList.length === 0 ||
                        (paymentData &&
                          paymentData?.data.length > 0 &&
                          paymentData?.data[0].payIn.paymentStatus ===
                            "Processing") ||
                        (paymentData?.data &&
                          paymentData?.data.length > 0 &&
                          paymentData?.data[0].payIn.paymentStatus ===
                            "Success" &&
                          (paymentData?.data[0].payIn.status === "Processing" ||
                            paymentData?.data[0].payIn.status ===
                              "Partial-Paid")) ||
                        (paymentData?.data &&
                          paymentData?.data.length > 0 &&
                          paymentData?.data[0].payIn.status === "Failure")
                      }>
                      <img
                        src={`/assets/icons/${
                          checkedList.length === 0 ? "checkbox" : "minus--blue"
                        }.svg`}
                        width={24}
                        alt="minus"
                      />
                      <span
                        style={{
                          fontWeight: checkedList.length > 0 ? 500 : 400,
                          fontSize: "14px",
                          lineHeight: "22px",
                          color: "rgba(0, 0, 0, 0.85)",
                        }}>
                        {checkedList.length > 0
                          ? checkedList.length + "/" + countData?.data
                          : "None"}{" "}
                        selected
                      </span>
                    </Button>
                    <Title level={3}>
                      {paymentData?.data && paymentData?.data.length > 0
                        ? paymentData.data[0].payIn.paymentStatus
                        : ""}
                    </Title>
                  </Row>
                </Col>
              )}

            <Row gutter={[0, 16]}>
              {isLoadingApplicants &&
                isPaymentStatusLoading &&
                [1, 2, 3, 4].map((_) => {
                  return (
                    <Col span={15}>
                      <Skeleton active avatar paragraph={{ rows: 1 }} />
                    </Col>
                  );
                })}
              <Col span={15} style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                <div
                  style={{
                    display: "grid",
                    gap: "16px",
                  }}>
                  {applicantType === "invite" && (
                    <Row justify="end">
                      <ReportButton
                        type="primary"
                        onClick={() => setaddInfluencerModalVisible(true)}>
                        Invite Influencer
                      </ReportButton>
                    </Row>
                  )}
                  {applicantsData?.data && applicantsData?.data.length > 0 ? (
                    <>
                      {applicantsData!.data.map((applicant) => {
                        return (
                          <Col span={24}>
                            <CreatorCard
                              key={applicant._id}
                              type={
                                applicantType as Exclude<
                                  typeof applicantType,
                                  "search"
                                >
                              }
                              applicantData={applicant}
                              isCardChecked={checkedList.includes(
                                applicant._id
                              )}
                              handleCheck={handleCheck}
                              isDisabled={checkedList.length > 0}
                              isProcessing={
                                applicant.paymentStatus === "Processing"
                              }
                              isCheckable={
                                paymentData?.data.length === 0 ||
                                ((paymentData?.data || []).length > 0 &&
                                  paymentData?.data[0].payIn.paymentStatus !==
                                    "Processing")
                              }
                            />
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!isLoadingApplicants && (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          style={{ marginTop: "30%", marginLeft: "40%" }}
                          description={
                            <p
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "22px",
                                marginTop: "10px",
                              }}>
                              No Applicants
                            </p>
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col
                span={8}
                style={{
                  marginLeft: "22px",
                }}>
                <div style={{ display: "grid", gap: "20px" }}>
                  {applicantType === "shortList" &&
                    !isLoadingApplicants &&
                    applicantsData &&
                    applicantsData?.data.length > 0 && (
                      <>
                        <PayCard
                          checkList={checkedList}
                          campaignId={id}
                          setPayModalVisible={setVisible}
                          queries={[getApplicantCountKey, getAllApplicantsKey]}
                        />
                        {campaignCountData?.data.finalized ? (
                          <FinalizedLink
                            campaignId={id}
                            creatorCount={
                              campaignCountData?.data.finalized || 0
                            }
                          />
                        ) : (
                          <div />
                        )}
                      </>
                    )}
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.06)",
                      borderRadius: "4px",
                    }}>
                    <CampaignNotification campaignId={id} />
                  </div>
                </div>
              </Col>
            </Row>
            {Boolean(countData?.data) && (
              <Col span={15}>
                <Row justify="center">
                  <Pagination
                    total={countData?.data}
                    style={{ margin: "46px auto" }}
                    onChange={(page, _) => setPage(page)}
                    pageSize={15}
                    showSizeChanger={false}
                    current={page}
                  />
                </Row>
              </Col>
            )}
          </Col>
        )}
      </Row>
      <InviteInfluencerModal
        visible={addInfluencerModalVisible}
        handleClose={() => setaddInfluencerModalVisible(false)}
        dealId={campaignCountData?.data?.campaignDefaultDeal || ""}
        platform={campaignCountData?.data?.campaignPlatform || "instagram"}
        campaignId={id}
      />

      <PayModal
        handleCancel={handleCancel}
        creatorIDList={checkedList}
        isVisible={visible}
        campaignId={id}
        initialPaymentLink={paymentLink}
        handleInvalidate={handleInvalidate}
        payInId={payInId}
      />
      <RejectionModal
        isVisible={rejectVisible}
        handleCancel={() => setRejectVisible(false)}
        handleSubmit={RejectionSubmit}
      />
    </>
  );
};

export default ManageCampaign;
