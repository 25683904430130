import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { useQueryClient } from "react-query";
import { useMutation, useQueries, useQuery } from "react-query";

import ReportModal from "../../ReportModal";
import CreatorCard from "../SharedComponents/CreatorCard";

import { CreatorReport } from "../../../../../typings";

import {
  upsertInfluencer,
  getConfigurationReportOnlyCampaign,
  getReportsReportOnlyCampaign,
  getCampaignAggregate,
  getInfluencerReportStatus,
} from "../../../../../handler/report";

import styles from "../AddInfluencer/styles.module.css";

interface EditCommercialProps {
  creator: CreatorReport;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const EditCommercial: React.FC<EditCommercialProps> = ({
  creator,
  visible,
  setVisible,
  campaignId,
  platform,
}) => {
  const queryClient = useQueryClient();
  const [commercial, setCommercial] = useState<number | undefined>(undefined);

  const [influencerStatusKey, influencerStatusFn] = getInfluencerReportStatus({
    campaignId,
    socialMediaId: creator?.socialMediaKit?.socialMediaId || "",
  });

  const { data } = useQuery(influencerStatusKey, influencerStatusFn, {
    enabled: Boolean(creator?.socialMediaKit?.socialMediaId),
  });

  const [querykey] = getCampaignAggregate({ campaignId });

  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });

  const editUserMutation = useMutation(upsertInfluencer, {
    onSuccess: () => {
      queryClient.invalidateQueries(querykey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries("reports");
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  useEffect(() => {
    if (data?.data?.brandPrice) {
      setCommercial(data?.data?.brandPrice);
    }
  }, [data]);

  return (
    <ReportModal
      visible={visible}
      centered
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={() =>
        editUserMutation.mutate({
          campaignId,
          socialMediaId: creator.socialMediaId,
          socialMediaKitId: creator.socialMediaKit._id,
          brandPrice: commercial || 0,
        })
      }
      okText={`Save`}
      okButtonProps={{
        disabled: !commercial || commercial < 0,
        loading: editUserMutation.isLoading,
      }}
      // footer={null}
      title="Edit Price">
      <main style={{ display: "grid", gap: "20px" }}>
        <CreatorCard
          creator={creator}
          handleClick={() => {}}
          isSelected={false}
          campaignId={campaignId}
        />
        <div style={{ width: "60%" }}>
          <p className={styles.label}>
            Edit Price for {creator.socialMediaKit.socialMediaName} (Optional)
          </p>
          <p
            className={styles.subLabel}
            style={{ marginTop: "0", marginBottom: "20px" }}>
            This is used to calculate CPV, CPC, etc
          </p>
          <Input
            addonBefore="$"
            placeholder="Enter Price"
            type="number"
            className={styles.input}
            size="large"
            style={{ width: "70%" }}
            value={commercial}
            onChange={(e) => {
              if (e.target.value) {
                return setCommercial(Number(e.target.value) || 0);
              }
              setCommercial(0);
            }}
          />
        </div>
      </main>
    </ReportModal>
  );
};

export default EditCommercial;
