import React from "react";
import { Modal, ModalProps, Row } from "antd";
import clsx from "clsx";

import styles from "./styles.module.css";

export interface ReportModalProps extends ModalProps {}

const ReportModal: React.FC<ReportModalProps> = (props) => {
  const { okButtonProps, cancelButtonProps, className, ...restProps } = props;

  return (
    <Modal
      title={null}
      bodyStyle={{
        borderRadius: restProps?.bodyStyle?.borderRadius || 8,
        padding: 28,
        ...restProps?.bodyStyle,
      }}
      destroyOnClose
      className={clsx(styles.modal, "custom-modal", className)}
      centered
      width={720}
      cancelButtonProps={{
        ...cancelButtonProps,
        type: "primary",
        ghost: true,
        style: {
          padding: "10px 20px ",
          height: "fit-content",
          borderRadius: "5px",
        },
      }}
      okButtonProps={{
        ...okButtonProps,
        type: "primary",
        style: {
          padding: "10px 20px",
          height: "fit-content",
          borderRadius: "5px ",
          borderColor: "#533af5",
          background: "#533af5",
          color: "white",
          opacity: okButtonProps?.disabled ? 0.5 : 1,
        },
      }}
      {...restProps}>
      {props.children}
    </Modal>
  );
};

export default ReportModal;
