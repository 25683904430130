import { sendRequest } from ".././helper";

import { AddOn } from "../../typings";

type PlanResult = {
  status: string;
  message: string;
  data: AddOn[];
};

export const getAddOns = () => {
  const queryKey = ["add-ons"];
  const queryFunction = () =>
    sendRequest<PlanResult>("get", `/brand/saas-billing/addons`, false).then(
      (res) => res.data
    );
  return [queryKey, queryFunction] as const;
};
