import { sendRequest } from "../helper";

interface inviteInfluencerProps {
  socialMediaKitId: string;
}

interface Result {
  status: string;
  message: string;
  data: {
    isInvitationSendToInfluencer: boolean;
  };
}

export const inviteInfluencer = ({
  socialMediaKitId,
}: inviteInfluencerProps) => {
  return sendRequest<Result>(
    "post",
    `/brand/connect-request/discovery/influencer-connect-request?socialMediaKitId=${socialMediaKitId}`,
    false
  ).then((res) => res.data);
};
