import { sendRequest } from "../helper";

import { SearchFilters } from "../../typings/Discovery";

import { ApplicantUpdated } from "../../typings";

type SearchResults = {
  status: string | null;
  message: string | null;
  data: {
    currentPage: number;
    result: ApplicantUpdated[];
  };
};

export const getSearchResults = (searchFilters: Partial<SearchFilters>) => {
  let tempFilter: any = { ...searchFilters };
  // if (tempFilter?.age?.low === undefined) {
  //   tempFilter = { ...tempFilter, age: { low: 18, high: 100 } };
  // }
  // if (tempFilter?.follower?.low === undefined) {
  //   tempFilter = { ...tempFilter, follower: { low: 1, high: 1000000000 } };
  // }
  // tempFilter = {
  //   ...tempFilter,
  //   locationId: tempFilter.location?.id,
  // };
  // delete tempFilter.location;
  const queryKey = ["search", tempFilter];
  const queryFunction = ({ pageParam = 1 }: { pageParam?: number }) =>
    sendRequest<SearchResults>(
      "post",
      `/brand/discovery/discover-influencer?limit=5&pageNo=${pageParam}`,
      false,
      { ...tempFilter }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
