import React, { useEffect, useState } from "react";
import {
  Drawer,
  // Row,
  // Collapse,
  // Col,
  // Input,
  // Button,
  // Tooltip,
  // Rate,
  // message,
} from "antd";
// import {
//   QuestionCircleOutlined,
//   HeartFilled,
//   CommentOutlined,
//   LinkOutlined,
// } from "@ant-design/icons";
// import {
//   useQuery,
//   useMutation,
//   useInfiniteQuery,
//   useQueryClient,
// } from "react-query";
// import { AxiosError } from "axios";
// import { EditOutlined } from "@ant-design/icons";

// import { useNavigate } from "react-router-dom";

// import nf from "@tuplo/numberfmt";

// import {
//   getInfluencerMedia,
//   getInfluencer,
//   getInfluencerCampaigns,
//   postNotes,
//   getInfluencerReviews,
// } from "../../../../handler/Profile";

// import { getUser } from "../../../../handler/getUser";

// import CampaignCard from "./CampaignCard";
// import ReviewCard from "./ReviewCard";
// import InsightCard from "./InsightCard";
// import CommercialCard from "./CommercialCard";
// import Demographic from "./Demographic";
// import ReviewModal from "../Finalized/Timeline/LinksTile/ReviewModals/Review";
import ProfileAnalysis from "../../../ProfileAnalysis";

// import styles from "./creatorProfile.module.css";
// import PopularPostCard from "./PopularPostCard";
// import CampaignModal from "../../../Discover/CampaignModal";

interface CreatorProfileProps {
  isVisible: boolean;
  handleClose: () => void;
  influencerId: string;
  platformContext?: "youtube" | "instagram";
}
// const { Panel } = Collapse;
// const { TextArea } = Input;

const CreatorProfile: React.FC<CreatorProfileProps> = ({
  isVisible,
  handleClose,
  influencerId,
  platformContext = "instagram",
}) => {
  // const queryClient = useQueryClient();
  // const [getInfluencerKey, getInfluencerFn] = getInfluencer(influencerId);
  // const { data: influencerData, isLoading: isInfluencerDataLoading } = useQuery(
  //   getInfluencerKey,
  //   getInfluencerFn,
  //   { enabled: isVisible }
  // );

  // const [getUserKey, getUserFn] = getUser();
  // const { data: userData, isLoading: isUserDataLoading } = useQuery(
  //   getUserKey,
  //   getUserFn,
  //   { enabled: isVisible }
  // );

  // const [notes, setNotes] = useState<string>(influencerData?.data.notes || "");
  // const [editNotes, setEditNotes] = useState<boolean>(false);
  // const [campaignModalProps, setCampaignModalProps] = useState<{
  //   visible: boolean;
  //   socialMediaKitId: string;
  //   platform: "youtube" | "instagram";
  // }>({ visible: false, socialMediaKitId: "", platform: "instagram" });

  // const navigate = useNavigate();

  // useEffect(() => {
  //   setNotes(influencerData?.data.notes || "");
  // }, [influencerData?.data.notes]);

  // const postNotesMutation = useMutation(postNotes, {
  //   onSuccess: () => {
  //     message.success("Updated User Notes");
  //     queryClient.invalidateQueries(getInfluencerKey);
  //     setEditNotes(false);
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error("Some error Occurred");
  //   },
  // });

  // const [getInfluencerCampaignsKey, getInfluencerCampaignsFn] =
  //   getInfluencerCampaigns(influencerId);

  // const {
  //   data: InfluencerCampaignsData,
  //   isLoading: isInfluencerCampaignDataLoading,
  //   hasNextPage,
  //   fetchNextPage,
  //   isFetchingNextPage,
  // } = useInfiniteQuery(getInfluencerCampaignsKey, getInfluencerCampaignsFn, {
  //   getNextPageParam: (lastPage) =>
  //     lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  //   enabled: isVisible,
  // });

  // const [getInfluencerReviewsKey, getInfluencerReviewsFn] =
  //   getInfluencerReviews(influencerId);

  // const {
  //   data: InfluencerReviewsData,
  //   isLoading: isInfluencerReviewsDataLoading,
  //   hasNextPage: hasReviewNextPage,
  //   fetchNextPage: fetchReviewNextPage,
  //   isFetchingNextPage: isFetchingReviewNextPage,
  // } = useInfiniteQuery(getInfluencerReviewsKey, getInfluencerReviewsFn, {
  //   getNextPageParam: (lastPage) =>
  //     lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  //   enabled: isVisible,
  // });

  // const [isReviewVisible, setReviewVisible] = useState<boolean>(false);

  // const [getInfluencerMediaKey, getInfluencerMediaFn] =
  //   getInfluencerMedia(influencerId);

  // const { data: influencerMedia, isLoading: isInfluencerMediaLoading } =
  //   useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {
  //     enabled: isVisible,
  //   });

  // const getReviewStarCount = (val: number) => {
  //   return Math.floor(val) + (val - Math.floor(val) >= 0.5 ? 0.5 : 0);
  // };

  // if (
  //   isInfluencerCampaignDataLoading ||
  //   isInfluencerDataLoading ||
  //   isInfluencerReviewsDataLoading ||
  //   isInfluencerMediaLoading ||
  //   isUserDataLoading
  // ) {
  //   return null;
  // }

  // const influencerMediaIdInsta =
  //   influencerMedia?.data?.find(
  //     (item) => item.socialMediaPlatform === "instagram"
  //   )?.socialMediaId || "";

  return (
    <Drawer
      headerStyle={{ background: "#F0F2F5" }}
      title="Creator Profile"
      placement="right"
      onClose={handleClose}
      visible={isVisible}
      width={1200}>
      <ProfileAnalysis
        inDrawer
        initialId={influencerId}
        isQueryCalled={isVisible}
      />
    </Drawer>
  );
};

export default CreatorProfile;
