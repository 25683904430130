import axios from "axios";

export interface getInfluencersAlgoliaResponse {
  hits?: any[];
}

interface getInfluencersAlgoliaProps {
  query: string;
  platform: string;
  brandId: string;
  isManagedAccount: boolean;
}

const getAlgoliaIndexName = (platform: string) => {
  switch (platform) {
    case "instagram":
      return "platform-discovery-ig-v2-stage";
    case "youtube":
      return "platform-discovery-yt-v2-stage";
    default:
      return "platform-discovery-ig-v2-stage";
  }
};

export const getInfluencersAlgolia = ({
  query,
  platform,
  brandId,
  isManagedAccount,
}: getInfluencersAlgoliaProps) => {
  const queryKey = ["getInfluencersAlgolias", query, platform, brandId];

  const facetParams = `?hitsPerPage=5&getRankingInfo=1&query=${query}${
    isManagedAccount ? "" : `&facetFilters=[["community:${brandId}"]]`
  }`;

  const queryFunction = () =>
    axios.get<getInfluencersAlgoliaResponse>(
      `https://${
        process.env.REACT_APP_ALGOLIA_APP_ID
      }-dsn.algolia.net/1/indexes/${getAlgoliaIndexName(
        platform
      )}${facetParams}`,
      {
        headers: {
          "X-Algolia-API-Key": `${process.env.REACT_APP_ALGOLIA_SEARCH_KEY}`,
          "X-Algolia-Application-Id": `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
        },
      }
    );

  return [queryKey, queryFunction] as const;
};
