import { sendRequest } from "../helper";
import { InvitedInfluencerCommunity } from "../../typings";

export type getInvitedInfluencersResult = {
  status: string;
  message: string;
  data: {
    invited: InvitedInfluencerCommunity[];
    pageNo: number;
  };
};
type CommunityProps = {
  limit: number;
  search: string;
  inviteBy?: string;
  platform: string[];
  inviteStatus: string[];
  accountStatus: string[];
};

export const getInvitedInfluencers = (props: CommunityProps) => {
  const queryKey = ["community", "invited", props];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getInvitedInfluencersResult>(
      "put",
      `/brand/community/invite-request`,
      false,
      {
        limit: props.limit,
        search: props.search,
        pageNo: pageParam || 1,
        inviteBy: props.inviteBy || "",
        platform: props.platform,
        inviteStatus: props.inviteStatus,
        accountStatus: props.accountStatus,
      }
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
