import React, { useState, useEffect } from "react";

import WelcomeScreen from "./Welcome";
// import DetailsScreen from "./Details";

import DetailsScreen from "./Details/Updated";
import Success from "./Success";
import DemoCall from "./DemoCall";

interface ModalsProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTime: boolean;
}

const Modals: React.FC<ModalsProps> = ({
  isVisible,
  setVisible,
  isFirstTime,
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isSuccesModalOpen, setSuccesModalOpen] = useState<boolean>(false);
  const [isDemoModalOpen, setDemoModalOpen] = useState<boolean>(false);
  const [isDetailsModalVisible, setDetailsModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (isVisible) {
      setModalVisible(isFirstTime);
      setDetailsModalVisible(!isFirstTime);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <WelcomeScreen
        isVisible={isVisible && isModalVisible}
        handleActivate={() => {
          setModalVisible(false);
          setDetailsModalVisible(true);
        }}
        handleExplore={() => {
          setModalVisible(false);
          setVisible(false);
        }}
        handleDemoClick={() => {
          setModalVisible(false);
          setDemoModalOpen(true);
        }}
      />
      <DetailsScreen
        isVisible={isVisible && isDetailsModalVisible}
        handleSubmit={() => {
          setDetailsModalVisible(false);
          setSuccesModalOpen(true);
        }}
        handleCancel={() => {
          setDetailsModalVisible(false);
          setVisible(false);
        }}
      />
      <Success
        isVisible={isVisible && isSuccesModalOpen}
        handleSubmit={() => {
          setSuccesModalOpen(false);
          setDemoModalOpen(true);
        }}
        handleCancel={() => {
          setSuccesModalOpen(false);
          setVisible(false);
        }}
      />
      <DemoCall
        isVisible={isVisible && isDemoModalOpen}
        handleClose={() => {
          setDemoModalOpen(false);
        }}
      />
    </div>
  );
};

export default Modals;
