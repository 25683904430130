import { Modal, Button, Row, Col, Form, message } from "antd";
import React, { useEffect } from "react";
import CustomInput from "../../../../shared/Custom/Input";
import { useForm } from "antd/lib/form/Form";
import { useMutation, useQueryClient } from "react-query";

import { updateBillingSettings } from "../../../../../handler/Billing/updateBillingSettings";

import { AxiosError } from "axios";

import styles from "./details.module.css";

import { gstinValidate } from "../../../../../utils";
import { pincodeValidate } from "../../../../../utils/pincodeValidate";

import { User } from "../../../../../typings";

interface DetailsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData:
    | {
        status: string;
        message: string;
        data: User;
      }
    | undefined;
}

export interface FormData {
  personFirstName: string;
  personLastName: string;
  companyName: string;
  street: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  gst: string;
}

const Details: React.FC<DetailsProps> = (props) => {
  const { open, userData, setOpen } = props;
  const [form] = useForm();

  const queryClient = useQueryClient();

  const billingMutation = useMutation(updateBillingSettings, {
    onSuccess: (response) => {
      form.resetFields();
      queryClient.invalidateQueries(["user"]);

      if (response.status === "SUCCESS") {
        setOpen(false);
        form.resetFields();
        message.success("Details updated successfully");
      }
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  useEffect(() => {
    form.resetFields();
  }, [open, form]);

  const onFinish = async (values: FormData) => {
    form.setFields([
      {
        name: "gst",
        errors: gstinValidate(values.gst) ? [] : ["GSTIN is not valid"],
      },
      {
        name: "pincode",
        errors: pincodeValidate(values.pincode) ? [] : ["Pincode is not valid"],
      },
      {
        name: "street",
        errors: values.street.length <= 100 ? [] : ["Limit is 100 Characters"],
      },
    ]);

    if (
      gstinValidate(values.gst) &&
      pincodeValidate(values.pincode) &&
      values.personFirstName &&
      values.personLastName &&
      values.companyName &&
      values.city &&
      values.country &&
      values.state &&
      values.street &&
      values.street.length <= 100 &&
      values.pincode &&
      values.gst
    ) {
      try {
        billingMutation.mutate({ data: values });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      okText={"Save Details"}
      visible={open}
      title={<span className={styles.modalTitle}>Billing Details:</span>}
      centered
      onCancel={handleCancel}
      footer={null}
      width={768}
      closeIcon={
        <Button type="link" style={{ right: "185%" }}>
          <span className={styles.modalClose}>Discard Changes</span>
        </Button>
      }
    >
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{ width: "100%" }}
        initialValues={{
          personFirstName:
            userData?.data.businessDetails?.billing?.personFirstName || "",
          personLastName:
            userData?.data.businessDetails?.billing?.personLastName || "",
          companyName:
            userData?.data.businessDetails?.billing?.companyName || "",
          city: userData?.data.businessDetails.address.city || "",
          country: userData?.data.businessDetails.address.country || "",
          state: userData?.data.businessDetails.address.state || "",
          street: userData?.data.businessDetails.address.street || "",
          pincode: userData?.data.businessDetails.address.pincode || "",
          gst: userData?.data.businessDetails.gst || "",
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
          <Col span={12}>
            <Form.Item
              name="personFirstName"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <CustomInput label="First Name*" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="personLastName"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <CustomInput label="Last Name*" />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ margin: "10px 0" }}></div>
        <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
          <Col span={10}>
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please enter your company name",
                },
              ]}
            >
              <CustomInput label="Company Name*" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="gst"
              rules={[{ required: true, message: "Please enter your GSTIN" }]}
            >
              <CustomInput label="GSTIN*" />
            </Form.Item>
          </Col>
        </Row>
        {/* === Billing Address === */}
        <div style={{ margin: "10px 0" }}></div>
        <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
          <Col span={24}>
            <p className={styles.addressLabel}>Billing Address*</p>
            <div className={styles.addressInput}>
              <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                <Col span={12}>
                  <Form.Item
                    name="street"
                    rules={[
                      {
                        required: true,
                        message: "Please enter steet name",
                      },
                    ]}
                  >
                    <CustomInput placeholder="Street Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the country name",
                      },
                    ]}
                  >
                    <CustomInput placeholder="Country" />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ margin: "5px 0" }}></div>
              <Row gutter={{ xs: 8, sm: 16, md: 20 }}>
                <Col span={8}>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the state name",
                      },
                    ]}
                  >
                    <CustomInput placeholder="State" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the city name",
                      },
                    ]}
                  >
                    <CustomInput placeholder="City" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the pincode",
                      },
                    ]}
                  >
                    <CustomInput placeholder="Pincode" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div style={{ margin: "10px 0 0" }}></div>
        <Form.Item>
          <div className={styles.modalFooter}>
            <Button
              htmlType="button"
              onClick={handleCancel}
              style={{ borderRadius: 10, height: 42 }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: 10, height: 42 }}
              loading={billingMutation.isLoading}
              disabled={billingMutation.isLoading}
            >
              Save Details
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default Details;
