import { sendRequest } from "../helper";

export type Campaign = {
  name: string;
  createdDateTime: string;
  createdBy: string;
  requirement?: string;
  notes?: string;
  internalNotes?: string;
  serviceCostPercentage?: number;
  isProductDispatch?: boolean;
  isInternalApproval?: boolean;
  lastUpdatedDateTime?: string;
  brandId?: string;
};

export type getCampaignByIdResult = {
  status: string;
  data: Campaign;
};

export const getCampaignById = (campaignId: any) => {
  const queryKey = ["campaign", campaignId];
  const queryFunction = () =>
    sendRequest<getCampaignByIdResult>(
      "get",
      `/campaign/${campaignId}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
