import { sendRequest } from "../helper";

export type Campaign = {
  name: string;
  createdDateTime: string;
  createdBy: string;
};

export type getAllCampaignsResult = {
  status: string;
  results: number;
  total: number;
  data: Campaign[];
};

type CampaignProps = {
  page?: number;
  limit?: number;
  isArchived?: boolean;
};

export const getAllCampaigns = (props: CampaignProps) => {
  const queryKey = ["campaigns", props];
  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getAllCampaignsResult>(
      "get",
      `/campaign?page=${pageParam || props.page || 1}&limit=${
        props.limit || 10
      }&isArchived=${props.isArchived}`,
      false,
      {}
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
