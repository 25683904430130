import React, { useState } from "react";
import {
  Modal,
  InputNumber,
  Button,
  Checkbox,
  Radio,
  Col,
  Row,
  Input,
  Tooltip,
  Popover,
} from "antd";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";

// import ProductModal from "../../Create/AddProduct/Modal";

// import YoutubeModal from "../../Create/Deliverables/Modal/Youtube";

// import PostModal from "../../Create/Deliverables/Modal/Instagram/Post";
// import ReelModal from "../../Create/Deliverables/Modal/Instagram/Reel";
// import StoryModal from "../../Create/Deliverables/Modal/Instagram/Story";
// import VideoModal from "../../Create/Deliverables/Modal/Instagram/Video";

import { Product, Applicant } from "../../../../typings";
// import {
//   InstaModal,
//   InstaContent,
//   YoutubeDel,
// } from "../../Create/Deliverables/Deliverables";

interface OfferModalProps {
  isVisible: boolean;
  handleSubmit: (price: number) => void;
  handleCancel: () => void;
  applicant: { _id: string; price: number };
}
const OfferModal: React.FC<OfferModalProps> = ({
  isVisible,
  handleSubmit,
  handleCancel,
  applicant,
}) => {
  const [price, setPrice] = useState<number>(applicant.price);

  const handleModalSubmit = () => {
    handleSubmit(price);
    setPrice(applicant.price);
  };

  const handleModalCancel = () => {
    handleCancel();
    setPrice(applicant.price);
  };

  return (
    <Modal
      title="Negotiate"
      visible={isVisible}
      onCancel={handleModalCancel}
      destroyOnClose={true}
      centered
      footer={
        <Button size="middle" type="primary" onClick={handleModalSubmit}>
          DONE
        </Button>
      }>
      <section>
        <p>
          What’s your offer?
          <Tooltip placement="topLeft" title={"Help"} arrowPointAtCenter>
            <InfoCircleOutlined size={14} style={{ marginLeft: "4px" }} />
          </Tooltip>
        </p>
        <Col span={7} style={{ marginTop: "8px" }}>
          <InputNumber
            min={1}
            size="large"
            addonBefore="₹"
            type={"number"}
            value={price}
            onChange={(val) => setPrice(val as number)}
          />
        </Col>
      </section>
    </Modal>
  );
};

export default OfferModal;

// const [hasProduct, setHasProduct] = useState<boolean>(applicant.isProduct);

//   const [productList, setProductList] = useState<Product[]>(
//     applicant.productDetails
//   );

//   const [popVisible, SetPopVisible] = useState<boolean>(false);
//   const [selectedproductList, setSelectedProductList] = useState<Product[]>();

//   const [isAddModalVisible, setIsAddModalVisible] = useState(false);

//   const [_, setProduct] = useState<Product | null>(null);

//   const { TextArea } = Input;

//   const [youtubeModalVisible, setYoutubeModalVisible] = useState(false);
//   const [isEditYoutubeModalVisible, setEditYoutubeModalVisible] =
//     useState(false);
//   const [ytDeliverableList, setYtDeliverableList] = useState<YoutubeDel[]>(
//     applicant.deliverables.filter(
//       (deliverable) => deliverable!.platform === "youtube"
//     ) as unknown as YoutubeDel[]
//   );
//   const [ytDeliverable, setYtDeliverable] = useState<YoutubeDel | null>(null);

//   const [instaModalVisible, setInstaModalVisible] = useState<InstaModal>({
//     Reel: false,
//     Story: false,
//     Post: false,
//     Video: false,
//   });
//   const [isEditInstaModalVisible, setEditInstaModalVisible] =
//     useState<InstaModal>({
//       Reel: false,
//       Story: false,
//       Post: false,
//       Video: false,
//     });
//   const [instaDeliverableList, setInstaDeliverableList] = useState<
//     InstaContent[]
//   >(
//     applicant.deliverables.filter(
//       (deliverable) => deliverable!.platform === "instagram"
//     ) as unknown as InstaContent[]
//   );
//   const [instaDeliverable, setInstaDeliverable] = useState<InstaContent | null>(
//     null
//   );

//   const handleProductChange = (productIds: string[]) => {
//     const updatedList = productIds.map((id) => {
//       return productList.find((product) => product._id === id);
//     });
//     setSelectedProductList(updatedList as Product[]);
//   };

{
  /* <p>Does the creator need a product?</p>
        <Radio.Group
          style={{ marginTop: "8px", width: "100%" }}
          size="middle"
          defaultValue={Boolean(hasProduct)}
          onChange={(event) => setHasProduct(event.target.value)}>
          <Radio.Button
            value={true}
            style={{
              padding: "5px 40px",
              width: "25%",
              textAlign: "center",
              height: "100%",
            }}>
            YES
          </Radio.Button>
          <Radio.Button
            value={false}
            style={{
              padding: "5px 40px",
              width: "25%",
              textAlign: "center",
              height: "100%",
            }}>
            No
          </Radio.Button>
        </Radio.Group>

        <section style={{ marginTop: "26px" }}>
          {hasProduct ? (
            <section>
              <p
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                Select Products
                <PlusOutlined
                  size={14}
                  style={{ marginLeft: "4px" }}
                  onClick={() => setIsAddModalVisible(true)}
                />
                <ProductModal
                  type="Add"
                  visible={isAddModalVisible}
                  setIsVisible={setIsAddModalVisible}
                  setProductList={setProductList}
                  setProduct={setProduct}
                  campaignId={"62d68880912ee7073a822d10"}
                  modalFor="Deal"
                />
              </p>
              <Checkbox.Group
                style={{ marginTop: "12px" }}
                onChange={(value) => handleProductChange(value as string[])}>
                <Row gutter={[0, 12]}>
                  {productList.map((product) => {
                    return (
                      <Col span={24}>
                        <Checkbox value={product._id}>
                          {product.productTitle}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </section>
          ) : (
            <section>
              <p>
                Describe what you are promoting
                <Tooltip placement="topLeft" title={"Help"} arrowPointAtCenter>
                  <InfoCircleOutlined size={14} style={{ marginLeft: "4px" }} />
                </Tooltip>
              </p>
              <TextArea
                showCount
                size="middle"
                rows={6}
                maxLength={1000}
                style={{ marginTop: "8px" }}
                defaultValue={applicant.promotionDescription}
              />
            </section>
          )}
        </section>
        <section id="deliverables" style={{ marginTop: "26px" }}>
          <p
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}>
            Select Deliverables
            {applicant.platform === "instagram" ? (
              <Popover
                trigger="click"
                arrowPointAtCenter
                placement="top"
                visible={popVisible}
                content={
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      marginTop: "12px",
                    }}>
                    <Button
                      type="text"
                      style={{ height: "min-content" }}
                      onClick={() => {
                        SetPopVisible(false);
                        setInstaModalVisible((prev) => {
                          return { ...prev, Reel: true };
                        });
                      }}>
                      <img
                        src="/assets/icons/insta-reel.svg"
                        alt="reel icon"
                        width="18px"
                      />
                      <div>Reel</div>
                    </Button>
                    <Button
                      type="text"
                      style={{ height: "min-content" }}
                      onClick={() => {
                        SetPopVisible(false);
                        setInstaModalVisible((prev) => {
                          return { ...prev, Story: true };
                        });
                      }}>
                      <img
                        src="/assets/icons/insta-story.svg"
                        alt="story icon"
                        width="18px"
                      />
                      <div>Story</div>
                    </Button>
                    <Button
                      type="text"
                      style={{ height: "min-content" }}
                      onClick={() => {
                        SetPopVisible(false);
                        setInstaModalVisible((prev) => {
                          return { ...prev, Video: true };
                        });
                      }}>
                      <img
                        src="/assets/icons/insta-video.svg"
                        alt="video icon"
                        width="18px"
                      />
                      <div>Video</div>
                    </Button>
                    <Button
                      type="text"
                      style={{ height: "min-content" }}
                      onClick={() => {
                        SetPopVisible(false);
                        setInstaModalVisible((prev) => {
                          return { ...prev, Post: true };
                        });
                      }}>
                      <img
                        src="/assets/icons/insta-post.svg"
                        alt="post icon"
                        width="18px"
                      />
                      <div>Post</div>
                    </Button>
                  </div>
                }>
                <PlusOutlined size={18} onClick={() => SetPopVisible(true)} />

                <PostModal
                  type="Add"
                  visible={instaModalVisible.Post}
                  setIsVisible={setInstaModalVisible}
                  setDeliverableList={setInstaDeliverableList}
                  setDeliverable={setInstaDeliverable}
                  productList={selectedproductList || []}
                  campaignId={applicant.campaignId}
                  hasProduct={hasProduct}
                  modalFor="Deal"
                />

                {instaDeliverable && instaDeliverable.typeDel === "Post" && (
                  <PostModal
                    type="Edit"
                    visible={isEditInstaModalVisible.Post}
                    setIsVisible={setEditInstaModalVisible}
                    setDeliverableList={setInstaDeliverableList}
                    setDeliverable={setInstaDeliverable}
                    productList={selectedproductList || []}
                    initialValues={instaDeliverable}
                    campaignId={applicant.campaignId}
                    hasProduct={hasProduct}
                    modalFor="Deal"
                  />
                )}

                <ReelModal
                  type="Add"
                  visible={instaModalVisible.Reel}
                  setIsVisible={setInstaModalVisible}
                  setDeliverableList={setInstaDeliverableList}
                  setDeliverable={setInstaDeliverable}
                  productList={selectedproductList || []}
                  campaignId={applicant.campaignId}
                  hasProduct={hasProduct}
                  modalFor="Campaign"
                />

                {instaDeliverable && instaDeliverable.typeDel === "Reel" && (
                  <ReelModal
                    type="Edit"
                    visible={isEditInstaModalVisible.Reel}
                    setIsVisible={setEditInstaModalVisible}
                    setDeliverableList={setInstaDeliverableList}
                    setDeliverable={setInstaDeliverable}
                    productList={selectedproductList || []}
                    initialValues={instaDeliverable}
                    campaignId={applicant.campaignId}
                    hasProduct={hasProduct}
                    modalFor="Deal"
                  />
                )}

                <StoryModal
                  type="Add"
                  visible={instaModalVisible.Story}
                  setIsVisible={setInstaModalVisible}
                  setDeliverableList={setInstaDeliverableList}
                  setDeliverable={setInstaDeliverable}
                  productList={selectedproductList || []}
                  campaignId={applicant.campaignId}
                  hasProduct={hasProduct}
                  modalFor="Deal"
                />

                {instaDeliverable && instaDeliverable.typeDel === "Story" && (
                  <StoryModal
                    type="Edit"
                    visible={isEditInstaModalVisible.Story}
                    setIsVisible={setEditInstaModalVisible}
                    setDeliverableList={setInstaDeliverableList}
                    setDeliverable={setInstaDeliverable}
                    productList={selectedproductList || []}
                    initialValues={instaDeliverable}
                    campaignId={applicant.campaignId}
                    hasProduct={hasProduct}
                    modalFor="Deal"
                  />
                )}

                <VideoModal
                  type="Add"
                  visible={instaModalVisible.Video}
                  setIsVisible={setInstaModalVisible}
                  setDeliverableList={setInstaDeliverableList}
                  setDeliverable={setInstaDeliverable}
                  productList={selectedproductList || []}
                  campaignId={applicant.campaignId}
                  hasProduct={hasProduct}
                  modalFor="Deal"
                />

                {instaDeliverable && instaDeliverable.typeDel === "Video" && (
                  <VideoModal
                    type="Edit"
                    visible={isEditInstaModalVisible.Video}
                    setIsVisible={setEditInstaModalVisible}
                    setDeliverableList={setInstaDeliverableList}
                    setDeliverable={setInstaDeliverable}
                    productList={selectedproductList || []}
                    initialValues={instaDeliverable}
                    campaignId={applicant.campaignId}
                    hasProduct={hasProduct}
                    modalFor="Deal"
                  />
                )}
              </Popover>
            ) : (
              <>
                <PlusOutlined
                  size={18}
                  onClick={() => setYoutubeModalVisible(true)}
                  style={{ cursor: "pointer" }}
                />
                <YoutubeModal
                  type="Add"
                  visible={youtubeModalVisible}
                  setIsVisible={setYoutubeModalVisible}
                  setDeliverableList={setYtDeliverableList}
                  setDeliverable={setYtDeliverable}
                  productList={selectedproductList || []}
                  campaignId={applicant.campaignId}
                  hasProduct={hasProduct}
                  modalFor="Deal"
                />

                {ytDeliverable && (
                  <YoutubeModal
                    type="Edit"
                    visible={isEditYoutubeModalVisible}
                    setIsVisible={setEditYoutubeModalVisible}
                    setDeliverableList={setYtDeliverableList}
                    setDeliverable={setYtDeliverable}
                    productList={selectedproductList || []}
                    initialValues={ytDeliverable}
                    campaignId={applicant.campaignId}
                    hasProduct={hasProduct}
                    modalFor="Deal"
                  />
                )}
              </>
            )}
          </p>
          <Checkbox.Group style={{ marginTop: "12px", width: "100%" }}>
            <Row gutter={[0, 12]}>
              {applicant.platform === "instagram" &&
                instaDeliverableList.map((item) => {
                  return (
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Checkbox value={item?._id}>1 {item?.typeDel}</Checkbox>
                      <Button
                        type="link"
                        onClick={() => {
                          setInstaDeliverable(item);
                          setEditInstaModalVisible((prev) => {
                            return { ...prev, [item!.typeDel]: true };
                          });
                        }}>
                        {" "}
                        Edit
                      </Button>
                    </Col>
                  );
                })}
              {applicant.platform === "youtube" &&
                ytDeliverableList.map((item) => {
                  return (
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Checkbox value={item?._id}>1 {item.type} Video</Checkbox>
                      <Button
                        type="link"
                        onClick={() => {
                          setYtDeliverable(item);
                          setEditYoutubeModalVisible(true);
                        }}>
                        {" "}
                        Edit
                      </Button>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        </section> */
}
