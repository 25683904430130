import { sendRequest } from "./helper";

interface verifyOTPProps {
  OTP: string;
}

export const verifyOTP = ({ OTP }: verifyOTPProps) => {
  return sendRequest("put", `/brand/otp/validate-otp/${OTP}`, false).then(
    (res) => res.data
  );
};
