import React from "react";
import { Space } from "antd";

import styles from "./video.module.css";
import { formatData } from "../../../../utils";

import MetricCard from "../../Shared/MetricCard";

import { PinnedMetrics } from "..";

type VideoInsightsProps = {
  data: { key: string; value: number; name: string; description?: string }[];
  pinnedMetrics: PinnedMetrics[];
  content: PinnedMetrics["content"];
  handlePin: (title: PinnedMetrics["title"]) => void;
  count: number;
};

const VideoInsights: React.FC<VideoInsightsProps> = (props) => {
  const { data, handlePin, pinnedMetrics, content, count } = props;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Video Insights</p>
      <p className={styles.desc}>
        The data is the average for the past {count}{" "}
        {content === "video" ? "videos" : content}
      </p>
      <Space size={20} wrap style={{ height: "45vh", overflowY: "scroll" }}>
        {data.map((item, key) => {
          const isPinned = pinnedMetrics.findIndex(
            (el) => el.title === item.name && el.content === content
          );

          return (
            <MetricCard
              key={key}
              title={item.name}
              desc={item.description || "-"}
              metric={formatData((item.value as number) || 0, "a")}
              isPinned={isPinned !== -1}
              handlePin={handlePin}
            />
          );
        })}
      </Space>
    </div>
  );
};
export default VideoInsights;
