import React from "react";
import { useCampaign } from "../Context/campaignContext";

import InstagramDeliverables from "./Instagram";
import YoutubeDeliverables from "./Youtube";

import styles from "../styles.module.css";

const Deliverables = () => {
  const { campaign } = useCampaign();
  return (
    <div>
      {" "}
      <h2 className={styles.title} style={{ marginBottom: "32px" }}>
        Add Deliverables
      </h2>
      {campaign.campaign.platform === "instagram" ? (
        <InstagramDeliverables />
      ) : (
        <YoutubeDeliverables />
      )}
    </div>
  );
};

export default Deliverables;
