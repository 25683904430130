import React, { useState } from "react";

import { Form, Radio, Typography, Row, Col, Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
// import { InboxOutlined, CloseCircleOutlined } from "@ant-design/icons";

import DocsInput from "./DocsInput";
import SocialLinkInput from "./SocialLinkInput";

// import { useUserAuth } from "../../../context/userAuthcontext";

import { verifyORGDetails } from "../../../handler/verifyORGDetails";

import ProgressBar from "../../shared/ProgressBar";
import styles from "../Onboarding.module.css";

const VerifyOrg: React.FC = () => {
  const [radioVal, setRadioVal] = useState("");
  const [CIN, setCIN] = useState("");
  const [docsLink, setDocsLink] = useState<string[]>([]);
  const [socialLink, SetSocialLink] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [socialMediaUsed, setSocialMediaUsed] = useState<string>("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  // useEffect(() => {
  // }, [form.getFieldValue("radio")]);

  const onFinish = (values: any) => {
    if (!radioVal) {
      return;
    } else if (radioVal === "social-media") {
      if (!socialMediaUsed || !socialLink) {
        return;
      } else {
        setLoading(true);
        return verifyORGDetails({
          verificationType: radioVal,
          socialMediaProfileUrl: socialLink,
          socialMediaUsed,
        })
          .then(() => navigate("/", { replace: true }))
          .catch((err) => console.log("backend_err", err))
          .finally(() => setLoading(false));
      }
    } else {
      if (!CIN && !docsLink.length) {
        return;
      } else {
        setLoading(true);
        return verifyORGDetails({
          verificationType: radioVal,
          cin: CIN,
          officalDoc: docsLink,
        })
          .then(() => navigate("/", { replace: true }))
          .catch((err) => console.log("backend_err", err))
          .finally(() => setLoading(false));
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFormChange = (event: any) => {
    setRadioVal(event.target.value);
    if (!radioVal) {
      return setDisabled(true);
    } else if (radioVal === "social-media") {
      if (!socialMediaUsed || !socialLink) {
        return setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (!CIN && !docsLink.length) {
        return setDisabled(true);
      } else {
        return setDisabled(false);
      }
    }
  };

  const handleChange = () => {
    if (!radioVal) {
      return setDisabled(true);
    } else if (radioVal === "social-media") {
      if (!socialMediaUsed || !socialLink) {
        return setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (!CIN && !docsLink.length) {
        return setDisabled(true);
      } else {
        return setDisabled(false);
      }
    }
  };

  const { Title } = Typography;

  const formItemLayout = {
    labelCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 } },
  };

  return (
    <>
      <ProgressBar size={100} />
      <div className={styles.container}>
        <Row justify="center">
          <Col sm={10} xs={22} md={8}>
            <Title>Verify your Organisation Details</Title>
            <Form
              {...formItemLayout}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
              onChange={handleChange}
              // onChange={(event) => onFormChange(event)}>
            >
              <Form.Item
                name="radioInput"
                label={
                  <p className={styles.radioHeading}>
                    Choose an option to verify organization
                  </p>
                }>
                <Radio.Group onChange={(event) => onFormChange(event)}>
                  <Space direction="vertical">
                    <Radio value="social-media">
                      <>
                        <Title level={4} className={styles.radioTitle}>
                          Active social media page
                        </Title>
                        <Title level={5} className={styles.radioDesc}>
                          Connect your most active social media profile of which
                          you are an admin with minimum 900-1100 likes/followers
                        </Title>
                        {radioVal === "social-media" && (
                          <SocialLinkInput
                            socialLink={socialLink}
                            SetSocialLink={SetSocialLink}
                            link={socialMediaUsed}
                            setLink={setSocialMediaUsed}
                          />
                        )}
                      </>
                    </Radio>
                    <Radio value="offical-doc">
                      <>
                        <Title level={4} className={styles.radioTitle}>
                          Official company documents
                        </Title>
                        <Title level={5} className={styles.radioDesc}>
                          Verify using Corporate Identification Number (CIN) or
                          any govt. issued business registration document
                        </Title>
                        {radioVal === "offical-doc" && (
                          <DocsInput
                            CIN={CIN}
                            docsLink={docsLink}
                            setCIN={setCIN}
                            setDocsLink={setDocsLink}
                          />
                        )}
                      </>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  title="Continue to Verify Organisation"
                  htmlType="submit"
                  className={styles.submitButton}
                  block
                  disabled={docsLink.length > 0 ? false : disabled}
                  loading={loading}>
                  <span className={styles.submitButtonText}>
                    Continue to Verify Organisation
                  </span>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyOrg;
