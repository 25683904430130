import React, { useState } from "react";
import { Menu } from "antd";
import { useSearchParams } from "react-router-dom";

import InviteModal from "../InviteModal";

import ReportButton from "../../Campaign/Report/ReportButton";

import styles from "./styles.module.css";

interface SelectorProps {
  selectedState: string;
}

const Selector: React.FC<SelectorProps> = ({ selectedState }) => {
  const [isInviteVisible, setIsInviteVisible] = useState<boolean>(false);

  const [_, setSearchParams] = useSearchParams();
  return (
    <div className={styles.menuContainer}>
      <Menu
        defaultSelectedKeys={[selectedState]}
        selectedKeys={[selectedState]}
        mode="horizontal"
        style={{ position: "relative" }}
        onSelect={(e) => {
          setSearchParams({ type: e.key });
        }}>
        <Menu.Item key={"community"}>My Community</Menu.Item>
        <Menu.Item key={"network"}>Network Page</Menu.Item>
        <Menu.Item key={"influencers"}>Invited Influencers</Menu.Item>
        {/* <Menu.Item key={"invite"}>Invite New</Menu.Item> */}
      </Menu>
      <ReportButton onClick={() => setIsInviteVisible(true)} type="primary">
        Create Invite Link
      </ReportButton>
      <InviteModal
        isVisible={isInviteVisible}
        handleCancel={() => setIsInviteVisible(false)}
      />
    </div>
  );
};

export default Selector;
