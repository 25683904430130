import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  DatePicker,
  message,
  Space,
  Alert,
  Spin,
} from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";

import CreateSteps from "../Steps";
import Footer from "../Footer";
import Navbar from "../../../shared/Navbar";
import { capitalizeFirstLetter } from "../../../../utils";

import { postCampaign, getCampaign } from "../../../../handler/campaign";
import { CampaignResponse } from "../../../../typings";

import styles from "../Create.module.css";
import { AxiosError } from "axios";
import { editCampaign } from "../../../../handler/campaign/editCampaign";

interface BasicDetailProps {
  platform: "instagram" | "youtube";
  id?: string;
}

const { RangePicker } = DatePicker;

const BasicDetails: React.FC<BasicDetailProps> = ({ platform, id }) => {
  const queryClient = useQueryClient();

  const [campaignId, setCampaignId] = useState<string>(id || "");

  const [isEditEnabled, setEditEnabled] = useState<boolean>(false);
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);

  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId,
  });

  const { data: campaignData, isLoading } = useQuery(
    getCampaignKey,
    getCampaignFunction,
    {
      enabled: campaignId !== "",
    }
  );

  useEffect(() => {
    if (campaignData) {
      if (
        campaignData.data.campaign.statusHistory.findIndex(
          (item) => item.status === "Created-Campaign"
        ) !== -1
      ) {
        setEditEnabled(true);
      }
    }
  }, [campaignData]);

  const postCampaignMutation = useMutation(postCampaign, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (_) => {
          return response;
        }
      );
      setCampaignId(response.data.campaign._id);
      navigate(`/campaign/create/${response.data.campaign._id}`);
      message.success("Campaign Created");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const editCampaignMutation = useMutation(editCampaign, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          return response;
        }
      );
      setEditEnabled(true);
      message.success("Details Updated");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const { id: paramsId } = useParams();

  const navigate = useNavigate();
  const onFinish = (values: any) => {
    if (paramsId) {
      editCampaignMutation.mutate({
        campaignId: id as string,
        campaignName: values.cName,
        endDate: new Date(values.cDate[1]).toDateString(),
        startDate: new Date(values.cDate[0]).toDateString(),
      });
    } else {
      postCampaignMutation.mutate({
        campaignName: values.cName,
        platform,
        endDate: new Date(values.cDate[1]).toDateString(),
        startDate: new Date(values.cDate[0]).toDateString(),
      });
    }
  };
  useEffect(() => {
    if (isEditEnabled) {
      return setSaveEnabled(false);
    }
    return setSaveEnabled(true);
  }, [isEditEnabled]);

  if (campaignId && isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <div>
        <Navbar />
        <Row justify="center" style={{ height: "70px", margin: "50px 0" }}>
          <Col span={24}>
            <div
              style={{
                fontWeight: 500,
                fontSize: "30px",
                lineHeight: "40px",
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.85)",
              }}>
              Create an {capitalizeFirstLetter(platform)} Campaign
              <img
                src={`/assets/icons/${platform}Icon.png`}
                alt="instagram"
                style={{ width: "30px", marginLeft: "8px" }}
              />
            </div>

            <p
              style={{
                marginTop: "8px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.45)",
              }}>
              Need assistance? Please watch this{" "}
              <span
                style={{
                  color: "#2130FF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}>
                video
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={2}>
            <CreateSteps
              currentStep={0}
              statusHistory={campaignData?.data.campaign.statusHistory || []}
            />
          </Col>
          <Col span={8} offset={2}>
            <p
              style={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.85)",
                marginBottom: "10px",
              }}>
              Campaign Details
            </p>
            <div
              style={{
                width: "100%",
                padding: "16px 20px",
                background: "#FAFAFA",
                minHeight: "30vh",
              }}>
              <Form
                layout="vertical"
                autoComplete="on"
                requiredMark={false}
                onFinish={onFinish}>
                <Form.Item
                  label="Campaign Name"
                  name="cName"
                  className={styles.label}
                  rules={[
                    { required: true, message: "Please input Campaign Name" },
                  ]}
                  initialValue={campaignData?.data.campaign.campaignName}

                  // {...formItemLayout}
                >
                  <Input
                    placeholder="e.g. June | Kesh Kanti"
                    size="middle"
                    disabled={!isSaveEnabled}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "22px",
                          color: "rgba(0, 0, 0, 0.85)",
                        }}>
                        Timeline
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "20px",
                          color: "rgba(0, 0, 0, 0.45)",
                        }}>
                        When do you plan for all the creators’ content to go
                        live?
                      </p>
                    </div>
                  }
                  name="cDate"
                  className={styles.label}
                  rules={[
                    {
                      type: "array" as const,
                      required: true,
                      message: "Please select time!",
                    },
                  ]}
                  initialValue={
                    campaignData && [
                      moment(campaignData?.data.campaign.startDate),
                      moment(campaignData?.data.campaign.endDate),
                    ]
                  }

                  // {...formItemLayout}
                >
                  <RangePicker
                    format={`DD/MM/YYYY`}
                    size="middle"
                    style={{ width: "100%" }}
                    disabled={!isSaveEnabled}
                    defaultValue={
                      campaignData && [
                        moment(campaignData?.data.campaign.startDate),
                        moment(campaignData?.data.campaign.endDate),
                      ]
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      size="large"
                      title="Next"
                      style={{ width: "144px", marginTop: "32px" }}
                      htmlType="submit"
                      loading={
                        editCampaignMutation.isLoading ||
                        postCampaignMutation.isLoading
                      }
                      disabled={!isSaveEnabled}>
                      Save
                    </Button>
                    {campaignData?.data.campaign.statusHistory.findIndex(
                      (item) => item.status === "Active"
                    ) === -1 && (
                      <Button
                        type="text"
                        size="large"
                        title="Next"
                        style={{ width: "144px", marginTop: "32px" }}
                        onClick={() => setEditEnabled(false)}
                        disabled={!isEditEnabled}>
                        Edit
                      </Button>
                    )}
                  </Space>
                </Form.Item>
                {/* <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    title="Next"
                    style={{ width: "144px", marginTop: "32px" }}
                    htmlType="submit"
                    loading={postCampaignMutation.isLoading}>
                    {isFormEdited ? "Edit" : "Save"}
                  </Button>
                </Form.Item> */}
              </Form>
            </div>
          </Col>
          <Col
            style={{ overflowY: "auto", maxHeight: "47vh" }}
            offset={1}
            span={6}>
            {campaignData?.data.campaign.status === "Needs-Attention" && (
              <Alert
                type="error"
                message={campaignData?.data.campaign.remarks}
              />
            )}
          </Col>
        </Row>
        <Footer
          onNext={() => {
            navigate(`/campaign/create/${campaignId}/add-products`, {
              replace: true,
            });
          }}
          onBack={() => {}}
          isBackDisabled={true}
          isNextDisabled={
            !campaignData
              ? true
              : isSaveEnabled
              ? true
              : campaignData?.data.campaign.statusHistory.findIndex(
                  (item) => item.status === "Created-Campaign"
                ) === -1
          }
        />
      </div>
    </>
  );
};

export default BasicDetails;
