import React, { useState } from "react";
import { useQuery } from "react-query";
import { Skeleton, Spin } from "antd";
// import InfiniteScrollReverse from "react-infinite-scroll-reverse";
import InfiniteScroll from "react-infinite-scroll-component";

import { useChatContext } from "../../../context/ChatContext";

import { getUserProfile } from "../../../handler/chat";

import styles from "./chatWindow.module.css";

import Message from "./Message";
import ChatInput from "./ChatInput";

import { Message as MessageType } from "../../../typings";
import CreatorProfile from "../../Campaign/Manage/CreatorProfile";

interface ChatWindowProps {
  messages: MessageType[];
  influencerId: string;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  conversationId: string;
  isInTimeline: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  influencerId,
  hasNextPage,
  fetchNextPage,
  isLoading,
  conversationId,
  isInTimeline,
}) => {
  const [getUserProfileKey, getUserProfileFn] = getUserProfile(influencerId);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { data } = useQuery(getUserProfileKey, getUserProfileFn);

  const {} = useChatContext();

  return (
    <main
      style={isInTimeline ? { height: "94.7vh" } : {}}
      className={styles.container}>
      <div className={styles.header}>
        {data?.data ? (
          <>
            <img
              src={data?.data.profilePicUrl || "/assets/img/test-profile.png"}
              alt="profile"
              onClick={() => setIsVisible(true)}
              className={styles.profile}
            />
            <span className={styles.cName}>
              {" "}
              {data?.data.name || "Name Surname"}{" "}
            </span>
          </>
        ) : (
          <Skeleton
            active
            avatar={{ shape: "circle" }}
            title={{ width: 200 }}
            paragraph={false}
          />
        )}
      </div>
      <div
        className={styles.chats}
        style={{
          overflow: "auto",
          height: `${isInTimeline ? "76.1vh" : "74.1vh"}`,
          display: "flex",
          flexDirection: "column-reverse",
          // backgroundColor: "#FAFAFA",
        }}
        id={`scrollableDiv${conversationId}`}>
        {isLoading ? (
          <>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
            <Skeleton active avatar paragraph={{ rows: 2 }} />
            <Skeleton active avatar paragraph={{ rows: 2 }} />
            <Skeleton active avatar paragraph={{ rows: 2 }} />
            <Skeleton active avatar paragraph={{ rows: 2 }} />
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </>
        ) : (
          <InfiniteScroll
            next={() => {
              console.log("last");
              fetchNextPage();
            }}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "hidden",
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={hasNextPage}
            loader={<Spin />}
            scrollableTarget={`scrollableDiv${conversationId}`}
            dataLength={messages.length - 1}>
            {messages.length > 0 &&
              messages.map((item, i) => (
                <Message
                  conversationId={conversationId}
                  messageItem={item}
                  influencerId={influencerId}
                  previousMessageAuthor={
                    i !== messages.length - 1 ? messages[i + 1].author : ""
                  }
                  previousMessageDate={
                    i !== messages.length - 1
                      ? new Date(messages[i + 1].createDateTime)
                      : new Date(1970, 1, 1)
                  }
                />
              ))}
          </InfiniteScroll>
        )}
      </div>
      <ChatInput />
      <CreatorProfile
        isVisible={isVisible}
        handleClose={() => setIsVisible(false)}
        influencerId={influencerId}
      />
    </main>
  );
};

export default ChatWindow;
