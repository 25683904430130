import React, { useEffect, useState } from "react";
import { Form, Input, Popconfirm, Table, Typography } from "antd";

import { validateEmail, validateIndNum } from "../../../../utils";

import { TableItem } from "..";

// const originData: Ta[] = [];
// for (let i = 0; i < 10; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Test ${i}`,
//     platform: "youtube",
//     username: `cooldude_${i}`,
//     email: `test@gmail.com`,
//     phoneNumber: "+91-7889561310",
//   });
// }
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "text" | "number";
  record: TableItem;
  index: number;
  children: React.ReactNode;
}

const validateDataField = (dataIndex: string, record: any) => {
  if (!record || !dataIndex || !record[dataIndex]) {
    return null;
  }
  let errorMessage = "";

  switch (dataIndex) {
    case "name":
      if (String(record["name"]).length > 20)
        errorMessage = "Name can't be greater than 20 characters";
      break;

    case "platform":
      if (
        record["platform"] !== "instagram" &&
        record["platform"] !== "youtube"
      )
        errorMessage = "Platform must be instagram or youtube";
      break;
    case "email":
      if (!record["email"].trim()) return;
      if (!validateEmail(record["email"])) errorMessage = "Invalid Email";
      break;
    case "phoneNumber":
      if (
        String(record["phoneNumber"]).length !== 10 ||
        !validateIndNum(String(record["phoneNumber"]))
      )
        errorMessage = "Phone Number must be of 10 characters";
      break;
    default:
      break;
  }
  if (errorMessage) {
    return <p style={{ color: "red" }}>{errorMessage}</p>;
  }
  return null;
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "text" ? <Input /> : <Input type="number" />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: dataIndex === "email" ? false : true,
              message: `Please Input ${title.toLowerCase()}!`,
            },
            () => ({
              validator(_, value) {
                if (dataIndex) {
                  if (dataIndex === "name" && value.length >= 20)
                    return Promise.reject(
                      new Error("Name can't be greater than 20 characters")
                    );
                  if (
                    dataIndex === "email" &&
                    (value || "").trim() &&
                    !validateEmail(value)
                  )
                    return Promise.reject(new Error("Invalid Email"));
                  if (
                    dataIndex === "platform" &&
                    value !== "instagram" &&
                    value !== "youtube"
                  )
                    return Promise.reject(
                      new Error("Platform must be instagram or youtube")
                    );

                  if (
                    dataIndex === "phoneNumber" &&
                    String(value || "").trim().length === 0
                  ) {
                    return Promise.reject(
                      new Error("Phone number cannot be empty!")
                    );
                  }

                  if (
                    dataIndex === "phoneNumber" &&
                    (String(value).length !== 10 || !validateIndNum(value))
                  )
                    return Promise.reject(new Error("Invalid Phone Number"));
                }
                console.log(dataIndex, value);
                if (!value) {
                  return Promise.resolve();
                }

                return Promise.resolve();
              },
            }),
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        <>
          {children}
          {/* {(dataIndex === "email" || dataIndex === "phoneNumber") &&
            !(record.phoneNumber || record.email) && (
              <p style={{ color: "red" }}>
                Please enter either email/phoneNumber
              </p>
            )} */}

          {dataIndex &&
            typeof dataIndex !== "undefined" &&
            dataIndex !== "email" &&
            !record[dataIndex as keyof TableItem] && (
              <p style={{ color: "red" }}>
                Please enter {dataIndex.toLowerCase()}
              </p>
            )}

          {dataIndex &&
            typeof dataIndex !== "undefined" &&
            validateDataField(dataIndex, record)}
        </>
      )}
    </td>
  );
};

interface TableEditProps {
  data: TableItem[];
  setData: React.Dispatch<React.SetStateAction<TableItem[]>>;
}

const TableEdit: React.FC<TableEditProps> = ({ data, setData }) => {
  const [form] = Form.useForm();
  //   const [data, setData] = useState<Item[]>(originData);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: TableItem) => record.key === editingKey;

  const edit = (record: Partial<TableItem> & { key: React.Key }) => {
    form.setFieldsValue({
      name: "",
      platform: "",
      username: "",
      email: "",
      phoneNumber: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as TableItem;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRecord = (key: React.Key) => {
    setData((data) => data.filter((item) => item.key !== key));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "Platform (Instagram/Youtube)",
      dataIndex: "platform",
      width: "20%",
      editable: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: "15%",
      editable: true,
    },
    {
      title: "Email (Optional)",
      dataIndex: "email",
      width: "20%",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record: TableItem) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record.key);
              }}
              style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}>
              Edit
            </Typography.Link>
            <Typography.Link
              onClick={() => deleteRecord(record.key)}
              style={{ marginLeft: "20px" }}>
              Delete
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TableItem) => ({
        record,
        inputType: col.dataIndex !== "phoneNumber" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      {/* <Button
        type="primary"
        style={{
          borderRadius: "10px",
          padding: "10px 26px",
          width: "auto",
          height: "46px",
          fontSize: "16px",
          marginLeft: "auto",
          marginBottom: "20px",
        }}
        onClick={() =>
          setData((data) => {
            data.unshift({
              key: cuid(),
              name: "",
              platform: "",
              username: "",
              email: "",
              phoneNumber: "",
            });
            return data;
          })
        }>
        Add a Row
      </Button> */}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: 250 }}
        />
      </Form>
    </div>
  );
};

export default TableEdit;
