export const addArrays = (arrays: number[][]): number[] => {
  const length = arrays[0].length;

  for (let i = 1; i < arrays.length; i++) {
    if (arrays[i].length !== length) {
      return new Array(length).fill(0);
    }
  }

  const result = new Array(length).fill(0);

  for (let i = 0; i < length; i++) {
    for (let j = 0; j < arrays.length; j++) {
      result[i] += arrays[j][i];
    }
  }

  return result;
};
