import React from "react";
import { Drawer } from "antd";
import IndividualReportScreen from "../../IndividualReportScreen";
interface InfluencerDrawerProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  socialMediaId: string;
  platform: string;
  deliverableMediaId: string;
}

const InfluencerDrawer: React.FC<InfluencerDrawerProps> = ({
  visible,
  setVisible,
  campaignId,
  socialMediaId,
  platform,
  deliverableMediaId,
}) => {
  return (
    <Drawer
      headerStyle={{ background: "#FFFFFF" }}
      title="Customise Reports"
      placement="right"
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
      width={1200}>
      <IndividualReportScreen
        campaignId={campaignId}
        socialMediaId={socialMediaId}
        platform={platform as "instagram" | "youtube"}
        deliverableMediaId={deliverableMediaId}
        inDrawer={true}
      />
    </Drawer>
  );
};

export default InfluencerDrawer;
