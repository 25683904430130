import { sendRequest } from "../helper";

export type UpdateCampaignTimelineProps = {
  campaignId: string;
  timeline: Array<{
    deliverable: string;
    tasks: Array<{
      type: string;
      isApprovalRequired: boolean;
    }>;
  }>;
};

export const updateCampaignTimeline = (props: UpdateCampaignTimelineProps) => {
  console.log(props);
  return sendRequest(
    "put",
    `/campaign/update-campaign-timeline`,
    false,
    props
  ).then((res) => res.data);
};
