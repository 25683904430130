import React from "react";
import { format } from "date-fns";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

import { Typography, Space, Row, Col, Card } from "antd";

import { formatData } from "../../../../utils";

import { useCreatorPageContext } from "../context";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const { Title, Text } = Typography;

const CommentAnalysis = () => {
  const { creatorData } = useCreatorPageContext();

  return (
    <div>
      <Title level={4}>Comment analysis</Title>
      <Text type="secondary">
        We analyzed{" "}
        {creatorData?.profile_comment_analytics?.total_comment_count} across the
        most relevant posts by this creator. Last updated{" "}
        {format(
          new Date(creatorData?.profile_comment_analytics?.updated_at || ""),
          "MMM, yyyy"
        )}
      </Text>
      <Row gutter={[50, 50]} style={{ marginTop: 40 }}>
        <Col span={10}>
          <Card style={{ height: "400px", width: "300px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <Doughnut
                options={{
                  cutout: "95%",
                  plugins: {
                    legend: { position: "top" },
                    datalabels: {
                      anchor: "start",
                      align: "start",

                      formatter: (value) => formatData(value || 0, "a"),
                      font: {
                        size: 16,
                        style: "normal",
                      },
                    },
                  },
                }}
                data={{
                  labels: [
                    `Positive ${formatData(
                      (creatorData?.profile_comment_analytics
                        ?.positive_comment_count || 0) /
                        (creatorData?.profile_comment_analytics
                          ?.total_comment_count || 1),
                      "%"
                    )}`,
                    `Neutral ${formatData(
                      (creatorData?.profile_comment_analytics
                        ?.neutral_comment_count || 0) /
                        (creatorData?.profile_comment_analytics
                          ?.total_comment_count || 1),
                      "%"
                    )}`,
                    `Negative ${formatData(
                      (creatorData?.profile_comment_analytics
                        ?.negative_comment_count || 0) /
                        (creatorData?.profile_comment_analytics
                          ?.total_comment_count || 1),
                      "%"
                    )}`,
                  ],
                  datasets: [
                    {
                      label: "No",
                      data: [
                        creatorData?.profile_comment_analytics
                          ?.positive_comment_count,
                        creatorData?.profile_comment_analytics
                          ?.neutral_comment_count,
                        creatorData?.profile_comment_analytics
                          ?.negative_comment_count,
                      ],
                      backgroundColor: [
                        "rgb(4, 219, 142)",
                        "rgb(229, 229, 232)",
                        "rgb(217, 29, 74)",
                      ],
                      borderColor: [
                        "rgb(4, 219, 142)",
                        "rgb(229, 229, 232)",
                        "rgb(217, 29, 74)",
                      ],
                      borderWidth: 1,
                      borderJoinStyle: "round",
                      borderRadius: 10,
                      hoverOffset: 4,
                      offset: 0,
                    },
                  ],
                }}
              />
            </div>
            <Title level={4}>Audience Sentiment</Title>
            <Text type="secondary">
              Comments classified on the basis of the opinions and emotions
              behind them.
            </Text>
          </Card>
        </Col>
        <Col span={10}>
          <Card style={{ height: "500px", width: "360px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <Doughnut
                options={{
                  cutout: "95%",
                  plugins: {
                    legend: { position: "top" },
                    datalabels: {
                      anchor: "start",
                      align: "start",

                      formatter: (value) => formatData(value || 0, "a"),
                      font: {
                        size: 16,
                        style: "normal",
                      },
                    },
                  },
                }}
                data={{
                  labels: [
                    `Intent to buy  ${formatData(
                      (creatorData?.profile_comment_analytics
                        ?.purchase_intent_comment_count || 0) /
                        (creatorData?.profile_comment_analytics
                          ?.total_comment_count || 1),
                      "%"
                    )}`,
                    `Bypassers ${formatData(
                      (creatorData?.profile_comment_analytics
                        ?.total_comment_count ||
                        0 -
                          (creatorData?.profile_comment_analytics
                            ?.purchase_intent_comment_count || 0)) /
                        (creatorData?.profile_comment_analytics
                          ?.total_comment_count || 1),
                      "%"
                    )}`,
                  ],
                  datasets: [
                    {
                      label: "No",
                      data: [
                        creatorData?.profile_comment_analytics
                          ?.purchase_intent_comment_count,
                        creatorData?.profile_comment_analytics
                          ?.total_comment_count ||
                          0 -
                            (creatorData?.profile_comment_analytics
                              ?.purchase_intent_comment_count || 0),
                      ],
                      backgroundColor: [
                        "rgb(4, 219, 142)",
                        "rgb(229, 229, 232)",
                      ],
                      borderColor: ["rgb(4, 219, 142)", "rgb(229, 229, 232)"],
                      borderWidth: 1,
                      borderJoinStyle: "round",
                      borderRadius: 10,
                      hoverOffset: 4,
                      offset: 0,
                    },
                  ],
                }}
              />
            </div>
            <Title level={4}>Intent to buy Product</Title>

            <Text type="secondary" style={{ display: "block" }}>
              {
                creatorData?.profile_comment_analytics
                  ?.purchase_intent_comment_count
              }{" "}
              / {creatorData?.profile_comment_analytics?.total_comment_count}{" "}
              comments
            </Text>
            <Text type="secondary">
              Comments classified on the basis of the opinions and emotions
              behind them.
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CommentAnalysis;
