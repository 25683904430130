import React from "react";
import cuid from "cuid";
import { Space, Tag, Row, Button } from "antd";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { sleep } from "../../../utils";

const TagHoc: React.FC<{ facetName: string; title: string; desc: string }> = ({
  desc,
  facetName,
  title,
}) => {
  const { setSearchParams, getResults } = useCreatorSearchContext();

  return (
    <Tag
      onClose={() => {
        setSearchParams((sParams) => {
          const params = { ...sParams };
          delete params[facetName];
          delete params["filterTags"][facetName];
          return params;
        });

        sleep(10).then(() => {
          getResults();
        });
      }}
      closable={true}
    >
      {title} : {desc}
    </Tag>
  );
};

const getDescription = (val: any) => {
  if (typeof val !== "object") {
    return String(val);
  } else {
    if (Array.isArray(val)) {
      let result = "";
      val?.forEach((item, idx) => {
        result = result + `${idx === 0 ? "" : " | "}` + getDescription(item);
      });
      return result;
    } else {
      let result = "";

      if (val["value"]) {
        result = result + val["value"];
      }

      if (val["min"] || val["max"]) {
        result = result + " " + val?.min + "-" + val?.max;
      }

      if (val["percentage"]) {
        result = result + " >" + val?.percentage + "%";
      }
      return result;
    }
  }
};

const FilterSelect = () => {
  const { removeFilters, ObjToFilter } = useCreatorSearchContext();

  if (Object.keys(ObjToFilter || {}).length <= 0) {
    return null;
  }

  return (
    <Row
      style={{
        borderRadius: "6px",
        padding: "16px 24px 16px 16px",
        backgroundColor: "#f5f5f6",
      }}
      justify={"space-between"}
    >
      <Space wrap size={10} style={{ width: "85%" }}>
        {Object.values(ObjToFilter || {}).map((oItem) => (
          <TagHoc
            desc={getDescription(oItem?.facetValue)}
            facetName={oItem.facetName}
            title={oItem.facetTitle}
            key={cuid()}
          />
        ))}
      </Space>
      <Button type="text" onClick={() => removeFilters()}>
        <p style={{ marginBlock: 0, textDecoration: "underline" }}>
          Remove all Filters
        </p>
      </Button>
    </Row>
  );
};

export default FilterSelect;
