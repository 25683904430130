import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";

import StatsCard from "./StatsCard";
import CircularStatsCard from "./CircularStatsCard";

import {
  getInfluencerMediaInsight,
  postInsightRequest,
} from "../../../../../handler/Profile";
import { getUser } from "../../../../../handler/getUser";

import styles from "./demographic.module.css";
import { AxiosError } from "axios";

interface DemographicProps {
  mediaKitId: string;
  hasAccess: boolean;
  platform: "instagram" | "youtube";
  influencerId: string;
  accessRequested: boolean;
}

const Demographic: React.FC<DemographicProps> = ({
  mediaKitId,
  hasAccess,
  platform,
  influencerId,
  accessRequested,
}) => {
  const queryClient = useQueryClient();
  const [getInfluencerMediaKey, getInfluencerMediaFn] =
    getInfluencerMediaInsight(mediaKitId);
  const [getUserKey, getUserFn] = getUser();

  const { data: userData } = useQuery(getUserKey, getUserFn);

  const { data: influencerMedia, isLoading: isInfluencerMediaLoading } =
    useQuery(getInfluencerMediaKey, getInfluencerMediaFn, {
      enabled: hasAccess,
    });

  const [isSeeMoreEnabled, setSeeMoreEnabled] = useState<boolean>(false);

  const postInsightRequestMutation = useMutation(postInsightRequest, {
    onSuccess: () => {
      message.success("Access Requested");
      queryClient.invalidateQueries(["influencer", influencerId, "media"]);
    },
    onError: (err: AxiosError) => {
      message.error("Some error Occurred");
    },
  });

  let ageData = Object.entries(
    influencerMedia?.data[platform]?.audience.age || { "0-65+": 100 }
  ).map((key) => {
    return {
      label: String(key[0]),
      percent: Number(Math.round(key[1])),
    };
  });

  if (isInfluencerMediaLoading) {
    return <div>Loading..</div>;
  }

  if (!hasAccess) {
    return (
      <Row justify="center" className={styles.requestContainer}>
        <Col>
          <p className={styles.requestHeading}>
            {" "}
            You don’t have access to this data. Request access from the
            <br /> creator to see their audience’s location, age-range and
            gender.
          </p>
          <Row justify="center">
            <Button
              type="primary"
              size="middle"
              style={{ borderRadius: "2px" }}
              loading={postInsightRequestMutation.isLoading}
              onClick={() =>
                postInsightRequestMutation.mutate({
                  influencerId,
                  socialMediaKitId: mediaKitId,
                })
              }
              disabled={
                userData?.data?.signUpStatus !== "businessVerified" ||
                accessRequested
              }>
              {" "}
              {accessRequested ? "Request Sent" : "Request Access"}
            </Button>
          </Row>
        </Col>
      </Row>
    );
  }

  const getLocationItems = () => {
    if (!isSeeMoreEnabled) {
      return Object.entries(
        influencerMedia!.data[platform]!.audience!.location![
          platform === "instagram" ? "state" : "country"
        ] || [{ stateName: "India", views: 100 }]
      )
        .sort((a, b) => b[1].views - a[1].views)
        .reduce((prevItems, item, idx) => {
          const [key, value] = item;
          if (idx <= 3) {
            return [
              ...prevItems,
              {
                label:
                  value[platform === "instagram" ? "stateName" : "countryName"],
                percent: Number(
                  (value.views * 100) /
                    (influencerMedia!.data[platform]!.audience.location
                      ?.aggregate![
                      platform === "instagram" ? "state" : "country"
                    ]! || 1)
                ),
              },
            ];
          } else {
            return [
              ...prevItems.slice(0, 4),
              {
                label: "Others",
                percent: Number(
                  100 -
                    prevItems
                      .slice(0, 3)
                      .reduce(
                        (prevItem, currentItem) =>
                          prevItem + currentItem.percent,
                        0
                      )
                ),
              },
            ];
          }
        }, [] as { label: string; percent: number }[]);
    }
    return Object.entries(
      influencerMedia!.data[platform]!.audience!.location![
        platform === "instagram" ? "state" : "country"
      ] || [{ stateName: "India", views: 100 }]
    )
      .sort((a, b) => b[1].views - a[1].views)
      .map((item) => {
        const [_, value] = item;
        return {
          label: value[platform === "instagram" ? "stateName" : "countryName"],
          percent: Number(
            (value.views * 100) /
              (influencerMedia!.data[platform]!.audience.location?.aggregate![
                platform === "instagram" ? "state" : "country"
              ]! || 1)
          ),
        };
      });
  };

  if (influencerMedia?.data)
    return (
      <>
        <Row gutter={[4, 4]}>
          <Col span={8}>
            <StatsCard
              title={platform === "instagram" ? "State" : "Country"}
              items={getLocationItems()}
              isSeeMoreEnabled={isSeeMoreEnabled}
              handleSeeMoreEnabled={() =>
                setSeeMoreEnabled((isSeeMoreEnabled) => !isSeeMoreEnabled)
              }
            />
          </Col>
          <Col span={8}>
            <StatsCard title="Age Range" items={ageData} />
          </Col>
          <Col span={8}>
            <CircularStatsCard
              title="Gender Ratio"
              items={[
                {
                  label: "Male",
                  percent:
                    influencerMedia?.data[platform]?.audience.gender?.male || 0,
                },
                {
                  label: "Female",
                  percent:
                    influencerMedia?.data[platform]?.audience.gender?.female ||
                    0,
                },
                {
                  label: "Other",
                  percent:
                    influencerMedia?.data[platform]?.audience.gender?.unknown ||
                    0,
                },
              ]}
            />
          </Col>
        </Row>
      </>
    );
  return null;
};

export default Demographic;
