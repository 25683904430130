import React from "react";
import { Row, Col, Button, Input, Card } from "antd";

import PlatformSelector from "../FilterSearch/PlatformSelector";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";

const ProfileSearch = () => {
  const { getResults, searchParams, setSearchParams } =
    useCreatorSearchContext();

  return (
    <Card
      style={{
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "start" }}>
          {" "}
          <PlatformSelector />
          <Input
            size="large"
            style={{ minWidth: "400px", marginLeft: "15px" }}
            placeholder="Search using username, @handle, or profile URL"
            value={searchParams?.username}
            onChange={(e) =>
              setSearchParams((sParams) => ({
                ...sParams,
                username: e.target.value,
              }))
            }
            onPressEnter={() => getResults()}
          />
        </div>

        <Button
          type="primary"
          onClick={() => getResults()}
          size="large"
          style={{ borderRadius: "8px" }}
          disabled={(searchParams?.username || "")?.trim().length <= 0}
        >
          Get Results
        </Button>
      </div>
    </Card>
  );
};

export default ProfileSearch;
