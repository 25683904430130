import React, { useState } from "react";
import { Row, Col, Button, Dropdown, Card, Space } from "antd";
import PlatformSelector from "./PlatformSelector";
import type { MenuProps } from "antd";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import FiltersDrawer from "../FiltersDrawer";
import { DownOutlined } from "@ant-design/icons";
import FilterHOC from "../Common/FilterHOC";
import {
  IGFilters,
  TikTokFilters,
  YtFilters,
} from "../../../typings/CreatorSearch";
import DisabledPopOver from "../Common/DisabledPopover";

const FilterSearch = () => {
  const { searchParams, getResults, isSearchEnabled, ObjToFilter } =
    useCreatorSearchContext();
  const [visible, setVisible] = useState<boolean>(false);

  const filtersToSelect =
    searchParams?.platform === "youtube"
      ? YtFilters
      : searchParams?.platform === "tiktok"
      ? TikTokFilters
      : IGFilters;

  const followersDropDown = filtersToSelect?.find(
    (item) => item.facetName === "follower_count"
  );

  const subDropDown = filtersToSelect?.find(
    (item) => item.facetName === "subscriber_count"
  );

  const topicsDropDown = filtersToSelect?.find(
    (item) => item.facetName === "topics"
  );
  const locationDropDown = filtersToSelect?.find(
    (item) => item.facetName === "creator_locations"
  );

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div onClick={(e) => e.stopPropagation()}>
          <FilterHOC
            item={
              searchParams?.platform === "youtube"
                ? subDropDown
                : followersDropDown
            }
          />
        </div>
      ),
    },
  ];

  return (
    <Card
      style={{
        borderRadius: "10px",
      }}
    >
      <Row justify={"space-between"}>
        <Row gutter={[10, 10]}>
          <Col>
            <PlatformSelector />
          </Col>

          <Col>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button style={{ borderRadius: "8px" }} size="large">
                <Space>
                  {searchParams?.platform === "youtube"
                    ? "Subscribers"
                    : "Followers"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <div onClick={(e) => e.stopPropagation()} style={{ minWidth: 200 }}>
              <FilterHOC item={topicsDropDown} />
            </div>
          </Col>
          <Col>
            <div onClick={(e) => e.stopPropagation()} style={{ minWidth: 200 }}>
              <FilterHOC item={locationDropDown} />
            </div>
          </Col>
        </Row>
        <Row justify={"end"}>
          <Col>
            <Button
              // type="link"
              onClick={() => setVisible(true)}
              size="large"
              style={{ borderRadius: "8px" }}
            >
              More Filters
            </Button>
          </Col>
          <Col style={{ marginLeft: "5px" }}>
            <DisabledPopOver
              isDisabled={Object.keys(ObjToFilter || {}).length < 2}
              customText="Select at least 2 Filters"
            >
              <Button
                type="primary"
                onClick={() => getResults()}
                size="large"
                style={{ borderRadius: "8px" }}
                disabled={Object.keys(ObjToFilter || {}).length < 3}
              >
                Get Results
              </Button>
            </DisabledPopOver>
          </Col>
        </Row>
      </Row>

      <FiltersDrawer visible={visible} handleClose={() => setVisible(false)} />
    </Card>
  );
};

export default FilterSearch;
