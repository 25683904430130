type funcArgs = {
  userNameOrChannelId: string;
  platform?: "instagram" | "youtube";
};

const socialMediaUrlGen = (args: funcArgs): string => {
  const { userNameOrChannelId, platform = "instagram" } = args;
  let url = "";

  switch (platform) {
    case "instagram":
      url = `https://www.instagram.com/${userNameOrChannelId}/`;
      break;
    case "youtube":
      url = `https://www.youtube.com/@${userNameOrChannelId}`;
      break;
    default:
      url = "https://www.instagram.com/clout.flow";
  }

  return url;
};

export default socialMediaUrlGen;
