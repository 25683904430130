import React, { useState, useEffect } from "react";
import { Modal, Button, Rate, Input, message } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useMutation } from "react-query";

import { postReview } from "../../../../../../../../handler/Profile";
import { AxiosError } from "axios";

const { TextArea } = Input;

interface ReviewModalsProps {
  isVisible: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
  influencerId: string;
  finalizedOfferId?: string;
}

const ReviewModal: React.FC<ReviewModalsProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  influencerId,
  finalizedOfferId = "",
}) => {
  const [inputVal, setInputVal] = useState<{ rating: number; review: string }>({
    rating: 0,
    review: "",
  });

  useEffect(() => {
    setInputVal({ rating: 0, review: "" });
  }, [isVisible]);

  const postReviewMutation = useMutation(postReview, {
    onSuccess: () => {
      handleSubmit();
      message.success("Review Added");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <Modal
      centered
      title={"Add a review"}
      // footer={null}
      visible={isVisible}
      onCancel={handleCancel}
      okText="Submit"
      bodyStyle={{ borderRadius: "14px" }}
      okButtonProps={{ disabled: inputVal.review.trim() === "" }}
      onOk={() =>
        postReviewMutation.mutate({
          finalizedOfferId: finalizedOfferId || undefined,
          influencerId,
          rating: inputVal.rating,
          review: inputVal.review,
        })
      }
      confirmLoading={postReviewMutation.isLoading}

      // onOk={handleSubmit}
    >
      <Rate
        value={inputVal.rating}
        style={{ color: "#F00276", marginBottom: "17px" }}
        character={
          <HeartFilled
            size={13}
            style={{
              fontSize: "70%",
            }}
          />
        }
        onChange={(value) => {
          if (value) {
            setInputVal((inputVal) => {
              return { ...inputVal, rating: value };
            });
          }
        }}
      />
      <TextArea
        autoSize={{ minRows: 4 }}
        value={inputVal.review}
        onChange={(event) => {
          setInputVal((inputVal) => {
            return { ...inputVal, review: event.target.value };
          });
        }}
        placeholder="Write a review for the creator"
      />
    </Modal>
  );
};

export default ReviewModal;
