import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import styles from "./styles.module.css";

interface TextEditorProps {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  placeholder?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  editorState,
  setEditorState,
  placeholder,
}) => {
  return (
    <Editor
      editorState={editorState}
      wrapperClassName={styles.container}
      editorClassName={styles.textArea}
      onEditorStateChange={(editorState) => setEditorState(editorState)}
      placeholder={placeholder}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "emoji",
          "history",
          "image",
        ],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
        },
        blockType: {
          inDropdown: true,
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
          className: styles.blockType,
          dropdownClassName: styles.blockTypeDropdown,
        },
        link: {
          inDropdown: false,
          showOpenOptionOnHover: true,
          defaultTargetOption: "_blank",
          options: ["link", "unlink"],
        },
      }}
    />

    // <div
    //   dangerouslySetInnerHTML={{
    //     __html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    //   }}
    // />
    //   {}
    // </div>
  );
};

export default TextEditor;
