import { sendRequest } from "../helper";

interface getInfluencerCommunityRelationResponse {
  data: {
    isRestricted: boolean;
    reason: string;
    isInBrandCommunity: boolean;
    isProfileLockedForBrand: boolean;
    accountStatus: string;
  };
  message: string;
  status: string;
}

export const getInfluencerCommunityRelation = ({
  socialMediaId,
}: {
  socialMediaId: string;
}) => {
  const queryKey = ["community", "influencer", "relation", socialMediaId];

  const queryFunction = () =>
    sendRequest<getInfluencerCommunityRelationResponse>(
      "get",
      `/brand/community/is-in-community?socialMediaId=${socialMediaId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
