export type ConfigType = ConfigTypeSingle[];
export type ConfigTypeSingle = {
  facetName: string;
  title: string;
  isSearchable: boolean;
  compType:
    | "list"
    | "inputRange"
    | "slider"
    | "custom-multi-select"
    | "checkbox"
    | "custom-multi-inputRange"
    | "contact";
  desc: string;
  type?: string;
  placeHolder?: string;
  fType: "creator" | "audience" | "performance";
  maxCount?: number;
};

export const IGFilters: ConfigType = [
  {
    facetName: "follower_count",
    title: "Followers",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by follower count",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  {
    facetName: "topics",
    title: "Topics",
    isSearchable: true,
    compType: "list",
    desc: "Enter topics and get AI generated results of creators who create content based on the entered topics ",
    type: "multiple",
    placeHolder: "eg. Banking",
    fType: "creator",
  },

  {
    facetName: "creator_locations",
    title: "Creator's location",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators who is from the selected location. ",
    type: "multiple",
    placeHolder: "Search country or city",
    fType: "creator",
  },
  {
    facetName: "description_keywords",
    title: "Keywords",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by keywords that can be found in creator's posts captions.",
    type: "tags",
    fType: "creator",
    placeHolder: "Search by keywords",
  },

  // {
  //   facetName: "creator_brand_affinities",
  //   title: "Creator brand Affinity",
  //   isSearchable: true,
  //   compType: "list",
  //   desc: "Filter creators who has affinity to the selected brand. ",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "creator",
  // },
  // {
  //   facetName: "contact",
  //   title: "Contact options",
  //   isSearchable: true,
  //   compType: "contact",
  //   desc: "Filter creators by availability of specified Contact ",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "creator",
  // },
  {
    facetName: "content_count",
    title: "Number of Contents",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by the number of contents.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_gender",
    title: "Creator's Gender",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by gender of the creator.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  {
    facetName: "creator_age",
    title: "Creator's Age",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by creator's age.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "is_verified",
    title: "Is verified?",
    isSearchable: false,
    compType: "checkbox",
    desc: "Filter creators by platform verification status.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "last_post_timestamp",
    title: "Most Recent Post",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators who posted content in the selected time frame. ",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_language",
    title: "Creator's language",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators by creators language.",
    type: "",
    placeHolder: "Search Language",
    fType: "creator",
  },
  {
    facetName: "creator_interests",
    title: "Creator's Interests",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators who have the selected interest. ",
    type: "",
    placeHolder: "Search Interests",
    fType: "creator",
  },

  {
    facetName: "bio_phrase",
    title: "Bio phrase",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by the phrase they use in their bio.",
    type: "tags",
    fType: "creator",
    placeHolder: "Search keywords in bio of the creator",
  },

  {
    facetName: "creator_account_type",
    title: "Account Type",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by account type.",
    placeHolder: "",
    type: "multiple",
    fType: "creator",
  },

  {
    facetName: "hashtags",
    title: "Hashtags",
    isSearchable: false,
    compType: "list",
    desc: "Shows creators who have mentioned the added hashtags in their posts. Enter without #",
    placeHolder: "Search by Hashtags",
    type: "tags",
    fType: "creator",
    maxCount: 20,
  },

  {
    facetName: "mentions",
    title: "Mentions",
    isSearchable: false,
    compType: "list",
    desc: "Shows creators who have mentioned the added usernames in their posts. Enter without @",
    placeHolder: "Search by Mentions",
    type: "tags",
    fType: "creator",
    maxCount: 20,
  },

  {
    facetName: "audience_locations",
    title: "Audience Location",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who's audience is from the selected location.",
    type: "multiple",
    placeHolder: "Search country or city",
    fType: "audience",
  },

  {
    facetName: "audience_age",
    title: "Audience Age",
    isSearchable: true,
    compType: "custom-multi-inputRange",
    desc: "Filter creators by audience age.",
    type: "",
    placeHolder: "Search country or city",
    fType: "audience",
  },
  {
    facetName: "audience_gender",
    title: "Audience Gender",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators by gender of the audience.",
    type: "",
    placeHolder: "Search Gender",
    fType: "audience",
  },
  {
    facetName: "audience_language",
    title: "Audience Language",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filters creator by the language of the audience and its percentage value.",
    type: "",
    placeHolder: "Search Language",
    fType: "audience",
  },
  // {
  //   facetName: "audience_brand_affinities",
  //   title: "Audience brand Affinity",
  //   isSearchable: true,
  //   compType: "list",
  //   desc: "Filter creators who's audience has affinity to the selected brand. ",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "audience",
  // },
  {
    facetName: "audience_interest_affinities",
    title: "Audience Interest Affinities",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who's audience has the selected interest.",
    type: "multiple",
    placeHolder: "Search by Interests",
    fType: "audience",
  },

  {
    facetName: "average_likes",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by average likes.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },
  {
    facetName: "engagement_rate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "slider",
    desc: "Filter creators by engagement rates.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  {
    facetName: "has_sponsored_posts",
    title: "Has sponsored posts?",
    isSearchable: false,
    compType: "checkbox",
    desc: "Filter creators which have sponsored posts.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  // {
  //   facetName: "brand_sponsors",
  //   title: "Previous Brand Sponsors",
  //   isSearchable: true,
  //   compType: "list",
  //   desc: "Filter creators which have been sponsored by selected brands.",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "performance",
  // },
  {
    facetName: "instagram_options_reel_views",
    title: "Reel Views",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by minimum average reels views.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },
  {
    facetName: "follower_growth",
    title: "Follower Growth",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who have grown more than the selected percentage in the selected time. ",
    type: "",
    placeHolder: "Search Language",
    fType: "performance",
  },

  // {
  //   facetName: "audience_lookalikes",
  //   title: "Audience Look Alike",
  //   isSearchable: false,
  //   compType: "custom-multi-select",
  //   desc: "Filter creators whose audience looks like the audience of the selected username.",
  //   type: "tags",
  //   placeHolder: "Search Creators with similar audience",
  //   fType: "performance",
  // },
  {
    facetName: "creator_lookalikes",
    title: "Creator Look Alike",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators whose posts look like the posts of the selected username.",
    type: "tags",
    placeHolder: "Search Similar Creators",
    fType: "performance",
    maxCount: 1,
  },
];

export const YtFilters: ConfigType = [
  {
    facetName: "subscriber_count",
    title: "Subscribers",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by subscriber count.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "topics",
    title: "Topics",
    isSearchable: true,
    compType: "list",
    desc: "Enter topics and get AI generated results of creators who create content based on the entered topics ",
    type: "multiple",
    placeHolder: "e.g Banking",
    fType: "creator",
  },

  {
    facetName: "creator_locations",
    title: "Creator's location",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators who is from the selected location. ",
    type: "multiple",
    placeHolder: "Search country ",
    fType: "creator",
  },
  {
    facetName: "description_keywords",
    title: "Keywords",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by keywords that can be found in creator's posts captions.",
    type: "tags",
    fType: "creator",
    placeHolder: "Search by keyword",
  },

  {
    facetName: "content_count",
    title: "Number of Contents",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by the number of contents.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_gender",
    title: "Creator's Gender",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by gender of the creator.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_age",
    title: "Creator's Age",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by creator's age.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "is_verified",
    title: "Is verified?",
    isSearchable: false,
    compType: "checkbox",
    desc: "Filter creators by platform verification status.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  // {
  //   facetName: "contact",
  //   title: "Contact options",
  //   isSearchable: true,
  //   compType: "contact",
  //   desc: "Filter creators by availability of specified Contact ",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "creator",
  // },

  {
    facetName: "last_post_timestamp",
    title: "Most Recent Post",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators who posted content in the selected time frame. ",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_language",
    title: "Creator's language",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators by creators language.",
    type: "",
    placeHolder: "Search language",
    fType: "creator",
  },

  {
    facetName: "audience_locations",
    title: "Audience Location",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who's audience is from the selected location. ",
    type: "multiple",
    placeHolder: "Search country ",
    fType: "audience",
  },

  {
    facetName: "audience_age",
    title: "Audience Age",
    isSearchable: true,
    compType: "custom-multi-inputRange",
    desc: "Filter creators by audience age.",
    type: "",
    placeHolder: "Search country or city",
    fType: "audience",
  },
  {
    facetName: "audience_gender",
    title: "Audience Gender",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators by gender of the audience.",
    type: "",
    placeHolder: "Search Gender",
    fType: "audience",
  },
  {
    facetName: "audience_language",
    title: "Audience Language",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filters creator by the language of the audience and its percentage value.",
    type: "",
    placeHolder: "Search Language",
    fType: "audience",
  },

  {
    facetName: "average_likes",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by average likes.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  {
    facetName: "average_views",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "ilter creators by average views.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  {
    facetName: "engagement_rate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "slider",
    desc: "Filter creators by engagement rate.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  {
    facetName: "subscriber_growth",
    title: "Subscriber Growth",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who have grown more than the selected percentage in the selected time. ",
    type: "",
    placeHolder: "Search Language",
    fType: "performance",
  },

  // {
  //   facetName: "audience_lookalikes",
  //   title: "Audience Look Alike",
  //   isSearchable: false,
  //   compType: "custom-multi-select",
  //   desc: "Filter creators whose audience looks like the audience of the selected username.",
  //   type: "tags",
  //   placeHolder: "Search creators with similar audience",
  //   fType: "performance",
  // },
  {
    facetName: "creator_lookalikes",
    title: "Creator Look Alike",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators whose posts look like the posts of the selected username.",
    type: "tags",
    placeHolder: "Search similar creators",
    fType: "performance",
    maxCount: 1,
  },
];

export const TikTokFilters: ConfigType = [
  {
    facetName: "follower_count",
    title: "Followers",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by follower count.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  {
    facetName: "topics",
    title: "Topics",
    isSearchable: true,
    compType: "list",
    desc: "Enter topics and get AI generated results of creators who create content based on the entered topics ",
    type: "multiple",
    placeHolder: "eg. Banking",
    fType: "creator",
  },

  {
    facetName: "creator_locations",
    title: "Creator's location",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators who is from the selected location. ",
    type: "multiple",
    placeHolder: "Search country ",
    fType: "creator",
  },
  {
    facetName: "description_keywords",
    title: "Keywords",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by keywords that can be found in creator's posts captions.",
    type: "tags",
    fType: "creator",
    placeHolder: "Search by keyword",
  },

  {
    facetName: "content_count",
    title: "Number of Contents",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by the number of contents.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_gender",
    title: "Creator's Gender",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by gender of the creator.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  {
    facetName: "creator_age",
    title: "Creator's Age",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by creator's age.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "is_verified",
    title: "Is verified?",
    isSearchable: false,
    compType: "checkbox",
    desc: "Filter creators by platform verification status.",
    type: "",
    placeHolder: "",
    fType: "creator",
  },
  // {
  //   facetName: "contact",
  //   title: "Contact options",
  //   isSearchable: true,
  //   compType: "contact",
  //   desc: "Filter creators by availability of specified Contact ",
  //   type: "multiple",
  //   placeHolder: "Search Brands",
  //   fType: "creator",
  // },

  {
    facetName: "last_post_timestamp",
    title: "Most Recent Post",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators who posted content in the selected time frame. ",
    type: "",
    placeHolder: "",
    fType: "creator",
  },

  {
    facetName: "creator_language",
    title: "Creator's language",
    isSearchable: true,
    compType: "list",
    desc: "Filter creators by creators language.",
    type: "",
    placeHolder: "Search Creator Language",
    fType: "creator",
  },

  {
    facetName: "bio_phrase",
    title: "Bio phrase",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by the phrase they use in their bio.",
    type: "tags",
    fType: "creator",
    placeHolder: "Search keywords in the bio of Creators",
  },

  {
    facetName: "creator_account_type",
    title: "Account Type",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators by account type. ",
    placeHolder: "",
    type: "multiple",
    fType: "creator",
  },
  {
    facetName: "hashtags",
    title: "Hashtags",
    isSearchable: false,
    compType: "list",
    desc: "Shows creators who have mentioned the added hashtags in their posts. ",
    placeHolder: "Search By Hashtags",
    type: "tags",
    fType: "creator",
    maxCount: 20,
  },

  {
    facetName: "mentions",
    title: "Mentions",
    isSearchable: false,
    compType: "list",
    desc: "Shows creators who have mentioned the added usernames in their posts. ",
    placeHolder: "Search by Mentions",
    type: "tags",
    fType: "creator",
    maxCount: 20,
  },

  {
    facetName: "audience_locations",
    title: "Audience Location",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who's audience is from the selected location. ",
    type: "multiple",
    placeHolder: "Search country ",
    fType: "audience",
  },

  {
    facetName: "audience_age",
    title: "Audience Age",
    isSearchable: true,
    compType: "custom-multi-inputRange",
    desc: "Filter creators by audience age.",
    type: "",
    placeHolder: "Search country or city",
    fType: "audience",
  },
  {
    facetName: "audience_gender",
    title: "Audience Gender",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators by gender of the audience.",
    type: "",
    placeHolder: "Search Gender",
    fType: "audience",
  },
  {
    facetName: "audience_language",
    title: "Audience Language",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filters creator by the language of the audience and its percentage value.",
    type: "",
    placeHolder: "Search Language",
    fType: "audience",
  },
  {
    facetName: "average_likes",
    title: "Average Likes",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by average likes.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },
  {
    facetName: "average_views",
    title: "Average Views",
    isSearchable: false,
    compType: "inputRange",
    desc: "Filter creators by average views.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },

  {
    facetName: "engagement_rate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "slider",
    desc: "Filter creators by engagement rate.",
    type: "",
    placeHolder: "",
    fType: "performance",
  },
  {
    facetName: "follower_growth",
    title: "Follower Growth",
    isSearchable: true,
    compType: "custom-multi-select",
    desc: "Filter creators who have grown more than the selected percentage in the selected time. ",
    type: "",
    placeHolder: "Search Language",
    fType: "performance",
  },

  // {
  //   facetName: "audience_lookalikes",
  //   title: "Audience Look Alike",
  //   isSearchable: false,
  //   compType: "custom-multi-select",
  //   desc: "Filter creators whose audience looks like the audience of the selected username.",
  //   type: "tags",
  //   placeHolder: "Search creators with similar Audience",
  //   fType: "performance",
  // },
  {
    facetName: "creator_lookalikes",
    title: "Creator Look Alike",
    isSearchable: false,
    compType: "list",
    desc: "Filter creators whose posts look like the posts of the selected username.",
    type: "tags",
    placeHolder: "Search Similar Creators",
    fType: "performance",
    maxCount: 1,
  },
];
