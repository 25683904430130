import { Row, Col, Space, Button, Divider } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";

import PersonalDetails from ".././PersonalDetails";
import BusinessDetails from ".././BusinessDetails";
import Sent from "../../Invitations/Sent";
import Modals from "../../Onboarding-1/Modals";
import EmailConfig from ".././EmailConfig";
import ReportModal from "../../Campaign/Report/ReportModal";
import ProfileMenu from "./Menu";
import OrganizationManage from "../OrganizationManage";
import Plan from "../Plan";
import BillingDetails from "../BillingDetails";

import styles from ".././profile.module.css";

import { getUser } from "../../../handler/getUser";

interface ProfileModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export type MenuType =
  | "Personal"
  | "Business"
  | "Invitation"
  | "InvitationTeam"
  | "Email"
  | "Subscription"
  | "Organization"
  | "viewPlan";

const ProfileModal: React.FC<ProfileModalProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [getUserkey, getUserFn] = getUser();
  const { data: userData } = useQuery(getUserkey, getUserFn);
  const [sectionSelected, setSectionSelected] = useState<MenuType>("Personal");

  const isKycInitiated = userData?.data?.kyc.status !== "Not-Initiated";

  const getHeaderName = () => {
    switch (sectionSelected) {
      case "Personal":
        return "Personal Details";
      case "Business":
        return "Business Details";
      case "Invitation":
        return "Manage External Collaborators";
      case "InvitationTeam":
        return "Manage Team";
      case "Email":
        return "E-mail";
      case "Subscription":
        return "Upgrade Plan";
      case "Organization":
        return "Manage Organization";
      case "viewPlan":
        return "View Plan";
      default:
        return "";
    }
  };

  return (
    <ReportModal
      visible={modalVisible}
      centered
      maskClosable={true}
      onCancel={() => setModalVisible(false)}
      footer={null}
      width={1000}
      bodyStyle={{
        backgroundColor: "#f7f7f7",
        borderRadius: "8px",
        padding: "0px",
      }}
      closable={false}>
      <Row>
        {isKycInitiated && (
          <Col span={6} style={{ padding: 10 }}>
            <ProfileMenu
              sectionSelected={sectionSelected}
              handleSelect={(type: MenuType) => setSectionSelected(type)}
            />
          </Col>
        )}
        <Col span={isKycInitiated ? 18 : 24}>
          {userData?.data.kyc.status === "Not-Initiated" ? (
            <>
              {/* <p className={styles.heading}>Your Profile</p> */}
              <Row
                style={{
                  background: "##F5F5F5",
                  // border: "2px solid rgba(0, 0, 0, 0.04)",
                }}>
                <Col span={24} style={{ minHeight: "60vh" }}>
                  <Row align="middle" style={{ height: "100%" }}>
                    <Space
                      direction="vertical"
                      align="center"
                      style={{ width: "400px", margin: "0 auto" }}>
                      <p className={styles.noKycHeading}>
                        Account not activated yet
                      </p>
                      <p className={styles.noKycDesc}>
                        You’re logged in with {userData.data.emailId}. Fill in
                        the your personal and business details to activate your
                        account.
                      </p>
                      <Button
                        onClick={() => setVisible(true)}
                        style={{
                          marginTop: "12px",
                          backgroundColor: "#533af5 !important",
                        }}
                        type="primary">
                        Activate account
                      </Button>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Modals
                isVisible={visible}
                setVisible={setVisible}
                isFirstTime={false}
              />
            </>
          ) : (
            <div className={styles.outerContainer}>
              <section className={styles.header}>
                <p>{getHeaderName()}</p>
                <Divider style={{ margin: "10px 0 0 0" }} />
              </section>
              <div style={{ height: 600, overflowY: "scroll" }}>
                {sectionSelected === "Personal" ? (
                  <PersonalDetails />
                ) : sectionSelected === "Business" ? (
                  <BusinessDetails />
                ) : sectionSelected === "Invitation" ? (
                  <Sent isTeam={false} />
                ) : sectionSelected === "InvitationTeam" ? (
                  <Sent isTeam={true} />
                ) : sectionSelected === "Organization" ? (
                  <div style={{ padding: 20 }}>
                    <OrganizationManage />
                  </div>
                ) : sectionSelected === "Subscription" ? (
                  <Plan />
                ) : sectionSelected === "viewPlan" ? (
                  <BillingDetails />
                ) : (
                  <EmailConfig />
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </ReportModal>
  );
};

export default ProfileModal;
