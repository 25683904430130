export const getNameOfDeliverable = (value: string) => {
  return value === "YT-Dedicated-Video"
    ? "Dedicated Video"
    : value === "YT-Integrated-Video"
    ? "Integrated Video"
    : value === "YT-Shorts"
    ? "Short"
    : value === "IG-Feed-Image"
    ? "Image"
    : value === "IG-Feed-Story"
    ? "Story"
    : value === "IG-Feed-Video"
    ? "Video"
    : "Reel";
};
