import React, { useState, useEffect } from "react";
import { Button, Modal, Select, message, Divider, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { DatePickerProps } from "antd/es/date-picker";
import { Radio } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import type { RadioChangeEvent } from "antd";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import CollapsibleReq from "./CollapsibleReq";

import {
  getWorkFlowDeliverables,
  postWorkFlowReq,
} from "../../../../../handler/campaign/applicants/";

import { Requirement } from "../../../../../typings";

import AddReq from "./AddReq";

import styles from "./reqModal.module.css";

import { AxiosError } from "axios";

const { Option } = Select;

interface ReqModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  id: string;
  finalizedOfferId?: string;
  applicantIds: string[];
  initialDeliverableId?: string;
}

const ReqModal: React.FC<ReqModalProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  finalizedOfferId,
  id,
  applicantIds,
  initialDeliverableId = "",
}) => {
  const queryClient = useQueryClient();

  const initialReqState: Requirement = {
    summary: "",
    mentions: [],
    caption: "",
    references: [],
    _id: "new-item",
    name: "",
    isDraftNeeded: true,
  };

  const [deliverableId, setDeliverableId] = useState(initialDeliverableId);
  const [closureDate, setClosureDate] = useState<string | null>(null);

  const [isAddDeliverableVisible, setIsAddDeliverableVisible] =
    useState<boolean>(false);

  const [getWorkFlowDeliverablesKey, getWorkFlowDeliverablesFn] =
    getWorkFlowDeliverables({
      campaignId: id,
      finalizedOfferId,
      deliverableId,
    });

  const { data: workflowDeliverableData } = useQuery(
    getWorkFlowDeliverablesKey,
    getWorkFlowDeliverablesFn
  );

  const postReqMutation = useMutation(postWorkFlowReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const [deliverableOptions, setDeliverableOptions] = useState<
    { label: string; id: string; count: number }[]
  >([]);

  const [reqs, setReqs] = useState<Requirement[]>();

  const [reqSelected, setReqSelected] = useState<Requirement>(initialReqState);
  const [editReq, setEditReq] = useState<{
    req: Requirement;
    visible: boolean;
  }>({
    visible: false,
    req: initialReqState,
  });

  useEffect(() => {
    if (workflowDeliverableData?.data && deliverableId) {
      let data = workflowDeliverableData?.data.filter(
        (item) => item?._id === deliverableId
      )[0]?.requirements;
      setReqs((data || []).reverse());
      setReqSelected(initialReqState);
    }
  }, [deliverableId, workflowDeliverableData]);

  useEffect(() => {
    setDeliverableId(initialDeliverableId);
  }, [initialDeliverableId]);

  useEffect(() => {
    if (workflowDeliverableData) {
      setDeliverableOptions(
        workflowDeliverableData.data.map((item) => {
          return {
            id: item._id,
            label: item?.deliverablesDetails.typeDel,
            count: item.deliverablesDetails.count,
          };
        })
      );
    }
  }, [workflowDeliverableData]);

  const onRadioChange = (e: RadioChangeEvent) => {
    if (workflowDeliverableData) {
      setReqSelected(
        workflowDeliverableData.data
          .filter((item) => item._id === deliverableId)[0]
          .requirements.filter((reqItem) => reqItem._id === e.target.value)[0]
      );
    }
  };

  const onChange = (_: DatePickerProps["value"], dateString: string) => {
    setClosureDate(dateString);
  };

  const handleFormSubmit = () => {
    postReqMutation.mutate({
      finalizedOffersIds: applicantIds,
      deliverableId,
      closureDate: closureDate || dayjs().endOf("day").toString(),
      isDraftNeeded: reqSelected.isDraftNeeded,
      requirementId: reqSelected._id,
    });
  };

  return (
    <>
      {workflowDeliverableData && (
        <Modal
          title="Send Requirements"
          visible={isVisible}
          onOk={handleFormSubmit}
          okText={deliverableId !== "" ? "Continue" : "Send"}
          onCancel={handleCancel}
          okButtonProps={{
            loading: postReqMutation.isLoading,
            disabled: deliverableId === "" || reqSelected._id === "new-item",
          }}
          centered>
          <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
            {!initialDeliverableId && (
              <>
                <p className={styles.heading}>Select Deliverable</p>
                <Select
                  placeholder="Select Deliverable"
                  onChange={(value) => {
                    setDeliverableId(value);
                  }}
                  style={{ width: "210px", marginTop: "7px" }}>
                  {deliverableOptions?.map((item) => (
                    <Option value={item.id}>
                      {item.label} {item.count}
                    </Option>
                  ))}
                </Select>
              </>
            )}

            {deliverableId !== "" && (
              <>
                <div
                  style={{
                    marginTop: "32px",
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  Previously created requirements
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => setIsAddDeliverableVisible(true)}>
                    Add new{" "}
                  </Button>
                </div>
                {(reqs || []).length > 0 ? (
                  <Radio.Group
                    onChange={onRadioChange}
                    value={reqSelected?._id}>
                    <div
                      style={{
                        display: "grid",
                        gap: "20px",
                        margin: "24px 0px 16px 0px",
                        height: "30vh",
                        overflowY: "scroll",
                      }}>
                      <>
                        {reqs?.map((item) => {
                          return (
                            <Radio
                              value={item._id}
                              className={styles.radioWrapper}
                              key={item._id}>
                              <CollapsibleReq
                                selectedRequirement={item}
                                onReplicate={() =>
                                  setEditReq({
                                    visible: true,
                                    req: { ...item, name: "" },
                                  })
                                }
                                workflowDeliverableData={
                                  workflowDeliverableData?.data?.filter(
                                    (item) => item._id === deliverableId
                                  )[0]
                                }
                              />
                            </Radio>
                          );
                        })}
                      </>
                    </div>
                  </Radio.Group>
                ) : (
                  <Row justify="center" style={{ marginTop: "20px" }}>
                    <Col span={24}>
                      <h3 style={{ textAlign: "center" }}>
                        You need to create a Requirement First
                      </h3>
                    </Col>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{ marginTop: "10px" }}
                      onClick={() => setIsAddDeliverableVisible(true)}>
                      Add a new Requirement
                    </Button>
                  </Row>
                )}

                <Row justify="center" style={{ margin: "16px 0" }}>
                  {reqSelected._id !== "new-item" && (
                    <div style={{ width: "100%" }}>
                      <div className={styles.item}>
                        <p className={styles.heading}>Set a deadline</p>
                        <DatePicker
                          onChange={onChange}
                          value={moment(
                            closureDate || dayjs().endOf("day").toString()
                          )}
                          disabledDate={(current) => {
                            return current && current < dayjs().endOf("day");
                          }}
                          allowClear={false}
                        />
                      </div>
                    </div>
                  )}
                </Row>
              </>
            )}
          </div>
          <AddReq
            deliverableId={deliverableId}
            handleCancel={() => {
              if (editReq.visible) {
                return setEditReq({ visible: false, req: initialReqState });
              }

              return setIsAddDeliverableVisible(false);
            }}
            handleSubmit={() => {
              if (editReq.visible) {
                return setEditReq({ visible: false, req: initialReqState });
              }
              return setIsAddDeliverableVisible(false);
            }}
            id={id}
            isVisible={isAddDeliverableVisible || editReq.visible}
            workflowDeliverableData={
              workflowDeliverableData?.data?.filter(
                (item) => item._id === deliverableId
              )[0]
            }
            initialReq={editReq?.visible ? editReq.req : undefined}
            isView={false}
          />
        </Modal>
      )}
    </>
  );
};

export default ReqModal;
