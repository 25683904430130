import { sendRequest } from "../helper";
import { CreatorReport } from "../../typings";

export type upsertInfluencerProps = {
  socialMediaId: string;
  campaignId: string;
  socialMediaKitId: string;
  brandPrice: number;
};

export const upsertInfluencer = (props: upsertInfluencerProps) => {
  return sendRequest<CreatorReport>(
    "post",
    "/brand/report-only/influencer",
    false,
    { ...props }
  ).then((res) => res.data);
};
