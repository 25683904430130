import { sendRequest } from "../helper";

export const acceptInvitation = ({
  brandOrgRoleId,
}: {
  brandOrgRoleId: string;
}) => {
  return sendRequest(
    "put",
    `/brand/organisation/accept?brandOrgRoleId=${brandOrgRoleId}`,
    false
  ).then((res) => res.data);
};
