import React, { ReactElement, JSXElementConstructor } from "react";
import { Row, Space } from "antd";
import { BiCaretDown } from "react-icons/bi";
import { LuPin, LuPinOff } from "react-icons/lu";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { getRelativePosition } from "chart.js/helpers";

import CustomDropDown from "../../../shared/Custom/DropDown";

import styles from "./styles.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface LineGraphProps {
  title: string;
  selectedOption?: string;
  optionsItemArray: {
    label: string | ReactElement<any, string | JSXElementConstructor<any>>;
    onClick: () => void;
  }[];
  description?: React.ReactNode;
  secondaryDescription?: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  metric?: string;
  metricLabel?: string;
  graphData: number[];
  labelArray: string[];
  barColor?: string;
  params?: { [key: string]: any };
  handleClick?: (y: number, x: number) => void;
  isAudience?: boolean;
  isPinned?: boolean;
  handlePin?: () => void;
  hidePin?: boolean;
}

const LineGraph: React.FC<LineGraphProps> = (props) => {
  const data = {
    labels: props.labelArray,
    datasets: [
      {
        label: "Dataset 1",
        data: props.graphData,
        borderColor: props.barColor || "#533AF5",
        backgroundColor: props.barColor || "#533AF5",
        tension: 0.5,
        fill: false,

        ...props.params,
      },
    ],
  };

  return (
    <div className={styles.container}>
      {!props?.hidePin && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: 10,
            cursor: "pointer",
          }}
          onClick={props.handlePin}>
          {props.isPinned ? (
            <LuPin size={10} color={"#00000066"} />
          ) : (
            <LuPinOff size={10} color={"#00000066"} />
          )}
        </div>
      )}
      <Row justify="space-between" style={{ width: "100%" }}>
        <p className={styles.title}>{props.title}</p>
        {props?.optionsItemArray?.length > 0 && (
          <CustomDropDown
            items={props.optionsItemArray.map((optionsItem, idx) => ({
              ...optionsItem,
              key: idx.toString(),
            }))}>
            <a
              onClick={(e) => e.preventDefault()}
              className={styles.optionsContainer}>
              {props.selectedOption}
              <BiCaretDown color="#00000080" size={8} />
            </a>
          </CustomDropDown>
        )}
      </Row>

      {props.description}
      <Row justify="space-between" style={{ width: "100%" }}>
        <Space align="center" size={10}>
          {props.secondaryIcon}
          <p className={styles.secondaryDescription}>
            {props.secondaryDescription}
          </p>
        </Space>
        <div>
          <p className={styles.metric}>{props.metric}</p>
          <p className={styles.metricLabel}>{props.metricLabel}</p>
        </div>
      </Row>
      <div style={{ height: "200px" }}>
        {props?.graphData?.reduce((prev, current) => prev + current || 0, 0) ===
        0 ? (
          <p className="global-no-data">Not enough Data To show this Metric</p>
        ) : (
          <Line
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "top" as const,
                },
                datalabels: {
                  display: false,
                  // This code is used to display data values
                },
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
              },
              interaction: { mode: "nearest" },
              scales: {
                y: {
                  grid: {
                    display: props?.isAudience ? true : false,
                  },
                  title: {
                    display: props?.params?.ylabel ? true : false,
                    text: props?.params?.ylabel,
                  },
                },
                x: {
                  type: props?.isAudience ? "linear" : "category",
                  grid: {
                    display: props?.isAudience ? true : false,
                  },
                  title: {
                    display: props?.params?.xlabel ? true : false,
                    text: props?.params?.xlabel,
                  },
                },
              },
              onClick: (event, _, chart) => {
                console.log("event", event);
                const canvasPosition = getRelativePosition(event, chart as any);

                // Substitute the appropriate scale IDs
                const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
                const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);

                if (props?.handleClick) {
                  props.handleClick(dataY || 0, dataX || 0);
                }
              },
            }}
            data={data}
            width={"500px"}
          />
        )}
      </div>
    </div>
  );
};

export default LineGraph;
