import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import LOOP from "./../../../assets/LooperGroup.svg";
import ARROW from "./../../../assets/arrow.svg";

import styles from "./demoCall.module.css";

import { InlineWidget } from "react-calendly";

interface DemoCallProps {
  isVisible: boolean;
  handleClose: () => void;
}

const DemoCall: React.FC<DemoCallProps> = ({ isVisible, handleClose }) => {
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3500);
  }, []);

  return (
    <Modal
      onCancel={handleClose}
      closable={false}
      visible={isVisible}
      centered
      title={null}
      footer={null}
      bodyStyle={{ padding: 0 }}
      width={1024}
    >
      <div className={styles.modalBody}>
        <div className={styles.modalContent}>
          <div className={styles.modalContentContainer}>
            <img src={ARROW as string} alt="" className={styles.modalArrow} />
            <h2 className={styles.modalContentBG}>Need Assistance ?</h2>
            <p className={styles.modalContentBG}>
              Enhance your brand value with Influencer Marketing
            </p>
            <h3>
              <img src={LOOP as string} alt="" />
              Book a Demo Call
            </h3>
          </div>
        </div>
        {/* <!-- Calendly inline widget begin --> */}
        <div className={styles.modalCalender}>
          <InlineWidget
            url="https://calendly.com/cloutflow-rishikesh/30min?hide_event_type_details=1&hide_gdpr_banner=1"
            styles={{
              width: "100%",
              height: "600px",
            }}
          />
          {loader && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                zIndex: 9999,
              }}
            >
              <Spin />
            </div>
          )}
        </div>
        {/* <!-- Calendly inline widget end --> */}
      </div>
    </Modal>
  );
};

export default DemoCall;
