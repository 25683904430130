import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  User,
  updateProfile,
  reload,
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth";
import { useQueryClient } from "react-query";
import Cookies from "universal-cookie";

import { signInBackend } from "../handler/login";
import { sendOTP } from "../handler/auth/sendOTP";

import { auth } from "../firebase";

type ContextState = {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
  signup: (email: string, password: string) => Promise<UserCredential>;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  verifyEmail: () => Promise<void>;
  refetchUser: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUsername: (name: string) => Promise<void>;
  customSignIn: (token: string) => Promise<void>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  googleSignIn: () => Promise<UserCredential>;
  googleSignUp: () => Promise<UserCredential>;
};

const userAuthContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const UserAuthContextProvider: React.FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>("");
  const provider = new GoogleAuthProvider();

  const [searchParams] = useSearchParams();

  const cookies = new Cookies();

  const logout = async () => {
    await signOut(auth);
    localStorage.clear();
    cookies.remove("brandId", { path: "/" });

    window.location.replace("/");
  };

  const signup = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (userCred) => {
        // await sleep(5000);
        // if (!userCred?.user?.emailVerified) await verifyEmail();
        return userCred;
      }
    );
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password).then(
      async (user) => {
        // await sleep(5000);
        // if (!user?.user?.emailVerified) await verifyEmail();
        return user;
      }
    );
    //call backend ApI
  };

  const verifyEmail = () => {
    // const actionCodeSettings = {
    //   url: process.env.REACT_APP_DEPLOYMENT_URL || "",
    //   handleCodeInApp: true,
    // };

    // return sendEmailVerification(auth.currentUser!, actionCodeSettings).then(
    //   () => {}
    // );
    return sendOTP();
  };

  const updateUsername = async (name: string) => {
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, { displayName: name });
    }
  };

  const refetchUser = () => {
    return reload(auth.currentUser!);
  };

  const googleSignUp = () => {
    return signInWithPopup(auth, provider).then(async (userCred) => {
      if (!userCred.user.emailVerified) {
        await verifyEmail();
      }
      return userCred;
    });
  };

  const googleSignIn = () => {
    return signInWithPopup(auth, provider).then(async (userCred) => {
      if (!userCred.user.emailVerified) {
        await verifyEmail();
      }
      return userCred;
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setCurrentUser(currentUser);
      setLoading(false);

      if (currentUser) {
        // console.log("onAuthSignedIn", searchParams.get("hash"));
        const response = await signInBackend(
          "",
          searchParams.get("hash") || undefined
        ).catch((err) => console.log("backend err", err));

        if (response) {
          await currentUser!.getIdToken(true);
          // await currentUser.reload();
          queryClient.setQueryData(["user"], response);
          cookies.set("__session", JSON.stringify({ isLoggedIn: true }), {
            domain: "cloutflow.com",
            maxAge: 360 * 24 * 60 * 60,
          });
        }
      } else {
        cookies.remove("brandId", { path: "/" });
        cookies.set("__session", JSON.stringify({ isLoggedIn: false }), {
          domain: "cloutflow.com",
          maxAge: 360 * 24 * 60 * 60,
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const resetPassword = (email: string) => {
    const actionCodeSettings = {
      url: process.env.REACT_APP_DEPLOYMENT_URL || "",
      handleCodeInApp: true,
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  const customSignIn = (token: string) => {
    return signInWithCustomToken(auth, token)
      .then(() => {
        console.log("signed in custom token");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <userAuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        signup,
        login,
        loading,
        logout,
        verifyEmail,
        refetchUser,
        status,
        setStatus,
        googleSignIn,
        updateUsername,
        resetPassword,
        googleSignUp,
        customSignIn,
      }}>
      {!loading && children}
    </userAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  const context = useContext(userAuthContext);

  if (!context) {
    throw new Error("useAuth must be used in <AuthProvider />");
  }

  return context;
};
