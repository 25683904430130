import React from "react";
import clsx from "clsx";
import { Popover, Space } from "antd";
import { FiInfo } from "react-icons/fi";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

import styles from "./styles.module.css";

interface BlockEntryProps {
  isSelected: boolean;
  title: string;
  desc: string;
  onClick: Function;
}

const BlockEntry: React.FC<BlockEntryProps> = ({
  isSelected,
  title,
  desc,
  onClick,
}) => {
  return (
    <div
      className={clsx(styles.container, { [styles.selected]: isSelected })}
      onClick={() => onClick()}>
      <Space align="center" size={10}>
        {title}
        <Popover
          content={desc}
          title="Information"
          trigger="hover"
          placement="left"
          arrowPointAtCenter>
          <FiInfo size={12} color={isSelected ? "#533AF5" : "#C7C7C7"} />
        </Popover>
      </Space>
      {isSelected ? (
        <BsEyeFill size={15} color="#533AF5" />
      ) : (
        <BsEyeSlashFill size={15} color="#C7C7C7" />
      )}
    </div>
  );
};

export default BlockEntry;
