export const convertAlgoliaToBackendUser = (
  item: any,
  platform: "instagram" | "youtube"
) => {
  return {
    _id: item?.objectID || "",
    brandId: "",
    campaignId: "",
    platform: platform,
    socialMediaId: item?.socialMediaId || "",
    influencerId: item?.influencerId || "",
    socialMediaKitId: item?.socialMediaId || "",
    status: "",
    statusHistory: [
      {
        status: "",
        dateTime: "",
      },
    ],
    statusLastChangedBy: "",
    brandPrice: 0,
    createdDateTime: "",
    lastUpdatedDateTime: "",
    searchAbleParams: "",
    __v: 0,
    socialMediaKit: {
      _id: item?.socialMediaKitId || "",
      socialMediaName: item?.username || "",
      contentCount: item?.contentCount || 0,
      followerCount: item?.followers || 0,
      influencerName: item?.name || "",
      profilePicUrl: item?.profilePicUrl || "",
      socialMediaId: item?.socialMediaId || "",
      connectionStatus: item?.connectionStatus,
    },
    noOfMedias: item?.contentCount || 0,
  };
};
