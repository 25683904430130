import React, { useEffect, useState } from "react";
import { Space, Row, Col } from "antd";
import clsx from "clsx";

import CreatorSection from "../CreatorSection";
import DeliverableSelect from "./DeliverableSelect";
import Option from "../../../../../../shared/UI/Option";

import styles from "./styles.module.css";

import { CreatorType, SelectPost } from "..";

type ProductViewProps = {
  creator: CreatorType;
  selectedPosts: SelectPost[];
  setSelectedPosts: React.Dispatch<React.SetStateAction<SelectPost[]>>;
  platform: "instagram" | "youtube";
};

const ProductView: React.FC<ProductViewProps> = ({
  creator,
  selectedPosts,
  setSelectedPosts,
  platform,
}) => {
  const [delType, setDelType] = useState<"post" | "story" | "video">("video");

  const imageUrl =
    "https://fastly.picsum.photos/id/44/200/200.jpg?hmac=W5KcqhapHjBgEIHGQpQnX6o9jdOXQEVCKEdGIohjisY";

  return (
    <div className={styles.grid}>
      <CreatorSection creator={creator} />

      {platform === "instagram" && (
        <div>
          <h3>Select the type of deliverable</h3>
          <Space>
            <Option
              title="Post/Reel"
              isSelected={delType === "post"}
              handleClick={() => setDelType("post")}
              key="1"
            />
            <Option
              title="Story"
              isSelected={delType === "story"}
              handleClick={() => setDelType("story")}
              key="2"
            />
          </Space>
        </div>
      )}
      <div id="postSelect">
        <h3>
          Select the {delType} to add ({selectedPosts.length} selected){" "}
        </h3>
        <Row
          gutter={[8, 8]}
          style={{ height: "30vh", overflowY: "scroll", overflowX: "hidden" }}>
          {new Array(20).fill("_").map((_, idx) => {
            return (
              <Col span={6}>
                <DeliverableSelect
                  url={imageUrl}
                  id={String(idx)}
                  isSelected={
                    selectedPosts.findIndex(
                      (item) => item.id_ === String(idx)
                    ) !== -1
                  }
                  type="image"
                  handleClick={() => {
                    if (
                      selectedPosts.findIndex(
                        (item) => item.id_ === String(idx)
                      ) === -1
                    ) {
                      return setSelectedPosts((selectedPosts) => [
                        ...selectedPosts,
                        { id_: String(idx), url: imageUrl },
                      ]);
                    }

                    return setSelectedPosts((selectedPosts) =>
                      selectedPosts.filter((item) => item.id_ !== String(idx))
                    );
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default ProductView;
