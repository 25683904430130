import React, { useState, useEffect } from "react";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { FiPieChart } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";

import { Row, Space, Divider } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useSearchParams } from "react-router-dom";

import { useQuery } from "react-query";
import Lottie from "react-lottie";

import ReportButton from "../../ReportButton";
import CustomDropDown from "../../../../shared/Custom/DropDown";
import DeliverableSelect from "../../Modals/SharedComponents/DeliverableSelect";

import AddReport from "../../Modals/AddReport";
import DeleteInfluencer from "../../Modals/DeleteInfluencer";
import DeleteReport from "../../Modals/DeleteReport";
import EditCommercial from "../../Modals/EditCommercial";

import animationData from "../../../../assets/lottie/threeDotLoading.json";

import { getInfluencerMedia } from "../../../../../handler/report";

import { CreatorReport, YtMediaResponseType } from "../../../../../typings";

import styles from "./styles.module.css";

import InfluencerDrawer from "../../ReportScreen/InfluencerDrawer";
import func from "../../../../../utils/socialMediaUrlGen";

const panelVariants = {
  visible: {
    height: "initial",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const CreatorCard: React.FC<{
  creatorData: CreatorReport;
  campaignId: string;
  platform: "instagram" | "youtube";
}> = ({ creatorData, campaignId, platform }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [creatorProfileVisible, setCreatorProfileVisible] =
    useState<boolean>(false);
  const [reportVisible, setReportVisible] = useState<boolean>(false);
  const [reportDeleteVisible, setReportDeleteVisible] =
    useState<boolean>(false);
  const [influencerDeleteVisible, setInfluencerDeleteVisible] =
    useState<boolean>(false);
  const [commercialVisible, setCommercialVisible] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [getMediaKey, getMediaFn] = getInfluencerMedia({
    campaignId: creatorData.campaignId,
    socialMediaId: creatorData.socialMediaId,
  });

  const { data, isLoading } = useQuery(getMediaKey, getMediaFn, {
    enabled: toggle,
  });

  useEffect(() => {
    if (creatorData.socialMediaId === searchParams.get("influencer")) {
      setToggle(true);
    }
  }, [searchParams.get("influencer")]);

  return (
    <React.Fragment>
      <div
        className={styles.container}
        onClick={() => {
          return setCreatorProfileVisible(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <Row justify="space-between" align="middle">
          <Space size={10}>
            <img
              src={creatorData?.socialMediaKit?.profilePicUrl}
              alt="creator"
              className={styles.image}
            />
            <div>
              <p className={styles.name}>
                {creatorData?.socialMediaKit?.influencerName}
              </p>
              <Space style={{ height: "max-content" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {creatorData?.platform === "instagram" ? (
                    <AiOutlineInstagram size={20} color="#333333" />
                  ) : (
                    <AiOutlineYoutube size={20} color="#333333" />
                  )}
                </div>
                <p className={styles.username}>
                  <a
                    style={{ color: "#333" }}
                    href={func({
                      userNameOrChannelId:
                        creatorData?.socialMediaKit?.socialMediaName,
                      platform: platform,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {creatorData?.socialMediaKit?.socialMediaName}
                  </a>
                </p>
              </Space>
            </div>
          </Space>
          <Space size={25} align="center">
            <div>
              <p className={styles.metricLabel}>Media Added</p>
              <p className={styles.metric}> {creatorData.noOfMedias}</p>
            </div>
            <div>
              <p className={styles.metricLabel}>Total Price</p>
              <p className={styles.metric}> {creatorData.brandPrice}</p>
            </div>
            <Space>
              <ReportButton
                id="btn"
                type="default"
                isOutline
                onClick={(e) => {
                  setReportVisible(true);
                  e.stopPropagation();
                }}
              >
                <Space
                  size={5}
                  align="center"
                  style={{ height: "max-content" }}
                >
                  <HiPlus size={14} />
                  Add Media
                </Space>
              </ReportButton>
              <ReportButton
                type="primary"
                onClick={(e) => {
                  window.open(
                    `/campaign/manage/report/${campaignId}/${creatorData?.socialMediaId}`
                  );
                  e.stopPropagation();
                }}
              >
                <Space size={5} align="center">
                  <FiPieChart size={12} />
                  Open Report
                </Space>
              </ReportButton>
            </Space>
          </Space>
        </Row>
        <Divider style={{ margin: "20px" }} />
        <Row justify="space-between">
          <Space align="center">
            <ReportButton
              ghost
              style={{ gap: "5px" }}
              onClick={(e) => {
                setToggle((toggle) => !toggle);
                e.stopPropagation();
              }}
            >
              {toggle ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  Hide Media
                  <RxCaretUp style={{ color: "#533AF5" }} size={20} />
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  View Media
                  <RxCaretDown style={{ color: "#533AF5" }} size={20} />
                </div>
              )}
            </ReportButton>
            {toggle && (
              <p className={styles.metricLabel}>
                Media’s added to report for this campaign
              </p>
            )}
          </Space>
          <CustomDropDown
            items={[
              {
                label: "Remove Media",
                key: "2",
                onClick: (e) => {
                  setReportDeleteVisible(true);
                  e.domEvent.stopPropagation();
                },
              },
              {
                label: "Edit Price",
                key: "3",
                onClick: (e) => {
                  setCommercialVisible(true);
                  e.domEvent.stopPropagation();
                },
              },
              {
                label: "Delete from campaign",
                key: "4",
                onClick: (e) => {
                  setInfluencerDeleteVisible(true);
                  e.domEvent.stopPropagation();
                },
              },
            ]}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className={styles.optionsContainer}
            >
              Actions
              <BsThreeDotsVertical color="#533AF5" size={10} />
            </a>
          </CustomDropDown>
        </Row>

        <AnimatePresence>
          {toggle && (
            <motion.section
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={panelVariants}
              style={{ padding: "4px", margin: "12px 0" }}
            >
              <div style={{}}>
                {isLoading ? (
                  <Lottie options={defaultOptions} height={50} width={100} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "40px",
                      overflowY: "scroll",
                      paddingBottom: "5px",
                    }}
                    className={styles.check}
                  >
                    {data?.data.map((item, i) => {
                      return (
                        <>
                          <DeliverableSelect
                            isLabelClickEnabled={true}
                            isSelected={false}
                            handleClick={() => {
                              return window.open(
                                `/campaign/manage/report/${campaignId}/${item?.socialMediaId}?mediaId=${item?.deliverableMediaId}
                               `,
                                "_blank"
                              );
                            }}
                            id={item?._id}
                            label={item?.deliverableType}
                            url={item?.media?.savedMedias[0]?.url}
                            key={item?._id}
                            type={
                              platform === "instagram"
                                ? item.media?.savedMedias[0]?.mediaType
                                : "yt-video"
                            }
                            permaLink={item?.media?.permalink}
                            caption={
                              platform === "youtube"
                                ? (item?.media as YtMediaResponseType)?.title
                                : undefined
                            }
                            profilePic={
                              creatorData?.socialMediaKit?.profilePicUrl || ""
                            }
                          />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
      <AddReport
        visible={reportVisible}
        setVisible={setReportVisible}
        creator={creatorData}
        campaignId={campaignId}
        platform={platform}
      />
      <DeleteReport
        visible={reportDeleteVisible}
        setVisible={setReportDeleteVisible}
        creator={creatorData}
        campaignId={campaignId}
        platform={platform}
      />
      <DeleteInfluencer
        visible={influencerDeleteVisible}
        setVisible={setInfluencerDeleteVisible}
        creator={creatorData}
        campaignId={campaignId}
        platform={platform}
      />
      <EditCommercial
        visible={commercialVisible}
        setVisible={setCommercialVisible}
        creator={creatorData}
        campaignId={campaignId}
        platform={platform}
      />
      <InfluencerDrawer
        visible={creatorProfileVisible}
        setVisible={setCreatorProfileVisible}
        campaignId={campaignId}
        platform={platform}
        socialMediaId={creatorData.socialMediaId}
        deliverableMediaId={""}
      />
    </React.Fragment>
  );
};

export default CreatorCard;
