import { ResultItem } from "../../typings";
import {
  IGFilters,
  YtFilters,
  TikTokFilters,
} from "../../typings/CreatorSearch";
import { sendSearchRequest } from "../searchHelper";

export interface InsightsIqQuickSearchResponse {
  status: string;
  message: string;
  data: {
    data: ResultItem;
    metadata: {
      offset: number;
      limit: number;
      total_results: number;
    };
  };
}

const insightsiqPlatformWorkIdMap = {
  tiktok: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  instagram: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  youtube: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  twitter: "7645460a-96e0-4192-a3ce-a1fc30641f72",
  twitch: "e4de6c01-5b78-4fc0-a651-24f44134457b",
  facebook: "ad2fec62-2987-40a0-89fb-23485972598c",
  substack: "fbf76083-710b-439a-8b8c-956f607ef2c1",
  instagramLite: "fb7cafcf-60ed-468a-ad6d-6bb6899d0871",
  adSense: "3e6f4cdd-2119-4125-aa0d-86eee4e20fcc",
  spotify: "1db0b86c-a547-4119-9834-da927171a685",
  linkedIn: "36410629-f907-43ba-aa0d-434ca9c0501a",
  beehiiv: "24fcaa09-0a79-4deb-b074-d8efd900265b",
};

export const getQuickSearchCreators = ({
  body,
}: {
  body: { [key: string]: any };
}) => {
  const dataConverter = () => {
    let tempObj: { [key: string]: any } = {};
    if (body["searchType"] && body["searchType"] === "search-by-profile") {
      tempObj = {
        ...tempObj,
        platform_username: {
          value: body["username"],
          operator: "PREFIX",
        },
      };
      tempObj = {
        ...tempObj,
        sort_by: { field: "AVERAGE_LIKES", order: "DESCENDING" },
      };
    } else {
      Object.keys(body || {}).forEach((item) => {
        const igFilter =
          body?.work_platform_id === insightsiqPlatformWorkIdMap["instagram"]
            ? IGFilters.find((igItem) => igItem.facetName === item)
            : body?.work_platform_id === insightsiqPlatformWorkIdMap["youtube"]
            ? YtFilters.find((igItem) => igItem.facetName === item)
            : TikTokFilters.find((igItem) => igItem.facetName === item);

        if (igFilter && igFilter.facetName === "subscriber_count") {
          tempObj = {
            ...tempObj,
            subscriber_count: {
              min: body[item]["min"],
              max: body[item]["max"],
            },
          };
        }

        if (igFilter && igFilter.facetName === "follower_count") {
          tempObj = {
            ...tempObj,
            follower_count: { min: body[item]["min"], max: body[item]["max"] },
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "topics" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            topic_relevance: {
              name: body[item],
              weight: 0.5,
              threshold: 0.55,
            },
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "creator_locations" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            creator_locations: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "description_keywords" &&
          body[item] &&
          body[item] !== ""
        ) {
          tempObj = {
            ...tempObj,
            description_keywords: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "bio_phrase" &&
          body[item] &&
          body[item] !== ""
        ) {
          tempObj = {
            ...tempObj,
            bio_phrase: body[item],
          };
        }

        if (igFilter && igFilter.facetName === "content_count") {
          tempObj = {
            ...tempObj,
            content_count: { min: body[item]["min"], max: body[item]["max"] },
          };
        }

        if (igFilter && igFilter.facetName === "creator_gender" && body[item]) {
          tempObj = {
            ...tempObj,
            creator_gender: body[item],
          };
        }

        if (igFilter && igFilter.facetName === "creator_age") {
          tempObj = {
            ...tempObj,
            creator_age: { min: body[item]["min"], max: body[item]["max"] },
          };
        }

        if (igFilter && igFilter.facetName == "is_verified") {
          tempObj = {
            ...tempObj,
            is_verified: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "last_post_timestamp" &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            last_post_timestamp: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "creator_language" &&
          body[item] &&
          body[item] !== ""
        ) {
          tempObj = {
            ...tempObj,
            creator_language: {
              code: body[item],
            },
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "creator_interests" &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            creator_interests: [body[item]],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "creator_account_type" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            creator_account_type: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "hashtags" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            hashtags: body[item]?.map((item: any) => ({ name: item })),
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "mentions" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            mentions: body[item]?.map((item: any) => ({ name: item })),
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "audience_locations" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            audience_locations:
              body[item]?.map(
                (ele: { value: string | number; percentage: number }) => {
                  return {
                    location_id: ele.value,
                    percentage_value: ele.percentage || 1,
                    operator: "GT",
                  };
                }
              ) || [],
          };
        }

        if (igFilter && igFilter.facetName === "audience_age" && body[item]) {
          tempObj = {
            ...tempObj,
            audience_age: { min: body[item]["min"], max: body[item]["max"] },
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "audience_gender" &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            audience_gender: {
              type: body[item]?.value || "",
              operator: "GT",
              percentage_value: body[item]?.percentage || 1,
            },
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "audience_language" &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            audience_language: [
              {
                code: body[item].value,
                percentage_value: body[item].percentage || 1,
              },
            ],
          };
        }

        if (
          igFilter &&
          (igFilter.facetName === "audience_brand_affinities" ||
            igFilter.facetName === "creator_brand_affinities") &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            [item]: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "audience_interest_affinities" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            audience_interest_affinities:
              body[item]?.map(
                (ele: { value: string | number; percentage: number }) => {
                  return {
                    value: ele.value,
                    operation: "GT",
                    percentage_value: ele.percentage || 1,
                  };
                }
              ) || [],
          };
        }

        if (
          igFilter &&
          (igFilter.facetName === "average_likes" ||
            igFilter?.facetName === "average_views")
        ) {
          tempObj = {
            ...tempObj,
            [item]: { min: body[item]["min"], max: body[item]["max"] },
          };
        }

        if (igFilter && igFilter.facetName === "engagement_rate") {
          tempObj = {
            ...tempObj,
            engagement_rate: {
              percentage_value: body[item] || 1,
            },
          };
        }

        if (igFilter && igFilter.facetName == "has_sponsored_posts") {
          tempObj = {
            ...tempObj,
            has_sponsored_posts: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName == "brand_sponsors" &&
          body[item]?.length > 0
        ) {
          tempObj = {
            ...tempObj,
            brand_sponsors: body[item],
          };
        }

        if (
          igFilter &&
          igFilter.facetName === "instagram_options_reel_views" &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            instagram_options: {
              reel_views: { min: body[item]["min"], max: body[item]["max"] },
            },
          };
        }

        if (
          igFilter &&
          (igFilter.facetName === "subscriber_growth" ||
            igFilter.facetName === "follower_growth") &&
          body[item]
        ) {
          tempObj = {
            ...tempObj,
            [item]: {
              interval: body[item].value,
              interval_unit: "MONTH",
              operator: "GT",
              percentage_value: body[item].percentage,
            },
          };
        }

        if (
          igFilter &&
          (igFilter.facetName === "audience_lookalikes" ||
            igFilter?.facetName === "creator_lookalikes") &&
          body[item] &&
          body[item] !== ""
        ) {
          tempObj = {
            ...tempObj,
            [item]: body[item],
          };
        }
      });

      if (
        Object.keys(body || {}).includes("specific_contact_details") &&
        body["specific_contact_details"]?.length > 0
      ) {
        tempObj = {
          ...tempObj,
          specific_contact_details: body["specific_contact_details"]?.map(
            (ele: string) => ({
              type: ele,
              preference: "SHOULD_HAVE",
            })
          ),
        };
      }

      if (
        Object.keys(body || {}).includes("has_contact_details") &&
        body["has_contact_details"]
      ) {
        tempObj = {
          ...tempObj,
          has_contact_details: true,
        };
      }

      tempObj = {
        ...tempObj,
        sort_by: body?.sort_by || undefined,
        limit: body?.limit || 10,
        offset: body?.offset || 0,
      };
    }

    // console.log(tempObj, "sdsdsds", body);
    return tempObj;
  };
  const queryKey = [
    "insights-iq-quick-search",
    body?.work_platform_id,
    dataConverter(),
  ];

  const queryFunction = () =>
    sendSearchRequest<InsightsIqQuickSearchResponse>(
      "post",
      "/insightsIq/by-pass?url=/v1/social/creators/profiles/quick-search",
      false,
      {
        ...dataConverter(),
        work_platform_id: body?.work_platform_id || "",
      }
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
