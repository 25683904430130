import { sendRequest } from "./helper";

interface editUserDetailsProps {
  orgId: string;
}

export const leaveOrg = (inputProps: editUserDetailsProps) => {
  return sendRequest(
    "put",
    `/brand/organisation/leave?brandOrgRoleId=${inputProps?.orgId}`,
    false,
    {
      ...inputProps,
    }
  ).then((res) => res.data);
};
