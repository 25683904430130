import React, { useState, useEffect } from "react";
import { Col, Row, Empty, Pagination } from "antd";
import { useQuery } from "react-query";
import Lottie from "react-lottie";

import animationData from "../../../assets/lottie/Search.json";
import threeDotAnimationData from "../../../assets/lottie/threeDotLoading.json";

import CreatorCard from "../../CreatorCard";

import {
  getAllApplicants,
  getApplicantCount,
} from "../../../../handler/campaign/applicants";

import { getDefaultLottieOptions } from "../../../../utils";

import Search from "../../Report/Modals/SharedComponents/Search";

const SearchPage = ({ campaignId }: { campaignId: string }) => {
  const [text, setText] = useState<string>("");

  const [getApplicantCountKey, getApplicantCountFn] = getApplicantCount({
    campaignId,
    status: "",
    text,
  });

  const [page, setPage] = useState<number>(1);

  const [getAllApplicantsKey, getAllApplicantsFn] = getAllApplicants({
    campaignId,
    pageNumber: page,
    sortOrder: "Asc",
    sortBy: "none",
    status: "",
    text,
  });

  const { data: applicantsData, isLoading: isLoadingApplicants } = useQuery(
    getAllApplicantsKey,
    getAllApplicantsFn,
    {
      enabled: Boolean(text.trim()),
    }
  );

  const { data: countData, isLoading: countLoading } = useQuery(
    getApplicantCountKey,
    getApplicantCountFn,
    {
      enabled: Boolean(text.trim()),
    }
  );

  useEffect(() => {
    setPage(1);
  }, [text]);

  const getApplicantType = (status: string) => {
    switch (status) {
      case "Applied":
        return "appRec";
      case "Shortlisted":
        return "shortList";
      case "Invited":
        return "invite";
      case "Rejected":
        return "reject";
      case "Finalized":
        return "appRec";
      case "Completed":
        return "appRec";
      default:
        return "appRec";
    }
  };

  return (
    <Col span={19} style={{ padding: "20px 0 0 20px" }}>
      {/* <Title style={{ fontWeight: 500 }} level={2}>
        Global Search
      </Title> */}
      <p
        style={{
          color: "rgba(0, 0, 0, 0.60)",
          fontFamily: "Inter",
          fontSize: 22,
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          marginBottom: 20,
        }}>
        Search for the influencer by name or username
      </p>
      <Col span={24}>
        <Search searchQuery={text} setSearchQuery={setText} />
      </Col>

      {text.trim() === "" || countLoading || isLoadingApplicants ? (
        <Row justify="center">
          <Lottie
            style={{
              margin: "20px 0",
            }}
            options={getDefaultLottieOptions(
              Boolean(text.trim()) ? threeDotAnimationData : animationData
            )}
            height={300}
            width={300}
          />
        </Row>
      ) : (
        <>
          {applicantsData?.data && applicantsData?.data.length > 0 ? (
            <>
              <div
                style={{
                  display: "grid",
                  gap: 10,
                  marginTop: 20,
                  height: "60vh",
                  overflowY: "scroll",
                }}>
                {applicantsData!.data.map((applicant) => {
                  return (
                    <Col span={19} style={{ height: "180px" }}>
                      <CreatorCard
                        key={applicant._id}
                        type={getApplicantType(applicant.status)}
                        applicantData={applicant}
                        isCardChecked={false}
                        handleCheck={(id) => {}}
                        isDisabled={false}
                        isProcessing={applicant.paymentStatus === "Processing"}
                        isCheckable={false}
                        isSearch
                      />
                    </Col>
                  );
                })}
              </div>
              {Boolean(countData?.data) && (
                <Col span={19}>
                  <Row justify="center">
                    <Pagination
                      total={countData?.data}
                      style={{ margin: "46px auto" }}
                      onChange={(page, _) => setPage(page)}
                      pageSize={15}
                      showSizeChanger={false}
                      current={page}
                    />
                  </Row>
                </Col>
              )}
            </>
          ) : (
            <>
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                style={{ marginTop: "20%" }}
                description={
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                      marginTop: "10px",
                    }}>
                    No Applicants
                  </p>
                }
              />
            </>
          )}
        </>
      )}
    </Col>
  );
};

export default SearchPage;
