import React from "react";

import { Tag } from "antd";

interface CHIPProps {
  handleCloseFilter: (filterName: string, itemName?: string) => void;
  label: string;
  isArrayItem?: boolean;
  filterName: string;
}

const Chip: React.FC<CHIPProps> = ({
  handleCloseFilter,
  label,
  isArrayItem,
  filterName,
}) => {
  return (
    <Tag
      color="white"
      style={{
        height: "34px",
        borderRadius: "30px",
        padding: "6px 12px",
        backgroundColor: "#2130FF",
        color: "#ffffff",
      }}
      closable
      onClose={() =>
        handleCloseFilter(filterName, isArrayItem ? label : undefined)
      }
      key={label}>
      {label}
    </Tag>
  );
};

export default Chip;
