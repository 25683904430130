import { sendRequest } from "../helper";
import { sendSearchRequest } from "../searchHelper";

export type UserResult = {
  status: string;
  message: string;
  data: {
    name: string;
    value: string;
    display_name?: string;
    type: string;
  }[];
};

export const getFacetValues = ({
  facetName,
  body,
  query = "",
  work_platform_id = "",
}: {
  facetName: string;
  body: { [key: string]: any };
  query?: string;
  work_platform_id?: string;
}) => {
  const queryKey = ["facets", facetName, body, query];
  const queryFunction = () =>
    sendSearchRequest<UserResult>("post", "/insightsIq/facets-values", false, {
      facetName,
      ...body,
      text: query,
      work_platform_id,
    }).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
