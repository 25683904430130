import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Row, Col, Space, Pagination, Input, message } from "antd";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";

import ReportModal from "../../ReportModal";
import Search from "../SharedComponents/Search";
import CreatorCard from "../SharedComponents/CreatorCard";
import animationData from "../../../../assets/lottie/Search.json";
import dotAnimationData from "../../../../assets/lottie/threeDotLoading.json";
import AddReport from "../AddReport";

import { CreatorReport } from "../../../../../typings";

import styles from "./styles.module.css";
import ReportButton from "../../ReportButton";

import {
  upsertInfluencer,
  getCampaignAggregate,
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
  getCampaignReport,
  getInfluencerReportStatus,
  getInfluencerCommunityRelation,
} from "../../../../../handler/report";

import { getInfluencersAlgolia } from "../../../../../handler/algoliaSearch/getInfluencersAlgolia";
import { getUser } from "../../../../../handler/getUser";

import { convertAlgoliaToBackendUser } from "./utils/convertAlgoliatoBackendUser";
import { getDefaultLottieOptions } from "../../../../../utils";

interface AddInfluencerModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const AddInfluencerModal: React.FC<AddInfluencerModalProps> = ({
  visible,
  campaignId,
  setVisible,
  platform,
}) => {
  const [creator, setCreator] = useState<CreatorReport | null>(null);
  const [reportVisible, setReportVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setCreator(null);
    }
  }, [visible]);

  return (
    <>
      <ReportModal
        visible={visible}
        centered
        maskClosable={false}
        onCancel={() => setVisible(false)}
        footer={null}>
        <Child
          setVisible={setVisible}
          campaignId={campaignId}
          platform={platform}
          creator={creator}
          setCreator={setCreator}
          setReportVisible={setReportVisible}
        />
      </ReportModal>
      {creator && (
        <AddReport
          visible={reportVisible}
          setVisible={setReportVisible}
          creator={creator!}
          campaignId={campaignId}
          platform={platform}
        />
      )}
    </>
  );
};

export default AddInfluencerModal;

const Child = ({
  setVisible,
  campaignId,
  platform,
  setCreator,
  creator,
  setReportVisible,
}: {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setReportVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCreator: React.Dispatch<React.SetStateAction<CreatorReport | null>>;
  creator: CreatorReport | null;
  campaignId: string;
  platform: "instagram" | "youtube";
}) => {
  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });
  const navigate = useNavigate();

  const [campaignKey] = getCampaignReport({ campaignId });

  const queryClient = useQueryClient();

  const [queryKey] = getCampaignAggregate({ campaignId });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [userKey, userFn] = getUser();

  const { data: userData, isLoading: isLoadingUserData } = useQuery(
    userKey,
    userFn
  );

  const [getInfluencersKey, getInfluencersFn] = getInfluencersAlgolia({
    platform,
    query: searchQuery,
    brandId: userData?.data?._id || "",
    isManagedAccount: userData?.data?.accountType === "Managed",
  });

  const { data: algoliaUserData, isLoading } = useQuery(
    getInfluencersKey,
    getInfluencersFn,
    {
      enabled: searchQuery.trim() !== "" && userData?.data?._id !== "",
    }
  );

  const [influencerStatusKey, influencerStatusFn] = getInfluencerReportStatus({
    campaignId,
    socialMediaId: creator?.socialMediaKit?.socialMediaId || "",
  });
  // const [influencerCommunityRelationKey, influencerCommunityRelationFn] =
  //   getInfluencerCommunityRelation({
  //     socialMediaId: creator?.socialMediaKit?.socialMediaId || "",
  //   });

  const { data } = useQuery(influencerStatusKey, influencerStatusFn, {
    enabled: Boolean(creator?.socialMediaKit?.socialMediaId),
  });
  // const { data: relationData, isLoading: relationLoading } = useQuery(
  //   influencerCommunityRelationKey,
  //   influencerCommunityRelationFn,
  //   {
  //     enabled: Boolean(creator?.socialMediaKit?.socialMediaId),
  //   }
  // );

  const [commercial, setCommercial] = useState<number | undefined>(undefined);
  const editUserMutation = useMutation(upsertInfluencer, {
    onSuccess: () => {
      message.success("influencer Added");
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries(campaignKey);
    },
    onError: (err) => {
      message.error("Something went wrong");
      console.log(err);
    },
  });

  const memoizedCreatorCard = useCallback(
    (itemCreator: any) => {
      return (
        <CreatorCard
          creator={convertAlgoliaToBackendUser(itemCreator, platform)}
          key={itemCreator?._id}
          handleClick={(creator: CreatorReport, price?: number) => {
            const element = document.getElementById(creator._id);
            setCreator(creator);
            setCommercial(price);
            // console.log(price);
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          isSelected={itemCreator?.socialMediaId === creator?.socialMediaId}
          campaignId={campaignId}
          inCampaign
        />
      );
    },
    [creator?._id]
  );

  useEffect(() => {
    setCreator(null);
  }, [searchQuery]);

  // const getErrorStatus = (accountStatus: string) => {
  //   switch (accountStatus) {
  //     case "Disconnected":
  //       return "is disconnected from our platform. Ask them to connect Again";
  //     case "Session-Expired":
  //       return "Facebook account session's has been expired. You cannot add it to campaign";

  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <div>
        <Row justify="start" style={{ marginTop: "0px", marginBottom: "40px" }}>
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              lineHeight: "normal",
              fontFamily: "Inter",
            }}>
            Add influencer to campaign
          </p>
        </Row>
        <main style={{ display: "grid", gap: "23px" }}>
          {searchQuery.trim() === "" && (
            <div style={{ marginBlock: 14 }}>
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.60)",
                  fontFamily: "Inter",
                  fontSize: 22,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}>
                Search for the influencer by
                <br /> name or username
              </p>
            </div>
          )}
          <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <Row gutter={0}>
            {searchQuery.trim() === "" ? (
              <>
                <Col
                  span={24}
                  offset={0}
                  style={{
                    textAlign: "left",
                  }}>
                  <Lottie
                    style={{
                      margin: "20px 0",
                    }}
                    options={getDefaultLottieOptions(animationData)}
                    height={136}
                    width={200}
                  />
                </Col>
                <Col span={24} offset={0}>
                  <>
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Manrope",
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}>
                      or Find and add influencers from{" "}
                      <Link
                        to="/discover"
                        style={{ textDecoration: "underline" }}>
                        discovery
                      </Link>{" "}
                    </span>
                  </>
                </Col>
              </>
            ) : (
              <>
                {isLoading ? (
                  <Col span={12} offset={5}>
                    <Lottie
                      options={getDefaultLottieOptions(dotAnimationData)}
                      height={250}
                      width={300}
                    />
                  </Col>
                ) : (
                  <>
                    <Col style={{ marginBottom: "20px" }}>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#000000",
                        }}>
                        You can only add the influencers that are part of your
                        community
                      </p>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#000000",
                        }}>
                        You need to add influencer to campaign to add their
                        reports.
                      </p>
                    </Col>
                    <div
                      style={{
                        display: "grid",
                        gap: "20px",
                        width: "100%",
                        maxHeight: "40vh",
                        overflowY: "auto",
                        height: "fit-content",
                      }}>
                      {algoliaUserData?.data?.hits?.map((item, idx) => (
                        <Col span={17}>{memoizedCreatorCard(item)}</Col>
                      ))}
                    </div>
                    <Col span={23}>
                      {(algoliaUserData?.data?.hits || []).length <= 0 && (
                        <Row
                          style={{
                            backgroundColor: "white",
                            padding: "30px 0px",
                          }}
                          justify="center">
                          <Space direction="vertical" align="center">
                            <img src="/assets/img/empty.png" alt="" />
                            <p style={{ fontWeight: 500, fontSize: "16px" }}>
                              No search results found
                            </p>
                          </Space>
                        </Row>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
          <div
            style={{
              marginTop: creator ? "20px" : 0,
            }}>
            {creator && (
              <div>
                <p className={styles.label}>
                  Add Price for {creator.socialMediaKit.influencerName}{" "}
                  (Optional)
                </p>
                <Input
                  addonBefore="$"
                  placeholder="Enter Price"
                  type="number"
                  className={styles.input}
                  size="large"
                  value={commercial}
                  onChange={(e) => {
                    if (e.target.value) {
                      return setCommercial(Number(e.target.value) || 0);
                    }
                    setCommercial(0);
                  }}
                />
                <p className={styles.subLabel}>
                  This is used to calculate CPV, CPC, etc
                </p>
              </div>
            )}
          </div>
          {/* {creator &&
            !Boolean(relationData?.data?.isInBrandCommunity) &&
            !relationLoading && (
              <p className={styles.error}>
                @{creator?.socialMediaKit?.socialMediaName} is not a part of
                your community. You need to{" "}
                <span
                  className={styles.link}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/invite")}>
                  {" "}
                  invite @{creator?.socialMediaKit?.socialMediaName}
                </span>{" "}
                to add them to campaign
              </p>
            )}
          {creator &&
            !Boolean(relationData?.data?.accountStatus === "Connected") &&
            !relationLoading && (
              <p className={styles.error}>
                @{creator?.socialMediaKit?.socialMediaName}{" "}
                {getErrorStatus(relationData?.data?.accountStatus || "")}
              </p>
            )} */}
          {creator && (
            <Row justify="end" gutter={10}>
              <ReportButton
                type="primary"
                // loading={editUserMutation.isLoading || relationLoading}
                loading={editUserMutation.isLoading}
                style={{ padding: "10px 20px" }}
                // disabled={
                //   !Boolean(relationData?.data?.isInBrandCommunity) ||
                //   !Boolean(relationData?.data?.accountStatus === "Connected")
                // }
                onClick={() =>
                  editUserMutation.mutate(
                    {
                      brandPrice: commercial || 0,
                      campaignId: campaignId,
                      socialMediaId: creator.socialMediaId,
                      socialMediaKitId: creator.socialMediaKit._id,
                    },
                    {
                      onSuccess: () => {
                        setReportVisible(true);
                        setVisible(false);
                      },
                    }
                  )
                }>
                {data?.data?.isInfluencerInCampaign
                  ? `Add Media for ${creator.socialMediaKit?.socialMediaName}`
                  : `
                Add ${creator.socialMediaKit?.socialMediaName} to Campaign
                `}
              </ReportButton>
            </Row>
          )}
        </main>
      </div>
    </>
  );
};
