import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row, Switch, Spin, message } from "antd";
import { FiCheck } from "react-icons/fi";
import { BsCheck2, BsChevronLeft } from "react-icons/bs";
import { HiArrowRight } from "react-icons/hi";
import { useQuery, useMutation } from "react-query";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import { auth } from "../../../firebase";

import styles from "./styles.module.css";

import MetricBox from "./MetricBox";
import PlanEntity from "./PlanEntity";

import { getAppState } from "../../../handler/getAppState";
import { getUser } from "../../../handler/getUser";

import {
  getPlanDetails,
  createCheckoutSession,
  getCustomerPortalLink,
  createAddonCheckoutSession,
  getAddOns,
} from "../../../handler/plan";

import { tierDetail } from "../../../typings";

import { formatCurrency } from "../../../utils";

const panelVariants = {
  visible: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
};

const KeyLabelArray = [
  "Discover connected creators",
  "Invite influencers",
  "Invite your team",
  "Customize Reports",
  "Invite your agency",
  "Export Your Data",
  "Community Size",
  "Credits per month",
];

type PlanProps = {};

const Plan: React.FC<PlanProps> = () => {
  const firstRender = useRef(true);
  const [view, setView] = useState<"current" | "all">("current");
  const [isAnnualChecked, setIsAnnualChecked] = useState<boolean>(false);
  const [userStateKey, userStateFn] = getAppState();
  const { data, isLoading, isSuccess } = useQuery(userStateKey, userStateFn);
  const currentCycle = data?.data?.tier?.cycle || "month";
  const firebaseUid = auth?.currentUser?.uid || "";

  const [addOnsKey, addOnFn] = getAddOns();

  const { data: addOnsData, isLoading: addOnsLoading } = useQuery(
    addOnsKey,
    addOnFn
  );

  const [userKey, userFn] = getUser();

  const { data: universalUserData } = useQuery(userKey, userFn);

  const isOwner = firebaseUid === (universalUserData?.data?._id || "");
  // console.log(isOwner, universalUserData?.data?._id, firebaseUid);

  const [planDetailsAllKey, planDetailsAllFn] = getPlanDetails({
    cycle: isAnnualChecked ? "year" : "month",
    isAll: true,
  });

  const { data: allPlansData, isLoading: isLoadingPlansData } = useQuery(
    planDetailsAllKey,
    planDetailsAllFn
  );

  const [otherPlansKey, otherPlansFn] = getPlanDetails({
    cycle: currentCycle,
    isAll: false,
  });

  const { data: otherPlansData, isLoading: isLoadingPlans } = useQuery(
    otherPlansKey,
    otherPlansFn,
    { enabled: Boolean(isSuccess) }
  );

  const [acc, setAcc] = useState([true, false, false]);

  const handleAcc = (x: number) => {
    if (acc[x]) setAcc((prev) => prev.map((item, idx) => false));
    else setAcc((prev) => prev.map((item, idx) => (idx === x ? true : false)));
  };

  const currentPlan = data?.data;

  const getDetailColumns = (column: tierDetail) => {
    return [
      column.featureLimit.canDiscoverCreator,
      column.featureLimit.canInviteCreator,
      column.featureLimit.canInviteTeamMembers,
      column.featureLimit.reportCustomize,
      column.featureLimit.canInviteAgencyMembers,
      column.featureLimit.canExportDataExcel,
      column.isCustomPlan ? "Custom" : column.featureLimit.community.maxLimit,
      column.isCustomPlan ? "Custom" : column.featureLimit.addMedia.maxLimit,
    ];
  };

  const createCheckoutSessionMutation = useMutation(createCheckoutSession, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Creating Checkout Session");
      console.log(err);
    },
  });
  const createCheckoutAddonSessionMutation = useMutation(
    createAddonCheckoutSession,
    {
      onSuccess: (res) => {
        window.open(res?.data, "_blank");
      },
      onError: (err) => {
        message.error("Something went wrong while Creating Checkout Session");
        console.log(err);
      },
    }
  );
  const getCustomerPortalLinkMutation = useMutation(getCustomerPortalLink, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Getting Link");
      console.log(err);
    },
  });

  const currentPlanIndex = data?.data?.tier?.index || 0;

  const getAction = (planHovered: tierDetail) => {
    if (planHovered?.isBasePlan || data?.data?.tier?.isCustomPlan) {
      return {
        onClick: () => {},
        isLoading: false,
        disabled: true,
      };
    }

    if (planHovered?.isCustomPlan) {
      return {
        // Open Book a Call Modal
        onClick: () =>
          window.open("https://cloutflow.com/book-a-demo", "_blank"),
        isLoading: false,
        disabled: !isOwner,
      };
    }

    if (!planHovered?.isCustomPlan && !planHovered?.isCustomPlan) {
      if (data?.data?.tier?.isBasePlan) {
        return {
          onClick: () =>
            createCheckoutSessionMutation.mutate({
              priceId: planHovered?.stripePriceId,
            }),
          isLoading: createCheckoutSessionMutation.isLoading,
          disabled: planHovered.index < currentPlanIndex || !isOwner,
        };
      }
      return {
        onClick: () => getCustomerPortalLinkMutation.mutate(),
        isLoading: getCustomerPortalLinkMutation.isLoading,
        disabled: planHovered.index < currentPlanIndex || !isOwner,
      };
    }

    return {
      onClick: () => {},
      isLoading: false,
      disabled: true,
    };
  };

  useEffect(() => {
    if (data?.data?.tier?.cycle && firstRender.current) {
      setIsAnnualChecked(data?.data?.tier?.cycle === "month" ? false : true);
      firstRender.current = false;
    }
  }, [firstRender.current, data]);

  if (isLoading || isLoadingPlans || addOnsLoading) {
    return (
      <Row justify="center" style={{ margin: "150px 0" }}>
        <Spin />
      </Row>
    );
  }

  return (
    <React.Fragment>
      {view === "current" && (
        <section className={styles.container}>
          <h1 className={styles.planHeading}>Your Plan</h1>
          <div className={styles.planBox}>
            <div className={styles.planCurrent}>
              <Row gutter={[30, 0]}>
                <Col span={8}>
                  <h2 className={styles.planTitle}>
                    {data?.data?.tier?.planName}
                  </h2>
                  <h6 className={styles.planDescription}>
                    {/* {data?.data?.currencySymbol} */}
                    {formatCurrency(
                      currentPlan?.tier?.price[
                        data?.data?.isIndia ? "inr" : "usd"
                      ] || "0",
                      data?.data?.isIndia ? "INR" : "USD"
                    )}
                    {/* {
                      currentPlan?.tier?.price[
                        data?.data?.isIndia ? "inr" : "usd"
                      ]
                    } */}{" "}
                    per {currentPlan?.tier?.cycle}
                  </h6>
                  <Row gutter={[0, 6]}>
                    {currentPlan?.tier?.description?.map((descItem) => {
                      return (
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: 6,
                          }}>
                          <FiCheck /> <span>{descItem}</span>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col span={16}>
                  <Row gutter={[30, 0]}>
                    {addOnsData?.data?.map((addOnItem) => (
                      <Col span={12}>
                        <MetricBox
                          buttonLoading={
                            createCheckoutAddonSessionMutation.isLoading
                          }
                          title={addOnItem?.name}
                          description={addOnItem?.description}
                          isOwner={isOwner}
                          maxLimit={
                            (data?.data?.tierPlanUsed?.featureLimit?.[
                              addOnItem?.addOnType
                            ]?.maxLimit || 1) +
                            (data?.data?.tierPlanUsed?.featureLimit?.[
                              addOnItem?.addOnType
                            ]?.addOnLimit || 0)
                          }
                          onClick={() =>
                            createCheckoutAddonSessionMutation.mutate({
                              priceId: addOnItem?.stripePriceId,
                            })
                          }
                          buttonText={
                            addOnItem?.addOnType === "community"
                              ? "Purchase More"
                              : "Add Media Credits"
                          }
                          occupany={
                            data?.data?.tierPlanUsed?.featureLimit?.[
                              addOnItem?.addOnType
                            ]?.usedLimit || 0
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
            <div>
              <div className={styles.otherPlanContainer}>
                <h3 className={styles.otherPlansHeading}>
                  Other plans to consider
                </h3>

                <Button type="text" onClick={() => setView("all")}>
                  <div className={styles.allPlans}>
                    <span className={styles.text}>See All Plans</span>
                    <HiArrowRight color="#808080" />
                  </div>
                </Button>
              </div>
              <div className={styles.otherPlans}>
                <Row gutter={[0, 36]}>
                  {otherPlansData?.data?.map((planItem) => (
                    <Col span={24}>
                      <PlanEntity
                        pricingModel={planItem}
                        key={planItem.index}
                        isOwner={isOwner}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </section>
      )}
      {view === "all" && (
        <>
          {isLoadingPlansData ? (
            <Row justify="center" style={{ margin: "150px 0" }}>
              <Spin />
            </Row>
          ) : (
            <>
              <header
                className={styles.allPlansContainer}
                style={{ paddingTop: 20 }}>
                <Button
                  style={{ paddingInline: 0 }}
                  type="text"
                  onClick={() => setView("current")}>
                  <h3 className={styles.allPlansHeading}>
                    <BsChevronLeft size={20} /> All Plans
                  </h3>
                </Button>

                <div className={styles.allPlans}>
                  <span>Pay Annually</span>
                  <Switch
                    style={{ backgroundColor: "#534af7" }}
                    size="small"
                    checked={isAnnualChecked}
                    onChange={(checked) => setIsAnnualChecked(checked)}
                  />
                </div>
              </header>
              <div className={styles.container}>
                <Row gutter={[0, 30]} style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Row>
                      <Col span={8} />
                      <Col span={16}>
                        <Row gutter={[30, 0]}>
                          {allPlansData?.data?.map((planItem) => (
                            <Col span={8}>
                              <h6 className={styles.title}>
                                {planItem.planName}
                              </h6>
                              <p className={styles.price}>
                                {planItem?.isCustomPlan ? (
                                  "Custom"
                                ) : (
                                  <>
                                    {" "}
                                    {formatCurrency(
                                      planItem.price[
                                        data?.data?.isIndia ? "inr" : "usd"
                                      ] || "0",
                                      data?.data?.isIndia ? "INR" : "USD"
                                    )}
                                    {/* {data?.data?.currencySymbol} */}
                                    {/* {
                                      planItem.price[
                                        data?.data?.isIndia ? "inr" : "usd"
                                      ]
                                    } */}{" "}
                                    per {planItem.cycle}
                                  </>
                                )}
                              </p>
                              {planItem.index === currentPlan?.tier.index ? (
                                <p className={styles.curr}>Current Plan</p>
                              ) : (
                                <Button
                                  type="primary"
                                  style={
                                    getAction(planItem).disabled
                                      ? {}
                                      : {
                                          borderRadius: 4,
                                          background: "rgba(83, 58, 245, 0.15)",
                                          color: "#533AF5",
                                          borderColor: "transparent",
                                        }
                                  }
                                  onClick={() => getAction(planItem).onClick()}
                                  loading={getAction(planItem).isLoading}
                                  disabled={getAction(planItem).disabled}>
                                  {planItem.planName !== "Enterprise"
                                    ? "Upgrade Plan"
                                    : "Contact us"}
                                </Button>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Row gutter={[24, 0]}>
                      <Col span={8}>
                        <Row gutter={[0, 16]}>
                          {KeyLabelArray.map((item, key) => (
                            <Col span={24} key={key}>
                              <p className={styles.perks}>{item}</p>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row gutter={[24, 0]}>
                          {allPlansData?.data?.map((item) => {
                            const mappedItem = getDetailColumns(item);
                            return (
                              <Col span={8}>
                                <Row gutter={[0, 16]}>
                                  {mappedItem.map((item, key) => (
                                    <Col span={24} key={key}>
                                      <p className={styles.perkTile}>
                                        {typeof item === "string" ||
                                        typeof item === "number" ? (
                                          item
                                        ) : item ? (
                                          <BsCheck2 />
                                        ) : (
                                          <RxCross2 />
                                        )}
                                      </p>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {/* Faqs */}
          <section className={styles.container}>
            <div style={{ marginTop: 40, marginBottom: 10 }}>
              <h6 className={styles.faqsHeading}>FAQ’s</h6>
              <div>
                <Row gutter={[0, 10]}>
                  <Col span={24} className={styles.accordian}>
                    <div
                      className={styles.acc_question}
                      onClick={() => handleAcc(0)}>
                      <p>How do refunds work?</p>
                      {!acc[0] ? (
                        <AiOutlinePlus size={20} />
                      ) : (
                        <AiOutlineMinus />
                      )}
                    </div>
                    <AnimatePresence>
                      {acc[0] && (
                        <motion.section
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={panelVariants}
                          style={{
                            padding: "4px",
                            margin: "12px 0",
                            willChange: "transform, opacity, height",
                          }}>
                          <div style={{}}>
                            Our refund policy is simple. If you believe you've
                            made a mistake signing up for a paid subscription
                            online or in-app, please contact us inside the app
                            or email team@cloutflow.com. We'll be happy to
                            refund you the full amount (not pro-rated) if you
                            downgrade your subscription within 72 hours of
                            signing up for monthly billing, or within 30 days of
                            signing up for annual billing. If you were invoiced
                            for members accidentally added to your workspace,
                            we're happy to provide a refund of the prorated
                            charges if you reach out within 72 hours of the
                            invoice date (please make sure the additional
                            members have been removed from your workspace when
                            writing in). And if for any reason you're unhappy
                            with Cloutflow, please let us know at
                            team@cloutflow.com — we'd love to hear your
                            feedback, and will be happy to work with you to
                            create the best experience possible.
                          </div>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </Col>
                  <Col
                    span={24}
                    className={styles.accordian}
                    onClick={() => handleAcc(1)}>
                    <div className={styles.acc_question}>
                      <p>Where can I find my invoices?</p>

                      {!acc[1] ? (
                        <AiOutlinePlus size={20} />
                      ) : (
                        <AiOutlineMinus />
                      )}
                    </div>
                    <AnimatePresence>
                      {acc[1] && (
                        <motion.section
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={panelVariants}
                          style={{ padding: "4px", margin: "12px 0" }}>
                          <div style={{}}>
                            Workspace admins can access, view, and download your
                            workspace's invoices by going to Settings & members
                            → Billing, then scrolling to Invoices. There you can
                            click on "View invoice" to see the invoice PDF.
                          </div>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </Col>
                  <Col
                    span={24}
                    className={styles.accordian}
                    onClick={() => handleAcc(2)}>
                    <div className={styles.acc_question}>
                      <p>What happens when I change plans?</p>
                      {!acc[2] ? (
                        <AiOutlinePlus size={20} />
                      ) : (
                        <AiOutlineMinus />
                      )}
                    </div>
                    <AnimatePresence>
                      {acc[2] && (
                        <motion.section
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={panelVariants}
                          style={{ padding: "4px", margin: "12px 0" }}>
                          <div style={{}}>
                            Upgrading your plan: Your account will be upgraded
                            and charged immediately. The amount due will be
                            reduced based on the percentage of the billing cycle
                            left at the time the plan was changed. Downgrading
                            your plan: Your account will be downgraded at the
                            end of your billing cycle.
                          </div>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </>
      )}
    </React.Fragment>
  );
};
export default Plan;
