import React from "react";
import { Typography, Row, Col, Card, Divider, Tag, Space } from "antd";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useCreatorPageContext } from "../context";

import { formatData } from "../../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const { Title, Text } = Typography;

const Growth = () => {
  const { creatorData } = useCreatorPageContext();

  const followerData =
    creatorData?.profile?.reputation_history?.map(
      (item) => item.follower_count || 0
    ) || [];

  const likesData =
    creatorData?.profile?.reputation_history?.map(
      (item) => item.average_likes || 0
    ) || [];

  const labels = creatorData?.profile?.reputation_history?.map(
    (item) => item?.month || ""
  );

  const getData = (type: "followers" | "likes") => {
    return {
      labels: labels,
      datasets: [
        {
          label: type === "followers" ? "Followers" : "Average Likes",
          data: type === "followers" ? followerData : likesData,
          borderColor: "#533AF5",
          backgroundColor: "#533AF5",
          tension: 0.5,
          fill: false,
        },
      ],
    };
  };

  return (
    <div>
      <Title level={4}>Growth & interests</Title>
      <Row gutter={20}>
        <Col span={10}>
          <Card>
            <Title level={4} style={{ marginBlock: 0, marginBottom: 5 }}>
              Followers Growth
            </Title>
            <Text type="secondary">Monthly trend of total followers</Text>
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                    position: "top" as const,
                  },
                  datalabels: {
                    display: false,
                    // This code is used to display data values
                  },
                  title: {
                    display: false,
                    text: "Chart.js Line Chart",
                  },
                },
                interaction: { mode: "nearest" },
                scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      callback: (value) => {
                        return formatData(value, "0.0a");
                      },
                    },
                  },
                  x: {
                    // type: "linear",
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={getData("followers")}
              width={"500px"}
              style={{ marginTop: "20px" }}
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card>
            <Title level={4} style={{ marginBlock: 0, marginBottom: 5 }}>
              Likes Growth
            </Title>
            <Text type="secondary">
              Monthly trend of average likes per post
            </Text>
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                    position: "top" as const,
                  },
                  datalabels: {
                    display: false,
                    // This code is used to display data values
                  },
                  title: {
                    display: false,
                    text: "Chart.js Line Chart",
                  },
                },
                interaction: { mode: "nearest" },
                scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      callback: (value) => {
                        return formatData(value, "0.0a");
                      },
                    },
                  },
                  x: {
                    // type: "linear",
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={getData("likes")}
              width={"500px"}
              style={{ marginTop: "20px" }}
            />
          </Card>
        </Col>
      </Row>
      {creatorData?.work_platform?.name === "Instagram" && (
        <>
          <Divider />
          <div style={{ display: "grid", gap: "50px" }}>
            <div>
              <Title level={4} style={{ marginBlock: 0, marginBottom: 5 }}>
                Frequently used hashtags
              </Title>
              <Text
                type="secondary"
                style={{ display: "block", marginBottom: 20 }}
              >
                Hashtags frequently used by creator in their content
              </Text>
              <Space wrap>
                {creatorData?.profile?.top_hashtags?.map((item, idx) => (
                  <Tag
                    key={idx}
                    style={{ borderRadius: 20, padding: "4px 10px " }}
                  >
                    #{item?.name}
                  </Tag>
                ))}
              </Space>
            </div>

            <div>
              <Title level={4} style={{ marginBlock: 0, marginBottom: 5 }}>
                Frequently tagged accounts
              </Title>
              <Text
                type="secondary"
                style={{ display: "block", marginBottom: 20 }}
              >
                Accounts frequently tagged by the creator in their content
              </Text>
              <Space wrap>
                {creatorData?.profile?.top_mentions?.map((item, idx) => (
                  <Tag
                    key={idx}
                    style={{ borderRadius: 20, padding: "4px 10px " }}
                  >
                    @{item?.name}
                  </Tag>
                ))}
              </Space>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Growth;
