import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Space, Input, Col, Select, message } from "antd";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import ReportButton from "../../../Campaign/Report/ReportButton";

import styles from "./styles.module.css";
import "react-phone-number-input/style.css";

import { capitalizeFirstLetter, isEmailValid } from "../../../../utils";

import { editUserDetails } from "../../../../handler/track/editUserDetails";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

interface Props {
  type: "email" | "number" | "tags";
  phoneData?: { numberOf: string; number: string }[];
  data?: string[];
  visible: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
  collectionId: string;
  collectionName: string;
}

const EditUserFieldsModal: React.FC<Props> = ({
  type,
  data,
  handleClose,
  handleSubmit,
  visible,
  phoneData,
  collectionId,
  collectionName,
}) => {
  const [editData, setEditData] = useState<string[]>([]);
  const [noData, setNoData] = useState<{ numberOf: string; number: string }[]>(
    []
  );

  const validateItem = (item: string) => {
    switch (type) {
      case "email":
        return isEmailValid(item);
      case "number":
        return isValidPhoneNumber(item);
      case "tags":
        return Boolean(item.trim());

      default:
        return "";
    }
  };

  useEffect(() => {
    if (visible) {
      // console.log(data, phoneData, phoneData || [], data || []);
      setNoData(phoneData || []);
      return setEditData(data || []);
    }
    return setEditData([]);
  }, [visible]);

  const isDataValid = () => {
    if (type === "number") {
      return noData
        ?.map((item) => item.number)
        .every((phItem) => validateItem(phItem));
    }

    return editData?.every((eItem) => validateItem(eItem));
  };

  const editDetailsMutation = useMutation(editUserDetails, {
    onSuccess: () => {
      message.success("Details Updated");
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      message.error((err.response?.data as any)?.message);
    },
  });

  const getDataType = () => {
    switch (type) {
      case "email":
        return "emailId";
      case "number":
        return "phoneNumber";
      case "tags":
        return "tags";
      default:
        return "";
    }
  };

  return (
    <Modal
      width={800}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        handleClose();
        // return setTouched(false);
      }}
      visible={visible}
      centered
      className={"campaign-create-modal"}>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={styles.heading}>Edit {capitalizeFirstLetter(type)}</p>
      </Row>
      {type !== "number" ? (
        <div>
          <Space style={{ marginBlock: 10 }}>
            <Button onClick={() => setEditData((eData) => [...eData, ""])}>
              Add
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "10px" }}>
            {editData?.map((editItem, idx) => (
              <li>
                <Row gutter={[10, 10]}>
                  <Col style={{ width: "50%" }}>
                    <Input
                      value={editItem}
                      onChange={(e) => {
                        const oldEmailId = editData;
                        const updatedEmails = oldEmailId?.map(
                          (noItem: string, idxs: number) => {
                            if (idx !== idxs) return noItem;
                            return e.target.value || "";
                          }
                        );
                        setEditData(updatedEmails);
                      }}
                    />
                    {editItem ? (
                      validateItem(editItem) ? undefined : (
                        <p
                          style={{ color: "red", fontSize: 8, marginBlock: 2 }}>
                          Invalid {type}
                        </p>
                      )
                    ) : (
                      <p style={{ color: "red", fontSize: 8, marginBlock: 2 }}>
                        Item is required
                      </p>
                    )}
                  </Col>
                  <Button
                    danger
                    onClick={() =>
                      setEditData(
                        editData?.filter((_, delIdx) => delIdx !== idx)
                      )
                    }>
                    Delete{" "}
                  </Button>
                </Row>
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <div>
          <Space style={{ marginBlock: 10 }}>
            <Button
              onClick={() =>
                setNoData((noData) => [
                  ...noData,
                  { number: "", numberOf: "creator" },
                ])
              }>
              Add
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "10px" }}>
            {noData?.map((noItem, idx) => (
              <li>
                <Row gutter={[10, 10]}>
                  <Col style={{ width: "50%" }}>
                    <Space>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={noItem?.number}
                        defaultCountry="IN"
                        onChange={(changedValue) => {
                          const oldPhoneNumbers = noData;
                          const updatedNos = oldPhoneNumbers?.map(
                            (noItem: any, idxs: number) => {
                              if (idx !== idxs) return noItem;
                              return {
                                number: changedValue,
                                numberOf: noItem?.numberOf,
                              };
                            }
                          );
                          setNoData(updatedNos);
                        }}
                        error={
                          noItem?.number
                            ? isValidPhoneNumber(noItem?.number)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                      <Select
                        style={{ width: 120 }}
                        value={noItem?.numberOf}
                        // size="small"
                        onChange={(changedValue) => {
                          const oldPhoneNumbers = noData;
                          const updatedNos = oldPhoneNumbers?.map(
                            (noItem: any, idxs: number) => {
                              if (idx !== idxs) return noItem;
                              return {
                                number: noItem?.number,
                                numberOf: changedValue,
                              };
                            }
                          );
                          setNoData(updatedNos);
                        }}
                        options={[
                          {
                            value: "creator",
                            label: "Creator",
                          },
                          {
                            value: "agency",
                            label: "Agency",
                          },
                          {
                            value: "manager",
                            label: "Manager",
                          },
                        ]}
                      />
                    </Space>
                    {noItem?.number ? (
                      isValidPhoneNumber(noItem?.number) ? undefined : (
                        <p style={{ color: "red", fontSize: 8 }}>
                          Invalid phone number
                        </p>
                      )
                    ) : (
                      <p style={{ color: "red", fontSize: 8 }}>
                        Phone number required
                      </p>
                    )}
                  </Col>
                  <Button
                    danger
                    onClick={() =>
                      setNoData(noData?.filter((_, delIdx) => delIdx !== idx))
                    }>
                    Delete{" "}
                  </Button>
                </Row>
              </li>
            ))}
          </ol>
        </div>
      )}

      <Row justify="start" style={{ marginTop: 80 }}>
        <ReportButton
          title="Create"
          type="primary"
          size="large"
          onClick={() => {
            if (isDataValid()) {
              editDetailsMutation.mutate({
                collectionId,
                collectionName,
                type: getDataType(),
                data: type == "number" ? noData : editData,
              });
            } else {
              message.error("Please recheck the form for errors");
            }
          }}
          loading={editDetailsMutation.isLoading}>
          Submit
        </ReportButton>
        <ReportButton
          title="Cancel"
          type="link"
          size="large"
          onClick={() => handleClose()}
          loading={false}>
          Cancel
        </ReportButton>
      </Row>
    </Modal>
  );
};

export default EditUserFieldsModal;
