import React from "react";
import Lottie from "react-lottie";
import { message } from "antd";
import { useMutation, useQuery } from "react-query";
import { useQueryClient } from "react-query";

import animationData from "../../../../assets/lottie/threeDotLoading.json";

import ReportModal from "../../ReportModal";
import CreatorCard from "../SharedComponents/CreatorCard";

import globalStyles from "../styles.module.css";

import {
  deleteInfluencer,
  getInfluencerReportStatus,
  getCampaignAggregate,
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
  getCampaignReport,
} from "../../../../../handler/report";

import { CreatorReport } from "../../../../../typings";

interface DeleteInfluencerProps {
  creator: CreatorReport;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  platform: "instagram" | "youtube";
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const DeleteInfluencer: React.FC<DeleteInfluencerProps> = ({
  creator,
  visible,
  setVisible,
  campaignId,
  platform,
}) => {
  const [campaignKey] = getCampaignAggregate({ campaignId });
  const queryClient = useQueryClient();

  const [campaignAggregateKey] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [reportConfigKey] = getConfigurationReportOnlyCampaign({
    campaignId,
    platform,
  });
  const [campaignAggKey] = getCampaignReport({ campaignId });

  const deleteInfluencerMutation = useMutation(deleteInfluencer, {
    onSuccess: () => {
      queryClient.invalidateQueries(campaignKey);
      queryClient.invalidateQueries(campaignAggregateKey);
      queryClient.invalidateQueries(reportConfigKey);
      queryClient.invalidateQueries(campaignAggKey);
      setVisible(false);
    },
    onError: () => {
      message.error("something went wrong");
    },
  });

  const [queryKey, queryFn] = getInfluencerReportStatus({
    campaignId,
    socialMediaId: creator.socialMediaId,
  });

  const { data, isLoading } = useQuery(queryKey, queryFn, { enabled: visible });

  return (
    <ReportModal
      visible={visible}
      centered
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={() =>
        deleteInfluencerMutation.mutate({
          influencerReportOnlyId: creator._id,
        })
      }
      okButtonProps={{ loading: deleteInfluencerMutation.isLoading }}
      okText={"Delete"}
      title="Delete from Report">
      <main style={{ display: "grid", gap: "20px" }}>
        <CreatorCard
          creator={creator}
          handleClick={() => {}}
          isSelected={false}
          campaignId={campaignId}
        />

        <div>
          <div
            style={{ height: "20vh", overflowY: "scroll", marginTop: "30px" }}>
            {isLoading ? (
              <Lottie options={defaultOptions} height={50} width={100} />
            ) : (
              <div>
                {!data?.data?.isInfluencerHasReport ? (
                  <p className={globalStyles.label} style={{ width: "70%" }}>
                    Are you sure you want to delete{" "}
                    {creator.socialMediaKit?.socialMediaName} from campaign?
                  </p>
                ) : (
                  <div style={{ display: "grid", gap: "30px", width: "70%" }}>
                    <p className={globalStyles.label}>
                      You have added some media to report for{" "}
                      {creator.socialMediaKit?.socialMediaName}.{" "}
                      <span
                        className={globalStyles.highlightedLink}
                        style={{ textDecoration: "underline" }}
                        onClick={() =>
                          window.open(
                            `/campaign/manage/report/${campaignId}/${creator?.socialMediaId}`
                          )
                        }>
                        {" "}
                        View Report
                      </span>
                      .
                    </p>
                    <p className={globalStyles.label}>
                      Removing {creator.socialMediaKit?.socialMediaName} from
                      campaign will remove the media from reports too!
                    </p>
                    <p className={globalStyles.label}>
                      Are you sure you want to delete
                      {creator.socialMediaKit.socialMediaName} from campaign?
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </ReportModal>
  );
};

export default DeleteInfluencer;
