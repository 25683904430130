import TypeSelector from "./TypeSelector";
import { useState } from "react";
import { Row, Typography } from "antd";
import ResultsTable from "./ResultsTable";
import Pagination from "./Pagination";
import MetaData from "./MetaData";
import ProfileSearch from "./ProfileSearch";
import FilterSelect from "./FilterSelect";
import FiltersDrawer from "./FiltersDrawer";
import { useCreatorSearchContext } from "../../context/CreatorSearchContext";
import FilterSearch from "./FilterSearch";
const { Title, Text } = Typography;

const CreatorSearch = () => {
  const { searchParams, backendState, isSearchEnabled, isLoading } =
    useCreatorSearchContext();

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ margin: "25px 0px" }}>
        <TypeSelector />
      </div>
      {searchParams?.searchType === "search-by-filter" ? (
        <>
          {" "}
          <div style={{ margin: "25px 0px" }}>
            <FilterSearch />
          </div>
          <div style={{ margin: "25px 0px" }}>
            <FilterSelect />
          </div>
          <>
            <MetaData />
            <ResultsTable />
            <Row justify={"center"} style={{ marginTop: "25px" }}>
              <Pagination />
            </Row>
          </>
        </>
      ) : (
        <>
          <ProfileSearch />

          <div style={{ marginTop: "25px" }}>
            <MetaData />
            <ResultsTable />
            <Row justify={"center"} style={{ marginTop: "25px" }}>
              <Pagination />
            </Row>
          </div>
        </>
      )}
      <FiltersDrawer visible={visible} handleClose={() => setVisible(false)} />
    </div>
  );
};

export default CreatorSearch;
