import React, { useState, useEffect, useRef } from "react";
import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  useQuery,
} from "react-query";

import {
  message,
  Modal,
  Row,
  Col,
  Input,
  Spin,
  Space,
  Checkbox,
  Select,
  Divider,
} from "antd";

import AddRule from "./AddRule";
import CustomInput from "../../shared/Custom/Input";
import IgDeliverable from "../../ProfileAnalysis/IndividualPostsInsights/IgDeliverable";

import {
  Catalogue,
  ProductCatalogue,
  MessageReply,
  RuleById,
  SingularRule,
  WorkFlowReport,
  ReplyWorkflow,
} from "../../../typings";

import {
  getRules,
  getWorkflowById,
  createWorkflow,
  editWorkflow,
  getWorkflows,
} from "../../../handler/replies";

import { trimData, getTrimmedLength } from "../../../utils";

import { AxiosError } from "axios";

import globalStyles from "../workflows.module.css";
import ReportButton from "../../Campaign/Report/ReportButton";

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  workflowId?: string;
  isView?: boolean;
  isEdit?: boolean;
  selectedMedia?: {
    type: "IMAGE" | "VIDEO";
    url: string;
    permaUrl?: string;
    mediaId?: string;
  };
}

const AddWorkflow: React.FC<Props> = ({
  isVisible,
  handleClose,
  workflowId = "",
  isEdit,
  isView,
  selectedMedia,
}) => {
  const queryClient = useQueryClient();
  const [addRuleModal, setAddRuleModal] = useState<{
    visible: boolean;
    ruleId?: string;
  }>({ visible: false, ruleId: undefined });

  const [selectedWorkflow, setSelectedWorkflow] = useState<ReplyWorkflow>({});

  const resetForm = () => {
    setSelectedWorkflow({});
  };

  const [rulesKey, rulesFn] = getRules({});
  const [workflowsKey, _] = getWorkflows({});

  const { data: rulesData, isLoading: rulesDataLoading } = useInfiniteQuery(
    rulesKey,
    rulesFn,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.rules.length ? lastPage.data.pageNo + 1 : null,
      enabled: isVisible,
    }
  );

  const firstRender = useRef(true);

  const [getWorkflowKey, getWorkflowFn] = getWorkflowById({ workflowId });

  const { data: workflowData, isLoading: workflowLoading } = useQuery(
    getWorkflowKey,
    getWorkflowFn,
    { enabled: Boolean(workflowId) }
  );

  useEffect(() => {
    if (!isVisible) {
      firstRender.current = true;
      return resetForm();
    }
    if (isVisible && workflowData?.data?.workflow?._id && firstRender.current) {
      firstRender.current = false;
      return setSelectedWorkflow(workflowData?.data?.workflow);
    }
  }, [isVisible, workflowData?.data?.workflow?._id]);

  useEffect(() => {
    if (selectedMedia?.type) {
      setSelectedWorkflow((workflow) => ({
        ...workflow,
        mediaId: selectedMedia?.mediaId || "",
        media: {
          permalink: selectedMedia?.permaUrl || "",
          savedMedias: [
            { url: selectedMedia?.url, mediaType: selectedMedia?.type },
          ],
        },
      }));
    }
  }, [selectedMedia]);

  const createWorkflowMutation = useMutation(createWorkflow, {
    onSuccess: () => {
      message.success("Workflow Created");
      queryClient.invalidateQueries(workflowsKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      console.log(err?.response);
      message.error((err?.response?.data as any)?.message);
    },
  });

  const editWorkflowMutation = useMutation(editWorkflow, {
    onSuccess: () => {
      message.success("Workflow Edited");
      queryClient.invalidateQueries(workflowsKey);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err?.response?.data as any)?.message);
    },
  });

  return (
    <Modal
      width={1000}
      title={null}
      footer={null}
      bodyStyle={{
        borderRadius: "8px",
        padding: 28,
        minHeight: "550px",
      }}
      onCancel={() => {
        handleClose();
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}
      destroyOnClose>
      <Row
        justify="start"
        style={{
          marginTop: "0px",
          paddingBottom: "18px",
        }}>
        <p className={globalStyles.heading}>
          {isView ? "View" : isEdit ? "Edit" : "Add "} Workflow
        </p>
      </Row>
      {workflowLoading ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <Col
          span={16}
          style={{
            display: "grid",
            gap: "12px",
          }}>
          <div style={{ display: "grid", gap: "5px" }}>
            <h5>Selected Media</h5>
            <IgDeliverable
              isSelected={true}
              handleClick={() => {}}
              type={
                selectedWorkflow?.media?.savedMedias?.at(0)?.mediaType ||
                "IMAGE"
              }
              url={selectedWorkflow?.media?.savedMedias?.at(0)?.url || ""}
            />
            <ReportButton
              type="text"
              onClick={() =>
                window.open(selectedWorkflow?.media?.permalink, "_blank")
              }>
              Open in Instagram
            </ReportButton>
          </div>
          <CustomInput
            label="Select a Rule to apply"
            children={
              <>
                <Select
                  showSearch
                  placeholder="Select a Rule"
                  optionFilterProp="children"
                  size="large"
                  disabled={isView}
                  notFoundContent={
                    rulesDataLoading ? (
                      <Row justify="center">
                        <Spin />
                      </Row>
                    ) : (
                      <>
                        <h4>
                          You need to create a matching rule before making a
                          workflow{" "}
                        </h4>
                        <Row justify="center">
                          <ReportButton
                            type="primary"
                            onClick={() =>
                              setAddRuleModal({
                                visible: true,
                                ruleId: undefined,
                              })
                            }
                            disabled={isView}>
                            Create a Rule
                          </ReportButton>
                        </Row>
                      </>
                    )
                  }
                  onChange={(val: string, _) => {
                    const [name, id] = val.split("*");

                    setSelectedWorkflow((workflow) => ({
                      ...workflow,
                      rules: [{ name, ruleId: id }],
                    }));
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={rulesData?.pages
                    ?.flatMap((rowItem) => rowItem?.data?.rules || [])
                    .map((item) => ({
                      label: trimData({ value: item?.name || "", limit: 20 }),
                      value: item?.name + "*" + item?._id,
                    }))}
                />

                {(selectedWorkflow?.rules || [])?.length > 0 && (
                  <div style={{ padding: 5 }}>
                    <Divider style={{ marginBlock: "5px" }} />
                    <Row justify="space-between">
                      <p>
                        {trimData({
                          value: selectedWorkflow?.rules?.at(0)?.name || "",
                          limit: 30,
                        })}
                      </p>
                      <ReportButton
                        type="link"
                        onClick={() => {
                          setAddRuleModal({
                            visible: true,
                            ruleId: selectedWorkflow?.rules?.at(0)?.ruleId,
                          });
                        }}>
                        View
                      </ReportButton>
                    </Row>
                  </div>
                )}
              </>
            }
          />

          <ReportButton
            type="primary"
            style={{ width: "max-content", marginTop: 100 }}
            loading={
              editWorkflowMutation.isLoading || createWorkflowMutation.isLoading
            }
            onClick={() => {
              if (isView) {
                return handleClose();
              }

              if ((selectedWorkflow?.rules || [])?.length > 0) {
                if (isEdit) {
                  return editWorkflowMutation.mutate({
                    data: {
                      workflowId,
                      mediaId: selectedWorkflow?.mediaId || "",
                      rulesApplied:
                        selectedWorkflow?.rules?.map(
                          (rItem) => rItem?.ruleId
                        ) || [],
                    },
                  });
                }
                return createWorkflowMutation.mutate({
                  data: {
                    mediaId: selectedWorkflow?.mediaId || "",
                    rulesApplied:
                      selectedWorkflow?.rules?.map((rItem) => rItem?.ruleId) ||
                      [],
                  },
                });
              }

              return message.error("Please Select a Rule");
            }}>
            {isView ? "Close" : "Submit"}
          </ReportButton>
        </Col>
      )}
      <AddRule
        isVisible={addRuleModal.visible}
        handleClose={() => {
          setAddRuleModal({ visible: false, ruleId: undefined });
          editWorkflowMutation.reset();
        }}
        isView={Boolean(addRuleModal.ruleId)}
        ruleId={addRuleModal?.ruleId}
      />
    </Modal>
  );
};

export default AddWorkflow;
