import { sendRequest } from "../../helper";

export type postWorkFlowReqProps = {
  finalizedOffersIds: string[];
  deliverableId: string;
  closureDate: string;
  requirementId: string;
  isDraftNeeded: boolean;
};

export const postWorkFlowReq = ({
  finalizedOffersIds,
  deliverableId,
  closureDate,
  isDraftNeeded,
  requirementId,
}: postWorkFlowReqProps) => {
  return sendRequest("post", `/brand/execution/requirements`, false, {
    finalizedOffersIds,
    deliverableId,
    closureDate,
    isDraftNeeded,
    requirementId,
  }).then((res) => res.data);
};
