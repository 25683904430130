import React, { useState } from "react";
import {
  Modal,
  Button,
  Radio,
  Space,
  message,
  Row,
  Skeleton,
  Divider,
} from "antd";
import { useQuery, useMutation } from "react-query";
import { AxiosError } from "axios";
import nf from "@tuplo/numberfmt";
import { PlusOutlined } from "@ant-design/icons";

import { getAllCampaigns } from "../../../handler/campaign";
import { inviteInfluencer } from "../../../handler/campaign/offer";
import { getUser } from "../../../handler/getUser";
import { getInvitePrice } from "../../../handler/discovery";

import Modals from "../../Onboarding-1/Modals";
import SpecialOfferModal from "./SpecialOfferModal";

import { getNameOfDeliverable } from "../../../utils/getNameOfDeliverable";

interface CampaignModalProps {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  influencerId: string;
  platform: string;
  socialMediaKitId: string;
}

const CampaignModal: React.FC<CampaignModalProps> = ({
  isVisible,
  handleCancel,
  handleSubmit,
  influencerId,
  platform,
  socialMediaKitId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isOfferModalVisible, setOfferModalVisible] = useState<boolean>(false);

  const [kycNotVerifiedModal, setKycNotVerifiedModal] =
    useState<boolean>(false);

  const [inputValues, setInputValues] = useState<{
    dealId: string;
    campaignId: string;
    campaignName: string;
  }>({
    dealId: "",
    campaignId: "",
    campaignName: "",
  });
  const [getUserkey, getUserFn] = getUser();

  const { data: userData } = useQuery(getUserkey, getUserFn);

  const [getAllCampaignsKey, getAllCampaignsFn] = getAllCampaigns({
    pageNumber: 1,
    status: "Active",
    influencerId,
    platform,
  });

  const { data: campaignsData, isLoading } = useQuery(
    getAllCampaignsKey,
    getAllCampaignsFn,
    {
      enabled: isVisible,
    }
  );

  const [getInvitePriceKey, getInvitePriceFn] = getInvitePrice({
    dealId: inputValues.dealId,
    campaignId: inputValues.campaignId,
    socialMediaKitId,
  });
  const { data: invitePrice, isLoading: invitePriceIsLoading } = useQuery(
    getInvitePriceKey,
    getInvitePriceFn,
    {
      enabled: inputValues.dealId !== "" && inputValues.campaignId !== "",
    }
  );

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Invite Sent");
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      {campaignsData?.data.length === 0 ? (
        <>
          <Modal
            width={356}
            centered={true}
            visible={isVisible}
            footer={false}
            onCancel={handleCancel}>
            <Space
              size="large"
              style={{ width: "100%" }}
              direction="vertical"
              align="center">
              <Row justify="center">
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    color: "rgba(0, 0, 0, 0.85)",
                  }}>
                  Create Campaign
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.45)",
                  }}>
                  To invite someone, you need to create a campaign first.
                </p>
              </Row>
              <Row>
                <Button
                  onClick={() => {
                    userData?.data.signUpStatus !== "businessVerified"
                      ? userData?.data.kyc.status === "Not-Initiated" ||
                        userData?.data.kyc.status === "Needs-Attention"
                        ? setVisible(true)
                        : setKycNotVerifiedModal(true)
                      : window.open(
                          `/campaign/create?platform=${platform}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                    handleCancel();
                  }}
                  type="primary">
                  Create Campaign
                </Button>
              </Row>
            </Space>
          </Modal>
          <Modal
            centered={true}
            title="Account Verification Pending"
            visible={kycNotVerifiedModal}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => setKycNotVerifiedModal(false)}
            onOk={() => setKycNotVerifiedModal(false)}>
            We are still under the process of verifying your Account. It will
            take less than 24 hours. If it’s taking longer or you're facing an
            issue, write to us at support@cloutflow.com
          </Modal>
          <Modals
            isVisible={visible}
            setVisible={setVisible}
            isFirstTime={false}
          />
        </>
      ) : (
        <Modal
          title={
            <div style={{ display: "flex", gap: "4px" }}>
              <p>Select an</p>
              <p style={{ textTransform: "capitalize" }}>{platform}</p>
              <p>campaign</p>
            </div>
          }
          visible={isVisible}
          footer={
            <Button
              type="primary"
              disabled={inputValues.dealId === ""}
              onClick={() =>
                inviteInfluencerMutation.mutate({
                  dealId: inputValues.dealId,
                  socialMediaKitId,
                })
              }
              loading={inviteInfluencerMutation.isLoading}>
              Send
            </Button>
          }
          onCancel={handleCancel}
          centered
          destroyOnClose>
          {isLoading ? (
            <div>Loader</div>
          ) : campaignsData ? (
            <>
              <div>
                <h5
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    margin: "0",
                  }}>
                  Select campaign
                </h5>
                <Radio.Group
                  value={inputValues.dealId}
                  style={{ margin: "16px 0px" }}>
                  <Space direction="vertical">
                    {campaignsData.data.map((item) => (
                      <Radio
                        onChange={() =>
                          setInputValues((inputValues) => {
                            return {
                              ...inputValues,
                              campaignId: item._id,
                              dealId: item.defaultDeal,
                              campaignName: item.campaignName,
                            };
                          })
                        }
                        value={item.defaultDeal}
                        disabled={item.offer && item.offer.length > 0}>
                        <>
                          {item.campaignName}
                          {item.offer && item.offer.length > 0 && (
                            <span
                              style={{
                                border: "1px solid #D9D9D9",
                                marginLeft: "8px",
                                background: "#F0F0F0",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "20px",
                                padding: "1px 8px",
                                borderRadius: "2px",
                              }}>
                              Already invited
                            </span>
                          )}
                        </>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <Row>
                  <Space
                    style={{ cursor: "pointer", color: "#2130FF" }}
                    onClick={() => {
                      window.open(
                        `/campaign/create?platform=${platform}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                      handleCancel();
                    }}>
                    <PlusOutlined />
                    <span>Create new campaign</span>
                  </Space>
                </Row>
                {inputValues.dealId && (
                  <div>
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ width: "100%" }}>
                      <h5
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                          margin: "0",
                          marginTop: "20px",
                        }}>
                        Commercial (Negotiable)
                      </h5>
                      <div
                        style={{
                          backgroundColor: "#FAFAFA",
                          padding: "17px 21px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "7px",
                          borderRadius: "12px",
                        }}>
                        {invitePriceIsLoading ? (
                          <Skeleton />
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                                borderBottom: "1.5px solid #EEEEEE",
                                paddingBottom: "7px",
                              }}>
                              {invitePrice?.data?.items.map((item) => (
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#999999",
                                  }}>
                                  <p style={{ flex: 0.5 }}>
                                    {getNameOfDeliverable(item.name)}
                                  </p>
                                  <p style={{ flex: 0.5 }}>
                                    ₹{nf(item.price, "0,0.00")}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "4px",
                              }}>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#09121F",
                                  flex: 0.5,
                                }}>
                                Total
                              </p>
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#09121F",
                                  flex: 0.5,
                                }}>
                                {/* ₹{nf(item.total, "0,0.00")} */}₹
                                {nf(invitePrice?.data?.price, "0,0.00")}
                              </p>
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </Space>
                  </div>
                )}
                {/* {userData?.data?.accountType &&
                  userData?.data?.accountType === "Managed" && ( */}
                <>
                  <Divider type="horizontal">OR</Divider>
                  <Row justify="center">
                    <Button
                      type="primary"
                      onClick={() => setOfferModalVisible(true)}
                      disabled={!inputValues?.campaignId}>
                      Invite by special Offer
                    </Button>
                  </Row>
                </>
                {/* )} */}
              </div>
              <SpecialOfferModal
                isVisible={isOfferModalVisible}
                handleClose={() => {
                  setOfferModalVisible(false);
                  handleCancel();
                }}
                socialMediaKitId={socialMediaKitId}
                queryKey={getAllCampaignsKey}
                campaignName={inputValues.campaignName}
                campaignId={inputValues.campaignId}
              />
            </>
          ) : (
            <div>No Data</div>
          )}
        </Modal>
      )}
    </>
  );
};

export default CampaignModal;
