import InputRange from "../Common/InputRange";
import InputList from "../Common/InputList";
import CustomMultiSelect from "../Common/CustomMultiSelect";
import MultiInputRange from "../Common/MultiInputRange";
import RangeSlider from "../Common/RangeSlider";
import CustomCheckBox from "./CustomCheckBox";

import { ConfigTypeSingle } from "../../../typings/CreatorSearch";

import cuid from "cuid";
import CustomSingleSelect from "./CustomSingleSelect";

const FilterHOC: React.FC<{ item?: ConfigTypeSingle; showTitle?: boolean }> = ({
  item,
  showTitle = false,
}) => {
  if (item?.compType === "inputRange") {
    return (
      <InputRange
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        key={cuid()}
        isOnSearch={!showTitle}
        fType={item?.fType}
        maxCount={item?.maxCount}
      />
    );
  }
  if (item?.compType === "checkbox") {
    return (
      <CustomCheckBox
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        fType={item?.fType}
        maxCount={item?.maxCount}
      />
    );
  }

  if (item?.compType === "list") {
    return (
      <InputList
        fType={item?.fType}
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        key={cuid()}
        isOnSearch={!showTitle}
        maxCount={item?.maxCount}
      />
    );
  }

  if (item?.compType === "custom-multi-select") {
    return (
      <CustomMultiSelect
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        key={cuid()}
        isOnSearch={!showTitle}
        fType={item?.fType}
        maxCount={item?.maxCount}
      />
    );
  }
  if (item?.compType === "custom-multi-inputRange") {
    return (
      <MultiInputRange
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        key={cuid()}
        isOnSearch={!showTitle}
        fType={item?.fType}
        maxCount={item?.maxCount}
      />
    );
  }
  if (item?.compType === "slider") {
    return (
      <RangeSlider
        compType={item?.compType}
        desc={item?.desc}
        facetName={item?.facetName}
        isSearchable={item?.isSearchable}
        title={item?.title}
        placeHolder={item?.placeHolder}
        type={item?.type}
        key={cuid()}
        isOnSearch={!showTitle}
        fType={item?.fType}
        maxCount={item?.maxCount}
      />
    );
  }

  return <div />;
};

export default FilterHOC;
