import React from "react";
import { ConfigTypeSingle } from "../../../typings/CreatorSearch";
import { useState, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useQuery } from "react-query";

import { getFacetValues } from "../../../handler/insightIq";
import HelpPopOver from "./HelpPopover";

import { Typography, Space, Checkbox, Divider, Row, Spin } from "antd";

import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import CustomModal from "./CustomModal";

const { Paragraph, Title, Text } = Typography;

interface Props extends ConfigTypeSingle {
  isOnSearch?: boolean;
}
const ContactOptions: React.FC<Props> = ({
  compType,
  desc,
  facetName,
  isSearchable,
  title,
  placeHolder,
  type,
  isOnSearch = false,
}) => {
  const [visible, setVisible] = useState<boolean>(isOnSearch);

  return (
    <div>
      {!isOnSearch && (
        <Space
          onClick={() => setVisible(!visible)}
          size={"small"}
          align="center"
          style={{
            marginBottom: "5px",
            color: visible ? "#1677ff" : undefined,
          }}
        >
          {visible ? <FiPlus size={19} /> : <FiMinus size={19} />}
          <Title
            level={5}
            style={{ marginBlock: 0, color: visible ? "#1677ff" : undefined }}
          >
            {title}
          </Title>
          {desc && <HelpPopOver customText={desc} color="black" />}
        </Space>
      )}
      <ContactModal visible={visible} handleClose={() => setVisible(false)} />
    </div>
  );
};

const ContactModal = ({
  visible,
  handleClose,
}: {
  visible: boolean;
  handleClose: () => void;
}) => {
  const { searchParams, setSearchParams, platformId } =
    useCreatorSearchContext();

  const isSpecificContactChecked = Boolean(
    searchParams["has_contact_details"] || false
  );

  const checkedContactOptions = searchParams["specific_contact_details"] || [];

  const [getFacetKey, getFaceValue] = getFacetValues({
    facetName: "specific_contact_details",
    body: {},

    work_platform_id: platformId,
  });

  const { data, isLoading } = useQuery(getFacetKey, getFaceValue, {
    enabled: visible,
  });

  return (
    <CustomModal
      title="Which contact options should creator have?"
      visible={visible}
      handleClose={() => handleClose()}
      onOk={() => handleClose()}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div style={{ padding: "40px 0" }}>
        <Checkbox
          checked={isSpecificContactChecked}
          onChange={(e) => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              ["has_contact_details"]: e.target.checked || undefined,
              specific_contact_details: e.target.checked
                ? []
                : searchParams?.specific_contact_details || undefined,
              filterTags: {
                ...searchParams?.filterTags,
                ["has_contact_details"]: e.target.checked || undefined,
                specific_contact_details: e.target.checked
                  ? []
                  : searchParams?.specific_contact_details || undefined,
              },
            }));
          }}
        >
          <div>
            <Text
              strong
              style={{ marginBlock: 0, marginBottom: 0, display: "block" }}
            >
              Any Contact Option
            </Text>
            <Text type="secondary">
              {" "}
              Get creators with at least 1 available contact option
            </Text>
          </div>
        </Checkbox>
        <Divider> OR</Divider>
        {isLoading ? (
          <Row style={{ marginTop: 20 }} justify={"center"}>
            <Spin />
          </Row>
        ) : (
          <Checkbox.Group
            options={data?.data?.map((dItem) => ({
              label: dItem?.name,
              value: dItem?.type || dItem?.value,
            }))}
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              display: "grid",
              gap: "20px",
            }}
            disabled={isSpecificContactChecked}
            value={checkedContactOptions}
            onChange={(val) => {
              setSearchParams((sParams) => ({
                ...sParams,
                specific_contact_details: val,
                filterTags: {
                  ...sParams?.filterTags,
                  specific_contact_details: val,
                },
              }));
            }}
          />
        )}
      </div>
    </CustomModal>
  );
};

export default ContactOptions;
