import { sendRequest } from "../helper";

import { SingularRule } from "../../typings";

interface createCatalogueProps {
  name: string;
  productIds: string[];
}

export const createCatalogue = ({ name, productIds }: createCatalogueProps) => {
  return sendRequest("post", `/brand/products/catalogue`, false, {
    name,
    productIds,
  }).then((res) => res.data);
};
