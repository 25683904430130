import React from "react";
import { Collapse, Switch, Input, Upload, Button } from "antd";
import { MdContentCopy } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import type { Requirement } from "../../../../../typings";

import Chip from "./Chip";

import styles from "./reqModal.module.css";

const { Panel } = Collapse;
const { TextArea } = Input;

interface CollapsibleReqProps {
  selectedRequirement: Requirement;
  workflowDeliverableData: any;
  onReplicate: () => void;
}

const CollapsibleReq: React.FC<CollapsibleReqProps> = ({
  selectedRequirement,
  workflowDeliverableData,
  onReplicate,
}) => {
  if (!selectedRequirement) {
    return null;
  }
  return (
    <Collapse
      collapsible="header"
      style={{ width: "380px" }}
      bordered={false}
      expandIconPosition="end"
      expandIcon={(panelProps) => {
        return panelProps.isActive ? <IoIosArrowUp /> : <IoIosArrowDown />;
      }}>
      <Panel
        header={selectedRequirement.name}
        key="1"
        extra={
          <Button
            type="text"
            onClick={() => {
              onReplicate();
            }}
            icon={<MdContentCopy />}
          />
        }>
        <>
          <div className={styles.item}>
            <p className={styles.heading}>Deliverable Details</p>
            {workflowDeliverableData !== undefined && (
              <div className={styles.description}>
                <p>
                  {workflowDeliverableData?.deliverablesDetails.duration && (
                    <>
                      [{workflowDeliverableData?.deliverablesDetails.duration}]
                    </>
                  )}{" "}
                  {workflowDeliverableData?.deliverablesDetails?.typeDel}
                </p>
                {workflowDeliverableData?.deliverablesDetails?.contentRights !==
                  "Not Needed" &&
                  workflowDeliverableData?.deliverablesDetails
                    ?.contentRights !== undefined && (
                    <p>
                      [
                      {
                        workflowDeliverableData?.deliverablesDetails
                          .contentRights
                      }
                      ] Content Rights
                    </p>
                  )}
              </div>
            )}
          </div>

          <div className={styles.item}>
            <p className={styles.heading}>Brief</p>
            <p className={styles.description}>
              {workflowDeliverableData?.deliverablesDetails.description}
            </p>
          </div>
          <div className={styles.draftReq}>
            <p>Creator needs to send draft</p>
            <span className="">
              <Switch
                checked={selectedRequirement.isDraftNeeded}
                disabled={true}
              />
            </span>
          </div>

          <div className={styles.item}>
            <p className={styles.heading}> Requirements</p>
            <div
              style={{ wordBreak: "break-all" }}
              dangerouslySetInnerHTML={{
                __html: selectedRequirement.summary,
              }}
            />
          </div>

          <div className={styles.item}>
            <p className={styles.heading}>Caption</p>
            <TextArea
              size="middle"
              placeholder="Caption"
              autoSize={{ minRows: 3 }}
              value={selectedRequirement.caption}
              disabled={true}
            />
          </div>
          <div className={styles.item}>
            <p className={styles.heading}>Mentions</p>
            <Input value={selectedRequirement.mentions} disabled={true} />
            <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {selectedRequirement.mentions.map((item) => {
                return <Chip text={item} onclose={() => {}} />;
              })}
            </div>
          </div>
          {selectedRequirement.references?.length > 0 && (
            <div className={styles.item}>
              <p className={styles.heading}> References </p>
              <Upload
                listType="picture-card"
                fileList={selectedRequirement.references.map((imgItem) => {
                  return {
                    uid: imgItem.url,
                    name: imgItem.type,
                    status: "done",
                    url: imgItem.url,
                    type: imgItem.type,
                  };
                })}
                disabled={true}
              />
            </div>
          )}
        </>
      </Panel>
    </Collapse>
  );
};

export default CollapsibleReq;
