import React from "react";
import { Dropdown, Menu } from "antd";
import { useQuery } from "react-query";
import Cookies from "universal-cookie";
import { Space } from "antd";
import Lottie from "react-lottie";
import { MdAccountCircle } from "react-icons/md";

import { getUser } from "../../../handler/getUser";
import { getInvitations } from "../../../handler/organization";

import { capitalizeFirstLetter } from "../../../utils";

import styles from "./nav.module.css";

import { useUserAuth } from "../../../context/userAuthcontext";

type NavProps = {};

const Nav: React.FC<NavProps> = () => {
  const [userKey, userFn] = getUser();
  const cookies = new Cookies();
  const { data: userData, isLoading: userDataLoading } = useQuery(
    userKey,
    userFn
  );

  const { logout } = useUserAuth();

  const handleOrgChange = (val: string) => {
    if (val === "default") {
      cookies.remove("brandId", {
        path: "/",
      });
    } else {
      cookies.set("brandId", val, {
        path: "/",
        maxAge: 360 * 24 * 60 * 60,
      });
    }
    // to go to homepage
    window.open(process.env.REACT_APP_DEPLOYMENT_URL, "_self", "noreferrer");
  };

  const [getInvitationsKey, getInvitationsFn] = getInvitations({
    status: "Accepted",
    send: false,
  });

  const { data: orgData, isLoading: orgDataLoading } = useQuery(
    getInvitationsKey,
    getInvitationsFn
  );

  if (orgDataLoading || userDataLoading) {
    return <div></div>;
  }

  return (
    <nav className={styles.navContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}>
        <div>
          <img src={"/logo512.png"} alt="logo" width={30} />
        </div>
        <div>
          <p className={styles.cloutflow}>Cloutflow</p>
          <p className={styles.ads}>Ads</p>
        </div>
      </div>
      {orgData && (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: "1",
                  type: "group",
                  label: "Organizations",
                  children: [
                    {
                      key: "default",
                      label: "Default Organization",
                      onClick: () => handleOrgChange("default"),
                    },
                    ...orgData?.data?.map((org) => {
                      return {
                        key: org.brandId,
                        label: org?.brandUser?.name + " Organization",
                        onClick: () => handleOrgChange(org.brandId),
                      };
                    }),
                  ],
                },
                {
                  key: "2",
                  type: "group",
                  label: "Profile",
                  children: [
                    {
                      key: "3",
                      label: <p onClick={logout}>Logout</p>,
                    },
                  ],
                },
              ]}
            />
          }
          placement="bottomLeft"
          arrow
          trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <div className={styles.navBorder}>
              {userData?.data?.businessDetails?.logoUrl ? (
                <img
                  src={userData?.data?.businessDetails?.logoUrl}
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                />
              ) : (
                <MdAccountCircle size={15} />
              )}

              {capitalizeFirstLetter(userData?.data?.emailId.charAt(0) ?? "")}
            </div>
          </a>
        </Dropdown>
      )}
    </nav>
  );
};
export default Nav;
