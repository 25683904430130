import React from "react";
import { Divider, Row, Col, Space, Spin } from "antd";
import { useQuery } from "react-query";
import { format } from "date-fns";

import Tag from "./Tag";
import ReportButton from "../Campaign/Report/ReportButton";

import { getAllInvoices } from "../../handler/plan";

import styles from "./styles.module.css";

const Invoices = () => {
  const [invoicesKey, invoicesFn] = getAllInvoices();

  const { data: invoiceData, isLoading } = useQuery(invoicesKey, invoicesFn);

  if (isLoading) {
    return (
      <Row justify="center" style={{ paddingBlock: "50px" }}>
        <Spin size="default" />
      </Row>
    );
  }

  return (
    <div>
      <p className={styles.heading}>Invoices</p>
      <Divider style={{ margin: "10px 0" }} />
      <div className={styles.invoicesContainer}>
        {invoiceData?.data.map((invoiceItem) => (
          <Row justify="space-between">
            <Col span={5} className={styles.rowItem}>
              <Space size={8} align="center">
                {format(new Date(invoiceItem?.created), "d LLL yyyy")}
              </Space>
            </Col>
            <Col span={5} className={styles.rowItem}>
              {invoiceItem?.total}
            </Col>
            <Col span={5} className={styles.rowItem}>
              <Tag status={invoiceItem?.status} />
            </Col>
            <Col span={5} className={styles.rowItem}>
              <ReportButton
                title="Open Invoice"
                type="primary"
                onClick={() => window.open(invoiceItem?.url, "_blank")}>
                Open Invoice
              </ReportButton>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Invoices;
