import React from "react";
import { Space } from "antd";

import MetricCard from "../../Shared/MetricCard";
import { PinnedMetrics } from "../../YtAnalytics";

import { formatData } from "../../../../utils";

type VideoMetricInsightsPinnedProps = {
  videoMetrics: PinnedMetrics[];
  removePin: (title: string) => void;
  ytVideoData:
    | {
        name: string;
        description: string | undefined;
        key: string;
        value: number;
      }[];
};

const VideoMetricInsightsPinned: React.FC<VideoMetricInsightsPinnedProps> = (
  props
) => {
  const { videoMetrics, removePin, ytVideoData } = props;

  const insightsData = videoMetrics.map((item) => {
    return ytVideoData.find((x) => x.name === item.title);
  });

  return (
    <React.Fragment>
      <Space size={20} style={{ marginBlock: 20 }} wrap>
        {insightsData?.map((item, key) => {
          return (
            <MetricCard
              key={key}
              title={item?.name || "-"}
              desc={item?.description || "-"}
              metric={formatData((item?.value as number) || 0, "a")}
              isPinned={true}
              handlePin={() => removePin(item?.name || "")}
            />
          );
        })}
      </Space>
    </React.Fragment>
  );
};
export default VideoMetricInsightsPinned;
