import { sendRequest } from "../../../helper";

export type postLiveChangesV2Props = {
  workflowId: string;
  changes: string;
  prevLiveLinkTimeLineId: string;
};

export const postLiveChangesV2 = ({
  workflowId,
  changes,
  prevLiveLinkTimeLineId,
}: postLiveChangesV2Props) => {
  return sendRequest(
    "put",
    `/brand/execution/live/require-changes`,
    false,
    {
      workflowId,
      changes,
      prevLiveLinkTimeLineId,
    },
    null,
    true
  ).then((res) => res.data);
};
