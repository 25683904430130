import React, { useState } from "react";
import { Row, Space } from "antd";
import Lottie from "react-lottie";

import animationData from "../../../../assets/lottie/astronot.json";
import { IndividualInfluencerReport } from "../../../../../typings";

import ReportButton from "../../ReportButton";
import AddReport from "../../Modals/AddReport";

import { getDefaultLottieOptions } from "../../../../../utils";

interface NoMediaProps {
  data: IndividualInfluencerReport;
}

const NoMedia: React.FC<NoMediaProps> = ({ data }) => {
  const [addReportVisible, setAddReportVisible] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "grid",
        gap: "20px",
        width: "100%",
        height: "30vh",
        margin: "50px 0",
        justifyContent: "center",
      }}>
      <Lottie
        options={getDefaultLottieOptions(animationData)}
        height={123}
        width={300}
      />
      <Row justify="center">
        <ReportButton
          onClick={() => setAddReportVisible(true)}
          type="primary"
          style={{ width: "fit-content" }}>
          Add media to report
        </ReportButton>
      </Row>
      <Row justify="center">
        <p
          style={{
            color: "rgba(0, 0, 0, 0.80)",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 600,
          }}>
          No Media Added for {data?.socialMediaKit?.influencerName}
        </p>
      </Row>
      <AddReport
        campaignId={data?.campaignId}
        creator={{ ...data, noOfMedias: 0 }}
        platform={data?.platform}
        setVisible={setAddReportVisible}
        visible={addReportVisible}
      />
    </div>
  );
};

export default NoMedia;
