import { sendRequest } from "../helper";

interface getInfluencersCountResponse {
  status: string;
  message: string;
  data: {
    count: number;
  };
}

export const getInfluencersCount = () => {
  const queryKey = ["saas-influencers", "lists", "count"];

  const queryFunction = () =>
    sendRequest<getInfluencersCountResponse>(
      "get",
      `/brand/discovery/saas/influencer-connect-request-count`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
