import React from "react";
import { Input, Typography, Button, Row, Col } from "antd";
import { InstagramOutlined, LinkedinOutlined } from "@ant-design/icons";

import styles from "../../Onboarding.module.css";

interface DocsInputProps {
  socialLink: string;
  SetSocialLink: React.Dispatch<React.SetStateAction<string>>;
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

const DocsInput: React.FC<DocsInputProps> = ({
  socialLink,
  SetSocialLink,
  link,
  setLink,
}) => {
  const { Title, Paragraph } = Typography;

  const linkedInLink = "https://in.linkedin.com/in/shivam-arora-73a784127";
  const instagramLink = "https://www.instagram.com/clout.flow/";

  const handleLinkChange = (value: string) => {
    if (link !== value) {
      SetSocialLink("");
    }
    setLink(value);
  };

  return (
    <>
      <Row
        align="middle"
        justify="center"
        gutter={[0, 24]}
        style={{ padding: "24px 0" }}
        // style={{ margin: "0 24px" }}
      >
        <Col span={14}>
          <Button
            size="large"
            block
            onClick={() => handleLinkChange("instagram")}>
            <InstagramOutlined />
            Instagram
          </Button>
        </Col>
        <Col span={14}>
          <Button
            size="large"
            block
            onClick={() => handleLinkChange("LinkedIn")}>
            <LinkedinOutlined />
            LinkedIn
          </Button>
        </Col>
      </Row>
      {link && (
        <div style={{ display: "grid", gap: "20px" }}>
          <div>
            <Title level={4} className={styles.radioTitle}>
              Enter {link} profile URL
            </Title>
            <Input
              value={socialLink}
              onChange={(event) => SetSocialLink(event.target.value)}
              style={{ marginTop: "5px" }}
              size="large"
            />
          </div>
          <div>
            <Title level={4} className={styles.radioTitle}>
              Please send this message to{" "}
              <span>
                <a
                  href={link === "instagram" ? instagramLink : linkedInLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  Cloutflow
                </a>
              </span>{" "}
              {link} account to complete your verification
            </Title>
            <Paragraph
              copyable
              className={styles.radioDesc}
              style={{
                border: "2px dashed #1890ff",
                padding: "20px",
                width: "max-content",
              }}>
              I verify that I am the owner of this {link} account{"   "}
              {"               "}
            </Paragraph>
          </div>
        </div>
      )}
    </>
  );
};

export default DocsInput;
