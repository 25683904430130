import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  message,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  updateCloutflowCampaignExecution,
  UpdateCampaignExecutionProps,
} from "../../../../handler/cloutflowCampaign/updateCampaignExecution";
import { getUser } from "../../../../handler/getUser";
import { getOrgAppState } from "../../../../handler/organization";

const { Text } = Typography;

interface CampaignSettingsPageProps {
  campaign: {
    _id: string;
    serviceCostPercentage: number;
    isProductDispatch: boolean;
    isInternalApproval: boolean;
  };
}

const ExecutionSettings: React.FC<CampaignSettingsPageProps> = ({
  campaign,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [orgUserKey, orgUserFn] = getOrgAppState();
  const { data: orgUserData, isLoading: orgUserDataLoading } = useQuery(
    orgUserKey,
    orgUserFn
  );

  const [serviceCostPercentage, setServiceCostPercentage] = useState<number>(
    campaign.serviceCostPercentage
  );
  const [isProductDispatch, setIsProductDispatch] = useState<boolean>(
    campaign.isProductDispatch
  );
  const [isInternalApproval, setIsInternalApproval] = useState<boolean>(
    campaign.isInternalApproval
  );

  const mutation = useMutation(updateCloutflowCampaignExecution, {
    onSuccess: () => {
      message.success("Campaign execution updated successfully");
      queryClient.invalidateQueries(["campaign", campaign._id]);
    },
    onError: (error: any) => {
      message.error(error.message);
    },
  });

  const onFinish = () => {
    if (orgUserData?.data?.accessLevel === "brand") {
      const updateData: UpdateCampaignExecutionProps = {
        campaignId: campaign._id,
        isProductDispatch,
        isInternalApproval,
      };
      mutation.mutate(updateData);
    }
    if (orgUserData?.data?.accessLevel === "cloutflow") {
      const updateData: UpdateCampaignExecutionProps = {
        campaignId: campaign._id,
      };
      mutation.mutate(updateData);
    }
    if (orgUserData?.data?.accessLevel === "super") {
      const updateData: UpdateCampaignExecutionProps = {
        campaignId: campaign._id,
        serviceCostPercentage,
        isProductDispatch,
        isInternalApproval,
      };
      mutation.mutate(updateData);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      serviceCostPercentage,
      isProductDispatch,
      isInternalApproval,
    });
  }, [
    campaign,
    form,
    serviceCostPercentage,
    isProductDispatch,
    isInternalApproval,
  ]);

  return (
    <section>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Platform Fee Percentage"
          labelCol={{ style: { fontWeight: "bold" } }}
          help=" Platform Fees percentage is the fees charged on the Influencer's
            purchase."
        >
          <Input
            value={`${serviceCostPercentage}%`}
            disabled={
              orgUserData?.data.accessLevel === "brand" ||
              orgUserData?.data.accessLevel === "cloutflow"
            }
          />
        </Form.Item>
        <Form.Item
          label="Enable Product Sending Features"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
        >
          <Row justify="space-between">
            <Col span={20} dir="horizontal">
              <Text type="secondary">
                In case you need to send out physical Products to Influencer's
                enable this feature, to get access to Influencer's address &
                much more.
              </Text>
            </Col>
            <Col span={2}>
              <Switch
                checked={isProductDispatch}
                onChange={(checked) => setIsProductDispatch(checked)}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Enable External Approval's"
          labelCol={{ style: { fontWeight: "bold", marginTop: "10px" } }}
        >
          <Row justify="space-between">
            <Col span={20} dir="horizontal">
              <Text type="secondary">
                Need to approve draft's or Live link's outside of Platform,
                enable this feature, and you can approve anywhere.
              </Text>
            </Col>
            <Col span={2}>
              <Switch
                checked={isInternalApproval}
                onChange={(checked) => setIsInternalApproval(checked)}
                disabled={orgUserData?.data.accessLevel === "cloutflow"}
              />
            </Col>
          </Row>
        </Form.Item>
        <Divider />
        <Row justify="end">
          <Button
            style={{ marginRight: 8, borderRadius: 8 }}
            onClick={() => form.resetFields()}
          >
            Cancel
          </Button>
          <Button type="primary" style={{ borderRadius: 8 }} htmlType="submit">
            Save
          </Button>
        </Row>
      </Form>
    </section>
  );
};

export default ExecutionSettings;
