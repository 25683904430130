import { sendRequest } from "../../../helper";

export type addDraftV2Props = {
  workflowId: string;
  references: any[];
};

export const addDraftV2 = ({ workflowId, references }: addDraftV2Props) => {
  return sendRequest(
    "put",
    `/brand/execution/drafts/upload`,
    false,
    {
      workflowId,
      references,
    },
    null,
    true
  ).then((res) => res.data);
};
