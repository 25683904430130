import React from "react";
import { Popover } from "antd";

import { LuInfo } from "react-icons/lu";

interface Props {
  desc: string;
}
const InfoPopover: React.FC<Props> = ({ desc }) => {
  return (
    <Popover
      content={desc}
      title={null}
      trigger="hover"
      placement="top"
      arrowPointAtCenter>
      <LuInfo size={15} style={{ cursor: "pointer" }} />
    </Popover>
  );
};

export default InfoPopover;
