import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IGFilters, YtFilters, TikTokFilters } from "../typings/CreatorSearch";

import { useQuery } from "react-query";
import { ResultItem } from "../typings/InsightIq";
import { sleep } from "../utils/sleep";
import {
  InsightsIqQuickSearchResponse,
  getQuickSearchCreators,
} from "../handler/insightIq/getCreator";

export const insightsiqPlatformWorkIdMap = {
  tiktok: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  instagram: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  youtube: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  // twitter: "7645460a-96e0-4192-a3ce-a1fc30641f72",
  // twitch: "e4de6c01-5b78-4fc0-a651-24f44134457b",
  // facebook: "ad2fec62-2987-40a0-89fb-23485972598c",
  // substack: "fbf76083-710b-439a-8b8c-956f607ef2c1",
  // instagramLite: "fb7cafcf-60ed-468a-ad6d-6bb6899d0871",
  // adSense: "3e6f4cdd-2119-4125-aa0d-86eee4e20fcc",
  // spotify: "1db0b86c-a547-4119-9834-da927171a685",
  // linkedIn: "36410629-f907-43ba-aa0d-434ca9c0501a",
  // beehiiv: "24fcaa09-0a79-4deb-b074-d8efd900265b",
};

type TsearchTypes = "search-by-filter" | "search-by-profile";
type Tplatform = "instagram" | "youtube" | "tiktok";
export interface TsearchParams {
  searchType?: TsearchTypes;
  platform?: Tplatform; // Assuming Tplatform is a string type
  offset?: number;
  limit?: number;
  username?: string;
  topics?: string[]; // Array<string> changed to string[]
  [key: string]: any;
  sort_by?: { field: string; order: "DESCENDING" | "ASCENDING" };

  // Ensure all dynamic properties conform to the structure or undefined
}

type ContextState = {
  getResults: () => void;
  searchParams: TsearchParams;
  creatorData?: InsightsIqQuickSearchResponse;
  setSearchParams: React.Dispatch<React.SetStateAction<TsearchParams>>;
  platformId: string;
  removeFilters: () => void;
  isLoading: boolean;
  backendState: TsearchParams;
  ObjToFilter: { [key: string]: any };
  beObjToFilter: { [key: string]: any };
  selectedCreators: ResultItem[];
  setSelectedCreators: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  isSearchEnabled: boolean;
  platform: Tplatform;
};

const creatorSearchContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

const getPlatformName = (platformId: string): Tplatform => {
  for (const [platform, id] of Object.entries(insightsiqPlatformWorkIdMap)) {
    if (id === platformId) {
      return platform as Tplatform;
    }
  }
  return "instagram"; // If platform ID not found
};

export const CreatorSearchContextProvider: React.FC<Props> = ({ children }) => {
  const [selectedCreators, setSelectedCreators] = useState<ResultItem[]>([]);
  const [searchState, setSearchState] = useState<TsearchParams>({
    offset: 0,
    limit: 10,
    platform: "instagram",
    searchType: "search-by-filter",
    sort_by: {
      field: "AVERAGE_LIKES",
      order: "DESCENDING",
    },
  });
  const [beSearchParams, setBeSearchState] = useState<TsearchParams>({});

  const filtersToSelect =
    searchState?.platform === "youtube"
      ? YtFilters
      : searchState?.platform === "tiktok"
      ? TikTokFilters
      : IGFilters;

  let ObjToFilter: { [key: string]: any } = {};
  let beObjToFilter: { [key: string]: any } = {};

  const [searchFirstRender, setSearchFirstRender] = useState<boolean>(false);
  // const searchFirstRender = useRef(false);

  Object.entries(searchState["filterTags"] || {})?.forEach(([key, value]) => {
    const facet = filtersToSelect?.find((fItem) => fItem.facetName === key);

    if (facet && value) {
      ObjToFilter = {
        ...ObjToFilter,
        [key]: { facetValue: value, facetTitle: facet.title, facetName: key },
      };
    }
  });

  if (
    Object.keys(searchState["filterTags"] || {}).includes(
      "specific_contact_details"
    )
  ) {
    ObjToFilter = {
      ...ObjToFilter,
      ["specific_contact_details"]: {
        facetValue: searchState["filterTags"]["specific_contact_details"],
        facetTitle: "Contact Options: ",
        facetName: "specific_contact_details",
      },
    };
  }
  if (
    Object.keys(searchState["filterTags"] || {}).includes("has_contact_details")
  ) {
    ObjToFilter = {
      ...ObjToFilter,
      ["has_contact_details"]: {
        facetValue: searchState["filterTags"]["has_contact_details"],
        facetTitle: "Contact Options: Any",
        facetName: "has_contact_details",
      },
    };
  }

  Object.entries(beSearchParams["filterTags"] || {})?.forEach(
    ([key, value]) => {
      const facet = filtersToSelect?.find((fItem) => fItem.facetName === key);

      if (facet && value) {
        beObjToFilter = {
          ...beObjToFilter,
          [key]: {
            facetValue: value,
            facetTitle: facet.title,
            facetName: key,
          },
        };
      }
    }
  );
  if (
    Object.keys(beSearchParams["filterTags"] || {}).includes(
      "specific_contact_details"
    )
  ) {
    beObjToFilter = {
      ...beObjToFilter,
      ["specific_contact_details"]: {
        facetValue: beSearchParams["filterTags"]["specific_contact_details"],
        facetTitle: "Contact Options: ",
        facetName: "specific_contact_details",
      },
    };
  }
  if (
    Object.keys(beSearchParams["filterTags"] || {}).includes(
      "has_contact_details"
    )
  ) {
    beObjToFilter = {
      ...beObjToFilter,
      ["has_contact_details"]: {
        facetValue: beSearchParams["filterTags"]["has_contact_details"],
        facetTitle: "Contact Options: Any",
        facetName: "has_contact_details",
      },
    };
  }

  const [getQuickSearchCreatorsKey, getQuickSearchCreatorsFn] =
    getQuickSearchCreators(
      beSearchParams.searchType === "search-by-filter"
        ? {
            body: {
              work_platform_id:
                insightsiqPlatformWorkIdMap[
                  beSearchParams.platform || "instagram"
                ],
              ...beSearchParams,
            },
          }
        : {
            body: {
              work_platform_id:
                insightsiqPlatformWorkIdMap[
                  beSearchParams.platform || "instagram"
                ],
              username: beSearchParams.username,
              platform: beSearchParams.platform,
              searchType: "search-by-profile",
              sort_by: {
                field: "AVERAGE_LIKES",
                order: "DESCENDING",
              },
            },
          }
    );
  const { data, isLoading } = useQuery(
    getQuickSearchCreatorsKey,
    getQuickSearchCreatorsFn,

    {
      enabled:
        searchState?.searchType === "search-by-filter"
          ? searchFirstRender
          : Boolean((searchState?.username || "").trim()),
    }
  );

  const [urlParams, setSearchParams] = useSearchParams();
  const firstRender = useRef(true);

  const enableSearch = () => {
    setSearchFirstRender(true);
  };

  useEffect(() => {
    if (!firstRender?.current) {
      setSearchParams({ filters: JSON.stringify(searchState) });
    }
  }, [searchState]);

  useEffect(() => {
    if (Object.keys(beObjToFilter || {}).length > 0) {
      enableSearch();
    }
  }, [JSON.stringify(beObjToFilter)]);

  const [incrementCounter, setIncrementCounter] = useState<number>(1);

  const getResultsFE = () => {
    setIncrementCounter((incrementCounter) => incrementCounter + 1);
  };

  const getResultsBE = () => {
    // console.log(getQuickSearchCreatorsKey, searchState);

    setBeSearchState(searchState);
  };

  const removeFilters = () => {
    setSearchState((searchState) => ({
      limit: 10,
      offset: 0,
      platform: searchState.platform,
      sort_by: searchState.sort_by,
      searchType: searchState.searchType,
    }));
    sleep(10).then(() => getResultsFE());
  };

  useEffect(() => {
    getResultsBE();
  }, [incrementCounter]);

  useEffect(() => {
    const searchParamsQ = urlParams.get("filters");

    const isProfile = Boolean(urlParams.get("identifier"));
    if (isProfile) {
      setSearchFirstRender(false);
      firstRender.current = true;
      return;
    }

    if (firstRender?.current) {
      firstRender.current = false;

      let tempObj = JSON.parse(searchParamsQ || "{}");

      tempObj = {
        ...tempObj,
        offset: tempObj?.offset || 0,
        limit: tempObj?.limit || 10,
        platform: tempObj?.platform || "instagram",
        searchType: tempObj?.searchType || "search-by-filter",
        sort_by: tempObj?.sort_by || {
          field: "AVERAGE_LIKES",
          order: "DESCENDING",
        },
        username: tempObj?.username || "",
      };

      setSearchState(tempObj);
      setBeSearchState(tempObj);
    }
  }, [window.location.search]);

  useEffect(() => {
    setSelectedCreators([]);
  }, [searchState?.platform, searchState?.searchType]);

  const userPlatform = JSON.parse(urlParams.get("filters") || "{}")?.platform;

  return (
    <creatorSearchContext.Provider
      value={{
        searchParams: searchState,
        setSearchParams: setSearchState,
        getResults: getResultsFE,
        platformId:
          insightsiqPlatformWorkIdMap[beSearchParams.platform || "instagram"],
        creatorData: data,
        removeFilters: removeFilters,
        isLoading: isLoading,
        backendState: beSearchParams,
        ObjToFilter: ObjToFilter || {},
        beObjToFilter: beObjToFilter || {},
        selectedCreators,
        setSelectedCreators,
        isSearchEnabled: searchFirstRender,
        platform:
          userPlatform ||
          getPlatformName(urlParams.get("workplatformId") || ""),
      }}
    >
      {children}
    </creatorSearchContext.Provider>
  );
};

export const useCreatorSearchContext = () => {
  const context = useContext(creatorSearchContext);

  if (!context) {
    throw new Error("useAuth must be used in <CreatorSearchContextProvider />");
  }

  return context;
};
