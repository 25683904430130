import { Button, Card, Col, Row } from "antd";
import { format } from "date-fns";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import { ChatCard } from "../../../../../typings";
import ReqModal from "../../../../Campaign/Manage/Finalized/ReqModal/ReqModalUpdated";
import styles from "./card.module.css";

interface CampaignStartedCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    ref?: { offerId?: string; finalizedOfferId?: string; campaignId?: string };
    messageId: string;
    cardTimestamp?: string;
    type: string;
  };
  conversationId: string;
}

const CampaignStartedCard: React.FC<CampaignStartedCardProps> = ({
  item,
  conversationId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [selectedIds, setSelectedIds] = useState<string[]>([
    item?.ref?.finalizedOfferId as string,
  ]);
  const [getChatCardKey, getChatCardDataFn] = getChatCardData({
    ref: item.ref,
    messageId: item.messageId,
    type: item.type,
  });

  const { data: cardData } = useQuery(getChatCardKey, getChatCardDataFn);

  const handleSubmit = () => {
    queryClient.invalidateQueries(["chats", conversationId]);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      {cardData && (
        <div className={styles.card}>
          <Card
            bodyStyle={{
              fontWeight: 400,
              padding: "12px 16px",
            }}
            style={{
              borderRadius: "10px",
              borderWidth: "",
            }}
            headStyle={{
              backgroundColor: "#2075F3",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              borderWidth: "1.5px",
              color: "white",
              padding: "0px 16px",
            }}
            title={
              <div style={{ display: "grid", gap: "5px", minWidth: "341px" }}>
                <p style={{ fontWeight: 500, fontSize: "14px" }}>
                  {item.cardName}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.6",
                    }}>
                    {item.cardTitle}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                    }}>
                    {format(new Date(item.cardTimestamp as string), "hh:mm")}
                  </p>
                </div>
              </div>
            }>
            <Row>
              <Col>{(cardData?.data as ChatCard).campaign?.campaignName}</Col>
            </Row>
            <div className={styles.cardInner}>
              <div style={{ display: "flex", gap: "5px" }}>
                {(cardData?.data as ChatCard).deliverables.map((delData, i) => (
                  <p key={i}>
                    {delData.deliverablesDetails?.count}{" "}
                    {delData.deliverablesDetails?.typeDel}
                    {(cardData?.data as ChatCard).deliverables.length - 1 !==
                      i && ","}
                  </p>
                ))}
              </div>
              <span>
                <span style={{ fontWeight: "bold" }}>₹ </span>
                {(cardData?.data as ChatCard).price}
              </span>
            </div>
            <div className={styles.cardButton}>
              <Button
                size="small"
                onClick={() => setVisible(true)}
                type="primary"
                style={{
                  padding: "0px 7px",
                  marginTop: "12px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}>
                Send Requirements
              </Button>
            </div>
          </Card>
          {visible && (
            <ReqModal
              isVisible={visible}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              finalizedOfferId={item.ref?.finalizedOfferId}
              applicantIds={selectedIds}
              id={item.ref?.campaignId as string}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CampaignStartedCard;
