import { sendRequest } from "../helper";
import { CreatorReport } from "../../typings";

export type deleteInfluencerProps = {
  influencerReportOnlyId: string;
};

export const deleteInfluencer = (props: deleteInfluencerProps) => {
  return sendRequest<CreatorReport>(
    "delete",
    `/brand/report-only/influencer?influencerReportOnlyId=${props.influencerReportOnlyId}`,
    false
  ).then((res) => res.data);
};
