import { sendRequest } from "../../../helper";

export type approveDraftV2Props = {
  workflowId: string;
  comments?: string;
  prevDraftTimeLineId: string;
};

export const approveDraftV2 = ({
  workflowId,
  comments,
  prevDraftTimeLineId,
}: approveDraftV2Props) => {
  return sendRequest(
    "put",
    `/brand/execution/drafts/accept`,
    false,
    {
      workflowId,
      comments,
      prevDraftTimeLineId,
    },
    null,
    true
  ).then((res) => res.data);
};
