import React from "react";
import { Card } from "antd";
import { FaWhatsapp } from "react-icons/fa";

import styles from "./styles.module.css";

import { Influencer } from "../../../../typings";

type BasicProfileProps = {
  influencerData?: Influencer;
};

const BasicProfile: React.FC<BasicProfileProps> = (props) => {
  const { influencerData: data } = props;
  const Chip: React.FC<{ label: string }> = (props) => {
    const { label } = props;
    return (
      <div
        style={{
          padding: "10px 20px",
          borderRadius: 20,
          background: "#E7E6FE",
        }}>
        {label[0].toUpperCase()}
        {label.slice(1)}
      </div>
    );
  };

  return (
    <Card
      style={{ maxWidth: "650px", borderRadius: 10 }}
      className={styles.detailsCard}>
      <h1>{data?.name}</h1>
      <h2>
        {data?.age} Years Old . {data?.gender}{" "}
        {`(${data?.gender === "Male" ? "He/Him" : "She/Her"})`} .
      </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          flexWrap: "wrap",
        }}>
        {data?.niche?.map((item) => (
          <Chip label={item} />
        ))}
      </div>
      <h6>
        <span>
          Phone Number: {data?.phoneNumber}{" "}
          {!data?.phoneNumber?.includes("X") && (
            <FaWhatsapp
              style={{ marginLeft: 2, cursor: "pointer", color: "green" }}
              size={18}
              onClick={() =>
                window.open(`https://wa.me/${data?.phoneNumber}`, "_blank")
              }
            />
          )}
        </span>
        {/* <span>Email Id: abc@bcd.com</span> */}
      </h6>
    </Card>
  );
};
export default BasicProfile;
