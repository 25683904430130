import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Space, Dropdown, Button } from "antd";
import { useCreatorSearchContext } from "../../../context/CreatorSearchContext";
import { capitalizeFirstLetter, sleep } from "../../../utils";

const PlatformSelector = () => {
  const { searchParams, setSearchParams, removeFilters } =
    useCreatorSearchContext();

  const items: MenuProps["items"] = [
    {
      label: (
        <Space
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              platform: "instagram",
            }));
            sleep(10).then(() => removeFilters());
          }}
        >
          <img
            alt="instagram_logo"
            src={"/assets/img/logo_instagram.png"}
            style={{ height: "38px", width: "38px" }}
          />
          Instagram
        </Space>
      ),
      key: "0",
    },
    {
      label: (
        <Space
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              platform: "youtube",
            }));
            sleep(10).then(() => {
              removeFilters();
            });
          }}
        >
          <img
            alt="youtube_logo"
            src={"/assets/img/logo_youtube.png"}
            style={{ height: "38px", width: "38px" }}
          />
          Youtube
        </Space>
      ),
      key: "1",
    },
    {
      label: (
        <Space
          onClick={() => {
            setSearchParams((searchParams) => ({
              ...searchParams,
              platform: "tiktok",
            }));
            sleep(10).then(() => removeFilters());
          }}
        >
          <img
            alt="tiktok_logo"
            src={"/assets/img/logo_tiktok.png"}
            style={{ height: "38px", width: "38px" }}
          />
          TikTok
        </Space>
      ),
      key: "3",
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button size="large" style={{ borderRadius: "8px" }}>
        <Space size={5} align="start">
          <img
            alt="logo"
            src={`/assets/img/logo_${searchParams.platform}.png`}
            style={{ height: "25px", width: "25px" }}
          />
          {capitalizeFirstLetter(searchParams?.platform || "")}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default PlatformSelector;
