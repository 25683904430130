import React from "react";
import clsx from "clsx";

import styles from "./styles.module.css";

import CustomButton, {
  CustomButtonProps,
} from "../../../shared/Custom/CustomButton";

export interface ReportButtonProps extends CustomButtonProps {
  isOutline?: boolean;
  isOutlineDanger?: boolean;
}

const ReportButton: React.FC<ReportButtonProps> = ({
  isOutline = false,
  isOutlineDanger = false,
  ...props
}) => {
  return (
    <CustomButton
      {...props}
      className={clsx(styles.container, props.className, {
        [styles.primary]: props.type === "primary",
        [styles.default]: props.type === "default",
        [styles.outline]: isOutline,
        [styles.outlineDanger]: isOutlineDanger,
        [styles.disabled]: props.disabled,
      })}
    >
      {props.children}
    </CustomButton>
  );
};

export default ReportButton;
