import { sendRequest } from "../helper";

interface Payload {
  username: string;
}

export const updateUsername = async ({ username }: Payload) => {
  const res = await sendRequest(
    "put",
    `/brand/connect-request/slug?slug=${username}`,
    false
  );
  return res.data;
};
