import React from "react";
import { Space } from "antd";

import Option from "../../../shared/UI/Option";

type SelectorProps = {
  selected: "video" | "shorts";
  setSelected: React.Dispatch<React.SetStateAction<"video" | "shorts">>;
};

const contentType: {
  name: string;
  key: SelectorProps["selected"];
}[] = [
  { name: "Video", key: "video" },
  { name: "Shorts", key: "shorts" },
];

const Selector: React.FC<SelectorProps> = (props) => {
  const { selected, setSelected } = props;
  return (
    <Space size={10}>
      {contentType.map((item, key) => {
        return (
          <Option
            key={key}
            title={item.name}
            isSelected={selected === item.key}
            handleClick={() => setSelected(item.key)}
          />
        );
      })}
    </Space>
  );
};
export default Selector;
