import React, { useState } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { Row, Spin, Col, Space, Rate, Button } from "antd";
import { HeartFilled } from "@ant-design/icons";

import ReportButton from "../../../Campaign/Report/ReportButton";
import ReviewCard from "../../../Campaign/Manage/CreatorProfile/ReviewCard";
import ReviewModal from "../../../Campaign/Manage/Finalized/Timeline/LinksTile/ReviewModals/Review";

import {
  getInfluencerReviews,
  getInfluencer,
} from "../../../../handler/Profile";

import styles from "./reviews.module.css";

import { formatData } from "../../../../utils";

const getReviewStarCount = (val: number) => {
  return Math.floor(val) + (val - Math.floor(val) >= 0.5 ? 0.5 : 0);
};

const ReviewsCard: React.FC<{ influencerId: string }> = ({ influencerId }) => {
  const queryClient = useQueryClient();
  const [reviewVisible, setReviewVisible] = useState<boolean>(false);
  const [getInfluencerKey, getInfluencerFn] = getInfluencer(influencerId);
  const { data: influencerData, isLoading: isInfluencerDataLoading } = useQuery(
    getInfluencerKey,
    getInfluencerFn
  );

  const [getInfluencerReviewsKey, getInfluencerReviewsFn] =
    getInfluencerReviews(influencerId);
  const {
    data: InfluencerReviewsData,
    isLoading: isInfluencerReviewsDataLoading,
    hasNextPage: hasReviewNextPage,
    fetchNextPage: fetchReviewNextPage,
    isFetchingNextPage: isFetchingReviewNextPage,
    isError,
  } = useInfiniteQuery(getInfluencerReviewsKey, getInfluencerReviewsFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  if (isInfluencerReviewsDataLoading || isInfluencerDataLoading) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  if (isError || !InfluencerReviewsData?.pages) {
    return <div />;
  }
  return (
    <div className={styles.container}>
      <Row justify="space-between">
        <Col>
          <p className={styles.title}>Reviews</p>
        </Col>
        <ReportButton isOutline onClick={() => setReviewVisible(true)}>
          {" "}
          Add a review
        </ReportButton>
      </Row>
      <div className={styles.innerContainer}>
        <Space size={15}>
          <Rate
            value={getReviewStarCount(
              (influencerData?.data.aggregatedReviewRating || 0) /
                (influencerData?.data.totalReviews || 1)
            )}
            disabled
            style={{ color: "#EC3434" }}
            character={
              <HeartFilled
                style={{
                  fontSize: "110%",
                }}
              />
            }
            allowHalf={true}
          />
          <p className={styles?.reviewNumber}>
            {formatData(
              `${
                (influencerData?.data.aggregatedReviewRating || 0) /
                (influencerData?.data.totalReviews || 1)
              }`,
              "0.0"
            )}{" "}
            / {5}
          </p>

          <p className={styles.reviewCount}>
            {influencerData?.data?.totalReviews} reviews
          </p>
        </Space>
        <div style={{ display: "grid", gap: "20px" }}>
          {InfluencerReviewsData?.pages.map((page) =>
            page?.data.reviews.map((review) => {
              return (
                <Col span={12}>
                  <ReviewCard review={review} />
                </Col>
              );
            })
          )}
        </div>
        <Row justify="end">
          <Button
            style={{ padding: "4px 24px" }}
            type="text"
            disabled={!hasReviewNextPage}
            onClick={() => {
              fetchReviewNextPage();
            }}
            loading={isFetchingReviewNextPage}>
            <u
              className={styles.reviewCount}
              style={{ textDecoration: "underline" }}>
              See More
            </u>
          </Button>
        </Row>
        <ReviewModal
          isVisible={reviewVisible}
          handleSubmit={() => {
            setReviewVisible(false);
            queryClient.invalidateQueries(getInfluencerReviewsKey);
            queryClient.invalidateQueries(getInfluencerKey);
          }}
          handleCancel={() => {
            setReviewVisible(false);
          }}
          influencerId={influencerId}
        />
      </div>
    </div>
  );
};

export default ReviewsCard;
