import { sendRequest } from "../helper";

export type CreateCampaignResult = {
  status: string;
  message: string;
  data: any;
};

type CreateCampaignProps = {
  name: string;
  requirements: String;
};

export const createCloutflowCampaign = (props: CreateCampaignProps) => {
  return sendRequest<CreateCampaignResult>("post", "/campaign", false, {
    name: props.name,
    requirements: props.requirements,
  }).then((res) => res.data);
};
