import { useSearchParams } from "react-router-dom";
import { Row, Col } from "antd";

import ChatSideBar from "../Chat/ChatSidebar";
import ChatScreen from "../Chat/ChatScreen";
import Navbar from "../shared/Navbar";

import { ChatContextProvider } from "../../context/ChatContext";

import styles from "./chat.module.css";

const ChatTest = () => {
  const [searchParams] = useSearchParams();

  return (
    <div style={{ overflowY: "hidden", height: "100vh" }}>
      <Navbar selectedItem="chat" />
      <main style={{ backgroundColor: "#FAFAFA", height: "91.7vh" }}>
        <ChatContextProvider>
          <Row className={styles.container}>
            <Col
              span={7}
              xxl={7}
              style={{
                background: "white",
                borderRight: "1px solid #CECECE",
                overflowY: "hidden",
              }}>
              <ChatSideBar />
            </Col>
            <Col style={{ height: "91.7vh" }} span={17} xxl={17}>
              <ChatScreen
                influencerIdParam={searchParams.get("influencerId") || ""}
              />
            </Col>
          </Row>
        </ChatContextProvider>
      </main>
    </div>
  );
};

export default ChatTest;
