import { sendRequest } from "../../../helper";

export type addLiveProps = {
  workflowId: string;
  references: any[];
};

export const addLiveV2 = ({ workflowId, references }: addLiveProps) => {
  return sendRequest(
    "put",
    `/brand/execution/live/upload`,
    false,
    {
      workflowId,
      references,
    },
    null,
    true
  ).then((res) => res.data);
};
