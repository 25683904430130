import { sendRequest } from "../helper";
import { MessageReply } from "../../typings";

interface editProductProps {
  ig: MessageReply;
}

export const editProduct = ({ ig }: editProductProps) => {
  const { _id, ...restProps } = ig;

  delete restProps["brandId"];
  delete restProps["isIgSupported"];
  delete restProps["createdDateTime"];
  delete restProps["lastUpdatedDateTime"];
  delete restProps["searchAbleParams"];
  delete restProps["__v"];

  return sendRequest("put", `/brand/products`, false, {
    ig: restProps,
    productId: _id || "",
  }).then((res) => res.data);
};
