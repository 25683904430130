import { sendRequest } from "../helper";
import { CampaignResponse } from "../../typings";

export type deleteProductProps = {
  campaignId: string;
  productId: string;
};

export const deleteProduct = ({
  campaignId,
  productId,
}: deleteProductProps) => {
  return sendRequest<CampaignResponse>(
    "delete",
    `/brand/campaign/create-campaign-form/product/${campaignId}/${productId}`,
    false
  ).then((res) => res.data);
};
