import React, { useState } from "react";
import { Row, Tag } from "antd";
import clsx from "clsx";

import styles from "./campaignCard.module.css";
import { InfluencerCampaign } from "../../../../../typings";

import TimeLineDrawer from "../../Finalized/Timeline";

const CampaignCard: React.FC<{
  campaign: InfluencerCampaign;
  influencerId: string;
}> = ({ campaign, influencerId }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const getCampaignStatus = () => {
    switch (campaign.finalizedStatus!.status) {
      case "Finalized":
        return { color: "green", label: "Ongoing" };
      case "Completed":
        return { color: "orange", label: "Completed" };
      case "Cancel":
        return { color: "red", label: "Cancelled" };
      default:
        return { color: "blue", label: campaign.status };
    }
  };
  const { color, label } = getCampaignStatus();

  return (
    <>
      <section
        className={clsx(styles.container, {
          [styles.disabled]:
            campaign.finalizedStatus!.status !== "Finalized" &&
            campaign.finalizedStatus!.status !== "Completed",
        })}
        onClick={() => {
          if (
            campaign.finalizedStatus!.status === "Finalized" ||
            campaign.finalizedStatus!.status === "Completed"
          )
            return setVisible(true);
          return;
        }}>
        <p className={styles.cName}>{campaign.campaign.campaignName}</p>
        <Row justify="space-between">
          <p className={styles.date}>Started on 23rd July, 2022</p>
          <Tag color={color}>{label}</Tag>
        </Row>
      </section>
      <TimeLineDrawer
        isOpen={visible}
        applicantId={campaign.finalizedStatus?._id || ""}
        campaignId={campaign.campaignId}
        influencerId={influencerId}
        handleClose={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default CampaignCard;
