import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { UserAuthContextProvider } from "./context/userAuthcontext";

import { ChatNotificationContextProvider } from "./context/ChatNotificationContext";

import ErrorBoundary from "./ErrorBoundary";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    // Set the default stale time to 10 minutes (600000 milliseconds)
    queries: {
      staleTime: 600000,
      // Set the default cache time to 1 hour (3600000 milliseconds)
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <BrowserRouter>
        <ErrorBoundary>
          <UserAuthContextProvider>
            <ChatNotificationContextProvider>
              <App />
            </ChatNotificationContextProvider>
          </UserAuthContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
