import React, { useState } from "react";
import CreatorProfile from "../Manage/CreatorProfile";

const CreatorProfileImg = ({
  imageURl,
  influencerID,
  size = "",
}: {
  imageURl: string;
  influencerID: string;
  size?: string;
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <div>
      <img
        src={imageURl !== "" ? imageURl : "/assets/img/test-profile.png"}
        style={{
          width: size ? size : "44px",
          height: size ? size : "44px",
          borderRadius: "100px",
          cursor: "pointer",
        }}
        alt="influencer"
        onClick={() => setIsVisible(true)}
      />
      <CreatorProfile
        isVisible={isVisible}
        handleClose={() => setIsVisible(false)}
        influencerId={influencerID}
      />
    </div>
  );
};

export default CreatorProfileImg;
