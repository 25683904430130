import React, { useState } from "react";
import { Menu } from "antd";
import { useSearchParams } from "react-router-dom";

// import InviteModal from "../InviteModal";

import ReportButton from "../../Campaign/Report/ReportButton";
import AddMessageModal from "../Modal/AddMessage";
import AddCatalogue from "../Modal/AddCatalogue";
import AddRule from "../Modal/AddRule";

import styles from "../workflows.module.css";

interface SelectorProps {
  selectedState: string;
}
// "messages" | "catalogue" | "rule"
const Selector: React.FC<SelectorProps> = ({ selectedState }) => {
  const [modalVisibleProps, setModalVisibleProps] = useState<{
    messages: boolean;
    catalogue: boolean;
    rule: boolean;
  }>({ catalogue: false, messages: false, rule: false });

  const [_, setSearchParams] = useSearchParams();

  const getButtonText = () => {
    switch (selectedState) {
      case "messages":
        return "Create a New Message";
      case "catalogue":
        return "Create Product Catalogue";
      case "rule":
        return "Create Rule";
      default:
        return "";
    }
  };

  const getButtonClick = () => {
    switch (selectedState) {
      case "messages":
        return setModalVisibleProps({
          messages: true,
          catalogue: false,
          rule: false,
        });

      case "catalogue":
        return setModalVisibleProps({
          messages: false,
          catalogue: true,
          rule: false,
        });
      case "rule":
        return setModalVisibleProps({
          messages: false,
          catalogue: false,
          rule: true,
        });
      default:
        return {};
    }
  };

  return (
    <div className={styles.menuContainer}>
      <Menu
        defaultSelectedKeys={[selectedState]}
        selectedKeys={[selectedState]}
        mode="horizontal"
        style={{ position: "relative" }}
        onSelect={(e) => {
          setSearchParams({ type: e.key });
        }}>
        <Menu.Item key={"messages"}>Messages</Menu.Item>
        <Menu.Item key={"catalogue"}>Catalogue</Menu.Item>
        <Menu.Item key={"rule"}>Rules</Menu.Item>
        <Menu.Item key={"Workflows"}>Workflows</Menu.Item>
      </Menu>

      {selectedState !== "workflows" && (
        <ReportButton onClick={getButtonClick} type="primary">
          {getButtonText()}
        </ReportButton>
      )}

      <AddMessageModal
        isVisible={modalVisibleProps.messages}
        handleClose={() =>
          setModalVisibleProps((props) => ({ ...props, messages: false }))
        }
      />

      <AddCatalogue
        isVisible={modalVisibleProps.catalogue}
        handleClose={() =>
          setModalVisibleProps((props) => ({ ...props, catalogue: false }))
        }
      />

      <AddRule
        isVisible={modalVisibleProps.rule}
        handleClose={() =>
          setModalVisibleProps((props) => ({ ...props, rule: false }))
        }
      />
    </div>
  );
};

export default Selector;
