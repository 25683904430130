export const notificationTypes = {
  ApplicationWithdrawn: "Influencer-Application-Withdrawn",
  ApplicationAccepted: "Influencer-Invitation-Accepted",
  ApplicationRejected: "Influencer-Invitation-Rejected",
  CampaignApproved: "Campaign-Approved",
  CampaignApprovalIssue: "Campaign-Approval-Issue",
  KycApproved: "Kyc-Approved",
  KycIssue: "Kyc-Issue",
  PaymentDone: "Payment-Done",
  PaymentFailed: "Payment-Failed",
  PaymentPendingReminder: "Reminder-Payment-Pending",
  SendRequirementsReminder: "Reminder-Send-Requirements",
  DraftsApprovedReminder: "Reminder-Drafts-Approve",
  LiveApprovedReminder: "Reminder-Live-Approve",
};
