import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { Row, Col, Skeleton, Space, Radio } from "antd";
import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { getUser } from "../../handler/getUser";
import { Configure, useSearchBox } from "react-instantsearch";
import {
  INSTANT_SEARCH_INDEX_INSTA_NAME,
  INSTANT_SEARCH_INDEX_YT_NAME,
} from "./constants";
import Filters from "./Filters";

import AlgoliaPagination from "../SaasDiscovery/AlgoliaPagination";
// import AlgoliaPagination from "../AlgoliaPagination";

import {
  InstantSearch,
  useInstantSearch,
  useRefinementList,
} from "react-instantsearch";

import ResultsTable from "./ResultsTable";
import Navbar from "../shared/Navbar";
import Title from "../shared/Title";
import { MdOutlineInfo } from "react-icons/md";

// import { useRangeSlider } from "./Filters/AgeRangeSlider";

const DiscoverV2 = ({ platform }: { platform: "instagram" | "youtube" }) => {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY as string
  );

  const [getUserkey, getUserFn] = getUser();
  const { data: userData } = useQuery(getUserkey, getUserFn);
  const [visible, setVisible] = useState<boolean>(false);

  const memoizedResult = useMemo(() => {
    return (
      <div style={{ overflowY: "auto", height: "100vh" }}>
        <Navbar selectedItem="connected" />
        <Row justify="center" style={{ padding: "20px 64px" }}>
          <Col style={{ width: "1300px" }}>
            <div style={{ padding: "20px" }}>
              <Title
                name="Connected discovery"
                tagline="Find best Influencer's from first-party Database"
                icon={<MdOutlineInfo color="rgba(0, 0, 0, 0.50)" />}
              />
            </div>

            <Row>
              <InstantSearch
                searchClient={searchClient}
                indexName={
                  platform === "instagram"
                    ? INSTANT_SEARCH_INDEX_INSTA_NAME
                    : INSTANT_SEARCH_INDEX_YT_NAME
                }
                routing={true}
              >
                <Configure />
                <div>
                  <Child searchClient={searchClient} platform={platform} />
                </div>
              </InstantSearch>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }, [userData?.data?.kyc?.status, visible]);

  return memoizedResult;
};

const Child = ({
  searchClient,
  platform,
}: {
  searchClient: SearchClient;
  platform: "instagram" | "youtube";
}) => {
  const { status, results } = useInstantSearch();

  // to render search box empty  widget from algolia
  useSearchBox();

  // Ig Facets
  useRefinementList({ attribute: "audience_country_0-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_10-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_20-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_30-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_40-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_50-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_60-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_70-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_80-100", operator: "and" });
  useRefinementList({ attribute: "audience_country_90-100", operator: "and" });

  useRefinementList({ attribute: "audience_city_0-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_10-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_20-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_30-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_40-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_50-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_60-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_70-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_80-100", operator: "and" });
  useRefinementList({ attribute: "audience_city_90-100", operator: "and" });

  useRefinementList({ attribute: "audience_ageRanges_0-100", operator: "and" });
  useRefinementList({
    attribute: "audience_ageRanges_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_ageRanges_90-100",
    operator: "and",
  });

  //yt facets

  useRefinementList({
    attribute: "audience_country_video_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_video_90-100",
    operator: "and",
  });

  useRefinementList({
    attribute: "audience_country_shorts_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_country_shorts_90-100",
    operator: "and",
  });

  useRefinementList({
    attribute: "audience_city_video_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_video_90-100",
    operator: "and",
  });

  useRefinementList({
    attribute: "audience_city_shorts_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_city_shorts_90-100",
    operator: "and",
  });

  useRefinementList({
    attribute: "audience_age_ranges_video_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_video_90-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_0-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_10-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_20-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_30-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_40-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_50-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_60-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_70-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_80-100",
    operator: "and",
  });
  useRefinementList({
    attribute: "audience_age_ranges_shorts_90-100",
    operator: "and",
  });

  return (
    <div>
      <Row
        style={{
          height: "91vh",
          overflowY: "scroll",
        }}
      >
        <Col>
          <main>
            <section style={{ padding: "25px 0 0 0", overflowY: "scroll" }}>
              <Radio.Group
                style={{ marginBlock: 10 }}
                onChange={(e) => {
                  // setPlatform(e.target.value);
                  // setIndexUiState({
                  //   refinementList: {},
                  //   range: {},
                  // });
                  // setSelectedCreatorList([]);
                }}
                value={platform}
              >
                <Radio.Button
                  value="instagram"
                  onClick={
                    () => {
                      if (platform !== "instagram")
                        window.open("/discover/connected/instagram", "_self");
                    }

                    // navigate("/discover/v2/instagram")
                  }
                >
                  Instagram
                </Radio.Button>
                <Radio.Button
                  value="youtube"
                  onClick={() => {
                    if (platform !== "youtube")
                      window.open("/connected/youtube", "_self");
                  }}
                >
                  Youtube
                </Radio.Button>
              </Radio.Group>

              <Filters platform={platform} />
            </section>

            {status === "loading" ? (
              <>
                <Skeleton active />
                <Skeleton active />
              </>
            ) : (
              <>
                <Row gutter={[15, 20]}>
                  <Col span={24}>
                    {results?.hits?.length <= 0 ? (
                      <Row
                        style={{
                          backgroundColor: "white",
                          padding: "30px 0px",
                        }}
                        justify="center"
                      >
                        <Space direction="vertical" align="center">
                          <img src="/assets/img/empty.png" alt="" />
                          <p style={{ fontWeight: 500, fontSize: "16px" }}>
                            No search results found
                          </p>
                        </Space>
                      </Row>
                    ) : (
                      <ResultsTable platform={platform} />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </main>
          <Row
            justify="center"
            style={{
              marginTop: "30px",
              background: "#FAFAFA",
              // padding: "25px 0",
            }}
          >
            <Col offset={2}>
              <AlgoliaPagination />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DiscoverV2;
