import { sendRequest } from "../helper";

export interface IMetrics {
  status: string;
  message: string;
  data: string[];
}

export const getPinnedMetrics = (platform: "instagram" | "youtube") => {
  const queryKey = [platform, "pinnedMetrics"];
  const queryFunction = () =>
    sendRequest<IMetrics>(
      "get",
      `/brand/report-only/pinned?platform=${platform}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
