import React from "react";
import { Row, Col } from "antd";
import { Bar, Doughnut } from "react-chartjs-2";
import { LuPin } from "react-icons/lu";

import styles from "./../../../AudienceInsights/styles.module.css";

import { PinnedMetric } from "../../../IgAnalytics";

import { InfluencerResult } from "../../../../../handler/Profile";
import { formatData } from "../../../../../utils";
import StatsCard from "../../../AudienceInsights/StatsCard";

type AudienceInsightsProps = {
  audienceInsights: PinnedMetric[];
  influencerData: InfluencerResult;
  setPinnedMetrices:
    | React.Dispatch<React.SetStateAction<PinnedMetric[]>>
    | undefined;
};

const AudienceInsights: React.FC<AudienceInsightsProps> = (props) => {
  const { audienceInsights, influencerData, setPinnedMetrices } = props;

  // AGE GENDER INSIGHTS
  const getAgeGenderData = (
    typeSelected: PinnedMetric["type"],
    breakDownSelected: PinnedMetric["period"]
  ) => {
    let totalObject: { [key: string]: { [key: string]: any } } = {};
    try {
      switch (typeSelected) {
        case "followers": {
          if (
            influencerData?.data?.user
              ?.follower_demographics_last_30_days_age_gender?.isBreakDown &&
            !influencerData?.data?.user
              ?.follower_demographics_last_30_days_age_gender?.isError &&
            influencerData?.data?.user
              ?.follower_demographics_last_30_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.follower_demographics_last_30_days_age_gender?.breakDowns
                ?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
            break;
          }
          break;
        }
        case "engAudience":
          if (
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isBreakDown &&
            !influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isError &&
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.engaged_audience_demographics_last_90_days_age_gender
                ?.breakDowns?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
          }
          break;

        case "reAudience":
          if (
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isBreakDown &&
            !influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isError &&
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_age_gender
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.reached_audience_demographics_last_90_days_age_gender
                ?.breakDowns?.age_gender
            )?.forEach((ele) => {
              const [age, gender] = ele[0].split("_");
              const value = ele[1];
              totalObject[age] = { ...totalObject[age], [gender]: value };
            });
          }
      }
    } catch (error) {
      console.log("err", error);
    }

    return totalObject;
  };

  const ageGenderInsights = audienceInsights
    .filter((item) => item.title === "Age-Gender Ratio")
    .map((item) => ({
      ...item,
      data: getAgeGenderData(item.type, item.period),
    }));

  type foo = (typeof ageGenderInsights)[number]["data"];

  const barData = (data: foo) => ({
    labels: Object.keys(data),
    datasets: [
      {
        label: "Female",
        data: Object.values(data).map((item) => item?.F),
        backgroundColor: "#FF6384",
        borderRadius: 4,
      },
      {
        label: "Male",
        data: Object.values(data).map((item) => item?.M || 0),
        backgroundColor: "#36A2EB",
        borderRadius: 4,
      },
      {
        label: "Unknown ",
        data: Object.values(data).map((item) => item?.U || 0),
        backgroundColor: "#FFCD56",
        borderRadius: 4,
      },
    ],
  });

  // CITY, COUNTRY & AGE INSIGHTS
  const restInsights = audienceInsights.filter(
    (item) =>
      !(item.title === "Male Female Ratio" || item.title === "Age-Gender Ratio")
  );

  const getAgeRangeData = (
    typeSelected: PinnedMetric["type"],
    breakDownSelected: PinnedMetric["period"]
  ) => {
    const totalObjectArray = Object.entries(
      getAgeGenderData(typeSelected, breakDownSelected)
    ).map((ele) => {
      return {
        label: ele[0],
        percent: Object.values(ele[1]).reduce(
          (prev, current) => current + prev,
          0
        ),
      };
    });

    const totalValue = totalObjectArray?.reduce(
      (prev, current) => current.percent + prev,
      0
    );

    return totalObjectArray?.map((item) => ({
      ...item,
      percent: (item.percent / (totalValue || 1)) * 100,
    }));
  };

  const getCityData = (
    typeSelected: PinnedMetric["type"],
    breakDownSelected: PinnedMetric["period"]
  ) => {
    let totalCityObject: { [key: string]: any } = {};

    try {
      switch (typeSelected) {
        case "followers": {
          if (
            influencerData?.data?.user?.follower_demographics_last_30_days_city
              ?.isBreakDown &&
            !influencerData?.data?.user?.follower_demographics_last_30_days_city
              ?.isError &&
            influencerData?.data?.user?.follower_demographics_last_30_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.follower_demographics_last_30_days_city?.breakDowns?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
          break;
        }
        case "engAudience":
          if (
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_city?.isBreakDown &&
            !influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_city?.isError &&
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.engaged_audience_demographics_last_90_days_city?.breakDowns
                ?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
          break;

        case "reAudience":
          if (
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_city?.isBreakDown &&
            !influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_city?.isError &&
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_city
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.reached_audience_demographics_last_90_days_city?.breakDowns
                ?.city
            )?.forEach((ele) => {
              totalCityObject[ele[0]] = ele[1];
            });
          }
      }
    } catch (error) {
      console.log("err", error);
    }

    const totalValue = Object.values(totalCityObject)?.reduce(
      (prev, current) => (current || 0) + prev,
      0
    );

    return Object.entries(totalCityObject)
      .map((ele) => {
        return {
          label: ele[0],
          percent:
            (ele[1] /
              ((typeSelected === "followers"
                ? influencerData?.data?.user?.followers_count
                : totalValue) || 1)) *
            100,
        };
      })
      .sort((a, b) => b.percent - a.percent);
  };

  const getCountryData = (
    typeSelected: PinnedMetric["type"],
    breakDownSelected: PinnedMetric["period"]
  ) => {
    let totalCountryObject: { [key: string]: any } = {};

    try {
      switch (typeSelected) {
        case "followers": {
          if (
            influencerData?.data?.user
              ?.follower_demographics_last_30_days_country?.isBreakDown &&
            !influencerData?.data?.user
              ?.follower_demographics_last_30_days_country?.isError &&
            influencerData?.data?.user
              ?.follower_demographics_last_30_days_country?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.follower_demographics_last_30_days_country?.breakDowns
                ?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
          break;
        }
        case "engAudience":
          if (
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_country
              ?.isBreakDown &&
            !influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_country?.isError &&
            influencerData?.data?.user
              ?.engaged_audience_demographics_last_90_days_country
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.engaged_audience_demographics_last_90_days_country?.breakDowns
                ?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
          break;

        case "reAudience":
          if (
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_country
              ?.isBreakDown &&
            !influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_country?.isError &&
            influencerData?.data?.user
              ?.reached_audience_demographics_last_90_days_country
              ?.isMetricDataAviable
          ) {
            Object.entries(
              influencerData?.data?.user
                ?.reached_audience_demographics_last_90_days_country?.breakDowns
                ?.country
            )?.forEach((ele) => {
              totalCountryObject[ele[0]] = ele[1];
            });
          }
      }
    } catch (error) {
      console.log("err", error);
    }

    const totalValue = Object.values(totalCountryObject)?.reduce(
      (prev, current) => (current || 0) + prev,
      0
    );

    return Object.entries(totalCountryObject)
      .map((ele) => {
        return {
          label: ele[0],
          percent:
            (ele[1] /
              ((typeSelected === "followers"
                ? influencerData?.data?.user?.followers_count
                : totalValue) || 1)) *
            100,
        };
      })
      .sort((a, b) => b.percent - a.percent);
  };

  // MALE FEMALE RAIO INSIGHTS
  const maleFemaleRatioInsights = audienceInsights.filter(
    (item) => item.title === "Male Female Ratio"
  );

  const getMaleFemaleRatioData = (
    type: PinnedMetric["type"],
    breakdown: PinnedMetric["period"]
  ) => {
    const female =
      Object.values(getAgeGenderData(type, breakdown))
        .map((item) => item?.F || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    const male =
      Object.values(getAgeGenderData(type, breakdown))
        .map((item) => item?.M || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    const unknown =
      Object.values(getAgeGenderData(type, breakdown))
        .map((item) => item?.U || 0)
        .reduce((prev, current) => prev + current, 0) || 0;
    return [female, male, unknown, female + male + unknown];
  };

  return (
    <div style={{ marginBlock: 20 }}>
      {ageGenderInsights.length > 0 && (
        <React.Fragment>
          <Row gutter={[20, 20]} style={{ marginBlock: 20 }}>
            {ageGenderInsights.map((item, key) => {
              return (
                <Col span={24} key={key}>
                  <div className={styles.graphContainer}>
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setPinnedMetrices?.((prev) => {
                          return prev.filter(
                            (x) =>
                              !(
                                x.title === item.title &&
                                x.period === item.period &&
                                x.type === item.type
                              )
                          );
                        });
                      }}>
                      <LuPin size={10} color={"#00000066"} />
                    </div>
                    <p className={styles.graphLabel}>
                      Age-Gender Ratio (
                      {item.type === "engAudience"
                        ? "Engaged Audience"
                        : item.type === "followers"
                        ? "Followers"
                        : "Reached Audience"}
                      )
                    </p>
                    <div
                      style={{
                        width: "100%",
                        height: "308px",
                      }}>
                      {Object.values(item.data).reduce(
                        (prev, current) =>
                          prev +
                            Object.values(current)?.reduce(
                              (x, y) => x + (y || 0),
                              0
                            ) || 0,
                        0
                      ) === 0 ? (
                        <p className="global-no-data">
                          Not enough Data To show this Metric
                        </p>
                      ) : (
                        <Bar
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "top" as const,
                              },
                              title: {
                                display: false,
                              },
                              datalabels: {
                                anchor: "end",
                                align: "top",
                                formatter: (value) =>
                                  formatData(value || 0, "a"),
                              },
                            },
                            scales: {
                              y: {
                                // display: false,
                              },
                              x: {
                                grid: {
                                  // display: false,
                                },
                              },
                            },
                          }}
                          data={barData(item.data)}
                          style={{ height: "320px" }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </React.Fragment>
      )}
      {restInsights.length > 0 && (
        <Row gutter={[20, 20]} style={{ marginBlock: 20 }}>
          {restInsights.map((item, key) => {
            return (
              <Col span={8} key={key}>
                <div className={styles.graphContainer}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPinnedMetrices?.((prev) => {
                        return prev.filter(
                          (x) =>
                            !(
                              x.title === item.title &&
                              x.period === item.period &&
                              x.type === item.type
                            )
                        );
                      });
                    }}>
                    <LuPin size={10} color={"#00000066"} />
                  </div>
                  <p className={styles.graphLabel}>
                    {item.title} ({" "}
                    {item.type === "engAudience"
                      ? "Engaged Audience"
                      : item.type === "followers"
                      ? "Followers"
                      : "Reached Audience"}
                    )
                  </p>
                  <StatsCard
                    items={
                      item.title === "Age Range"
                        ? getAgeRangeData(item.type, item.period)
                        : item.title === "City wise split"
                        ? getCityData(item.type, item.period)
                        : getCountryData(item.type, item.period)
                    }
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      {maleFemaleRatioInsights.length > 0 && (
        <Row gutter={[20, 20]} style={{ marginBlock: 20 }}>
          {maleFemaleRatioInsights.map((item, key) => {
            return (
              <Col span={8} key={key}>
                <div className={styles.graphContainer}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPinnedMetrices?.((prev) => {
                        return prev.filter(
                          (x) =>
                            !(
                              x.title === item.title &&
                              x.period === item.period &&
                              x.type === item.type
                            )
                        );
                      });
                    }}>
                    <LuPin size={10} color={"#00000066"} />
                  </div>
                  <p className={styles.graphLabel}>
                    Male Female Ratio (
                    {item.type === "engAudience"
                      ? "Engaged Audience"
                      : item.type === "followers"
                      ? "Followers"
                      : "Reached Audience"}
                    )
                  </p>
                  <div
                    style={{
                      height: "308px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    {getMaleFemaleRatioData(item.type, item.period)?.reduce(
                      (prev, current) => prev + current || 0,
                      0
                    ) === 0 ? (
                      <p className="global-no-data">
                        {" "}
                        Not enough Data To show this Metric{" "}
                      </p>
                    ) : (
                      <Doughnut
                        options={{
                          plugins: {
                            legend: { position: "top" },
                            datalabels: {
                              anchor: "end",
                              align: "start",

                              formatter: (value) => formatData(value || 0, "a"),
                              font: {
                                size: 16,
                                style: "normal",
                              },
                            },
                          },
                        }}
                        data={{
                          labels: [
                            `Female ${formatData(
                              getMaleFemaleRatioData(
                                item.type,
                                item.period
                              )[0] /
                                (getMaleFemaleRatioData(
                                  item.type,
                                  item.period
                                )[3] || 1),
                              "%"
                            )}`,
                            `Male ${formatData(
                              getMaleFemaleRatioData(
                                item.type,
                                item.period
                              )[1] /
                                (getMaleFemaleRatioData(
                                  item.type,
                                  item.period
                                )[3] || 1),
                              "%"
                            )}`,
                            `Unknown ${formatData(
                              getMaleFemaleRatioData(
                                item.type,
                                item.period
                              )[2] /
                                (getMaleFemaleRatioData(
                                  item.type,
                                  item.period
                                )[3] || 1),
                              "%"
                            )}`,
                          ],
                          datasets: [
                            {
                              label: "No",
                              data: getMaleFemaleRatioData(
                                item.type,
                                item.period
                              ).slice(0, 3),
                              backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCD56",
                              ],
                              borderColor: ["#FF6384", "#36A2EB", "#FFCD56"],
                              borderWidth: 1,
                              borderJoinStyle: "round",
                              borderRadius: 10,
                              hoverOffset: 4,
                              offset: 0,
                            },
                          ],
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};
export default AudienceInsights;
