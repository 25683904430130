import React, { useState } from "react";
import { Divider, Space, Table, Tag } from "antd";
import { useInstantSearch } from "react-instantsearch";
import { ColumnsType } from "antd/es/table";

import CreatorProfile from "../../Campaign/Manage/CreatorProfile";
import EditUserFieldsModal from "./EditUserFieldsModal";
import ReportButton from "../../Campaign/Report/ReportButton";

import styles from "../styles.module.css";

import { formatData } from "../../../utils";

import socialMediaUrlGen from "../../../utils/socialMediaUrlGen";

interface DataType {
  key: React.Key;
  handleName: string;
  username: string;
  dp: string;
  followers: number;
  engagement: number;
  email: string[];
  phoneNumber: { numberOf: string; number: string }[];
  isOnBoarded: boolean;
  tags: string[];
  collectionName: string;
  collectionId: string;
  influencerId?: string;
}

const ResultsTable = ({ platform }: { platform: "instagram" | "youtube" }) => {
  const { results, refresh } = useInstantSearch();
  const [creatorDrawerProps, setCreatorDrawerProps] = useState<{
    influencerId: string;
    visible: boolean;
  }>({ influencerId: "", visible: false });
  // const { selectedCreatorList, setSelectedCreatorList, platform } =
  //   useRevampSaasDiscoveryContext();

  const columns: ColumnsType<DataType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { dp, handleName, username, isOnBoarded }) => (
        <Space size={10} align="center">
          <div className={styles.profilePictureContainer}>
            <img src={dp ?? ""} className={styles.profile} alt="profile" />
          </div>
          <div>
            <p className={styles.name}>{handleName ?? ""}</p>
            <a
              className={styles.username}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              href={socialMediaUrlGen({
                userNameOrChannelId: username,
                platform,
              })}
              onClick={() => {
                //   if (isLocked) {
                //     return setIsPurchaseModalVisible(true);
                //   }
                //   navigate(`/influencerAnalysis/${influencerId ?? " "}`);
              }}
            >
              @{username ?? ""}
            </a>
            {isOnBoarded && (
              <Tag color="green" style={{ display: "block", marginBlock: 2 }}>
                Onbaorded
              </Tag>
            )}
          </div>
        </Space>
      ),
    },
    {
      title: "Followers",
      dataIndex: "followers",
      key: "Followers",
      render: (_, { followers }) => (
        <>
          <p className={styles.followers}>{formatData(followers, "a")}</p>
          <p className={styles.label}>Followers</p>
        </>
      ),
    },
    {
      title: "Engagement",
      dataIndex: "engagement",
      key: "Engagement",
      render: (_, { engagement }) => (
        <>
          <p className={styles.followers}>
            {formatData(engagement, "0.00")}
            {"%"}
          </p>
          <p className={styles.label}>{"Engagement"}</p>
        </>
      ),
    },
    {
      title: "Phone Numbers",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, { phoneNumber }) => (
        <>
          <p className={styles.label}>
            {phoneNumber?.map((item, idx) => (
              <>
                <span key={idx}>{item?.number}</span>
                <Divider type="vertical" />
              </>
            ))}
          </p>
        </>
      ),
    },
    {
      title: "Email Ids",
      dataIndex: "emailIds",
      key: "emailIds",
      render: (_, { email }) => (
        <>
          {/* <p className={styles.followers}>
            {formatData(engagement, platform === "instagram" ? "0.00" : "a")}
            {platform === "instagram" ? "%" : ""}
          </p> */}
          <p className={styles.label}>
            {email?.map((item, idx) => (
              <>
                <span key={idx}>{item}</span>
                <Divider type="vertical" />
              </>
            ))}
          </p>
        </>
      ),
    },

    {
      title: "Actions",
      render: (
        _,
        { tags, email, phoneNumber, collectionName, collectionId, influencerId }
      ) => (
        <div style={{ display: "grid", gap: "5px" }}>
          <ReportButton
            type="primary"
            onClick={() =>
              setCreatorDrawerProps({
                visible: true,
                influencerId: influencerId || "",
              })
            }
            disabled={!influencerId}
          >
            Open Profile
          </ReportButton>
          <ReportButton
            isOutline
            onClick={() => {
              setCreatorProfileProps({
                type: "tags",
                visible: true,
                data: tags,
                collectionId,
                collectionName,
                phoneData: [],
              });
            }}
          >
            Edit Tags
          </ReportButton>
          <ReportButton
            isOutline
            onClick={() => {
              setCreatorProfileProps({
                type: "email",
                visible: true,
                data: email,
                collectionId,
                collectionName,
                phoneData: [],
              });
            }}
          >
            Edit Email Id's
          </ReportButton>
          <ReportButton
            isOutline
            onClick={() => {
              setCreatorProfileProps({
                type: "number",
                visible: true,
                data: [],
                phoneData: phoneNumber,
                collectionId,
                collectionName,
              });
            }}
            disabled={collectionName === "socialmediainfluencermodels"}
          >
            Edit Phone Numbers
          </ReportButton>
        </div>
      ),
    },
  ];

  const [creatorProfileProps, setCreatorProfileProps] = useState<{
    type: "email" | "number" | "tags";
    visible: boolean;
    phoneData?: { numberOf: string; number: string }[];
    data?: string[];
    collectionId: string;
    collectionName: string;
  }>({
    type: "email",
    visible: false,
    phoneData: [],
    data: [],
    collectionId: "",
    collectionName: "",
  });

  return (
    <>
      <Table
        // rowSelection={{
        //   selectedRowKeys: selectedCreatorList,
        //   onChange: (_, selectedRows) => {
        //     return setSelectedCreatorList(
        //       selectedRows?.map((item) => item.socialMediaId)
        //     );
        //   },
        // }}
        columns={columns}
        dataSource={results?.hits?.map((hitItem) => ({
          key: hitItem?.all_engagementRate || "",
          handleName: hitItem?.fullname || hitItem?.name || "",
          username: hitItem?.username || "",
          dp: hitItem?.profilePicUrl || "",
          followers: hitItem?.followerCount || 0,
          engagement: hitItem?.all_engagementRate,
          email: hitItem?.emailIds || [],
          phoneNumber: hitItem?.phoneNumbers || [],
          isOnBoarded: hitItem?.isOnBoarded || false,
          tags: hitItem?.tags || [],
          collectionId: hitItem?.collectionId || "",
          collectionName: hitItem?.collectionName || "",
          influencerId: hitItem?.influencerId || "",
        }))}
        pagination={false}
        scroll={{ y: 540 }}
      />
      <EditUserFieldsModal
        handleClose={() =>
          setCreatorProfileProps({
            visible: false,
            type: "email",
            data: [],
            phoneData: [],
            collectionId: "",
            collectionName: "",
          })
        }
        handleSubmit={() => {
          setTimeout(() => {
            refresh();
          }, 2000);

          setCreatorProfileProps({
            visible: false,
            type: "email",
            data: [],
            phoneData: [],
            collectionId: "",
            collectionName: "",
          });
        }}
        visible={creatorProfileProps.visible}
        type={creatorProfileProps.type}
        data={creatorProfileProps.data}
        phoneData={creatorProfileProps.phoneData}
        collectionId={creatorProfileProps.collectionId}
        collectionName={creatorProfileProps.collectionName}
      />
      <CreatorProfile
        handleClose={() => {
          setCreatorDrawerProps({ influencerId: "", visible: false });
        }}
        influencerId={creatorDrawerProps.influencerId}
        isVisible={creatorDrawerProps?.visible}
      />
    </>
  );
};

export default ResultsTable;
