import { sendRequest } from "../helper";

import { InfluencerMedia } from "../../typings";

type InfluencerMediaResult = {
  status: string | null;
  message: string | null;
  data: InfluencerMedia;
};

export const getInfluencerMedia = (influencerId: string) => {
  const queryKey = ["influencer", influencerId, "media"];
  const queryFunction = () =>
    sendRequest<InfluencerMediaResult>(
      "get",
      `/brand/brand-influencer/media-kit?influencerId=${influencerId}`,
      false,
      undefined
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
