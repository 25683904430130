import React, { useState } from "react";
import { Row, Space } from "antd";

import ReportButton from "../../ReportButton";
import AddReport from "../../Modals/AddReport";
import DeleteReport from "../../Modals/DeleteReport";

import {
  IndividualInfluencerReport,
  YtMediaResponseType,
} from "../../../../../typings";

import DeliverableSelect from "../../Modals/SharedComponents/DeliverableSelect";

interface MediasProps {
  data: IndividualInfluencerReport;
  handleSelect: (id: string) => void;
  selectedId: string;
}

const Medias: React.FC<MediasProps> = ({ data, handleSelect, selectedId }) => {
  const [addMediaVisible, setAddMediaVisible] = useState<boolean>(false);
  const [deleteMediaVisible, setDeleteMediaVisible] = useState<boolean>(false);

  return (
    <>
      <div style={{ display: "grid", gap: "20px" }}>
        <Row justify="space-between">
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "19px",
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            Media Added to Report
          </p>
          <Space size={10}>
            <ReportButton
              ghost
              style={{ textDecoration: "underline" }}
              onClick={() => setDeleteMediaVisible(true)}
            >
              Delete Media
            </ReportButton>
            <ReportButton
              ghost
              isOutline
              onClick={() => setAddMediaVisible(true)}
            >
              Add Media
            </ReportButton>
          </Space>
        </Row>
        <Space size={10} style={{ overflowY: "auto" }}>
          {data?.reportInfluencer.map((item, idx) => (
            <DeliverableSelect
              isLabelClickEnabled={false}
              isSelected={selectedId === item.deliverableMediaId}
              handleClick={() => handleSelect(item?.deliverableMediaId)}
              id={item?._id}
              label={item?.deliverableType}
              url={item?.media?.savedMedias[0]?.url || ""}
              key={item?._id}
              type={
                data?.platform === "instagram"
                  ? item?.media?.savedMedias[0]?.mediaType || "IMAGE"
                  : "yt-video"
              }
              caption={
                data?.platform === "youtube"
                  ? (item?.media as YtMediaResponseType)?.title
                  : undefined
              }
              profilePic={data?.socialMediaKit?.profilePicUrl}
            />
          ))}
        </Space>
      </div>
      <AddReport
        visible={addMediaVisible}
        setVisible={setAddMediaVisible}
        campaignId={data?.campaignId}
        platform={data?.platform}
        creator={{ ...data, noOfMedias: 0 }}
      />
      <DeleteReport
        visible={deleteMediaVisible}
        setVisible={setDeleteMediaVisible}
        campaignId={data?.campaignId}
        platform={data?.platform}
        creator={{ ...data, noOfMedias: 0 }}
      />
    </>
  );
};

export default Medias;
