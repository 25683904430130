import React, { useState } from "react";
import { Table, Button, Spin, Empty, Switch, Row, Col, Skeleton } from "antd";
import { useQuery } from "react-query";
import { getAllCampaigns } from "../../handler/cloutflowCampaign/getAllCampaigns";
import { format } from "date-fns";
import CreateCampaignModal from "./CreateCampaignModal";

const CloutflowCampaignTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showArchived, setShowArchived] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [getCampaignsKey, getCampaignsFn] = getAllCampaigns({
    page,
    limit: pageSize,
    isArchived: showArchived,
  });

  const { data, isLoading } = useQuery(getCampaignsKey, getCampaignsFn);

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created Date",
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (text: string) => format(new Date(text), "d MMMM yyyy"),
    },

    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Button
            type="default"
            style={{ borderRadius: "8px" }}
            onClick={() => openCampaign(record)}
          >
            Open Campaign
          </Button>
        </div>
      ),
    },
  ];
  console.log(data?.data);

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const openCampaign = (campaign: any) => {
    // Add your logic to open the campaign here
    window.open(`/campaign/${campaign._id}`, "_blank");
  };

  const handleSwitchChange = (checked: boolean) => {
    setShowArchived(checked);
    setPage(1); // Reset to the first page when toggling between archived and active campaigns
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }

  return (
    <div>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "12px" }}
      >
        <Row justify="end" style={{ width: "100%" }}>
          {/* <Switch
            checked={showArchived}
            onChange={handleSwitchChange}
            checkedChildren="Archived"
            unCheckedChildren="Active"
          /> */}
          <Button
            type="primary"
            onClick={showModal}
            style={{ borderRadius: "8px" }}
          >
            Create Campaign
          </Button>
        </Row>
      </Row>
      {data?.data?.length === 0 ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Empty description="No Campaigns Found" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data?.data}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: data?.total,
          }}
          onChange={handleTableChange}
          rowKey="name"
        />
      )}
      <CreateCampaignModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default CloutflowCampaignTable;
