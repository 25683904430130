import { sendRequest } from "../helper";
import { TableItem } from "../../components/Invite/Steps";

interface inviteInfluencerProps {
  inviteList: Omit<TableItem, "key">[];
}

export const inviteInfluencer = ({ inviteList }: inviteInfluencerProps) => {
  const newPayLoad = inviteList.map((item) =>
    Object.fromEntries(
      Object.entries(item)
        .filter(([key, value]) => {
          return (
            key !== "key" &&
            value != null &&
            value !== undefined &&
            String(value).trim() !== ""
          );
        })
        .map(([key, value]) => {
          if (key === "name") {
            return ["fullName", String(value)];
          }
          return [key, String(value)];
        })
    )
  );

  return sendRequest(
    "post",
    `/brand/connect-request/email/influencer-connect-request`,
    false,
    {
      invitePayload: newPayLoad,
    }
  ).then((res) => res.data);
};
