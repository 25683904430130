import React, { useState, useEffect, useRef } from "react";
import { Modal, Row, Col, message, Divider, Button, Alert } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { stringify } from "querystring";
import { AxiosError } from "axios";

import { getUser } from "../../../../../handler/getUser";
import { onboardUser } from "../../../../../handler/onboardUser";

import BusinessDetails from "./BusinessDetails";
import PersonalDetails from "./PersonalDetails";

import styles from "./styles.module.css";

export interface UserType {
  name: string;
  lastname: string;
  designation: string;
  phoneNumber: string;
  businessType: "Brand" | "Agency";
  businessLogoUrl: string;
  businessName: string;
  businessWebsiteUrl: string;
  businessNiche: string[];
  isPhoneVerified: boolean;
}

interface DetailsScreenProps {
  handleSubmit: () => void;
  handleCancel: () => void;
  isVisible: boolean;
}

const DetailsScreen: React.FC<DetailsScreenProps> = ({
  handleSubmit,
  handleCancel,
  isVisible,
}) => {
  const queryClient = useQueryClient();
  const editUserDetailsMutation = useMutation(onboardUser, {
    onSuccess: () => {
      //
      handleSubmit();
      message.success("Details Updated");
      queryClient.invalidateQueries(["user"]);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  const handleFormSubmit = () => {
    if (!checkIsDataValid()) {
      return message.error("Please recheck the form");
    }
    return editUserDetailsMutation.mutate({
      name: userData.name,
      lastname: userData.lastname,
      designation: userData.designation,
      businessType: userData.businessType,
      logoUrl: userData.businessLogoUrl,
      businessName: userData.businessName,
      websiteUrl: userData.businessWebsiteUrl,
      niche: userData.businessNiche,
    });
  };

  const [getUserKey, getUserFn] = getUser();
  const firstRender = useRef(true);

  const { data: initialUserData } = useQuery(getUserKey, getUserFn);

  const [userData, setUerData] = useState<UserType>({
    name: "",
    lastname: "",
    phoneNumber: "",
    businessLogoUrl: "",
    businessName: "",
    businessNiche: [],
    businessType: "Brand",
    businessWebsiteUrl: "",
    designation: "",
    isPhoneVerified: false,
  });
  const [userDataErr, setUerDataErr] = useState<UserType>({
    name: "",
    lastname: "",
    phoneNumber: "",
    businessLogoUrl: "",
    businessName: "",
    businessNiche: [],
    businessType: "Brand",
    businessWebsiteUrl: "",
    designation: "",
    isPhoneVerified: false,
  });

  useEffect(() => {
    if (initialUserData?.data && firstRender.current) {
      setUerData({
        name: initialUserData.data.name,
        lastname: initialUserData.data.lastname,
        phoneNumber: initialUserData.data.phoneNumber,
        businessLogoUrl: initialUserData.data.businessDetails.logoUrl,
        businessName: initialUserData.data.businessDetails.name,
        businessNiche: initialUserData.data.businessDetails.niche,
        businessType:
          (initialUserData.data.businessDetails.businessType as
            | "Brand"
            | "Agency") || "Brand",
        businessWebsiteUrl: initialUserData.data.businessDetails.websiteUrl,
        designation: initialUserData.data.designation,
        isPhoneVerified: initialUserData.data.isPhoneVerified,
      });
      firstRender.current = false;
    }
  }, [initialUserData]);

  const isValidUrl = (urlString: string) => {
    if (urlString === "") return true;
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const checkIsDataValid = () => {
    let isError = false;
    let errorObject: UserType = {
      name: "",
      lastname: "",
      phoneNumber: "",
      businessLogoUrl: "",
      businessName: "",
      businessNiche: [],
      businessType: "Brand",
      businessWebsiteUrl: "",
      designation: "",
      isPhoneVerified: userData?.isPhoneVerified || false,
    };
    if (userData.name.trim() === "") {
      errorObject = { ...errorObject, name: "Please Enter Name" };
      isError = true;
    }
    if (userData.lastname.trim() === "") {
      errorObject = { ...errorObject, lastname: "Please Enter Last Name" };
      isError = true;
    }
    if (userData.designation.trim() === "") {
      errorObject = { ...errorObject, designation: "Please Enter Name" };
      isError = true;
    }

    if (!userData?.phoneNumber.trim()) {
      errorObject = {
        ...errorObject,
        phoneNumber: "Please Enter Phone Number",
      };
      isError = true;
    }
    if (userData?.phoneNumber && !userData?.isPhoneVerified) {
      errorObject = {
        ...errorObject,
        phoneNumber: "Please Verify your phone Number First",
      };
      isError = true;
    }

    if (
      !userData?.businessWebsiteUrl ||
      !isValidUrl(userData?.businessWebsiteUrl)
    ) {
      errorObject = {
        ...errorObject,
        businessWebsiteUrl: "Please Enter a Valid Url",
      };
    }
    if (!userData?.businessName.trim()) {
      errorObject = {
        ...errorObject,
        businessName: `Please Enter ${userData.businessType} Name`,
      };
      isError = true;
    }

    if (!userData?.businessLogoUrl) {
      errorObject = {
        ...errorObject,
        businessLogoUrl: "Please Upload a Logo",
      };
      isError = true;
    }
    setUerDataErr({ ...errorObject });
    return !isError;
  };

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      onCancel={handleCancel}
      title={
        <Row justify="end">
          <Button
            type="text"
            className={styles.closeButton}
            onClick={() => handleCancel()}>
            {" "}
            Close
          </Button>
        </Row>
      }
      centered
      style={{ borderRadius: "20px" }}
      bodyStyle={{ padding: "0 30px  0 44px" }}
      okText="Save"
      onOk={handleFormSubmit}
      width={"1100px"}
      destroyOnClose
      closable={false}
      confirmLoading={editUserDetailsMutation.isLoading}
      okButtonProps={{
        type: "primary",
        // ghost: true,
        style: { borderRadius: "10px", backgroundColor: "#533af5" },
      }}
      cancelButtonProps={{ style: { borderRadius: "20px" } }}
      // okButtonProps={{ disabled: handleDisabledCheck() }}
    >
      <div className={styles.scrollableContainer}>
        {initialUserData?.data?.kyc?.remarks && (
          <Alert
            message="Alert"
            description={initialUserData?.data?.kyc?.remarks}
            type="warning"
            showIcon
            style={{ margin: "20px 0" }}
          />
        )}
        <PersonalDetails
          inputValues={userData}
          errors={userDataErr}
          setInputValues={setUerData}
          setErrors={setUerDataErr}
        />
        <Divider />
        <BusinessDetails
          inputValues={userData}
          errors={userDataErr}
          setInputValues={setUerData}
          setErrors={setUerDataErr}
        />
      </div>
    </Modal>
  );
};

export default DetailsScreen;
