import React from "react";
import { Popover } from "antd";
import { useQuery } from "react-query";

import { getUser } from "../../../handler/getUser";
import { auth } from "../../../firebase";

interface Props {
  children: React.ReactNode;
  isDisabled: boolean;
  customText?: string;
}

const DisabledPopOver: React.FC<Props> = ({
  isDisabled,
  children,
  customText,
}) => {
  const [userKey, userFn] = getUser();
  const { data: userData } = useQuery(userKey, userFn);

  const firebaseUserId = auth?.currentUser?.uid;
  const isOwner = firebaseUserId === userData?.data?._id;

  if (!isDisabled) return <React.Fragment>{children}</React.Fragment>;
  return (
    <Popover
      content={
        customText ?? !isOwner
          ? "Contact Organization Admin to enable this feature"
          : "You need to upgrade your plan to enable this feature"
      }
      title={null}
      trigger="hover"
      placement="top"
      arrowPointAtCenter>
      <div>{children}</div>
    </Popover>
  );
};

export default DisabledPopOver;
