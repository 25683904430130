import { sendRequest } from "../../helper";

export type releasePaymentProps = {
  offerId: string;
};

export const releasePayment = ({ offerId }: releasePaymentProps) => {
  return sendRequest(
    "put",
    `/brand/transaction/release?finalizedOfferId=${offerId}`,
    false
  ).then((res) => res.data);
};
