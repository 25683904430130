import { sendRequest } from "../helper";

import { InfluencerCampaigns } from "../../typings";

type InfluencerCampaignsResult = {
  status: string | null;
  message: string | null;
  data: InfluencerCampaigns;
};

export const getInfluencerCampaigns = (
  influencerId: string,
  limit?: number
) => {
  const queryKey = ["influencer", influencerId, "campaigns", limit];
  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<InfluencerCampaignsResult>(
      "get",
      `/brand/brand-influencer/campaign?limit=${
        limit ? limit : 4
      }&influencerId=${influencerId}${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
