import React from "react";

import ReportModal from "../../../Campaign/Report/ReportModal";

import EmailConfig from "../../../Profile/EmailConfig";

interface AddEmailModalProps {
  visible: boolean;
  handleSubmit: () => void;
}

const AddEmailModal: React.FC<AddEmailModalProps> = ({
  visible,
  handleSubmit,
}) => {
  return (
    <ReportModal
      title="Add Email"
      onCancel={() => handleSubmit()}
      visible={visible}
      footer={null}>
      <EmailConfig inModal handleModalSubmit={handleSubmit} />
    </ReportModal>
  );
};

export default AddEmailModal;
