import React from "react";
import { Button, Result } from "antd";

class ErrorBoundary extends React.Component {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    console.log(error.toString(), info.componentStack);
  }

  // A fake logging service.

  render() {
    const handleWebviewEvent = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "reloadApp" })
        );
      }
    };

    if (this.state.errorMessage) {
      return (
        <Result
          status="500"
          title="500"
          //   image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          style={{
            "--image-height": "150px",
          }}
          subTitle={
            <span style={{ padding: "0 24px" }}>
              An unknown error has occurred, please click the button to reload
              the app
            </span>
          }
          extra={
            <Button
              color="primary"
              onClick={() => {
                handleWebviewEvent();
                window && window.location.reload();
              }}>
              Refresh App
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
