import React, { useState } from "react";
import { Row, Col, Input, Space } from "antd";
import { useQuery } from "react-query";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/Rocket.json";
import animationDataEmptyReport from "../../../assets/lottie/astronot.json";
import { HiMagnifyingGlass } from "react-icons/hi2";

import { MdAccountBox, MdOutlineDateRange } from "react-icons/md";
import { FaDollarSign, FaInstagram, FaYoutube } from "react-icons/fa";
import { BsCurrencyDollar, BsFilterLeft } from "react-icons/bs";
import { BiSolidUser } from "react-icons/bi";

import MetricCard from "./MetricCard";
import ReportButton from "../ReportButton";
import CreatorCard from "./CreatorCard";

import AddInfluencerModal from "../Modals/AddInfluencer";
import CustomDropDown from "../../../shared/Custom/DropDown";

import styles from "./styles.module.css";

import globalStyles from "../Modals/styles.module.css";
import "./styles.module.css";

import { getCampaignAggregate } from "../../../../handler/report";
import { formatData } from "../../../../utils";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ReportOptions = {
  loop: true,
  autoplay: true,
  animationData: animationDataEmptyReport,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const InfluencerScreen: React.FC<{
  campaignId: string;
  platform: "instagram" | "youtube";
}> = ({ campaignId, platform }) => {
  const [queryKey, queryFn] = getCampaignAggregate({
    campaignId: campaignId,
  });

  const { data, isLoading } = useQuery(queryKey, queryFn);

  const [visible, setVisible] = useState<boolean>(false);

  // const [searchQueryInput, setSearchQueryInput] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [sortCriteria, setSortCriteria] = useState<
    "followers" | "price" | "date" | null
  >(null);

  return (
    <div>
      <h1>Overview</h1>
      <Col span={22}>
        <Row gutter={[20, 20]}>
          <Col span={7}>
            <MetricCard
              title="Influencers added"
              desc="The total number of influencers in campaign"
              icon={<MdAccountBox size={24} color="#7F7F7F" />}
              metricNumber={data?.data?.length || 0}
              isLoading={isLoading}
              extra={
                <ReportButton
                  type="default"
                  style={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => setVisible(true)}>
                  {" "}
                  Add Influencer
                </ReportButton>
              }
              emptyText="No Influencer Added"
            />
          </Col>
          <Col span={7}>
            <MetricCard
              title="Medias added"
              desc="The total number of media received by all influencers "
              icon={
                platform === "instagram" ? (
                  <FaInstagram size={20} color="#7F7F7F" />
                ) : (
                  <FaYoutube size={20} color="#7F7F7F" />
                )
              }
              metricNumber={data?.data?.reduce(
                (prev, current) => Number(current?.noOfMedias) + prev,
                0
              )}
              emptyText="No Media Added"
              // extra={
              //   <ReportButton
              //     type="default"
              //     style={{
              //       width: "150px",
              //       display: "flex",
              //       justifyContent: "center",
              //     }}
              //     onClick={() => setVisible(true)}>
              //     {" "}
              //     Add Media
              //   </ReportButton>
              // }
            />
          </Col>
          <Col span={7}>
            <MetricCard
              title="Budget"
              desc="The total Price on influencers
              calculated by prices entered "
              icon={<BsCurrencyDollar size={20} color="#7F7F7F" />}
              metricNumber={
                (data?.data?.reduce(
                  (prev, current) => Number(current?.brandPrice) + prev,
                  0
                ) || 0) > 0
                  ? `₹ ${formatData(
                      data?.data.reduce(
                        (prev, current) => Number(current?.brandPrice) + prev,
                        0
                      ) || 0,
                      "a"
                    )}`
                  : 0
              }
              emptyText="Add influencers 
              to enter the prices"
            />
          </Col>
        </Row>
      </Col>

      <div style={{ marginTop: "20px" }}>
        {isLoading ? (
          <Row justify="center" style={{ width: "100%" }}>
            <Lottie options={defaultOptions} height={300} width={170} />
          </Row>
        ) : (
          <div>
            {(data?.data?.length || 0) > 0 && (
              <Row>
                <Col span={14}>
                  <section
                    className={styles.inputContainer}
                    style={{ width: "100%" }}>
                    <Space size={2} align="center" style={{ width: "100%" }}>
                      <HiMagnifyingGlass
                        size={18}
                        color="rgba(0, 0, 0, 0.45)"
                      />
                      <Input
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value || "")}
                        onPressEnter={() => setSearchQuery(searchQuery)}
                        placeholder={"Search by name or username"}
                        className={styles.input}
                        size="large"
                        style={{
                          borderColor: "transparent",
                          boxShadow: "none",
                          width: "100%",
                          minWidth: "400px",
                        }}
                      />
                    </Space>
                    <ReportButton
                      type="primary"
                      onClick={() => setSearchQuery(searchQuery)}
                      style={{ padding: "10px 20px " }}>
                      Search
                    </ReportButton>
                  </section>
                </Col>
                <Col
                  span={2}
                  offset={8}
                  style={{ display: "flex", alignItems: "center" }}>
                  <CustomDropDown
                    items={[
                      {
                        label: (
                          <div
                            style={{
                              color: "#8c8c8c",
                            }}>
                            Sort By
                          </div>
                        ),
                        key: "0",
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                        },
                        disabled: true,
                      },
                      {
                        label: (
                          <div
                            style={{
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}>
                            <BiSolidUser />
                            Followers
                          </div>
                        ),
                        key: "1",
                        onClick: (e) => {
                          if (sortCriteria === "followers") {
                            setSortCriteria(null);
                          } else {
                            setSortCriteria("followers");
                          }
                          e.domEvent.stopPropagation();
                        },
                      },
                      {
                        label: (
                          <div
                            style={{
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}>
                            <FaDollarSign />
                            Price
                          </div>
                        ),
                        key: "2",
                        onClick: (e) => {
                          if (sortCriteria === "price") {
                            setSortCriteria(null);
                          } else {
                            setSortCriteria("price");
                          }
                          e.domEvent.stopPropagation();
                        },
                      },
                      {
                        label: (
                          <div
                            style={{
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}>
                            <MdOutlineDateRange />
                            Date Added
                          </div>
                        ),
                        key: "3",
                        onClick: (e) => {
                          if (sortCriteria === "date") {
                            setSortCriteria(null);
                          } else {
                            setSortCriteria("date");
                          }
                          e.domEvent.stopPropagation();
                        },
                      },
                    ]}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={styles.optionsContainer}
                      style={{
                        textAlign: "right",
                      }}>
                      <BsFilterLeft color={"#8C8C8C"} size={24} />
                    </a>
                  </CustomDropDown>
                </Col>
              </Row>
            )}
            <Row gutter={[0, 10]} style={{ paddingBottom: "40px" }}>
              {(
                data?.data?.filter((creatorItem) =>
                  creatorItem.searchAbleParams
                    .toLowerCase()
                    .includes(searchQuery.trim().toLowerCase())
                ) || []
              ).length > 0 ? (
                <>
                  {data?.data
                    ?.filter((creatorItem) =>
                      creatorItem.searchAbleParams
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase())
                    )
                    ?.sort((a, b) => {
                      if (sortCriteria === "followers") {
                        return (
                          b.socialMediaKit.followerCount -
                          a.socialMediaKit.followerCount
                        );
                      } else if (sortCriteria === "price") {
                        return b.brandPrice - a.brandPrice;
                      } else if (sortCriteria === "date") {
                        return (
                          new Date(b.statusHistory[0].dateTime).getTime() -
                          new Date(a.statusHistory[0].dateTime).getTime()
                        );
                      } else {
                        return 0;
                      }
                    })
                    ?.map((item, idx) => (
                      <Col span={24}>
                        <CreatorCard
                          key={idx}
                          creatorData={item}
                          campaignId={campaignId}
                          platform={platform}
                        />
                      </Col>
                    ))}
                </>
              ) : (
                <Col span={18} style={{ marginTop: "80px" }}>
                  <Lottie options={ReportOptions} height={120} width={300} />
                  <div
                    style={{
                      display: "grid",
                      gap: "14px",
                      textAlign: "center",
                      marginTop: "40px",
                    }}>
                    <p className={globalStyles.label}>
                      Oh, this is an empty space!{" "}
                    </p>
                    <p className={globalStyles.label}>
                      {(data?.data?.length || 0) > 0
                        ? ` No Influencer found by name or username of ${searchQuery}`
                        : "No Influencer Added to the campaign"}
                    </p>
                    <p className={globalStyles.label}>
                      You can{" "}
                      <span
                        className={globalStyles.highlightedLink}
                        style={{ textDecoration: "underline" }}
                        onClick={() => setVisible(true)}>
                        Find {searchQuery}
                      </span>{" "}
                      and add to campaign <br />
                      Or directly{" "}
                      <span
                        className={globalStyles.highlightedLink}
                        onClick={() => setVisible(true)}
                        style={{ textDecoration: "underline" }}>
                        Add The Report
                      </span>{" "}
                      to the campaign
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>
      <AddInfluencerModal
        visible={visible}
        setVisible={setVisible}
        campaignId={campaignId}
        platform={platform}
      />
    </div>
  );
};

export default InfluencerScreen;
