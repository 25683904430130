import React, { useState, useEffect, useRef } from "react";
import { Select, Row, Space } from "antd";
import { IoIosClose } from "react-icons/io";

import { ConfigTypeSingle } from "../../../constants";
import { useQuery } from "react-query";

import { getFacetsAlgolia } from "../../../../../handler/algoliaSearch/getFacetValues";

import { useInstantSearch } from "react-instantsearch-core";

const MultiDimensionFilter: React.FC<{
  facet: ConfigTypeSingle;
  platform: "instagram" | "youtube";
}> = ({ facet, platform }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const firstRender = useRef(true);
  const { setIndexUiState, indexUiState } = useInstantSearch();

  const [getFacetsKey, getFacetsVal] = getFacetsAlgolia({
    facetName: `audience_${facet.facetName}_0-100`,
    platform,
    query: searchQuery,
  });

  const { data } = useQuery(getFacetsKey, getFacetsVal);

  const [selectedValues, setSelectedValues] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const handleChange = (option: any) => {
    const { parentLabel, value } = option;

    return setSelectedValues((selectedValues) =>
      selectedValues.map((selItem) => {
        if (selItem.label === parentLabel) {
          return { label: parentLabel, value: `${parentLabel}_${value}` };
        }
        return selItem;
      })
    );
    // return;
  };

  useEffect(() => {
    if (!firstRender.current) {
      let newRefinementObject: { [x: string]: string[] } = {};
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((item) => {
        newRefinementObject = {
          ...newRefinementObject,
          [`audience_${facet.facetName}_${item * 10}-100`]: [],
        };
      });

      selectedValues.forEach((selItem) => {
        const bucketName = `audience_${facet.facetName}_${selItem?.value
          .split("_")
          .at(-1)}`;
        newRefinementObject = {
          ...newRefinementObject,
          [bucketName]: [...newRefinementObject[bucketName], selItem.label],
        };
      });

      setIndexUiState((indexUiState) => {
        return {
          ...indexUiState,
          refinementList: {
            ...indexUiState.refinementList,
            ...newRefinementObject,
          },
        };
      });
    }
  }, [selectedValues]);

  useEffect(() => {
    // console.log("hello");
    if (indexUiState && firstRender.current) {
      const refinementList = indexUiState?.refinementList || {};
      let selectedValuesBucket: { label: string; value: string }[] = [];
      Object.keys(refinementList || {})
        .filter((keyItem) => keyItem.includes(`audience_${facet.facetName}`))
        .forEach((keyItem) => {
          const facetNew = indexUiState!.refinementList![keyItem];

          facetNew.forEach((facetItem) => {
            selectedValuesBucket = [
              ...selectedValuesBucket,
              {
                label: facetItem,
                value: `${facetItem}_${keyItem.split("_").at(-1)}`,
              },
            ];
          });
        });
      firstRender.current = false;
      // console.log("hello2", refinementList, selectedValuesBucket);
      // console.log(facet.facetName, firstRender.current);
      setSelectedValues((selectedValues) => {
        return [...selectedValues, ...selectedValuesBucket];
      });
    }
  }, [indexUiState]);

  // console.log(selectedValues, firstRender.current, facet.facetName);

  return (
    <div>
      <Select
        showSearch
        mode="multiple"
        placeholder={facet.placeHolder || `Search ${facet.facetName}`}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        value={selectedValues.map((item) => ({
          value: item.label,
          label: item.label,
        }))}
        onChange={(_, selectedVal) => {
          const newlySelectedArray = (selectedVal as any[]).map(
            (item) => item.label
          );
          const oldSelectedArray = selectedValues.map((item) => item.label);

          //   setSelectedValues(selectedVal as any);
          return setSelectedValues(
            newlySelectedArray.map((item) => {
              if (oldSelectedArray.includes(item)) {
                return (
                  selectedValues.find(
                    (findItem) => findItem.label === item
                  ) || { value: "", label: "" }
                );
              }
              return { label: item, value: `${item}_30-100` };
            })
          );
        }}
        onSearch={(value) => setSearchQuery(value || "")}
        style={{ width: "100%" }}
        tagRender={(props) => {
          return <div />;
        }}
        options={(data?.data?.facetHits || []).map((d) => ({
          value: d.value,
          label: d.value,
        }))}
      />
      <div style={{ marginBlock: 5, display: "grid", gap: "2px" }}>
        {selectedValues.map((item) => {
          return (
            <Row justify="space-between">
              <Space align="center">
                <IoIosClose
                  style={{ cursor: "pointer" }}
                  size={16}
                  onClick={() =>
                    setSelectedValues((selectedValues) =>
                      selectedValues.filter(
                        (filterItem) => filterItem.label !== item.label
                      )
                    )
                  }
                />
                <span>{item.label}</span>
              </Space>
              <Select
                value={item.value.split("_").at(-1)}
                style={{ width: "40%" }}
                onChange={(_, option) =>
                  handleChange({ ...option, parentLabel: item.label })
                }
                options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => ({
                  value: `${item * 10}-100`,
                  label: `>${item * 10}%`,
                }))}
              />
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default MultiDimensionFilter;
