export const getButtonTexts = (
  cardType: "appRec" | "shortList" | "reject" | "invite"
) => {
  switch (cardType) {
    case "appRec":
      return {
        primaryButtonText: "ShortList",
        secondaryButtonText: "Not Interested",
      };
    case "shortList":
      return {
        primaryButtonText: "",
        secondaryButtonText: "",
      };
    case "reject":
      return {
        primaryButtonText: "Chat",
        secondaryButtonText: "Re-Invite",
      };
    case "invite":
      return {
        primaryButtonText: "Chat",
        secondaryButtonText: "Withdraw",
      };
  }
};
