import React, { useRef, useEffect } from "react";
import { Button } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import clsx from "clsx";
import { useQuery } from "react-query";
import { CampaignType } from "../../../typings";
import Option from "../../shared/UI/Option";
import { getUser } from "../../../handler/getUser";
import styles from "./../Campaign.module.css";

type CampaignSelectorProps = {
  type: CampaignType;
  setType: React.Dispatch<React.SetStateAction<CampaignType>>;
};

type props = {
  title: string;
  text: string;
  isTitleBlue: boolean;
};

export const CampaignTitle: React.FC<props> = (props): React.ReactElement => {
  const { title, text, isTitleBlue } = props;

  return (
    <div>
      <h6
        className={clsx(
          styles.buttonTitle,
          isTitleBlue ? styles.blueTitle : ""
        )}
      >
        {title}
      </h6>
      <div
        style={{ width: 260, display: "flex", alignItems: "center", gap: 4 }}
      >
        <p className={styles.buttonText}>{text}</p>
        <Button type="link" className="custom-option-button">
          <AiOutlineInfoCircle color="#000000B2" />
        </Button>
      </div>
    </div>
  );
};

const CampaignSelector: React.FC<CampaignSelectorProps> = (props) => {
  const { type, setType } = props;

  const [queryKey, queryFn] = getUser();
  const { data: userData } = useQuery(queryKey, queryFn);

  // useEffect(() => {
  //   if (firstRender?.current && userData?.data?.accountType) {
  //     setType(userData?.data?.accountType === "Report" ? "report" : "exec");
  //   }
  // }, [userData]);

  return (
    <React.Fragment>
      <section className={styles.buttonContainer}>
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <Option
            className={
              type === "campaign" ? styles.rounded_selected : styles.rounded
            }
            handleClick={() => {
              setType("campaign");
            }}
            isSelected={type === "campaign"}
            title={
              <CampaignTitle
                title="🚀 Cloutflow Campaign"
                text="Manage the campaigns executed with Cloutflow"
                isTitleBlue={type === "campaign"}
              />
            }
          />
          {userData?.data?.accountType &&
            userData?.data?.accountType !== "Report" && (
              <Option
                className={
                  type === "exec" ? styles.rounded_selected : styles.rounded
                }
                handleClick={() => {
                  setType("exec");
                }}
                isSelected={type === "exec"}
                title={
                  <CampaignTitle
                    title="⚡Connect Campaign"
                    text="End-to-end campaign for your
            influencer marketing campaigns"
                    isTitleBlue={type === "exec"}
                  />
                }
              />
            )}
          <Option
            className={
              type === "report" ? styles.rounded_selected : styles.rounded
            }
            handleClick={() => {
              setType("report");
            }}
            isSelected={type === "report"}
            title={
              <CampaignTitle
                title="📈 Reporting Campaign"
                text="Generate reports for self-managed 
            campaigns"
                isTitleBlue={type === "report"}
              />
            }
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default CampaignSelector;
