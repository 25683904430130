import { sendRequest } from "../helper";

interface CampaignCountResponse {
  status: string;
  message: string;
  data: number;
}

export const getCampaignCount = (isArchived = false) => {
  const queryKey = ["campaigns-count", isArchived];

  const queryFunction = () =>
    sendRequest<CampaignCountResponse>(
      "get",
      `/brand/campaign/count?isArchived=${isArchived}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
