import { useMemo } from "react";
import { CreatorSearchContextProvider } from "../../context/CreatorSearchContext";
import CreatorSearchComp from "./CreatorSearch";
import Navbar from "../shared/Navbar";
import { Col, Row } from "antd";
import Title from "../shared/Title";
import { MdOutlineInfo } from "react-icons/md";

const GlobalCreatorSearch = () => {
  const memoizedResult = useMemo(() => {
    return (
      <div style={{ overflowY: "auto", height: "100vh" }}>
        <Navbar selectedItem="discover" />
        <Row justify="center" style={{ padding: "20px 64px" }}>
          <Col style={{ width: "1200px" }}>
            <div style={{ padding: "20px" }}>
              <Title
                name="Global discovery"
                tagline="Find best Influencer's from a database of 120M influencers"
                icon={<MdOutlineInfo color="rgba(0, 0, 0, 0.50)" />}
              />
            </div>

            <Row>
              <CreatorSearchContextProvider>
                <CreatorSearchComp />
              </CreatorSearchContextProvider>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return memoizedResult;
};

export default GlobalCreatorSearch;
