import React from "react";

import styles from "./../table.module.css";

type HeadProps = {};

const Head: React.FC<HeadProps> = () => {
  return (
    <React.Fragment>
      <section className={styles.campaignHeader}>
        <div className={styles.headerItem}>Campaign Name</div>
        <div className={styles.headerItem} style={{ textAlign: "center" }}>
          Platform
        </div>
        <div className={styles.headerItem} style={{ textAlign: "center" }}>
          Type
        </div>
        <div className={styles.headerItem} style={{ textAlign: "center" }}>
          Created On
        </div>
        <div className={styles.headerItem}></div>
      </section>
    </React.Fragment>
  );
};
export default Head;
