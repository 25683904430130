import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import exportFromJSON from "export-from-json";
import {
  Row,
  Col,
  Typography,
  Pagination,
  Skeleton,
  Button,
  Space,
  Empty,
  Divider,
} from "antd";

import { DownloadOutlined } from "@ant-design/icons";

import Card from "../.././Finalized/Card";
import ChipInput from "../ChipInput";
import styles from "../finalized.module.css";

// import ReqModal from "../ReqModal";
// import ReqModal from "../ReqModal/ReqModalUpdated";
import ReqModalNoDel from "../ReqModalNoDel";
import Search from "../../../Report/Modals/SharedComponents/Search";

import {
  getFinalizedCount,
  getFinalizedApplicant,
  getFinalizedAggregate,
} from "../../../../../handler/campaign/applicants";
import { useNavigate } from "react-router-dom";
import { getDeliveryAddress } from "../../../../../handler/campaign/applicants/getDeliveryAddress";
import { getCampaign } from "../../../../../handler/campaign";
import { getCampaignOfferCount } from "../../../../../handler/campaign/getCampaignOfferCount";
import CampaignNotification from "../../CamapignNotification";

interface FinalizedScreenProps {
  id: string;
}

const FinalizedScreen: React.FC<FinalizedScreenProps> = ({ id }) => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [influencerId, setInfluencerId] = useState<string>("");

  const handleSubmit = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [filters, setFilters] = useState<{
    isRequirementNotSent: boolean;
    isDraftWaitingForReview: boolean;
    isLiveLinkWaitingForReview: boolean;
    isPaidToShow: boolean;
  }>({
    isRequirementNotSent: false,
    isDraftWaitingForReview: false,
    isLiveLinkWaitingForReview: false,
    isPaidToShow: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [getFinalizedApplicantKey, getFinalizedApplicantFn] =
    getFinalizedApplicant({
      campaignId: id,
      pageNo: page.toString(),
      sortOrder: "Desc",
      text: searchQuery,
      ...filters,
    });

  const [getFinalizedCountKey, getFinalizedCountFn] = getFinalizedCount({
    campaignId: id,
    text: searchQuery,
    ...filters,
  });
  const [getFinalizedAggregateKey, getFinalizedAggregateFn] =
    getFinalizedAggregate({
      campaignId: id,
    });

  const { data: finalizedCountData } = useQuery(
    getFinalizedCountKey,
    getFinalizedCountFn
  );
  const { data: finalizedApplicants, isLoading: isLoadingFinalizedApplicants } =
    useQuery(getFinalizedApplicantKey, getFinalizedApplicantFn);
  const { data: aggregateData, isLoading: isLoadingAggregateData } = useQuery(
    getFinalizedAggregateKey,
    getFinalizedAggregateFn
  );

  const [getDeliveryAddressKey, getDeliveryAddressFunc] = getDeliveryAddress({
    campaignId: id,
  });

  const {
    data: deliveryAddressData,
    isLoading: isDeliveryAddressLoading,
    refetch: refetchDeliveryAddress,
  } = useQuery(getDeliveryAddressKey, getDeliveryAddressFunc, {
    enabled: false,
  });

  const [getCampaignKey, getCampaignFunc] = getCampaign({ campaignId: id });

  const { data: campaignData } = useQuery(getCampaignKey, getCampaignFunc);

  const [getCampaignOfferCountKey, getCampaignOfferCountFn] =
    getCampaignOfferCount({ campaignId: id as string });

  const { data: campaignCountData, isLoading: isCampaignCountLoading } =
    useQuery(getCampaignOfferCountKey, getCampaignOfferCountFn);

  const handleTileCheck = ({
    id,
    influencerId,
  }: {
    id: string;
    influencerId: string;
  }) => {
    if (selectedIds.includes(id)) {
      if (selectedIds.length === 1) {
        setInfluencerId("");
      }
      return setSelectedIds((selectedIds) =>
        selectedIds.filter((item) => item !== id)
      );
    }
    if (selectedIds.length === 0) setInfluencerId(influencerId);
    return setSelectedIds((selectedIds) => [...selectedIds, id]);
  };

  const handleDownloadDeliveryAddress = () => {
    refetchDeliveryAddress()
      .then((res) => {
        exportFromJSON({
          data: res.data?.data || [],
          fileName: campaignData?.data.campaign.campaignName,
          exportType: "csv",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [searchQuery]);

  return (
    <Col span={19} style={{}}>
      <Row gutter={[21, 21]}>
        <Col
          span={15}
          style={{
            padding: "14px 21px 0 26px",
          }}>
          <Title style={{ fontWeight: 500 }} level={2}>
            Finalized
          </Title>

          <div style={{ display: "flex", gap: "12px", marginBottom: "24px" }}>
            <ChipInput
              label={`Send Requirements (${
                aggregateData?.data.requirementNotSent || 0
              })`}
              isActive={filters.isRequirementNotSent}
              handleClick={() =>
                setFilters({
                  ...filters,
                  isRequirementNotSent: !filters.isRequirementNotSent,
                  isDraftWaitingForReview: false,
                  isLiveLinkWaitingForReview: false,
                })
              }
            />
            <ChipInput
              label={`Review Deliverables (${
                aggregateData?.data.draftWaitingForReview || 0
              })`}
              isActive={filters.isDraftWaitingForReview}
              handleClick={() =>
                setFilters({
                  ...filters,
                  isRequirementNotSent: false,
                  isDraftWaitingForReview: !filters.isDraftWaitingForReview,
                  isLiveLinkWaitingForReview: false,
                })
              }
            />
            <ChipInput
              label={`Review Live Links (${
                aggregateData?.data.liveLinkWaitingForReview || 0
              })`}
              isActive={filters.isLiveLinkWaitingForReview}
              handleClick={() =>
                setFilters({
                  ...filters,
                  isRequirementNotSent: false,
                  isDraftWaitingForReview: false,
                  isLiveLinkWaitingForReview:
                    !filters.isLiveLinkWaitingForReview,
                })
              }
            />
            <Divider type="vertical" style={{ color: "black" }} />
            <span style={{ color: "GrayText", fontSize: 12 }}>(Optional) </span>
            <ChipInput
              label={`Already Paid Creators `}
              isActive={filters.isPaidToShow}
              handleClick={() =>
                setFilters((filters) => ({
                  ...filters,
                  isPaidToShow: !filters.isPaidToShow,
                }))
              }
            />
          </div>
          <Row justify="space-between" style={{ marginBottom: "16px" }}>
            <Button
              type="link"
              size="middle"
              onClick={() => setSelectedIds([])}
              style={{
                display: "flex",
                gap: "10px",
                padding: "4px",
                alignContent: "center",
              }}
              disabled={selectedIds.length === 0}>
              <img
                src={`/assets/icons/${
                  selectedIds.length === 0 ? "checkbox" : "minus--blue"
                }.svg`}
                width={24}
                alt="minus"
              />
              <span
                style={{
                  fontWeight: selectedIds.length > 0 ? 500 : 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "rgba(0, 0, 0, 0.85)",
                }}>
                {selectedIds.length > 0 ? selectedIds.length : "None"} selected
              </span>
            </Button>
            <Space>
              {selectedIds.length < 2 && (
                <Button
                  onClick={() => navigate(`/chat?influencerId=${influencerId}`)}
                  type="text"
                  disabled={selectedIds.length === 0}>
                  Send Message
                </Button>
              )}
              <Button
                type="primary"
                disabled={selectedIds.length === 0}
                onClick={() => {
                  setVisible(true);
                }}>
                Send Requirements
              </Button>
            </Space>
          </Row>
          <div style={{ marginTop: 10, marginBottom: 50, width: "125%" }}>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </div>
          {isLoadingFinalizedApplicants ? (
            [1, 2, 3, 4].map((_) => {
              return (
                <Col span={15} key={_}>
                  <Skeleton active avatar paragraph={{ rows: 1 }} />
                </Col>
              );
            })
          ) : (
            <div style={{ overflowY: "scroll", height: "80vh" }}>
              <div style={{ minHeight: "50%" }}>
                <div
                  style={{
                    display: "grid",
                    gap: "16px",
                    // minHeight: "50%",
                  }}>
                  {finalizedApplicants?.data &&
                  finalizedApplicants.data.result.length > 0 &&
                  !isLoadingFinalizedApplicants ? (
                    <>
                      {finalizedApplicants!.data.result.map((applicant) => {
                        return (
                          <Col span={24} key={applicant._id}>
                            <Card
                              applicant={applicant}
                              handleCheck={handleTileCheck}
                              isCardChecked={selectedIds.includes(
                                applicant._id
                              )}
                              campaignId={id}
                              finalizedApplicantKey={getFinalizedApplicantKey}
                            />
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      style={{
                        marginTop: "30%",
                        marginLeft: "10%",
                      }}
                      description={
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "22px",
                            marginTop: "10px",
                          }}>
                          No Applicants
                        </p>
                      }
                    />
                  )}
                </div>
              </div>

              {Boolean(finalizedCountData?.data.count) && (
                <Row justify="center">
                  <Pagination
                    total={finalizedCountData?.data.count}
                    style={{ margin: "46px auto" }}
                    onChange={(page, _) => setPage(page)}
                    pageSize={5}
                    showSizeChanger={false}
                    current={page}
                  />
                </Row>
              )}
            </div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            minHeight: "100vh",
            borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
            padding: "16px 23px 0",
          }}>
          <div style={{ display: "grid", gap: "20px" }}>
            {!isCampaignCountLoading && campaignCountData?.data.finalized ? (
              <Row>
                <div className={styles.download__card}>
                  {" "}
                  <div style={{ alignItems: "end" }}>
                    <DownloadOutlined
                      style={{
                        color: "#2130FF",
                        fontSize: "2rem",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: "500",
                    }}>
                    Download Shipping Information <br />
                    <span style={{ fontWeight: "100", color: "#9D9D9D" }}>
                      {!isCampaignCountLoading
                        ? campaignCountData?.data?.finalized || 0
                        : ""}{" "}
                      Creators
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "100",
                      color: "#9D9D9D",
                      marginTop: "1rem",
                    }}>
                    If you’re shipping your products, we have collected the
                    adress and contact details.
                  </div>
                  <div style={{ width: "100%" }}>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      block
                      style={{ marginTop: "1rem" }}
                      onClick={handleDownloadDeliveryAddress}
                      disabled={isDeliveryAddressLoading}
                      loading={isDeliveryAddressLoading}>
                      Download Now
                    </Button>
                  </div>
                </div>
              </Row>
            ) : (
              ""
            )}
            <CampaignNotification campaignId={id} />
          </div>
        </Col>
      </Row>

      {visible && (
        <ReqModalNoDel
          isVisible={visible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          campaignId={id}
          applicantIds={selectedIds}
          isView={false}
          platform={campaignData?.data?.campaign?.platform || "instagram"}
        />
      )}
    </Col>
  );
};

export default FinalizedScreen;
