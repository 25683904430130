import { sendRequest } from "../helper";

export type UpdateCampaignExecutionProps = {
  campaignId: string;
  serviceCostPercentage?: number;
  isProductDispatch?: boolean;
  isInternalApproval?: boolean;
};

export const updateCloutflowCampaignExecution = (
  props: UpdateCampaignExecutionProps
) => {
  return sendRequest(
    "put",
    `/campaign/update-campaign-execution`,
    false,
    props
  ).then((res) => res.data);
};
