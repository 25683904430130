export const igAllUserAnalysisConfig = [
  {
    name: "engagement_rate",
    isPercent: true,
  },
  {
    name: "promotional_frequency",
    isPercent: true,
  },
  // {
  //   name: "sponsored",
  //   isPercent: true,
  // },
];

export const igFeedUserAnalysisConfig = [
  {
    name: "engagement_rate",
    isPercent: true,
  },
  {
    name: "avgLikes",
    isPercent: false,
  },
  {
    name: "avgComments",
    isPercent: false,
  },
  {
    name: "avgProfile_visits",
    isPercent: false,
  },
  {
    name: "avgShares",
    isPercent: false,
  },
  {
    name: "avgEngagement",
    isPercent: false,
  },
  {
    name: "avgImpressions",
    isPercent: false,
  },
  {
    name: "avgReach",
    isPercent: false,
  },
  {
    name: "avgSaved",
    isPercent: false,
  },
  {
    name: "avgProfile_activity_action_type_bio_link_clicked",
    isPercent: false,
  },
  {
    name: "avgProfile_activity_action_type_text",
    isPercent: false,
  },
];

export const igReelUserAnalysisConfig = [
  {
    name: "avgClips_replays_count",
    isPercent: false,
  },
  {
    name: "avgIg_reels_aggregated_all_plays_count",
    isPercent: false,
  },
  {
    name: "engagement_rate",
    isPercent: true,
  },
  {
    name: "ig_reels_avg_watch_time",
    isPercent: false,
  },
  {
    name: "avgIg_reels_video_view_total_time",
    isPercent: false,
  },
  {
    name: "avgPlays",
    isPercent: false,
  },
  {
    name: "avgLikes",
    isPercent: false,
  },
  {
    name: "avgComments",
    isPercent: false,
  },
  {
    name: "avgProfile_visits",
    isPercent: false,
  },
  {
    name: "avgShares",
    isPercent: false,
  },
  {
    name: "avgEngagement",
    isPercent: false,
  },
  {
    name: "avgImpressions",
    isPercent: false,
  },
  {
    name: "avgReach",
    isPercent: false,
  },
  {
    name: "avgSaved",
    isPercent: false,
  },
  {
    name: "avgProfile_activity_action_type_bio_link_clicked",
    isPercent: false,
  },
  {
    name: "avgProfile_activity_action_type_text",
    isPercent: false,
  },
];

export const igStoryUserAnalysisConfig = [
  {
    name: "engagement_rate",
    isPercent: true,
  },
  {
    name: "avgImpressions",
    isPercent: false,
  },
  {
    name: "avgReach",
    isPercent: false,
  },
  {
    name: "avgReplies",
    isPercent: false,
  },
  {
    name: "avgProfile_activity_action_type_bio_link_clicked",
    isPercent: false,
  },
  {
    name: "avgShares",
    isPercent: false,
  },
  {
    name: "avgProfile_visits",
    isPercent: false,
  },
];
