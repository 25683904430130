import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Alert, Divider } from "antd";

import Navbar from "../../shared/Navbar";
import BasicDetails from "./BasicDetails";
import Products from "./Products";
import Deliverables from "./Deliverables";
import CreatorDetails from "./CreatorDetails";
import Guidelines from "./Guidelines";

import Preview from "./Preview";

import Footer from "./Footer";

import { capitalizeFirstLetter } from "../../../utils";

import {
  CampaignContextProvider,
  useCampaign,
} from "./Context/campaignContext";

import styles from "./styles.module.css";

const CreateCampaignWrapper = () => {
  const { id } = useParams();
  return (
    <CampaignContextProvider>
      <CreateCampaign campaignId={id || ""} />
    </CampaignContextProvider>
  );
};

export default CreateCampaignWrapper;

const CreateCampaign = ({ campaignId }: { campaignId: string }) => {
  const {
    fetchCampaignById,
    campaignId: currentCampaignId,
    campaign,
  } = useCampaign();

  useEffect(() => {
    if (campaignId !== "" && campaignId !== currentCampaignId) {
      return fetchCampaignById(campaignId);
    }
  }, [campaignId]);

  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "30px 0" }}>
        <Col span={24}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "40px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.85)",
            }}>
            Create an {capitalizeFirstLetter(campaign?.campaign?.platform)}{" "}
            Campaign
            <img
              src={`/assets/icons/${campaign?.campaign?.platform}Icon.png`}
              alt="instagram"
              style={{ width: "30px", marginLeft: "8px" }}
            />
          </div>
          <p
            style={{
              marginTop: "8px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.45)",
            }}>
            Need assistance? Please watch this{" "}
            <span
              style={{
                color: "#2130FF",
                // textDecoration: "underline",
                cursor: "pointer",
              }}>
              video
            </span>
          </p>
        </Col>
      </Row>

      {campaign.campaign.status === "Needs-Attention" && (
        <Col
          style={{ overflowY: "auto", maxHeight: "20vh" }}
          offset={1}
          span={7}>
          <Alert
            type="warning"
            description={campaign.campaign.remarks}
            message="Admin Remarks"
            showIcon
            style={{ marginBottom: "40px" }}
          />
        </Col>
      )}

      <Col span={22} className={styles.container} offset={1}>
        <Row justify="center">
          <Col
            span={15}
            style={{
              display: "grid",
              gap: "62px",
              height: "476px",
              overflowY: "scroll",
              paddingRight: "10px",
            }}>
            <BasicDetails />
            <Divider style={{ margin: "10px 0" }} />
            <Products />
            <Divider style={{ margin: "10px 0" }} />
            <Deliverables />
            <Divider style={{ margin: "10px 0" }} />
            <CreatorDetails />
            <Divider style={{ margin: "10px 0" }} />
            <Guidelines />
          </Col>
          <Col span={2} />
          <Col span={7}>
            <Preview />
          </Col>
        </Row>
        <Footer />
      </Col>
    </div>
  );
};
