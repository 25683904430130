import { sendRequest } from "../helper";

interface Payload {
  username: string;
}

interface Response {
  status: 200;
  message: string;
  data: {
    isSlugAvailable: boolean;
  };
}

export const checkUserName = async ({ username }: Payload) => {
  const res = await sendRequest<Response>(
    "get",
    `/brand/connect-request/slug?slug=${username}`,
    false
  );
  return res.data;
};
