import { Button, Card, Carousel, Col, message, Row } from "antd";
import styles from "./card.module.css";

import React, { useState } from "react";
import ReactPlayer from "react-player";
import { getChatCardData } from "../../../../../handler/chat/getChatCardData";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CardType } from "../CardTypes";
import LiveChangesModal from "./Modal/LiveChangesModal";
import { approveLiveV2 } from "../../../../../handler/campaign/applicants";
import { LiveCard } from "../../../../../typings";
import { format } from "date-fns";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AxiosError } from "axios";
interface LiveReceivedCardProps {
  item: {
    cardName?: string;
    cardTitle?: string;
    ref?: {
      deliverableId?: string;
      workflowId?: string;
      finalizedOfferId?: string;
      liveLinkTimeLineId?: string;
    };
    messageId: string;
    cardTimestamp?: string;
    type: string;
  };
}

const LiveReceivedCard: React.FC<LiveReceivedCardProps> = ({ item }) => {
  const queryClient = useQueryClient();
  const [isChangesModalVisible, setChangesModalVisible] =
    useState<boolean>(false);
  const [getChatCardKey, getChatCardDataFn] = getChatCardData({
    ref: item.ref,
    messageId: item.messageId,
    type: item.type,
  });
  const { data: cardData } = useQuery(getChatCardKey, getChatCardDataFn);

  const approveLiveMutation = useMutation(approveLiveV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tester"]);
      queryClient.invalidateQueries(["chat", CardType.deliverableLiveSentType]);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      {cardData && (
        <>
          {(cardData?.data as LiveCard).isRequestedChanges ||
          (cardData?.data as LiveCard).isApproved ? (
            <div className={styles.draft}>
              <div className={styles.draftHeading}>
                <p style={{ fontWeight: 600, fontSize: "13.5px" }}>
                  {item.cardName}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.6",
                    }}>
                    {item.cardTitle}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        opacity: "0.6",
                      }}>
                      {item.cardTitle}
                    </p>
                    <p
                      style={{
                        fontSize: "10px",
                      }}>
                      {format(new Date(item.cardTimestamp as string), "hh:mm")}
                    </p>
                  </div>
                </div>
              </div>
              <Slider
                className="slider"
                speed={500}
                slidesToScroll={1}
                infinite={false}
                dots={false}>
                {(cardData?.data as LiveCard).live.references.map((refe, i) => (
                  <>
                    {refe.type === "video" ? (
                      <div className={styles.video}>
                        {refe.platform === "youtube" ? (
                          <ReactPlayer
                            controls={true}
                            light={true}
                            url={refe.url}
                            volume={1}
                            width="100%"
                            height="100%"
                            onReady={() => console.log("ready now")}
                          />
                        ) : (
                          <video
                            muted={true}
                            src={refe.url}
                            controls={true}
                            style={{ objectFit: "cover" }}
                            width={"100%"}
                            height={"100%"}
                          />
                        )}
                      </div>
                    ) : (
                      <div className={styles.image}>
                        <img
                          className={styles.draftImage}
                          src={refe.url}
                          alt=""
                        />
                      </div>
                    )}
                  </>
                ))}
              </Slider>
              <div className={styles.draftApproved}>
                {(cardData?.data as LiveCard).isRequestedChanges && (
                  <Button
                    disabled
                    size="small"
                    style={{
                      padding: "0px 7px",
                    }}>
                    Changes Requested
                  </Button>
                )}

                {(cardData?.data as LiveCard).isApproved && (
                  <Button
                    disabled
                    size="small"
                    style={{
                      padding: "0px 7px",
                    }}>
                    Approved
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              {(cardData?.data as LiveCard).isEdited ? (
                <div className={styles.card}>
                  <Card
                    bodyStyle={{
                      fontWeight: 400,
                      padding: "12px 16px",
                    }}
                    style={{
                      borderRadius: "10px",
                      borderWidth: "",
                    }}
                    headStyle={{
                      backgroundColor: "rgba(0,0,0,0.05)",
                      borderRadius: "10px 10px 0 0",
                      padding: "0px 16px",
                    }}
                    title={
                      <div style={{ display: "grid", gap: "5px" }}>
                        <p style={{ fontWeight: 600, fontSize: "14px" }}>
                          {item.cardName}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: "12px",
                              opacity: "0.6",
                            }}>
                            {item.cardTitle}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                            }}>
                            {format(
                              new Date(item.cardTimestamp as string),
                              "hh:mm"
                            )}
                          </p>
                        </div>
                      </div>
                    }>
                    <Row>
                      <Col>Live Link has been edited</Col>
                    </Row>
                  </Card>
                </div>
              ) : (
                <div className={styles.draft}>
                  <div className={styles.draftHeading}>
                    <p style={{ fontWeight: 600, fontSize: "14px" }}>
                      {item.cardName}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          opacity: "0.6",
                        }}>
                        {item.cardTitle}
                      </p>
                      <p
                        style={{
                          fontSize: "10px",
                        }}>
                        {format(
                          new Date(item.cardTimestamp as string),
                          "hh:mm"
                        )}
                      </p>
                    </div>
                  </div>
                  <Slider
                    className="slider"
                    speed={500}
                    slidesToScroll={1}
                    infinite={false}
                    dots={false}>
                    {(cardData?.data as LiveCard).live.references.map(
                      (refe, i) => (
                        <>
                          {refe.type === "video" ? (
                            <div className={styles.video}>
                              {refe.platform === "youtube" ? (
                                <ReactPlayer
                                  controls={true}
                                  light={true}
                                  url={refe.url}
                                  volume={1}
                                  width="100%"
                                  height="100%"
                                  onReady={() => console.log("ready now")}
                                />
                              ) : (
                                <video
                                  muted={true}
                                  src={refe.url}
                                  controls={true}
                                  style={{ objectFit: "cover" }}
                                  width={"100%"}
                                  height={"100%"}
                                />
                              )}
                            </div>
                          ) : (
                            <div className={styles.image}>
                              <img
                                className={styles.draftImage}
                                src={refe.url}
                                alt=""
                              />
                            </div>
                          )}
                        </>
                      )
                    )}
                  </Slider>
                  <div className={styles.draftButton}>
                    <Button
                      onClick={() => setChangesModalVisible(true)}
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Request Changes
                    </Button>
                    <Button
                      onClick={() =>
                        approveLiveMutation.mutate({
                          workflowId: item.ref?.workflowId as string,
                          prevLiveLinkTimeLineId: item.ref
                            ?.liveLinkTimeLineId as string,
                        })
                      }
                      loading={approveLiveMutation.isLoading}
                      type="primary"
                      size="small"
                      style={{
                        padding: "0px 7px",
                        marginTop: "12px",
                      }}>
                      Approve
                    </Button>
                  </div>

                  <LiveChangesModal
                    isVisible={isChangesModalVisible}
                    handleSubmit={() => setChangesModalVisible(false)}
                    handleCancel={() => setChangesModalVisible(false)}
                    workflowId={item.ref?.workflowId as string}
                    prevLiveLinkTimeLineId={
                      item.ref?.liveLinkTimeLineId as string
                    }
                  />
                </div>
              )}
            </>
          )}
        </>
      )}{" "}
    </>
  );
};

export default LiveReceivedCard;
