import { sendRequest } from "../helper";

import { DemographicData } from "../../typings";

type Insights = {
  status: string | null;
  message: string | null;
  data: DemographicData;
};

export const getInfluencerMediaInsight = (mediaKitId: string) => {
  const queryKey = ["influencer", "media", "mediaKit", mediaKitId];
  const queryFunction = () =>
    sendRequest<Insights>(
      "get",
      `/brand/brand-influencer/media-kit-insights?socialMediaKitId=${mediaKitId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
