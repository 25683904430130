import React, { useState, useEffect } from "react";
import { Row, Space, Col, InputNumber, Button, message, Select } from "antd";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import CustomInput from "../../../../../shared/Custom/Input";

import { sendOTP } from "../../../../../../handler/sendOTP";
import { verifyOTP } from "../../../../../../handler/verifyOTP";

import { countryCodeArray } from "../../../../../assets/country_code";

import { UserType } from "..";

import styles from "../styles.module.css";

const { Option } = Select;

interface PersonalDetailsProps {
  inputValues: UserType;
  errors: UserType;
  setInputValues: React.Dispatch<React.SetStateAction<UserType>>;
  setErrors: React.Dispatch<React.SetStateAction<UserType>>;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  inputValues,
  errors,
  setInputValues,
  setErrors,
}) => {
  const [isVerifyClicked, setIsVerifyClicked] = useState<boolean>(false);
  const [otpTimer, setOtpTimer] = useState<number>(31);
  const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(
    inputValues.phoneNumber !== "" ? true : false
  );
  const [OTP, setOTP] = useState<string>("");

  const [countryCode, setCountryCode] = useState<string>("+91");

  const sendOTPMutation = useMutation(sendOTP, {
    onSuccess: () => {
      setIsVerifyClicked(true);
      setOtpTimer(30);
      message.success("OTP sent");
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const verifyOTPMutation = useMutation(verifyOTP, {
    onSuccess: () => {
      setIsVerifyClicked(false);
      message.success("OTP Verified");
      setInputValues((userData) => {
        return { ...userData, isPhoneVerified: true };
      });
      setErrors((err) => ({ ...err, isPhoneVerified: true }));
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const phoneInputBefore = () => {
    return (
      <Select
        value={countryCode}
        onChange={(value) => setCountryCode(value)}
        disabled={editPhoneNumber}>
        {countryCodeArray.map((item) => (
          <Option value={item.dial_code} key={item.name}>
            {item.flag} {item.dial_code}
          </Option>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    if (otpTimer > 0 && isVerifyClicked) {
      const interval = setInterval(() => {
        setOtpTimer((otpTimer) => otpTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimer]);

  return (
    <div>
      <h1 className={styles.heading}>Personal Details:</h1>
      <Row justify="space-between" style={{ marginBottom: "20px" }} gutter={14}>
        <Col span={8}>
          <CustomInput
            value={inputValues.name}
            label="First Name"
            error={errors.name}
            onChange={(e) =>
              setInputValues((inputValues) => ({
                ...inputValues,
                name: e.target.value || "",
              }))
            }
          />
        </Col>
        <Col span={8}>
          <CustomInput
            value={inputValues.lastname}
            label="Last Name"
            error={errors.lastname}
            onChange={(e) =>
              setInputValues((inputValues) => ({
                ...inputValues,
                lastname: e.target.value || "",
              }))
            }
          />
        </Col>
        <Col span={8}>
          <CustomInput
            value={inputValues.designation}
            label="Designation"
            error={errors.designation}
            onChange={(e) =>
              setInputValues((inputValues) => ({
                ...inputValues,
                designation: e.target.value || "",
              }))
            }
          />
        </Col>
      </Row>
      <CustomInput
        value={inputValues.phoneNumber}
        label="Contact Details"
        error={errors.phoneNumber}
        onChange={(e) =>
          setInputValues((inputValues) => ({
            ...inputValues,
            phoneNumber: e.target.value || "",
          }))
        }
        style={{ width: "33%" }}
        children={
          <>
            <Space>
              <InputNumber
                addonBefore={phoneInputBefore()}
                type={"number"}
                disabled={editPhoneNumber}
                maxLength={10}
                controls={false}
                placeholder="xxxxxxxxxx"
                size="middle"
                style={{ width: "300px" }}
                value={(inputValues?.phoneNumber || "+91-").split("-").at(-1)}
                onPressEnter={(e) => {
                  setIsVerifyClicked(true);
                  setEditPhoneNumber(true);
                  sendOTPMutation.mutate({
                    phoneNumber: inputValues?.phoneNumber.toString(),
                  });
                  setOTP("");
                }}
                onChange={(value) => {
                  setInputValues((inputValues) => {
                    return {
                      ...inputValues,
                      phoneNumber: countryCode + "-" + (value || ""),
                    };
                  });
                  setIsVerifyClicked(false);
                }}
              />
              {isVerifyClicked || editPhoneNumber ? (
                <Button type="text" onClick={() => setEditPhoneNumber(false)}>
                  Edit
                </Button>
              ) : (
                <Button
                  type="text"
                  onClick={() => {
                    sendOTPMutation.mutate({
                      phoneNumber: inputValues.phoneNumber.toString(),
                    });
                    setOTP("");
                    setEditPhoneNumber(true);
                  }}
                  loading={sendOTPMutation.isLoading}>
                  {" "}
                  Verify
                </Button>
              )}
            </Space>
            {isVerifyClicked && (
              <>
                <Space>
                  <InputNumber
                    type={"number"}
                    onPressEnter={(e) =>
                      verifyOTPMutation.mutate({
                        OTP: OTP.toString(),
                      })
                    }
                    maxLength={4}
                    placeholder="Enter OTP"
                    controls={false}
                    size="middle"
                    style={{ width: "150px" }}
                    value={OTP}
                    onChange={(value) => setOTP(value || "")}
                  />
                  <Button
                    type="text"
                    onClick={() =>
                      verifyOTPMutation.mutate({
                        OTP: OTP.toString(),
                      })
                    }
                    loading={sendOTPMutation.isLoading}>
                    {" "}
                    Verify
                  </Button>
                </Space>
                <div className="">
                  <Button
                    disabled={otpTimer !== 0}
                    onClick={() => {
                      sendOTPMutation.mutate({
                        phoneNumber: "+91" + inputValues.phoneNumber.toString(),
                      });
                      setOTP("");
                    }}
                    type="text">
                    Resend OTP {otpTimer !== 0 && `in ${otpTimer}`}
                  </Button>
                </div>
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default PersonalDetails;
