import { sendRequest } from "../helper";

interface MessageResult {
  status: string;
  message: string;
  data: any;
}

export const deleteMessage = ({ messageId }: { messageId: string }) => {
  return sendRequest<MessageResult>(
    "delete",
    `/brand/chat/messages?messageId=${messageId}`,
    false
  ).then((res) => res.data);
};
