import React from "react";
import { useNavigate } from "react-router-dom";
import { RefinementList, RangeInput } from 'react-instantsearch';
import { Row, Button, Checkbox, Col, Radio, message } from "antd";
import { useSaasDiscoveryContext } from "../../SaasDiscovery/context";
import { useQueryClient, useMutation, useQuery } from "react-query";

import { getUser } from "../../../handler/getUser";

import {
  getInfluencerOnboardStatus,
  inviteInfluencer,
  getInfluencersCount,
} from "../../../handler/saas";

import FilterTile from "./FilterTile";

import styles from "./filters.module.css";

const Filters = () => {
  const {
    setShowMetrics,
    showMetrics,
    selectedCreatorList,
    platform,
    setSelectedCreatorList,
  } = useSaasDiscoveryContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [getInfluencersCountKey, getInfluencersCountQuery] =
    getInfluencersCount();

  const { data: influencersCount } = useQuery(
    getInfluencersCountKey,
    getInfluencersCountQuery
  );

  const [getUserKey, getUserFn] = getUser();
  const { data: userData } = useQuery(getUserKey, getUserFn);

  const inviteMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Invitation sent successfully");
      selectedCreatorList.forEach((saasId) => {
        const [getInfluencerOnboardStatusKey] = getInfluencerOnboardStatus({
          saasId,
          platform,
        });
        queryClient.invalidateQueries(getInfluencerOnboardStatusKey);
        queryClient.invalidateQueries(getInfluencersCountKey);
      });
      setSelectedCreatorList([]);
    },
  });

  return (
    <div>
      <Row justify="center" style={{ margin: "20px 0" }}>
        <h2>Filters</h2>
      </Row>

      {(influencersCount?.data?.count || 0) > 0 && (
        <Row>
          <div className={styles.download__card}>
            <div
              style={{
                fontWeight: "500",
              }}>
              Invited Creators <br />
              <span style={{ fontWeight: "100", color: "#9D9D9D" }}>
                {influencersCount?.data?.count} Creators Invited
              </span>
            </div>

            <div style={{ width: "100%" }}>
              <Button
                type="primary"
                block
                style={{ marginTop: "1rem" }}
                onClick={() => navigate("/algolia-influencers")}>
                Go to list
              </Button>
            </div>
          </div>
        </Row>
      )}

      {selectedCreatorList.length > 0 && (
        <Row>
          <div className={styles.download__card}>
            <div
              style={{
                fontWeight: "500",
              }}>
              Invite Creators <br />
              <span style={{ fontWeight: "100", color: "#9D9D9D" }}>
                {selectedCreatorList.length} Creators selected
              </span>
            </div>
            <div
              style={{
                fontWeight: "100",
                color: "#9D9D9D",
                marginTop: "1rem",
              }}>
              Invite Selected Creators to a <br />
              the platform
            </div>
            <div style={{ width: "100%" }}>
              <Button
                type="default"
                block
                style={{ marginTop: "1rem" }}
                onClick={() =>
                  inviteMutation.mutate({
                    platform,
                    saasIds: selectedCreatorList,
                  })
                }
                loading={inviteMutation.isLoading}>
                Invite Now
              </Button>
            </div>
          </div>
        </Row>
      )}
      <FilterTile title="Configure">
        {/* <Col style={{ marginBottom: "20px" }}>
          <Radio.Group
            onChange={(e) => setPlatform(e.target.value)}
            value={platform}>
            <Radio.Button value="instagram">Instagram</Radio.Button>
            <Radio.Button value="youtube">Youtube</Radio.Button>
          </Radio.Group>
        </Col> */}
        <Col>
          <Checkbox
            checked={showMetrics.showMoreMetrics}
            onChange={(e) => {
              setShowMetrics((showMetrics) => ({
                ...showMetrics,
                showMoreMetrics: e.target.checked,
              }));
            }}>
            Metrics
          </Checkbox>
        </Col>
        <Checkbox
          checked={showMetrics.showMorePosts}
          onChange={(e) => {
            setShowMetrics((showMetrics) => ({
              ...showMetrics,
              showMorePosts: e.target.checked,
            }));
          }}>
          Posts
        </Checkbox>
        <Col>
          <Checkbox
            checked={showMetrics.showPhoneNumber}
            onChange={(e) => {
              setShowMetrics((showMetrics) => ({
                ...showMetrics,
                showPhoneNumber: e.target.checked,
              }));
            }}
            disabled={userData?.data?.accountType !== "Managed"}>
            With Phone Number
          </Checkbox>
        </Col>
      </FilterTile>

      <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
        <FilterTile title="Niches">
          <RefinementList
            attribute="mainCategories"
            searchable={true}
            searchablePlaceholder="Search Niches"
          />
        </FilterTile>
        <FilterTile title="Location">
          <RefinementList
            attribute="cities"
            searchable={true}
            searchablePlaceholder="Search Locations"
          />
        </FilterTile>
        <FilterTile title="Hashtags">
          <RefinementList
            attribute={"hashtagArray"}
            searchable={true}
            searchablePlaceholder="Search Hashtags"
          />
        </FilterTile>
        <FilterTile title="Followers">
          <RangeInput
            attribute={`followers`}
            translations={{
              separatorElementText: "-",
              submitButtonText: "Apply",
            }}
            classNames={{
              form: styles.followersRoot,
              submit: styles.followersSubmit,
            }}
          />
        </FilterTile>
      </div>
    </div>
  );
};

export default Filters;
