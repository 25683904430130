import React, { useState } from "react";
import { Row } from "antd";

import BlockEntry from "./BlockEntry";

import { useReportOnly } from "../../../Context/ReportOnlyContext";

import styles from "./styles.module.css";

interface AggregatedBlockProps {
  title: string;
  searchQuery: string;
}

const AggregatedBlock: React.FC<AggregatedBlockProps> = ({
  title,
  searchQuery,
}) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { tableColumn, tabelFullColumns, selectCampaignColumn } =
    useReportOnly();

  const getTitle = () => {
    switch (title) {
      case "postData":
        return "Posts Data";

      case "defaultCalculatedMetrics-Influencer":
        return "Default Metrics";
      case "customCalculatedMetrics-Influencer":
        return "Custom Metrics";

      default:
        return title;
    }
  };

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <span className={styles.title}>{getTitle()}</span>
        <p
          onClick={() => setSeeMore((seeMore) => !seeMore)}
          className={styles.seeMoreButton}>
          Show {seeMore ? "less" : "more"}
        </p>
      </Row>
      <div className={styles.grid}>
        {tabelFullColumns
          .filter((item) => item.fieldType === title)
          .filter((item) =>
            ((item.title || "") as string)
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
          .slice(0, seeMore ? undefined : 5)
          ?.map((entry) => (
            <BlockEntry
              desc={entry.desc}
              title={entry.title}
              isSelected={
                tableColumn.findIndex((item) => item.key === entry.key) !== -1
              }
              onClick={() => selectCampaignColumn(entry)}
            />
          ))}
      </div>
    </div>
  );
};

export default AggregatedBlock;
