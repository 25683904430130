import React from "react";
import { Popover } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";

interface Props {
  children?: React.ReactNode;
  customText: string;
  color?: string;
}

const HelpPopOver: React.FC<Props> = ({ children, customText, color }) => {
  return (
    <Popover content={customText} title={null} trigger="hover" placement="top">
      {children ? (
        <div>{children}</div>
      ) : (
        <IoMdInformationCircleOutline size={19} color={color} />
      )}
    </Popover>
  );
};

export default HelpPopOver;
