import { sendRequest } from "../helper";
import { Message } from "../../typings";

type getOldMessagesResult = {
  status: string;
  message: string;
  data: {
    messages: Message[];
    nextPageToken: string | null;
  };
};

export const getOldMessages = (conversationId: string) => {
  const queryKey = ["chats", conversationId];

  const queryFunction = ({ pageParam }: { pageParam?: string }) =>
    sendRequest<getOldMessagesResult>(
      "get",
      `/brand/chat/messages?conversationId=${conversationId}&limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
