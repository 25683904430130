import React from "react";
import { Layout, Button, Typography } from "antd";
import styles from "./Sidebar.module.css";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCampaignOfferCount } from "../../../../handler/campaign/getCampaignOfferCount";
import { IoSettingsOutline } from "react-icons/io5";
const { Title, Text } = Typography;
interface SidebarProps {
  subMenuType:
    | "suggested"
    | "shortList"
    | "reject"
    | "finalized"
    | "completed"
    | "settings";

  handleMenuChange: (
    type:
      | "suggested"
      | "shortList"
      | "reject"
      | "finalized"
      | "completed"
      | "settings"
  ) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ subMenuType, handleMenuChange }) => {
  const { Sider } = Layout;
  const { id } = useParams();

  const [getCampaignOfferCountKey, getCampaignOfferCountFn] =
    getCampaignOfferCount({ campaignId: id as string });

  const { data: count } = useQuery(
    getCampaignOfferCountKey,
    getCampaignOfferCountFn
  );

  const handleMenuItemChange = (value: string) => {
    handleMenuChange(
      value as
        | "suggested"
        | "shortList"
        | "reject"
        | "finalized"
        | "completed"
        | "settings"
    );
  };

  return (
    <Sider width={"100%"} className={styles.sidebar} theme="light">
      <div className={styles.heading}>
        <div
          className={styles.campaignButton}
          onClick={() => handleMenuItemChange("suggested")}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              color: "black",
            }}
          >
            {count?.data.campaignName}
          </p>
        </div>
      </div>

      <div className={styles.navs}>
        <div
          style={{
            marginLeft: "20px",

            marginTop: "25px",
          }}
        ></div>
        <div className={styles.options}>
          <div
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
            className={
              subMenuType !== "suggested" ? styles.option : styles.optionActive
            }
            key={"suggested"}
            onClick={() => handleMenuItemChange("suggested")}
          >
            <p>Suggested</p>
            <span
              className={
                subMenuType !== "suggested" ? styles.count : styles.countActive
              }
            >
              {count?.data.applicationRecieved}
            </span>
          </div>
          <div
            className={
              subMenuType !== "shortList" ? styles.option : styles.optionActive
            }
            key={"shortList"}
            onClick={() => handleMenuItemChange("shortList")}
          >
            <p>Shortlisted</p>
            <span
              className={
                subMenuType !== "shortList" ? styles.count : styles.countActive
              }
            >
              {count?.data.shortListed}
            </span>
          </div>

          <div
            // style={{
            //   borderBottomLeftRadius: "8px",
            //   borderBottomRightRadius: "8px",
            // }}
            className={
              subMenuType !== "reject" ? styles.option : styles.optionActive
            }
            key={"reject"}
            onClick={() => handleMenuItemChange("reject")}
          >
            <span>Not interested</span>
            <span
              className={
                subMenuType !== "reject" ? styles.count : styles.countActive
              }
            >
              {count?.data.rejected}
            </span>
          </div>
        </div>
        <div className={styles.options}>
          <div
            style={{
              marginLeft: "20px",

              marginTop: "25px",
            }}
          ></div>
          <div
            style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
            className={
              subMenuType !== "finalized" ? styles.option : styles.optionActive
            }
            key={"finalized"}
            onClick={() => handleMenuItemChange("finalized")}
          >
            <span>Ongoing</span>
            <span
              className={
                subMenuType !== "finalized" ? styles.count : styles.countActive
              }
            >
              {count?.data.finalized}
            </span>
          </div>
          <div
            style={{
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
            className={
              subMenuType !== "completed" ? styles.option : styles.optionActive
            }
            key={"completed"}
            onClick={() => handleMenuItemChange("completed")}
          >
            <span>Completed</span>
            <span
              className={
                subMenuType !== "completed" ? styles.count : styles.countActive
              }
            >
              {count?.data?.completed}
            </span>
          </div>
        </div>

        <div
          style={{ borderRadius: "8px" }}
          className={
            subMenuType !== "settings" ? styles.option : styles.optionActive
          }
          key={"settings"}
          onClick={() => handleMenuItemChange("settings")}
        >
          <span>Settings</span>

          <span
            className={
              subMenuType !== "settings" ? styles.count : styles.countActive
            }
          >
            <IoSettingsOutline />
          </span>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
