import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Popover,
  Input,
  Select,
  message,
  Menu,
  Dropdown,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { BsChevronDown } from "react-icons/bs";
import { LuInfo } from "react-icons/lu";

import { countryCodeArray } from "../../../assets/country_code";

import CustomDropDown from "../../../shared/Custom/DropDown";
import CustomInput from "../../../shared/Custom/Input";
import ReportButton from "../../../Campaign/Report/ReportButton";
import AddEmailModal from "../../Steps/Modal/AddEmail";

import { validateEmail } from "../../../../utils";

import styles from "../styles.module.css";

import { inviteInfluencer } from "../../../../handler/invite";
import { getUser } from "../../../../handler/getUser";

interface SingleInviteProps {
  handleCancel: () => void;
  isVisible: boolean;
  handleSubmit: () => void;
}

const { Option } = Select;

const SingleInvite: React.FC<SingleInviteProps> = ({
  handleCancel,
  isVisible,
  handleSubmit,
}) => {
  const [userInput, setUserInput] = useState<{
    name: string;
    platform: "instagram" | "youtube";
    countryCode: string;
    phoneNumber: string;
    email: string;
  }>({
    name: "",
    platform: "instagram",
    countryCode: "+91",
    email: "",
    phoneNumber: "",
  });
  const [userInputErr, setUserInputErr] = useState<{
    name: string;
    platform: string;
    countryCode: string;
    phoneNumber: string;
    email: string;
  }>({
    name: "",
    platform: "",
    countryCode: "",
    email: "",
    phoneNumber: "",
  });

  const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);

  const resetForm = () => {
    setUserInput({
      name: "",
      platform: "instagram",
      countryCode: "+91",
      email: "",
      phoneNumber: "",
    });
    setUserInputErr({
      name: "",
      platform: "",
      countryCode: "",
      email: "",
      phoneNumber: "",
    });
  };

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Invite sent successfully!");
      handleSubmit();
      handleCancel();
      resetForm();
    },
    onError: (e) => {
      console.log(e);
      message.error("Something went wrong while inviting Influencer");
    },
  });

  const isDataValid = () => {
    let isValid = true;
    if (!userInput?.name?.trim()) {
      setUserInputErr((err) => ({ ...err, name: "Please Enter a Name" }));
      isValid = false;
    } else {
      setUserInputErr((err) => ({ ...err, name: "" }));
    }
    if (userInput?.email?.trim() && !validateEmail(userInput?.email)) {
      setUserInputErr((err) => ({
        ...err,
        email: "Please Enter a valid Email",
      }));
      isValid = false;
    } else {
      setUserInputErr((err) => ({ ...err, email: "" }));
    }
    if (
      !userInput?.phoneNumber?.trim() ||
      userInput?.phoneNumber.length < 7 ||
      userInput?.phoneNumber.length > 12
    ) {
      setUserInputErr((err) => ({
        ...err,
        phoneNumber: "Please Enter a valid Phone Number",
      }));
      isValid = false;
    } else {
      setUserInputErr((err) => ({ ...err, phoneNumber: "" }));
    }
    return isValid;
  };

  const [userKey, userFn] = getUser();

  const { data: userData } = useQuery(userKey, userFn);

  const menu = (
    <Menu>
      {[
        {
          key: "ig",
          label: "Instagram",
          onClick: () =>
            setUserInput((userInput) => ({
              ...userInput,
              platform: "instagram",
            })),
        },
        {
          key: "yt",
          label: "Youtube",
          onClick: () =>
            setUserInput((userInput) => ({
              ...userInput,
              platform: "youtube",
            })),
        },
      ]?.map((item) => {
        return (
          <Menu.Item
            key={item.key}
            // className={clsx(
            //   {
            //     [styles.item]: !(item.disabled || false),
            //   },
            //   menuItemClassName
            // )}
            onClick={item.onClick}
            style={{ cursor: "default" }}
          >
            {item.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div style={{ display: "grid", justifyContent: "space-between" }}>
      <Row gutter={[40, 20]}>
        <Col span={12}>
          <CustomInput
            label="Full Name"
            error={userInputErr?.name}
            // className={styles.inputContainer}
            children={
              <Input
                value={userInput.name}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    name: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />
        </Col>
        <Col span={12}>
          <CustomInput
            label="Platform"
            children={
              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  className={styles.optionsContainerUpdated}
                >
                  {userInput.platform === "instagram" ? "Instagram" : "Youtube"}
                  <BsChevronDown color="#00000080" size={14} />
                </a>
              </Dropdown>
            }
          />
        </Col>
        <Col span={12}>
          <CustomInput
            label={
              <div
                style={{
                  display: "inline-flex",
                  gap: "6px",
                  alignItems: "center",
                }}
              >
                Phone Number
                <Popover
                  content={"Cloutflow will send invite via WhatsApp."}
                  title={null}
                  trigger="hover"
                  placement="top"
                  arrowPointAtCenter
                >
                  <LuInfo size={15} style={{ cursor: "pointer" }} />
                </Popover>
              </div>
            }
            children={
              <>
                <Input.Group style={{ display: "flex" }}>
                  <Select
                    size={"large"}
                    // className="selectTransparent"
                    // style={{
                    //   width: "35%",
                    //   height: "100% !important",
                    //   borderRadius: "4px",
                    //   borderColor: "transparent",
                    //   backgroundColor: "transparent !important",
                    // }}
                    value={userInput.countryCode}
                    onChange={(value) =>
                      setUserInput((userInput) => ({
                        ...userInput,
                        countryCode: value,
                      }))
                    }
                  >
                    {countryCodeArray.map((item) => (
                      <Option value={item.dial_code} key={item.name}>
                        {item.flag} {item.dial_code}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    // style={{
                    //   width: "65%",
                    //   height: "100%",
                    //   borderRadius: "4px",
                    //   borderColor: "transparent",
                    // }}
                    type="number"
                    value={userInput.phoneNumber}
                    onChange={(e) =>
                      setUserInput((userInput) => ({
                        ...userInput,
                        phoneNumber: e.target.value || "",
                      }))
                    }
                  />
                </Input.Group>
                {userInputErr?.phoneNumber && (
                  <p className={styles.error}>{userInputErr?.phoneNumber}</p>
                )}
              </>
            }
          />
        </Col>
        <Col span={12}>
          <CustomInput
            //

            label={
              <div
                style={{
                  display: "inline-flex",
                  gap: "6px",
                  alignItems: "center",
                }}
              >
                Email Address (Optional)
                <Popover
                  content={
                    !userData?.data?.brevo?.isSendersMailVerified ? (
                      <p>
                        To Invite Influencer By Email You need to add your Email
                        id. <br />
                        {/* <Row justify="center"> */}
                        <ReportButton
                          type="primary"
                          style={{ cursor: "pointer", marginTop: 5 }}
                          onClick={() => setEmailModalVisible(true)}
                        >
                          Verify
                        </ReportButton>
                        {/* </Row> */}
                      </p>
                    ) : (
                      "Influencer will receive an Invitation By Email to join your community."
                    )
                  }
                  title={null}
                  trigger="hover"
                  placement="top"
                  arrowPointAtCenter
                >
                  <LuInfo size={15} style={{ cursor: "pointer" }} />
                </Popover>
              </div>
            }
            // className={styles.inputContainer}

            error={userInputErr?.email}
            children={
              <Input
                value={userInput.email}
                disabled={!userData?.data?.brevo?.isSendersMailVerified}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    email: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />
        </Col>
      </Row>
      {!userData?.data?.brevo?.isSendersMailVerified &&
        userInput?.email?.trim() && (
          <Row className={styles.emailContainer} justify="space-between">
            <Col span={17} className={styles.emailHeading}>
              The invite is sent by app@cloutflow.com.
              <br /> To increase conversions 📈, you can add your own email-Id
            </Col>
            <Col>
              <ReportButton
                ghost
                isOutline
                title="Add Your Email-Id"
                className={styles.emailButton}
                onClick={() => setEmailModalVisible(true)}
              >
                Add Your Email ID
              </ReportButton>
            </Col>
          </Row>
        )}
      <Row justify="start" style={{ marginTop: 80 }}>
        <ReportButton
          title="Create"
          type="primary"
          size="large"
          onClick={() => {
            if (isDataValid()) {
              inviteInfluencerMutation.mutate({
                inviteList: [
                  {
                    name: userInput.name,
                    platform: userInput.platform,

                    email: userInput?.email,
                    phoneNumber:
                      userInput?.countryCode + userInput?.phoneNumber,
                  },
                ],
              });
            } else {
              message.error("Please recheck the form for errors");
            }
          }}
          loading={inviteInfluencerMutation.isLoading}
        >
          Invite Creator
        </ReportButton>
        <ReportButton
          title="Cancel"
          type="link"
          size="large"
          onClick={() => handleCancel()}
          loading={false}
        >
          Cancel
        </ReportButton>
      </Row>
      <AddEmailModal
        visible={emailModalVisible}
        handleSubmit={() => setEmailModalVisible(false)}
      />
    </div>
  );
};

export default SingleInvite;
