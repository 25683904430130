import React from "react";

import styles from "./chip.module.css";

interface ChipProps {
  onclose: (id: string) => void;
  text: string;
}

const Chip: React.FC<ChipProps> = ({ onclose, text }) => {
  return (
    <div className={styles.container}>
      <span className={styles.chipText}>{text}</span>
      <img
        src="/assets/icons/close-accent-blue.svg"
        alt="close"
        style={{ cursor: "pointer" }}
        onClick={() => onclose(text)}
      />
    </div>
  );
};

export default Chip;
