import { useSearchParams, useParams } from "react-router-dom";

import BasicDetails from "./BasicDetails";

const Create = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const platform = searchParams.get("platform") || "instagram";

  return (
    <BasicDetails platform={(platform as "instagram") || "youtube"} id={id} />
  );
};

export default Create;
