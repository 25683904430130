import React from "react";
import Nav from "../Locked/Nav";
import { Row, Col, Spin, Card, Button, Avatar, Tag } from "antd";
import { useQuery } from "react-query";
import Lottie from "react-lottie";

import { getOrgAppState } from "../../handler/organization";
import { getUserUniversal } from "../../handler/getUserUniversal";

import { getDefaultLottieOptions, handleCookieChange } from "../../utils";

import animationData from "../assets/lottie/Rocket.json";

const { Meta } = Card;

const OrgSelect = () => {
  const [orgUserKey, orgUserFn] = getOrgAppState();
  const [universalUserKey, universalUserFn] = getUserUniversal();

  const { data, isLoading } = useQuery(orgUserKey, orgUserFn);
  const { data: universalData, isLoading: isLoadingUniversalData } = useQuery(
    universalUserKey,
    universalUserFn
  );

  if (isLoading || isLoadingUniversalData) {
    return (
      <Row
        justify="center"
        style={{ width: "100%", height: "90vh", margin: "100px 0" }}>
        <Lottie
          options={getDefaultLottieOptions(animationData)}
          height={300}
          width={370}
        />
      </Row>
    );
  }

  return (
    <div>
      <Nav />
      <Row justify="center" style={{ marginTop: 150 }}>
        <Col span={14}>
          <h2>Select Organization with which you want to Continue</h2>
          <Row gutter={[20, 20]} align="middle">
            {data?.data?.accepted?.map((inviteItem) => (
              <Col>
                <Card
                  style={{ width: 300, marginTop: 16 }}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => handleCookieChange(inviteItem?.brandId)}>
                      {" "}
                      Select
                    </Button>,
                  ]}>
                  <Meta
                    avatar={
                      <Avatar
                        src={inviteItem?.brandUser?.businessDetails?.logoUrl}
                      />
                    }
                    title={
                      inviteItem?.brandUser?.businessDetails?.name +
                      " Organization"
                    }
                    description={
                      universalData?.data?._id === inviteItem?.brandId ? (
                        <Tag color="volcano" style={{ borderRadius: 8 }}>
                          Owner
                        </Tag>
                      ) : (
                        `Invited by ${inviteItem?.brandUser?.name}`
                      )
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OrgSelect;
