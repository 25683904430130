import { sendRequest } from "./helper";

import { User } from "../typings";

type UserResult = {
  status: string;
  message: string;
  data: User;
};

export const signInBackend = (otp = "", hash = "") => {
  return sendRequest<UserResult>(
    "post",
    `/brand/register/`,
    true,
    otp ? { otp } : hash ? { hash } : {}
  ).then((res) => res.data);
};
