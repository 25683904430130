import { sendRequest } from "../../helper";

interface getFInalizedAggregateProps {
  campaignId: string;
}

interface getFinalizedAggregateResponse {
  status: string;
  message: string;
  data: {
    _id: string;
    requirementNotSent: number;
    draftWaitingForReview: number;
    liveLinkWaitingForReview: number;
  };
}

export const getFinalizedAggregate = ({
  campaignId,
}: getFInalizedAggregateProps) => {
  const queryKey = ["tester", "applicants", campaignId, "finalized-aggregate"];

  const queryFunction = () =>
    sendRequest<getFinalizedAggregateResponse>(
      "get",
      `/brand/execution/filter-aggregates?campaignId=${campaignId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
