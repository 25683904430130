import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  message,
  Space,
  Alert,
  Spin,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { AxiosError } from "axios";

import { previewCampaign, getCampaign } from "../../../../handler/campaign";
import { CampaignResponse } from "../../../../typings";

import Navbar from "../../../shared/Navbar";
import CreateSteps from "../Steps";
import Footer from "../Footer";

import { capitalizeFirstLetter } from "../../../../utils";

import styles from "../Create.module.css";

const Guidelines = () => {
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [isEditEnabled, setEditEnabled] = useState<boolean>(false);
  let { id } = useParams();
  const queryClient = useQueryClient();
  const [getCampaignKey, getCampaignFunction] = getCampaign({
    campaignId: id!,
  });
  const { data: campaignData, isLoading } = useQuery(
    getCampaignKey,
    getCampaignFunction
  );

  useEffect(() => {
    if (
      campaignData &&
      campaignData.data.campaign.statusHistory.findIndex(
        (item) => item.status === "Under-Review"
      ) !== -1
    ) {
      setEditEnabled(true);
    }
  }, [campaignData]);

  useEffect(() => {
    if (isEditEnabled) {
      return setSaveEnabled(false);
    }
    return setSaveEnabled(true);
  }, [isEditEnabled]);

  const { TextArea } = Input;
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    previewCampaignMutation.mutate({
      campaignId: id!,
      campaignObject: {
        ...campaignData!.data,
        campaign: {
          ...campaignData!.data.campaign,
          brandGuidelines: values.description,
        },
      },
    });
    // onNext();
  };

  const previewCampaignMutation = useMutation(previewCampaign, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (oldData) => {
          // get sinngle backend data
          return response;
        }
      );
      setEditEnabled(true);
      message.success("Details Updated");
    },
    onError: (err: AxiosError) => {
      // console.log(err.code, err.message, "error-message", err);
      console.log("error-message", err!.response!.data);
      if (
        (err!.response!.data! as any).status ===
        "ERR_INVITE_CANNOT_FIXED_PRICED"
      ) {
        message.error("Invite only campaign can not be fixed priced");
      } else message.error("Something went wrong");
    },
  });

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Row style={{ height: "100vh", marginTop: "45vh" }} justify="center">
        <Spin size="large" />
      </Row>
    );
  }
  return (
    <div>
      <Navbar />
      <Row justify="center" style={{ height: "70px", margin: "50px 0" }}>
        <Col span={24}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "40px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.85)",
            }}>
            Create an{" "}
            {capitalizeFirstLetter(campaignData?.data.campaign.platform || " ")}{" "}
            Campaign
            <img
              src={`/assets/icons/${campaignData?.data.campaign.platform}Icon.png`}
              alt="instagram"
              style={{ width: "30px", marginLeft: "8px" }}
            />
          </div>

          <p
            style={{
              marginTop: "8px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.45)",
            }}>
            Need assistance? Please watch this{" "}
            <span
              style={{
                color: "#2130FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}>
              video
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={2}>
          {campaignData && (
            <CreateSteps
              currentStep={4}
              statusHistory={campaignData?.data.campaign.statusHistory}
            />
          )}
        </Col>
        <Col
          span={8}
          offset={2}
          style={{ maxHeight: "57vh", overflowY: "scroll" }}>
          <div style={{ marginBottom: "10px" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.85)",
              }}>
              General Brand Guidelines
            </p>
            <p className={styles.secondaryLabel}>
              Anything that the creators must keep in mind, no matter what
              content they’re creating
            </p>
          </div>
          <div
            style={{
              width: "100%",
              padding: "16px 20px",
              background: "#FAFAFA",
              minHeight: "30vh",
            }}>
            <div>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                // style={{ marginTop: "32px" }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                requiredMark={false}
                // form={form}
                // {...formItemLayout}
              >
                <Form.Item
                  className={styles.label}
                  name="description"
                  initialValue={
                    campaignData?.data.campaign.brandGuidelines || ""
                  }
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Guidelines",
                    },
                  ]}>
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    showCount={{
                      formatter: (args) => `${args.count}/${args.maxLength}`,
                    }}
                    maxLength={1000}
                    size="large"
                    placeholder={"Brand Guidelines"}
                    disabled={!isSaveEnabled}
                  />
                </Form.Item>

                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      size="large"
                      title="Next"
                      style={{ width: "144px", marginTop: "32px" }}
                      htmlType="submit"
                      loading={previewCampaignMutation.isLoading}
                      disabled={!isSaveEnabled}>
                      Save
                    </Button>
                    {campaignData?.data.campaign.statusHistory.findIndex(
                      (item) => item.status === "Active"
                    ) === -1 && (
                      <Button
                        type="text"
                        size="large"
                        title="Next"
                        style={{ width: "144px", marginTop: "32px" }}
                        onClick={() => setEditEnabled(false)}
                        disabled={!isEditEnabled}>
                        Edit
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col
          style={{ overflowY: "auto", maxHeight: "47vh" }}
          offset={1}
          span={6}>
          {campaignData?.data.campaign.status === "Needs-Attention" && (
            <Alert type="error" message={campaignData?.data.campaign.remarks} />
          )}
        </Col>
      </Row>
      <Footer
        isType={` ${
          campaignData?.data.campaign.statusHistory.findIndex(
            (item) => item.status === "Active"
          ) !== -1
            ? "View All Campaigns"
            : "Submit for review"
        }`}
        onNext={() => {
          if (
            campaignData?.data.campaign.statusHistory.findIndex(
              (item) => item.status === "Active"
            ) !== -1
          ) {
            navigate(`/`, { replace: true });
          } else navigate(`/campaign/create/${id}/under-review`);
        }}
        onBack={() => {
          navigate(`/campaign/create/${id}/creators`);
        }}
        isNextDisabled={
          !campaignData
            ? true
            : campaignData.data.campaign.statusHistory.findIndex(
                (item) => item.status === "Under-Review"
              ) === -1
        }
      />
    </div>
  );
};

export default Guidelines;
