import React from "react";
import { Slider } from "antd";
import { useConnector } from "react-instantsearch";
import connectRange from "instantsearch.js/es/connectors/range/connectRange";

import { formatData } from "../../../../utils";

import type {
  RangeConnectorParams,
  RangeWidgetDescription,
} from "instantsearch.js/es/connectors/range/connectRange";

type UseRangeSliderProps = RangeConnectorParams;

export function useRangeSlider(props?: UseRangeSliderProps) {
  return useConnector<RangeConnectorParams, RangeWidgetDescription>(
    connectRange,
    props
  );
}

interface AgeRangeSliderProps extends UseRangeSliderProps {
  isPercent?: boolean;
}

const AgeRangeSlider: React.FC<AgeRangeSliderProps> = (props) => {
  const { start, range, refine } = useRangeSlider(props);

  const isPercentShown = props.isPercent || false;

  return (
    <Slider
      style={{ margin: "0 12px" }}
      range={{ draggableTrack: true }}
      min={range.min}
      max={range.max}
      step={0.01}
      onChange={(val: [number, number]) => {
        refine(val);
      }}
      marks={{
        [String(range.min)]: `${formatData(String(range.min), "a")}${
          isPercentShown ? "%" : ""
        }`,
        [String(range.max)]: `${formatData(String(range.max), "a")}${
          isPercentShown ? "%" : ""
        }`,
      }}
      value={[
        Number(start[0]) === -Infinity ? Number(range.min) : Number(start[0]),
        Number(start[1]) === Infinity ? Number(range.max) : Number(start[1]),
      ]}
    />
  );
};

export default AgeRangeSlider;
