import React from "react";
import { Button, ButtonProps } from "antd";

export interface CustomButtonProps extends ButtonProps {}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

export default CustomButton;
