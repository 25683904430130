import React from "react";
import { Modal, Row, Col } from "antd";
import { GrFormClose } from "react-icons/gr";

import styles from "./styles.module.css";

interface SuccessProps {
  handleSubmit: () => void;
  handleCancel: () => void;
  isVisible: boolean;
}

const Success: React.FC<SuccessProps> = ({
  handleCancel,
  handleSubmit,
  isVisible,
}) => {
  return (
    <Modal
      onOk={handleSubmit}
      onCancel={handleCancel}
      closable={false}
      visible={isVisible}
      centered
      title={null}
      footer={null}
      bodyStyle={{ padding: 0 }}>
      <div style={{ padding: "24px" }}>
        <Row justify="end">
          <GrFormClose
            onClick={() => handleCancel()}
            // size={"14px"}
            style={{ fontSize: "22px", cursor: "pointer", flexShrink: "0" }}
          />
        </Row>

        <div style={{ textAlign: "center" }}>
          <p className={styles.success}>🥳</p>
          <h2 className={styles.title}>Hey, good looking out.</h2>
          <p className={styles.desc}>
            Your Activation Request will be approved within 24 Hours.
          </p>
        </div>
        <h4
          className={styles.help}
          style={{ marginTop: "24px" }}
          onClick={() => handleSubmit()}>
          Need any Assistance ?{" "}
          <span className={styles.demo}> Book a Demo Call</span>
        </h4>
      </div>
    </Modal>
  );
};

export default Success;
