import { sendRequest } from "../../helper";
import { paymentStatus } from "../../../typings";

export type getPaymentStatusProps = {
  campaignId: string;
};
type PaymentStatusResult = {
  message: string;
  status: string;
  data: paymentStatus[];
};

export const getPaymentStatus = ({ campaignId }: getPaymentStatusProps) => {
  const queryKey = ["campaign", campaignId, "status"];

  const queryFunction = () =>
    sendRequest<PaymentStatusResult>(
      "get",
      `/brand/campaign/offer/finalize-payin/${campaignId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
