import React from "react";
import { Col, Row } from "antd";
import { MdTimeline } from "react-icons/md";
import { Doughnut } from "react-chartjs-2";

import styles from "./basic.module.css";

import StatsCard from "../../AudienceInsights/StatsCard";
import LineGraph from "../../BasicInsights/LineGraph";

import { capitalizeFirstLetter, formatData } from "../../../../utils";

import { PinnedMetrics } from "..";
import { LuPin, LuPinOff } from "react-icons/lu";

type BasicInsightsProps = {
  data: {
    [key: string]: {
      [key: string]: number;
    };
  };
  pinnedMetrics: PinnedMetrics[];
  content: PinnedMetrics["content"];
  setPinnedMetrics: React.Dispatch<React.SetStateAction<PinnedMetrics[]>>;
};

const BasicInsights: React.FC<BasicInsightsProps> = (props) => {
  const { data, content, pinnedMetrics, setPinnedMetrics } = props;

  const handlePin = (
    title: PinnedMetrics["title"],
    type: PinnedMetrics["type"] = "basic"
  ) => {
    const itemPresent = pinnedMetrics.find(
      (item) =>
        item.title === title && item.content === content && item.type === type
    );

    if (itemPresent) {
      setPinnedMetrics((prev) =>
        prev.filter(
          (item) =>
            !(
              item.title === itemPresent.title &&
              item.content === itemPresent.content &&
              item.type === itemPresent.type
            )
        )
      );
    } else {
      setPinnedMetrics((prev) => [...prev, { title, content, type }]);
    }
  };

  const isPinned = (title: string) =>
    pinnedMetrics.findIndex(
      (item) =>
        item.title === title &&
        item.type === "basic" &&
        item.content === content
    ) !== -1;

  const getDayViews = () => {
    let result: { labels: string[]; values: number[] } = {
      labels: [],
      values: [],
    };
    const response = data;

    if (!("basicStats_last_30_days" in response)) return result;

    Object.entries(response["basicStats_last_30_days"]).forEach(
      ([key, value]) => {
        result = {
          labels: [...result.labels, key.split("$").at(-1) || ""],
          values: [...result.values, value],
        };
      }
    );

    return result;
  };

  const getSubscribedViews = () => {
    const response = data;
    if (!("subscribedStatus_lifetime" in response))
      return { subs: 0, unSubs: 0 };
    let subs = response["subscribedStatus_lifetime"]["$SUBSCRIBED"] || 0;
    let unSubs = response["subscribedStatus_lifetime"]["$UNSUBSCRIBED"] || 0;

    return { subs, unSubs };
  };

  const getPlaybackLocationsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("playBackLocation_lifetime" in response)) return result;

    Object.entries(response["playBackLocation_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const getOsViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("operatingSystemDeviceType_lifetime" in response)) return result;

    Object.entries(response["operatingSystemDeviceType_lifetime"]).forEach(
      ([key, value]) => {
        result.push({
          value,
          label: key.split("$").at(-1)?.split("$").join(" ") || "",
        });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };
  const getTrafficViews = () => {
    let result: { value: number; label: string }[] = [];

    const response = data;
    if (!("trafficSource_lifetime" in response)) return result;

    Object.entries(response["trafficSource_lifetime"]).forEach(
      ([key, value]) => {
        result.push({ value, label: key.split("$").at(-1) || "" });
      }
    );
    return result.sort((a, b) => (b.value || 0) - (a.value || 0));
  };

  const totalLocationViews =
    getPlaybackLocationsViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalTrafficViews =
    getTrafficViews().reduce(
      (prev, current) => prev + (current.value || 0),
      0
    ) || 1;

  const totalOsViews =
    getOsViews().reduce((prev, current) => prev + (current.value || 0), 0) || 1;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Basic Insights</p>
      <Row gutter={[20, 20]}>
        <Col span={16}>
          <div>
            <LineGraph
              key={0}
              title="Views per day"
              optionsItemArray={[]}
              selectedOption={""}
              labelArray={getDayViews().labels}
              metric={formatData(
                getDayViews().values.reduce(
                  (prev, current) => prev + current,
                  0
                ),
                "a"
              )}
              metricLabel={`Total Views`}
              description={<></>}
              graphData={getDayViews().values}
              secondaryDescription={<p>Views per day</p>}
              secondaryIcon={<MdTimeline size={15} color="#000000B2" />}
              barColor="#398200"
              isPinned={isPinned("Views per day")}
              handlePin={() => handlePin("Views per day")}
            />
          </div>
        </Col>

        <Col span={8}>
          <div
            className={styles.container}
            style={{ position: "relative" }}
            key={2}>
            <Row
              justify="space-between"
              style={{ width: "100%" }}
              gutter={[10, 10]}>
              <Col span={24}>
                <p className={styles.label}>Views by subscribers</p>
              </Col>
              <Col span={24}>
                <p className={styles.sublabel}>
                  <MdTimeline size={15} color="#000000B2" />
                  Views by subscribers
                </p>
              </Col>
            </Row>

            {!(
              getSubscribedViews().subs === 0 &&
              getSubscribedViews().unSubs === 0
            ) ? (
              <div style={{ height: "308px" }}>
                <Doughnut
                  options={{
                    plugins: {
                      legend: { position: "top" },
                      datalabels: {
                        anchor: "end",
                        align: "start",
                        formatter: (value) => formatData(value || 0, "a"),
                        font: {
                          size: 16,
                          style: "normal",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Subscribed", "Not-Subscribed"],
                    datasets: [
                      {
                        label: "No",
                        data: [
                          getSubscribedViews().subs,
                          getSubscribedViews().unSubs,
                        ],
                        backgroundColor: ["#FF6384", "#FFCD56"],
                        borderColor: ["#FF6384", "#FFCD56"],
                        borderWidth: 1,
                        borderJoinStyle: "round",
                        borderRadius: 10,
                        hoverOffset: 4,
                        offset: 0,
                      },
                    ],
                  }}
                />
              </div>
            ) : (
              <p className="global-no-data">
                {" "}
                Not enough Data To show this Metric{" "}
              </p>
            )}
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Views by subscribers");
              }}>
              {isPinned("Views by subscribers") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}
            key={3}>
            <p className={styles.graphLabel}>PlaybackLocation wise split</p>
            <StatsCard
              items={getPlaybackLocationsViews().map((item) => ({
                label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                percent: (item.value / totalLocationViews) * 100,
              }))}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("PlaybackLocation wise split");
              }}>
              {isPinned("PlaybackLocation wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}
            key={4}>
            <p className={styles.graphLabel}>Traffic wise split</p>
            <StatsCard
              items={getTrafficViews().map((item) => ({
                label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                percent: (item.value / totalTrafficViews) * 100,
              }))}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Traffic wise split");
              }}>
              {isPinned("Traffic wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div
            className={styles.graphContainer}
            style={{ position: "relative" }}
            key={5}>
            <p className={styles.graphLabel}>Device wise split</p>
            <StatsCard
              items={getOsViews().map((item) => ({
                label: capitalizeFirstLetter(item.label.split("_").join(" ")),
                percent: (item.value / totalOsViews) * 100,
              }))}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                handlePin("Device wise split");
              }}>
              {isPinned("Device wise split") ? (
                <LuPin size={10} color={"#00000066"} />
              ) : (
                <LuPinOff size={10} color={"#00000066"} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BasicInsights;
