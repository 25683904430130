import React from "react";
import { AiFillLock } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";

import { getAppState } from "../../../handler/getAppState";

import styles from "./pay.module.css";

import ReportButton from "../../Campaign/Report/ReportButton";
import Invoices from "../../Invoices";

import { getCustomerPortalLink } from "../../../handler/plan";

type PayProps = {};

const Pay: React.FC<PayProps> = () => {
  const [userStateKey, userStateFn] = getAppState();

  const { data: appStateData } = useQuery(userStateKey, userStateFn);

  const getCustomerPortalLinkMutation = useMutation(getCustomerPortalLink, {
    onSuccess: (res) => {
      window.open(res?.data, "_blank");
    },
    onError: (err) => {
      message.error("Something went wrong while Getting Link");
      console.log(err);
    },
  });

  return (
    <div>
      <div className={styles.block}>
        <div>
          <img
            src={"/assets/img/lock-acc.png"}
            alt="lock"
            style={{
              display: "block",
              marginInline: "auto",
              marginBottom: 50,
            }}
            width={110}
          />
        </div>
        <div>
          <p className={styles.blocktitle}>Account Blocked - Payment Due</p>
          <p className={styles.blockdesc}>
            You are missing out on premium features
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.box}>
          <div
            className={styles.block}
            style={{ alignItems: "start", paddingBlock: 24 }}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                background: "#333",
              }}></div>
            <div>
              <p className={styles.blocktitle} style={{ textAlign: "left" }}>
                Your account is currently blocked due to an outstanding
                <br /> payment.
              </p>
              <p className={styles.blockdesc} style={{ textAlign: "left" }}>
                Payment attempts have been made, but they were unsuccessful.
              </p>
            </div>
          </div>

          <div
            className={styles.block}
            style={{ height: "auto", paddingBottom: 30 }}>
            <ReportButton
              onClick={() => {
                if (
                  appStateData?.data?.subscriptionPlan
                    ?.isSubscriptionManagedByStripe
                ) {
                  return getCustomerPortalLinkMutation.mutate();
                }
                return window.open(
                  "https://cloutflow.com/book-a-demo",
                  "_blank"
                );
              }}
              loading={getCustomerPortalLinkMutation.isLoading}
              style={{
                borderRadius: 4,
                border: "1px solid #533AF5",
                background: "#533AF5",
                color: "#fff",
                width: "300px",
                padding: "8px 24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}>
              {appStateData?.data?.subscriptionPlan
                ?.isSubscriptionManagedByStripe
                ? "Complete Payment"
                : "Contact Us"}
            </ReportButton>
            <ReportButton
              onClick={() => {}}
              disabled
              style={{
                borderRadius: 4,
                width: "300px",
                padding: "8px 24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}>
              <AiFillLock />
              Switch Team
            </ReportButton>
          </div>
        </div>
        {appStateData?.data?.subscriptionPlan
          ?.isSubscriptionManagedByStripe && (
          <div style={{ width: "868px", margin: "50px auto" }}>
            <Invoices />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pay;
