import { AxiosError } from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import {
  getReportsReportOnlyCampaign,
  getConfigurationReportOnlyCampaign,
  updateReportOnlyCampaignConfiguration,
  getYtAdvanceMetrics,
  getYtAdvanceMetricsResponse,
} from "../../../../handler/report";
import { fieldConfiguration } from "../../../../typings";

type rowData = { [key: string]: any }[] | [];
const mediaTypes = new Set<string>();
type coloumData = { [objKey: string]: any }[] | [];

type ContextState = {
  tableRow: rowData;
  tableColumn: coloumData;
  tabelFullColumns: coloumData;
  selectCampaignColumn: Function;
  setFilterBySearchOrMediaType: Function;
  searchKey: string;
  mediaTypes: string[];
  selectedMediaType: string;
  isLoading: boolean;
  platform: "instagram" | "youtube";
  campaignId: string;
  totalAggregateObject: { [key: string]: any };
  advanceMetricsData: getYtAdvanceMetricsResponse | undefined;
  advanceMetricsDataLoading: boolean;
  updateCampaignConfig: (
    configArray: Omit<fieldConfiguration, "_id">[],
    handleCallBack: () => void
  ) => void;
  isUpdatingConfig: boolean;
  totalReportsLength: number;
};

const ReportOnlyContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
  campaignId: string;
  platform: "instagram" | "youtube";
}

export const ReportOnlyContextProvider: React.FC<Props> = ({
  children,
  campaignId,
  platform,
}) => {
  const queryClient = useQueryClient();
  let [tableRow, setTableRow] = useState<rowData>([]);
  let [tableColumn, setTableColumns] = useState<coloumData>([]);
  let [tabelFullColumns, setTableFullColumns] = useState<coloumData>([]);
  let [searchKey, setSearchKey] = useState<string>("");
  const [selectedMediaType, setSelectedMediaType] = useState<string>("");
  const [totalAggregateObject, setTotalAggregateObject] = useState<{
    [key: string]: any;
  }>({});

  const [getAllReports, getAllReportsFn] = getReportsReportOnlyCampaign({
    campaignId,
    platform,
  });

  const { data, isLoading: isLoadingReports } = useQuery(
    getAllReports,
    getAllReportsFn
  );

  const [getReportConfiguration, getReportConfigurationFn] =
    getConfigurationReportOnlyCampaign({
      campaignId,
      platform,
    });

  const { data: reportConfiguration, isLoading } = useQuery(
    getReportConfiguration,
    getReportConfigurationFn
  );

  const [ytAdvanceMetricsKey, ytAdvanceMetricsFn] = getYtAdvanceMetrics({
    campaignId,
  });

  const { data: advanceMetricsData, isLoading: advanceMetricsDataLoading } =
    useQuery(ytAdvanceMetricsKey, ytAdvanceMetricsFn, {
      enabled: platform === "youtube",
    });

  const evalCalculator = (
    fieldsToCalculate: { [key: string]: string },
    baseValues: { [key: string]: any }
  ) => {
    let calculatedColumns: string = "{";
    Object.entries(fieldsToCalculate)?.map((ele: any) => {
      calculatedColumns += ele[0] + ":" + ele[1] + ",";
    });
    calculatedColumns += "}";

    let evalString = "";
    Object.entries(baseValues || {}).map((ele) => {
      if (typeof ele[1] == "number") {
        evalString += "let " + ele[0] + "=" + ele[1] + "\n";
      }
    });

    evalString += "let columns=" + calculatedColumns + "\n" + "columns";

    try {
      let result = eval(evalString);
      return result;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const calculateCustomFields = (columns: any, data: any) => {
    let calculatedColumns = {};
    columns?.map((ele: any) => {
      if (
        ele?.fieldType === "defaultCalculatedMetrics-Influencer" ||
        ele?.fieldType === "customCalculatedMetrics-Influencer"
      ) {
        calculatedColumns = {
          ...calculatedColumns,
          [ele.variableName]: ele.mappedName
            .split("{")
            .join("")
            .split("}")
            .join(""),
        };
      }
    });
    let mappedRows: { [key: string]: any }[] = [];
    let totalAggregateRows: { [key: string]: number } = {};
    for (let row of data) {
      let totalRow: { [key: string]: number } = {};

      for (let i = 0; i < (row?.reportInfluencer || []).length; i += 1) {
        let reports = row?.reportInfluencer[i];

        let mappedRowReport: { [key: string]: number } = {};

        mappedRowReport = {
          ...reports.media,
        };

        Object.entries(mappedRowReport || {}).map((ele) => {
          if (typeof ele[1] == "number") {
            totalRow = {
              ...totalRow,
              [ele[0]]: ele[0] in totalRow ? ele[1] + totalRow[ele[0]] : ele[1],
            };
          }
        });

        mappedRows.push({
          ...mappedRowReport,
          type: reports?.deliverableType,
          influencer: row._id,
          influencerSpan: 0,
          socialMediaId: row.socialMediaId,
          deliverableMediaId: reports?.deliverableMediaId?.toString(),
          influencerData: {
            name: row?.socialMediaKit?.influencerName,
            username: row?.socialMediaKit?.name,
            platform: reports?.platform,
            profilePicUrl: row?.socialMediaKit?.profilePicUrl,
          },
        });
      }

      Object.entries({ ...totalRow, brandPrice: row.brandPrice } || {}).map(
        (ele) => {
          if (typeof ele[1] == "number") {
            totalAggregateRows = {
              ...totalAggregateRows,
              [ele[0]]:
                ele[0] in totalAggregateRows
                  ? ele[1] + totalAggregateRows[ele[0]]
                  : ele[1],
            };
          } else {
            console.log(ele[0]);
          }
        }
      );

      let result = evalCalculator(calculatedColumns, {
        ...totalRow,
        brandPrice: row.brandPrice,
      });

      totalRow = {
        ...totalRow,
        ...result,
      };

      mappedRows = [
        ...mappedRows.slice(
          0,
          mappedRows.length - row?.reportInfluencer.length
        ),
        {
          ...totalRow,
          type: "Total",
          influencer: row._id,
          influencerSpan: row?.reportInfluencer.length + 1,
          socialMediaId: row?.socialMediaId?.toString(),
          influencerData: {
            name: row?.socialMediaKit?.influencerName,
            username: row?.socialMediaKit?.name,
            platform: row.platform,
            profilePicUrl: row?.socialMediaKit?.profilePicUrl,
          },
        },
        ...mappedRows.slice(mappedRows.length - row?.reportInfluencer.length),
      ];
    }

    let result1 = evalCalculator(calculatedColumns, {
      ...totalAggregateRows,
    });

    setTotalAggregateObject({ ...totalAggregateRows, ...result1 });

    return mappedRows;
  };

  const putCampaignConfiguration = useMutation(
    updateReportOnlyCampaignConfiguration,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(getReportConfiguration);

        // queryClient.invalidateQueries(["reportOnlyReports", campaignId]);
      },
      onError: (err: AxiosError) => {
        console.log(err.code, err.message, "error-message", err);
      },
    }
  );

  const updateCampaignConfig = (
    configArray: Omit<fieldConfiguration, "_id">[],
    handleCallBack: () => void
  ) => {
    putCampaignConfiguration.mutate(
      {
        data: {
          campaignId,
          fieldConfiguration: configArray,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getReportConfiguration);
          handleCallBack();
        },
      }
    );
  };

  useEffect(() => {
    if (data && reportConfiguration) {
      console.log("dd", data);
      if (reportConfiguration.data.campaignConfiguraion.length === 0) {
        setTableFullColumns(
          reportConfiguration.data.reportConfiguration.fields?.map((ele) => {
            return {
              key: ele.variableName,
              title: ele.name,
              desc: ele.description,
              fieldType: ele.fieldType,
              mappedName: ele.mappedName,
              _id: ele?._id,
            };
          }) || []
        );

        setTableColumns(
          [
            { key: "influencer", title: "Influencer" },
            { key: "type", title: "Type" },
            ...reportConfiguration.data.reportConfiguration.fields?.map(
              (ele) => {
                return {
                  key: ele.variableName,
                  title: ele.name,
                  desc: ele.description,
                  fieldType: ele.fieldType,
                  _id: ele?._id,
                };
              }
            ),
          ] || []
        );
        // const initialMediaTypes = calCulateMediaTypes();
        setTableRow(
          calculateCustomFields(
            reportConfiguration.data.reportConfiguration.fields,
            filterBySearchOrMediaType(searchKey, "")
          )
        );
      } else {
        let tableColumms: coloumData = [];

        reportConfiguration.data.reportConfiguration.fields?.forEach((ele) => {
          tableColumms = [
            ...tableColumms,
            {
              key: ele.variableName || "",
              title: ele.name,
              desc: ele.description,
              fieldType: ele.fieldType,
              mappedName: ele.mappedName || "",
              _id: ele?._id,
            },
          ];
        });
        reportConfiguration.data.campaignConfiguraion.forEach((ele) => {
          tableColumms = [
            ...tableColumms,
            {
              key: ele.variableName || "",
              title: ele.name,
              desc: ele.description,
              fieldType: ele.fieldType,
              mappedName: ele.mappedName || "",
              _id: ele?._id,
            },
          ];
        });

        setTableFullColumns(tableColumms || []);

        setTableColumns([
          { key: "influencer", title: "Influencer" },
          { key: "type", title: "Type" },
          ...tableColumms,
        ]);

        // const initialMediaTypes = calCulateMediaTypes();
        setTableRow(
          calculateCustomFields(
            [
              ...reportConfiguration.data.reportConfiguration.fields,
              ...reportConfiguration.data.campaignConfiguraion,
            ],
            filterBySearchOrMediaType(searchKey, "")
          )
        );
      }
    } else {
    }
  }, [reportConfiguration, data]);

  const selectCampaignColumn = (item: { [key: string]: any }) => {
    if (reportConfiguration) {
      setTableColumns((tableColumns) => {
        if (
          tableColumns.findIndex((tableItem) => tableItem.key === item.key) ===
          -1
        ) {
          const indexToInsert = tabelFullColumns.findIndex(
            (x) => x.key === item.key
          );

          return indexToInsert === -1
            ? [...tableColumn, item]
            : [
                ...tableColumn.slice(0, indexToInsert + 2),
                item,
                ...tableColumn.slice(indexToInsert + 2),
              ];
        }

        return tableColumns.filter((tableItem) => tableItem.key !== item.key);
      });

      let selectedColumns = reportConfiguration.data.campaignConfiguraion;

      if (
        reportConfiguration.data.campaignConfiguraion.findIndex(
          (colItem) => colItem.variableName === item.key
        )
      ) {
        selectedColumns = reportConfiguration.data.campaignConfiguraion.filter(
          (colItem) => colItem.variableName !== item.key
        );
      } else {
        selectedColumns = [
          ...reportConfiguration.data.campaignConfiguraion,
          reportConfiguration.data.reportConfiguration.fields.find(
            (colItem) => colItem.variableName === item.key
          )!,
        ];
      }
      // setTableColumns([
      //   { key: "influencer", title: "Influencer" },
      //   { key: "type", title: "Type" },
      //   ...(selectedColumns?.map((ele) => {
      //     return {
      //       key: ele.variableName,
      //       title: ele.name,
      //       desc: ele.description,
      //       fieldType: ele.fieldType,
      //     };
      //   }) || []),
      // ]);

      // putCampaignConfiguration.mutate({
      //   data: {
      //     campaignId: campaignId,
      //     fieldConfiguration: selectedColumns,
      //   },
      // });
    }
  };

  const filterBySearchOrMediaType = (key: string, selectedType: string) => {
    if (reportConfiguration && data) {
      let filteredRows = data?.data?.filter((ele) => {
        if (
          (ele?.searchAbleParams.toLowerCase() || "")?.includes(
            key?.toLowerCase()
          )
        )
          return true;
        return false;
      });

      filteredRows = filteredRows.map((ele) => {
        return {
          ...ele,
          reportInfluencer: ele.reportInfluencer.filter((mItem) =>
            selectedType === "" ? true : selectedType === mItem.deliverableType
          ),
        };
      });

      return filteredRows || [];
    }

    return [];
  };

  const calCulateMediaTypes = () => {
    if (data) {
      data?.data?.forEach((influencerItem) =>
        influencerItem.reportInfluencer.forEach((item) =>
          mediaTypes.add(item.deliverableType)
        )
      );

      //   setSelectedMediaType("");
      return Array.from(mediaTypes);
    }
  };

  const setFilterBySearchOrMediaType = (key: string, selectedType: string) => {
    let filteredRows = filterBySearchOrMediaType(key, selectedType);

    if (reportConfiguration) {
      setTableRow((preValue) =>
        calculateCustomFields(
          [
            ...reportConfiguration.data.reportConfiguration.fields,
            ...reportConfiguration.data.campaignConfiguraion,
          ],
          filteredRows
        )
      );
    }

    setSearchKey((prev) => key);
    setSelectedMediaType(selectedType);
  };

  return (
    <ReportOnlyContext.Provider
      value={{
        tableRow,
        tableColumn,
        tabelFullColumns,
        selectCampaignColumn,
        searchKey,
        setFilterBySearchOrMediaType,
        mediaTypes: Array.from(mediaTypes),
        selectedMediaType,
        isLoading: isLoading || isLoadingReports,
        platform,
        campaignId,
        totalAggregateObject,
        advanceMetricsData,
        advanceMetricsDataLoading,
        updateCampaignConfig,
        isUpdatingConfig: putCampaignConfiguration.isLoading,
        totalReportsLength:
          data?.data?.reduce(
            (prev, current) => current.reportInfluencerSize + prev,
            0
          ) || 0,
      }}>
      {children}
    </ReportOnlyContext.Provider>
  );
};

export const useReportOnly = () => {
  const context = useContext(ReportOnlyContext);

  if (!context) {
    throw new Error("useAuth must be used in <AuthProvider />");
  }

  return context;
};
