import React from "react";
import { formatData } from "../../../../../../utils";

import styles from "./styles.module.css";
import { Popover } from "antd";

const MetricCard: React.FC<{
  title: React.ReactNode;
  desc: string;
  metric?: number;
  isChip?: boolean;
  chipColor?: string;
  chipLabel?: string;
}> = ({
  title,
  desc,
  metric = 0,
  isChip = false,
  chipColor = "#E7E6FE",
  chipLabel,
}) => {
  return (
    <div className={styles.gridContainer}>
      <p className={styles.title}>{title}</p>

      {desc.length > 30 ? (
        <Popover
          content={desc}
          title="Information"
          trigger="hover"
          placement="top"
          arrowPointAtCenter>
          <p className={styles.desc}>{desc.slice(0, 30)}...</p>
        </Popover>
      ) : (
        <p className={styles.desc}>{desc}</p>
      )}
      {isChip ? (
        <div
          style={{
            backgroundColor: chipColor,
            padding: "10px 20px",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
          }}>
          {chipLabel}
        </div>
      ) : (
        <p className={styles.metric}>
          {metric === 0
            ? "-"
            : metric < 1000
            ? formatData(metric, "0.00")
            : formatData(metric, "a")}
        </p>
      )}
    </div>
  );
};

export default MetricCard;
