export const INSTANT_SEARCH_INDEX_INSTA_NAME = "platform-discovery-ig-v2-stage";
export const INSTANT_SEARCH_INDEX_YT_NAME = "platform-discovery-yt-v2-stage";
export type ConfigType = ConfigTypeSingle[];
export type ConfigTypeSingle = {
  facetName: string;
  title: string;
  isSearchable: boolean;
  compType: "list" | "inputRange" | "slider" | "custom-multi" | "checkbox";
  desc: string;
  type?: string;
  placeHolder?: string;
};

export const YTconfig: ConfigType = [
  {
    facetName: "hashtags",
    title: "Hashtags",
    isSearchable: true,
    compType: "list",
    desc: "Search by hashtags used by Influencers in their recent posts or reels",
    placeHolder: "Search Hashtags",
  },
  {
    facetName: "mentions",
    title: "Mentions",
    isSearchable: true,
    compType: "list",
    desc: "Search by mentions by influencers in any of their recent posts or reels",
    placeHolder: "Search Mentions",
  },
  {
    facetName: "partnerships",
    title: "Partnership",
    isSearchable: true,
    compType: "list",
    desc: "Search by brands mentioned by influencers in their recent posts or reels",
    placeHolder: "Search Partnerships",
  },
  {
    facetName: "bio",
    title: "Bio",
    isSearchable: true,
    compType: "list",
    desc: "Search by keywords used by influencers in their Bio",
    placeHolder: "Search Influencers Bio",
  },

  {
    facetName: "influencerLocation",
    title: "Influencer Location",
    isSearchable: true,
    compType: "list",
    desc: "Search by location of influencers",
    placeHolder: "Search Influencers Location",
  },
  {
    facetName: "gender",
    title: "Influencer Gender",
    isSearchable: false,
    compType: "list",
    desc: "Search by gender of influencers",
    placeHolder: "Search Influencer Gender",
  },
  {
    facetName: "followers",
    title: "Subscribers Range",
    isSearchable: true,
    compType: "slider",
    desc: "Followers of Influencers",
    placeHolder: "Search Influencer Followers",
  },
  {
    facetName: "age",
    title: "Age",
    isSearchable: true,
    compType: "slider",
    desc: "Age of Influencers",
    placeHolder: "Search Age",
  },
  {
    facetName: "video_avgViews",
    title: "Average Video Views",
    isSearchable: true,
    compType: "slider",
    desc: "",
    placeHolder: "Search Video Average Views",
  },
  {
    facetName: "shorts_avgViews",
    title: "Average Short Views",
    isSearchable: true,
    compType: "slider",
    desc: "",
    placeHolder: "Search Shorts Average Views",
  },
  {
    facetName: "video_avg_view_percentage",
    title: "Average Video View Percentage",
    isSearchable: true,
    compType: "slider",
    desc: "",
    placeHolder: "Search Video Average Views Percentage",
  },
  {
    facetName: "shorts_avg_view_percentage",
    title: "Average Shorts View Percentage",
    isSearchable: true,
    compType: "slider",
    desc: "",
    placeHolder: "Search Shorts Average Views Percentage",
  },
  {
    facetName: "shorts_subscriber_view_percentage",
    title: "Shorts Subscribers Views Percentage",
    isSearchable: true,
    compType: "slider",
    desc: "",
    placeHolder: "Search Shorts Subscriber Views percentage",
  },
  {
    facetName: "video_subscriber_view_percentage",
    title: "Video Subscribers Views Percentage",
    isSearchable: true,
    compType: "slider",
    desc: "Video Subscribers Views Percentage",
    placeHolder: "Search Video Subscriber Views percentage",
  },

  {
    facetName: "country_video",
    title: "Audience Country",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given country",
    placeHolder: "Search Audience Country",
  },
  {
    facetName: "age_ranges_video",
    title: "Audience Age Ranges",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given Age range",
    placeHolder: "Search Audience Age Ranges",
  },
  {
    facetName: "audience_gender_video_female",
    title: "Female Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of followers are Female",
    placeHolder: "Search Female Audience",
  },
  {
    facetName: "audience_gender_video_male",
    title: "Male Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of Male Followers",
    placeHolder: "Search Male Audience",
  },

  {
    facetName: "country_shorts",
    title: "Audience Country",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given country",
    placeHolder: "Search Audience Country",
  },
  {
    facetName: "age_ranges_shorts",
    title: "Audience Age Ranges",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given Age range",
    placeHolder: "Search Audience Age Ranges",
  },
  {
    facetName: "audience_gender_shorts_female",
    title: "Female Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of followers are Female",
    placeHolder: "Search Female Audience",
  },
  {
    facetName: "audience_gender_shorts_male",
    title: "Male Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",

    desc: "Percentage of followers are Male",
    placeHolder: "Search Male Audience",
  },

  {
    facetName: "isAutoInvite",
    title: "Is Profile Open ?",
    isSearchable: false,
    compType: "list",
    desc: "Open Profile means that creator is added to your community as soon as you add them",
    placeHolder: "Search for Open Profile Options",
  },
];

export const IGConfig: ConfigType = [
  {
    facetName: "hashtags",
    title: "Hashtags Used",
    placeHolder: "Search Hashtags",
    isSearchable: true,
    compType: "list",
    desc: "Search by hashtags used by Influencers in their recent posts or reels",
  },
  {
    facetName: "mentions",
    title: "Mentions",
    isSearchable: true,
    placeHolder: "Search Mentions",
    compType: "list",
    desc: "Search by mentions by influencers in any of their recent posts or reels",
  },
  {
    facetName: "partnership",
    title: "Partnership",
    placeHolder: "Search Partnership",
    isSearchable: true,
    compType: "list",
    desc: "Search by brands mentioned by influencers in their recent posts or reels",
  },
  {
    facetName: "bio",
    title: "Bio",
    isSearchable: true,
    compType: "list",
    desc: "Search by keywords used by influencers in their Bio",
    placeHolder: "Search Bio",
  },

  {
    facetName: "influencerLocation",
    title: "Influencer Location",
    isSearchable: true,
    compType: "list",
    desc: "Search by location of influencers",
    placeHolder: "Search Influencer Location",
  },
  {
    facetName: "gender",
    title: "Influencer Gender",
    isSearchable: false,
    compType: "list",
    desc: "Search by gender of influencers",
  },

  {
    facetName: "isInorganic",
    title: "Is Profile Boosted?",
    isSearchable: false,
    compType: "list",
    desc: "Boosted profiles are those in which Engagement source is organic(eg bots/adds)",
    placeHolder: "",
  },

  {
    facetName: "all_engagementRate",
    title: "Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Engagement Rate of influencers is the Average of Engagement of Posts and Reels divided by Followers",
    placeHolder: "Search Engagement Rate",
  },

  {
    facetName: "all_feed_engagementRate",
    title: "Feed Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Average engagement per Post, Average of (engagement / reach ) of recent Posts",
    placeHolder: "Search Feed Engagement Rate",
  },
  {
    facetName: "all_reels_engagementRate",
    title: "Reels Engagement Rate",
    isSearchable: false,
    compType: "inputRange",
    desc: "Average engagement per Reel, Average of (engagement / reach ) of recent Reels",
    placeHolder: "Search Reel Engagement Rate",
  },
  {
    facetName: "followers",
    title: "Followers Range",
    isSearchable: true,
    compType: "slider",
    desc: "Followers of Influencer",
    placeHolder: "Search Influencer Followers",
  },
  {
    facetName: "age",
    title: "Age",
    isSearchable: true,
    compType: "slider",
    desc: "Age of Influencers",
    placeHolder: "Search Age of Infuencers",
  },
  {
    facetName: "avgReelPlays",
    title: "Average Reel Plays",
    isSearchable: true,
    compType: "slider",
    desc: "Average Reels plays",
    placeHolder: "Search Average Reel Plays",
  },
  {
    facetName: "country",
    title: "Audience Country",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given country",
    placeHolder: "Search Audience Country",
  },
  {
    facetName: "city",
    title: "Audience City",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given city",
    placeHolder: "Search Audience City",
  },
  {
    facetName: "ageRanges",
    title: "Audience Age Ranges",
    isSearchable: false,
    compType: "custom-multi",
    type: "audience",
    desc: "Percentage of followers in a given Age range",
    placeHolder: "Search Audience Age Ranges",
  },
  {
    facetName: "audience_gender_female",
    title: "Female Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of  Female followers",
    placeHolder: " Search Audience female Percentage",
  },
  {
    facetName: "audience_gender_male",
    title: "Male Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of Male Followers",
    placeHolder: "Search Audience Male Percentage",
  },
  {
    facetName: "estGenuineFollower",
    title: "Genuine Followers Percentage",
    isSearchable: true,
    compType: "slider",
    type: "audience",
    desc: "Percentage of Genuine Followers",
    placeHolder: "Search genuine Percentage",
  },
  {
    facetName: "isAutoInvite",
    title: "Is Profile Open ?",
    isSearchable: false,
    compType: "list",
    desc: "Open Profile means that creator is added to your community as soon as you add them",
    placeHolder: "Search for Open Profile Options",
  },
];
