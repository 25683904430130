import CryptoJS from "crypto-js";

import { useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Pay = () => {
  let [searchParams] = useSearchParams();
  let cypher = searchParams.get("i") || "";

  let bytes = CryptoJS.AES.decrypt(cypher, process.env.REACT_APP_RAZORPAY_KEY!);
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  let newObj = JSON.parse(originalText);
  const { url, inputs } = newObj;
  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    formRef.current && formRef.current.submit();
  }, [searchParams]);

  return (
    <form method="POST" action={url} ref={formRef}>
      {Object.keys(inputs).map((item) => {
        return (
          <input type="hidden" name={item} value={inputs[item]} key={item} />
        );
      })}
      <text>Please do not close window.......</text>
    </form>
  );
};

export default Pay;
