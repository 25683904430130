import React, { useState } from "react";
import { Modal, Row, message, Space, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { AxiosError } from "axios";
import Option from "../../../shared/UI/Option";
import CustomInput from "../../../shared/Custom/Input";
import CustomButton from "../../../shared/Custom/CustomButton";
import { postCampaign } from "../../../../handler/campaign";
import { createCampaign } from "../../../../handler/report";
import { getUser } from "../../../../handler/getUser";
import { CampaignResponse } from "../../../../typings";
import styles from "../Create.module.css";

interface CreateModalProps {
  isVisible: boolean;
  handleCancel: () => void;
}

const CreateModal: React.FC<CreateModalProps> = ({
  isVisible,
  handleCancel,
}) => {
  const navigate = useNavigate();

  const [platform, setPlatform] = useState<"instagram" | "youtube">(
    "instagram"
  );

  const [execType, setExecType] = useState<"exec" | "report" | "campaign">(
    "report"
  );

  const [touched, setTouched] = useState<boolean>(false);

  const [cName, setCName] = useState<string>("");

  const queryClient = useQueryClient();

  const handleClick = () => {
    if (execType === "exec") {
      postCampaignMutation.mutate({
        platform: platform,
        campaignName: cName,
      });
    }
    if (execType === "report") {
      createCampaignMutation.mutate({
        platform: platform,
        campaignName: cName,
      });
    }
  };

  const postCampaignMutation = useMutation(postCampaign, {
    onSuccess: (response) => {
      queryClient.setQueryData<CampaignResponse>(
        ["campaign", response.data.campaign._id],
        (_) => {
          return response;
        }
      );

      navigate(`/campaign/create/${response.data.campaign._id}`);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong while Creating Campaign");
    },
  });
  const createCampaignMutation = useMutation(createCampaign, {
    onSuccess: (response) => {
      console.log(response);
      navigate(`/campaign/manage/report/${response.data._id}`);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong while Creating Campaign");
    },
  });

  const [queryKey, queryFn] = getUser();
  const { data: userData } = useQuery(queryKey, queryFn);

  return (
    <Modal
      width={684}
      title={null}
      footer={null}
      bodyStyle={{ borderRadius: "8px", padding: 28 }}
      onCancel={() => {
        handleCancel();
        return setTouched(false);
      }}
      visible={isVisible}
      centered
      className={"campaign-create-modal"}
    >
      <Row justify="start" style={{ marginTop: "0px" }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 22,
            lineHeight: "normal",
            fontFamily: "Inter",
          }}
        >
          Create a new campaign
        </p>
      </Row>
      <div className={styles.grid} style={{ marginTop: 40, gap: 32 }}>
        <section>
          <Row style={{ marginTop: "3px" }}>
            <Col span={24}>
              <p className={styles.secondaryLabel}>Select the platform</p>
            </Col>
            <Space style={{ marginTop: "20px" }}>
              <Option
                title={"Instagram"}
                isSelected={platform === "instagram"}
                handleClick={() => setPlatform("instagram")}
                style={{
                  width: "180px",
                  textAlign: "center",
                  borderRadius: 10,
                }}
              />
              <Option
                title={"Youtube"}
                isSelected={platform === "youtube"}
                handleClick={() => setPlatform("youtube")}
                // disabled={execType === "report"}
                style={{
                  width: "180px",
                  textAlign: "center",
                  borderRadius: 10,
                }}
              />
            </Space>
          </Row>
        </section>

        <section>
          <Row style={{ marginTop: "3px" }}>
            <Col span={24}>
              <p className={styles.secondaryLabel}>Select Campaign Type</p>
            </Col>
            <Space style={{ marginTop: "20px" }}>
              <Option
                title={"Reporting"}
                isSelected={execType === "report"}
                handleClick={() => setExecType("report")}
                style={{
                  width: "180px",
                  textAlign: "center",
                  borderRadius: 10,
                }}
              />
              {userData?.data?.accountType &&
                userData?.data?.accountType !== "Report" && (
                  <Option
                    title={"Connect"}
                    isSelected={execType === "exec"}
                    handleClick={() => setExecType("exec")}
                    // disabled={execType === "report"}
                    style={{
                      width: "180px",
                      textAlign: "center",
                      borderRadius: 10,
                    }}
                  />
                )}
            </Space>
          </Row>
        </section>

        <section>
          <CustomInput
            placeholder="Example: Your Brand’s Jan Campaign"
            containerStyles={{ width: "320px", gap: 12 }}
            subLabel="Name your Campaign"
            subLabelClassName={styles.secondaryLabel}
            value={cName}
            onBlur={() => setTouched(true)}
            onChange={(e) => setCName(e?.target?.value || "")}
            error={
              touched
                ? cName.trim() === ""
                  ? "Campaign Name can't be empty"
                  : ""
                : undefined
            }
          />
        </section>
        <Row justify="end">
          <CustomButton
            title="Create"
            type="primary"
            style={{
              padding: "14px 30px",
              borderRadius: "10px",
              height: "50px",
              border: "1px solid #FFF",
              background: cName.trim() === "" ? "#f5f5f5" : "#533AF5",
            }}
            className={styles.hover}
            disabled={cName.trim() === ""}
            onClick={() => {
              handleClick();
            }}
            loading={
              postCampaignMutation.isLoading || createCampaignMutation.isLoading
            }
          >
            {" "}
            Create Campaign
          </CustomButton>
        </Row>
      </div>
    </Modal>
  );
};

export default CreateModal;
