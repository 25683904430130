import { sendRequest } from "../../helper";

import { TimelineReq } from "../../../typings";

interface getTimelineRequirementsProps {
  deliverableId: string;
}

interface getTimelineRequirementsResponse {
  status: string;
  message: string;
  data: TimelineReq;
}

export const getTimelineRequirements = ({
  deliverableId,
}: getTimelineRequirementsProps) => {
  const queryKey = ["tester", "applicants", deliverableId, "reqs", "timeline"];

  const queryFunction = () =>
    sendRequest<getTimelineRequirementsResponse>(
      "get",
      `/brand/execution/deliverables/requirements?deliverableId=${deliverableId}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
