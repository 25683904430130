import ChatWindow from "../ChatWindow";

import { useChatContext } from "../../../context/ChatContext";

import { Link } from "react-router-dom";

const ChatScreen = ({
  influencerIdParam = "",
}: {
  influencerIdParam?: string;
}) => {
  const {
    conversationId,
    influencerId,
    noConversation,
    messages,
    setInfluencerId,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useChatContext();

  if (influencerIdParam) {
    setInfluencerId(influencerIdParam);
  }

  return (
    <div style={{ height: "91.7vh" }}>
      {influencerId === "" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <img
            style={{ width: "323px", height: "202px" }}
            src="/assets/img/chat.png"
            alt=""
          />
          <p style={{ fontSize: "30px", fontWeight: "500" }}>
            Cloutflow Messaging
          </p>
          {!noConversation ? (
            <p style={{ fontSize: "14px", opacity: "0.45" }}>
              Enhancing brand-creator relationship
            </p>
          ) : (
            <p style={{ fontSize: "14px", fontFamily: "Roboto" }}>
              <span style={{ opacity: "0.45" }}>
                Start conversation with creators. Either{" "}
              </span>
              <Link
                to={"/campaign/create"}
                style={{
                  color: "#2130FF",
                  opacity: "",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}>
                create a campaign
              </Link>{" "}
              or{" "}
              <Link
                to={"/discover"}
                style={{
                  color: "#2130FF",
                  opacity: "",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}>
                explore creator
              </Link>
            </p>
          )}
        </div>
      ) : (
        <ChatWindow
          messages={messages}
          influencerId={influencerId}
          conversationId={conversationId}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage || false}
          isLoading={isLoading}
          isInTimeline={false}
        />
      )}
    </div>
  );
};

export default ChatScreen;
